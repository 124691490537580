const energyFields = [
  'usage',
  'controlled_load_daily_kwh_0',
  'controlled_load_daily_kwh_1',
  'controlled_load_daily_kwh_2',
  'curve_weekday',
  'curve_weekend',
  'demand_60min',
  'kwh_0',
  'kwh_1',
  'kwh_2',
  'kwh_3',
  'kwh_4',
  'kwh_5',
  'kwh_6',
  'kwh_7',
  'kwh_8',
  'kwh_9',
  'kwh_10',
  'kwh_11',
  'kw_0',
  'kw_1',
  'kw_2',
  'kw_3',
  'kw_4',
  'kw_5',
  'kw_6',
  'kw_7',
  'kw_8',
  'kw_9',
  'kw_10',
  'kw_11',
  'scale_weekend',
  'usage',
  'usage_data_source',
  'kw_monthly_demand',
  'tariffs',
]

export default energyFields
