import { makeStyles, Tooltip } from '@material-ui/core'
import { CalendarTodayOutlined } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear'
import dayjs from 'dayjs'
import { DatePicker, DatePickerProps, IconButton, OsDatePickerVersion, useComponentVersion } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import PropTypes from 'prop-types'
import { FieldTitle, useInput, useLocale, useTranslate } from 'react-admin'
import { getDateFormat } from 'util/date'

export const DateInput = ({
  label,
  inputVariant,
  value,
  onChange,
  disablePast = false,
  margin = 'none',
  format,
  disabled,
  ...rest
}: DatePickerProps) => {
  const locale = useLocale()

  // To Do: support more locales
  const defaultFormat = getDateFormat({ locale, long: false, variant: 'date' })

  return (
    <DatePicker
      variant="inline"
      inputVariant={inputVariant}
      label={label}
      value={value}
      onChange={onChange}
      autoOk
      format={format || defaultFormat}
      margin={margin}
      onError={(validationError) => {
        if (validationError) {
          console.log('Date validation error', validationError)
        }
      }}
      disablePast={disablePast}
      disabled={disabled}
      {...rest}
    />
  )
}

/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */

const getDateFromString = (input) => {
  if (input && input.includes('-')) {
    var inputDate = input.split('-')
    return new Date(inputDate[0], inputDate[1] - 1, inputDate[2])
  } else {
    return new Date(input)
  }
}

export const formatDate = (input, isVersion2 = false) => {
  if (!input) {
    return null
  }
  if (isVersion2) return dayjs(input)
  const date = input instanceof Date ? input : getDateFromString(input)
  if (!(date instanceof Date)) {
    throw new Error(`Invalid date: ${input}`)
  }
  return date
}

const useStyles = makeStyles(
  (theme) => ({
    input: {
      width: theme.spacing(32),
    },
  }),
  { name: 'OS-date-input' }
)

const DateFormInput = (props) => {
  const {
    required,
    label,
    options,
    source,
    resource,
    helperText,
    margin = 'dense',
    onBlur,
    className,
    onChange,
    onFocus,
    optionsTime,
    labelTime,
    validate,
    hideClearButton,
    inputVariant,
    defaultValue,
    disabled,
    disablePast = false,
    style = {},
    ...rest
  } = props
  const {
    id,
    input,
    isRequired,
    meta: { error, touched, submitError },
  } = useInput({
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    validate,
    ...rest,
  })
  const translate = useTranslate()
  const classes = useStyles()

  const handleChange = (date) => {
    input.onChange(date)
    input.onBlur()
  }

  const clearDate = () => {
    input.onChange(null)
    input.onBlur()
  }
  const isVersion2 = useComponentVersion('date_picker') === OsDatePickerVersion.V2
  const value = formatDate(input?.value ? input.value : defaultValue, isVersion2)

  return (
    <>
      <DateInput
        label={
          label ? (
            <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired || required} />
          ) : undefined
        }
        variant="inline"
        value={value}
        disabled={disabled}
        onChange={handleChange}
        className={`${classes.input} ${className}`}
        disablePast={disablePast}
        inputVariant={inputVariant}
        margin={margin}
        error={touched ? error || submitError : false}
        InputProps={{
          endAdornment:
            !hideClearButton && input.value ? (
              <Tooltip title={translate('Clear Date')} enterDelay={300}>
                <IconButton
                  disabled={disabled}
                  onClick={clearDate}
                  style={{
                    padding: 5,
                    right: 0,
                    width: 30,
                    height: 30,
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <CalendarTodayOutlined style={{ height: 16, fill: COLOR_PALETTE.darkGrey }} />
            ),
        }}
        {...options}
      />
    </>
  )
}

DateFormInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  defaultValue: PropTypes.object,
  inputVariant: PropTypes.string,
  style: PropTypes.object,
  parse: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hideClearButton: PropTypes.bool,
  onChange: PropTypes.func,
}

DateFormInput.defaultProps = {
  options: {},
}

export default DateFormInput
