import { orgSelectors } from 'ducks/orgs'
import useAdBanner from 'elements/ads/useAdBanner'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { ComponentTypes } from 'types/selectComponent'
import HardwareAdBannerContainer from './HardwareAdBannerContainer'
import CityPlumbingBanner from './banners/CityPlumbingBanner'
import HdmBanner from './banners/HdmBanner'
import SegenBanner from './banners/SegenBanner'

export const getShopResultsPageAdPlacement = ({
  location,
  searchText,
  componentType,
}: {
  location: 'top' | 'bottom'
  searchText: string
  componentType?: ComponentTypes
}): string => {
  if (componentType) {
    return `shop_category_${componentType}_${location}`
  } else if (searchText) {
    return `shop_search_${location}`
  }
  return ''
}

const ShopResultsPageTopBanner = ({
  searchText,
  componentType,
}: {
  searchText: string
  componentType?: ComponentTypes
}) => {
  const hardwareSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const adPlacementTop = getShopResultsPageAdPlacement({ location: 'top', searchText, componentType })
  const AdBanner = useAdBanner({
    formats: ['leaderboard'],
    placement: adPlacementTop,
    requiredWindowSize: { width: 960, height: 800 },
  })

  const OriginalBanner = useMemo(() => {
    if (hardwareSupplier === HardwareSupplierEnum.Segen) {
      return <SegenBanner renderOBPBanner={true} />
    }
    if (hardwareSupplier === HardwareSupplierEnum.CityPlumbing) {
      return <CityPlumbingBanner />
    }
    if (hardwareSupplier === HardwareSupplierEnum.Hdm) {
      return <HdmBanner />
    }
  }, [hardwareSupplier])

  if (AdBanner != null) {
    return <HardwareAdBannerContainer> {AdBanner} </HardwareAdBannerContainer>
  }

  if (OriginalBanner != null) {
    return OriginalBanner
  }
  return null
}

export default ShopResultsPageTopBanner
