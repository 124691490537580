import appStorage from 'storage/appStorage'

export const setAmplitudeIdentity = ({
  orgId,
  orgName,
  userId,
  roleId,
  userEmail,
  isStaff,
  isAnonymous,
  phone,
}: {
  orgId: number
  userId: number
  roleId: number
  orgName: string
  userEmail?: string
  phone?: string
  isStaff: boolean
  isAnonymous: boolean
}) => {
  try {
    // the user id in amplitude is used to identify the user across multiple sessions. If a user accesses our app before logging in, fires a bunch of events
    // which won't have a user id on them, then logs in and we set the user id, amplitude will automstically associate the earlier events with this user

    const isInternalUser = isStaff || userEmail?.endsWith('opensolar.com')
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    let partner = params.get('partner_source')
    if (!partner) {
      partner = sessionStorage.getItem('os_partner_source')
    }
    let campaign = params.get('campaign')
    if (!campaign) {
      campaign = sessionStorage.getItem('os_partner_campaign')
    }

    const amplitudeInstance = window.amplitude.getInstance()
    amplitudeInstance.setUserId(`${userId}`)
    const userProperties = {
      user_id: `${userId}`,
      org_name: orgName,
      org_id: `${orgId}`,
      is_anonymous: isAnonymous ? 'yes' : 'no',
      phone: `${phone}`,
      userEmail: `${userEmail}`,
      role_id: `${roleId}`,
      is_customer: 'no',
      internal_user: isInternalUser ? 'yes' : 'no',
      ...(partner && { referral_partner: partner }),
      ...(campaign && { referral_campaign: campaign }),
    }
    amplitudeInstance.setUserProperties(userProperties)

    // User groups not available for our current plan
    // The following user-groups kind of doing nothing, leave it for now until get approval for remove it.
    // https://www.docs.developers.amplitude.com/data/sdks/javascript/#user-groups

    amplitudeInstance.setGroup('org_id', `${orgId}`)
    amplitudeInstance.setGroup('user_id', `${userId}`)
    amplitudeInstance.setGroup('is_anonymous', isAnonymous ? 'yes' : 'no')
    amplitudeInstance.setGroup('role_id', `${roleId}`)
    amplitudeInstance.setGroup('is_customer', 'no')
    amplitudeInstance.setGroup('internal_user', isInternalUser ? 'yes' : 'no')
    if (partner) amplitudeInstance.setGroup('referral_partner', partner)
    if (campaign) amplitudeInstance.setGroup('referral_campaign', campaign)
  } catch (ex) {
    console.log('error setting amplitude user id', ex)
  }
}

const eventGroups: { [key: string]: string[] | string } = {}
export const setAmplitudeEventGroups = (groups: { [key: string]: string[] | string | number | undefined }) => {
  for (const i in groups) {
    let value = groups[i]
    if (typeof value === 'number') value = value.toString()
    if (typeof value === 'undefined') delete eventGroups[i]
    else eventGroups[i] = value
  }
}

export const setAmplitudeUserIdentityForCustomer = (orgName: string, isAnonymous: boolean, projectId: number) => {
  // we are no longer sending customer events to Amplitude
  // try {
  //   const amplitude = window.amplitude.getInstance()
  //   // treat all customer users under the same project as the same user from Amplitude's perspective
  //   // this is to 1.) limit user count and 2.) ensure that when a customer access multiple projects we don't
  //   // consolidate their events
  //   amplitude.setUserId(`${projectId}-C`)
  //   amplitude.setGroup('is_customer', 'yes')
  //   amplitude.setGroup('internal_user', 'no')
  //   if (orgName) amplitude.setGroup('org_name', orgName)
  //   amplitude.setGroup('is_anonymous', isAnonymous ? 'yes' : 'no')
  // } catch (ex) {
  //   console.log('error setting amplitude user id for customer', ex)
  // }
}

// though the free tier is big we need to make sure we don't have run away events so if we have 5 events of the same type within 0.5 seconds we won't fire another
const getEventUnderRateLimit = (eventId: string, forceLimitToOne?: boolean): boolean => {
  const recentEventQueueString: string = appStorage.getString('amplitude_event_queue') || ''
  const recentEventQueue = recentEventQueueString.split(',') || []
  if (recentEventQueue && recentEventQueue.length > 0) {
    let recentInstancesOfThisEvent = 0
    const now = new Date()
    recentEventQueue.forEach((eventRecord: string) => {
      if (!eventRecord || !eventRecord.includes('@')) return
      const recoredEventId = eventRecord.substring(0, eventRecord.indexOf('@'))
      if (!recoredEventId || recoredEventId !== eventId) return
      const recordedEventTime = new Date(eventRecord.substring(eventRecord.indexOf('@') + 1))
      const diffSeconds = (now.getTime() - recordedEventTime.getTime()) / 1000
      if (diffSeconds < 0.5) recentInstancesOfThisEvent++
    })
    if (forceLimitToOne && recentInstancesOfThisEvent > 0) {
      console.log('forceLimitToOne RATE LIMITING AMPLITUDE EVENT', eventId)
      return false
    }
    if (recentInstancesOfThisEvent >= 5) {
      console.log('RATE LIMITING AMPLITUDE EVENT', eventId)
      return false
    }
  }
  return true
}

// after firing update local storage with the event
const addEventToQueue = (eventId: string): void => {
  const recentEventQueueString: string = appStorage.getString('amplitude_event_queue') || ''
  const recentEventQueue = recentEventQueueString.split(',') || []
  const queueTag = `${eventId}@${new Date()}`
  let newQueue: string = ''
  if (recentEventQueue.length > 10) {
    recentEventQueue.pop()
    newQueue = `${queueTag},${recentEventQueue.reduce((tag: string, acc: string) => (acc += tag))}`
  } else {
    newQueue = `${queueTag},${recentEventQueueString}`
  }
  appStorage.setString('amplitude_event_queue', newQueue)
}

interface EventPayloadType {
  groups?: { [key: string]: string[] | string }
}

type EventPayloadTypeDynamic = EventPayloadType & {
  [key: string]: string | string[] | number | boolean | null | undefined
}

export type EventIdType = keyof typeof eventMap

export const logAmplitudeEvent = (
  eventId: EventIdType,
  eventPayload?: EventPayloadTypeDynamic,
  forceLimitToOne = false
): boolean => {
  if (!eventId || !eventMap[eventId]) {
    console.log('invalid amplitude event id', eventId)
    return false
  }
  const orgInRedux = window.reduxStore?.getState()?.orgs?.org
  if (!orgInRedux) {
    // if we do not have an org saved, this is either us trying to log an event for an end customer or for a user that is not logged in. Both of which we don't want to do
    return false
  }
  try {
    const amplitude = window.amplitude.getInstance()
    if (getEventUnderRateLimit(eventId, forceLimitToOne)) {
      const eventName: string = eventMap[eventId]
      const payload = eventPayload
        ? { ...eventPayload, groups: { ...eventGroups, ...eventPayload.groups } }
        : { groups: { ...eventGroups } }
      amplitude.logEvent(eventName, payload)
      addEventToQueue(eventId)
      if (window.location?.href?.includes('localhost:3000') || window.debugAmplitude) {
        console.log('LOGGING AMPLITUDE EVENT --->', eventName, payload)
      }
      return true
    } else {
      console.log('AMPLITUDE EVENT RATE LIMITED --->', eventId, eventMap[eventId], eventPayload)
    }
  } catch (ex) {
    console.log('Amplitude Exception', ex)
  }
  return false
}

// Todo: add strong type check for generic event
const eventMap = {
  page_viewed: 'Page Viewed',
  //  Required field: source, context
  generic_button_clicked: 'Generic Button Clicked',
  //  Required field: source, context
  generic_element_viewed: 'Generic Element Viewed',
  //  Required field: action, source
  generic_tooltip_interacted: 'Generic Tooltip Interacted',
  generic_confirmation_interacted: 'Generic Confirmation Interacted',
  generic_filter_interacted: 'Generic Filter Interacted',
  //  Required field: action, source
  generic_dropdown_interacted: 'Generic Dropdown Interacted',
  //  Required field: action, source
  generic_toggle_interacted: 'Generic Tab Interacted',
  ad_click: 'Ad Click',
  ad_impression: 'Ad Impression',
  sso_flow_interacted: 'SSO Flow Interacted',
  docusign_disconnected: 'Docusign Account Disconnected',
  docusign_oauth_started: 'Docusign Oauth Started',
  docusign_account_connected: 'Docusign Account Connected',
  docusign_account_connection_error: 'Docusign Account Connection Error',
  greenlancer_cta_clicked: 'GreenLancer CTA Clicked',
  greenlancer_account_created: 'GreenLancer Account Created',
  greenlancer_account_connected: 'GreenLancer Account Connected',
  greenlancer_account_connection_error: 'GreenLancer Account Connection Error',
  greenlancer_account_form_invalid: 'GreenLancer Account Form Invalid',
  greenlancer_project_created: 'GreenLancer Project Created',
  greenlancer_view_project_clicked: 'GreenLancer View Project Clicked',
  greenlancer_account_connection_lost: 'GreenLancer Account Connection Lost',
  greenlancer_project_creation_error: 'GreenLancer Project Creation Error',
  docusign_contract_initiated: 'Docusign Contract Initiated',
  docusign_envelope_id_error: 'Docusign Envelope Id Error',
  docusign_envelope_url_error: 'Docusign Envelope URL Error',
  docusign_contract_signed: 'Docusign Contract Signed',
  docusign_contract_reset: 'Docusign Contract Reset',
  docusign_contract_reset_error: 'Docusign Contract Reset Error',
  docusign_preview_viewed: 'Docusign Preview Viewed',
  docusign_preview_error: 'Docusign Preview Error',
  payment_option_in_opened_proposal: 'Payment Option in Opened Proposal', //payment type, index
  payment_option_visible: 'Payment Option Visible',
  payment_option_selected: 'Payment Option Selected',
  transaction_started: 'MyEnergy Transaction Started',
  transaction_completed: 'MyEnergy Transaction Completed',
  registration_form_submitted: 'Registration Form Submitted',
  login_success: 'Login Success',
  login_fail: 'Login Fail',
  sunglight_quick_create_cta_clicked: 'Sunlight Quick Create CTA Clicked',
  ux2_enabled: 'UX2 Enabled',
  ux2_disabled: 'UX2 Disabled',
  oslite_enabled: 'OSLite Enabled',
  oslite_disabled: 'OSLite Disabled',
  integrated_finance_products_refreshed: 'Integrated Finance Products Refreshed',
  integrated_finance_products_refresh_error: 'Integrated Finance Products Refresh Error',
  integrated_finance_application_opened: 'Integrated Finance Application Opened',
  integrated_finance_application_open_error: 'Integrated Finance Application Open Error',
  integrated_finance_application_form_error: 'Integrated Finance Application Form Error',
  integrated_finance_application_submit_attempt: 'Integrated Finance Application Submit Attempt',
  integrated_finance_application_submit_error: 'Integrated Finance Application Submit Error',
  integrated_finance_application_submitted: 'Integrated Finance Application Submitted',
  send_loan_application_dialog_opened: 'Send Loan Application Dialog Opened',
  send_loan_agreement_dialog_opened: 'Send Loan Agreement Dialog Opened',
  integrated_finance_loan_agreement_shown: 'Integrated Finance Loan Agreement Shown',
  integrated_finance_loan_agreement_started: 'Integrated Finance Loan Agreement Started',
  integrated_finance_loan_agreement_ready: 'Integrated Finance Loan Agreement Ready',
  integrated_finance_loan_agreement_error: 'Integrated Finance Loan Agreement Error',
  integrated_finance_loan_agreement_sent: 'Integrated Finance Loan Agreement Sent',
  integrated_finance_application_sent: 'Integrated Finance Application Sent',
  integrated_finance_application_error: 'Integrated Finance Application Error',
  integrated_finance_application_blocked: 'Integrated Finance Application Blocked',
  integrated_finance_onboarding_dialog_opened: 'Integrated Finance Onboarding Dialog Opened',
  integrated_finance_onboarding_dialog_submitted: 'Integrated Finance Onboarding Dialog Submitted',
  integrated_finance_onboarding_dialog_error: 'Integrated Finance Onboarding Dialog Error',
  integrated_finance_onboarding_user_redirected: 'Integrated Finance Onboarding User Redirected',
  loanpal_account_dialog_opened: 'Loanpal Account Dialog Opened',
  loanpal_account_dialog_error: 'Loanpal Account Dialog Error',
  loanpal_account_connected: 'Loanpal Account Connected',
  loanpal_account_disconnected: 'Loanpal Account Disconnected',
  mosaic_account_dialog_opened: 'Mosaic Account Dialog Opened',
  mosaic_account_dialog_error: 'Mosaic Account Dialog Error',
  mosaic_account_connected: 'Mosaic Account Connected',
  mosaic_account_disconnected: 'Mosaic Account Disconnected',
  mosaic_users_synced: 'Mosaic Users Synced',
  mosaic_users_sync_error: 'Mosaic Users Sync Error',
  mosaic_ssn_flag_disabled: 'Mosaic 4 Digit SSN Flag Disabled',
  sungage_account_dialog_opened: 'Sungage Account Dialog Opened',
  sungage_account_dialog_error: 'Sungage Account Dialog Error',
  sungage_account_connected: 'Sungage Account Connected',
  sungage_account_disconnected: 'Sungage Account Disconnected',
  send_stip_link_clicked: 'Send Stip Link Button Clicked',
  send_stip_link_sent: 'Send Stip Link Button Sent',
  send_stip_link_error: 'Send Stip Link Error',
  stip_upload_form_viewed: 'Stip Upload Form Viewed',
  stip_upload_form_viewed_again: 'Stip Upload Form Viewed Again',
  stip_doc_type_chosen: 'Stip Doc Type Chosen',
  stip_upload_success: 'Stip Upload Success',
  stip_upload_fail: 'Stip Upload Failure',
  credit_app_blocked: 'Credit App Blocked',
  reapp_started_after_credit_block: 'Re-Application Started After Credit App Blocked',
  brighte_connect_account_dialog_opened: 'Brighte Connect Account Dialog Opened',
  brighte_oauth_started: 'Brighte Oauth Started',
  brighte_account_connected: 'Brighte Account Connected',
  brighte_account_disconnected: 'Brighte Account Disconnected',
  brighte_oauth_error: 'Brighte Oauth Error',
  brighte_error_prompt_connect_attempt: 'Brighte Error Propmt Connect Attempt',
  commonbond_account_disconnected: 'CommonBond Account Disconnected',
  dividend_account_connected: 'Dividend Account Connected',
  dividend_account_disconnected: 'Dividend Account Disconnected',
  partner_org_linking_interacted: 'Partner Org Linking Interacted',
  partner_account_pairing_interacted: 'Partner Account Pairing Interacted',
  ironridge_bom_saved: 'IronRidge BOM Saved',
  ironridge_bom_form_opened: 'IronRidge BOM Form Opened',
  ironridge_account_form_opened: 'IronRidge Account Form Opened',
  ironridge_bom_retreived: 'IronRidge BOM Fetched',
  ironridge_bom_fetch_error: 'IronRidge BOM Fetch Error',
  ironridge_email_not_found: 'IronRidge Email Not Found',
  ironridge_connected_no_permissions: 'Ironridge User Connected - No Permissions',
  ironridge_connected: 'Ironridge User Connected',
  ironridge_no_account_clicked: 'Ironridge No Existing User Clicked',
  ironridge_panels_not_found: 'IronRidge Panels Not Found',
  ironridge_panels_found: 'IronRidge Panels Found',
  ironridge_multi_slope_blocker: 'IronRidge Multi-Slope Blocker',
  ironridge_min_slope_blocker: 'IronRidge Minimum Slope Blocker',
  ironridge_max_slope_blocker: 'IronRidge Maximum Slope Blocker',
  ironridge_bom_download_dialog_opened: 'IronRidge BOM Download Dialog Opened',
  ironridge_bom_download_dialog_refresh_warning: 'IronRidge BOM Download Refresh Warning Shown',
  ironridge_bom_downloaded: 'IronRidge BOM Downloaded',
  ironridge_bom_download_error: 'IronRidge BOM Download Error',
  global_bom_download_dialog_opened: 'Global BOM Download Dialog Opened',
  global_bom_download_dialog_refresh_warning: 'Global BOM Download Refresh Warning Shown',
  global_bom_downloaded: 'Global BOM Downloaded',
  global_bom_download_error: 'Global BOM Download Error',
  ironridge_project_disconnected: 'IronRidge Project Disconnected',
  ironridge_project_data_not_found: 'IronRidge Project Data Not Found',
  fuji_tour_started: 'FUJI Tour Started',
  fuji_tour_started_on_mobile: 'FUJI Tour Started on Mobile',
  fuji_confirm_cancel_dialog_shown: 'FUJI Confirm Cancel Dialog Shown',
  fuji_tour_step: 'FUJI Tour Step',
  fuji_tour_cancelled: 'FUJI Tour Cancelled',
  fuji_banner_hidden: 'FUJI Banner Hidden by User',
  fuji_banner_shown: 'FUJI Banner Shown',
  fuji_tour_started_from_help: 'FUJI Tour Started From Help',
  proposal_emailed: 'Proposal Emailed',
  proposal_pdf_generated: 'Proposal PDF Generated',
  proposal_qr_code_scanned: 'Proposal QR Code Scanned',
  placeholer_dropped: 'Placeholder Field Dropped',
  placeholder_path_copied: 'Placeholder Path Copied',
  document_pdf_mode_chosen: 'Document Template PDF Mode Chosen',
  document_html_mode_chosen: 'Document Template HTML Mode Chosen',
  contract_pdf_mode_chosen: 'Contract Template PDF Mode Chosen',
  contract_html_mode_chosen: 'Contract Template HTML Mode Chosen',
  sunlight_apply_dealer_fee_shown: 'Sunlight Apply Dealer Fee Shown',
  sunlight_apply_dealer_fee_enabled: 'Sunlight Apply Dealer Fee Enabled',
  sunlight_apply_dealer_fee_disabled: 'Sunlight Apply Dealer Fee Disabled',
  powerswitch_example_hidden: 'PowerSwitch Zero Example System Hidden',
  in_app_promo_shown: 'In-App Promo Shown',
  in_app_promo_dismissed: 'In-App Promo Dismissed',
  in_app_promo_accepted: 'In-App Promo Accepted',
  partner_registration_form_viewed: 'Partner Registration Form Viewed',
  global_wallet_transaction_created: 'Global Wallet Transaction Created',
  wallet_page_mode_changed: 'Wallet Page Mode Changed',
  wallet_page_viewed: 'Wallet Page Viewed',
  manual_add_funds_attempted: 'Manual Add Funds Attempted',
  manual_add_funds_success: 'Manual Add Funds Success',
  top_up_settings_change_attempted: 'Top-Up Settings Change Attempted',
  top_up_settings_change_success: 'Top-Up Settings Change Success',
  wallet_save_error: 'Wallet Save Error',
  wallet_card_added: 'Wallet Card Added',
  wallet_card_error: 'Wallet Card Error',
  premium_product_enabled: 'Premium Product Enabled',
  premium_product_disabled: 'Premium Product Disabled',
  premium_product_change_error: 'Premium Product Change Error',
  premium_imagery_warning_shown: 'Premium Imargery Warning Shown',
  nearmap_explore_banner_shown: 'Nearmap Explore Banner Shown',
  premium_imagery_added: 'Premium Imagery Added',
  premium_imagery_add_error: 'Premium Imagery Add Error',
  integrated_racking_dialog_opened: 'Integrated Racking Dialog Opened',
  integrated_racking_dialog_dismissed: 'Integrated Racking Dialog Dismissed',
  integrated_racking_dialog_submitted: 'Integrated Racking Dialog Submitted',
  integrated_racking_dialog_error: 'Integrated Racking Dialog Error',
  integrated_racking_BOM_Saved: 'Integrated Racking BOM Saved',
  bulk_update_page_viewed: 'Bulk Update Page Viewed',
  bulk_update_project_selected: 'Bulk Update Project Selected',
  bulk_update_projects_previewed: 'Bulk Update Projects Previewed',
  bulk_update_projects_preview_error: 'Bulk Update Projects Preview Error',
  bulk_update_projects_saved: 'Bulk Update Projects Saved',
  bulk_update_projects_save_error: 'Bulk Update Projects Save Error',
  bulk_update_old_pmt_chosen: 'Bulk Update Old Payment Option Chosen',
  bulk_update_new_pmt_chosen: 'Bulk Update New Payment Option Chosen',
  project_deleted: 'Project Deleted',
  project_lite_upgrade: 'Lite Project Upgraded',
  project_saved: 'Project Saved',
  uk_racking_item_imported: 'UK Racking Item Imported',
  connect_segen_attempted: 'Connect Segen Attempted',
  segen_account_connected: 'Segen Account Connected',
  disconnect_segen_clicked: 'Disconnect Segen Clicked',
  disconnect_city_plumbing_clicked: 'Disconnect City Plumbing Clicked',
  segen_create_quote_clicked: 'Segen Create Quote Clicked',
  outfitter_create_quote_clicked: 'Outfitter Create Quote Clicked',
  segen_quote_error: 'Segen Quote Error',
  reapplication_confirm_dialog_shown: 'Reapplication Confirmation Dialog Shown',
  reapplication_confirm_dismissed: 'Reapplication Confirmatin Dismissed',
  reapplication_confirmed: 'Reapplication Confirmed',
  vimeo_video_player_interacted: 'Vimeo Video Player Interacted',
  premium_product_terms_dismissed: 'Premium Product Terms Dismissed',
  premium_product_terms_agreed: 'Premium Product Terms Agreed',
  home_academy_button_clicked: 'Home Page Academy Button Clicked',
  course_page_viewed: 'Course Page Viewed',
  course_button_clicked: 'Course Button Clicked',
  returned_from_course: 'User Returned From Course',
  dividend_connect_role_dialog_shown: 'Dividend Connect Role Dialog Shown',
  dividend_role_connected: 'Dividend Role Connected',
  dividend_role_disconnected: 'Dividend Role Disconnected',
  dividend_connect_role_error: 'Dividend Connect Role Error',
  dividend_disconnect_role_error: 'Dividend Disconnect Role Error',
  mfa_phone_page_viewed: 'MFA Phone Page Viewed',
  mfa_sms_code_sent: 'MFA SMS Code Sent',
  mfa_sms_code_resent: 'MFA SMS Code Resent',
  mfa_sms_resend_error: 'MFA SMS Resend Error',
  mfa_phone_setup_already_active: 'MFA Phone Setup Number Already Active',
  mfa_phone_setup_error: 'MFA Phone Setup Error',
  mfa_sms_code_page_viewed: 'MFA SMS Code Page Viewed',
  mfa_sms_code_success: 'MFA SMS Code Success',
  mfa_sms_code_error: 'MFA SMS Code Error',
  mfa_totp_code_success: 'MFA TOTP Code Success',
  mfa_totp_code_error: 'MFA TOTP Code Error',
  mfa_recovery_codes_generated: 'MFA Recovery Codes Generated',
  mfa_recovery_codes_downloaded: 'MFA Recovery Codes Downloaded',
  mfa_required_upon_login: 'MFA Required upon Login',
  mfa_setup_required_on_login: 'MFA Setup Required on Login',
  mfa_code_rejected: 'MFA Code Rejected',
  mfa_clear_error: 'MFA Clear Devices Error',
  initiate_application_cta_clicked: 'Initiate Finance CTA Clicked',
  finance_application_initiated_as_pro: 'Finance Application Initiated as Pro',
  au_send_integrated_credit_app_clicked: 'AU Send Integrated Credit App Clicked',
  integrated_finance_quotation_table_customized: 'Integrated Finance Quotation Table Customized',
  design_error_prompt_shown: 'Design Error Prompt Shown',
  rate_change_management_saved: 'Rate Change Preferences Saved',
  archived_payment_option_replaced: 'Archived Payment Option(s) Replaced',
  link_sharing_enabled: 'Proposal Link Sharing Enabled',
  link_sharing_disabled: 'Proposal Link Sharing Disabled',
  finance_change_order_submitted: 'Finance Change Order Submitted',
  finance_change_order_submitted_no_update: 'Finance Change Order Submitted Without Update',
  finance_change_order_error: 'Finance Change Order Error',
  application_automatically_opened: 'Application Automatically Opened',
  phoenix_account_connected: 'Phoenix Account Connected',
  phoenix_account_disconnected: 'Phoenix Account Disconnected',
  integrated_finance_calculator_opened: 'Integrated Finance Calculator Opened',
  phoenix_application_validation_submitted: 'Phoenix Application Validation Submitted',
  phoenix_application_validation_form_error: 'Phoenix Application Validation Form Error',
  phoenix_application_validation_unhandled_error: 'Phoenix Application Validation Unhandled Error',
  phoenix_contact_verification_opened: 'Phoenix Contact Verification Opened',
  phoenix_role_prompt_shown: 'Phoenix Role Prompt Shown',
  phoenix_role_connected: 'Phoenix Role Connected',
  phoenix_new_user_redirected: 'Phoenix New User Redirected',
  deep_green_cta_shown: 'Deep Green CTA Shown',
  deep_green_cta_clicked: 'Deep Green CTA Clicked',
  eagleview_order_submit: 'EagleView Order Submit',
  eagleview_order_success: 'EagleView Order Success',
  eagleview_order_error: 'EagleView Order Error',
  unsaved_data_discarded: 'Unsaved Data Discarded',
  unsaved_data_recovered: 'Unsaved Data Recovered',
  payment_option_comparison_opened: 'Payment Option Comparison Opened',
  apply_now_prompt_dismissed: 'Apply Now Prompt Dismissed',
  apply_now_prompt_clicked: 'Apply Now Prompt Clicked',
  apply_now_prompt_row_expanded: 'Apply Now Prompt Row Expanded',
  app_opened_from_prompt: 'Finance Application Opened from Prompt',
  exhibit_custom_tile_mount: 'Exhibit Custom Tile Mount',
  exhibit_custom_tile_interaction: 'Exhibit Custom Tile Interaction',
  custom_form_opened: 'Custom Form Opened',
  nem3_duplicate_system: 'NEM3 Duplicate System',
  finance_qr_code_enabled_in_pdf: 'Finance QR Code Enabled in PDF',
  finance_qr_code_disabled_in_pdf: 'Finance QR Code Disabled in PDF',
  finance_qr_code_permanently_hidden: 'Finance QR Code Permanently Hidden',
  stripe_attempted: 'Stripe Payment Attempted',
  stripe_success: 'Stripe Payment Successful',
  stripe_error: 'Stripe Payment Error',
  org_connection_created: 'Organization Connection Created',
  org_connection_accepted: 'Organization Connection Accepted',
  org_connection_rejected: 'Organization Connection Rejected',
  org_connection_deleted: 'Organization Connection Deleted',
  cogs_refresh_notification_interacted: 'COGS Refresh Notification Interacted',
  project_shared: 'Project Shared',
  project_unshared: 'Project Unshared',
  project_list_select_dropdown: 'Project List Select Dropdown',
  integrated_finance_account_dialog_opened: 'Integrated Finance Account Dialog Opened',
  integrated_finance_account_connected: 'Integrated Finance Account Connected',
  integrated_finance_account_disconnected: 'Integrated Finance Account Disonnected',
  integrated_finance_account_connection_error: 'Integrated Finance Account Connection Error',
  rate_change_reminder_shown: 'Rate Change Reminder Shown',
  rate_change_reminder_clicked: 'Rate Change Reminder Clicked',
  solkunder_used_inactive: 'Solkunder Used Inactive',
  solkunder_used_active: 'Solkunder Used Active',
  solkunder_on_info_icon: 'Solkunder On Info Icon',
  pagination_interacted: 'Pagination Interacted',
  on_lead_capture_page: 'On Lead Capture Page',
  on_lead_capture_create: 'On Lead Capture Create',
  solkunder_on_lead_capture_create: 'Solkunder On Lead Capture Create',
  solkunder_link_to_clicked: 'Solkunder Link To Clicked',
  solkunder_code_block_section_clicked: 'Solkunder Code Block Section Clicked',
  payment_option_pricing_override_dialog_opened: 'Payment Option Pricing Override Dialog Opened',
  down_payment_overridden: 'Down Payment Overridden',
  payment_option_manually_marked_sold: 'Payment Option Manually Marked as Sold',
  non_integrated_finance_product_saved: 'Non-Integrated Finance Payment Option Saved',
  finance_proposal_dialog_cta_shown: 'Finance Proposal Dialog CTA Shown',
  finance_proposal_dialog_cta_opened: 'Finance Proposal Dialog CTA Opened',
  finance_proposal_dialog_cta_submitted: 'Finance Proposal Dialog CTA Submitted',
  buy_hardware_button_clicked: 'Buy Hardware Button Clicked',
  hardware_banner_register_click: 'Register with Hardware Supplier CTA Clicked',
  hardware_replace_item_cta_clicked: 'Hardware Replace Item CTA Clicked',
  hardware_replace_item_flow: 'Hardware Replace Item Flow',
  hardware_navigation_button: 'Hardware Navigation Button',
  hardware_search_bar: 'Hardware Search Bar',
  hardware_search_autocomplete_input: 'Hardware Search Autocomplete Input',
  hardware_view_more_switch_button: 'Hardware View More Switch Button',
  hardware_subheader_clicked: 'Hardware Subheader Clicked',
  hardware_sidebar_navigation_button_clicked: 'Hardware Sidebar Navigation Button Clicked',
  hardware_sidebar_navigation_submenu_clicked: 'Hardware Sidebar Navigation Sub Menu Button Clicked',
  hardware_distributer_support_element: 'Hardware Distributer Support Element',
  hardware_cart_button_clicked: 'Hardware Cart Button Clicked',
  hardware_distributor_connect_button_clicked: 'Hardware Distributor Connect Button Clicked',
  hardware_select_filters: 'Hardware Select Filters',
  hardware_catalog_item_clicked: 'Hardware Catalog Item Clicked',
  hardware_collection_interacted: 'Hardware Collection Interacted',
  hardware_distributor_connection_completed: 'Hardware Distributor Connection Completed',
  hardware_project_checkbox_toggled: 'Hardware Project Checkbox Toggled',
  hardware_view_bom_button_clicked: 'Hardware View BOM Button Clicked',
  hardware_project_system_dropdown: 'Hardware Project System Dropdown',
  hardware_quantity_field_updated: 'Hardware Quantity Field Updated',
  hardware_quantity_field_selector: 'Hardware Quantity Field Selector',
  hardware_placed_order_link_clicked: 'Hardware Placed Order Link Clicked',
  hardware_detail_document_button: 'Hardware Detail Document Button',
  hardware_order_table_delete_button_clicked: 'Hardware Order Table Delete Button Clicked',
  hardware_add_to_cart_button_clicked: 'Hardware Add To Cart Button Clicked',
  hardware_unavailable_items_warning: 'Hardware Unavailable Items Warning',
  hardware_outOfStock_items_warning: 'Hardware Out Of Stock Items Warning',
  hardware_place_order_button: 'Hardware Place Order Button',
  hardware_project_redirect: 'Hardware Project Redirect',
  hardware_segen_service_failed: 'Hardware Segen Service Failed',
  hardware_hdm_service_failed: 'Hardware Hdm Service Failed',
  hardware_vtac_service_failed: 'Hardware V-Tac Service Failed',
  hardware_order_placed_success: 'Hardware Order Placed Success',
  hardware_review_order_cta_clicked: 'Hardware Review Order CTA Clicked',
  hardware_place_order_cta_clicked: 'Hardware Place Order CTA Clicked',
  hardware_check_design_stock_availability: 'Hardware Check Design Stock Availability',
  hardware_segen_shipping: 'Hardware Segen Shipping',
  hardware_segen_checkout: 'Hardware Segen Checkout',
  hardware_segen_post_checkout: 'Hardware Segen Post Checkout',
  hardware_segen_create_order: 'Hardware Segen Create Order',
  hardware_solar_outlet_create_order: 'Hardware Solar Outlet Create Order',
  hardware_order_promo_button_clicked: 'Hardware Order Promo Button Clicked',
  hardware_hdm_post_checkout: 'Hardware HDM Post Checkout',
  hardware_vtac_post_checkout: 'Hardware V-Tac Post Checkout',
  control_crud_action: 'CRUD Action Event',
  tariff_warning_shown: 'Tariff Warning Shown',
  tariff_input_switch: 'Tariff Input Type Toggled',
  select_tariff_clicked: 'Select Tariff Button Clicked',
  create_tariff_clicked: 'Create New Tariff Button Clicked',
  save_new_tariff_toggle: '"Save to My Utility Tariffs" Toggled',
  report_missing_tariff_clicked: 'Report Missing Tariff Button Clicked',
  select_tariff_dialog_filters: 'Select Tariff Dialog: Search Filters Used',
  report_incorrect_tariff_clicked: 'Report Incorrect Tariff Button Clicked',
  payments_tour_started: 'Payments Tour Started',
  payments_tour_cancelled: 'Payments Tour Cancelled',
  payments_page_viewed: 'Payments Page Viewed',
  payments_page_design_a_system_clicked: 'Payments Page Design a System Button Clicked',
  payments_page_make_system_visible: 'Payments Page System Set as Visible',
  payments_page_make_system_hidden: 'Payments Page System Set as Hidden',
  payments_page_system_reordered: 'Payments Page System Reordered',
  payments_page_card_deleted: 'Payments Page Card Deleted',
  payments_page_card_reordered: 'Payments Page Card Reordered',
  payment_ghost_card_dismissed: 'Payment Ghost Card Dismissed',
  payment_ghost_card_shown: 'Payment Ghost Card Shown',
  upfront_payment_edited_in_card: 'Upfront Payment Edited in Card',
  payments_page_system_rendered: 'Payments Page System Rendered',
  payments_page_system_visible: 'Payments Page System Visible',
  payments_page_cards_rendered_count: 'Payments Page Card Rendered Count',
  payment_option_dialog_opened: 'Payment Option Dialog Opened',
  payment_option_error_shown: 'Payment Option Card - Error Shown',
  payments_page_see_details_clicked: 'Payments Page See Details Clicked',
  payments_page_launch_application_clicked: 'Payments Page Launch Application Clicked',
  payments_page_email_application_clicked: 'Payments Page Email Application Clicked',
  payments_page_promo_banner_shown: 'Payments Page Promo Banner Shown',
  payments_page_promo_dialog_opened: 'Payments Page Promo Dialog Opened',
  integration_included_in_payments_promo_banner: 'Integration shown in Payments Promo Banner',
  integration_included_in_payments_promo_dialog: 'Integration shown in Payments Promo Dialog',
  integration_clicked_in_payments_promo_dialog: 'Integration Clicked in Payments Promo Dialog',
  payment_option_explorer_query_submitted: 'Payment Option Explorer Query Submitted',
  payment_option_explorer_error: 'Payment Options Explorer Error',
  payment_option_explorer_row_shown: 'Payment Option Explorer Row Shown',
  payment_option_explorer_row_selected: 'Payment Option Explorer Row Selected',
  payments_page_calculation_result: 'Payments Page Calculation Result',
  payments_page_calcuation_mismatch: 'Payments Page Calculation Mismatch',
  payments_page_explorer_error: 'Payments Page Explorer Error',
  psp_onboarding_page_viewed: 'PSP Onboarding Page Viewed',
  psp_onboarding_add_contact_clicked: 'PSP Onboarding Add Contact Clicked',
  psp_onboarding_application_submitted: 'PSP Onboarding Application Submitted',
  psp_onboarding_decision_shown: 'PSP Onboarding Decision Shown',
  psp_onboarding_submit_error: 'PSP Onboarding Submit Error',
  psp_onboarding_next_page_error: 'PSP Onboarding Next Page Error',
  psp_onboarding_non_admin_form_viewed: 'PSP Onboarding Non-Admin Form Viewed',
  psp_onboarding_non_admin_form_submitted: 'PSP Onboarding Non-Admin Form Submitted',
  cashflow_milestone_configuration_saved: 'CashFlow Milestone Configuration Saved',
  cashflow_edit_invoice_details_launched: 'CashFlow Edit Invoice Details Launched',
  cashflow_adjust_payments_dialog_launched: 'CashFlow Adjust Payments Dialog Launched',
  cashflow_standalone_invoice_dialog_sent: 'CashFlow Standalone Invioce Dialog Sent',
  cashflow_invoice_emailed: 'CashFlow Invoice Emailed',
  cashflow_invoice_launched: 'CashFlow Invoice Launched',
  cashflow_invoice_cancelled: 'CashFlow Invoice Cancelled',
  cashflow_invoice_marked_paid_offline: 'CashFlow Invoice Marked Paid Offline',
  cashflow_invoice_reminder_sent: 'CashFlow Invoice Reminder Sent',
  cashflow_payment_method_rendered: 'CashFlow Payment Method Rendered',
  cashflow_payment_method_viewed: 'CashFlow Payment Method Viewed',
  cashflow_payment_method_selected: 'CashFlow Payment Method Selected',
  cashflow_payment_method_error: 'CashFlow Payment Method Error',
  cashflow_payment_authrized: 'CashFlow Payment Authorized',
  cashflow_payment_form_viewed: 'CashFlow Payment Form Viewed',
  cashflow_adjust_payment_update_total_price: 'CashFlow Adjust Payments - Update Total Price',
  cashflow_adjust_payment_update_individual_payment: 'CashFlow Adjust Payments - Update Individual Payment',
  cashflow_adjust_payment_milestone_added: 'CashFlow Adjust Payments - Milestone Added',
  cashflow_adjust_payment_milestone_moved: 'CashFlow Adjust Payments - Milestone Moved',
  cashflow_adjust_payment_milestone_removed: 'CashFlow Adjust Payments - Milestone Removed',
  cashflow_adjust_payments_reset_clicked: 'CashFlow Adjust Payments - Reset Clicked',
  cashflow_adjust_payments_changes_saved: 'CashFlow Adjust Payments - Changes Saved',
  cashflow_standalone_invoice_dialog_opened: 'CashFlow Standalone Invoice Dialog Opened',
  cashflow_report_downloaded: 'Cashflow Report Downloaded',
  selected_native_mounting_system: 'Selected Native System Mounting',

  // battery design assistant
  battery_tab_add_battery_clicked: 'Add Battery Clicked',
  bda_close_icon_clicked: 'BDA Close Icon Clicked',
  bda_auto_battery_option_card_selected: 'BDA Auto-Battery Option Card Selected',
  bda_from_list_option_card_selected: 'BDA From List Option Card Selected',
  bda_maximise_savings_option_card_selected: 'BDA Maxmise Savings Option Card Selected',
  bda_self_consumption_option_card_selected: 'BDA Self Consumption Option Card Selected',
  bda_peak_demand_option_card_selected: 'BDA Peak Demand Option Card Selected',
  bda_next_button_clicked: 'BDA Next Button Clicked',
  bda_appliance_tab_clicked: 'BDA Appliance Tab Clicked',
  bda_manual_tab_clicked: 'BDA Manual Tab Clicked',
  bda_hours_of_backup_text_input_selected: 'BDA Hours of Backup Text Input Selected',
  bda_hours_of_backup_text_input_recorded: 'BDA Hours of Backup Text Input Recorded',
  bda_total_continuous_power_text_input_selected: 'BDA Total Continuous Power Text Input Selected',
  bda_total_continuous_power_text_input_recorded: 'BDA Total Continuous Power Text Input Recorded',
  bda_peak_power_text_input_selected: 'BDA Peak Power Text Input Selected',
  bda_peak_power_text_input_recorded: 'BDA Peak Power Text Input Recorded',
  bda_lights_toggle_on: 'BDA Lights Toggle On',
  bda_lights_toggle_off: 'BDA Lights Toggle Off',
  bda_light_total_continuous_power_text_input_selected: 'BDA Light Total Continuous Power Text Input Selected',
  bda_light_total_continuous_power_text_input_recorded: 'BDA Light Total Continuous Power Text Input Recorded',
  bda_light_peak_power_text_input_selected: 'BDA Light Peak Power Text Input Selected',
  bda_light_peak_power_text_input_recorded: 'BDA Light Peak Power Text Input Recorded',
  bda_light_quantity_text_input_selected: 'BDA Light Quantity Text Input Selected',
  bda_light_quantity_text_input_recorded: 'BDA Light Quantity Text Input Recorded',
  bda_refrigerators_toggle_on: 'BDA Refrigerators Toggle On',
  bda_refrigerators_toggle_off: 'BDA Refrigerators Toggle Off',
  bda_refrigerator_total_continuous_power_text_input_selected:
    'BDA Refrigerator Total Continuous Power Text Input Selected',
  bda_refrigerator_total_continuous_power_text_input_recorded:
    'BDA Refrigerator Total Continuous Power Text Input Recorded',
  bda_refrigerator_peak_power_text_input_selected: 'BDA Refrigerator Peak Power Text Input Selected',
  bda_refrigerator_peak_power_text_input_recorded: 'BDA Refrigerator Peak Power Text Input Recorded',
  bda_refrigerator_quantity_text_input_selected: 'BDA Refrigerator Quantity Text Input Selected',
  bda_refrigerator_quantity_text_input_recorded: 'BDA Refrigerator Quantity Text Input Recorded',
  bda_freezer_toggle_on: 'BDA Freezer Toggle On',
  bda_freezer_toggle_off: 'BDA Freezer Toggle Off',
  bda_freezer_total_continuous_power_text_input_selected: 'BDA Freezer Total Continuous Power Text Input Selected',
  bda_freezer_total_continuous_power_text_input_recorded: 'BDA Freezer Total Continuous Power Text Input Recorded',
  bda_freezer_peak_power_text_input_selected: 'BDA Freezer Peak Power Text Input Selected',
  bda_freezer_peak_power_text_input_recorded: 'BDA Freezer Peak Power Text Input Recorded',
  bda_freezer_quantity_text_input_selected: 'BDA Freezer Quantity Text Input Selected',
  bda_freezer_quantity_text_input_recorded: 'BDA Freezer Quantity Text Input Recorded',
  bda_wifi_toggle_on: 'BDA WiFi Toggle On',
  bda_wifi_toggle_off: 'BDA WiFi Toggle Off',
  bda_wifi_total_continuous_power_text_input_selected: 'BDA WiFi Total Continuous Power Text Input Selected',
  bda_wifi_total_continuous_power_text_input_recorded: 'BDA WiFi Total Continuous Power Text Input Recorded',
  bda_wifi_peak_power_text_input_selected: 'BDA WiFi Peak Power Text Input Selected',
  bda_wifi_peak_power_text_input_recorded: 'BDA WiFi Peak Power Text Input Recorded',
  bda_wifi_quantity_text_input_selected: 'BDA WiFi Quantity Text Input Selected',
  bda_wifi_quantity_text_input_recorded: 'BDA WiFi Quantity Text Input Recorded',
  bda_computer_toggle_on: 'BDA Computer Toggle On',
  bda_computer_toggle_off: 'BDA Computer Toggle Off',
  bda_computer_total_continuous_power_text_input_selected: 'BDA Computer Total Continuous Power Text Input Selected',
  bda_computer_total_continuous_power_text_input_recorded: 'BDA Computer Total Continuous Power Text Input Recorded',
  bda_computer_peak_power_text_input_selected: 'BDA Computer Peak Power Text Input Selected',
  bda_computer_peak_power_text_input_recorded: 'BDA Computer Peak Power Text Input Recorded',
  bda_computer_quantity_text_input_selected: 'BDA Computer Quantity Text Input Selected',
  bda_computer_quantity_text_input_recorded: 'BDA Computer Quantity Text Input Recorded',
  bda_tv_toggle_on: 'BDA TV Toggle On',
  bda_tv_toggle_off: 'BDA TV Toggle Off',
  bda_tv_total_continuous_power_text_input_selected: 'BDA TV Total Continuous Power Text Input Selected',
  bda_tv_total_continuous_power_text_input_recorded: 'BDA TV Total Continuous Power Text Input Recorded',
  bda_tv_peak_power_text_input_selected: 'BDA TV Peak Power Text Input Selected',
  bda_tv_peak_power_text_input_recorded: 'BDA TV Peak Power Text Input Recorded',
  bda_tv_quantity_text_input_selected: 'BDA TV Quantity Text Input Selected',
  bda_tv_quantity_text_input_recorded: 'BDA TV Quantity Text Input Recorded',
  bda_electric_range_toggle_on: 'BDA Electric Range Toggle On',
  bda_electric_range_toggle_off: 'BDA Electric Range Toggle Off',
  bda_electric_range_total_continuous_power_text_input_selected:
    'BDA Electric Range Total Continuous Power Text Input Selected',
  bda_electric_range_total_continuous_power_text_input_recorded:
    'BDA Electric Range Total Continuous Power Text Input Recorded',
  bda_electric_range_peak_power_text_input_selected: 'BDA Electric Range Peak Power Text Input Selected',
  bda_electric_range_peak_power_text_input_recorded: 'BDA Electric Range Peak Power Text Input Recorded',
  bda_electric_range_quantity_text_input_selected: 'BDA Electric Range Quantity Text Input Selected',
  bda_electric_range_quantity_text_input_recorded: 'BDA Electric Range Quantity Text Input Recorded',
  bda_dishwasher_toggle_on: 'BDA Dishwasher Toggle On',
  bda_dishwasher_toggle_off: 'BDA Dishwasher Toggle Off',
  bda_dishwasher_total_continuous_power_text_input_selected:
    'BDA Dishwasher Total Continuous Power Text Input Selected',
  bda_dishwasher_total_continuous_power_text_input_recorded:
    'BDA Dishwasher Total Continuous Power Text Input Recorded',
  bda_dishwasher_peak_power_text_input_selected: 'BDA Dishwasher Peak Power Text Input Selected',
  bda_dishwasher_peak_power_text_input_recorded: 'BDA Dishwasher Peak Power Text Input Recorded',
  bda_dishwasher_quantity_text_input_selected: 'BDA Dishwasher Quantity Text Input Selected',
  bda_dishwasher_quantity_text_input_recorded: 'BDA Dishwasher Quantity Text Input Recorded',
  bda_electric_dryer_toggle_on: 'BDA Electric Dryer Toggle On',
  bda_electric_dryer_toggle_off: 'BDA Electric Dryer Toggle Off',
  bda_electric_dryer_total_continuous_power_text_input_selected:
    'BDA Electric Dryer Total Continuous Power Text Input Selected',
  bda_electric_dryer_total_continuous_power_text_input_recorded:
    'BDA Electric Dryer Total Continuous Power Text Input Recorded',
  bda_electric_dryer_peak_power_text_input_selected: 'BDA Electric Dryer Peak Power Text Input Selected',
  bda_electric_dryer_peak_power_text_input_recorded: 'BDA Electric Dryer Peak Power Text Input Recorded',
  bda_electric_dryer_quantity_text_input_selected: 'BDA Electric Dryer Quantity Text Input Selected',
  bda_electric_dryer_quantity_text_input_recorded: 'BDA Electric Dryer Quantity Text Input Recorded',
  bda_electric_water_heater_toggle_on: 'BDA Electric Water Heater Toggle On',
  bda_electric_water_heater_toggle_off: 'BDA Electric Water Heater Toggle Off',
  bda_electric_water_heater_total_continuous_power_text_input_selected:
    'BDA Electric Water Heater Total Continuous Power Text Input Selected',
  bda_electric_water_heater_total_continuous_power_text_input_recorded:
    'BDA Electric Water Heater Total Continuous Power Text Input Recorded',
  bda_electric_water_heater_peak_power_text_input_selected: 'BDA Electric Water Heater Peak Power Text Input Selected',
  bda_electric_water_heater_peak_power_text_input_recorded: 'BDA Electric Water Heater Peak Power Text Input Recorded',
  bda_electric_water_heater_quantity_text_input_selected: 'BDA Electric Water Heater Quantity Text Input Selected',
  bda_electric_water_heater_quantity_text_input_recorded: 'BDA Electric Water Heater Quantity Text Input Recorded',
  bda_washing_machine_toggle_on: 'BDA Washing Machine Toggle On',
  bda_washing_machine_toggle_off: 'BDA Washing Machine Toggle Off',
  bda_washing_machine_total_continuous_power_text_input_selected:
    'BDA Washing Machine Total Continuous Power Text Input Selected',
  bda_washing_machine_total_continuous_power_text_input_recorded:
    'BDA Washing Machine Total Continuous Power Text Input Recorded',
  bda_washing_machine_peak_power_text_input_selected: 'BDA Washing Machine Peak Power Text Input Selected',
  bda_washing_machine_peak_power_text_input_recorded: 'BDA Washing Machine Peak Power Text Input Recorded',
  bda_washing_machine_quantity_text_input_selected: 'BDA Washing Machine Quantity Text Input Selected',
  bda_washing_machine_quantity_text_input_recorded: 'BDA Washing Machine Quantity Text Input Recorded',
  bda_air_conditioning_toggle_on: 'BDA Air Conditioning Toggle On',
  bda_air_conditioning_toggle_off: 'BDA Air Conditioning Toggle Off',
  bda_air_conditioning_total_continuous_power_text_input_selected:
    'BDA Air Conditioning Total Continuous Power Text Input Selected',
  bda_air_conditioning_total_continuous_power_text_input_recorded:
    'BDA Air Conditioning Total Continuous Power Text Input Recorded',
  bda_air_conditioning_peak_power_text_input_selected: 'BDA Air Conditioning Peak Power Text Input Selected',
  bda_air_conditioning_peak_power_text_input_recorded: 'BDA Air Conditioning Peak Power Text Input Recorded',
  bda_air_conditioning_quantity_text_input_selected: 'BDA Air Conditioning Quantity Text Input Selected',
  bda_air_conditioning_quantity_text_input_recorded: 'BDA Air Conditioning Quantity Text Input Recorded',
  bda_ev_toggle_on: 'BDA EV Toggle On',
  bda_ev_toggle_off: 'BDA EV Toggle Off',
  bda_ev_total_continuous_power_text_input_selected: 'BDA EV Total Continuous Power Text Input Selected',
  bda_ev_total_continuous_power_text_input_recorded: 'BDA EV Total Continuous Power Text Input Recorded',
  bda_ev_peak_power_text_input_selected: 'BDA EV Peak Power Text Input Selected',
  bda_ev_peak_power_text_input_recorded: 'BDA EV Peak Power Text Input Recorded',
  bda_ev_quantity_text_input_selected: 'BDA EV Quantity Text Input Selected',
  bda_ev_quantity_text_input_recorded: 'BDA EV Quantity Text Input Recorded',
  bda_well_pump_toggle_on: 'BDA Well Pump Toggle On',
  bda_well_pump_toggle_off: 'BDA Well Pump Toggle Off',
  bda_well_pump_total_continuous_power_text_input_selected: 'BDA Well Pump Total Continuous Power Text Input Selected',
  bda_well_pump_total_continuous_power_text_input_recorded: 'BDA Well Pump Total Continuous Power Text Input Recorded',
  bda_well_pump_peak_power_text_input_selected: 'BDA Well Pump Peak Power Text Input Selected',
  bda_well_pump_peak_power_text_input_recorded: 'BDA Well Pump Peak Power Text Input Recorded',
  bda_well_pump_quantity_text_input_selected: 'BDA Well Pump Quantity Text Input Selected',
  bda_well_pump_quantity_text_input_recorded: 'BDA Well Pump Quantity Text Input Recorded',
  bda_pool_pump_toggle_on: 'BDA Pool Pump Toggle On',
  bda_pool_pump_toggle_off: 'BDA Pool Pump Toggle Off',
  bda_pool_pump_total_continuous_power_text_input_selected: 'BDA Pool Pump Total Continuous Power Text Input Selected',
  bda_pool_pump_total_continuous_power_text_input_recorded: 'BDA Pool Pump Total Continuous Power Text Input Recorded',
  bda_pool_pump_peak_power_text_input_selected: 'BDA Pool Pump Peak Power Text Input Selected',
  bda_pool_pump_peak_power_text_input_recorded: 'BDA Pool Pump Peak Power Text Input Recorded',
  bda_pool_pump_quantity_text_input_selected: 'BDA Pool Pump Quantity Text Input Selected',
  bda_pool_pump_quantity_text_input_recorded: 'BDA Pool Pump Quantity Text Input Recorded',
  bda_back_button_clicked: 'BDA Back Button Clicked',
  bda_product_card_selected: 'BDA Product Card Selected',
  bda_add_battery_clicked: 'BDA Add Battery Clicked',
  bda_warning_notification_banner: 'BDA Warning Notification Banner',
  hardware_solar_outlet_pay_using_solar_juice_credit_cta_clicked: 'Hardware Solar Outlet Pay Using Credit CTA Clicked',
  hardware_solar_outlet_pricing_option_updated: 'Hardware Solar Outlet Pricing Option Updated',
  hardware_solar_outlet_credit_checkout_shipping_address_updated:
    'Hardware Solar Outlet Credit Checkout Shipping Address Updated',
  hardware_solar_outlet_credit_checkout_shipping_method_updated:
    'Hardware Solar Outlet Credit Checkout Shipping Method Updated',
  hardware_solar_outlet_review_purchase_order: 'Hardware Solar Outlet Review Purchase Order',
}
