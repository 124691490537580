// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDataProvider, useNotify, useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import PanelContainer from '../PanelContainer'
import { SidePanelContext } from '../SidePanel'
import FetchProjectsWithTags from './FetchProjectsWithTags'
import ProjectActivityChart from './ProjectActivityChart'

const MONTHS_TO_SHOW = 6

const useStyles = makeStyles(() => ({
  toggleWrapper: {
    width: 'fit-content',
    margin: '20px auto',
    display: 'flex',
  },
  buttonInactive: {
    width: 150,
    height: 28,
    boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px',
    background: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-child': {
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
    },
  },
  buttonActive: {
    width: 150,
    height: 28,
    boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px',
    background: 'rgba(0, 0, 0, 0.54)',
    color: 'rgb(255,255,255)',
    cursor: 'pointer',
    border: 'none',
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-child': {
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
    },
  },
}))

const monthsWithYears = (dateAsString) => {
  var dateAsDate = new Date(dateAsString)
  return dateAsDate.getYear() * 12 + dateAsDate.getMonth()
}

const updateProjectsData = (projectsData: any, data: any) => {
  const createdMonth = monthsWithYears(data.created_date)
  const installedMonth = data.installation_date && monthsWithYears(data.installation_date)
  const soldMonth = data.contract_date && monthsWithYears(data.contract_date)
  const currentMonth = monthsWithYears(new Date())
  const firstMonthToShow = currentMonth - MONTHS_TO_SHOW + 1
  if (createdMonth >= firstMonthToShow) {
    // Convert index to ensure the current month is the last index
    let index = createdMonth - firstMonthToShow
    projectsData.created[index] += 1
  }

  if (installedMonth >= firstMonthToShow) {
    let index = installedMonth - firstMonthToShow
    projectsData.installed[index] += 1
  }

  if (soldMonth >= firstMonthToShow) {
    let index = soldMonth - firstMonthToShow
    projectsData.sold[index] += 1
  }

  // Stage counts outside the charts are not restricted to last 6 months
  //@ts-ignore
  projectsData[data.active_stage] = (projectsData[data.active_stage] || 0) + 1
}

const OverViewPanel = () => {
  const { state, setState } = useContext(SidePanelContext)
  const [showAllProjects, setShowAllProjects] = useState(false)
  const orgId = useSelector((state: any) => (state.auth ? state.auth.org_id : null))
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const stateRef = useRef(state)
  stateRef.current = state
  useEffect(() => {
    const onFailed = (error: any) => {
      notify('ra.notification.http_error', 'warning')
      //@ts-ignore
      console.warn(error)
    }
    function fetchTags() {
      dataProvider
        .CUSTOM_GET(
          'tags',
          {
            url: 'tag_stats/?remove_tag_when_fetched=true',
          },
          {
            onSuccess: (response: any) => {
              const tagsData = response.data
              setState((state: any) => ({
                ...state,
                tagsData,
              }))
            },
          }
        )
        .catch(onFailed)
    }
    function fetchData() {
      const onSuccess = ({ data = [] }: any) => {
        if (stateRef.current.allProjectsData && stateRef.current.myProjectsData) {
          //loaded data already ignore callback
          return
        }
        const myProjectsData = {
          created: [0, 0, 0, 0, 0, 0],
          sold: [0, 0, 0, 0, 0, 0],
          installed: [0, 0, 0, 0, 0, 0],
          new: 0,
          Selling: 0,
          Lead: 0,
          Installing: 0,
          Complete: 0,
          Others: 0,
        }
        const allProjectsData = {
          created: [0, 0, 0, 0, 0, 0],
          sold: [0, 0, 0, 0, 0, 0],
          installed: [0, 0, 0, 0, 0, 0],
          new: 0,
          Selling: 0,
          Lead: 0,
          Installing: 0,
          Complete: 0,
          Others: 0,
        }

        for (let i = 0; i < data.length; i++) {
          if (data[i].is_myproject) {
            updateProjectsData(myProjectsData, data[i])
          }
          updateProjectsData(allProjectsData, data[i])
        }

        setState((state: any) => ({
          ...state,
          myProjectsData,
          allProjectsData,
        }))
      }

      dataProvider
        .CUSTOM_GET(
          'projects',
          {
            url: 'home/overview/',
          },
          { onSuccess }
        )
        .catch(onFailed)
    }
    if ((!state.myProjectsData || !state.allProjectsData) && orgId) {
      fetchData()
    }

    if (!state.tagsData && orgId) {
      fetchTags()
    }
  }, [])
  const isProjectDataReady = showAllProjects ? !!state.allProjectsData : !!state.myProjectsData
  const isTagDataReady = !!state.tagsData
  return (
    <PanelContainer title={translate('Overview')}>
      <div className={classes.toggleWrapper}>
        <button
          className={showAllProjects ? classes.buttonInactive : classes.buttonActive}
          onClick={() => setShowAllProjects(false)}
        >
          {translate('My Projects')}
        </button>
        <button
          className={showAllProjects ? classes.buttonActive : classes.buttonInactive}
          onClick={() => setShowAllProjects(true)}
        >
          {translate('All Projects')}
        </button>
      </div>
      <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        {isProjectDataReady ? (
          <>
            <ProjectActivityChart data={showAllProjects ? state.allProjectsData : state.myProjectsData} />
            {/* <OverallStatistics data={showAllProjects ? state.allProjectsData : state.myProjectsData} /> */}
          </>
        ) : (
          <div style={{ margin: 20 }}>
            <Skeleton height={'200px'} />
          </div>
        )}
        {isTagDataReady ? (
          <FetchProjectsWithTags
            data={state.tagsData}
            // data={[
            //   { id: 4439, title: 'Test Design required', projects: [1, 2, 3] },
            //   { id: 4439, title: 'Test Design required', projects: [1, 2, 3] },
            // ]}
          />
        ) : (
          <div style={{ margin: 20 }}>
            <Skeleton height={'50px'} />
          </div>
        )}
      </div>
    </PanelContainer>
  )
}

export default OverViewPanel
