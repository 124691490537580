import { urlToId } from '../util/misc'

const showAll = false

const systemsVisible = (systemsAll, soldSystemId) => {
  let systems = systemsAll
  if (showAll) {
    return systems
  } else {
    systems = systems.filter((s) => s.show_customer === true)
  }

  if (soldSystemId) {
    systems = systems.filter((s) => s.id === soldSystemId)
  }
  return systems
}

const systemsVisibleAndSortedByOrderAscending = (systemsAll, soldSystemId) => {
  return systemsVisible(systemsAll, soldSystemId)
    .slice() // why we need slice the array?
    .sort((a, b) => {
      return a.order > b.order
    })
}

//  We want to handle all data preparation server-side and remove most field calculation from populate.js
//
// Temporary workaround. Previously we used system.data but this does not include any custom placeholders such as componentWarranties etc.
// Now we will keep using system.data but we will inject all the missing placeholders.
// In future, we should merge these into a single object to avoid unnecessary data duplication and confusion.
const systemDataIncudingPlaceholders = (system) => {
  var keysToInject = [
    'systemKwStc',
    'inverterTotalRatingKw',
    'moduleQuantity',
    'moduleEfficiency',
    'moduleCode',
    'moduleManufacturer',
    'moduleWattStc',
    'moduleProductWarranty',
    'modulePerformanceWarranty',
    'systemOutputAnnualkWh',
    'inverterManufacturer',
    'inverterQuantity',
    'inverterProductWarranty',
    'inverterAdditionalPartsWarranty',
    'inverterCodes',
    'batteryManufacturer',
    'batteryCodes',
    'otherComponentCodes',
    'batteryProductWarranty',
    'systemBatteryKwhOptimal',
    'systemBatteryUsableKwh',
    'batteryThroughput',
    'batteryCycleLife',
    'inverterNominalAcPower',
    'panelOrientations',
    'systemTotalLosses',
    'inverterLosses',
    'optimizerLosses',
    'shadingLossAnnual',
    'outputCalculator',
    'performanceAdjustment',
    'systemNetPresentValue',
    'systemPaybackYear',
    'systemDiscountedPaybackYear',
    'systemReturnOnInvestment',
    'systemIrr',
    'systemLcoe',
    'systemFinancialDiscountRate',
    'systemUtilityInflationAnnual',
    'systemFeedInTariffInflationAnnual',
    'proposedUsageAdjustment',
    'outputCalculationNotes',
    'componentWarranties',

    'disclaimer_system_hardware_components',
    'disclaimer_system_performance',
    'disclaimer_system_performance_new',
    'disclaimer_daily_energy_flows',
    'disclaimer_environmental_benefits',
    'content_block_1',
    'content_block_2',
    'content_block_3',

    'tile_online_proposals',
    'tile_pdf_proposals',
    'inverter_codes_with_details',
    'other_codes_with_details',
    'battery_codes_with_details',
  ]

  var systemWithPlaceholders = system.data

  // Notice: This will modify the raw system data with extra keys/data but we assume this is ok.
  keysToInject.forEach((key) => {
    systemWithPlaceholders[key] = system[key]
  })

  return systemWithPlaceholders
}

export default function parseDataToState(data) {
  //Filter only customer viewable
  const org = data[0]
  const selectedProject = org.projects[0]
  const soldSystemId = selectedProject.system_sold ? urlToId(selectedProject.system_sold) : null
  const systems = systemsVisibleAndSortedByOrderAscending(
    selectedProject.proposal_data.systems.map((s) => systemDataIncudingPlaceholders(s)),
    soldSystemId
  )
  const selectedSystem = systems[0]
  const otherDesigns = org?.projects.filter((m) => m.id !== selectedProject.id)
  const otherAccounts = data.filter((a) => a.id !== org.id)

  // @TODO: This is bogus: data.selectedPaymentOptionId because data is a list of projects!
  let soldPaymentOptionId = selectedProject.payment_option_sold ? urlToId(selectedProject.payment_option_sold) : null
  if (soldPaymentOptionId && selectedProject.system_sold) {
    let soldSystemId = urlToId(selectedProject.system_sold)
    if (selectedProject.proposal_data?.systems?.length > 0) {
      let soldPmtIsPhoenix = false
      selectedProject.proposal_data?.systems?.forEach((sys) => {
        if (sys?.data?.id === soldSystemId && sys.attached_phoenix_payment_option) soldPmtIsPhoenix = true
      })

      if (soldPmtIsPhoenix) soldPaymentOptionId = null
    }
  }
  // try {
  //   selectedPaymentOptionId === data.selectedPaymentOptionId
  //     ? data.selectedPaymentOptionId
  //     : selectedSystem.payment_options[0].id
  // } catch (err) {
  //   console.warn(err)
  //   selectedPaymentOptionId = null
  // }
  const selectedPaymentOptionId = null
  if (soldPaymentOptionId) {
    systems.forEach(
      (system) => (system.payment_options = system.payment_options.filter((po) => po.id === soldPaymentOptionId))
    )
  }

  let design
  try {
    design = selectedProject.proposal_data.design
  } catch (err) {
    design = null
  }

  let viewUuids = []
  let selectedViewUuid = null

  if (design && design.object) {
    var viewDetails = window.ViewHelper.viewUuidsForCustomer(design.object.userData.views)
    viewUuids = viewDetails.viewUuids
    selectedViewUuid = viewDetails.selectedViewUuid
  }

  //#@TODO: What happens if more than one org? which org should be fetch user from???
  const user = org.user

  return {
    loading: false, //moved to loadingInitialData
    org: org,
    testimonials: selectedProject['testimonials'],
    user: user,
    selectedProject: selectedProject,
    systems: systems,
    selectedSystem: selectedSystem,
    otherDesigns: otherDesigns,
    otherAccounts: otherAccounts,
    viewUuids: viewUuids,
    selectedViewUuid: selectedViewUuid,
    selectedPaymentOptionId: selectedPaymentOptionId,
    proposalTemplateSettings: selectedProject.proposal_data.proposal_template_settings,
    fullCalcReady: !selectedProject.simulate_first_year_only,
  }
}
