import { InputAdornment, OutlinedInput } from '@material-ui/core'
import { Add, GetAppOutlined, GridOnOutlined, SearchOutlined, ViewListOutlined } from '@material-ui/icons'
import { permissionsSelectors } from 'ducks/permissions'
import Button from 'elements/button/Button'
import ListActions from 'elements/react-admin/ListActions'

import { makeStyles, styled, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import BulkDeleteWithConfirmButtonV3 from 'projectSections/sections/manage3/common/BulkDeleteWithConfirmButton'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { CRUDFileMenu } from '../common/CRUDFileMenu'
import { downloadFile } from '../common/utils'
import { FilesContext } from './ProjectFilesList'

export const getStyles = makeStyles(() => ({
  searchInput: {
    minWidth: 300,
    height: 2,
  },
}))

export const MainToolbarContainer = styled('div')(({ theme }) => ({
  gap: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginBottom: 10,
  width: '100%',
  flexWrap: 'wrap-reverse',
}))

export const ListActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 4,
  flexGrow: 18,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: 300,
  rowGap: 10,
  '&>div': {
    display: 'flex',
  },
}))

export const LeftContent = styled('div')(({ theme }) => ({
  height: 40,
  flex: 4,
}))

export const SearchInputStyled = styled(OutlinedInput)(({ theme }) => ({
  minWidth: 300,
  display: 'flex',
  flex: 1,
  backgroundColor: 'white',
  borderRadius: 8,
}))

export const RightContent = styled('div')(({ theme }) => ({
  height: 40,
  flex: 1,
  gap: 10,
  justifyContent: 'flex-end',
  '&>button': {
    padding: '10px 12px !important',
    fontSize: 14,
    margin: 0,
    whiteSpace: 'nowrap',
    minWidth: 120,
    flex: 1,
    '& svg': {
      marginRight: 6,
    },
  },
}))

export const ToggleContainer = styled('div')(({ theme }) => ({
  minWidth: 'fit-contents',
  display: 'flex',
  minHeight: 40,
  flexGrow: 1,
  '& svg': {
    marginRight: 5,
  },
  '&>div': {
    flex: 1,
    height: '100%',
  },
  '& button': {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
}))

export const MainToolBar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { isEditingInline, dialogSelected, selectDialog, viewMode, selectViewMode } = useContext(FilesContext)
  const { allowView, allowDelete, allowCreate } = useSelector(permissionsSelectors.getPermissionByKey('info_documents'))
  const translate = useTranslate()

  const deleteEnabled = allowDelete && !isEditingInline
  const createEnabled = allowCreate && !isEditingInline
  const viewEnabled = allowView && !isEditingInline

  const [value, setValue] = useState(props.filterValues?.q)
  const classes = getStyles()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const q = event.target.value
    setValue(q)
    props.setFilters({ ...props.filterValues, q })
  }

  return (
    <MainToolbarContainer>
      <ListActionsContainer>
        <ListActions
          hasCreate={true}
          hasSearch={false}
          {...props}
          CustomButton={
            <>
              <LeftContent>
                <SearchInputStyled
                  disabled={isEditingInline}
                  id={'search-files-input'}
                  value={value}
                  onChange={handleChange}
                  placeholder={'Search files'}
                  startAdornment={<InputAdornment position="start">{<SearchOutlined />}</InputAdornment>}
                />
              </LeftContent>
              <RightContent>
                {props.selectedIds.length > 0 && !isEditingInline && (
                  <>
                    <BulkDeleteWithConfirmButtonV3 {...props} disabled={!deleteEnabled} />
                    <Button
                      color={'secondary'}
                      variant="outlined"
                      startIcon={<GetAppOutlined htmlColor={COLOR_PALETTE.darkGrey} />}
                      onClick={(e) => {
                        props.selectedIds.forEach((id) => {
                          const fileRecord = props.data[id]
                          downloadFile(fileRecord)
                        })
                      }}
                      disabled={!viewEnabled}
                    >
                      Download
                    </Button>
                  </>
                )}
                <Button
                  color={'secondary'}
                  variant="outlined"
                  startIcon={<Add htmlColor={COLOR_PALETTE.darkGrey} />}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                    console.log(props)
                  }}
                  disabled={!createEnabled}
                >
                  Add File
                </Button>
              </RightContent>
              <CRUDFileMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                ctaSelected={dialogSelected}
                selectCta={selectDialog}
              />
            </>
          }
        />
      </ListActionsContainer>
      <ToggleContainer>
        <ToggleButtonGroup value={viewMode}>
          <ToggleButton disabled={isEditingInline} value="tableView" onClick={() => selectViewMode('tableView')}>
            <ViewListOutlined /> {translate('Table')}
          </ToggleButton>
          <ToggleButton disabled={isEditingInline} value="gridView" onClick={() => selectViewMode('gridView')}>
            <GridOnOutlined /> {translate('Grid')}
          </ToggleButton>
        </ToggleButtonGroup>
      </ToggleContainer>
    </MainToolbarContainer>
  )
}
