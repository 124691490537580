import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useForm, useFormState } from 'react-final-form'
import { composeOrdering, getSelectedSortFilter } from './fields'
import { SectionHeader } from './FilterDrawer'
import SortButton from './SortButton'

export const FlexStartWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

const MidSection = styled('div')(({ theme }) => ({
  padding: '15px 0',
  margin: '15px 0',
  borderTop: '1px solid ' + theme.palette.secondary.light,
  borderBottom: '1px solid ' + theme.palette.secondary.light,
}))

const SORT_BUTTONS = [
  { label: 'Last Updated', filter: 'modified_date' },
  { label: 'Created Date', filter: 'created_date' },
  { label: 'Priority', filter: 'priority' },
  { label: 'Project ID', filter: 'id' },
]

const SortSection = () => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const { isDescending, selectedSortFilter } = getSelectedSortFilter(formValues.ordering)
  const updateSort = (sort, filter) => {
    form.change('ordering', composeOrdering(sort, filter))
  }
  return (
    <>
      <SectionHeader>{translate('Sort By')}</SectionHeader>
      <FlexStartWrapper>
        {SORT_BUTTONS.map((button) => (
          <SortButton
            isActive={selectedSortFilter === button.filter}
            label={button.label}
            onClick={() => {
              updateSort(isDescending, button.filter)
            }}
          />
        ))}
      </FlexStartWrapper>
      <MidSection>
        <SectionHeader>{translate('Order By')}</SectionHeader>
        <FlexStartWrapper>
          <SortButton
            label={'Ascending'}
            isActive={!isDescending}
            onClick={() => {
              updateSort(false, selectedSortFilter)
            }}
          />
          <SortButton
            label={'Descending'}
            isActive={isDescending}
            onClick={() => {
              updateSort(true, selectedSortFilter)
            }}
          />
        </FlexStartWrapper>
      </MidSection>
    </>
  )
}

export default SortSection
