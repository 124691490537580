export const ALL_SECTOR_CHOICE = 0
export const RESIDENTIAL_SECTOR_CHOICE = 1
export const COMMERCIAL_SECTOR_CHOICE = 2
export const INDUSTRIAL_SECTOR_CHOICE = 3
export const LIGHTING_SECTOR_CHOICE = 4

export const SECTOR_CHOICES = [
  { id: ALL_SECTOR_CHOICE, name: 'All' },
  { id: RESIDENTIAL_SECTOR_CHOICE, name: 'Residential' },
  { id: COMMERCIAL_SECTOR_CHOICE, name: 'Commercial' },
  { id: INDUSTRIAL_SECTOR_CHOICE, name: 'Industrial' },
  { id: LIGHTING_SECTOR_CHOICE, name: 'Lighting' },
]
