import { BSA_CUSTOMER_PRIORITY_NODE_KEY, BSA_MANUAL_NODE_KEY, BSA_SELECTOR_NODE_KEY } from '../../utility/constants'
import type { BSAModeKeyType, BatterySalesAssistantFlowNodeComponentStandardPropsType } from '../../utility/types'

class ModeNodePresenter {
  static continueWithAutoMode = ({
    logAmplitudeEvent,
    hasBatteries,
    compatibleBatteries,
    flowController,
  }: {
    logAmplitudeEvent
    hasBatteries: boolean
    compatibleBatteries: string[]
    flowController: BatterySalesAssistantFlowNodeComponentStandardPropsType['flowController']
  }) => {
    logAmplitudeEvent('bda_next_button_clicked', {
      overwrite_existing_product: hasBatteries ? 'yes' : 'no',
      recorded_on_click: 'auto_battery',
    })
    if (compatibleBatteries.length) {
      // set the limitedCodes in the flow store for the compatible batteries
      flowController.setFlowStore((state) => {
        return {
          ...state,
          limitedCodes: compatibleBatteries,
        }
      })
    }
    if (compatibleBatteries.length === 1) {
      // skip the selector node if there is only one compatible battery
      flowController.goTo({
        currentNodeKey: BSA_SELECTOR_NODE_KEY,
        options: {},
      })
      return
    }

    flowController.goTo({
      currentNodeKey: BSA_CUSTOMER_PRIORITY_NODE_KEY,
      options: {},
    })
  }
  static continueWithManualMode = ({
    logAmplitudeEvent,
    hasBatteries,
    onClose,
    flowController,
    translate,
  }: {
    logAmplitudeEvent
    hasBatteries: boolean
    onClose(): void
    flowController: BatterySalesAssistantFlowNodeComponentStandardPropsType['flowController']
    translate
  }) => {
    logAmplitudeEvent('bda_next_button_clicked', {
      overwrite_existing_product: hasBatteries ? 'yes' : 'no',
      recorded_on_click: 'manual_battery',
    })
    flowController.goTo({
      currentNodeKey: BSA_MANUAL_NODE_KEY,
      options: {},
      title: translate('Select Battery'),
    })
  }

  static updateSelectedMode = ({
    previousSelectedMode,
    newSelectedMode,
    setSelectedMode,
    flowController,
    logAmplitudeEvent,
  }: {
    previousSelectedMode: BSAModeKeyType | undefined
    newSelectedMode: BSAModeKeyType
    setSelectedMode: React.Dispatch<React.SetStateAction<BSAModeKeyType | undefined>>
    flowController: BatterySalesAssistantFlowNodeComponentStandardPropsType['flowController']
    logAmplitudeEvent
  }) => {
    logAmplitudeEvent(
      newSelectedMode === 'auto' ? 'bda_auto_battery_option_card_selected' : 'bda_from_list_option_card_selected',
      {
        previous_selected_option: previousSelectedMode === 'auto' ? 'auto_battery' : 'manual_battery',
      }
    )

    setSelectedMode(newSelectedMode)

    flowController.setFlowStore((state) => ({
      ...state,
      selection: newSelectedMode,
    }))
  }
}

export default ModeNodePresenter
