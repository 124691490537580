import { ConditionFactory, SystemCondition } from 'Designer/designRules/types'
import { AndConditionDef, condition_and } from './and'
import { CheckContextValueDef, condition_checkContext } from './check_context'
import { CheckProjectValueDef, condition_checkProject } from './check_project'
import { CheckReduxStateDef, condition_checkReduxState } from './check_redux_state'
import { CheckSessionDataDef, condition_checkSessionData } from './check_session_data'
import { CheckSystemCustomDataDef, condition_checkSystemCustomData } from './check_system_custom_data'
import { ComponentActiveConditionDef, condition_componentActive } from './component_active'
import { ComponentChangedDef, condition_componentChanged } from './component_changed'
import { ComponentInSystemDef, condition_componentInSystem } from './component_in_system'
import { condition_exhibitorAvailable, ExhibitorAvailableDef } from './exhibitor_available'
import { condition_or, OrConditionDef } from './or'
import { ComponentProductEnabledDef, condition_productEnabled } from './premium_product_enabled'
import { condition_systemHasModules, SystemHasModulesDef } from './system_has_modules'

export const createSystemCondition = (def: SystemConditionDef): SystemCondition => {
  const factory = systemConditions[def.type]
  if (!factory) throw new Error(`DesignRules: Unknown system condition type: ${def.type}`)
  return factory(def)
}

const logicalConditions = {
  or: condition_or,
  and: condition_and,
}

const orgConditions = {
  ...logicalConditions,
}

const projectConditions = {
  ...orgConditions,
}

const systemConditions: Record<SystemConditionTypes, ConditionFactory> = {
  ...projectConditions,
  'premium-product-enabled': condition_productEnabled,
  'exhibitor-available': condition_exhibitorAvailable,
  'component-active': condition_componentActive,
  'system-has-modules': condition_systemHasModules,
  'component-in-system': condition_componentInSystem,
  'component-changed': condition_componentChanged,
  'check-system-custom-data': condition_checkSystemCustomData,
  'check-session-data': condition_checkSessionData,
  'check-project': condition_checkProject,
  'check-redux-state': condition_checkReduxState,
  check: condition_checkContext,
}

/// TYPES

export type SystemConditionDef =
  | OrConditionDef
  | AndConditionDef
  | ComponentProductEnabledDef
  | ExhibitorAvailableDef
  | ComponentActiveConditionDef
  | SystemHasModulesDef
  | ComponentInSystemDef
  | ComponentChangedDef
  | CheckSystemCustomDataDef
  | CheckProjectValueDef
  | CheckReduxStateDef
  | CheckSessionDataDef
  | CheckContextValueDef

export type SystemConditionTypes = SystemConditionDef['type']

export type BaseConditionDef = {
  negate?: boolean
}
