import { FormLabel, Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import { ReferenceArrayInput, TextInput, useTranslate } from 'react-admin'
import { DownloadIconLink } from 'resources/privateFiles/PrivateFiles'
import { getDialogStyles } from '../dialogAndFieldsStyles'

export const EditFileFields = (props) => {
  const translate = useTranslate()
  const dialogStyles = getDialogStyles()
  return (
    <div className={dialogStyles.dialogContent}>
      <Grid
        container
        alignItems="center"
        spacing={2}
        className={`${dialogStyles.fieldsStyling} ${dialogStyles.noMargin}`}
      >
        <Grid item sm={11}>
          <FormLabel>{translate('Title')}</FormLabel>
          <TextInput fullWidth source="title" label="" variant="outlined" helperText="optional" record={props.record} />
        </Grid>
        <Grid item sm={1}>
          <DownloadIconLink record={props.record} />
        </Grid>
        <Grid item sm={12}>
          <div style={{ marginBottom: 15 }}>
            <FormLabel>{translate('File Tags')}</FormLabel>
          </div>
          <div className={dialogStyles.chipsInput}>
            <CustomField
              name="file_tags"
              source="file_tags"
              component={ReferenceArrayInput}
              reference="file_tags"
              resource="file_tags"
              optionValueField="url"
              fullWidth
              style={{ width: '100%' }}
              filterToQuery={(searchText) => ({ search: searchText, type: 'private' })}
            >
              <ChipsInput
                source="file_tags"
                optionText="title"
                optionValue="url"
                variant="outlined"
                label=""
                translateChoice={false}
                options={{ style: {} }}
              />
            </CustomField>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
