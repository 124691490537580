import { generateDocumentSelectors, setGenerateDocumentDialog } from 'ducks/generateDocument'
import { useDataProvider, useNotify, useRefresh } from 'ra-core'
import { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateFileType } from 'types/privateFile'

type FileId = number

export interface FileResponseInfo {
  id: number
  fileRecordUpdated: PrivateFileType | null
}

export const useProjectFiles = () => {
  const doc = useSelector(generateDocumentSelectors.getLastDocument)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const formState = form.getState()
  const projectFormValues = formState.values
  const refresh = useRefresh()

  const files = form.getState().values.private_files_data

  const dispatch = useDispatch()

  const updateEventInForm = (fileResponseInfo: FileResponseInfo) => {
    const updatedfilesData: PrivateFileType[] = files
      .filter((f) => f.id !== fileResponseInfo.id)
      .concat(fileResponseInfo?.fileRecordUpdated ? [fileResponseInfo?.fileRecordUpdated] : [])
    formState.initialValues.files_data = updatedfilesData
    form.mutators.updateFieldSilently('private_files_data', updatedfilesData)
    dispatch(setGenerateDocumentDialog(false))
    refresh()
  }

  const deleteFileInForm = (deletedFileId) => {
    const updatedfilesData: PrivateFileType[] = files.filter((f) => f.id !== deletedFileId)
    formState.initialValues.files_data = updatedfilesData
    form.mutators.updateFieldSilently('private_files_data', updatedfilesData)
    refresh()
  }

  const addMultipleFilesToForm = (filesResponses: PrivateFileType[]) => {
    const updatedfilesData: PrivateFileType[] = files.concat(filesResponses)
    formState.initialValues.files_data = updatedfilesData
    form.mutators.updateFieldSilently('private_files_data', updatedfilesData)
    notify('Your changes have been saved', 'success')
  }

  const removeMultipleFromForm = (filesDeletedIds) => {
    const updatedfilesData: PrivateFileType[] = files.filter((f) => !filesDeletedIds.includes(f.id))
    formState.initialValues.files_data = updatedfilesData
    form.mutators.updateFieldSilently('private_files_data', updatedfilesData)
    notify('Removed', 'success')
  }

  useEffect(() => {
    if (doc?.status !== 'success' || !doc.data) return
    dataProvider.CUSTOM_GET(
      'private_files',
      {
        url: `private_files/${doc.data.id}/`,
      },
      {
        onSuccess: (response: { data: PrivateFileType }) => {
          const { data } = response
          updateEventInForm({ id: data.id, fileRecordUpdated: data })
        },
        onFailure: (e) => {
          console.error(e)
          notify('Error saving your file', 'warning')
        },
      }
    )
  }, [dataProvider, notify, doc])

  return {
    updateEventInForm,
    addMultipleFilesToForm,
    files,
    form,
    projectFormValues,
    removeMultipleFromForm,
    deleteFileInForm,
  }
}
