import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CustomField from 'elements/field/CustomField'
import { List } from 'elements/react-admin/List'
import withMediaQuery from 'layout/withMediaQuery'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FileField,
  FileInput,
  FilterComp,
  FunctionField,
  ImageField,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import { styles as standardStyles } from 'styles'
import { getIdForLabel, getLabel, getRoleFromState } from 'util/misc'
import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import ImageFieldClearable from '../../elements/field/ImageFieldClearable'
import DependentInput from '../../elements/input/DependentInput'
import OverviewInputs from '../../elements/input/OverviewInputs'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import ComponentSearch from './ComponentSearch'
import ContentPreview from './ContentPreview'

const DraftEditorWithContent = ({ record, fieldName, label, mentionContext, allowUploadImage, disabled }) => {
  return (
    <EditorComponent
      label={label}
      allowUploadImage={allowUploadImage}
      fieldName={fieldName}
      mentionContext={mentionContext}
      disabled={disabled}
    />
  )
}

const tableStyles = {
  table: {
    tableLayout: 'fixed',
  },
  tbody: {
    height: 'inherit',
  },
  header: {
    th: {
      padding: 0,
    },
    'th:first-child': {
      padding: '0 0 0 12px',
    },
  },
  cell: {
    td: {
      padding: '0 12px',
      whiteSpace: 'normal',
    },
    'td:first-child': {
      padding: '0 12px 0 16px',
      whiteSpace: 'normal',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    isStaff: state.auth ? state.auth.is_staff : null,
  }
}

export const COMPONENT_TYPE_CHOICES = [
  { id: 0, name: 'Module', reference: 'component_modules' },
  { id: 1, name: 'Inverter', reference: 'component_inverters' },
  { id: 2, name: 'Battery', reference: 'component_batteries' },
  { id: 3, name: 'Other', reference: 'component_others' },
  { id: 4, name: 'Tariff', reference: 'utility_tariffs' },
  { id: 5, name: 'Finance', reference: 'finance' },
]

export const FINANCE_CHOICES = [
  { code: 'brighte' },
  { code: 'commonbond' },
  { code: 'dividend' },
  { code: 'energy_ease' },
  { code: 'loanpal' },
  { code: 'mosaic' },
  { code: 'phoenix' },
  { code: 'plenti' },
  { code: 'sungage' },
  { code: 'sunlight' },
]

export const ComponentContentFilter = (props) => (
  <FilterComp {...props}>
    <TextInput style={standardStyles.FILTER_FIELD_STYLE} label="pos.search" source="q" />
  </FilterComp>
)

export const ComponentContentList = compose(
  withMediaQuery,
  connect(
    (state) => {
      return {
        org_id: state.auth ? state.auth.org_id : null,
      }
    },
    { duplicate: duplicateAction }
  )
)((props) => {
  const translate = useTranslate()
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))

  return (
    <List hasSearch={true} perPage={20} {...props} hasCreate={isAdmin}>
      <Datagrid styles={tableStyles}>
        <TextField source="title" />
        {props.layout >= 3 && <BooleanField source="is_approved" style={{ marginTop: 20 }} textAlign={'center'} />}
        {props.layout >= 3 && (
          <FunctionField
            source="component_type"
            label="resources.component_content.fields.type"
            render={(record) => getLabel(record.component_type, COMPONENT_TYPE_CHOICES, translate)}
          />
        )}
        {props.layout >= 4 && <TextField source="associated_component_model_codes" />}
        {props.layout >= 4 && <TextField label="Countries Include CSV" source="countries_include_csv" />}
        {props.layout >= 4 && <TextField label="Countries Exclude CSV" source="countries_exclude_csv" />}
        <EditOrImportButton
          sortable={false}
          label="Actions"
          source="actions"
          org_id={props.org_id}
          resource="component_content"
          duplicate={props.duplicate}
          isAdmin={isAdmin}
        />
      </Datagrid>
    </List>
  )
})

const inputStyle = {
  width: 256,
}

const formatSubmitValues = (values) => {
  const submitValues = { ...values }
  if (Array.isArray(submitValues.associated_component_model_codes)) {
    submitValues.associated_component_model_codes = submitValues.associated_component_model_codes.join(',')
  }
  return submitValues
}

const defaultValues = (values) => {
  if (typeof values.associated_component_model_codes === 'string') {
    values.associated_component_model_codes = values.associated_component_model_codes.split(',')
  }
  return values
}

const _ComponentContentCreate = ({ isStaff, ...props }) => {
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm formatSubmitValues={formatSubmitValues} defaultValue={defaultValues}>
        <OverviewInputs>
          <CustomField component={TextInput} inputStyle={inputStyle} name="title" source="title" />
          <CustomField
            component={SelectInput}
            name="component_type"
            source="component_type"
            choices={COMPONENT_TYPE_CHOICES.map((etc) => {
              return { id: etc.id, name: etc.name }
            })}
          />
        </OverviewInputs>
        <TextInput label="Label" style={inputStyle} source="external_label" />
        <TextInput label="URL" style={inputStyle} source="external_link" />
        <ComponentSearch />
        {isStaff ? (
          <>
            <h3>{translate('Regions')}</h3>
            <TextInput
              fullWidth={true}
              multiline={true}
              label="Countries Include CSV"
              source="countries_include_csv"
              {...props}
              helperText={'Enter ** or leave empty to include all countries'}
            />
            <TextInput
              fullWidth={true}
              multiline={true}
              label="Countries Exclude CSV"
              source="countries_exclude_csv"
              {...props}
            />
          </>
        ) : (
          ''
        )}
      </SimpleForm>
    </Create>
  )
}

const useStyles = makeStyles((theme) => ({
  zeroRightMargin: {
    marginRight: 0,
  },
}))

const _ComponentContentEdit = ({ isStaff, ...props }) => {
  const translate = useTranslate()
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const classes = useStyles()

  let withToolBar = isAdmin ? {} : { toolbar: false }

  return (
    <Edit {...props} hasDelete={isAdmin}>
      <SimpleForm {...withToolBar} formatSubmitValues={formatSubmitValues} defaultValue={defaultValues}>
        <OverviewInputs>
          <CustomField component={TextInput} inputStyle={inputStyle} name="title" source="title" disabled={!isAdmin} />

          <CustomField
            component={SelectInput}
            inputStyle={inputStyle}
            name="component_type"
            source="component_type"
            choices={COMPONENT_TYPE_CHOICES.map((etc) => {
              return { id: etc.id, name: etc.name }
            })}
            disabled={!isAdmin}
          />

          <CustomField
            component={BooleanInput}
            name="is_approved"
            source="is_approved"
            elStyle={{ marginTop: 20 }}
            disabled={!isStaff}
          />
        </OverviewInputs>

        <Grid container spacing={4} className={classes.zeroRightMargin}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ComponentSearch />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ContentPreview />
          </Grid>
        </Grid>

        <DependentInput dependsOn="component_type" resolve={(value) => value !== 5} style={{ display: 'block' }}>
          <h3>{translate('Description - Featured')}</h3>
          <DraftEditorWithContent
            fieldName="description_featured"
            mentionContext="exhibit"
            allowUploadImage={false}
            disabled={!isAdmin}
            label={false}
          />

          {isAdmin && <h1>{translate('Upload Logo Image')}</h1>}
          <p>{translate('Optional, if not supplied the Organisation logo will be used')}</p>
          <h3>{translate('Current Logo Image')}</h3>
          <ImageFieldClearable source="logo_image" title="title" disabled={!isAdmin} />
          {isAdmin && (
            <ImageInput
              source="logo_image"
              label="Logo Image"
              accept="image/*"
              placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          )}
          {isAdmin && <h1>{translate('Upload Promotional Image')}</h1>}
          <h3>{translate('Current Promotional Image')}</h3>
          <ImageFieldClearable source="promotional_image" title="title" disabled={!isAdmin} />
          {isAdmin && (
            <ImageInput
              source="promotional_image"
              label="Upload Promotional Image"
              accept="image/*"
              placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          )}
          {isAdmin && <h1>{translate('Upload Spec Sheet PDF')}</h1>}
          <h3>{translate('Current Spec Sheet PDF')}</h3>
          <FileField source="spec_sheet_pdf" title="spec_sheet_pdf" disabled={!isAdmin} />
          {isAdmin && (
            <FileInput
              source="spec_sheet_pdf"
              label="Upload Spec Sheet PDF"
              accept=".pdf"
              placeholder={<p>{translate('Click to upload or drag & drop a file here')}</p>}
            >
              <FileField source="spec_sheet_pdf" title="spec_sheet_pdf" />
            </FileInput>
          )}

          <DependentInput dependsOn="component_type" value={getIdForLabel('Module', COMPONENT_TYPE_CHOICES)}>
            {isAdmin && <h1>{translate('Upload Module Texture')}</h1>}
            <h3>{translate('Current Module Texture')}</h3>
            <ImageFieldClearable source="module_texture" title="title" disabled={!isAdmin} />
            {isAdmin && (
              <ImageInput
                source="module_texture"
                label="Upload Module Texture"
                accept="image/*"
                placeholder={<p>{translate('Click to upload or drag & drop an image file here')}</p>}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            )}
          </DependentInput>

          <h3>{translate('Product Details')}</h3>
          <DraftEditorWithContent
            fieldName="product_details"
            mentionContext="exhibit"
            allowUploadImage={true}
            disabled={!isAdmin}
            label={false}
          />

          <TextInput fullWidth={true} source="video_url" disabled={!isAdmin} />

          <h3>{translate('Component Metadata')}</h3>
          <DependentInput dependsOn="component_type" resolve={(value) => value !== 5} style={{ display: 'block' }}>
            <TextInput label="Label" inputStyle={inputStyle} source="external_label" disabled={!isAdmin} />
            <TextInput label="URL" inputStyle={inputStyle} source="external_link" disabled={!isAdmin} />
          </DependentInput>

          <h3>{translate('Regions')}</h3>
          <TextInput
            fullWidth={true}
            multiline={true}
            label="Countries Include CSV"
            source="countries_include_csv"
            {...props}
            helperText={'Enter ** or leave empty to include all countries'}
            disabled={!isStaff}
          />

          <TextInput
            fullWidth={true}
            multiline={true}
            label="Countries Exclude CSV"
            source="countries_exclude_csv"
            {...props}
            disabled={!isStaff}
          />
          {!isStaff && (
            <p>
              {translate(
                'Please reach out to support@opensolar.com or your partnership manager to update the fields below.'
              )}
            </p>
          )}
          <DependentInput dependsOn="component_type" resolve={(value) => value !== 4}>
            <h3>{translate('Quote Acceptance Content')}</h3>
            <DraftEditorWithContent
              fieldName="quote_acceptance_content"
              mentionContext="exhibit"
              allowUploadImage={false}
              disabled={!isStaff}
              label={false}
            />
          </DependentInput>
          <TextInput
            fullWidth={true}
            multiline={true}
            label="Automation"
            source="automation"
            {...props}
            disabled={!isStaff}
          />
        </DependentInput>

        <DependentInput dependsOn="component_type" resolve={() => isStaff} style={{ display: 'block' }}>
          <h1>{translate('Custom Tile Content')}</h1>

          <h3>{translate('Online Proposal')}</h3>
          <DraftEditorWithContent
            fieldName="tile_online_proposal"
            mentionContext="exhibit"
            allowUploadImage={true}
            disabled={!isAdmin}
            label={false}
          />

          <h3>{translate('PDF Proposal')}</h3>
          <DraftEditorWithContent
            fieldName="tile_pdf_proposal"
            mentionContext="exhibit"
            allowUploadImage={true}
            disabled={!isAdmin}
            label={false}
          />
        </DependentInput>
      </SimpleForm>
    </Edit>
  )
}

export const ComponentContentCreate = connect(mapStateToProps, {})(_ComponentContentCreate)
export const ComponentContentEdit = connect(mapStateToProps, {})(_ComponentContentEdit)
