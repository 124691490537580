import { authSelectors } from 'ducks/auth'
import { handleAddComponent } from 'projectSections/sections/design/util/handleAddComponent'
import { useCallback } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { OSComponentType } from 'types/components'
import { ComponentTypes } from 'types/selectComponent'
import { plural } from '../../elements/hardwareSelector/HardwareSelector'
import { updateSystemHardware } from '../../elements/hardwareSelector/updateSystemHardware'

var restClientInstance = restClient(window.API_ROOT + '/api')

const addOrUpdateToSystem = (
  componentType: string,
  activationId: number,
  slotKey?: string,
  targetUuids?: string[],
  quantity?: number
) => {
  // When changing an existing component, use updateSystemHardware
  if (targetUuids) {
    updateSystemHardware(componentType, activationId, targetUuids)
  } else {
    // We are selecting a component to add to the system, so we are not replacing an old component
    var params: Record<string, string | number> = {}
    params[componentType + 'Id'] = activationId

    if (slotKey) {
      params.slotKey = slotKey
    }
    if (quantity) {
      params.quantity = quantity
    }
    // @TODO: It's not safe to use selectedSystem.uuid
    // We should instead pass the systemUuid into the redux state for the dialog
    var systemUuid = window.editor.selectedSystem.uuid
    handleAddComponent(componentType, systemUuid, params)
  }
}

type BaseArgs = {
  componentType: ComponentTypes
  activeComponentsData?: OSComponentType[]
  targetUuids?: string[]
  slotKey?: string
  quantity?: number // currently only works for 'other' type
  closeDialog?: () => void
}
type ArgsActivated = BaseArgs & {
  componentActivationId: number | string
  componentId?: number
}
type ArgsDatabase = BaseArgs & {
  componentActivationId?: number | string
  componentId: number
}

export const useAddComponentToSystem = () => {
  const notify = useNotify()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId) as number

  return useCallback(
    ({
      componentType,
      activeComponentsData,
      targetUuids,
      slotKey,
      closeDialog,
      componentActivationId,
      componentId,
      quantity,
    }: ArgsActivated | ArgsDatabase) => {
      if (!activeComponentsData) {
        if (componentType === 'module') activeComponentsData = window.AccountHelper.getComponentModuleSpecsAvailable()
        else if (componentType === 'inverter')
          activeComponentsData = window.AccountHelper.getComponentInverterSpecsAvailable()
        else if (componentType === 'battery')
          activeComponentsData = window.AccountHelper.getComponentBatterySpecsAvailable()
        else if (componentType === 'other')
          activeComponentsData = window.AccountHelper.getComponentOtherSpecsAvailable()
        else throw new Error('Unknown component type: ' + componentType)
      }

      let matchingComponentActivation
      if (componentActivationId) {
        matchingComponentActivation = activeComponentsData.filter(
          (activeComponentData) => activeComponentData.id === componentActivationId
        )[0]
        if (matchingComponentActivation && componentId === undefined) {
          if (componentType === 'module') componentId = matchingComponentActivation['module_id']
          else if (componentType === 'inverter') componentId = matchingComponentActivation['inverter_id']
          else if (componentType === 'battery') componentId = matchingComponentActivation['battery_id']
          else if (componentType === 'other') componentId = matchingComponentActivation['other_id']
        }
      }

      if (componentId === undefined) throw new Error('Unable to establish `componentId`')

      if (!matchingComponentActivation) {
        // Another possibility is that this component is archived so it is showing in the "Database" results even though
        // it has an activation. In this case, search for an activation with a matching component id

        // Rather than relying on the archived components to be included in the data for the dialog, we kinda hack this
        // and lookup the loaded components directly, avoids needing to load archived components into the dialog.

        // Rather than handling archived components specially we just check all components in case we are selecting a component
        // in the database that has already been activated.

        var loadedDataForComponentType = {
          module: window.AccountHelper.loadedData.componentModuleSpecs,
          inverter: window.AccountHelper.loadedData.componentInverterSpecs,
          battery: window.AccountHelper.loadedData.componentBatterySpecs,
          other: window.AccountHelper.loadedData.componentOtherSpecs,
        }
        var loadedData: OSComponentType[] = loadedDataForComponentType[componentType]
        if (loadedData) {
          var componentIdFieldName = componentType + '_id'
          matchingComponentActivation = loadedData.filter((archivedComponentData) => {
            return archivedComponentData[componentIdFieldName] === componentId
          })[0]
        }
      }

      if (matchingComponentActivation) {
        //signals and controllers are disabled in dialog
        //enable signals and controllers before choosing new component type

        window.editor?.interactive(true)

        addOrUpdateToSystem(componentType, matchingComponentActivation.id as number, slotKey, targetUuids, quantity)

        closeDialog?.()
        return Promise.resolve('Component assigned to system')
      }

      let activatePromise
      activatePromise = restClientInstance('CUSTOM_POST', 'custom', {
        url: 'orgs/' + orgId + '/component_' + componentType + '_activations/',
        data: {
          [componentType]: '/api/component_' + plural(componentType) + '/' + componentId + '/',
        },
      })

      return activatePromise.then(
        (response: any) => {
          notify('Component activated on account & assigned to system.')

          // Future improvement
          // Load component details into Studio so we can use immediately without needing to load components again
          // Currently not working beacuse the response will not include exhibit content
          // window.AccountHelper.loadedData.componentModuleSpecs.push(new window.ModuleType(response.data))

          // Instead, just reload all components before returning to studio
          var callback = function () {
            //signals and controllers are disabled in dialog
            //enable signals and controllers before choosing new component type

            window.editor?.interactive(true)

            addOrUpdateToSystem(componentType, response.data.id, slotKey, targetUuids, quantity)

            closeDialog?.()
          }

          var currentAjaxSessionCounter = window.Designer.AjaxSession.new('WorkspaceHelper.loadWithProfile()')

          window.AccountHelper.loadComponentSpecs(callback, currentAjaxSessionCounter)
        },
        () => {
          notify(translate('Error activating component on account.'), 'warning')
          return Promise.reject('Error activating component on account.')
        }
      )
    },
    []
  )
}
