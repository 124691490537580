import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import { memo } from 'react'
import { useTranslate } from 'react-admin'
import useTableStyles from '../useTableStyles'

const InventoryListTableHeader = () => {
  const classes = useTableStyles()
  const translate = useTranslate()

  return (
    <Grid
      container
      item
      xs={12}
      className={classnames(classes.tableHeaderContainer, classes.rowWrapper)}
      alignItems="center"
      justify="center"
    >
      <Grid item xs={1}>
        {translate('Category')}
      </Grid>
      <Grid item xs={4}>
        {translate('Item')}
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span>{translate('Min')}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span> {translate('On hand')}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span>{translate('Incoming')}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span> {translate('Outgoing')}</span>
      </Grid>
      <Grid item xs={1} className={classes.gridItem}>
        <span>{translate('Available')}</span>
      </Grid>
      <Grid item xs={2} className={classes.gridItem}>
        <span>{translate('View Catalogue')}</span>
      </Grid>
    </Grid>
  )
}

export default memo(InventoryListTableHeader)
