import { AlertDemo } from './alert/AlertDemo'
import ButtonInfo from './button/ButtonInfo'
import { DemoButtons } from './button2/DemoButtons'
import { CheckboxDemo } from './checkbox/CheckboxDemo'
import { DemoChip } from './chip/DemoChip'
import { RadioDemo } from './radio/RadioDemo'
import { SelectDemo } from './select/SelectDemo'
import { DemoSplitButton } from './splitButton/DemoSplitButton'
import { SwitchDemo } from './switch/DemoSwitch'
import { DemoTinyBox } from './tinyBox/DemoTinyBox'
import { DemoToggleButton } from './toggleButton/DemoToggleButton'

const baseRoute = '/ui_library/'

export const uilib_atomsList = [
  {
    name: 'Button Sheet (Legacy)',
    route: baseRoute + 'button-sheet',
    info: <ButtonInfo />,
  },
  {
    name: 'Button',
    route: baseRoute + 'button',
    info: <DemoButtons />,
  },
  {
    name: 'Chip',
    route: baseRoute + 'chip',
    info: <DemoChip />,
  },
  {
    name: 'Switch',
    route: baseRoute + 'switch',
    info: <SwitchDemo />,
  },
  {
    name: 'Checkbox',
    route: baseRoute + 'checkbox',
    info: <CheckboxDemo />,
  },
  {
    name: 'Alert',
    route: baseRoute + 'alert',
    info: <AlertDemo />,
  },
  {
    name: 'Radio',
    route: baseRoute + 'radio',
    info: <RadioDemo />,
  },
  {
    name: 'ToggleButton',
    route: baseRoute + 'toggle-button',
    info: <DemoToggleButton />,
  },
  {
    name: 'Select',
    route: baseRoute + 'select',
    info: <SelectDemo />,
  },
]

export const uilib_moleculesList = [
  {
    name: 'TinyBox & TinyLabel',
    route: baseRoute + 'tiny-box',
    info: <DemoTinyBox />,
  },
  {
    name: 'SplitButton',
    route: baseRoute + 'split-button',
    info: <DemoSplitButton />,
  },
]

export const uilib_menu = [
  {
    name: 'Atoms',
    info_url: 'https://atomicdesign.bradfrost.com/chapter-2/#atoms',
    children: uilib_atomsList,
  },
  {
    name: 'Molecules',
    info_url: 'https://atomicdesign.bradfrost.com/chapter-2/#molecules',
    children: uilib_moleculesList,
  },
]
