import type { OptionGenericType } from '../typeV2'

const getCheckBoxSelectAllMutator = <OptionValue, RendererValue>(SELECT_ALL_OPTION: OptionGenericType<OptionValue>) => {
  const checkBoxSelectAllMutator = ({
    value,
    selectedOption,
    options,
  }: {
    value: OptionValue
    selectedOption: OptionGenericType<OptionValue>
    options: OptionGenericType<OptionValue>[]
  }) => {
    if (!(value instanceof Set)) {
      return value
    }
    if (selectedOption.id === SELECT_ALL_OPTION.id) {
      // click on select all
      const isAllChecked = value.has(selectedOption.value)
      const mutatedValue = isAllChecked ? new Set(options.map((option) => option.value)) : new Set()
      return mutatedValue as RendererValue
    } else if (value.size === 1 && value.has(SELECT_ALL_OPTION.value)) {
      // automatically uncheck
      return new Set() as RendererValue
    }
    return value
  }
  return checkBoxSelectAllMutator
}

export default getCheckBoxSelectAllMutator
