import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ScaffoldOutlineIcon } from 'opensolar-ui'
import React, { useState } from 'react'
import ManageDocusignDialog from 'resources/integrations/docusign/ManageDocusignDialog'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { COLOR_PALETTE } from '../../styles'

interface PropTypes {
  project: ProjectType
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    padding: '24px',
    borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    gap: '40px',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: COLOR_PALETTE.darkGrey,
    display: 'flex',
    gap: '12px',
  },
  detailWrapper: {
    maxWidth: '75%',
  },
  desc: {
    fontSize: 12,
    marginTop: 8,
    color: COLOR_PALETTE.darkGrey,
  },
  actionBtn: {
    //possibly add as new variant?
    backgroundColor: '#4272DD !important',
    color: '#fff !important',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    '&.MuiStepLabel-iconContainer': {
      paddingRight: theme.spacing(1.25),
    },
  },
  icon: {
    '&.MuiStepIcon-root.MuiStepIcon-completed': {
      color: 'blue',
    },
    '&.MuiStepIcon-root.MuiStepIcon-active': {
      color: 'green',
    },
    color: 'red',

    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `1px solid yellow`,
    borderRadius: '50%',
    padding: theme.spacing(0),
  },
  iconText: {
    '&.MuiStepIcon-text': {
      fill: 'blue',
      fontSize: '14px',
      fontWeight: 500,
      color: 'green',
      transform: 'translateY(1px)',
    },
  },
  selectedIconText: {
    '&.MuiStepIcon-text': {
      fontSize: '14px',
      fontWeight: 600,
      fill: 'blue',
      color: 'green',
      transform: 'translateY(1px)',
    },
  },
  label: {
    color: 'blue',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
    '&.MuiStepLabel-label.MuiStepLabel-active': {
      color: 'blue',
      fontSize: '16px',
      fontWeight: 500,
    },
    '&.MuiStepLabel-label.MuiStepLabel-completed': {
      color: 'blue',
      fontSize: '16px',
      fontWeight: 400,
    },
  },
}))
const steps = [
  {
    step: 1,
    label: 'Cost Calculation',
    description: `Cost estimate`,
  },
  {
    step: 2,
    label: 'Quote Creation',
    chip: '5 min',
    description: `Quote Creation`,
  },
  {
    step: 3,
    label: 'Order Submitted',
    description: `Order Submitted`,
  },
]

const PVFScaffoldCTA: React.FC<PropTypes> = ({ project }) => {
  const [showDialog, setShowDialog] = useState(false)
  const classes = useStyles()
  const completed = { 0: true }
  return (
    <>
      {/* TODO: move this to a separate component */}
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <ScaffoldOutlineIcon size={20} />
          <span>Scaffold</span>
        </div>
        <div>
          <Stepper className={classes.row} orientation="horizontal">
            {steps.map((step, index) => (
              <Step key={step.step} completed={completed[index]} active={true}>
                <StepLabel>
                  <span>{step.label}</span>
                </StepLabel>
                <StepContent>
                  {/* <StepButton color="inherit" onClick={() => void 0}>
                    Button
                  </StepButton> */}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      {showDialog && <ManageDocusignDialog project={project} open={showDialog} onClose={() => setShowDialog(false)} />}
    </>
  )
}

export default PVFScaffoldCTA
