import { permissionsSelectors } from 'ducks/permissions'
import { HourGlassOutlineIcon } from 'opensolar-ui'
import CRUDRecordDialog from 'projectSections/sections/common/CRUDRecordDialog'
import Notice from 'projectSections/sections/manage3/common/Notice'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ReferenceField, useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectTransactionTypeV3 } from 'types/projectTransactions'
import { FilesAndTransContext, FilesAndTransContextProps } from '../FilesAndTransactionsSection'
import TransactionFormFields from './TransactionFormFields'
import { TransactionRow } from './TransactionsRow'

export const TransactionsSection: React.FunctionComponent = () => {
  const translate = useTranslate()
  const { ctaSelected, selectCta } = useContext(FilesAndTransContext) as FilesAndTransContextProps
  const { allowView, allowDelete } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_transactions'))
  const placeholderText = allowView
    ? 'You have not created any transactions for this project'
    : "You don't have permission to view transactions for this project"

  const form = useForm()
  const formState = form.getState()
  const record = formState.values

  type ResponseStatus = 'created' | 'edited' | 'deleted'

  type TransactionId = number
  interface TransactionResponseInfo {
    status: ResponseStatus
    id: TransactionId
    response: ProjectTransactionTypeV3 | TransactionId // when deleting a transaction the response is just the ID
  }

  const [transactionResponseInfo, setTransactionResponseInfo] = useState<TransactionResponseInfo | null>(null)
  const [transactionSelected, setTransactionSelected] = useState<ProjectTransactionTypeV3 | null>(null)

  const transactionsData =
    useField('transactions_data', { subscription: { value: true } })?.input?.value || ([] as ProjectTransactionTypeV3[])

  const selectTransaction = useCallback((transaction: ProjectTransactionTypeV3) => {
    setTransactionSelected(transaction)
    selectCta('edit_transaction')
  }, [])

  useEffect(() => {
    let isMounted = true
    if (transactionResponseInfo?.status === 'created') {
      const record = formState.initialValues
      record.transactions_data.push(transactionResponseInfo.response)
      setTransactionResponseInfo(null)
    }
    if (transactionResponseInfo?.status === 'edited' || transactionResponseInfo?.status === 'deleted') {
      const updatedTransactionsData: ProjectTransactionTypeV3[] = transactionsData
        .filter((f) => f.id !== transactionResponseInfo.id)
        .concat(typeof transactionResponseInfo?.response !== 'number' ? [transactionResponseInfo?.response] : [])
      formState.initialValues.transactions_data = updatedTransactionsData
      form.change('transactions_data', updatedTransactionsData)
      setTransactionResponseInfo(null)
    }
    return () => {
      isMounted = false
    }
  }, [transactionSelected, transactionResponseInfo])

  return (
    <>
      {transactionsData.length ? (
        <section>
          {transactionsData.map((transaction, i) => (
            <ReferenceField
              getResourceLinkPath={() => false}
              record={transaction}
              source="payment_option"
              reference="payment_options"
              renderChildrenBeforeRecord={true}
              key={`${transaction?.id}--${transaction?.transaction_datetime}--${i}`}
            >
              <TransactionRow
                transactionRecordData={transaction}
                transactionSelectFunc={selectTransaction}
                setRecordResponseInfo={setTransactionResponseInfo}
              />
            </ReferenceField>
          ))}
        </section>
      ) : (
        <section>
          <span>
            <Notice
              icon={<HourGlassOutlineIcon variant={3} size={26} color={COLOR_PALETTE.darkGrey} />}
              mainText={translate('No transactions found')}
              subText={translate(placeholderText)}
            />
          </span>
        </section>
      )}
      {ctaSelected !== '' && (
        <>
          <CRUDRecordDialog
            isOpen={ctaSelected === 'add_transaction'}
            record={{ project: record.url }}
            basePath={'/transactions'}
            resource={'transactions'}
            onDismiss={() => selectCta('')}
            formContent={<TransactionFormFields dialogType="create" />}
            notificationOnSuccess={true}
            customSuccessNotificationText={translate('Your payment request has been logged')}
            updateFormValsFn={(response) =>
              setTransactionResponseInfo({ status: 'created', id: response.id, response })
            }
            dialogTitle={translate('Request Payment')}
            width={'sm'}
          />
          <CRUDRecordDialog
            isOpen={ctaSelected === 'edit_transaction'}
            record={transactionSelected}
            basePath={'/transactions'}
            resource={'transactions'}
            onDismiss={() => {
              setTransactionSelected(null)
              selectCta('')
            }}
            formContent={<TransactionFormFields dialogType="edit" />}
            notificationOnSuccess={true}
            customSuccessNotificationText={translate('Your transaction has been edited')}
            updateFormValsFn={(response) => setTransactionResponseInfo({ status: 'edited', id: response.id, response })}
            dialogTitle={translate('Edit Transaction')}
            width={'sm'}
            deleteDisabled={!allowDelete}
            removeRecordFromForm={(response) =>
              setTransactionResponseInfo({ status: 'deleted', id: response, response })
            }
            hideDeleteBtn
          />
        </>
      )}
    </>
  )
}
