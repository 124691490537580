import { TagOutlineIcon } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import React from 'react'
import { TextInput } from 'react-admin'
import { getProjectSectionStyles } from '../styles'
import { InputContainer, InputRow } from './styles'

const MarketingAccordion: React.FC = (props) => {
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  return (
    <AccordionCard
      name={'sales'}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={translate('Sales & Marketing')}
      titleIcon={TagOutlineIcon}
      content={MarketingContent}
      lazyRender={true}
    />
  )
}

const MarketingContent = (props) => {
  return (
    <InputContainer>
      <InputRow>
        <TextInput source={'id'} variant="outlined" label={'ID'} disabled={true} fullWidth />
        <TextInput source={'identifier'} variant="outlined" fullWidth label={'Identifier'} />
      </InputRow>
      <InputRow>
        <TextInput source={'lead_source'} variant="outlined" fullWidth label={'Lead Source'} />
        <TextInput source={'notes'} variant="outlined" fullWidth label={'Notes'} />
      </InputRow>
    </InputContainer>
  )
}

export default MarketingAccordion
