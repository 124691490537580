import { removeProjectError } from 'actions/project'
import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { projectMilestonesSelectors, saveProjectMilestones, setProjectMilestonesLoading } from 'ducks/projectMilestones'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import { useSortedProjectErrors } from 'projectSections/errors/useSortedProjectErrors'
import { addProjectErrorToReduxStoreDirect } from 'projectSections/hooks/useAddProjectErrors'
import { fetchProjectInvoice } from 'projectSections/sections/manage/cashFlowTransactions/invoices/utils'
import { useNotify, useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { StudioSystemType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { urlToId } from 'util/misc'
import { useIsProjectUsingCashFlow } from '../hooks'
import { useIsCalculating } from '../projectProgress/cashFlow/utils'

const restClientInstance = restClient(window.API_ROOT + '/api')

/**
 * Fetches all payment requests for a project and saves the resuts in redux to avoid duplicate requests
 * @param  {number} projectId The id of the project
 * @param {number | undefined} orgIdFromPaymentOption The id of the org whose payment options are being used which with Teams may not be the logged in org
 */
export const useGetAllPaymentRequestsForProject = (projectId: number, orgIdFromPaymentOption: number | undefined) => {
  const [sentPaymentRequests, setSentPaymentRequests] = useState<PaymentRequestType[]>([])

  const loggedInOrgId = useSelector(orgSelectors.getOrg)?.id
  const milestoneRefreshTrigger = useSelector(projectMilestonesSelectors.getRefreshMilestonesTrigger)
  const cashFlowIsVisible = useGetIsCashFlowVisible()

  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()

  const fetchSentRequests = useCallback(() => {
    // don't fetch if we don't have a project id or this org doesn't have access to CashFlow
    if (projectId && cashFlowIsVisible) {
      dispatch(setProjectMilestonesLoading())
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgIdFromPaymentOption || loggedInOrgId}/payments/?project_id=${projectId}`,
      })
        .then((res) => {
          setSentPaymentRequests(res.data.results)
          dispatch(saveProjectMilestones(res.data.results))
        })
        .catch((err) => {
          notify(translate('We were unable to retrieve the list of invoices for this project.'), 'warning')
          dispatch(saveProjectMilestones([]))
        })
    }
  }, [projectId, cashFlowIsVisible])

  useEffect(() => {
    fetchSentRequests()
  }, [projectId, milestoneRefreshTrigger, cashFlowIsVisible])

  return sentPaymentRequests
}

/**
 * Fetches all payment requests and expected milestone payments for a particular system and payment option
 * @param  {StudioSystemType | undefined} soldSystem The system you want milestones for, this is generally the sold system
 * @param {PaymentOptionDataType | undefined} soldPaymentOption The payment option you want milestones for, this is generally the sold payment option
 */
export const useGetPaymentRequests = (
  projectId: number,
  soldSystem: StudioSystemType | undefined,
  soldPaymentOption: PaymentOptionDataType | undefined
) => {
  const [sentPaymentRequests, setSentPaymentRequests] = useState<PaymentRequestType[]>([])
  const [paymentRequestIncludingUnsent, setPaymentRequestsIncludingUnsent] = useState<PaymentRequestType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [requestsFetched, setRequestsFetched] = useState<boolean>(false)

  const isCalculating = useIsCalculating(soldSystem?.uuid)
  const milestoneRefreshTrigger = useSelector(projectMilestonesSelectors.getRefreshMilestonesTrigger)
  const notify = useNotify()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const savedMilestones = useSelector(projectMilestonesSelectors.getProjectMiletones)
  const milestonesAreLoading = useSelector(projectMilestonesSelectors.getMilestonesAreLoading)
  const cashFlowIsVisible = useGetIsCashFlowVisible()
  const isCustomer = !useSelector(rolesSelectors.getCurrentRoleId)

  // all customers are allowed to query the payment requests associated with their project
  // and split doesn't work with customers so we can't rely on a feature flag for them
  const allowedToQueryPayments = isCustomer || cashFlowIsVisible

  const fetchSentRequests = useCallback(() => {
    if (projectId && soldSystem?.uuid && soldPaymentOption?.id && !milestonesAreLoading && allowedToQueryPayments) {
      if (savedMilestones) {
        setSentPaymentRequests(savedMilestones)
        setRequestsFetched(true)
        return
      }
      setIsLoading(true)
      dispatch(setProjectMilestonesLoading())
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${soldPaymentOption.org_id}/payments/?project_id=${projectId}`,
      })
        .then((res) => {
          setSentPaymentRequests(res.data.results)
          setRequestsFetched(true)
          dispatch(saveProjectMilestones(res.data.results))
        })
        .catch((err) => {
          notify(translate('We were unable to retrieve the list of invoices for this project.'), 'warning')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [
    projectId,
    soldSystem?.uuid,
    soldPaymentOption?.id,
    savedMilestones,
    milestonesAreLoading,
    allowedToQueryPayments,
  ])

  useEffect(() => {
    fetchSentRequests()
  }, [projectId, soldSystem?.uuid, soldPaymentOption?.id, fetchSentRequests])

  useEffect(() => {
    if (milestoneRefreshTrigger || refreshSystemsTrigger) fetchSentRequests()
  }, [milestoneRefreshTrigger, refreshSystemsTrigger, fetchSentRequests])

  // combine the sent payment requests we've fetched with unsent ones from the sold payment option
  // do this every time calcs finish to ensure updated unsent requests are returned
  useEffect(() => {
    if (requestsFetched && soldSystem && soldPaymentOption) {
      let unsentPayments = soldPaymentOption?.expected_milestone_payments?.filter((milestone) => {
        const match = sentPaymentRequests?.find((pmtReq) => {
          // ignore any standalone payment requests that have been sent. Those will always be in the sentPaymentRequests list
          if (pmtReq.is_standalone) return false
          // ignore any payment requests that have been sent but cancelled, we should still
          // have a record of upcoming payment for those
          if (pmtReq.status === 'cancelled') return false
          if (pmtReq.payment_milestone_configuration) {
            return pmtReq.payment_milestone_configuration === milestone.payment_milestone_configuration_id
          } else {
            return pmtReq.payment_milestone_override_uuid === milestone.override_uuid
          }
        })
        return !match
      })
      let combined = sentPaymentRequests?.filter((req) => req.payment_option === soldPaymentOption?.id)
      unsentPayments?.forEach((milestone) => {
        if (soldPaymentOption.org_id) {
          combined.push({
            amount_requested: milestone.payment_amount,
            amount_paid: 0,
            payment_method: null,
            currency: milestone.currency,
            date_completed: null,
            date_refunded: null,
            date_requested: null,
            date_due: null,
            date_paid_out: null,
            id: null,
            payment_number: milestone.payment_number,
            org: soldPaymentOption.org_id,
            payment_collection_url: null,
            payment_milestone_configuration: milestone.payment_milestone_configuration_id,
            payment_option: soldPaymentOption.id,
            project: projectId,
            psp_integration: 'bluesnap',
            status: 'unsent',
            system: soldSystem?.userData?.id,
            is_standalone: milestone.payment_milestone_configuration_id === null && milestone.override_uuid === null,
            title: milestone.title,
            payment_milestone_override_uuid: milestone.override_uuid,
            percentage: milestone.percentage,
            is_deposit: milestone.is_deposit,
          })
        }
      })
      combined = combined.sort((a, b) => a.payment_number - b.payment_number)
      setPaymentRequestsIncludingUnsent(combined)
    }
  }, [soldPaymentOption?.expected_milestone_payments, sentPaymentRequests, requestsFetched, isCalculating])

  return { paymentRequests: paymentRequestIncludingUnsent, isLoading, refresh: fetchSentRequests }
}

export const useGetSoldRecords = (project): [StudioSystemType | undefined, PaymentOptionDataType | undefined] => {
  const [soldSystem, setSoldSystem] = useState<StudioSystemType | undefined>(undefined)
  const [soldPaymentOption, setSoldPaymentOption] = useState<PaymentOptionDataType | undefined>(undefined)
  const soldSystemURL = project?.system_sold
  const soldPmtURL = project?.payment_option_sold

  const setSoldRecords = useCallback(() => {
    if (soldSystemURL && soldPmtURL) {
      const allSystemsOnProject = window.editor.getSystems()
      const soldSystem = project?.systems?.find((sys) => sys.url === soldSystemURL)
      setSoldSystem(soldSystem)
      if (soldSystem) {
        const soldPmtId = urlToId(soldPmtURL)
        const soldPaymentOption = allSystemsOnProject
          ?.find((sys) => sys.uuid === soldSystem.uuid)
          ?.payment_options?.find((pmt) => pmt.id === soldPmtId)
        setSoldPaymentOption(soldPaymentOption)
      }
    } else {
      if (soldSystem && !soldSystemURL) setSoldSystem(undefined)
      if (soldPaymentOption && !soldPmtURL) setSoldPaymentOption(undefined)
    }
  }, [soldPmtURL, soldSystemURL])

  useEffect(() => {
    setSoldRecords()
  }, [soldSystemURL, soldPmtURL])

  return [soldSystem, soldPaymentOption]
}

/**
 * Used in ProjectForm to get cashflow required customer info error message.
 * Once rendered, automatically adds ProjectError that shows error icon and popover in the Project>Payments tab
 */
export const useCashflowCustomerInfoValidations = () => {
  const project = useFormState().values
  const orgHasCashFlow = useSelector(orgSelectors.getCashFlowIsActive)
  const orgId = useSelector(authSelectors.getOrgId)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const form = useForm()
  const notify = useNotify()
  const dispatch = useDispatch()
  const fetchSystems = () => window.editor?.getSystems()
  const [isLoading, setIsLoading] = useState(true)
  const [systems, setSystems] = useState<StudioSystemType[]>(fetchSystems())
  const [soldSystem, soldPaymentOption] = useGetSoldRecords(project)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)
  const hasError = useGetCashflowCustomerInfoError()

  const isSold = useMemo(() => Boolean(project?.system_sold && project?.payment_option_sold), [
    project?.system_sold,
    project?.payment_option_sold,
  ])

  const showAlert = useMemo(() => Boolean(orgHasCashFlow && isProjectUsingCashflow && !isLoading && systems.length), [
    orgHasCashFlow,
    isLoading,
    systems.length,
    isProjectUsingCashflow,
  ])

  const errorMessage = useMemo(() => {
    const recipient = project?.contacts_data?.find((e) => !!e?.email)
    const invoiceDetails = project?.invoice_details // Overwrites project-level customer info (contacts_data) if invoice details are provided

    const missingRequiredRecipientFields = [
      invoiceDetails?.recipient_first_name || recipient?.first_name,
      invoiceDetails?.recipient_email || recipient?.email,
      invoiceDetails?.recipient_last_name || recipient?.family_name,
    ].some((i) => !i)

    const missingRequiredAddressFields = [
      invoiceDetails?.billing_address_line_1 || project?.address,
      invoiceDetails?.billing_address_postal_code || project?.zip,
      invoiceDetails?.billing_address_city || project?.locality,
      invoiceDetails?.billing_address_country || project?.country_iso2,
    ].some((i) => !i)

    if (missingRequiredAddressFields || missingRequiredRecipientFields) {
      if (isSold)
        return 'Projects with a CashFlow payment method must include Customer Name, address and email address. Please enter these values on the Info page or in the invoice details by clicking the edit invoice details button on the invoices section below.'
      return 'Projects with a CashFlow payment method must include customer name, address and email address. Please enter these values on the Info page or in the invoice details on the CashFlow payment card.'
    } else if (project?.country_iso2 === 'US' && !(invoiceDetails?.billing_address_state || project?.state)) {
      return 'Projects with a CashFlow payment method must include project country and state (for US-based projects). Please enter these values on the Info page.'
    } else {
      return false
    }
  }, [
    isSold,
    project?.invoice_details,
    project?.contacts_data,
    project?.address,
    project?.zip,
    project?.locality,
    project?.country_iso2,
    project?.state,
  ])

  useEffect(() => {
    if (orgId && project?.id && orgHasCashFlow) {
      fetchProjectInvoice(orgId, project?.id)
        .then((data) => {
          form.change('invoice_details', data)
        })
        .catch((error) => {
          notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [orgId, project?.id, orgHasCashFlow])

  useEffect(() => {
    if (hasError) {
      dispatch(
        removeProjectError({
          key: 'CASHFLOW_REQUIRED_CUSTOMER_INFO_ERROR',
          source: 'payment_cashflow',
        })
      )
    }

    if (showAlert) {
      if (errorMessage) {
        addProjectErrorToReduxStoreDirect({
          messageType: 'text',
          source: 'payment_cashflow',
          key: 'CASHFLOW_REQUIRED_CUSTOMER_INFO_ERROR',
          message: errorMessage,
          severity: 'error',
          category: 'payment_cashflow',
        })
      }
    }
  }, [showAlert, errorMessage, refreshSystemsTrigger])

  return [errorMessage, isLoading]
}

export const useGetCashflowCustomerInfoError = (): string => {
  // Get the project errors from redux with key CASHFLOW_REQUIRED_CUSTOMER_INFO_ERROR
  const errors = useSortedProjectErrors([
    { keys: ['CASHFLOW_REQUIRED_CUSTOMER_INFO_ERROR'], sources: ['payment_cashflow'] },
  ])

  // Bypass types since we know key CASHFLOW_REQUIRED_CUSTOMER_INFO_ERROR will always have string value
  if (errors.length && errors[0]?.message) return String(errors[0].message)
  return ''
}

export const useGetProjectHasCashFlow = () => {
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  const hasCashFlowOnThisProject = useMemo(() => {
    const systems = window.editor?.getSystems()
    let foundCashFlow = false
    systems?.forEach((sys) => {
      sys.payment_options?.forEach((pmt) => {
        if (pmt?.expected_milestone_payments?.length > 0) foundCashFlow = true
      })
    })
    return foundCashFlow
  }, [refreshSystemsTrigger])

  return hasCashFlowOnThisProject
}
