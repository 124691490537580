import { orgSelectors } from 'ducks/orgs'
import useAdBanner from 'elements/ads/useAdBanner'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import HardwareAdBannerContainer from './HardwareAdBannerContainer'
import CityPlumbingBanner from './banners/CityPlumbingBanner'
import HdmBanner from './banners/HdmBanner'
import SegenBanner from './banners/SegenBanner'
import VtacBanner from './banners/VtacBanner'

const OrderByProjectTopBanner = () => {
  const hardwareSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)

  const AdBanner = useAdBanner({
    formats: ['leaderboard'],
    placement: 'shop_order_by_project_top',
    requiredWindowSize: { width: 960, height: 800 },
  })

  const OriginalBanner = useMemo(() => {
    if (hardwareSupplier === HardwareSupplierEnum.Segen) {
      return <SegenBanner renderOBPBanner={false} />
    }
    if (hardwareSupplier === HardwareSupplierEnum.CityPlumbing) {
      return <CityPlumbingBanner renderOBPBanner={false} />
    }
    if (hardwareSupplier === HardwareSupplierEnum.Hdm) {
      return <HdmBanner renderOBPBanner={false} />
    }
    if (hardwareSupplier === HardwareSupplierEnum.VtacUk || hardwareSupplier === HardwareSupplierEnum.VtacPoland) {
      return <VtacBanner renderOBPBanner={false} />
    }
  }, [hardwareSupplier])

  if (AdBanner != null) {
    return <HardwareAdBannerContainer> {AdBanner} </HardwareAdBannerContainer>
  }

  if (OriginalBanner != null) {
    return OriginalBanner
  }
  return null
}

export default OrderByProjectTopBanner
