import { ComponentVersions } from 'opensolar-ui'

export type ComponentVersionInfo = {
  label: string
  versions: ComponentVersions
  default?: true
}

export const LibraryVersions: ComponentVersionInfo[] = [
  {
    label: 'UX 2.0',
    versions: {
      button: 1,
      chip: 1,
      icon_button: 1,
      switch: 1,
      checkbox: 1,
      radio: 1,
      alert: 1,
      dialog: 1,
      select: 1,
      text_field: 1,
      field_title: 1,
      date_picker: 1,
      date_time_picker: 1,
    },
    default: true,
  },
  {
    label: 'UX 3.0',
    versions: {
      button: 3,
      chip: 2,
      icon_button: 2,
      switch: 2,
      checkbox: 2,
      radio: 2,
      alert: 2,
      dialog: 2,
      select: 2,
      text_field: 2,
      field_title: 2,
      date_picker: 2,
      date_time_picker: 2,
    },
  },
]
