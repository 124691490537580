import { styles } from './styles'

const requireDownPaymentEnabled = {
  dependsOn: ['variable_down_payment_enabled'],
  resolve: (value) => {
    return value.variable_down_payment_enabled === true
  },
}

const requireInitialLoanEnabled = {
  dependsOn: ['variable_initial_loan_enabled'],
  resolve: (value) => {
    return value.variable_initial_loan_enabled === true
  },
}

const requireInitialLoanEnabledWithBulletEnabled = {
  dependsOn: ['variable_initial_loan_enabled', 'variable_initial_bullet_enabled'],
  resolve: (value) => {
    return value.variable_initial_loan_enabled === true && value.variable_initial_bullet_enabled === true
  },
}

const requireFinalLoanEnabledWithBulletEnabled = {
  dependsOn: ['variable_final_bullet_enabled'],
  resolve: (value) => {
    return value.variable_final_bullet_enabled === true
  },
}

const disabledForAllIntegrations = () => {
  return function (formData) {
    return !!formData.variable_integration
  }
}

// Disable various params for financial integrations
const disabledOnParticularIntegrations = (
  financialIntegrations = [
    'sunlight',
    'plenti',
    'energy_ease',
    'loanpal',
    'mosaic',
    'sungage',
    'brighte',
    'commonbond',
    'phoenix',
  ]
) => {
  return function (formData) {
    return financialIntegrations.includes(formData.variable_integration)
  }
}

// hide and disable some fields if this payment option has a cash flow paymentsconfiguration model applied to it
const disableWhenCashFlowApplicable = (formData) => {
  return Boolean(formData.auto_applied_payments_configuration) || Boolean(formData.payments_configuration)
}

export const payment_types_and_variables = [
  {
    title: 'cash',
    label: 'Cash',
    variables: [
      {
        name: 'deposit_percentage',
        units: '%',
        label: 'Deposit',
        style: styles.inputStyle,
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_deposit_percentage',
        disabled: disableWhenCashFlowApplicable,
        hiddenOnDisabled: disableWhenCashFlowApplicable,
      },
      {
        name: 'deposit_min',
        units: '$',
        label: 'Deposit Minimum',
        style: styles.inputStyle,
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_deposit_min',
        disabled: disableWhenCashFlowApplicable,
        hiddenOnDisabled: disableWhenCashFlowApplicable,
      },
      {
        name: 'deposit_max',
        units: '$',
        label: 'Deposit Maximum',
        style: styles.inputStyle,
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_deposit_max',
        disabled: disableWhenCashFlowApplicable,
        hiddenOnDisabled: disableWhenCashFlowApplicable,
      },
      {
        name: 'collect_signature',
        validationMethod: 'trueorfalse',
        variableName: 'variable_collect_signature',
      },
      {
        type: 'DealerFee',
        disabled: disabledOnParticularIntegrations([
          'energy_ease',
          'sungage',
          'loanpal',
          'mosaic',
          'commonbond',
          'phoenix',
        ]),
        extraFields: ['dealer_fee_fixed', 'dealer_fee_percentage'],
      },
      {
        name: 'integration',
        validationMethod: 'optional',
        variableName: 'variable_integration',
      },
      {
        name: 'dealer_fee_percentage',
        validationMethod: 'floatoptional',
        variableName: 'variable_dealer_fee_percentage',
      },
      { name: 'dealer_fee_fixed', validationMethod: 'floatoptional', variableName: 'variable_dealer_fee_fixed' },
    ],
  },
  {
    title: 'loan',
    label: 'Loan',
    variables: [
      {
        type: 'LoanParameters',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) =>
          formData.variable_integration === 'energy_ease' ||
          formData.variable_integration === 'plenti' ||
          formData.variable_integration === 'brighte',
      },
      {
        type: 'Prepayment',
        disabled: disabledForAllIntegrations(),
        extraFields: ['prepayment_formula', 'loan_type', 'prepayment_period', 'prepayment_percentage'],
      },
      {
        type: 'PromotionalPeriod',
        disabled: disabledForAllIntegrations(),
        extraFields: ['no_payment_period', 'interest_only_period', 'no_interest_period'],
      },
      {
        type: 'DealerFee',
        disabled: disabledOnParticularIntegrations(['energy_ease', 'sungage', 'loanpal', 'commonbond', 'mosaic']),
        extraFields: ['dealer_fee_fixed', 'dealer_fee_percentage'],
      },
      { type: 'DownPayment', extraFields: ['down_payment_percentage', 'down_payment_min', 'down_payment_max'] },
      {
        name: 'payment_factors',
        variableName: 'variable_payment_factors',
        disabled: disabledForAllIntegrations(),
      },
      {
        name: 'interest_rate',
        units: '%',
        validationMethod: 'floatrequired',
        variableName: 'variable_interest_rate',
      },
      {
        name: 'term_years',
        units: 'periods',
        validationMethod: 'floatrequired',
        variableName: 'variable_term_years',
      },
      { name: 'prepayment_formula', validationMethod: 'optional', variableName: 'variable_prepayment_formula' },
      {
        name: 'loan_type',
        validationMethod: 'optional',
        disabled: disabledForAllIntegrations(),
        variableName: 'variable_loan_type',
      },
      {
        name: 'prepayment_period',
        units: 'periods',
        validationMethod: 'floatoptional',
        variableName: 'variable_prepayment_period',
      },
      {
        name: 'prepayment_percentage',
        units: '%',
        validationMethod: 'floatoptional',
        variableName: 'variable_prepayment_percentage',
      },
      {
        name: 'no_payment_period',
        units: 'periods',
        label: 'No Payment Period',
        validationMethod: 'floatoptional',
        variableName: 'variable_no_payment_period',
      },
      {
        name: 'interest_only_period',
        units: 'periods',
        validationMethod: 'floatoptional',
        variableName: 'variable_interest_only_period',
      },
      {
        name: 'no_interest_period',
        units: 'periods',
        label: 'No Interest Period',
        validationMethod: 'floatoptional',
        variableName: 'variable_no_interest_period',
      },
      { name: 'dealer_fee_fixed', validationMethod: 'floatoptional', variableName: 'variable_dealer_fee_fixed' },
      {
        name: 'fixed_fee_per_period',
        validationMethod: 'floatoptional',
        variableName: 'variable_fixed_fee_per_period',
      },
      {
        name: 'dealer_fee_percentage',
        validationMethod: 'floatoptional',
        variableName: 'variable_dealer_fee_percentage',
      },
      {
        name: 'dealer_fee_percentage_adjustment',
        validationMethod: 'floatoptional',
        variableName: 'variable_dealer_fee_percentage_adjustment',
      },
      {
        name: 'down_payment_percentage',
        validationMethod: 'floatoptional',
        variableName: 'variable_down_payment_percentage',
      },
      { name: 'down_payment_min', validationMethod: 'floatoptional', variableName: 'variable_down_payment_min' },
      { name: 'down_payment_max', validationMethod: 'floatoptional', variableName: 'variable_down_payment_max' },
      {
        name: 'collect_signature',
        validationMethod: 'trueorfalse',
        variableName: 'variable_collect_signature',
      },
      {
        name: 'integration',
        validationMethod: 'optional',
        variableName: 'variable_integration',
      },
      { name: 'payment_frequency', validationMethod: 'truthy', variableName: 'variable_payment_frequency' },
      { name: 'channels', validationMethod: 'optional', variableName: 'variable_channels' },
      { name: 'offer_id', validationMethod: 'optional', variableName: 'variable_offer_id' },
      { name: 'states', validationMethod: 'optional', variableName: 'variable_states' },
      { name: 'category_id', validationMethod: 'optional', variableName: 'variable_category_id' },
      { name: 'is_flex_pay', validationMethod: 'optional', variableName: 'variable_is_flex_pay' },
      { name: 'lender_name', validationMethod: 'optional', variableName: 'variable_lender_name' },
      { name: 'payment_factor', validationMethod: 'optional', variableName: 'variable_payment_factor' },
      {
        name: 'payment_factor_post_itc',
        validationMethod: 'optional',
        variableName: 'variable_payment_factor_post_itc',
      },
      { name: 'payment_factor_no_itc', validationMethod: 'optional', variableName: 'variable_payment_factor_no_itc' },
      {
        name: 'banner_image_url',
        validationMethod: 'optional',
        variableName: 'variable_banner_image_url',
      },
      {
        name: 'maximum_finance_amount',
        validationMethod: 'floatoptional',
        variableName: 'variable_maximum_finance_amount',
      },
      {
        name: 'minimum_finance_amount',
        validationMethod: 'floatoptional',
        variableName: 'variable_minimum_finance_amount',
      },
      {
        name: 'max_deposit_percentage',
        validationMethod: 'floatoptional',
        variableName: 'variable_max_deposit_percentage',
      },
    ],
  },
  {
    title: 'loan_advanced',
    label: 'Loan (Advanced) (Deprecated)',
    variables: [
      {
        label: 'Enable Down Payment',
        name: 'down_payment_enabled',
        type: 'BooleanInput',
        style: Object.assign({}, styles.inputStyle, { height: 35, paddingTop: 37 }),
        variableName: 'variable_down_payment_enabled',
      },

      {
        label: 'Enable Initial Loan Term',
        name: 'initial_loan_enabled',
        type: 'BooleanInput',
        style: Object.assign({}, styles.inputStyle, { height: 35, paddingTop: 37 }),
        variableName: 'variable_initial_loan_enabled',
      },

      { cb: true, ...requireDownPaymentEnabled, key: 'cbdp' },
      { h1: 'Down Payment', ...requireDownPaymentEnabled },

      {
        label: 'Amount',
        name: 'down_payment_percentage',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_down_payment_percentage',
        ...requireDownPaymentEnabled,
      },
      {
        label: 'Minimum',
        name: 'down_payment_min',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_down_payment_min',
        ...requireDownPaymentEnabled,
      },
      {
        label: 'Maximum',
        name: 'down_payment_max',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_down_payment_max',
        ...requireDownPaymentEnabled,
      },

      { cb: true, ...requireDownPaymentEnabled, key: 'cbddp' },
      { h2: 'Delay Down Payment', ...requireDownPaymentEnabled },
      {
        label: 'Duration',
        name: 'delayed_down_payment_duration',
        units: 'Months',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_delayed_down_payment_duration',
        ...requireDownPaymentEnabled,
      },
      {
        label: 'Interest Rate',
        name: 'down_payment_interest_rate',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_down_payment_interest_rate',
        ...requireDownPaymentEnabled,
      },
      {
        label: 'Dealer Fee',
        name: 'down_payment_dealer_fee_percentage',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_down_payment_dealer_fee_percentage',
        ...requireDownPaymentEnabled,
      },
      {
        label: 'Dealer Fee',
        name: 'down_payment_dealer_fee_fixed',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_down_payment_dealer_fee_fixed',
        ...requireDownPaymentEnabled,
      },

      { cb: true, key: 'cbil' },

      { h1: 'Initial Loan', ...requireInitialLoanEnabled },

      {
        label: 'Payment Frequency',
        name: 'initial_payment_frequency',
        validationMethod: 'truthy',
        type: 'LoanAdvancedPaymentFrequencySelectInput',
        variableName: 'variable_initial_payment_frequency',
        style: Object.assign({}, styles.mediumLeft, { height: 72 }),
        ...requireInitialLoanEnabled,
      },
      {
        label: 'Term',
        name: 'initial_term',
        units: 'periods',
        validationMethod: 'floatrequired',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_term',
        ...requireInitialLoanEnabled,
      },
      {
        label: 'Interest Rate',
        name: 'initial_interest_rate',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_interest_rate',
        ...requireInitialLoanEnabled,
      },
      {
        label: 'Dealer Fee',
        name: 'initial_dealer_fee_percentage',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_dealer_fee_percentage',
        ...requireInitialLoanEnabled,
      },
      {
        label: 'Dealer Fee',
        name: 'initial_dealer_fee_fixed',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_dealer_fee_fixed',
        ...requireInitialLoanEnabled,
      },
      { cb: true, ...requireInitialLoanEnabled, key: 'cbilpr' },
      { h2: 'Initial Loan Principal Reduction', ...requireInitialLoanEnabled },
      {
        label: 'Amount',
        name: 'initial_principal_reduction',
        units: '%',
        validationMethod: 'floatrequired',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_principal_reduction',
        ...requireInitialLoanEnabled,
      },
      {
        label: 'Minimum',
        name: 'initial_principal_reduction_min',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_principal_reduction_min',
        ...requireInitialLoanEnabled,
      },
      {
        label: 'Maximum',
        name: 'initial_principal_reduction_max',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_principal_reduction_max',
        ...requireInitialLoanEnabled,
      },

      //% is the percentage of the total principle reduction for the initial loan term that comes from the bullet

      {
        label: 'Bullet/Balloon Payment',
        name: 'initial_bullet_enabled',
        type: 'BooleanInput',
        style: Object.assign({}, styles.inputStyle, { height: 35, paddingTop: 37 }),
        variableName: 'variable_initial_bullet_enabled',
        ...requireInitialLoanEnabled,
      },

      { cb: true, ...requireInitialLoanEnabledWithBulletEnabled, key: 'cbilbbp' },

      { h2: 'Initial Loan Bullet/Balloon Payment', ...requireInitialLoanEnabledWithBulletEnabled },
      {
        label: 'Amount',
        name: 'initial_bullet_payment_percentage',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_bullet_payment_percentage',
        ...requireInitialLoanEnabledWithBulletEnabled,
      },
      {
        label: 'Minimum',
        name: 'initial_bullet_payment_min',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_bullet_payment_min',
        ...requireInitialLoanEnabledWithBulletEnabled,
      },
      {
        label: 'Maximum',
        name: 'initial_bullet_payment_max',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_initial_bullet_payment_max',
        ...requireInitialLoanEnabledWithBulletEnabled,
      },

      { cb: true, key: 'cbfl' },
      { h1: 'Final Loan' },
      {
        label: 'Payment Frequency',
        name: 'final_payment_frequency',
        type: 'LoanAdvancedPaymentFrequencySelectInput',
        validationMethod: 'truthy',
        variableName: 'variable_final_payment_frequency',
        style: Object.assign({}, styles.mediumLeft, { height: 72 }),
      },
      {
        label: 'Term',
        name: 'final_term',
        units: 'periods',
        validationMethod: 'floatrequired',
        type: 'TextInput',
        variableName: 'variable_final_term',
        style: styles.inputStyle,
      },
      {
        label: 'Interest Rate',
        name: 'final_interest_rate',
        units: '%',
        validationMethod: 'floatrequired',
        type: 'TextInput',
        variableName: 'variable_final_interest_rate',
        style: styles.inputStyle,
      },
      {
        label: 'Dealer Fee',
        name: 'final_dealer_fee_percentage',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_final_dealer_fee_percentage',
        style: styles.inputStyle,
      },
      {
        label: 'Dealer Fee',
        name: 'final_dealer_fee_fixed',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_final_dealer_fee_fixed',
        style: styles.inputStyle,
      },

      {
        label: 'Bullet/Balloon Payment',
        name: 'final_bullet_enabled',
        type: 'BooleanInput',
        variableName: 'variable_final_bullet_enabled',
        style: Object.assign({}, styles.inputStyle, { height: 35, paddingTop: 37 }),
      },

      { cb: true, ...requireFinalLoanEnabledWithBulletEnabled, key: 'cbflbbp' },
      { h2: 'Final Loan Bullet/Balloon Payment', ...requireFinalLoanEnabledWithBulletEnabled },
      {
        label: 'Amount',
        name: 'final_bullet_payment_percentage',
        units: '%',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_final_bullet_payment_percentage',
        style: styles.inputStyle,

        ...requireFinalLoanEnabledWithBulletEnabled,
      },
      {
        label: 'Minimum',
        name: 'final_bullet_payment_min',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_final_bullet_payment_min',
        style: styles.inputStyle,

        ...requireFinalLoanEnabledWithBulletEnabled,
      },
      {
        label: 'Maximum',
        name: 'final_bullet_payment_max',
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        style: styles.inputStyle,
        variableName: 'variable_final_bullet_payment_max',
        ...requireFinalLoanEnabledWithBulletEnabled,
      },

      { cb: true, key: 'cbos' },
      { h1: 'Other Settings' },
      {
        name: 'collect_signature',
        validationMethod: 'trueorfalse',
        variableName: 'variable_collect_signature',
      },
      {
        name: 'integration',
        validationMethod: 'optional',
        variableName: 'variable_integration',
      },
    ],

    //@TODO: Data migration required: Convert loan_term from years into periods for existing data
  },
  {
    title: 'ppa',
    label: 'PPA',
    variables: [
      {
        name: 'ppa_dollars_per_kwh',
        label: 'PPA',
        style: styles.inputStyle,
        units: '$/kWh',
        variableName: 'variable_ppa_dollars_per_kwh',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        type: 'TextInput',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      }, // will be replaced with currency
      {
        name: 'ppa_escalator_annual',
        label: 'PPA Escalator',
        style: styles.inputStyle,
        units: '%/annual',
        variableName: 'variable_ppa_escalator_annual',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
        type: 'TextInput',
      },
      {
        name: 'ppa_upfront_payment',
        label: 'PPA Upfront Payment',
        style: styles.inputStyle,
        units: '$',
        variableName: 'variable_ppa_upfront_payment',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        disabled: disabledForAllIntegrations(),
        type: 'TextInput',
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      },

      {
        name: 'ppa_dollars_per_kwh_exports',
        label: 'PPA Export',
        style: styles.inputStyle,
        units: '$/kWh',
        variableName: 'variable_ppa_dollars_per_kwh_exports',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
        type: 'TextInput',
      }, // will be replaced with currency
      {
        name: 'ppa_escalator_annual_exports',
        label: 'PPA Export Escalator',
        style: styles.inputStyle,
        units: '%/annual',
        variableName: 'variable_ppa_escalator_annual_exports',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
        type: 'TextInput',
      },

      {
        name: 'term_years',
        label: 'Term',
        units: 'years',
        variableName: 'variable_term_years',
        style: styles.inputStyle,
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
        type: 'TextInput',
      },
      {
        name: 'cap_total_kwh',
        label: 'Cap Total',
        units: 'kWh',
        variableName: 'variable_cap_total_kwh',
        style: styles.inputStyle,
        validationMethod: 'floatoptional',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
        type: 'TextInput',
      },
      {
        name: 'collect_signature',
        validationMethod: 'trueorfalse',
        variableName: 'variable_collect_signature',
      },
      {
        name: 'integration',
        validationMethod: 'optional',
        variableName: 'variable_integration',
      },
      {
        name: 'fixed_fee_per_period',
        validationMethod: 'floatoptional',
        variableName: 'variable_fixed_fee_per_period',
      },
      {
        name: 'available_utilities',
        validationMethod: 'optional',
        variableName: 'variable_available_utilities',
      },
      {
        name: 'available_utility_names',
        validationMethod: 'optional',
        variableName: 'variable_available_utility_names',
      },
    ],
  },
  {
    title: 'regular_payment',
    label: 'Regular Payment',
    variables: [
      {
        name: 'payment_frequency',
        label: 'Payment Frequency',
        type: 'RegularPaymentFrequencySelectInput',
        variableName: 'variable_regular_payment',
        validationMethod: 'truthy',
      },
      {
        name: 'payment_amount',
        label: 'Payment Amount',
        style: styles.inputStyle,
        units: '$',
        validationMethod: 'floatrequired',
        variableName: 'variable_payment_amount',
        type: 'TextInput',
      },
      {
        name: 'term_years',
        style: styles.inputStyle,
        units: 'years',
        label: 'Term',
        validationMethod: 'floatrequired',
        variableName: 'variable_term_years',
        type: 'TextInput',
      },
      {
        name: 'down_payment',
        label: 'Down Payment',
        style: styles.inputStyle,
        units: '$',
        validationMethod: 'floatoptional',
        variableName: 'variable_down_payment',
        type: 'TextInput',
      },
      {
        name: 'escalator_percentage',
        label: 'Escalator Percentage',
        style: styles.inputStyle,
        units: '%',
        validationMethod: 'csvrequired',
        type: 'TextInput',
        variableName: 'variable_escalator_percentage',
      },
      {
        name: 'collect_signature',
        validationMethod: 'trueorfalse',
        variableName: 'variable_collect_signature',
      },
      {
        name: 'integration',
        validationMethod: 'optional',
        variableName: 'variable_integration',
      },
    ],
  },
  {
    title: 'lease',
    label: 'Lease',
    variables: [
      {
        name: 'payment_frequency',
        type: 'LoanSimplePaymentFrequencySelectInput',
        variableName: 'variable_payment_frequency',
        validationMethod: 'truthy',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      },
      {
        name: 'term',
        style: styles.inputStyle,
        label: 'Term',
        units: 'periods',
        validationMethod: 'floatrequired',
        type: 'TextInput',
        variableName: 'variable_term',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      },
      {
        name: 'interest_rate',
        style: styles.inputStyle,
        units: '%/annual',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        type: 'TextInput',
        variableName: 'variable_interest_rate',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => ['energy_ease', 'lightreach'].includes(formData.variable_integration),
      },
      {
        name: 'dealer_fee',
        style: styles.inputStyle,
        units: '$',
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_dealer_fee',
        disabled: disabledOnParticularIntegrations(['energy_ease']),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      }, // will be replaced with currency
      {
        name: 'down_payment_percentage',
        units: '%',
        label: 'Down Payment',
        style: styles.inputStyle,
        helperText: 'of System Cost',
        validationMethod: 'floatrequired',
        type: 'TextInput',
        variableName: 'variable_down_payment_percentage',
      },
      {
        name: 'down_payment_min',
        units: '$',
        label: 'Down Payment Minimum',
        style: styles.inputStyle,
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_down_payment_min',
      },
      {
        name: 'down_payment_max',
        units: '$',
        label: 'Down Payment Maximum',
        style: styles.inputStyle,
        validationMethod: 'floatoptional',
        type: 'TextInput',
        variableName: 'variable_down_payment_max',
      },
      {
        name: 'brokerage_rate',
        style: styles.inputStyle,
        units: '%',
        helperText: 'of System Cost',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        type: 'TextInput',
        variableName: 'variable_brokerage_rate',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      },
      {
        name: 'escalator_annual',
        style: styles.inputStyle,
        units: '%/annual',
        validationMethod: 'floatRequiredIfNoPaymentFactors',
        type: 'TextInput',
        variableName: 'variable_escalator_annual',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      },
      {
        name: 'payment_timing',
        style: styles.inputStyle,
        type: 'LeasePaymentTiming',
        validationMethod: 'truthy',
        variableName: 'variable_payment_timing',
        disabled: disabledForAllIntegrations(),
        hiddenOnDisabled: (formData) => formData.variable_integration === 'lightreach',
      },
      {
        name: 'additional_details',
        style: styles.inputStyle,
        label: 'Additional Details',
        validationMethod: 'optional',
        type: 'TextInput',
        variableName: 'variable_additional_details',
      },
      {
        name: 'collect_signature',
        validationMethod: 'trueorfalse',
        variableName: 'variable_collect_signature',
      },
      {
        name: 'integration',
        validationMethod: 'optional',
        variableName: 'variable_integration',
      },
      {
        name: 'fixed_fee_per_period',
        validationMethod: 'floatoptional',
        variableName: 'variable_fixed_fee_per_period',
      },
      {
        name: 'available_utilities',
        validationMethod: 'optional',
        variableName: 'variable_available_utilities',
      },
    ],
  },
]
