import { makeStyles } from '@material-ui/core'
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useField } from 'react-final-form'
import AccordionCard from '../../info/AccordionCard'
import ElectricityUsageContent from './ElectricityUsageContent'

const useStyles = makeStyles({
  errorBorder: {
    border: '2px solid rgb(230, 37, 0)',
    borderRadius: 4,
  },
  standard: {},
})

const ElectricityUsageAccordion = () => {
  const {
    meta: { error, submitError },
  } = useField('usage')
  const classes = useStyles()
  const translate = useTranslate()
  const usageErrorMessage = error || submitError

  return (
    <AccordionCard
      className={usageErrorMessage ? classes.errorBorder : classes.standard}
      defaultExpanded={true}
      name={'electricity-usage'}
      title={translate('Electricity Usage')}
      titleIcon={EmojiObjectsOutlinedIcon}
      content={ElectricityUsageContent}
    />
  )
}
export default ElectricityUsageAccordion
