import { Tooltip } from '@material-ui/core'
import { Chip } from 'opensolar-ui'
import { useContext } from 'react'
import { useTranslate } from 'react-admin'
import { TagsContainer } from '../../../common/styles'
import { FilesContext } from '../../ProjectFilesList'

export const SelectTagsField = ({ record, editTooltip, allowEdit }) => {
  const translate = useTranslate()

  const { selectActiveInlineEdit } = useContext(FilesContext)
  const tags = record?.file_tags_data || []

  return (
    <Tooltip title={editTooltip} placement={'bottom-start'}>
      <TagsContainer
        onClick={() => {
          if (allowEdit) {
            selectActiveInlineEdit({ record, fieldName: 'file_tags_data' })
          }
        }}
      >
        {tags.length ? (
          <>
            {tags.map((t) => (
              <Chip color={'info'} label={translate(t.title)} />
            ))}
          </>
        ) : (
          <p>{allowEdit && <i>{translate('No tags found')}</i>}</p>
        )}
      </TagsContainer>
    </Tooltip>
  )
}
