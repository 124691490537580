export const osHome = 'https://www.opensolar.com'
export const termsLink_external = 'https://www.opensolar.com/terms-conditions'
export const privacyLink = 'https://www.opensolar.com/privacy'
export const osLiteSupportLink = 'https://support-lite.opensolar.com/hc/en-us'
export const WebinarLink = 'https://www.opensolar.com/webinars'
export const osSupportLink = { url: 'https://support.opensolar.com', locale: ['it', 'es', 'fr', 'nl', 'pl', 'sv'] }
export const solkunderLink = 'https://www.3quotes.eu/connect/opensolar'
export const PAYMENTS_PAGE_TITLE_HELPCENTER_URL =
  'https://support.opensolar.com/hc/en-us/sections/4407123896729-Payment-Options'

// Missed protocol so it can be printed without in text
export const osDisclaimer = 'www.opensolar.com/proposal-disclaimer'

export const newSupportTicketLink = 'https://support.opensolar.com/hc/requests/new'
