import { Grid } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { getSupplierConfig } from 'pages/ordering/configs'
import type { StockLevelStatus } from 'pages/ordering/type'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import AvailabilityCheckButton from './AvailabilityCheckButton'
import HardwareSummary from './HardwareSummary'
import getHardwareDetailFromSystem from './getHardwareDetailFromSystem'
import useAvailabilityCheck from './useAvailabilityCheck'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  title: {
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  titleWrapper: {
    display: 'flex',
  },
  container: {
    margin: '20px 0',
  },
}))

export type HardwareStockStatusMap = {
  [key: string]: StockLevelStatus
}

const HardwareSection = ({ system }) => {
  const [showStockAvailability, setShowStockAvailability] = useState(false)
  const [stockStatusMap, setStockStatusMap] = useState<HardwareStockStatusMap | undefined>(undefined)
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const selectedSupplierFilterKey = getSupplierConfig(selectedSupplier)?.filterKey

  const classes = useStyles()
  const translate = useTranslate()
  const hardwareDetails = useMemo(() => getHardwareDetailFromSystem(system), [system])
  const { loading, loadStockStatus } = useAvailabilityCheck()

  const handleButtonClicked = useCallback(async () => {
    logAmplitudeEvent('hardware_check_design_stock_availability', {
      action: 'clicked',
      source: 'system_summary',
    })
    setShowStockAvailability(true)
    const stockStatus = selectedSupplierFilterKey && (await loadStockStatus(hardwareDetails, selectedSupplierFilterKey))
    setStockStatusMap(stockStatus)
  }, [loadStockStatus, hardwareDetails, selectedSupplierFilterKey])

  return (
    <div className={classes.container}>
      <Grid container spacing={1} justify={'space-between'} alignItems="center">
        <Grid item xs={4} className={classes.title}>
          <span>{translate('Hardware')}</span>
        </Grid>
        <Grid item container xs={8} justify="flex-end" alignItems="center">
          <AvailabilityCheckButton onClick={handleButtonClicked} showStockAvailability={showStockAvailability} />
        </Grid>
      </Grid>
      <HardwareSummary
        hardwareDetails={hardwareDetails}
        stockStatusMap={stockStatusMap}
        loading={loading}
        showStockAvailability={showStockAvailability}
      />
    </div>
  )
}

export default HardwareSection
