// @ts-nocheck
import { Portal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import React, { isValidElement, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { SpotlightType, StepType, ToolTipType } from '../types'
import { recordUserAction } from './common'
import MuiToolTip from './MuiToolTip'
import Spotlight from './Spotlight'

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    mixBlendMode: 'hard-light',
    zIndex: 2000,
  },
  tooltipsWrapper: {
    zIndex: 2000,
  },
}))

type TourRidePropsTypes = {
  steps: StepType[]
  stepIndex: number
  visible: boolean
  handleNext?: Function
  handlePrev?: Function
  handleCancel?: Function
}

const TourRide = memo((props: TourRidePropsTypes) => {
  const { steps, visible, stepIndex, handleNext, handlePrev, handleCancel } = props
  const [spotlightOnHover, setSpotlightOnHover] = useState('')
  const userId: number = useSelector(authSelectors.getCurrentUser)?.id
  useEffect(() => {
    setSpotlightOnHover('')
  }, [stepIndex])

  useEffect(() => {
    if (stepIndex && steps[stepIndex]) {
      logAmplitudeEvent('fuji_tour_step', {
        step: steps[stepIndex]?.step || 'unknown',
        page: steps[stepIndex]?.page || 'unknown',
      })
      recordUserAction(userId, steps[stepIndex]?.step)
    }
  }, [stepIndex])

  const classes = useStyles()
  const hoverRef = useRef(spotlightOnHover)

  const handleHover = useCallback((targetId, isMoveOverOnSpotlight) => {
    if (isMoveOverOnSpotlight) {
      if (targetId !== hoverRef.current) {
        hoverRef.current = targetId
        setSpotlightOnHover(targetId)
      }
    } else {
      if (targetId === hoverRef.current) {
        hoverRef.current = ''
        setSpotlightOnHover('')
      }
    }
  }, [])

  if (!visible) {
    return null
  }

  const { spotlights = [], tooltips = [] } = steps[stepIndex].config
  return (
    <Portal container={() => document.getElementsByTagName('body')[0]}>
      <div>
        <div className={classes.overlay} style={{ pointerEvents: spotlightOnHover ? 'none' : 'auto' }}>
          {spotlights.map((spotlight: SpotlightType, index: number) =>
            isValidElement(spotlight) ? (
              React.cloneElement(spotlight, {
                handleHover,
              })
            ) : (
              <Spotlight
                key={`tour-step-${stepIndex}-${spotlight.targetId}-${index}`}
                index={index}
                handleHover={handleHover}
                {...spotlight}
              />
            )
          )}
        </div>
      </div>
      <div className={classes.tooltipsWrapper}>
        {tooltips.map((tooltip: ToolTipType, index: number) =>
          isValidElement(tooltip) ? (
            React.cloneElement(tooltip, {
              handleNext,
              handlePrev,
              handleCancel,
            })
          ) : (
            <MuiToolTip
              key={`tour-step-${stepIndex}-${tooltip.targetId}-${index}`}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleCancel={handleCancel}
              {...tooltip}
            />
          )
        )}
      </div>
    </Portal>
  )
})

export default TourRide
