import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

/*
 * * About this Hook:
 * This hook is used to handle value update for bi-directional controlled input component.
 * It is helpful when we want to control the input value while allowing it to be overridden from an external source, such as URL params.
 *
 * * Enhancement:
 * Alternatively, we could move debounce controller to this component and invoke the callback on timeout.
 * However, this would require the callback execute immediately on the first call. (Not another debounce)
 * Definitely don't want double debounce, so we need to think more carefully about this approach.
 */
const useOptimisticValue = <T extends any>({ defaultValue, value }: { defaultValue: T; value: T | undefined }) => {
  const [isDebouncing, setIsDebouncing] = useState(false) // Handle debouncing
  // Never use undefine as value, otherwise it will became uncontrolled component and causing problems
  const [optimisticValue, setOptimisticValue] = useState<T>(value === undefined ? defaultValue : value)

  useEffect(() => {
    if (isDebouncing === true && isEqual(value, optimisticValue)) {
      // Not robust to detect debouncing finished
      // But should be good enough to cover 99% of the cases
      setIsDebouncing(false)
    }
  }, [value, optimisticValue, isDebouncing])

  useEffect(() => {
    const overrideValue = value === undefined ? defaultValue : value
    if (isDebouncing === false && !isEqual(overrideValue, optimisticValue)) {
      // Handle value override from url params
      setOptimisticValue(overrideValue)
    }
  }, [value, optimisticValue, isDebouncing])

  const updateOptimisticValue = useCallback(
    (value: T) => {
      setIsDebouncing(true)
      setOptimisticValue(value)
    },
    [setIsDebouncing, setOptimisticValue]
  )

  return useMemo(() => {
    return { optimisticValue, updateOptimisticValue }
  }, [optimisticValue, updateOptimisticValue])
}

export default useOptimisticValue
