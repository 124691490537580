import { authSelectors } from 'ducks/auth'
import { Button, styled } from 'opensolar-ui'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { showNotification } from 'react-admin'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { handleCsvDownload } from '../util'

const DiscardButton = styled(Button)({
  marginRight: 10,
})

const MainHeader = styled('h1')({
  fontWeight: 600,
  fontSize: 24,
  marginTop: 0,
  marginBottom: 10,
  color: COLOR_PALETTE.black,
})

const Subheader = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[900],
}))

const LeftDiv = styled('div')({
  marginRight: 'auto',
})

const FullRow = styled('div')({
  width: '100%',
})

const ProjectsHeader = (props) => {
  const history = useHistory()
  const translate = useTranslate()
  const classes = getProjectSectionStyles()
  const orgId = useSelector(authSelectors.getOrgId)
  const handleCSVDownload = () => {
    handleCsvDownload(
      `${window.API_BASE_URL}orgs/${orgId}/projects/?fieldset=csv&format=csv&ordering=-id`,
      showNotification,
      props.filterValues,
      'OpenSolarProjects'
    )
  }
  return (
    <FullRow className={classes.row}>
      <LeftDiv className={classes.column}>
        <MainHeader>{translate('Projects')}</MainHeader>
        <Subheader>{translate('View and manage your projects')}</Subheader>
      </LeftDiv>
      <DiscardButton variant="outlined" color="secondary" onClick={handleCSVDownload}>
        {translate('Export to CSV')}
      </DiscardButton>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          history.push('/home')
        }}
      >
        {translate('Create Project')}
      </Button>
    </FullRow>
  )
}

export default ProjectsHeader
