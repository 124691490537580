import { makeStyles } from '@material-ui/core'
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import { OpenSolarThemeType } from 'Themes'
import { TransitionNone } from 'elements/TransitionNone'
import React from 'react'

export type OsTooltipPropsType = Omit<TooltipProps, 'title'> & TooltipCustomProp

const useStyles = makeStyles<OpenSolarThemeType, { fullWidth?: boolean }>((theme) => ({
  tooltip: ({ fullWidth }) => ({
    maxWidth: fullWidth ? '100%' : 400,
  }),
}))

type TooltipCustomProp = { fullWidth?: boolean; disabled?: boolean; title?: NonNullable<React.ReactNode> }

// This is identical to Material Tooltip, leave the component for now in case we want to do more customization
const Tooltip: React.FC<OsTooltipPropsType> = ({ children, fullWidth, title, ...rest }) => {
  const classes = useStyles({ fullWidth })
  if (title === undefined) return <>{children}</>
  return (
    <MuiTooltip
      classes={classes}
      title={title}
      enterTouchDelay={10} // for touch device
      arrow
      {...rest}
      TransitionComponent={TransitionNone}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
