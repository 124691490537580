import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { PermissionKey } from 'types/roles'

interface PropTypes {
  linkTo: string
  label: string
  startIcon: ReactNode
  permissionToCheck: PermissionKey
}

const ButtonStyled = styled(Button)({
  justifyContent: 'flex-start',
  '& .OSUI-Button-startIcon': {
    marginRight: 8,
  },
})

const ProjectLinkButton: React.FC<PropTypes> = ({ linkTo, label, startIcon, permissionToCheck }) => {
  const translate = useTranslate()
  return (
    <WithPermissionsCheck permissionToCheck={permissionToCheck} permissions={['allowView']} projectMode={false}>
      <Link to={linkTo}>
        <ButtonStyled variant="text" color="default" startIcon={startIcon}>
          {translate(label)}
        </ButtonStyled>
      </Link>
    </WithPermissionsCheck>
  )
}

export default ProjectLinkButton
