import { authSelectors } from 'ducks/auth'
import { SharedEntityChipsInput } from 'elements/field/SharedEntityChipsInput'
import { formSharingParams } from 'projectSections/sections/info/orgSharing/util'
import { useEffect, useState } from 'react'
import { ReferenceArrayInput, useInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'

export const SharedAddersChipsInput = () => {
  const form = useForm()
  const { values: formValues } = form.getState()
  const currOrgId = useSelector(authSelectors.getOrgId) as number
  const system = window.editor.selectedSystem
  const addersInSystem = system.line_items || []
  const addersIds = addersInSystem.map((a) => a.adder_id)
  const { input } = useInput({ source: 'adders' })
  const [value, setValue] = useState<string[]>(addersIds)
  const [prevValue, setPrevValue] = useState<string[]>(input.value || [])

  useEffect(() => {
    const deletedAdders = prevValue.filter((prevItem) => !value.includes(prevItem))
    if (deletedAdders.length > 0) {
      deletedAdders.forEach((id) => {
        const filteredAdders = addersInSystem.filter((a) => id !== a.adder_id)
        if (system?.autoSync) {
          system.autoSync.adders = false
        }
        window.editor.execute(new window.SetValueCommand(system, 'line_items', filteredAdders, undefined, true))
      })
    }
    setPrevValue(value)
  }, [value])

  return (
    <div>
      {/* @ts-ignore */}
      <ReferenceArrayInput
        filter={formSharingParams(currOrgId, formValues, {})}
        input={{ ...input, value, onChange: (e) => setValue(e) }}
        id="adders-input"
        reference="adders"
        resource="adders"
        perPage={300}
      >
        <SharedEntityChipsInput
          label={'Select from Adders library'}
          resource="adders"
          fullWidth={true}
          optionText="title"
          onSelect={(e) => {
            if (system?.autoSync) {
              system.autoSync.adders = false
            }
            let adder = window.AccountHelper.getAdderById(e.id)
            if (!adder) {
              // Can happen if the adder was created while this tab was open
              adder = new Adder(e)
              window.AccountHelper.addAdderToLibrary(adder)
            }
            Adder.addToSystem(window.editor.selectedSystem, adder)
          }}
        />
      </ReferenceArrayInput>
    </div>
  )
}
