import { Theme, useMediaQuery } from '@material-ui/core'
import SelectableCard from 'elements/SelectableCard'
import LightbulbIcon from 'icons/LightbulbIcon'
import ListIcon from 'icons/ListIcon'
import { Box, Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import BatterySalesAssistantContainer from '../../components/BatterySalesAssistantContainer'
import ExistingAppliedBatteryAlert from '../../components/ExistingAppliedBatteryAlert'
import { BSAModeSelectionType, BatterySalesAssistantFlowNodeComponentStandardPropsType } from '../../utility/types'
import useModeNodePresenter from './useModeNodePresenter'

interface BSASelectionProps extends BatterySalesAssistantFlowNodeComponentStandardPropsType {}

const BSAModeNode: React.FC<BSASelectionProps> = ({ flowController, flowStore }) => {
  const translate = useTranslate()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const presenter = useModeNodePresenter({ defaultSelectedMode: flowStore.mode, flowController, flowStore })
  const BSA_MODE_SELECTIONS: BSAModeSelectionType[] = useMemo(
    () => [
      {
        description: translate(
          'OpenSolar’s Auto-Battery Selection tool generates recommended battery configurations based on a few key inputs about customer motivation and appliance usage in the home.'
        ),
        icon: <LightbulbIcon />,
        key: 'auto',
        title: translate('Battery Design Assistant'),
      },
      {
        description: translate(
          'Browse through our catalog of battery options. Select the one that best aligns with your needs and preferences.'
        ),
        icon: <ListIcon />,
        key: 'manual',
        title: translate('Manual Battery Selection'),
      },
    ],
    []
  )

  return (
    <BatterySalesAssistantContainer
      footerActions={
        <Button
          color="primary"
          disabled={!presenter.selectedMode}
          variant="contained"
          onClick={presenter.handleContinue}
        >
          {translate('Next')}
        </Button>
      }
      title={translate('What would you like to do?')}
    >
      <Box display="flex" gridGap={16} flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}>
        {BSA_MODE_SELECTIONS.map(({ chipText, description, icon, key, title }) => {
          return (
            <SelectableCard
              cardKey={key}
              childGap={8}
              chipText={chipText}
              description={description}
              gap={16}
              key={key}
              icon={icon}
              isSelected={presenter.selectedMode === key}
              onSelect={() => presenter.handleSelectMode(key)}
              title={title}
            />
          )
        })}
      </Box>
      <ExistingAppliedBatteryAlert />
    </BatterySalesAssistantContainer>
  )
}

export default BSAModeNode
