import { makeStyles } from '@material-ui/core'
import { getProjectSectionStyles } from '../styles'
import DetailFooter from './DetailFooter'
import DetailHeader from './DetailHeader'

const useStyles = makeStyles(
  {
    wrapper: {},
  },
  { name: 'ProjectDetails' }
)

const ProjectDetails = () => {
  const classes = useStyles()
  const sectionStyles = getProjectSectionStyles()
  return (
    <div className={`${classes.wrapper} ${sectionStyles.moduleFullWidth}`}>
      <div className={sectionStyles.sectionCardTop}>
        <DetailHeader />
      </div>
      <div className={sectionStyles.sectionCardBottom}>
        <DetailFooter />
      </div>
    </div>
  )
}

export default ProjectDetails
