import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'

const DEFAULT_SIMULATE_YEAR = 20
const INTEGRATIONS_LIFETIME = {
  lightreach: 25,
}

export const getSystemYearSimulationYear = (system: StudioSystemType, requiredLifetime: number | null) => {
  if (requiredLifetime) return requiredLifetime
  // if auto default simulate year is enabled then use the product warranty from module
  const isAutoDefaultSimulateYearEnabled = window.WorkspaceHelper?.project?.auto_apply_max_simulate_years
  if (isAutoDefaultSimulateYearEnabled) {
    return Math.min(system.moduleType()?.product_warranty || DEFAULT_SIMULATE_YEAR, 40)
  } else {
    return window.WorkspaceHelper.getYearsToSimulate()
  }
}

export const useGetRequiredLifetimeByIntegration = () => {
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  const requiredLifetime = useMemo(() => {
    let pmtWithRequiredLifetime: PaymentOptionDataType | undefined = undefined
    window.editor.getSystems()?.forEach((system) => {
      let pmtWithTargetIntegration = system?.payment_options?.find((pmt) => {
        return pmt.integration && INTEGRATIONS_LIFETIME[pmt.integration]
      })

      if (pmtWithTargetIntegration) pmtWithRequiredLifetime = pmtWithTargetIntegration
    })

    // @ts-ignore
    return pmtWithRequiredLifetime ? INTEGRATIONS_LIFETIME[pmtWithRequiredLifetime?.integration] : null
  }, [refreshSystemsTrigger])

  return requiredLifetime
}

export const useGetMaxSystemLifetime = () => {
  const [maxLifetime, setMaxLifetime] = useState(25)
  const requiredLifetime = useGetRequiredLifetimeByIntegration()

  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  useEffect(() => {
    if (requiredLifetime) setMaxLifetime(requiredLifetime)
    else {
      const maxPanelWarrantyYear = Math.max(
        ...window.editor.getSystems()?.map((sys) => getSystemYearSimulationYear(sys, requiredLifetime))
      )
      setMaxLifetime(maxPanelWarrantyYear)
    }
  }, [requiredLifetime, refreshSystemsTrigger])

  return maxLifetime
}

export const useGetSystemLifetimeLocked = () => {
  const requiredLifetime = useGetRequiredLifetimeByIntegration()
  return Boolean(requiredLifetime)
}
