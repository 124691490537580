import { useTranslate } from 'ra-core'
import React from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { GENDER_CHOICES } from 'resources/projects/choices'
import { dateParserYYYYMMDD } from 'util/misc'
import CustomFields from '../../../common/CustomFields'
import { InputRow, InputWrapper, StyledDateInput } from '../../../styles'
import { ContactFormExpandSection } from './ContactFormExpandSection'

type PropTypes = {
  source: string
}

export const ContactMoreDetailsSection: React.FC<PropTypes> = ({ source }) => {
  const translate = useTranslate()
  return (
    <ContactFormExpandSection sectionTitle={(isOpen) => translate(isOpen ? 'Hide details' : 'View more details')}>
      <InputWrapper>
        <TextInput source={`${source}.middle_name`} variant="outlined" label={'Middle Name'} fullWidth />
        <InputRow>
          <SelectInput
            source={`${source}.gender`}
            variant="outlined"
            label={'Gender'}
            choices={GENDER_CHOICES}
            fullWidth
          />
          <StyledDateInput
            inputVariant={'outlined'}
            source={`${source}.date_of_birth`}
            label={'Date of Birth'}
            options={{ format: 'MM/DD/YYYY', color: 'secondary' }}
            parse={dateParserYYYYMMDD}
          />
        </InputRow>
        <InputRow>
          <TextInput source={`${source}.passport_number`} variant="outlined" label={'Passport Number'} fullWidth />
          <TextInput source={`${source}.licence_number`} variant="outlined" label={'License Number'} fullWidth />
        </InputRow>
        <CustomFields model={'Contact'} source={source} inputVariant="outlined" />
      </InputWrapper>
    </ContactFormExpandSection>
  )
}
