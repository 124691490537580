import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Notify } from 'ra-core'
import restClient from 'restClient'
import { ImageryActivation } from 'types/imagery'
import { MapDataProvider, MapDataTypes } from 'types/map'
import { WalletProductType } from 'types/products'

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)

export const getSelectedPremiumImageryProvider = (selectedImageryType: MapDataTypes): MapDataProvider | undefined => {
  if (selectedImageryType?.variation_data?.require_wallet_product) {
    return selectedImageryType.provider
  } else {
    return undefined
  }
}

export const getSelectedPremiumImageryProduct = (selectedImageryType: MapDataTypes): WalletProductType | undefined => {
  return selectedImageryType?.variation_data?.require_wallet_product
}

export const getIsImageryPremium = (imageryType: MapDataTypes): boolean => {
  if (!imageryType) return false
  if (!!imageryType.variation_data?.require_wallet_product) return true
  else return false
}

export type AddPremiumImageryResultType =
  | {
      success: true
      premium_imagery_activation: ImageryActivation
    }
  | {
      success: false
      error_msg: string | undefined
    }
export const tryAddPremiumImagery = async (
  imageryType: MapDataTypes,
  currentLonLat: number[]
): Promise<AddPremiumImageryResultType> => {
  //@ts-ignore TODO: remove this once the type for project.id includes 'new'
  if (window.WorkspaceHelper.project.id === 'new') {
    return {
      success: false,
      error_msg: 'Project must be saved before adding premium imagery',
    }
  }
  return restClientInstance('CUSTOM_PUT', 'custom', {
    url:
      'orgs/' +
      //@ts-ignore
      WorkspaceHelper.project.org_id +
      '/projects/' +
      //@ts-ignore
      WorkspaceHelper.project.id +
      '/premium_imagery/' +
      imageryType?.variation_data?.require_wallet_product?.id +
      '/enable/',
    data: {
      country_iso2: window.WorkspaceHelper.project.country_iso2,
      state: window.WorkspaceHelper.project.state,

      //Should this use window.WorkspaceHelper.project.lat/lon instead?
      lat: currentLonLat[1],
      lon: currentLonLat[0],

      product: imageryType?.variation_data?.require_wallet_product?.id,
    },
  })
    .then((res: { data: AddPremiumImageryResultType }) => {
      return res.data
    })
    .catch((err) => {
      return {
        success: false,
        error_msg: err?.body?.error_msg || 'Unable to add premium imagery to this project',
      }
    })
}

export const updateProjectFormWithActivatedPremiumImagery = (premiumImageryActivation: ImageryActivation) => {
  // var walletProductId = premiumImageryActivation?.product?.id

  // Only add id not already included
  var originalFormState = window.projectForm.getState().values

  if (
    originalFormState.premium_imagery_activations &&
    !originalFormState.premium_imagery_activations.some((pia) => pia.id === premiumImageryActivation.id)
  ) {
    var newPremiumImageryActivations = originalFormState.premium_imagery_activations.concat([premiumImageryActivation])
    window.projectForm.mutators.updateFieldSilently('premium_imagery_activations', newPremiumImageryActivations)
  }
}

export const tryAddPremiumImageryIfRequiredAndNotify = async (
  imageryType: MapDataTypes,
  premiumImageryActivations: ImageryActivation[],
  currentLonLat: number[],
  notify: Notify
): Promise<boolean> => {
  // Take the last matching activation, in case there are multiple

  const selectedPremiumImageryActivation = premiumImageryActivations
    .reverse()
    .filter((a) => restrictToBounds(a, currentLonLat))
    .find((a) => a.product.id === imageryType.variation_data?.require_wallet_product?.id)

  const selectedPremiumImageryPeriod = selectedPremiumImageryActivation?.period || 'inactive'

  if (getIsImageryPremium(imageryType) && selectedPremiumImageryPeriod !== 'initial') {
    let result = await tryAddPremiumImagery(imageryType, currentLonLat)
    if (!result.success) {
      notify(result.error_msg || 'We are unable to add premium imagery to this project', 'warning')
      logAmplitudeEvent('premium_imagery_add_error', {
        provider: imageryType?.provider,
        msg: result.error_msg || 'unknown',
      })
      return false
    } else {
      notify('Premium Imagery has been added to this project', 'success')
      logAmplitudeEvent('premium_imagery_added', { provider: imageryType?.provider })

      /*
      There are various scenarios where we want to forcably clear tile cache, and it's always safe to clear them
      so just always clear them to avoid any unexpected edge cases which could result in cached broken tiles.
      
      Known scenarios where reloading tiles is required:
        1) If we are re-activating, be sure to clear the tile cache because it may be full of placeholder images which
        were loaded while the imagery was expired.
        2) Any time we are currentlyviewing Nearmap imagery while the new activation occurs, because any Nearmap tiles
        already loaded are probably broken/invalid. This can happen either because a) previous activation has expired
        or b) we are activating in a new location
      */
      window.MapHelper.clearTileCache()
      window.MapHelper.refreshLayerNearmap()

      updateProjectFormWithActivatedPremiumImagery(result.premium_imagery_activation)

      return true
    }
  } else {
    // Already activated, no actions required, return true to indicate it is active.
    return true
  }
}

/*
  Not all premium imagery activations may populate bounds, so we only filter if we find valid bounds, otherwise
  do not filter on location. For now, GSA will still function ok if we do not restrict bounds, it is mostly
  Nearmap PAYG that needs to strip out activations with bounds that do not overlap the current location.
   */
export const getCurrentLonLat = (fallbackLonLat) => {
  try {
    const _currentLonLat = window.MapHelper.activeMapInstance.toMapData().center
    if (_currentLonLat?.length === 2) {
      return _currentLonLat
    }
  } catch (e) {
    console.error(e)
  }
  return fallbackLonLat
}

export const restrictToBounds = (premiumImageryActivation: ImageryActivation | any, currentLonLat) => {
  // Ensure we do not treat dummy value [0,0,0,0] as valid
  if (premiumImageryActivation?.bounds?.length !== 4 || !premiumImageryActivation?.bounds[0] || !currentLonLat) {
    return true
  } else {
    return (
      currentLonLat[0] > premiumImageryActivation.bounds[0] &&
      currentLonLat[0] < premiumImageryActivation.bounds[2] &&
      currentLonLat[1] > premiumImageryActivation.bounds[1] &&
      currentLonLat[1] < premiumImageryActivation.bounds[3]
    )
  }
}
