import { Theme, Typography, useMediaQuery } from '@material-ui/core'
import { EventIdType, logAmplitudeEvent } from 'amplitude/amplitude'
import { PERFORMANCE_CALCULATORS_MCS } from 'constants/calculators'
import { orgSelectors } from 'ducks/orgs'
import SelectableCard from 'elements/SelectableCard'
import { Alert, BarChartIcon, Box, Button, LeafIcon, MoneyIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { doNotTranslate } from 'util/misc'
import BatterySalesAssistantContainer from '../components/BatterySalesAssistantContainer'
import { BSA_BACKUP_APPLIANCES_NODE_KEY } from '../utility/constants'
import {
  BSACustomerPriorityKeyType,
  BSACustomerPrioritySelectionType,
  BatterySalesAssistantFlowNodeComponentStandardPropsType,
} from '../utility/types'

const useCustomerPriorityCardStyles = makeOpenSolarStyles(() => ({
  iconContainer: {
    '& svg': {
      height: '32px',
      width: '32px',
    },
  },
  title: {
    color: '#212121',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
  },
  description: {
    color: '#4D4D4D',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',
  },
}))

const mapCustomerPriorityToAmplitude = (
  key: BSACustomerPriorityKeyType
): Extract<
  EventIdType,
  | 'bda_maximise_savings_option_card_selected'
  | 'bda_peak_demand_option_card_selected'
  | 'bda_self_consumption_option_card_selected'
> => {
  switch (key) {
    case 'maximize_savings':
      return 'bda_maximise_savings_option_card_selected'
    case 'peak_demand_shaving':
      return 'bda_peak_demand_option_card_selected'
    case 'self_consumption':
      return 'bda_self_consumption_option_card_selected'
  }
}

const CustomPriorityCard = ({
  description,
  icon,
  title,
}: {
  description: string
  icon: React.ReactNode
  title: string
}) => {
  const classes = useCustomerPriorityCardStyles()

  return (
    <Box alignItems="center" display="flex" flex={1} flexDirection="column" gridGap={16}>
      <Box className={classes.iconContainer} display="flex">
        {icon}
      </Box>
      <Box alignItems="center" display="flex" flex={1} flexDirection="column" gridGap={12}>
        <Typography classes={{ root: classes.title }}>{title}</Typography>
        <Typography classes={{ root: classes.description }}>{description}.</Typography>
      </Box>
    </Box>
  )
}

interface BSASelectionProps extends BatterySalesAssistantFlowNodeComponentStandardPropsType {}

const BSACustomerPriorityModeNode: React.FC<BSASelectionProps> = ({ flowController, flowStore }) => {
  const translate = useTranslate()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isUKAndMCS =
    useSelector(orgSelectors.getOrgIso2) === 'GB' &&
    window.editor?.selectedSystem?.calculator === PERFORMANCE_CALCULATORS_MCS

  const BSA_SELECTIONS: BSACustomerPrioritySelectionType[] = useMemo(
    () => [
      {
        description: translate('Designed to maximize cost reductions'),
        icon: <MoneyIcon height="32" fill="white" width="32" />,
        key: 'maximize_savings',
        title: translate('Maximize Savings'),
      },
      {
        description: translate('Tailored to minimize grid energy usage throughout the day'),
        icon: <LeafIcon fill="#757575" height="32" width="32" />,
        key: 'self_consumption',
        title: translate('Self-Consumption'),
      },
      {
        description: translate('Tailored to reduce peak energy usage and associated costs'),
        icon: <BarChartIcon height="32" fill="white" width="32" />,
        key: 'peak_demand_shaving',
        title: translate('Peak Demand Shaving'),
      },
    ],
    []
  )

  const [selectedCustomerPriority, setSelectedCustomerPriority] = useState<BSACustomerPriorityKeyType | undefined>(
    flowStore.customerPriority ?? undefined
  )

  const handleContinue = () => {
    logAmplitudeEvent('bda_next_button_clicked', {
      recorded_on_click: selectedCustomerPriority,
    })
    flowController.goTo({
      currentNodeKey: BSA_BACKUP_APPLIANCES_NODE_KEY,
      options: {},
    })
  }

  const handleSelect = (key: BSACustomerPriorityKeyType) => {
    setSelectedCustomerPriority((prevKey) => {
      logAmplitudeEvent(mapCustomerPriorityToAmplitude(key), {
        previous_selected_option: prevKey,
      })
      return key
    })
    flowController.setFlowStore((state) => ({
      ...state,
      customerPriority: key,
    }))
  }

  return (
    <BatterySalesAssistantContainer
      footerActions={
        <Button color="primary" disabled={!selectedCustomerPriority} variant="contained" onClick={handleContinue}>
          {translate('Next')}
        </Button>
      }
      title={translate('What goal is your customer looking to achieve with their solar system?')}
    >
      <Box display="flex" gridGap={16} flexWrap={isSmallScreen ? 'wrap' : 'nowrap'}>
        {BSA_SELECTIONS.map(({ description, icon, key, title }) => {
          return (
            <SelectableCard
              cardKey={key}
              disabled={isUKAndMCS && key !== 'self_consumption'}
              key={key}
              isSelected={selectedCustomerPriority === key}
              onSelect={(v) => handleSelect(v as BSACustomerPriorityKeyType)}
              render={<CustomPriorityCard description={description} icon={icon} title={title} />}
              title={title}
            />
          )
        })}
      </Box>
      {isUKAndMCS && (
        <Alert severity="warning">
          {doNotTranslate(
            'MCS (UK Only) is set as your Energy Production Calculator. To access Maximize Savings and Peak Demand Shaving goals, update your calculator via Advanced Settings in the system summary tab.'
          )}
        </Alert>
      )}
    </BatterySalesAssistantContainer>
  )
}

export default BSACustomerPriorityModeNode
