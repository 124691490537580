import { Dialog, makeStyles, TextField, withStyles } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import ContentSave from '@material-ui/icons/SaveOutlined'
import Alert from 'elements/Alert'
import { Button, IconButton } from 'opensolar-ui'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import React, { useState } from 'react'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'

// const useStyle = makeStyles({
//   root: {
//     borderCollapse: 'collapse',
//     position: 'relative',
//     height: '100%',
//     tableLayout: 'fixed',
//     margin: '0px',
//     width: '100%',
//   },
//   header: {
//     background: '#eeeeee',
//   },
// })

const dialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
}))(MuiDialogActions)

const DialogTitle = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h1>{children}</h1>
      {onClose ? (
        <IconButton id="TransactionDialogClose" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 0,
    margin: '0px 20px 20px 20px',
  },
}))(MuiDialogContent)

const useStyles = () =>
  makeStyles(
    (theme) => {
      return {
        button: {
          '&:hover': {
            cursor: 'pointer',
            background: theme.themeColorDark,
          },

          position: 'relative',
          padding: '4px 10px',
          margin: 10,
          width: 100,
          background: theme.themeColor,
          color: theme.headerFontColor,
        },
        fields: {
          width: 120,
        },
      }
    },
    { name: 'RaSaveButton' }
  )()

const activateBomWhenForComponentFound = [
  'SPR-MAX5-420-E3-AC',
  'SPR-MAX5-415-E3-AC',
  'SPR-MAX5-410-E3-AC',
  'SPR-MAX5-400-E3-AC',
  'SPR-P3-370-BLK-E3-AC',
  'SPR-P3-375-BLK-E3-AC',
  'SPR-P3-380-BLK-E3-AC',
  'SPR-P3-385-BLK-E3-AC',
  'SPR-MAX6-450-E3-AC',
  'SPR-MAX6-445-E3-AC',
  'SPR-MAX6-440-E3-AC',
  'SPR-MAX6-435-E3-AC',
  'SPR-MAX6-430-E3-AC',
  'SPR-MAX6-425-E3-AC',
  'SPR-MAX6-420-E3-AC',
  'SPR-MAX6-430-BLK-E3-AC',
  'SPR-MAX6-425-BLK-E3-AC',
  'SPR-MAX6-420-BLK-E3-AC',
  'SPR-MAX6-415-BLK-E3-AC',
  'SPR-MAX6-410-BLK-E3-AC',
  'SPR-MAX6-405-BLK-E3-AC',
  'SPR-MAX6-400-BLK-E3-AC',
  // Add panel codes for 25 year and 40 warranty (40 year is no longer in use)
  'SPR-MAX5-400-E3-AC/25',
  'SPR-MAX5-410-E3-AC/25',
  'SPR-MAX5-415-E3-AC/25',
  'SPR-MAX5-420-E3-AC/25',
  'SPR-MAX5-400-E3-AC/40',
  'SPR-MAX5-410-E3-AC/40',
  'SPR-MAX5-415-E3-AC/40',
  'SPR-MAX5-420-E3-AC/40',
]

export const checkEnableEnphaseBomGeneratorForSystem = (system) =>
  systemHasComponent(system, activateBomWhenForComponentFound)

export const systemMatchesRecommendedComponents = (system) => {
  var project = window.projectForm?.getState().values || {}
  var params = systemToParams(project, system)
  var systemCodeToQuantity = getCodesAndQuantities(system)
  var recommendedQuantities = getRecommendedQuantities(params, systemCodeToQuantity)
  var codesToQuantities = getCodesAndQuantities(system)
  for (var code in recommendedQuantities) {
    if (recommendedQuantities[code] === null || (!recommendedQuantities[code] && !codesToQuantities[code])) {
      // never show an error when recommendedQuantity is `null`
      // or if both are 0 or falsey, this is a match
    } else if (codesToQuantities[code] !== recommendedQuantities[code]) {
      return false
    }
  }
  return true
}

const formSections = [
  {
    heading: 'Cabling',
    codes: ['Q-25-17', 'Q-25-20', 'Q-25-17-3P', 'Q-25-20-3P'],
  },
  {
    heading: 'Board',
    codes: [
      // Generic 1P and 3P boards
      '1P Distribution Board',
      '3P Distribution Board',
    ],
  },

  {
    heading: 'Communications',
    codes: [
      'ENV-S-WM-230',
      'ENV-S-WB-230',
      'CT-200-SPLIT',

      // Generic harmonic filter
      'Harmonic Filter',
    ],
  },
  {
    heading: 'Sundries',
    codes: ['Q-RELAY-1P-INT', 'Q-RELAY-3P-INT', 'Q-RELAY-2-3P-IT', 'Q-TERM', 'Q-TERM-3P', 'Q-DISC', 'Q-DISC-3P'],
  },
  {
    heading: 'Optional Components',
    codes: [
      'Q-BA-3-1P-60',
      'Q-LCF-064-1P',
      'Q-SEAL',
      'ET-CLIP',
      'Q-CONN-R-M',
      'Q-CONN-R-F',
      'Q-CONN-3P-M',
      'Q-CONN-3P-F',
    ],
  },
]

const defaultComponentTitles = {
  'ENV-S-WM-230': 'ENVOY-S METERED + 2 CTs included',
  'ENV-S-WB-230': 'ENVOY-S STANDARD',
  'CT-200-SPLIT': 'Current transformer (CT) for 3-phase monitoring',
  'Q-RELAY-1P-INT': 'Q-Relay for 1-phase systems',
  'Q-RELAY-3P-INT': 'Q-Relay for 3-phases systems',
  'Q-RELAY-2-3P-IT': 'Q-Relay 2.0 for 1/3-phases systems in Italy',
  'Q-25-17': '1-phase Q-Cable (2 m between connectors)',
  'Q-25-20': '1-phase Q-Cable (2,3 m between connectors)',
  'Q-25-17-3P': '3-phase Q-Cable (2 m between connectors)',
  'Q-25-20-3P': '3-phase Q-Cable (2,3 m between connectors)',
  'Q-TERM': 'Terminator cap for unused 1-phase cable end',
  'Q-TERM-3P': 'Terminator cap for unused 3-phase cable end',
  'Q-SEAL': 'Sealing caps for unused cable connections',
  'ET-CLIP': 'Cable clip to fasten cabling to the racking',
  'Q-DISC': 'Disconnect tool for Q/DC/AC connectors',
  'Q-DISC-3P': 'Disconnect tool for 3-phase field wireable connector',
  'Q-CONN-R-M': 'Field-wireable connector (male / 1-phase)',
  'Q-CONN-R-F': 'Field-wireable connector (female / 1-phase)',
  'Q-CONN-3P-M': 'Field-wireable connector (male / 3-phase)',
  'Q-CONN-3P-F': 'Field-wireable connector (female / 3-phase)',
  'Q-BA-3-1P-60': 'Q-Aggregator',
  'Q-LCF-064-1P': 'Power Line Filter',
  '1P Distribution Board': '1P Distribution Board',
  '3P Distribution Board': '3P Distribution Board',
  'Harmonic Filter': 'Harmonic Filter',
}

var relayFreeCountries = ['IT', 'GB', 'PT', 'MT']

var maxPanelsPerBranchConfig = {
  '1P_AU': 11,
  '1P_IT': 10,
  '1P_OTHER': 10,
  '3P_AU': 39,
  '3P_IT': 31,
  '3P_OTHER': 30,
}

var getMaxPanelsPerBranch = (params) =>
  maxPanelsPerBranchConfig[`${params.numberOfPhases}P_${params.countryIso2}`] ||
  maxPanelsPerBranchConfig[`${params.numberOfPhases}P_OTHER`]

var quantityCalculators = {
  'ENV-S-WM-230': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!$C$25="yes", 1, 0)
    // Future storage/Self consumption model?
    // This is 97% of cases, so we default to use it instead of the alternative
    // Only set to 0 if we detect there is already an alternative included in the system design
    return systemCodeToQuantity['ENV-S-WB-230'] ? 0 : 1
  },
  'ENV-S-WB-230': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!$C$25="yes", 0, 1)
    // Future storage/Self consumption model?
    // This is ~3% of cases, so we default to to the metered alternative instead.
    // Only include in recommended quantity if already included in the system, otherwise recommend the metered alternatriuve
    return 0
  },
  'CT-200-SPLIT': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!$C$24=3, IF($E$11=1,2,2),0)
    return params.numberOfPhases === 3 ? 2 : 0
  },
  'Q-RELAY-1P-INT': (params, systemCodeToQuantity) => {
    // =IF(OR('1 - PV system data'!C7:D7=" Italy",'1 - PV system data'!C7:D7=" United Kingdom",'1 - PV system data'!C7:D7=" Portugal",'1 - PV system data'!C7:D7=" Malta"),0,IF('1 - PV system data'!$C$24=1,1,0))
    return params.numberOfPhases === 1 && !relayFreeCountries.includes(params.countryIso2)
      ? Math.ceil(params.numberOfPanels / getMaxPanelsPerBranch(params))
      : 0
  },
  'Q-RELAY-3P-INT': (params, systemCodeToQuantity) => {
    // =IF(OR('1 - PV system data'!C7:D7=" Italy",'1 - PV system data'!C7:D7=" United Kingdom",'1 - PV system data'!C7:D7=" Portugal",'1 - PV system data'!C7:D7=" Malta"),0,IF('1 - PV system data'!$C$24=3,1,0))
    return params.numberOfPhases === 3 && !relayFreeCountries.includes(params.countryIso2)
      ? Math.ceil(params.numberOfPanels / getMaxPanelsPerBranch(params))
      : 0
  },
  'Q-RELAY-2-3P-IT': (params, systemCodeToQuantity) => {
    // In Italy
    // if single phase then =ceil(branches/3)
    // if 3 phase then =branches
    if (params.countryIso2 === 'IT') {
      return params.numberOfPhases === 3 ? params.numberOfBranches : Math.ceil(params.numberOfBranches / 3)
    } else {
      return 0
    }
  },
  'Q-25-17': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!$C$24=1,TAB!F20, 0)
    // TAB!F20 >> ='1 - PV system data'!C13+'1 - PV system data'!E13+'1 - PV system data'!G13
    return params.numberOfPhases === 1 ? params.numberOfPanelsPortrait : 0
  },
  'Q-25-20': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!$C$24=1, TAB!G20, 0)
    return params.numberOfPhases === 1 ? params.numberOfPanelsLandscape : 0
  },
  'Q-25-17-3P': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!$C$24=3,TAB!F20,0)
    return params.numberOfPhases === 3 ? params.numberOfPanelsPortrait : 0
  },
  'Q-25-20-3P': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!$C$24=3, TAB!G20, 0)
    return params.numberOfPhases === 3 ? params.numberOfPanelsLandscape : 0
  },
  'Q-TERM': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!C24=3,0,IF('1 - PV system data'!C24=1,'1 - PV system data'!D30,"ERR"))
    return params.numberOfPhases === 1 ? params.numberOfBranches : 0
  },
  'Q-TERM-3P': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!C24=3,'1 - PV system data'!D30,IF('1 - PV system data'!C24=1,0,"ERR"))
    return params.numberOfPhases === 3 ? params.numberOfBranches : 0
  },
  'Q-SEAL': (params, systemCodeToQuantity) => {
    // (no formula)
    // Note that we return null to indicate that this should be ignore from recommended quantity calculations.
    // We should not return 0 because otherwise any value entered will be display a warning.
    return null
  },
  'ET-CLIP': (params, systemCodeToQuantity) => {
    // (no formula)
    // Note that we return null to indicate that this should be ignore from recommended quantity calculations.
    // We should not return 0 because otherwise any value entered will be display a warning.
    return null
  },
  'Q-DISC': (params, systemCodeToQuantity) => {
    // (no formula, hard-coded = 1)
    return 1
  },
  'Q-DISC-3P': (params, systemCodeToQuantity) => {
    // (no formula, hard-coded = 0)
    // Notes say this component is "Not Available"
    return 0
  },
  'Q-CONN-R-M': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!C24=3,0,IF('1 - PV system data'!C24=1,'1 - PV system data'!D30,"ERR"))
    return params.numberOfPhases === 1 ? null : 0
  },
  'Q-CONN-R-F': (params, systemCodeToQuantity) => {
    // =IF('1 - PV system data'!C24=3,0,IF('1 - PV system data'!C24=1,'1 - PV system data'!D30,"ERR"))
    return params.numberOfPhases === 1 ? null : 0
  },
  'Q-CONN-3P-M': (params, systemCodeToQuantity) => {
    // (no formula, hard-coded = 0)
    // Notes say this component is "Not Available"
    return 0
  },
  'Q-CONN-3P-F': (params, systemCodeToQuantity) => {
    // (no formula, hard-coded = 0).
    // Notes say this component is "Not Available"
    return 0
  },
  '1P Distribution Board': (params, systemCodeToQuantity) => {
    return params.numberOfPhases === 1 ? 1 : 0
  },
  '3P Distribution Board': (params, systemCodeToQuantity) => {
    return params.numberOfPhases === 3 ? 1 : 0
  },
}

const systemToParams = (project, system) => {
  var params = {
    numberOfPhases: project.number_of_phases || 1,
    countryIso2: project.country_iso2,
    numberOfBranches: system.getStrings((inverter) => inverter.microinverter === true).length,
    numberOfPanels: 0,
    numberOfPanelsPortrait: 0,
    numberOfPanelsLandscape: 0,
    inverterCode: system.inverters().filter((inverter) => inverter.microinverter === true)[0]?.code,
  }

  /*
  @TODO: Add support for unstring modules
  */
  system.inverters().forEach((i) => {
    i.mppts().forEach((m) => {
      m.strings().forEach((s) => {
        s.modules.forEach((m) => {
          if (m.getGrid().moduleLayout() === 'portrait') {
            params.numberOfPanelsPortrait++
          } else {
            params.numberOfPanelsLandscape++
          }
        })
      })
    })
  })

  // If branches are specified then use them directly, but otherwise guess based on geo-specific constraints and
  // number of unstrung modules.
  if (params.numberOfBranches === 0) {
    var unstrungModules = window.editor.selectedSystem.getUnstrungModules()
    if (unstrungModules.length > 0) {
      // For 1P it would be a simple rule of you cannot have more than 11 panels per branch (in AU)
      // or 10 panels per branch (in EU). However, for 3P it gets trickier where you have a maximum of 33 panels (AU)
      // per branch or 30 (EU), but you can have a branch which comprises of multiple "cables". You cannot have more
      // than 18 panels connected in series in one cable. This one might be best to discuss live.

      // e.g. 1P_AU or fallback to 1P_OTHER if country not found
      // e.g. 3P_XX or fallback to 3P_OTHER if country not found
      var maxPanelsPerBranch = getMaxPanelsPerBranch(params)

      params.numberOfBranches = Math.ceil(unstrungModules.length / maxPanelsPerBranch)
    }

    // If no panels are strung we should populate numberOfPanelsPortrait/Landscape using unstrung modules instead
    unstrungModules.forEach((unstrungModule) => {
      if (unstrungModule.getGrid().moduleLayout() === 'portrait') {
        params.numberOfPanelsPortrait++
      } else {
        params.numberOfPanelsLandscape++
      }
    })
  }

  params.numberOfPanels = params.numberOfPanelsPortrait + params.numberOfPanelsLandscape

  return params
}

const getRecommendedQuantities = (params, systemCodeToQuantity) => {
  var func
  var quantities = {}
  Object.keys(quantityCalculators).forEach((key) => {
    func = quantityCalculators[key]
    quantities[key] = func(params, systemCodeToQuantity)
  })

  return quantities
}

const getWarnings = (params, systemCodeToQuantity) => {
  var warnings = {}
  if (systemCodeToQuantity['ENV-S-WB-230'] > 0 && systemCodeToQuantity['ENV-S-WM-230'] === 0) {
    warnings['ENV-S-WM-230'] =
      'You have chosen the ENVOY-S STANDARD which means that you will not be able to optimise for storage or self consumption. Are you sure?'
  }
  return warnings
}

const upperCaseSafe = (value) => {
  return value && value.toUpperCase ? value.toUpperCase() : value
}

const indexOfCaseInsensitive = (valuesArray, value) => {
  var valuesArrayUpperCase = valuesArray.map((v) => upperCaseSafe(v))
  return valuesArrayUpperCase.indexOf(upperCaseSafe(value))
}

const getOtherComponentForCode = (code) => {
  var codeUpperCase = upperCaseSafe(code)
  return window.AccountHelper.getComponentOtherSpecsAvailable().filter(
    (component) => upperCaseSafe(component.code) === codeUpperCase
  )[0]
}

const codesForSystem = (system) => {
  return [system.moduleType().code].concat(
    system.inverters().map((i) => i.code),
    system.batteries().map((b) => b.code),
    system.others().map((o) => o.code)
  )
}

const systemHasComponent = (system, codes) => {
  if (codes && codes.constructor !== Array) {
    codes = [codes]
  }
  return countComponentsForSystem(system, codes) > 0
}

const countComponentsForSystem = (system, codes) => {
  return codesForSystem(system).filter((code) => indexOfCaseInsensitive(codes, code) !== -1).length
}

export const applyComponentQuantities = (system, componentQuantities, ephemeralComponentsData) => {
  /*
  We assume that any missing components have already been activated by the time this runs

  If component activation is not found we can still create a component using data in ephemeralComponentData
  ephemeralComponentData is in the form:
  [{
    code: "abc",
    title: "def",
    description: "hij",
    ...
  },...]

  */
  var hasChanged = false

  var missingComponents = []

  Object.entries(componentQuantities).forEach(([code, quantity]) => {
    var componentType, ephemeralComponentData

    if (quantity > 0) {
      // We do not need a component to be loaded in order to remove it.
      // We only need a component to be loaded if we are setting quantity >= 1
      componentType = getOtherComponentForCode(code)

      if (!componentType && ephemeralComponentsData) {
        // if no activation, lookup specs in ephemeralComponentData
        ephemeralComponentData = ephemeralComponentsData.find((c) => c.code === code)
      }

      if (!componentType && !ephemeralComponentData) {
        missingComponents.push(code)
        return
      }
    }

    if (quantity > 0 && !systemHasComponent(system, code)) {
      var osOther = componentType
        ? new window.OsOther({
            other_component_type: 'mounting_other',
            other_id: componentType.id,
            quantity: quantity,
          })
        : new window.OsOther({ ...ephemeralComponentData, other_component_type: 'mounting_other', quantity: quantity })

      window.editor.execute(new window.AddObjectCommand(new window.OsOther(osOther), system, false))
      hasChanged = true
    } else {
      // component already added to system, update quantity if necessary
      system
        .others()
        .filter((other) => other.code === code)
        .forEach((other) => {
          if (other.quantity !== quantity) {
            if (quantity === 0) {
              window.editor.deleteObject(other)
            } else {
              window.editor.execute(new window.SetValueCommand(other, 'quantity', quantity, undefined, true))
            }
            hasChanged = true
          }
        })
    }
  })

  if (missingComponents.length) {
    window.Designer.showNotification(
      'Warning: components have not been activated: ' + missingComponents.join(', '),
      'danger'
    )
    return
  }

  // Force System panel to refresh
  if (hasChanged) {
    window.Designer.showNotification('Components updated')
    window.editor.signals.sceneGraphChanged.dispatch()
  }
}

const submitEnphaseBomGeneratorForm = async (system, componentQuantities) => {
  var otherComponentActivationCodes = window.AccountHelper.getComponentOtherSpecsAvailable().map((c) => c.code)

  // First add any components which have quantity > 0 but are not yet activated
  var otherComponentCodesToActivate = Object.entries(componentQuantities)
    .filter(([code, quantity]) => quantity > 0 && indexOfCaseInsensitive(otherComponentActivationCodes, code) === -1)
    .map(([code, quantity]) => code)

  await new Promise((resolve, reject) => {
    if (otherComponentCodesToActivate.length === 0) {
      resolve()
    } else {
      window.AccountHelper.activateComponents(otherComponentCodesToActivate, resolve, undefined, reject)
    }
  })
    .then(() => {
      applyComponentQuantities(system, componentQuantities)
    })
    .catch((error) => {
      console.log('Error activating components', error)
    })
}

const parsePositiveIntOrZero = (value) => {
  var valueInt = value ? parseInt(value) : 0
  return valueInt >= 0 ? valueInt : 0
}

const getCodesAndQuantities = (system) => {
  var result = {}

  ;[
    // Modules uses different format to other components
    { code: system.moduleType().code, quantity: system.moduleQuantity() },
  ]
    .concat(system.inverters(), system.batteries(), system.others())
    .forEach((o) => {
      if (result[o.code]) {
        result[o.code] += o.quantity
      } else {
        result[o.code] = o.quantity
      }
    })
  return result
}

export const EnphaseBomGenerator = ({
  selectedLineItemIndex,
  panelSystem,
  currencySymbol,
  system,
  handleClose,
  countryIso2,
}) => {
  const [state, setState] = useState({
    systemCodeToQuantityOverride: {},
  })
  const classes = useStyles()
  const translate = useTranslateWithVariable()

  var project = window.projectForm?.getState().values || {}

  var params = systemToParams(project, system)

  var systemCodeToQuantity = getCodesAndQuantities(system)

  var recommendedQuantities = getRecommendedQuantities(params, systemCodeToQuantity)

  const codeToDescription = {}
  window.AccountHelper.getComponentOtherSpecsAvailable().forEach((otherComponent) => {
    codeToDescription[otherComponent.code] = otherComponent.title
  })

  const warningsByCode = getWarnings(params, { ...systemCodeToQuantity, ...state.systemCodeToQuantityOverride })

  return (
    <Dialog
      open={true}
      style={{ maxHeight: 'calc(100vh - 56px)' }}
      maxWidth="sm"
      className="enphase-bom-generator-dialog"
    >
      <DialogTitle onClose={handleClose}>{translate('Generate Enphase BOM')}</DialogTitle>
      <DialogContent>
        <div>{translate('Number of phases: %{number_of_phases}', { number_of_phases: params.numberOfPhases })}</div>
        <div>{translate('Selected inverter: %{code}', { code: params.inverterCode })}</div>
        <div>
          {translate('Panel orientations: %{orientations}', {
            orientations: [
              params.numberOfPanelsPortrait > 0
                ? translate('%{quantity} Portrait', { quantity: params.numberOfPanelsPortrait })
                : null,
              params.numberOfPanelsPortrait > 0
                ? translate('%{quantity} Landscape', { quantity: params.numberOfPanelsLandscape })
                : null,
            ]
              .filter(Boolean)
              .join(', '),
          })}
        </div>
        <div>
          {formSections.map((formSection) => (
            <div>
              <hr className="light" style={{ marginTop: 30, marginBottom: 10 }} />
              <h2>{formSection.heading}</h2>
              {formSection.codes.map((code) => {
                var inputFieldValue = state.systemCodeToQuantityOverride.hasOwnProperty(code)
                  ? state.systemCodeToQuantityOverride[code]
                  : systemCodeToQuantity[code] || recommendedQuantities[code] || 0

                return (
                  <>
                    <div style={{ display: 'flex', alignItems: 'flex-start', alignContent: 'center' }}>
                      <div style={{ flex: '0 0 50px' }}>
                        <TextField
                          label={null}
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth={false}
                          value={inputFieldValue}
                          name="quantity"
                          onFocus={() => {
                            markFieldActive.call(panelSystem, 'EnphaseBomTextInput', system)
                          }}
                          onBlur={() => {
                            markFieldInactive.call(panelSystem)
                          }}
                          onChange={(event) => {
                            // This should only be possible when cutting and pasting a negative number because the
                            // min=0 input attribute should prevent adding a minus sign any other way
                            const value = parsePositiveIntOrZero(event.target.value)
                            setState({
                              systemCodeToQuantityOverride: { ...state.systemCodeToQuantityOverride, [code]: value },
                            })
                          }}
                          error={
                            typeof recommendedQuantities[code] !== 'undefined' &&
                            recommendedQuantities[code] !== null &&
                            inputFieldValue !== recommendedQuantities[code]
                          }
                          inputProps={{ min: 0 }}
                        />
                      </div>
                      <div style={{ paddingLeft: 10, paddingTop: 6 }}>
                        {codeToDescription[code] || defaultComponentTitles[code] || code}{' '}
                        <span className="small">({code})</span>
                        {typeof recommendedQuantities[code] !== 'undefined' &&
                          recommendedQuantities[code] !== null &&
                          inputFieldValue !== recommendedQuantities[code] && (
                            <Alert severity="warning">
                              {translate('Recommended quantity: %{quantity}', {
                                quantity: recommendedQuantities[code],
                              })}
                            </Alert>
                          )}
                        {warningsByCode[code] && <Alert severity="warning">{translate(warningsByCode[code])}</Alert>}
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions style={{ paddingTop: 0 }}>
        <Button
          startIcon={<AlertError />}
          onClick={handleClose}
          style={{ margin: 10, position: 'relative' }}
          backgroundColor="#D8D8D8"
        >
          <span>{translate('Cancel')}</span>
        </Button>
        <Button
          className={classes.button}
          startIcon={<ContentSave />}
          name={'raSaveButton'}
          onClick={(e) =>
            submitEnphaseBomGeneratorForm(system, {
              ...recommendedQuantities,
              ...state.systemCodeToQuantityOverride,
            }).then(() => handleClose())
          }
        >
          <span>{translate('Apply')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
