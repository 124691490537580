import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import ProductDetail from './ProductDetail'
import PlaceOrderCta from './cta/PlaceOrderCta'
import useProductDetailLineItem from './useProductDetailLineItem'

const ProductDetailPage = ({ componentCode }: { componentCode: string }) => {
  const history = useHistory<{ from?: string }>()
  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'product_detail',
    })
  }, [])
  //decode componentCode from URL to ensure correct matching for api param
  const decodedComponentCode = decodeURIComponent(componentCode)
  const { lineItem, loading, updateLineItem } = useProductDetailLineItem({
    componentCode: encodeURIComponent(decodedComponentCode),
    confirmed: true,
  })
  const CtaAction = useMemo(
    () => (lineItem != null ? <PlaceOrderCta lineItem={lineItem} updateLineItem={updateLineItem} /> : null),
    [lineItem, updateLineItem]
  )
  const previousUrl = history.location.state?.from
  const handleBack = useCallback(() => {
    const redirectUrl = previousUrl || '/shop'
    history.push(redirectUrl)
  }, [previousUrl])

  return (
    <ProductDetail
      lineItem={lineItem}
      loading={loading}
      CtaAction={CtaAction}
      goBack={handleBack}
      trackSource="product_detail"
    />
  )
}

export default ProductDetailPage
