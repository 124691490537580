import { makeStyles, Theme } from '@material-ui/core'

import { FormProps } from 'elements/react-admin/SubFormDialog'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import CustomDropdownInput from '../../../common/CustomDropdownInput'

const useStyles = makeStyles((theme: Theme) => ({
  addContactBtn: {
    marginRight: 'auto !important',
  },
}))

const AddContactBtn: React.FC<FormProps> = ({ form, formState }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = formState.values
  const addNewContact = (existing) => {
    form.change('contacts_data', [...formValues?.contacts_data, existing ? { assignExisting: true } : undefined])
  }
  const actions = useMemo(
    () => [
      {
        label: translate('Add New Contact'),
        onClick: () => {
          addNewContact(false)
        },
      },
      {
        label: translate('Add Existing Contact'),
        onClick: () => {
          addNewContact(true)
        },
      },
    ],
    [formValues]
  )
  return (
    <CustomDropdownInput
      actions={actions}
      className={classes.addContactBtn}
      emptyText={translate('Add Contact')}
      variant="outlined"
    />
  )
}
export default AddContactBtn
