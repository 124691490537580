import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useMemo } from 'react'
import BasicFilter from '../basic/BasicFilter'
import { DISTRIBUTOR_FILTER_KEY_V2 } from '../distributor/DistributorFilterConfigV2Factory'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import useMultiDistributorsAvailableBrands from './useMultiDistributorsAvailableBrands'
import { useSelector } from 'react-redux'
import { RootState } from '../../../types/state'
import { DesignTabs } from '../../../reducer/designer/view'
import { parseStringifiedListFilterValue } from '../utils/utils'
import type { ComponentTypesV2 } from '../../../types/selectComponent'
import { OtherComponentTypes } from '../../../types/otherComponent'

export const DISTRIBUTOR_BRAND_FILTER_KEY_V2 = 'manufacturer_names'
const defaultSelectedOptionValue = ''
export const SELECT_ALL_OPTION = { id: 'default', title: 'All', value: defaultSelectedOptionValue }

type DistributorBrandManufacturerFilterPropsType = {
  componentTypes?: DesignTabs[]
}
const DistributorBrandManufacturerFilter = <T,>({
  label = 'Brand',
  distributors,
  componentTypes,
  otherComponentTypes,
  ...props
}: FilterGenericPropsType &
  DistributorBrandManufacturerFilterPropsType &
  ConfigurableFilterOptionsType<string, T> & {
    distributors: HardwareSupplierFilterKeyType[]
    otherComponentTypes: Set<OtherComponentTypes>
  }) => {

  const availableBrands = useMultiDistributorsAvailableBrands({
    distributors: distributors,
    componentType: componentTypes,
    otherComponentTypes: otherComponentTypes
  })

  const options = useMemo(() => {
    return availableBrands.sort().reduce(
      (options, brand) => {
        return options.concat({ id: brand, value: encodeURIComponent(brand), title: brand })
      },
      [SELECT_ALL_OPTION]
    )
  }, [availableBrands])

  return (
    <BasicFilter
      label={label}
      defaultSelectedOptionValue={SELECT_ALL_OPTION.value}
      options={options}
      filterKey={DISTRIBUTOR_BRAND_FILTER_KEY_V2}
      {...props}
    />
  )
}

const createDistributorBrandFilterNode = <T,>(
  config: ConfigurableFilterOptionsType<string, T>
): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const currentSelectedDistributor = filterProps.allFilterValues[DISTRIBUTOR_FILTER_KEY_V2] // This won't work when distributors turn to multi select checkbox
    const selectedTab = useSelector((state: RootState) => state.designer.view.selectedTab)
    const selectorConfigTypes = parseStringifiedListFilterValue<OtherComponentTypes>({
      filterValues: filterProps.persistentFilterValues, // Hardware selector sets what available at creation time
      key: 'types',
    })

    if (!!currentSelectedDistributor) {
      return (
        <DistributorBrandManufacturerFilter
          componentTypes={selectedTab ? [selectedTab] : []}
          distributors={[currentSelectedDistributor]}
          otherComponentTypes={selectorConfigTypes}
          {...config}
          {...filterProps}
        />
      )
    } else {
      return null
    }
  }

  return {
    key: DISTRIBUTOR_BRAND_FILTER_KEY_V2,
    component: FilterComponent,
  }
}

export default createDistributorBrandFilterNode
