import { Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import React, { FC } from 'react'
import { BooleanInput, FormDataConsumer, NumberInput, SelectInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'
import { requiredOrZero } from 'validations'
import { PRICE_ONLY_ADDER_FORMULA_CHOICES } from './AddersForm'

type PropsType = {
  resource: string
}

export const ADDER_DEALER_FEE_INTERACTION_CHOICES = [
  { id: 0, name: 'No Dealer Fee Charged on Adder', inputs: ['PriceAdderInputs'] },
  { id: 1, name: 'Apply Dealer Fee to Adder Value', inputs: ['PriceAdderInputs'] },
  { id: 2, name: 'Apply All Dealer Fee to Solar Price', inputs: ['PriceAdderInputs'] },
]

export const ADDER_DEALER_FEE_CHOCIES_SHOWN_TO_CUSTOMER = [
  { id: 0, name: 'No Dealer Fee Charged' },
  { id: 1, name: 'Dealer Fee Charged - Apply to Adder in Quotation Table' },
  { id: 2, name: 'Dealer Fee Charged - Apply to Standard System Price in Quotation Table' },
]

export const ADDER_DEALER_FEE_CHOCIES_NOT_SHOWN_TO_CUSTOMER = [
  { id: 0, name: 'No Dealer Fee Charged' },
  { id: 1, name: 'Dealer Fee Charged - Apply to Adder' },
  { id: 2, name: 'Dealer Fee Charged - Apply to Standard System Price' },
]

export const getAdderDealerFeeChoices = (showCustomer: boolean) => {
  return showCustomer ? ADDER_DEALER_FEE_CHOCIES_SHOWN_TO_CUSTOMER : ADDER_DEALER_FEE_CHOCIES_NOT_SHOWN_TO_CUSTOMER
}

const PriceAdderInputs: FC<PropsType> = ({ resource }) => {
  const translate = useTranslate()
  const currencySymbol = useSelector((state: any) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2>{translate('Price Adder')}</h2>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormDataConsumer>
          {({ formData, ...rest }: any) => {
            const adderFormula = PRICE_ONLY_ADDER_FORMULA_CHOICES.find((choice) => choice.id === formData.formula)
            let endAdornmentValue = ''
            if (adderFormula && adderFormula.name !== 'Fixed') {
              endAdornmentValue = adderFormula.name
            }

            return (
              <NumberInput
                name={'value'}
                source="value"
                resource={resource}
                validate={requiredOrZero}
                label={'Price Adder'}
                startAdornment={adderFormula?.name.includes('%') ? '%' : currencySymbol}
                endAdornment={endAdornmentValue.includes('%') ? '' : translate(endAdornmentValue)}
                options={{ helperText: 'Discount(-)/Adder(+)' }}
                fullWidth
                style={{ width: '100%' }}
              />
            )
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <NumberInput
          name={'tax_included'}
          source="tax_included"
          validate={requiredOrZero}
          resource={resource}
          label="Tax (%)"
          endAdornment={'%'}
          fullWidth
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormDataConsumer>
          {({ formData, ...rest }: any) => {
            const adderChoices = getAdderDealerFeeChoices(formData.show_customer)
            return (
              <CustomField
                name={'dealer_fee_interaction_type'}
                source="dealer_fee_interaction_type"
                defaultValue={2}
                resource={resource}
                label="Dealer Fee Interaction Type"
                choices={adderChoices}
                component={SelectInput}
                fullWidth
                style={{ width: '100%' }}
              />
            )
          }}
        </FormDataConsumer>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <CustomField
          style={{ width: '100%' }}
          name={'value_includes_tax'}
          resource={resource}
          label={'Including Tax'}
          component={BooleanInput}
          defaultValue={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <CustomField
          style={{ width: '100%' }}
          name={'show_customer'}
          resource={resource}
          label={'Show to Customer'}
          component={BooleanInput}
        />
      </Grid>
    </Grid>
  )
}

export default PriceAdderInputs
