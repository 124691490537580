import { makeStyles } from '@material-ui/core'
import { useProjectNavItems } from './hooks'
import ProjectNavLink from './ProjectNavLink'

const useStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
    },
  },
  { name: 'NavLinks' }
)

const NavLinks = (props) => {
  const classes = useStyles()
  const links = useProjectNavItems()
  if (!!!links?.length) return null
  return (
    <div className={classes.wrapper}>
      {links.map((navLink, i) => (
        <ProjectNavLink key={'navlink-' + i} name={navLink.id} label={navLink.label} redirect={navLink.redirect} />
      ))}
    </div>
  )
}

export default NavLinks
