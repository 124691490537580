import { CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { memo, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { currencySymbolForCountry, formatCurrency } from 'util/misc'
import { CheckoutPresenterContext, ShippingDetailContext, ShippingFeeFetchContext } from './CheckoutProvider'
import { useSolarJuiceStyles } from './style'
import { ShippingDetailType } from './type'

type ShippingMethodType = {
  label: string
  subLabel?: string
  value: string
}

const RadioItem = ({
  item,
  selectedShippingMethod,
  shippingFee,
  loading,
}: {
  item: ShippingMethodType
  selectedShippingMethod: string
  shippingFee: string | number
  loading: boolean
}) => {
  const solarJuiceClasses = useSolarJuiceStyles()
  const currencySymbol = useSelector((state: any) => currencySymbolForCountry(orgSelectors.getOrgIso2(state)))
  const fee = typeof shippingFee === 'string' ? shippingFee : `${currencySymbol} ${formatCurrency(shippingFee)}`
  const disabled = item.value === 'mainfreight' && loading
  return (
    <FormControlLabel
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
      }}
      value={item.value}
      control={
        <Radio
          color="secondary"
          style={{
            paddingTop: '0px',
            color: item.value === selectedShippingMethod ? '#4272DD' : '#B4B4B4',
          }}
          disabled={disabled}
        />
      }
      label={
        <Grid container direction="row" style={{ width: '540px' }}>
          <Grid item xs={10}>
            <Typography className={solarJuiceClasses.font14}>{item.label}</Typography>
            {item.subLabel && (
              <Typography
                className={solarJuiceClasses.font14}
                style={{
                  color: '#7F7D83',
                }}
              >
                {item.subLabel}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={`${solarJuiceClasses.font14} ${solarJuiceClasses.fontWeightMedium}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {item.value !== 'mainfreight' ? fee : loading ? <CircularProgress size={25} color="inherit" /> : fee}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  )
}

const ShippingMethod = () => {
  const solarJuiceClasses = useSolarJuiceStyles()
  const shippingDetail: ShippingDetailType = useContext(ShippingDetailContext)
  const checkoutPresenter = useContext(CheckoutPresenterContext)
  const isFetchingShippingFee = useContext(ShippingFeeFetchContext)
  const [shippingMethodFee, setShippingMethodFee] = useState<any>({
    commercial_delivery: 'TBD',
    residential_delivery: 'TBD',
    pick_up: 'Free',
    mainfreight: 0,
  })
  const shippingMethods: ShippingMethodType[] = [
    {
      label: 'Request Commercial Delivery Quote',
      subLabel: 'For deliveries to commercial addresses (eg. businesses, offices)',
      value: 'commercial_delivery',
    },
    {
      label: 'Request Residential Delivery Quote',
      subLabel: 'For deliveries to residential homes (eg. homes, apartments)',
      value: 'residential_delivery',
    },
    {
      label: 'Pickup at warehouse',
      value: 'pick_up',
    },
    {
      label: 'Mainfreight Shipping (Commercial Delivery Address)',
      subLabel: 'General Shipping',
      value: 'mainfreight',
    },
  ]

  useEffect(() => {
    ;(async () => {
      checkoutPresenter?.updateIsFetchingShippingFee(true)
      const rates = await checkoutPresenter?.getShippingRates(shippingDetail)
      const mainfreight = rates?.find((item) => item.description === 'General Shipping')
      const mainfreightFee = mainfreight ? mainfreight.total_price / 100 : 0
      setShippingMethodFee({
        ...shippingMethodFee,
        mainfreight: mainfreightFee,
      })
      checkoutPresenter?.updateIsFetchingShippingFee(false)
      if (shippingDetail.shippingMethod === 'mainfreight') {
        checkoutPresenter?.updateShippingDetail({
          ...shippingDetail,
          fee: mainfreightFee.toFixed(2),
        })
      }
    })()
  }, [shippingDetail.contactAddress])

  const handleUpdateShippingMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    logAmplitudeEvent('hardware_solar_outlet_credit_checkout_shipping_method_updated', {
      distributor: 'outlet',
      source: 'solar_juice_checkout',
      shipping_method: event.target.value,
    })
    checkoutPresenter?.updateShippingDetail({
      ...shippingDetail,
      fee: shippingMethodFee[event.target.value],
      shippingMethod: event.target.value,
    })
  }
  return (
    <Grid container direction="column" style={{ padding: '20px 0px' }}>
      <Grid item xs={3}>
        <Typography variant="h6" className={`${solarJuiceClasses.font18} ${solarJuiceClasses.fontWeightMedium}`}>
          <span style={{ marginRight: '20px' }}>2</span>
          <span>Select shipping</span>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: '20px 0px 0px 24px' }}>
        <RadioGroup
          name="shippingMethod"
          style={{ gap: '20px' }}
          value={shippingDetail.shippingMethod}
          onChange={handleUpdateShippingMethod}
        >
          {shippingMethods.map((item: ShippingMethodType) => (
            <RadioItem
              item={item}
              selectedShippingMethod={shippingDetail.shippingMethod}
              key={item.value}
              shippingFee={shippingMethodFee[item.value]}
              loading={isFetchingShippingFee}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  )
}

export default memo(ShippingMethod)
