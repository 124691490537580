import { makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { Theme } from 'types/themes'
import ChipArrayInput from '../../common/ChipsArrayInput'
import HoverEditWrapper from '../../common/HoverEditWrapper'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../styles'

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    minHeight: 30,
    display: 'flex',
    marginTop: 8,
    alignItems: 'center',
    color: COLOR_PALETTE.darkGrey,
  },
  chipStyle: {
    '& .MuiChip-deleteIcon': {
      margin: 0,
    },
    margin: '2px !important',
  },
  addBtn: {
    margin: '0 !important',
    marginTop: '8px !important',
  },
}))
const ProjectTags = ({ wrapperClass }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  const tags_data = useMemo(() => project.tags_data, [project.tags_data])
  return (
    <HoverEditWrapper
      modalProps={{
        headerText: translate('Tags'),
        content: <TagsFormContent />,
      }}
      className={wrapperClass}
      titleElement={<p>{translate('Tags')}</p>}
      emptyToggle={
        !!!project?.tags?.length
          ? {
              label: <>{translate('Add Tags')}</>,
              startIcon: <Add />,
              className: classes.addBtn,
            }
          : undefined
      }
    >
      <div className={classes.contentWrapper}>
        {!!tags_data?.length && (
          <div className={sectionStyles.row}>
            {tags_data.slice(0, 2).map((x) => (
              <Chip label={x.title} className={sectionStyles.detailChip} />
            ))}
            {tags_data.length > 2 && <>+{tags_data.length - 2}</>}
          </div>
        )}
      </div>
    </HoverEditWrapper>
  )
}

const TagsFormContent = (props) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const sectionStyles = getProjectSectionStyles()

  useEffect(() => {
    form.registerField('tags_data', () => {}, {})
  }, [])

  return (
    <ChipArrayInput
      source={'tags'}
      resource={'tags'}
      reference={'tags'}
      chipsInputProps={{
        source: 'tags',
        options: {
          placeholder: !!formValues.tags?.length ? undefined : 'Input a tag',
          label: translate('Tags'),
        },
        chipClassName: `${sectionStyles.detailChip} ${classes.chipStyle}`,
      }}
      onChange={(values) => {
        form.change('tags_data', values)
      }}
    />
  )
}
export default ProjectTags
