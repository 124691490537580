import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import CreateEmptyOrderButton from 'layout/widgets/CreateEmptyOrderButton'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import { Confirm } from 'ra-ui-materialui'
import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { setAuthRedirection, storeAuthRedirectionToLocalStorage } from 'redirections/authRedirectionStore'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'
import { OidcContext } from './OidcWrapper'

const useStyles = makeOpenSolarStyles((theme) => ({
  button: { margin: 5 },
}))

type CityPlumbingUserButtonProps = {
  layout: number | null
  allowOrdering: boolean
  variant: 'contained' | 'outlined' | 'text'
  trackSource: string
  color?: 'default' | 'primary' | 'secondary' | 'warning'
  label?: string
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const ConnectingMessage = () => {
  const classes = useStyles()
  return <span className={`small ${classes.button}`}>Connecting to City Plumbing...</span>
}

export const CityPlumbingConnectUserButton: React.FC<CityPlumbingUserButtonProps> = ({
  layout,
  allowOrdering,
  variant,
  trackSource,
  color = 'default',
  label = 'Connect City Plumbing...',
}) => {
  const {
    isAuthenticated: isOidcAuthenticated,
    signinRedirect,
    signinSilent,
    signoutPopup,
    removeUser,
    events: authEvents,
    isLoading,
  } = useAuth()

  const translate = useTranslateWithVariable()

  const classes = useStyles()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isOidcAuthenticated)
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const [confirmingConnect, setConfirmingConnect] = useState(false)
  const isEmptyOrder = useSelector(orderSelectors.getIsEmptyOrder)
  const orgId = useSelector(authSelectors.getOrgId)

  useEffect(() => {
    if (!isAuthenticated) {
      // Check if we have a refresh token on the backend.
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + '/distributors/city_plumbing/authentication_status/',
      })
        .then((response) => {
          if (response?.data?.result?.is_authenticated) {
            setIsAuthenticated(true)
          }
        })
        .catch((err: any) => {
          console.log(err, err)
        })
    }
  }, [orgId, isAuthenticated])

  useEffect(() => {
    return authEvents.addAccessTokenExpiring(() => {
      signinSilent()
    })
  }, [])

  const onClickConnect = () => {
    logAmplitudeEvent('hardware_distributor_connect_button_clicked', {
      source: trackSource,
      distributor: 'city_plumbing',
    })
    setConfirmingConnect(true)
  }

  const handleLogin = () => {
    setAuthRedirection({ type: 'AUTH_SUCCESS_REDIRECT', redirect: '/shop/after_connect/city_plumbing' })
    storeAuthRedirectionToLocalStorage()

    signinRedirect()
  }

  const onClickDisconnect = () => {
    logAmplitudeEvent('disconnect_city_plumbing_clicked', { source: trackSource })

    restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + orgId + '/distributors/city_plumbing/disconnect/',
    })
      .then((response) => {
        setIsAuthenticated(false)
        signoutPopup()
        removeUser()
      })
      .catch((err: any) => {
        console.log(err, err)
      })
  }

  let currentControl
  if (isAuthenticated) {
    if (allowOrdering && isEmptyOrder && !enableHardwareOrdering) {
      // Is able to create orders but doesn't have an order
      currentControl = (
        <span style={{ display: 'inline-block' }}>
          <CreateEmptyOrderButton layout={layout} enableHardwareOrdering={enableHardwareOrdering} />
        </span>
      )
    } else if (!allowOrdering) {
      currentControl = (
        <Button
          onClick={onClickDisconnect}
          variant={variant}
          color={color}
          size={allowOrdering ? 'small' : undefined}
          label={translate('Disconnect City Plumbing')}
          disabled={false}
          className={classes.button}
        />
      )
    }
  } else if (!isAuthenticated && !isLoading) {
    currentControl = (
      <Button
        onClick={onClickConnect}
        variant={variant}
        color={color}
        size={allowOrdering ? 'small' : undefined}
        label={label}
        disabled={false}
        className={classes.button}
      />
    )
  } else if (isLoading) {
    currentControl = <ConnectingMessage />
  }

  return (
    <>
      {currentControl}
      <Confirm
        isOpen={confirmingConnect}
        title="Connect to City Plumbing"
        content="You will be redirected to the City Plumbing login page to connect your accounts, please confirm to proceed. Any unsaved changes will be discarded."
        confirmColor="warning"
        onConfirm={() => handleLogin()}
        onClose={() => setConfirmingConnect(false)}
      />
    </>
  )
}

const Wrapper: React.FC<CityPlumbingUserButtonProps> = (props) => {
  const oidcContext = useContext(OidcContext)
  if (oidcContext.isWrapped) {
    return <CityPlumbingConnectUserButton {...props} />
  }

  return <></>
}

export default Wrapper
