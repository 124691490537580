import { Dialog, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import useFlow from 'elements/flow/useFlow'
import { IconButton } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import Breadcrumbs from './components/Breadcrumbs'
import {
  BSA_FLOW_CONFIG,
  BSA_FLOW_STEPS,
  BSA_MANUAL_NODE_KEY,
  BSA_MODE_NODE_KEY,
  BSA_NODES_WITH_BREADCRUMBS,
} from './utility/constants'
import type { BatterySalesAssistantFlowNodeConfigType, BatterySalesAssistantFlowStoreType } from './utility/types'

type StyleProps = {
  isManualSelectorView: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  dialogPaper: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: props.isManualSelectorView ? '0px' : '32px',
    padding: props.isManualSelectorView ? '0px' : '40px',
  }),
  dialogTitleContainer: (props: StyleProps) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: props.isManualSelectorView ? '0px 20px' : '0px',
  }),
  dialogTitle: (props: StyleProps) => ({
    color: '#212121',
    fontSize: props.isManualSelectorView ? '20px' : '24px',
    fontWeight: props.isManualSelectorView ? 600 : 500,
    lineHeight: 'normal',
  }),
  divider: {
    background: '#D9DBE9',
    height: '1px',
    minHeight: '1px',
    width: '100%',
  },
}))

interface BatterySalesAssistantDialogProps {
  isOpen: boolean
  onClose: () => void
  projectId: number
  systemUuid: string
}

export const BatterySalesAssistantDialog: React.FC<BatterySalesAssistantDialogProps> = ({
  isOpen,
  onClose,
  projectId,
  systemUuid,
}) => {
  const translate = useTranslate()

  const INITIAL_STORE: Omit<BatterySalesAssistantFlowStoreType, 'onFinish'> = {
    backupAppliances: undefined,
    backupAppliancesTab: undefined,
    backupHours: undefined,
    customerPriority: undefined,
    totalContinuousPower: undefined,
    totalPeakPower: undefined,
    mode: undefined,
    limitedCodes: undefined,
    selection: undefined,
    projectId,
    systemUuid,
  }

  const initialNodeConfig: BatterySalesAssistantFlowNodeConfigType = {
    title: translate('Add Battery'),
    currentNodeKey: BSA_MODE_NODE_KEY,
    options: {},
  }

  const handleClose = () => {
    logAmplitudeEvent('bda_close_icon_clicked')
    onClose()
    flowController.goTo(initialNodeConfig)
    flowController.setFlowStore(({ onFinish }) => ({ onFinish, ...INITIAL_STORE }))
  }

  const { currentNodeConfig, flow, flowController } = useFlow<
    typeof BSA_FLOW_CONFIG,
    BatterySalesAssistantFlowStoreType
  >({
    initialNodeConfig,
    flowConfigs: BSA_FLOW_CONFIG,
    initialStore: {
      ...INITIAL_STORE,
      onFinish: handleClose,
    },
  })
  const currentStep = BSA_FLOW_STEPS[currentNodeConfig.currentNodeKey]
  const shouldShowBreadcrumbs = BSA_NODES_WITH_BREADCRUMBS.includes(currentNodeConfig.currentNodeKey)
  const classes = useStyles({ isManualSelectorView: currentNodeConfig.currentNodeKey === BSA_MANUAL_NODE_KEY })

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth={currentNodeConfig.currentNodeKey === BSA_MODE_NODE_KEY ? 'lg' : 'xl'}
      open={isOpen}
      onBackdropClick={handleClose}
    >
      <DialogTitle classes={{ root: classes.dialogTitleContainer }} disableTypography>
        {shouldShowBreadcrumbs ? (
          <Breadcrumbs
            currentStep={currentStep}
            steps={[translate('Customer Goals'), translate('Backup Requirements'), translate('Select Battery')]}
          />
        ) : (
          <span className={classes.dialogTitle}>{currentNodeConfig.title ?? translate('Add Battery')}</span>
        )}
        <IconButton id="SelectComponentDialogClose" onClick={handleClose}>
          <CloseIcon style={{ color: '#000000' }} />
        </IconButton>
      </DialogTitle>
      <div className={classes.divider} />
      {flow}
    </Dialog>
  )
}

export default BatterySalesAssistantDialog
