import { useSetAmplitudeIdentity } from 'amplitude/useSetAmplitudeIdentity'
import { useTrackPartnerTags } from 'amplitude/useTrackPartnerTags'
import useBrandingMetaData from 'branding/useBrandingMetaData'
import { push } from 'connected-react-router'
import { setStudioMode as setStudioModeAction, StudioModeState } from 'ducks/studioMode'
import ElectricalDialog from 'elements/electrical/ElectricalDialog'
import CustomImageryDialog from 'projectSections/elements/CustomImageryDialog'
import UnsavedDataRecoveryPrompt from 'projectSections/elements/UnsavedDataRecoveryPrompt'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SdkMounterIfNested } from 'sdk/SdkMounter'
import { useLocalStoragePoller } from 'storage/useLocalStoragePoller'
import { useSessionStorageLogic } from 'storage/useSessionStorageLogic'
import { useSetupFetchMiddleware } from 'util/fetch/useSetupFetchMiddleware'
import HelpCenterDrawer from '../layout/widgets/HelpCenterDrawer'
import myApiRestClient from '../restClient'
import EagleViewOrderDialog from './eagleViewOrderDialog'
import AccessDialog from './elements/AccessDialog'
import DesignerComponent from './elements/DesignerComponent'
import InvalidProjectDialog from './elements/InvalidProjectDialog'
import LoginWithNearmapDialog from './elements/LoginWithNearmapDialog'
import OrderComponentsDialog from './elements/OrderComponentsDialog'
import PvsellProposalDialog from './elements/PvsellProposalDialog'
import SwitchInterfaceConfirmationDialog from './elements/SwitchInterfaceConfirmationDialog'
import UploadLogoDialog from './elements/UploadLogoDialog'
import UserAgreementDialog from './elements/UserAgreementDialog'
import InAppPromo from './inAppPromo'
import FujiTourController from './tours/fuji'
import PaymentsTourController from './tours/payments'
import SplitInit from './utils/SplitInit'

const API_URL = window.API_ROOT + '/api'

export const PersistentContent = () => {
  const dispatch = useDispatch()
  const [studioMode, setStudioMode] = useState<StudioModeState>('hidden')

  // Business Logic
  useSessionStorageLogic()
  useLocalStoragePoller()
  useSetAmplitudeIdentity()
  useTrackPartnerTags()
  useBrandingMetaData()
  useSetupFetchMiddleware()

  useEffect(() => {
    window.setStudioMode = function (studioMode) {
      dispatch(setStudioModeAction(studioMode))

      //Note: This only sets the mode, it doesn't update the objects because they haven't actually loaded yet
      if (studioMode === 'hidden') {
        window.editor.setMode('hidden')
      } else {
        var discardChanges = true
        var isInteractive = studioMode === 'studio' || studioMode === 'studioLite' || studioMode === 'explore'
        window.editor.setMode(isInteractive ? 'interactive' : 'presentation', discardChanges)
      }

      window.Designer.onWindowResize(null, true)
      setStudioMode(studioMode)
    }

    window.launch = function (params, studioMode) {
      if (params && params.api_key_google) {
        window.AccountHelper.overrideApiKeyGoogle = params.api_key_google
      } else {
        // Never clear overrideApiKeyGoogle, only overwrite with a new (not-empty) value
        // to avoid some race-condition bugs
        // window.AccountHelper.overrideApiKeyGoogle = null
      }

      // _this.setState({
      //   studioMode: studioMode,
      // })
      dispatch(setStudioModeAction(studioMode))

      if (studioMode === 'hidden') {
        // exit early for both new and old proUX, skip loading profile and making any other changes when hidden
        return
      }

      window.SceneHelper.showObliqueFirstIfAvailable = false

      if (studioMode === 'project') {
        window.LoaderHelper.active = false
      } else {
        window.LoaderHelper.active = true
      }

      //if metadata missing then assume we are building the design using "init", do not try to parse an existing design
      if (params.data && params.data.metadata) {
        //if self-contained data is available use it
        window.WorkspaceHelper.loadWithData(window.editor, params.data, params)
      } else {
        window.WorkspaceHelper.loadWithProfile(params)
      }

      //For all onWidowResize events pass forceRenderOnFinished=true to force clearing of artifacts

      window.Designer.onWindowResize(null, true)

      //Hack to fix myenergy not resizing as required
      // setTimeout(function(){
      //     if(window.logger) window.logger('window.Designer.onWindowResize() after timeout 10')
      //     window.Designer.onWindowResize()
      // }, 10)

      setTimeout(function () {
        if (window.logger) window.logger('window.Designer.onWindowResize() after timeout 1000')
        window.Designer.onWindowResize(null, true)
      }, 1000)

      //Added as hack when loading MyEnergy after Studio
      setTimeout(function () {
        if (window.logger) window.logger('window.Designer.onWindowResize() after timeout 3000')
        window.Designer.onWindowResize(null, true)
      }, 3000)

      //Call just in case animations were previously stopped on view change
      //window.designerAnimationController('start')

      // Run callbacks when editor is ready. e.g. React elements can fire a setup function after editor is ready
      // Beware: This is a hack for react - ensure the components are not unmounted or re-created otherwise references
      // will be broken.
      if (window.callWhenEditorReady) {
        window.callWhenEditorReady.forEach(function (func) {
          if (func) {
            func()
          } else {
            console.warn('callWhenEditorReady callback function invalid')
          }
        })
        window.callWhenEditorReady = undefined
      }
    }

    // This logic is used when logging in via NMOS in the mobile application
    // See logic in `mobile\App.js` for more context
    if (
      window.pathToLoadOnStart &&
      window.pathToLoadOnStart.length > 0 &&
      window.pathToLoadOnStart !== 'PATH_TO_LOAD_ON_START'
    ) {
      dispatch(push(window.pathToLoadOnStart))
      window.pathToLoadOnStart = undefined
    }
  }, [])

  return (
    <div id="PersistentContent">
      <SplitInit />
      <FujiTourController />
      <PaymentsTourController />
      <PvsellProposalDialog />
      <OrderComponentsDialog />
      <EagleViewOrderDialog />
      <ElectricalDialog />
      <UnsavedDataRecoveryPrompt />
      <InvalidProjectDialog />
      <LoginWithNearmapDialog />
      <AccessDialog restClientInstance={myApiRestClient(API_URL)} />
      <InAppPromo />
      <UserAgreementDialog restClientInstance={myApiRestClient(API_URL)} />
      <UploadLogoDialog />
      <CustomImageryDialog />
      <DesignerComponent id="DesignerComponent" />
      <SwitchInterfaceConfirmationDialog />
      <HelpCenterDrawer />
      <SdkMounterIfNested />
    </div>
  )
}

export default PersistentContent
