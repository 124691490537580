import Typography from '@material-ui/core/Typography'
import { OpenSolarThemeType } from 'Themes'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  title: {},
}))

const ProductDetailDescription = ({ description }) => {
  return (
    <div>
      <Typography variant="h6">About this item</Typography>
      <div>{description}</div>
    </div>
  )
}

export default ProductDetailDescription
