import StarIcon from '@material-ui/icons/Star'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import InverterQuantityBlock from 'elements/hardwareSelectorV2/elements/InverterQuantityBlock'
import { Box, Chip, ComponentVersionsInherit, Grid } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import ComponentOverviewIcon from '../../../hardwareSelector/ComponentOverview'
import DistributorBlock from '../../elements/DistributorBlock'
import DistributorDetails from '../../elements/DistributorDetails'
import HardwareImage from '../../elements/HardwareImage'
import { useHardwareListRowStyles } from '../../elements/HardwareListRow'
import InverterRatingBlock from '../../elements/InverterRatingBlock'
import ManufacturerBlock from '../../elements/ManufacturerBlock'
import NameBlock from '../../elements/NameBlock'
import PriceBlock from '../../elements/PriceBlock'
import QuantityBlock from '../../elements/QuantityBlock'
import SelectButton from '../../elements/SelectButton'
import { InverterWizardRecordType } from '../types'

export interface InverterAutoListRowProps {
  allowCreate: boolean
  isSelectDisabled: boolean
  record: InverterWizardRecordType
  onSelect(record: InverterWizardRecordType): void
}

const InverterAutoListRow: React.FC<InverterAutoListRowProps> = ({
  allowCreate,
  isSelectDisabled,
  record,
  onSelect,
}) => {
  const isAU = useSelector(orgSelectors.getOrgIso2) === 'AU'
  const orgName = useSelector(orgSelectors.getOrg)?.name
  const distributorPricingRolePermission = useSelector(permissionsSelectors.getPermissionByKey('distributor_pricing'))
  const classes = useHardwareListRowStyles({ isExpandable: false })
  const hasEnabledDistributors = (useSelector(orgSelectors.getEnabledHardwareSuppliers)?.length ?? 0) > 0
  const component = record.components[0]

  // We show the product image as long as the ordering_v2 has segen as a distributor
  const isProductOnSegen = component.ordering_v2.some(({ distributor }) => distributor === 'segen')
  const shouldShowProductImage =
    component.is_exhibitor || isProductOnSegen || component.ordering_v2.filter((v) => v.is_available).length > 0
  const productImage =
    component.ordering_v2?.find(({ image_url: imageUrl }) => imageUrl)?.image_url || component.promotional_image || ''

  const Row = (
    <div className={classes.row}>
      <Grid alignItems="flex-start" className={classes.gridContainer} container xs={12}>
        {component.is_my_list && !component?.is_archived && <StarIcon className={classes.starIcon} />}
        <Grid item xs={1}>
          <HardwareImage imageUrl={shouldShowProductImage ? productImage : ''} />
        </Grid>
        <Grid item xs={2}>
          <ManufacturerBlock
            name={component.manufacturer_name}
            logoUrl={component.manufacturer_logo}
            isExhibitor={component.is_exhibitor}
            distributors={component.ordering_v2}
          />
          {component.is_exhibitor && (
            <div className={classes.badgeWrapper}>
              <ComponentVersionsInherit versions={ComponentVersions_3_0}>
                <Chip label="Showcase Partner" color="info" />
              </ComponentVersionsInherit>
            </div>
          )}
        </Grid>
        <Grid item xs={isAU ? 3 : 2}>
          <NameBlock
            name={component.code}
            series={component.ordering_v2?.find(({ short_description }) => short_description)?.short_description}
            helperIcon={<ComponentOverviewIcon component={{ ...(component?.data as ComponentType) }} />}
          />
        </Grid>
        <Grid item xs={1}>
          <InverterQuantityBlock quantity={component.quantity} />
        </Grid>
        <Grid item xs={hasEnabledDistributors ? 2 : 5}>
          <InverterRatingBlock acDcRatio={record.system_dc_ac_ratio} combinedRating={record.combined_rating} />
        </Grid>
        {hasEnabledDistributors && distributorPricingRolePermission.allowView && (
          <>
            <Grid item xs={1}>
              <DistributorBlock distributors={component.ordering_v2 || []} />
            </Grid>
            <>
              {!isAU && (
                <Grid item xs={1}>
                  <QuantityBlock distributors={component.ordering_v2 || []} />
                </Grid>
              )}
              <Grid item xs={1}>
                <PriceBlock
                  code={component.code}
                  combinedRating={record.combined_rating}
                  componentType={'inverter'}
                  distributors={component.ordering_v2 || []}
                />
              </Grid>
            </>
          </>
        )}
        <Grid item xs={1}>
          <Box className={classes.selectContainer} display="flex">
            <SelectButton
              enabled={allowCreate || component.is_my_list}
              isLoading={isSelectDisabled}
              orgName={orgName}
              handleSelect={() => onSelect(record)}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <div className={classes.container}>
      {false ? (
        <AccordionCard
          detailsClassName={classes.accordionDetails}
          disableGutters
          elevation={0}
          name={`component-${component.code}`}
          render={() => (
            <DistributorDetails
              code={component.code}
              componentType={'inverter'}
              // Ignored as isExpandable already ensures min. length of component.ordering is 2
              // @ts-ignore
              distributors={component.ordering_v2}
            />
          )}
          summaryContent={Row}
        />
      ) : (
        Row
      )}
    </div>
  )
}

export default memo(InverterAutoListRow)
