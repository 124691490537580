import { makeStyles } from '@material-ui/core'
import AlertsPopover from 'projectSections/sections/common/AlertsPopover'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { useCallback, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(
  {
    link: {
      fontWeight: 500,
      padding: '15px 20px',
      textDecoration: 'none',
      '&:link': {
        color: COLOR_PALETTE.iconColor,
      },
      '&:visited': {
        color: COLOR_PALETTE.iconColor,
      },
      borderBottom: '4px solid transparent',
    },
    wrapper: {
      position: 'relative',
    },
  },
  { name: 'ProjectNavLink' }
)

interface PropTypes {
  name: string
  label: string
  redirect: string
}

const navActive = { color: COLOR_PALETTE.black, borderBottomColor: '#FFD800' }

const ProjectNavLink: React.FC<PropTypes> = ({ name, label, redirect }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const projectId = useMemo(() => project.id, [project.id])
  const handleClick = useCallback((e) => {
    form.mutators.recordFormDirtyFields()
  }, [])
  return (
    <div onClick={handleClick} className={classes.wrapper}>
      <NavLink activeStyle={navActive} className={classes.link} key={name} to={`/projects/${projectId}/${redirect}`}>
        {translate(label)}
      </NavLink>
      <AlertsPopover
        section={name}
        wrapperStyle={{
          position: 'absolute',
          top: -15,
          right: -10,
        }}
      />
    </div>
  )
}
export default ProjectNavLink
