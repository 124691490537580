import { AccessRightsType } from 'types/global'
import { PermissionsState } from 'types/permissions'
import { PermissionKey } from 'types/roles'
import { RootState } from 'types/state'
import { authSelectors, SET_ORG_ID } from './auth'

export const PROJECT_PERMISSIONS_UPDATE = 'PROJECT_PERMISSIONS_UPDATE'
export const PROJECT_PERMISSIONS_CLEAR = 'PROJECT_PERMISSIONS_CLEAR'
export const INHERIT_PROJECT_PERMISSIONS = 'INHERIT_PROJECT_PERMISSIONS'

const defaultState: PermissionsState = {
  permissionsCache: {},
  projectPermissions: undefined,
  projectPermissionsCache: undefined,
}

export function permissionsReducer(
  previousState: PermissionsState | undefined = defaultState,
  { type, payload }: { type: string; payload: any }
) {
  switch (type) {
    case SET_ORG_ID:
      // Clear all permissions caches when org switches
      previousState.projectPermissions = undefined
      previousState.projectPermissionsCache = undefined
      previousState.permissionsCache = {}
      break

    case PROJECT_PERMISSIONS_UPDATE:
      previousState.projectPermissions = payload?.permissions
      previousState.projectPermissionsCache = {}
      break

    case PROJECT_PERMISSIONS_CLEAR:
      previousState.projectPermissions = undefined
      previousState.projectPermissionsCache = undefined
      break

    case INHERIT_PROJECT_PERMISSIONS:
      previousState.projectPermissionsCache = {}
      break
  }

  return previousState
}

export const permissionsSelectors = {
  getPermissionsSetting: (state: RootState) => {
    const role = authSelectors.getCurrentRole(state)
    return role?.permissions
  },
  getPermissionByKey: (key?: PermissionKey) => {
    return (state: RootState): AccessRightsType => {
      if (!key) {
        return { allowView: true, allowCreate: true, allowEdit: true, allowDelete: true }
      }

      if (!permissionsSelectors.getIsPermissionsLoaded(state)) {
        return { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
      }

      if (state.permissions.permissionsCache[key]) {
        return state.permissions.permissionsCache[key]
      }
      const permissions = permissionsSelectors.getPermissionsSetting(state)
      let ret: AccessRightsType
      if (permissions?.[key]) {
        const setting = permissions[key]
        //convert 0|1 to boolean value, avoid unexpected 0,1 add to react render
        ret = {
          allowView: !!setting.view,
          allowCreate: !!setting.create,
          allowEdit: !!setting.edit,
          allowDelete: !!setting.delete,
        }
      } else {
        ret = { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
      }
      state.permissions.permissionsCache[key] = ret

      return ret
    }
  },

  getProjectPermissionsSetting: (state: RootState) => {
    if (!permissionsSelectors.getProjectPermissionsLoaded(state)) {
      throw new Error('Project permissions not loaded, consider using `getPermissionByKey`')
    }
    // This fallback behaviour is for the 'inherited' case, which happens for non-shared projects
    if (state.permissions.projectPermissions) {
      return state.permissions.projectPermissions
    } else {
      return permissionsSelectors.getPermissionsSetting(state)
    }
  },
  getIsPermissionsLoaded: (state: RootState): boolean => {
    const role = authSelectors.getCurrentRole(state)
    return !!role
  },
  getProjectPermissionsLoaded: (state: RootState): boolean => {
    return !!state.permissions.projectPermissionsCache
  },
  getProjectPermissionByKey: (key?: PermissionKey, skipError?: boolean) => {
    return (state: RootState): AccessRightsType => {
      if (!permissionsSelectors.getProjectPermissionsLoaded(state)) {
        if (skipError)
          return {
            allowView: false,
            allowCreate: false,
            allowEdit: false,
            allowDelete: false,
          }
        throw new Error('Project permissions not loaded, consider using `getPermissionByKey`')
      }

      if (!key) {
        return { allowView: true, allowCreate: true, allowEdit: true, allowDelete: true }
      }

      if (!permissionsSelectors.getIsPermissionsLoaded(state)) {
        return { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
      }

      if (state.permissions.projectPermissionsCache?.[key]) {
        return state.permissions.projectPermissionsCache[key]
      }

      const permissions = permissionsSelectors.getProjectPermissionsSetting(state)
      let ret: AccessRightsType
      if (permissions?.[key]) {
        const setting = permissions[key]
        ret = {
          allowView: !!setting.view,
          allowCreate: !!setting.create,
          allowEdit: !!setting.edit,
          allowDelete: !!setting.delete,
        }
      } else {
        ret = { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
      }
      if (state.permissions.projectPermissionsCache) state.permissions.projectPermissionsCache[key] = ret

      return ret
    }
  },
  hasAssessRight: (state: RootState) => {
    const accessRightsSetting = permissionsSelectors.getPermissionsSetting(state)
    return (key: PermissionKey | PermissionKey[] | undefined) => {
      if (!key) return true
      else if (Array.isArray(key))
        return key.every((k) => accessRightsSetting && accessRightsSetting[k] && accessRightsSetting[k]['view'])
      else if (typeof key === 'string') {
        if (accessRightsSetting && accessRightsSetting[key] && accessRightsSetting[key]['view']) return true
      } else return false
    }
  },
}
