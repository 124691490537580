import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-final-form'
import PaymentMethodPanel from './PaymentMethodPanel'
import {
  ACHPaymentDataType,
  BlueSnapCardDataType,
  PaymentRequestType,
  PaymentStaticCopy,
  SelectedPaymentMethodType,
} from './types'
import { getIsCardType } from './utils'

type PropTypes = {
  paymentRequestData: PaymentRequestType
  projectId: string
  doSubmitPayment: (cardData?: BlueSnapCardDataType | ACHPaymentDataType) => void
  countryIso2: string
  orgName: string
  paymentStaticCopy: PaymentStaticCopy
}

const PaymentMethodSelection: React.FC<PropTypes> = (props) => {
  const form = useForm()

  const sortedPaymentMethods = useMemo(() => {
    let cardsFiltered: SelectedPaymentMethodType[] = []
    props.paymentRequestData?.payment_methods?.forEach((pmtMethod) => {
      const isCard = getIsCardType(pmtMethod)
      if (isCard && !cardsFiltered?.includes('credit_card')) {
        cardsFiltered.push('credit_card')
      } else if (pmtMethod === 'local_bank_transfer') {
        cardsFiltered.push(pmtMethod)
      } else if (pmtMethod === 'ach') {
        cardsFiltered.push('ach')
      }
    })
    return cardsFiltered
  }, [props.paymentRequestData.payment_methods])

  // if only one type of payment method is accepted then select that option that on render
  useEffect(() => {
    if (sortedPaymentMethods?.length === 1) {
      form.change('payment_method_type', sortedPaymentMethods[0])
    }
  }, [sortedPaymentMethods])

  return (
    <div>
      {sortedPaymentMethods?.map((pmtMethod) => (
        <PaymentMethodPanel {...props} pmtMethod={pmtMethod} key={pmtMethod} countryIso2={props.countryIso2} />
      ))}
    </div>
  )
}

export default PaymentMethodSelection
