import classNames from 'classnames'
import { orgSelectors } from 'ducks/orgs'
import { uniqBy } from 'lodash'
import { Box } from 'opensolar-ui'
import { getSupplierConfigByFilterKey, getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { DistributorDataTypeV2 } from '../types'
import EmptyColumnItem from './EmptyColumnItem'

const useStyle = makeOpenSolarStyles({
  badge: {
    alignItems: 'center',
    background: '#ECECED',
    borderRadius: '4px',
    color: '#2D2B32',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    gap: '10px',
    justifyContent: 'center',
    lineHeight: '12px',
    marginRight: '12px',
    padding: '4px 8px',
  },
  distributorLogo: {
    background: '#FFF',
    border: '1px solid #C9C9CC',
    borderRadius: '4px',
    height: '24px',
    minWidth: '0px',
    objectFit: 'contain',
    padding: '2px 14px',
    '&:not(img)': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  moreDistributors: {
    position: 'relative',
    '&:hover > span': {
      display: 'flex',
    },
  },
  distributorsPopover: {
    display: 'none',
    position: 'absolute',
    background: 'white',
    padding: '8px 16px',
    zIndex: 1,
    top: '100%',
    left: '-50%',
    borderRadius: '4px',
    boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.25)',
  },
})

interface SupplierIconProps {
  distributor: HardwareSupplierFilterKeyType
}

const SupplierIcon: React.FC<SupplierIconProps> = ({ distributor }) => {
  const classes = useStyle()

  const supplierConfig = getSupplierConfigByFilterKey(distributor)

  return <img alt={supplierConfig.name} className={classes.distributorLogo} src={supplierConfig.logoUrl} />
}

interface DistributorBlockProps {
  distributors: DistributorDataTypeV2[]
}

const DistributorBlock: React.FC<DistributorBlockProps> = ({ distributors }) => {
  const classes = useStyle()
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const filteredDistributors = uniqBy(
    distributors.filter((item: DistributorDataTypeV2, index, self) => {
      const supplierEnum = getSupplierEnumByFilterKey(item.distributor)
      return enabledDistributors.includes(supplierEnum) && self.indexOf(item) === index
    }),
    'distributor'
  )

  if (filteredDistributors.length === 0) return <EmptyColumnItem />
  else if (filteredDistributors.length === 1) {
    return (
      <Box display="flex" flexDirection="row">
        <SupplierIcon distributor={filteredDistributors[0].distributor} />
      </Box>
    )
  }

  const [firstDistributor, ...restDistributors] = filteredDistributors

  return (
    <Box alignItems="center" display="flex" flexDirection="row" flexWrap="wrap" gridGap={8}>
      <SupplierIcon distributor={firstDistributor.distributor} />
      <span className={classNames([classes.distributorLogo, classes.moreDistributors])}>
        {`+${filteredDistributors.length - 1}`}
        <span className={classNames([classes.distributorsPopover, 'distributor-popover'])}>
          {restDistributors.map(({ distributor }) => (
            <SupplierIcon distributor={distributor} key={distributor} />
          ))}
        </span>
      </span>
    </Box>
  )
}

export default DistributorBlock
