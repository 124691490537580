import React from 'react'
import { useTranslate } from 'react-admin'
import { formatDecimalPlaces, numberWithCommas } from 'util/misc'
import { SystemLossesType } from './types'

const lossTableItems = [
    ['header', 'irradiance', 'Irradiance'],
    ['baseline', 'annual_poa_nom', 'Total POA Irradiance', '#ffaf8c'],
    ['loss_percentage', 'annual_poa_shading_loss_percent_fixed', 'Shading', '#ffaf8c'],
    ['loss_percentage', 'annual_poa_soiling_loss_percent', 'Soiling', '#ffaf8c'],
    ['loss_percentage', 'annual_poa_cover_loss_percent', 'Reflection (IAM)', '#ffaf8c'],
    ['gain_percentage', 'annual_poa_rear_gain_percent', 'Bifacial', '#ffaf8c', '#ff8450'],
    ['net_total', 'net_total_irradiance', '', '#ff8450'],
  
    ['module_efficiency', 'module_efficiency', '', ''],
  
    ['baseline', 'annual_dc_nominal', 'Total DC Energy as STC', '#ffed8c'],
    ['loss_percentage', 'annual_dc_module_loss_percent_fixed', 'Module Deviation from STC', '#ffed8c'],
    ['loss_percentage', 'annual_dc_snow_loss_percent', 'Snow', '#ffed8c'],
    ['loss_percentage', 'annual_dc_nameplate_loss_percent', 'Nameplate', '#ffed8c'],
    ['loss_percentage', 'first_year_degradation_loss_percent', 'First year degradation', '#ffed8c'],
    ['loss_percentage', 'annual_dc_mismatch_loss_percent', 'Module mismatch', '#ffed8c'],
    ['loss_percentage', 'annual_dc_mppt_clip_loss_percent', 'Inverter MPPT clipping', '#ffed8c'],
    ['loss_percentage', 'annual_dc_optimizer_loss_percent', 'DC power optimizer', '#ffed8c'],
    ['loss_percentage', 'annual_dc_wiring_loss_percent', 'DC wiring', '#ffed8c'],
    ['loss_percentage', 'annual_dc_diodes_loss_percent', 'Diodes & connections', '#ffed8c'],
    ['loss_percentage', 'annual_dc_tracking_loss_percent', 'Tracking error', '#ffed8c'],
    ['loss_percentage', 'annual_dc_perf_adj_loss_percent', 'DC availability and curtailment', '#ffed8c'],
    ['net_total', 'annual_dc_net', 'Net DC energy (kWh)', '#ffda14'],
  
    ['header', 'ac_inverter', 'AC - Inverter'],
    ['loss_percentage', 'annual_ac_inv_clip_loss_percent', 'Power clipping', '#e7ff8c'],
    ['loss_percentage', 'annual_ac_inv_pso_loss_percent', 'Power consumption', '#e7ff8c'],
    ['loss_percentage', 'annual_ac_inv_pnt_loss_percent', 'Nighttime consumption', '#e7ff8c'],
    ['loss_percentage', 'annual_ac_inv_eff_loss_percent', 'Inverter efficiency', '#e7ff8c'],
    ['net_total', 'annual_ac_gross', 'Gross AC energy (kWh)', '#c6f01a'],
  
    ['header', 'ac_other', 'AC - Other'],
    ['loss_percentage', 'annual_ac_wiring_loss_percent', 'AC wiring', '#a6efff'],
    ['loss_percentage', 'annual_xfmr_loss_percent', 'Transformer loss', '#a6efff'],
    ['loss_percentage', 'annual_ac_perf_adj_loss_percent', 'Availability', '#a6efff'],
    // ['baseline', 'annual_ac_net', 'Net AC energy (kWh)', '#ffaf8c'],
    ['net_total', 'annual_energy', 'Annual energy (kWh)', '#48deff'],
  ]

type PropTypes = {
    systemLosses: SystemLossesType
}

const LossTable: React.FC<PropTypes> = (props) => {
    let totalPercentage = 100, net_total_irradiance = 0
    const translate = useTranslate()
    return (
        <table style={{ width: '100%', backgroundColor: '#fff', marginTop: 5, borderSpacing: 0 }}>
        {lossTableItems.map((item) => {
            if (item[0] === 'header') {
            return (
                <tr key={item[1]}>
                <td colSpan={2}>
                    <h3 style={{ margin: 0 }}>{translate(item[2])}</h3>
                </td>
                </tr>
            )
            } else if (item[0] === 'module_efficiency') {
            return (
                <tr key={item[1]}>
                <td>
                    <div style={{ margin: 0, width: `${totalPercentage}%` }}>
                    <div
                        style={{
                        textAlign: 'center',
                        fontSize: 10,
                        fontWeight: 'bold',
                        borderRight: '2px solid #606060',
                        paddingTop: 10,
                        paddingBottom: 3,
                        }}
                    >
                        {translate('Module Efficiency')}:{' '}
                        {net_total_irradiance
                        ? `${formatDecimalPlaces((props.systemLosses['annual_dc_nominal'] / net_total_irradiance) * 100, 1)}%`
                        : 0}
                    </div>
                    <div
                        style={{
                        width: 0,
                        height: 0,
                        borderLeft: '5px solid transparent',
                        borderRight: '5px solid transparent',
                        borderTop: '12px solid #606060',
                        float: 'right',
                        marginRight: -4,
                        }}
                    ></div>
                    <h3 style={{ margin: 0 }}>{translate('DC')}</h3>
                    </div>
                </td>
                <td></td>
                </tr>
            )
            } else if (item[0] === 'baseline') {
            return (
                <tr key={item[1]}>
                <td>
                    <div style={{ margin: 0, width: `${totalPercentage}%`, backgroundColor: item[3], overflow: 'auto' }}>
                    <div style={{ padding: 5, fontSize: 10 }}>
                        <div style={{ display: 'inline-block' }}>{translate(item[2])} </div>
                        <div style={{ display: 'inline-block', float: 'right' }}>
                        {numberWithCommas(props.systemLosses[item[1]], true)} kWh
                        </div>
                    </div>
                    </div>
                </td>
                <td></td>
                </tr>
            )
            } else if (item[0] === 'net_total') {
            let net_total
            if (item[1] === 'net_total_irradiance') {
                net_total = props.systemLosses['annual_poa_nom']
                net_total -=
                (net_total * parseFloat(formatDecimalPlaces(props.systemLosses['annual_poa_shading_loss_percent_fixed'], 1))) /
                100
                net_total -=
                (net_total * parseFloat(formatDecimalPlaces(props.systemLosses['annual_poa_soiling_loss_percent'], 1))) / 100
                net_total -=
                (net_total * parseFloat(formatDecimalPlaces(props.systemLosses['annual_poa_cover_loss_percent'], 1))) / 100
                net_total +=
                (net_total * parseFloat(formatDecimalPlaces(props.systemLosses['annual_poa_rear_gain_percent'], 1))) / 100
                net_total_irradiance = net_total
                net_total = numberWithCommas(net_total, true)
            } else {
                net_total = numberWithCommas(props.systemLosses[item[1]], true)
            }
            return (
                <tr key={item[1]}>
                <td>
                    <div style={{ margin: 0, width: `${totalPercentage}%`, backgroundColor: item[3] }}>
                    <div style={{ padding: 5, fontSize: 10, textAlign: 'right', fontWeight: 'bold' }}>
                        {net_total} kWh
                    </div>
                    </div>
                </td>
                <td></td>
                </tr>
            )
            } else if (item[0] === 'loss_percentage') {
            let widthPercentage = totalPercentage
            totalPercentage -= parseFloat(formatDecimalPlaces(props.systemLosses[item[1]], 1))
            return (
                <tr key={item[1]}>
                <td>
                    <div style={{ margin: 0, width: `${widthPercentage}%`, backgroundColor: '#c4c4c4' }}>
                    <div
                        style={{
                        width: `${100 - parseFloat(formatDecimalPlaces(props.systemLosses[item[1]], 1))}%`,
                        backgroundColor: item[3],
                        }}
                    >
                        <div style={{ padding: 5, display: 'inline-block', fontSize: 10 }}>{translate(item[2])}</div>
                    </div>
                    </div>
                </td>
                <td style={{ textAlign: 'right', fontSize: 10 }}>-{formatDecimalPlaces(props.systemLosses[item[1]], 1)}%</td>
                </tr>
            )
            } else if (item[0] === 'gain_percentage') {
            totalPercentage += parseFloat(formatDecimalPlaces(props.systemLosses[item[1]], 1))
            return (
                <tr key={item[1]}>
                <td>
                    <div style={{ margin: 0, width: `${totalPercentage}%`, backgroundColor: item[4] }}>
                    <div
                        style={{
                        width: `${100 - parseFloat(formatDecimalPlaces(props.systemLosses[item[1]], 1))}%`,
                        backgroundColor: item[3],
                        }}
                    >
                        <div style={{ padding: 5, display: 'inline-block', fontSize: 10 }}>{translate(item[2])}</div>
                    </div>
                    </div>
                </td>
                <td style={{ textAlign: 'right', fontSize: 10 }}>+{formatDecimalPlaces(props.systemLosses[item[1]], 1)}%</td>
                </tr>
            )
            } else {
            return null
            }
        })}
        </table>
    )
}
export default LossTable