import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import SharedEntitySelectInput from 'elements/field/SharedEntitySelectInput'
import DateInput from 'elements/input/DateInput'
import { ProjectToggleField } from 'projectSections/sections/common/ProjectToggleField'
import { ChangeEvent, useMemo } from 'react'
import { ReferenceInput, SelectInput, useTranslate } from 'react-admin'
import { useField, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { RootState } from 'types/state'
import { useDateFormatter } from 'util/date'
import { useFeatureFlag } from 'util/split'
import { dateParser, getCurrentDate, systemNameOrDefaultWithSize, urlToId } from '../../../../util/misc'
import AccordionCard from '../AccordionCard'
import { convertUTCDate } from '../installation'
import SolarOutletCta from '../solarOutlet'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  column: { display: 'flex', flexDirection: 'column', width: '100%' },
  dateInput: { width: '100%' },
}))

const SaleContent = () => {
  const enableSolarOutlet = useSelector(orgSelectors.getEnableOutlet)
  const enableNewHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')

  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formatter = useDateFormatter()
  const project = useFormState().values as ProjectType
  const contractDate = useMemo(() => {
    if (project.contract_date) {
      return formatter(convertUTCDate(project.contract_date)).split(' ')[0]
    }
  }, [project.contract_date])
  const systemSold: string | undefined = useField('system_sold', { subscription: { value: true } }).input.value
  const systemsReady: boolean = !useField('simulate_first_year_only', {
    subscription: { value: true },
  }).input.value
  const orgId = useSelector(authSelectors.getOrgId)
  const availablePaymentOptions = useSelector((state: RootState) => {
    //@ts-ignore
    return state?.admin?.resources?.payment_options?.data
  })
  const logMarkedPmtAsSold = (e: ChangeEvent<HTMLInputElement>) => {
    soldAutoToggle(e)
    const selectedURL = e?.target?.value
    let selectedName = 'none'
    if (selectedURL && availablePaymentOptions) {
      const selectedId = urlToId(selectedURL)
      if (selectedId && availablePaymentOptions[selectedId]) selectedName = availablePaymentOptions[selectedId]?.title
    }
    logAmplitudeEvent('payment_option_manually_marked_sold', {
      project_id: project?.id,
      payment_option_title: selectedName,
    })
  }
  const soldAutoToggle = (e, dateVal = false) => {
    const value = dateVal ? e : e?.target?.value
    if (project.project_sold === 1 && value) {
      form.mutators.updateField('project_sold', 2)
      if (!project.contract_date && !dateVal) form.mutators.updateField('contract_date', getCurrentDate())
    }
  }
  const toggleLabel = useMemo(() => {
    if (project.contract_date && project.project_sold !== null) {
      if (project.project_sold === 1) {
        return translate('This project was marked sold on %{contract_date} through project workflow automation.', {
          contract_date: contractDate,
        })
      } else {
        return translate('This project was marked sold on %{contract_date}.', {
          contract_date: contractDate,
        })
      }
    } else if (project.project_sold === 1) {
      return translate('This project was marked sold through project workflow automation.')
    }
  }, [project.contract_date, project.project_sold])
  return (
    <div className={classes.column}>
      <ProjectToggleField
        label={translate('Project Sold')}
        subLabel={toggleLabel}
        checked={!!project.project_sold && project.project_sold > 0}
        source={'project_sold'}
        onChange={(value) => {
          if (value === true) {
            form.mutators.updateField('project_sold', 2)
            if (!project.contract_date) form.mutators.updateField('contract_date', getCurrentDate())
          } else {
            form.mutators.updateField('project_sold', null)
            form.change('contract_date', null)
            form.change('system_sold', null)
            form.change('payment_option_sold', null)
          }
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {/* @ts-ignore */}
          <DateInput
            //@TODO: Refactor into standard field translation and remove label attribute
            label={'Contract Date'}
            resource={'projects'}
            source={'contract_date'}
            parse={dateParser}
            className={classes.dateInput}
            onChange={(value) => {
              soldAutoToggle(value, true)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {
            // We do not simply disable ReferenceInput when !project.id
            // beacuse it still attempts to load data which is invalid
            Boolean(project && project.id && !isNaN(project.id) && systemsReady) ? (
              <ReferenceInput
                //@TODO: Refactor into standard field translation and remove label attribute
                label={'Sold System'}
                resource={'projects'}
                reference="systems"
                source="system_sold"
                filter={{
                  project: project ? project.id : null,
                  visible_to: orgId,
                }}
                style={{ width: '100%' }}
                sort={{ field: 'order', order: 'ASC' }}
                allowEmpty
                onChange={soldAutoToggle}
              >
                <SelectInput
                  optionValue="url"
                  optionText={(choice: any) => systemNameOrDefaultWithSize(choice, undefined)}
                  resettable={true}
                  source={'system_sold'}
                />
              </ReferenceInput>
            ) : (
              <div>
                <SelectInput
                  label={
                    <span>
                      {translate('resources.projects.fields.system_sold')}
                      {!systemsReady && (
                        <CircularProgress style={{ marginLeft: 10, verticalAlign: 'middle', height: 18, width: 18 }} />
                      )}
                    </span>
                  }
                  choices={[]}
                  value={null}
                  disabled={true}
                  resettable={true}
                  source={'system_sold'}
                />
              </div>
            )
          }
        </Grid>
        <Grid item xs={6}>
          {Boolean(project && project.id && !isNaN(project.id) && systemsReady) ? (
            <ReferenceInput
              //@TODO: Refactor into standard field translation and remove label attribute
              label={'Sold Payment Option'}
              resource={'projects'}
              reference="payment_options"
              source="payment_option_sold"
              filter={{
                project: project ? project.id : null,
                visible_to: orgId,
              }}
              style={{ width: '100%' }}
              sort={{ field: 'order', order: 'ASC' }}
              allowEmpty
              onChange={logMarkedPmtAsSold}
            >
              <SharedEntitySelectInput optionValue="url" optionText={'title'} resettable={true} />
            </ReferenceInput>
          ) : (
            <SelectInput
              resource={'projects'}
              label={
                <span>
                  {translate('resources.projects.fields.payment_option_sold')}
                  {!systemsReady && (
                    <CircularProgress style={{ marginLeft: 10, verticalAlign: 'middle', height: 18, width: 18 }} />
                  )}
                </span>
              }
              choices={[]}
              value={null}
              disabled={true}
              resettable={true}
              source={'payment_option_sold'}
            />
          )}
        </Grid>
        {enableSolarOutlet && !enableNewHardwareOrdering && (
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div style={{ paddingBottom: '16px', width: '100%' }}>
              <SolarOutletCta disabled={!systemSold} />
              {!systemSold && (
                <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
                  <i>{translate('Available once a sold system is saved')}</i>
                </div>
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const SaleAccordion = () => {
  const translate = useTranslate()
  const { allowView, allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_sale'))
  if (!allowView) {
    return null
  }

  return (
    <AccordionCard
      disabled={!allowEdit}
      name={'sale'}
      title={translate('Sale')}
      titleIcon={LocalOfferOutlinedIcon}
      content={SaleContent}
      lazyRender={true}
    />
  )
}
export default SaleAccordion
