import { Datagrid, FunctionField } from 'react-admin'
import { previewInfo } from '../../common/utils'
import { GridItemDropdownMenu } from './GridItemDropdownMenu'

import { styled } from 'opensolar-ui'

const StyledDatagrid = styled(Datagrid)(({ theme }) => ({
  '& thead': {
    display: 'none',
  },
  '& .MuiPaper-elevation1': {
    border: 'none',
  },
  '& .MuiTableBody-root': {
    display: 'grid',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 16,
    gap: 16,
    gridTemplateColumns: '1fr 1fr 1fr 1fr ',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr ',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  '& .MuiTableCell-root': {
    border: 'none',
  },
  '& tr': {
    display: 'flex',
    overflow: 'hidden',
  },
  '& td': {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    padding: '8px !important',
    borderRadius: 4,
  },
  '& .MuiTableCell-paddingCheckbox': {
    position: 'absolute',
  },
}))

const CardTop = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: 4,
}))

const PreviewArea = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  height: 120,
  backgroundColor: 'white',
  '& object': {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  '& img': {
    margin: 'auto',
    height: 50,
  },
}))

const CardBottom = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
  height: 20,
  paddingTop: 8,
  '& > button': {
    display: 'flex',
    width: 5,
    height: 20,
    padding: 0,
  },
}))

const FileTitle = styled('p')(({ theme }) => ({
  height: 20,
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

export const FilesGridView = (props) => {
  return (
    <StyledDatagrid {...props}>
      <FunctionField
        source="title"
        render={(record) => {
          const { hasPreview, fileType } = previewInfo(record)
          return (
            <>
              <div>
                <CardTop>
                  <PreviewArea>
                    {hasPreview ? (
                      <object data={record?.file_contents} />
                    ) : (
                      <>
                        <img src={`${window.PUBLIC_URL}/images/doc_icons/${fileType}.svg`} alt={`${fileType} icon`} />
                      </>
                    )}
                  </PreviewArea>
                </CardTop>
              </div>
              <CardBottom>
                <FileTitle>{record?.title}</FileTitle>
                <GridItemDropdownMenu record={record} />
              </CardBottom>
            </>
          )
        }}
      />
    </StyledDatagrid>
  )
}
