const getIconPathByMapType = (type: string) => {
  let fileName = ''
  switch (type) {
    case 'Google':
    case 'Google3D':
    case 'GoogleTop':
    case 'GoogleRoadMap':
      fileName = 'Google.png'
      break
    case 'Nearmap':
    case 'Nearmap3D':
    case 'NearmapSource':
      fileName = 'Nearmap.png'
      break
    case 'GetMapping':
    case 'GetMapping3D':
    case 'GetMapping2D':
      fileName = 'GetMapping.png'
      break
    case 'Bing':
      fileName = 'BingIcon.svg'
      break
    case 'MetroMap':
      fileName = 'MetroMap.svg'
      break
    default:
      fileName = 'DefaultMap.png'
      break
  }
  return `${window.PUBLIC_URL}/images/${fileName}`
}

export default getIconPathByMapType
