import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { useFeatureFlag } from 'util/split'

type PropTypes = {
  children: React.ReactNode
  systemLength: number
}

const useStyles = makeOpenSolarStyles<{ systemLength: number; enablePanelTabsV2: boolean }>((theme) => {
  return {
    root: ({ systemLength, enablePanelTabsV2 }) => ({
      width: '100%',
      boxShadow: enablePanelTabsV2 ? 'inset rgba(0, 0, 0, 0.12) 0px 4px 2px -2px' : 'none',
      display: 'flex',
      //100vh - (100px header) - (40px add system button) - (40px margin) - (65px accordion summary * system quantity)
      maxHeight: `calc(100vh - 180px - ${65 * systemLength}px)`,
      [theme.breakpoints.down('md')]: {
        //100vh - (100px header) - (40px margin) - (65px accordion summary * 1 system) - 100px bottom toolbar
        maxHeight: `calc(100vh - 180px - ${65 * systemLength}px - 100px)`,
      },
      [theme.breakpoints.down('xs')]: {
        //100vh - (100px header) - (40px margin) - (65px accordion summary * 1 system) - 100px bottom toolbar
        maxHeight: 'calc(100vh - 100px - 40px - 65px - 100px)',
      },
    }),
  }
})

const ResponsiveAccordionContentWrapper = ({ children, systemLength }: PropTypes) => {
  const enablePanelTabsV2 = useFeatureFlag('new_design_tabs', 'on')
  const classes = useStyles({ systemLength, enablePanelTabsV2: !!enablePanelTabsV2 })
  return (
    <div id="responsive-accordion-content-wrapper" className={classes.root}>
      {children}
    </div>
  )
}

export default ResponsiveAccordionContentWrapper
