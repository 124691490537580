var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinearProgress } from '@material-ui/core';
import { createMuiTheme, createStyles, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component, createElement, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import defaultTheme from '../defaultTheme';
import DefaultAppBar from './AppBar';
import DefaultError from './Error';
import { LayoutFooter } from './LayoutFooter';
import DefaultNotification from './Notification';
import DefaultSidebar from './Sidebar';
var styles = function (theme) {
    var _a;
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            //CSS3 100vh not constant in mobile browser
            //https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
            minHeight: ((_a = window.Utils) === null || _a === void 0 ? void 0 : _a.iOS()) ? '-webkit-fill-available' : '100vh',
            backgroundColor: theme.palette.background.default,
            // position: 'relative',
            // minWidth: 'fit-content',
            width: '100%',
        },
        appFrame: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            // [theme.breakpoints.up('xs')]: {
            //   marginTop: theme.spacing(6),
            // },
            // [theme.breakpoints.down('xs')]: {
            //   marginTop: theme.spacing(7),
            // },
        },
        mainContent: {
            display: 'flex',
            flexGrow: 1,
            overflow: 'hidden',
            position: 'relative', // Allows child pages to fill the content area exactly
        },
        appHeader: {
            position: 'sticky',
            top: 0,
            zIndex: 100,
            width: '100%',
        },
        content: {
            display: 'flex',
            // width: '100%',
            overflowY: 'auto',
            marginTop: 0,
            flexDirection: 'column',
            flexGrow: 1,
            flexBasis: 0,
            // padding: theme.spacing(3),
            // paddingTop: theme.spacing(1),
            // paddingLeft: 0,
            // [theme.breakpoints.up('xs')]: {
            //   paddingLeft: 5,
            // },
            // [theme.breakpoints.down('xs')]: {
            //   padding: 0,
            // },
        },
    });
};
var sanitizeRestProps = function (_a) {
    var staticContext = _a.staticContext, history = _a.history, location = _a.location, match = _a.match, props = __rest(_a, ["staticContext", "history", "location", "match"]);
    return props;
};
var Layout = /** @class */ (function (_super) {
    __extends(Layout, _super);
    function Layout(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false, errorMessage: null, errorInfo: null };
        /**
         * Reset the error state upon navigation
         *
         * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
         */
        props.history.listen(function () {
            if (_this.state.hasError) {
                _this.setState({ hasError: false });
            }
        });
        return _this;
    }
    Layout.prototype.componentDidCatch = function (errorMessage, errorInfo) {
        this.setState({ hasError: true, errorMessage: errorMessage, errorInfo: errorInfo });
    };
    Layout.prototype.render = function () {
        var _a = this.props, appBar = _a.appBar, children = _a.children, classes = _a.classes, _b = _a.className, className = _b === void 0 ? {} : _b, customRoutes = _a.customRoutes, error = _a.error, dashboard = _a.dashboard, logout = _a.logout, menu = _a.menu, notification = _a.notification, open = _a.open, sidebar = _a.sidebar, bottomNavigation = _a.bottomNavigation, title = _a.title, header = _a.header, isLoading = _a.isLoading, props = __rest(_a, ["appBar", "children", "classes", "className", "customRoutes", "error", "dashboard", "logout", "menu", "notification", "open", "sidebar", "bottomNavigation", "title", "header", "isLoading"]);
        var _c = this.state, hasError = _c.hasError, errorMessage = _c.errorMessage, errorInfo = _c.errorInfo;
        var hasSideBar = !!menu;
        return (_jsx("div", __assign({ className: classnames('layout', classes.root, className.root) }, sanitizeRestProps(props), { children: _jsxs("div", __assign({ className: classes.appFrame }, { children: [_jsxs("div", __assign({ id: "app-header", className: classes.appHeader }, { children: [!!header && createElement(appBar, { title: title, open: open, logout: logout, header: header, hasSideBar: hasSideBar, id: 'main-header' }), _jsx("div", { id: "secondary-appbar" })] })), _jsxs("main", __assign({ className: classes.mainContent }, { children: [hasSideBar &&
                                createElement(sidebar, {
                                    children: createElement(menu, {
                                        logout: logout,
                                        hasDashboard: !!dashboard,
                                    }),
                                }), _jsx("div", __assign({ className: classnames('content', classes.content, className.content) }, { children: hasError
                                    ? createElement(error, {
                                        error: errorMessage,
                                        errorInfo: errorInfo,
                                        title: title,
                                    })
                                    : children }))] })), createElement(notification), isLoading && (_jsx(LinearProgress, { color: 'primary', style: {
                            zIndex: 2000,
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '4px',
                        } })), _jsx(LayoutFooter, { bottomNavigation: bottomNavigation })] })) })));
    };
    return Layout;
}(Component));
Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.object,
    customRoutes: PropTypes.array,
    history: PropTypes.object.isRequired,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.node.isRequired,
};
Layout.defaultProps = {
    appBar: DefaultAppBar,
    error: DefaultError,
    notification: DefaultNotification,
    sidebar: DefaultSidebar,
};
var mapStateToProps = function (state) { return ({
    open: state.admin.ui.sidebarOpen,
    isLoading: state.admin.loading > 0,
}); };
var EnhancedLayout = compose(connect(mapStateToProps, {} // Avoid connect passing dispatch in props
), withRouter, withStyles(styles, { name: 'RaLayout' }))(Layout);
var LayoutWithTheme = function (_a) {
    var themeOverride = _a.theme, props = __rest(_a, ["theme"]);
    var themeProp = useRef(themeOverride);
    var _b = useState(createMuiTheme(themeOverride)), theme = _b[0], setTheme = _b[1];
    useEffect(function () {
        if (themeProp.current !== themeOverride) {
            themeProp.current = themeOverride;
            setTheme(createMuiTheme(themeOverride));
        }
    }, [themeOverride, themeProp, theme, setTheme]);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(EnhancedLayout, __assign({}, props)) })));
};
LayoutWithTheme.propTypes = {
    theme: PropTypes.object,
};
LayoutWithTheme.defaultProps = {
    theme: defaultTheme,
};
export default EnhancedLayout;
