import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'
import type { ComponentTypesV2 } from 'types/selectComponent'
import { mapComponentTypesV2ToV1 } from 'util/misc'
import BasicFilter from '../basic/BasicFilter'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType,
} from '../typeV2'
import { parseStringifiedListFilterValue } from '../utils/utils'
import useOtherComponentTypeFilterSideEffectV2 from './useOtherComponentTypeFilterSideEffectV2'

export const GENERAL_OTHER_COMPONENT_TYPE = 'general'
export const OTHER_COMPONENT_TYPE_DESIGN_PAGE_NODE_KEY = 'other_component_type'
export const OTHER_COMPONENT_FILTER_KEY_V2 = 'types'
export const SELECT_ALL_OPTION: OptionGenericType<string> = { id: 'default', title: 'All', value: 'all' }
export const getCheckBoxSelectAllMutator = <OptionValue, RendererValue>(ALL_OPTION: OptionGenericType<OptionValue>) => {
  const checkBoxSelectAllMutator = ({
    value,
    selectedOption,
    options,
  }: {
    value: OptionValue
    selectedOption: OptionGenericType<OptionValue>
    options: OptionGenericType<OptionValue>[]
  }) => {
    if (!(value instanceof Set)) {
      return value
    }
    if (selectedOption.id === ALL_OPTION.id || value.size === 0) {
      return new Set([ALL_OPTION.value]) as RendererValue
    } else if (selectedOption.id !== ALL_OPTION.id) {
      // uncheck all if other options are select
      value.delete('all')
      return value
    }

    return value
  }
  return checkBoxSelectAllMutator
}

export default getCheckBoxSelectAllMutator

export const FILTER_OPTIONS: OptionGenericType<string>[] = COMPONENT_TYPE_OPTIONS.filter(
  (option) => option.id !== GENERAL_OTHER_COMPONENT_TYPE
).reduce(
  (result, option) => {
    result.push({
      value: option.id,
      title: option.name,
      id: option.id,
    })
    return result
  },
  [SELECT_ALL_OPTION]
)

const getDefaultValue = (args?: {
  specsData: { [key: string]: string | number }
  componentType?: ComponentTypesV2
}) => {
  if (!args) {
    return
  }
  if (args.specsData.other_component_type == null) {
    return
  }
  const selectedValue = FILTER_OPTIONS.find((option) => option.value === args.specsData.other_component_type)?.value
  if (!!selectedValue) {
    return { [OTHER_COMPONENT_FILTER_KEY_V2]: selectedValue }
  }
}

export const createHardwareSelectorOtherComponentTypeFilterNode = <T,>({
  label = 'Component Type',
  ...restConfigs
}: ConfigurableFilterOptionsType<string, T>): FilterComponentNodeV2 => {
  const FilterComponent = (filterProps: FilterGenericPropsType) => {
    const selectorConfigTypes = parseStringifiedListFilterValue<ComponentTypesV2>({
      filterValues: filterProps.persistentFilterValues, // Hardware selector sets what available at creation time
      key: 'types',
    })

    const otherComponentTypeSideEffect = useOtherComponentTypeFilterSideEffectV2<string, T>()

    if (!Array.from(selectorConfigTypes).some((type) => mapComponentTypesV2ToV1(type) === 'other')) {
      return null
    }

    const availableOptions = FILTER_OPTIONS.filter(
      (option) => option.value === SELECT_ALL_OPTION.value || selectorConfigTypes.has(option.value as ComponentTypesV2)
    )

    return (
      <BasicFilter
        label={label}
        defaultSelectedOptionValue={SELECT_ALL_OPTION.value}
        options={availableOptions}
        filterKey={OTHER_COMPONENT_FILTER_KEY_V2}
        sideEffect={otherComponentTypeSideEffect}
        {...restConfigs}
        {...filterProps}
      />
    )
  }

  return {
    key: OTHER_COMPONENT_TYPE_DESIGN_PAGE_NODE_KEY,
    component: FilterComponent,
    getMatchedValueFromSpecsData: getDefaultValue,
  }
}
