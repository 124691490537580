import { CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { transactionSelectors } from 'ducks/transaction'
import GenericButton from 'elements/button/GenericButton'
import LoadingDots from 'layout/widgets/LoadingDots'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProposalDataType } from 'types/proposals'
import { getDocumentHasSecondaryDocusignTag } from 'util/docusign'
import CheckoutContentsHeader from '../shared/CheckoutContentsHeader'
import CheckoutQuotationTable from '../shared/CheckoutQuotationTable'
import TransactionDialogTitle from '../shared/TransactionDialogTitle'
import DocuSignRedirectDialog from './DocuSignRedirectDialog'
import { DS_ERROR_CODE_TO_MSG } from './constants'
import { getEnvelopeId, getEnvelopeURL } from './docusignCallouts'

const useStyles = makeOpenSolarStyles(() => ({
  contentsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  errorWrapper: {
    flex: 7,
  },
  errorText: {
    color: 'red',
  },
  submitWrapper: {
    flex: 1,
  },
  loadingWrapper: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  actionData: ActionDataType
  proposalData: ProposalDataType
}

// making this a standalone function for easier mocking in tests
export const doRedirect = (url: string) => {
  window.location.href = url
}

const DocuSignDialog: React.FC<PropTypes> = (props) => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [docusignEnvelopeId, setDocusignEnvelopeId] = useState<string | undefined>(undefined)
  const [selectedDocusignCustomer, setSelectedDocusignCustomer] = useState<string | undefined>(undefined)
  const [showDocusignUserSelection, setShowDocusignUserSelection] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (docusignEnvelopeId) redirectToDocusign()
  }, [docusignEnvelopeId])

  useEffect(() => {
    // make sure we have at least one valid contact and show error if not
    if (!props.proposalData?.selectedProject?.contacts?.length) {
      setErrorMsg('A contact with a name and email must be included before a contract can be signed')
      setIsDisabled(true)
    } else {
      const populatedContacts = props.proposalData?.selectedProject.contacts.filter(
        (con) => con && !!con.email && !!con.first_name && !!con.family_name
      )
      if (!populatedContacts?.length) {
        setErrorMsg('A contact with a name and email must be included before a contract can be signed')
        setIsDisabled(true)
      }
    }
    // check to see if we should show the multiple signer UI
    if (
      getDocumentHasSecondaryDocusignTag(props.proposalData.selectedPaymentOption.contract_template) &&
      props.proposalData?.selectedProject?.contacts &&
      props.proposalData?.selectedProject?.contacts?.length > 1
    ) {
      const populatedContacts = props.proposalData?.selectedProject.contacts.filter(
        (con) => con && !!con.email && !!con.first_name && !!con.family_name
      )
      if (populatedContacts?.length <= 1) {
        setErrorMsg(
          'This contract allows for multiple signers, but at least one customer on this project is missing a full name or email address'
        )
        setIsDisabled(true)
      } else {
        setShowDocusignUserSelection(true)
      }
    }
  }, [])

  const translate = useTranslate()

  const transactionRequestData: ActionDataType = useSelector(transactionSelectors.getTransactionRequestData)
  const classes = useStyles()

  const handleDocusignError = (errorCode: number) => {
    if (DS_ERROR_CODE_TO_MSG[errorCode]) {
      setErrorMsg(translate(DS_ERROR_CODE_TO_MSG[errorCode]))
    } else {
      setErrorMsg(translate('This contract cannot be accessed at the moment'))
    }
  }

  const getDocusignEnvelopeId = () => {
    if (showDocusignUserSelection && !selectedDocusignCustomer) {
      setErrorMsg(translate('Please select your name below'))
      return
    }
    setIsLoading(true)
    //make sure we don't allow user to get to a stale envelope
    setDocusignEnvelopeId(undefined)
    let reqStart = new Date()
    const paymentOptionId = transactionRequestData?.payment_option_id || transactionRequestData?.paymentOptionData?.id
    if (!paymentOptionId) {
      setErrorMsg(
        translate('Unable to find data for this payment option, please ensure this payment options is sill active')
      )
      return
    }
    getEnvelopeId(
      transactionRequestData,
      paymentOptionId,
      props.proposalData?.selectedPaymentOption?.contract_template_id
    )
      .then((envelopeResponse) => {
        if (envelopeResponse?.data?.envelope_id) {
          setDocusignEnvelopeId(envelopeResponse.data.envelope_id)
        }
      })
      .catch((err) => {
        if (err?.body?.error_code) {
          handleDocusignError(err.body.error_code)
        } else {
          let now = new Date()
          // @ts-ignore
          if (now - reqStart > 29000) {
            setErrorMsg(translate('It is taking a while to create this contract. Please wait a moment then try again'))
            logAmplitudeEvent('docusign_envelope_id_error', { document_type: 'timeout' })
          } else {
            setErrorMsg(translate('This contract cannot be accessed at the moment'))
            logAmplitudeEvent('docusign_envelope_id_error', { document_type: 'contract' })
          }
        }
      })
      .finally(() => setIsLoading(false))
  }

  const redirectToDocusign = () => {
    const paymentOptionId = transactionRequestData?.payment_option_id || transactionRequestData?.paymentOptionData?.id
    if (!paymentOptionId) return
    getEnvelopeURL(
      transactionRequestData,
      paymentOptionId,
      docusignEnvelopeId,
      selectedDocusignCustomer,
      props.proposalData?.selectedPaymentOption?.contract_template_id
    )
      .then((urlResponse) => {
        if (urlResponse?.data?.envelope_url) {
          doRedirect(urlResponse.data.envelope_url)
        } else if (urlResponse.data.error_code) {
          if (urlResponse.data.error_code === 1) {
            setErrorMsg(translate('This document is locked due to too many authentication failures.'))
          }
        }
      })
      .catch((err) => {
        if (err?.body?.error_code === 8) {
          setErrorMsg(
            translate('SMS authentication is required for this envelope but it is not enabled in the Docusign account')
          )
        } else {
          setErrorMsg(translate('This contract cannot be accessed at the moment'))
        }
        logAmplitudeEvent('docusign_envelope_url_error', { document_type: 'contract' })
      })
  }

  if (!props.proposalData?.fullCalcReady) {
    return (
      <Dialog open={props.isOpen} onClose={props.onClose}>
        <DialogContent>
          <div className={classes.loadingWrapper} data-testid="checkout-dialog-awaiting-full-calcs">
            <LoadingDots
              text={translate('Please wait until the proposal calculations are complete before proceeding')}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  } else {
    return (
      <Dialog open={props.isOpen} onClose={props.onClose}>
        <TransactionDialogTitle onClose={props.onClose} actionData={props.actionData} showCashFlowDeposit={false} />
        <DialogContent>
          <div className={classes.contentsWrapper} data-testid="docusign-redirect-dialog">
            <CheckoutContentsHeader />
            <CheckoutQuotationTable
              html={
                props.proposalData?.selectedProject?.proposal_data?.proposal_template_settings?.quotation_custom_content
              }
              proposalData={props.proposalData}
            />
            <DocuSignRedirectDialog
              proposalData={props.proposalData}
              setSelectedDocusignCustomer={setSelectedDocusignCustomer}
              showDocusignUserSelection={showDocusignUserSelection}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsWrapper}>
            <div className={classes.errorWrapper}>
              <span className={classes.errorText} data-testid="docusign-error-message">
                {errorMsg}
              </span>
            </div>
            <div className={classes.submitWrapper}>
              {/* @ts-ignore, the props on GenericButton have too many marked as required */}
              <GenericButton
                id={'transactionConfirmButton'}
                label={
                  isLoading ? (
                    <CircularProgress size={24} style={{ color: '#fff' }} />
                  ) : (
                    translate('Review and Sign Contract')
                  )
                }
                onClick={getDocusignEnvelopeId}
                labelWrapperStyle={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
                variant="contained"
                backgroundColor="#46bc41"
                backgroundColorOnHover="rgb(52, 160, 47)"
                disabled={isLoading || isDisabled}
              />
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}
export default DocuSignDialog
