import { TextFieldProps } from '@material-ui/core/TextField'
import BasicInput from 'elements/input/BasicInput'
import React, { memo, useCallback, useRef, useState } from 'react'
import BasicAutocompleteSuggestionList from './BasicAutocompleteSuggestionList'

const BasicAutocompleteSearchInput = <T,>({
  value,
  showSuggestion,
  fullWidth,
  placeholder,
  loading,
  renderer,
  options,
  InputProps,
  onSelect,
  onChange,
  onEnter,
  onFirstTouch,
  closeSuggestion,
  onFocus,
  disabled,
}: {
  value: string
  showSuggestion: boolean
  fullWidth?: boolean
  placeholder: string
  loading: boolean
  renderer(record: T): React.ReactNode
  options: T[]
  InputProps?: TextFieldProps['InputProps']
  onSelect?(item: T): void
  onChange?(value: string): void
  onEnter?(value: string): void
  onFirstTouch(): void
  closeSuggestion(): void
  onFocus(): void
  disabled?: boolean
}) => {
  // This state help the dropdown to rerender
  const [suggestionWidth, setSuggestionWidth] = useState(0)
  const inputEl = useRef<HTMLInputElement>()
  const [touched, setTouched] = useState<boolean>(false)

  const handleChange = (eventOrValue: React.ChangeEvent<{ value: string }> | string) => {
    const event = eventOrValue as React.ChangeEvent<{ value: string }>
    const value = event.target ? event.target.value : (eventOrValue as string)
    onChange?.(value)
    if (!touched) {
      setTouched(true)
      onFirstTouch?.()
    }
  }

  const storeInputRef = (input: any) => {
    inputEl.current = input
    // This is a workaround to make sure the suggestion dropdown to update its width
    // Unable to set entire input element as state here due to referencing issue which won't rerender
    setSuggestionWidth(input?.clientWidth || 0)
  }

  const handleSelect = useCallback(
    (item) => {
      onSelect?.(item)
      closeSuggestion()
    },
    [onSelect]
  )
  return (
    <div>
      <BasicInput
        loading={loading}
        value={value}
        placeholder={placeholder}
        fullWidth={fullWidth}
        variant="standard"
        InputProps={{
          inputRef: storeInputRef,
          ...InputProps,
        }}
        onChange={handleChange}
        onFocus={onFocus}
        onEnter={onEnter}
        disabled={disabled}
      />
      {showSuggestion && inputEl.current && (
        <BasicAutocompleteSuggestionList
          width={suggestionWidth}
          handleSelect={handleSelect}
          inputEl={inputEl.current}
          loading={loading}
          options={options}
          onClose={closeSuggestion}
          renderer={renderer}
        />
      )}
    </div>
  )
}

export default memo(BasicAutocompleteSearchInput)
