import { Divider } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import Button from 'elements/button/Button'
import React from 'react'
import { DatagridLoading } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ActivityDataRendererType, ActivityDataType, InventoryActivity } from './type'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    flexGrow: 1,
  },
}))

const ActivityList = ({
  Renderer,
  loadingActivityData,
  activityData,
  isDashboard,
  reloadActivityData,
}: {
  Renderer: ActivityDataRendererType
  loadingActivityData: boolean
  activityData: ActivityDataType | undefined
  isDashboard?: boolean
  reloadActivityData?: () => void
}) => {
  const history = useHistory()
  const classes = useStyles()

  if (loadingActivityData) {
    return <DatagridLoading hasBulkActions={true} nbChildren={5} />
  }

  if (activityData === undefined) {
    return <div>no record found</div>
  }

  const activityDataToShow = isDashboard ? activityData.slice(0, 9) : activityData

  return (
    <div>
      {activityData.map((activity: InventoryActivity) => {
        return <Renderer key={activity.id} activity={activity} reloadActivityData={reloadActivityData} />
      })}
      {isDashboard && (
        <div className={classes.container}>
          <div className={classes.divider}>
            <Divider />
          </div>
          <Button variant="text" size="small" onClick={() => history.push('/inventory/activity')}>
            See all
          </Button>
          <div className={classes.divider}>
            <Divider />
          </div>
        </div>
      )}
    </div>
  )
}

export default ActivityList
