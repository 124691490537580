import { Grid, Typography } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatCurrencyWithSymbol, format_percentage_value } from 'util/misc'
import PriceFigureWithDiscount from './PriceFigureWithDiscount'
import PricePerWattWithDiscount from './PricePerWattWithDiscount'

export type PriceDetailProps = {
  discountSavingPerItem?: number
  pricePerItem?: number
  outputPower?: number
  quantityPerItem?: number
}

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  row: {
    margin: '20px 0',
  },
  price: {
    fontSize: '18px',
    color: theme.palette.success.main,
  },
  pricePerWatt: {},
}))

export const formatPricePerWattWithUnit = (value: number, orgIso2?: string) => {
  if (value < 1) {
    // TODO: fix me, makes it more robust
    const unit = orgIso2 === 'GB' ? 'p' : 'c'
    return `${format_percentage_value(value, 2)}${unit}`
  } else {
    const currencySymbol = currencySymbolForCountry(orgIso2)
    return `${formatCurrencyWithSymbol(value, currencySymbol)}`
  }
}

export const getPricePerWatt = ({
  power,
  price,
  quantityPerItem,
}: {
  power?: number
  price?: number
  quantityPerItem?: number
}) => {
  if (!power || price == null) {
    return
  }
  return price / ((quantityPerItem || 1) * power)
}

const PriceDetail = ({ pricePerItem, outputPower, quantityPerItem, discountSavingPerItem }: PriceDetailProps) => {
  const classes = useStyles()
  const orgIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  if (pricePerItem == null) {
    return null
  }

  const priceAfterDiscount = pricePerItem - (discountSavingPerItem || 0)
  const pricePerWatt = getPricePerWatt({ power: outputPower, price: pricePerItem, quantityPerItem })
  const pricePerWattAfterDiscount = getPricePerWatt({ power: outputPower, price: priceAfterDiscount, quantityPerItem })
  const hasDiscount = !!discountSavingPerItem
  return (
    <div>
      <Grid container spacing={2} justify="flex-start" alignItems="center" className={classes.row}>
        <Grid item>
          <Typography variant="body1">Price per unit:</Typography>
        </Grid>
        <Grid item>
          {hasDiscount ? (
            <PriceFigureWithDiscount
              currencySymbol={currencySymbol}
              priceAfterDiscount={priceAfterDiscount}
              originalPrice={pricePerItem}
            />
          ) : (
            <div className={classes.price}>{`${formatCurrencyWithSymbol(pricePerItem, currencySymbol)}`}</div>
          )}
        </Grid>
      </Grid>
      {pricePerWatt != null && pricePerWatt && (
        <Grid container spacing={2} justify="flex-start" alignItems="center" className={classes.row}>
          <Grid item>
            <Typography variant="body1">Price per watt:</Typography>
          </Grid>
          <Grid item>
            {hasDiscount && pricePerWattAfterDiscount ? (
              <PricePerWattWithDiscount
                pricePerWattAfterDiscount={formatPricePerWattWithUnit(pricePerWattAfterDiscount, orgIso2)}
                pricePerWatt={formatPricePerWattWithUnit(pricePerWatt, orgIso2)}
              />
            ) : (
              <div className={classes.pricePerWatt}>{formatPricePerWattWithUnit(pricePerWatt, orgIso2)}</div>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default PriceDetail
