import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { useViewShow } from 'ducks/viewMode'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

export const useNavLinkStyles = makeStyles<
  OpenSolarThemeType,
  { badgeSeverity?: undefined | 'info' | 'warning' | 'error' | 'success' }
>((theme: OpenSolarThemeType) => ({
  navLinkActive: {
    fontWeight: 500,
    padding: '16px 25px',
    opacity: 1,
  },

  navLinkActivexs: {
    fontWeight: 500,
    padding: '18px 10px',
    opacity: 1,
  },
  navLink: {
    padding: '16px 25px',
  },
  navLinkSmall: {
    padding: '18px 18px',
    fontSize: 12,
  },
  navLinkxs: {
    padding: '18px 10px',
    fontSize: 12,
  },
  text: {
    color: 'inherit !important',
    textDecoration: 'none',
    display: 'inline-block',
    letterSpacing: 0.5,
    opacity: 0.87,
    '&:hover': {
      opacity: 0.98,
    },
    '&:visited': {
      color: 'inherit',
    },
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    height: '8px',
    width: '8px',
    marginLeft: '5px',
    borderRadius: '8px',
    backgroundColor: ({ badgeSeverity }) => {
      switch (badgeSeverity) {
        case 'info':
          return 'rgba(35, 87, 137, 0.95)'
        case 'warning':
          return 'rgba(255,160,0,0.95)'
        case 'error':
          return 'rgba(193, 42, 46, 0.95)'
        case 'success':
          return 'rgba(66, 195, 17, 0.95)'
        default:
          return 'transparent'
      }
    },
  },
}))

interface PropTypes {
  to: string
  uiKey?: string
  layout: number
  activeStyle?: string
  className?: string
  label: string
  badgeSeverity?: 'info' | 'warning' | 'error' | 'success'
}
const StyledNavLink: React.FC<PropTypes> = ({ to, uiKey, layout, label, ...props }) => {
  const classes = useNavLinkStyles({ badgeSeverity: props.badgeSeverity })
  const history = useHistory()
  const dispatch = useDispatch()
  const show = useViewShow(uiKey)
  const translate = useTranslateWithVariable()

  const onClick = (e) => {
    e.preventDefault()
    if (!window.disableAutoSave && window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: to,
          prompt_heading: translate('Leave Project to %{label}?', { label: translate(label) }),
        })
      )
      if (to === '/shop') logAmplitudeEvent('hardware_navigation_button', {})
    } else {
      history.push(to)
      if (to === '/shop') logAmplitudeEvent('hardware_navigation_button', {})
    }
  }
  const activeStyle = layout >= 3 ? classes.navLinkActive : classes.navLinkActivexs
  const style = layout >= 3 ? classes.navLink : layout === 2 ? classes.navLinkxs : classes.navLinkSmall
  const classNames = [classes.text, style]

  return !show ? (
    <></>
  ) : (
    <NavLink
      activeClassName={props.activeStyle || activeStyle}
      className={props.className || classNames.join(' ')}
      to={to}
      onClick={onClick}
    >
      <div className={classes.labelRow}>
        {translate(label)}
        {props?.badgeSeverity && <div className={classes.badge}></div>}
      </div>
    </NavLink>
  )
}

export default StyledNavLink
