import { orgSelectors } from 'ducks/orgs'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import type { DesignTabs } from 'reducer/designer/view'
import { COMPONENT_TYPE_OPTIONS } from 'resources/components/others/constants'
import { StudioSystemType } from 'types/global'
import { isMountingComponent, parseIntegrationJson } from 'util/misc'
import PanelGenericWarningBox from '../../warning/PanelGenericWarningBox'
import ComponentCollection from '../ComponentCollection'
import IronRidgeBom from '../other/ironridge/IronRidgeBom'
import { TabPanel } from '../TabPanel'
import MountingSettings from './MountingSettings'
import MountingSelector from './selector/MountingSelector'
import RoofTypeSelector from './selector/RoofTypeSelector'

interface MountingTabPropsType {
  selectedTab: DesignTabs
  containerStyle: React.CSSProperties
  allowEdit: boolean
  system: StudioSystemType
}

const MountingTab: FC<MountingTabPropsType> = ({ allowEdit, containerStyle, selectedTab, system }) => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)

  const mountingComponents = useMemo(() => COMPONENT_TYPE_OPTIONS?.filter((x) => isMountingComponent(x.id)), [
    COMPONENT_TYPE_OPTIONS,
  ])

  return (
    <TabPanel selectedTab={selectedTab} value="mounting">
      <div style={containerStyle}>
        <RoofTypeSelector />
        {/* Hide MountingSelector in the US - we don't need this because there's only one mounting option in the US */}
        {countryIso2 !== 'US' && <MountingSelector system={system} allowEdit={allowEdit} />}
        <PanelGenericWarningBox category="mounting" />
        {allowEdit && countryIso2 === 'US' && (
          <IronRidgeBom
            systemUuid={system.uuid}
            ironRidgeIntegration={parseIntegrationJson(system.integration_json)?.ironridge}
          />
        )}
        {/* Hide MountingSettings in the US - we don't need this because there's only one mounting option in the US */}
        {system.mounting && countryIso2 !== 'US' && (
          <MountingSettings mountingId={system.mounting} allowEdit={allowEdit} system={system} />
        )}
        {mountingComponents?.map((mountingType) => (
          <ComponentCollection
            key={mountingType.id}
            style={{ marginTop: 20 }}
            title={mountingType.name}
            dialogSelectorTitle="Select Other Components"
            addButtonLabel="+"
            systemUuid={system.uuid}
            others={system.others()}
            filterParams={{ other_component_type: mountingType.id }}
            disabled={!allowEdit}
          />
        ))}
      </div>
    </TabPanel>
  )
}

export default MountingTab
