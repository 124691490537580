import { Dialog } from '@material-ui/core'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useDialogStyles = makeOpenSolarStyles((theme) => ({
  dialogPaper: { minHeight: 210, height: 800, maxHeight: 'calc(100vh - 24px);' },
  content: {
    padding: '0 16px',
  },
}))

export const HardwareSelectorDialogV2 = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean
  onClose(): void
  children: React.ReactNode
}) => {
  const classes = useDialogStyles()

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} maxWidth="xl" open={isOpen} onBackdropClick={onClose}>
      {children}
    </Dialog>
  )
}

export default HardwareSelectorDialogV2
