import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import OrdersTable from 'pages/ordering/order/OrdersTable'
import { useNotify, useTranslate } from 'ra-core'
import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import type { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import { currencySymbolForCountry } from 'util/misc'
import CheckoutPageLayout from '../../PageLayout'
import CheckoutTitle from '../CheckoutTitle'
import PlaceOrderSideAction from './PlaceOrderAction'
import {
  BillingInfoContext,
  CheckoutPresenterContext,
  DealerContext,
  OrderStateContext,
  ShippingInfoContext,
  VtacOrderModeType,
} from './VtacCheckoutProvider'
import VtacDisclaimer from './VtacDisclaimer'
import VtacPlaceOrderButton from './VtacPlaceOrderButton'
import { HardwareSupplierFilterKeyType } from '../../type'
import CustomField from 'elements/field/CustomField'
import { Form } from 'react-final-form'
import { DealerType, VtacAddressInfoTypeEnum } from '../../../../services/vtac/type'
import AddressInfo from './AddressInfo'

const useContentActionStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    flexWrap: 'wrap',
  },
  infoWrapper: {},
  button: {
    flexGrow: 1,
    padding: 10,
    maxWidth: 300,
  },
  wrapper: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'sp e-between',
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  cost: {
    fontSize: '18px',
    color: '#018030',
  },
}))

const MainContentAction = memo(({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const classes = useContentActionStyles()
  const currencySymbol = useSelector((state: RootState) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const vtacCheckoutPresenter = useContext(CheckoutPresenterContext)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const { itemsCost } = vtacCheckoutPresenter?.getOrderCost(lineItems) || { itemsCost: 0 }

  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <VtacPlaceOrderButton onClick={handlePlaceOrder} trackSource={'order_page'} />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.wrapper}>
          <span className={classes.label}>Total before tax:</span>
          <span className={classes.cost}>&nbsp;{`${currencySymbol} ${itemsCost.toFixed(2)}`}</span>
        </div>
        <VtacDisclaimer />
      </div>
    </div>
  )
})

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  section: {
    padding: 20,
  },
  divider: {
    height: 2,
    margin: '20px 0',
  },
  usedSameInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '20px 20px 20px 0px',
  },
}))

const renderSelectField = ({
 input,
 label,
 required = true,
 meta: { touched, error },
 children,
 onChange,
 ...custom
}) => {
  return (
    <FormControl style={{ width: '400px' }} margin="dense" error={touched && error}>
      <InputLabel id="demo-simple-select-label" required={required}>
        {label}
      </InputLabel>
      <Select
        {...input}
        MenuProps={{ disableScrollLock: true }}
        onChange={(event) => {
          onChange(event.target.value, input)
        }}
        children={children}
        {...custom}
      />
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  )
}

const MainContent = memo(({
  handlePlaceOrder,
  distributor
}: {
  handlePlaceOrder(): void
  distributor: HardwareSupplierFilterKeyType
}) => {
  const [dealers, setDealers] = useState<DealerType[]>([]);
  const [dealerSelectOpen, setDealerSelectOpen] = useState<boolean>(false)
  const classes = useStyles()
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const [isSameBillingInfoAsShippingInfo, setIsSameBillingInfoAsShippingInfo] = useState(false)
  const shippingInfo = useContext(ShippingInfoContext)
  const vtacCheckoutPresenter = useContext(CheckoutPresenterContext)
  const translate = useTranslate()

  useEffect(() => {
    if (isSameBillingInfoAsShippingInfo) {
      vtacCheckoutPresenter?.updateBillingInfo(shippingInfo)
    }
  }, [vtacCheckoutPresenter, shippingInfo, isSameBillingInfoAsShippingInfo])

  useEffect(() => {
    const fetchDealers = async () => {
      const dealersRes = await vtacCheckoutPresenter?.getDealers(distributor)
      if (dealersRes) {
        const dealerArray: DealerType[] = Object.entries(JSON.parse(dealersRes)).map(([key, value]) => {
          return {
            value: key,
            label: value as string,
          };
        });
        setDealers(dealerArray)
      }

    }
    fetchDealers()
  }, [vtacCheckoutPresenter, distributor])

  return (
    <div>
      <Divider className={classes.divider} />

      <Typography variant="h6" gutterBottom>
        1 Shipping Info
      </Typography>
      <div className={classes.section}>
        <AddressInfo infoType={VtacAddressInfoTypeEnum.ShippingInfo} />
        <Form
          onSubmit={() => {}}
          render={() => (
            <CustomField
              id="dealer"
              name="dealer"
              component={renderSelectField}
              onChange={(value, input) => {
                if (value !== 'change_view') {
                  vtacCheckoutPresenter?.updateDealer(value)
                  input.onChange(value)
                  setDealerSelectOpen(false)
                }
              }}
              onClick={(e) => {
                setDealerSelectOpen(!dealerSelectOpen)
              }}
              label={translate('Select Distributor')}
              open={dealerSelectOpen}
            >
              {dealers.map((dealer, index) => (
                <MenuItem id={'dealer' + index} value={dealer.value} key={dealer.value}>
                  {dealer.label}
                </MenuItem>
              ))}
            </CustomField>
          )}
        />
      </div>
      <Divider className={classes.divider} />
      <Typography variant="h6" gutterBottom>
        2 Billing Info
      </Typography>
      <div className={classes.section}>
        <FormControlLabel
          className={classes.usedSameInfo}
          control={
            <Checkbox
              id="elevationAutoCheckbox"
              checked={isSameBillingInfoAsShippingInfo}
              onChange={() => setIsSameBillingInfoAsShippingInfo(!isSameBillingInfoAsShippingInfo)}
              style={{ padding: 0, paddingRight: 9 }}
            />
          }
          label={'I want to use same Billing Info as Shipping Info'}
        />
        <AddressInfo infoType={VtacAddressInfoTypeEnum.BillingIno} />
      </div>
      <Divider className={classes.divider} />
      <Typography variant="h6" gutterBottom>
        3 Review items
      </Typography>
      <div className={classes.section}>
        <OrdersTable lineItems={lineItems} editable={false} />
      </div>

      <Divider className={classes.divider} />

      <MainContentAction handlePlaceOrder={handlePlaceOrder} />
    </div>
  )
})

const OrderPage = () => {
  const notify = useNotify()
  const history = useHistory()

  const vtacCheckoutPresenter = useContext(CheckoutPresenterContext)
  const shippingInfo = useContext(ShippingInfoContext)
  const billingInfo = useContext(BillingInfoContext)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const orderState = useContext(OrderStateContext)
  const dealer = useContext(DealerContext)

  const { itemsCost, tax } = vtacCheckoutPresenter?.getOrderCost(lineItems) || { itemsCost: 0, tax: 0 }

  const orgId = useSelector(authSelectors.getOrgId) || 0
  const countryIso2 = useSelector(orgSelectors.getOrgIso2) || 'GB'
  const distributor: HardwareSupplierFilterKeyType = countryIso2 === 'GB' ? 'vtac_uk' : 'vtac_pl'
  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({
    orgId,
    distributor: countryIso2 === 'GB' ? 'vtac_uk' : 'vtac_pl',
  })

  useEffect(() => {
    if (isChecking) return
    let orderType: VtacOrderModeType | undefined = undefined
    if (isAuthenticated) orderType = 'order'

    vtacCheckoutPresenter?.updateOrderState<'orderType'>({ key: 'orderType', value: orderType })
  }, [isAuthenticated, isChecking, vtacCheckoutPresenter])

  useEffect(() => {
    const isValidShippingInfo = vtacCheckoutPresenter?.validateShippingInfo(shippingInfo)
    const isValidBillingInfo = vtacCheckoutPresenter?.validateBillingInfo(billingInfo)

    vtacCheckoutPresenter?.updateOrderState({ key: 'isReady', value: isValidShippingInfo && isValidBillingInfo })
  }, [billingInfo, shippingInfo, vtacCheckoutPresenter])

  useEffect(() => {
    logAmplitudeEvent('hardware_segen_checkout', {
      action: 'viewed',
      context: 'vtac_checkout_page',
    })
  }, [])

  const handlePlaceOrder = useCallback(async () => {
    try {
      vtacCheckoutPresenter?.updateOrderState<'submitting'>({ key: 'submitting', value: true })
      const orderResult = await vtacCheckoutPresenter?.placeOrder(
        lineItems,
        shippingInfo,
        billingInfo,
        distributor,
        orderState?.orderType || 'order',
        dealer
      )
      vtacCheckoutPresenter?.updateOrderState<'orderResult'>({ key: 'orderResult', value: orderResult })

      notify('Order placed with V-Tac successfully.', 'success')
      history.push('/shop/cart/vtac/?step=post-order')
    } catch (e: any) {
      console.error(e)
      notify(e.message, 'error')
    } finally {
      vtacCheckoutPresenter?.updateOrderState<'submitting'>({ key: 'submitting', value: false })
    }
  }, [lineItems, shippingInfo, billingInfo, vtacCheckoutPresenter, notify, history])

  return (
    <CheckoutPageLayout
      title={<CheckoutTitle />}
      mainContent={<MainContent handlePlaceOrder={handlePlaceOrder} distributor={distributor} />}
      sideAction={<PlaceOrderSideAction handlePlaceOrder={handlePlaceOrder} />}
    />
  )
}

export default memo(OrderPage)
