import { makeStyles } from '@material-ui/core'
import UserIcon from 'elements/UserIcon'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React from 'react'
import { StageActionEventType } from 'types/projects'
import { Theme } from 'types/themes'
import { getEventIcon } from '../util'
const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    marginRight: 10,
  },
  icon: {
    backgroundColor: COLOR_PALETTE.grey2,
    fill: COLOR_PALETTE.darkGrey,
    borderRadius: 20,
    height: 20,
    width: 20,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

interface PropTypes {
  event: StageActionEventType
  minDisplay?: boolean
}

const EventIcon: React.FC<PropTypes> = ({ event, minDisplay }) => {
  const classes = useStyles()
  const EventIcon = getEventIcon(event.event_icon, minDisplay) || null
  return (
    <div className={classes.userIcon} style={{ marginBottom: minDisplay ? 'unset' : 'auto' }}>
      {EventIcon === null && event.who && <UserIcon user={event.who} size={minDisplay ? 26 : undefined} />}
      {EventIcon !== null && <div className={classes.icon}>{EventIcon}</div>}
    </div>
  )
}

export default EventIcon
