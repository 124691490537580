import useGetDirtyFields from 'projectSections/hooks/useGetDirtyFields'
import CRUDRecordDialog from 'projectSections/sections/common/CRUDRecordDialog'
import { useTranslate } from 'ra-core'
import { PrivateFileType } from 'types/privateFile'
import { EditFileFields } from '../formFields/EditFileFields'
import GenerateDocumentDialog from './GenerateDocumentDialog'
import MultipleFilesDialog from './MultipleFilesDialog'
import PreviewDialog from './PreviewDialog'

interface FilesDialogs {
  dialogSelected: string
  onCloseDialog: any
  fileSelected: PrivateFileType | null
  form: any
  projectFormValues: any
  deleteFileInForm: any
  updateEventInForm: any
}

export const CRUDFilesDialogs: React.FC<FilesDialogs> = (props) => {
  const translate = useTranslate()
  const dirtyFields = useGetDirtyFields()

  const isMultipleDialog = props.dialogSelected === 'upload_file' || props.dialogSelected === 'upload_site_photos'

  return (
    <div>
      {isMultipleDialog && (
        <MultipleFilesDialog
          isOpen={props.dialogSelected === 'upload_file' || props.dialogSelected === 'upload_site_photos'}
          record={{ project: props.projectFormValues.url }}
          onDismiss={() => {
            props.onCloseDialog()
          }}
          dialogSelected={props.dialogSelected}
        />
      )}
      <>
        <GenerateDocumentDialog
          projectId={props.projectFormValues.id}
          orgId={props.projectFormValues.org_id}
          projectForm={props.form}
          projectFormDirtyFields={dirtyFields}
        />
        {props.fileSelected && props.dialogSelected === 'preview_file' && (
          <PreviewDialog
            isOpen={props.dialogSelected === 'preview_file'}
            onClose={() => props.onCloseDialog()}
            fileToPreview={props.fileSelected}
          />
        )}
        {props?.fileSelected?.id && (
          <CRUDRecordDialog
            isOpen={props.dialogSelected === 'edit_file'}
            record={props.fileSelected}
            resource="private_files"
            basePath="/private_files"
            onDismiss={() => {
              props.onCloseDialog()
            }}
            formContent={<EditFileFields />}
            updateFormValsFn={(response) => props.updateEventInForm({ id: response.id, fileRecordUpdated: response })}
            notificationOnSuccess={true}
            customSuccessNotificationText={translate('Your File has been saved')}
            dialogTitle={translate('Edit Project File')}
            removeRecordFromForm={(response) => props.deleteFileInForm(props?.fileSelected?.id)}
            width="sm"
            hideDeleteBtn
          />
        )}
      </>
    </div>
  )
}
