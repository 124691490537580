import Slide from '@material-ui/core/Slide'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef } from 'react'
import { SpotlightPropsType } from '../types'
import usePosition from './usePosition'

const Spotlight = (props: SpotlightPropsType) => {
  const { padding = 10, targetId, maxWait, index, disableBackgroundScroll = false, handleHover, keepObserve } = props
  const position: any = usePosition(targetId, maxWait, undefined, keepObserve)
  const positionRef = useRef(position)
  positionRef.current = position
  const handleMouseMove = useCallback((e: any) => {
    let isMoveOverOnSpotlight = false
    if (!positionRef.current) {
      isMoveOverOnSpotlight = false
    } else {
      const offsetY = e.clientY
      const offsetX = e.clientX
      const inSpotlightHeight = offsetY >= positionRef.current?.y && offsetY <= positionRef.current?.bottom
      const inSpotlightWidth = offsetX >= positionRef.current?.x && offsetX <= positionRef.current?.right
      isMoveOverOnSpotlight = inSpotlightWidth && inSpotlightHeight
    }
    handleHover(targetId, isMoveOverOnSpotlight)
  }, [])

  const disableScroll = useCallback(() => {
    const rootDom = window.document.body
    if (rootDom && rootDom.style.overflow !== 'hidden') {
      rootDom.style.overflow = 'hidden'
    }
  }, [])
  const enableScroll = useCallback(() => {
    const rootDom = window.document.body
    if (rootDom) {
      rootDom.style.cssText = ''
    }
  }, [])

  useEffect(() => {
    if (disableBackgroundScroll) {
      disableScroll()
    }
  })

  useEffect(() => {
    const handleMouseMoveDebounced = _.throttle(handleMouseMove, 200, { trailing: true, leading: true })
    window.addEventListener('mousemove', handleMouseMoveDebounced, false)

    return () => {
      window.removeEventListener('mousemove', handleMouseMoveDebounced)
      if (disableBackgroundScroll) {
        enableScroll()
      }
    }
  }, [])
  return position ? (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <div
        style={{
          pointerEvents: 'inherit',
          width: position.width + 2 * padding,
          height: position.height + 2 * padding,
          position: 'absolute',
          top: position.y - padding,
          left: position.x - padding,
          transition: 'opacity 0.2s ease 0s',
          backgroundColor: 'grey',
          borderRadius: 4,
        }}
      />
    </Slide>
  ) : null
}

export default Spotlight
