import { styled } from 'opensolar-ui'
import { IconBtnWithPermissions } from 'projectSections/sections/manage3/common/IconBtnWithPermissions'
import { useContext } from 'react'
import { downloadFile, previewInfo } from '../../../common/utils'
import { FilesContext } from '../../ProjectFilesList'

export const ActionButtonsRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
})

export const TableRowBtns = ({ record, ...props }) => {
  const { selectDialog, selectFile, deleteFileInForm } = useContext(FilesContext)

  const { hasPreview } = previewInfo(record)

  return (
    <ActionButtonsRow>
      <IconBtnWithPermissions
        permissionToCheck="info_documents"
        permissions={['allowView']}
        btnType={'preview'}
        resource={'private_files'}
        record={record}
        tooltip="Click to preview"
        disabledTooltip="Preview only available for PDF and image files"
        isDisabled={!hasPreview}
        onClick={() => {
          if (hasPreview) {
            selectFile(record)
            selectDialog('preview_file')
          }
        }}
      />
      <IconBtnWithPermissions
        permissionToCheck="info_documents"
        permissions={['allowView']}
        btnType={'download'}
        resource={'private_files'}
        record={record}
        onClick={() => {
          downloadFile(record)
        }}
      />
      <IconBtnWithPermissions
        permissionToCheck="info_documents"
        permissions={['allowDelete']}
        btnType={'delete'}
        resource={'private_files'}
        record={record}
        onDelete={() => {
          deleteFileInForm(record.id)
        }}
      />
    </ActionButtonsRow>
  )
}
