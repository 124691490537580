import OrderLineItem from './OrderLineItem'
import { HardwareSupplierEnum, HardwareSupplierFilterKeyType } from './type'

export function getOrderCost(orderableLineItems: OrderLineItem[], applyDiscounts: boolean = true): number {
  return orderableLineItems.reduce((sum, lineItem) => {
    const { beforeDiscount, afterDiscount } = lineItem.getBestDiscountOffer()
    if (applyDiscounts) {
      if (!afterDiscount) {
        return sum
      }
      return sum + afterDiscount
    } else {
      if (!beforeDiscount) {
        return sum
      }
      return sum + beforeDiscount
    }
  }, 0)
}

export const hardwareEnumToFilter: Record<HardwareSupplierEnum, HardwareSupplierFilterKeyType> = {
  1: 'segen',
  2: 'outlet',
  3: 'city_plumbing',
  4: 'hdm',
  5: 'vtac_uk',
  6: 'vtac_pl',
}
