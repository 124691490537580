import { Theme, Typography, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { autoLogin } from 'actions/authActions'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Box, Grid } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UploadDocumentContent from './UploadDocumentContent'
import { BlueSnapOnboardingDocument } from './types'

type PropTypes = {
  match: {
    params: {
      orgId: string
    }
  }
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  pageBackground: {
    backgroundColor: theme.greyLight3,
    minHeight: '100vh',
  },
  container: ({ isMobile }) => ({
    padding: '24px',
    backgroundColor: theme.white,
    maxWidth: '1280px',
    marginTop: isMobile ? 0 : '15px',
  }),
  contentWrapper: {
    margin: '24px 0px',
  },
}))

const HostedUploadDocumentPage: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const classes = useStyles({ isMobile })
  const translate = useTranslate()
  const [isLoading, setIsLoading] = useState(true)
  const [document, setDocument] = useState<BlueSnapOnboardingDocument | undefined>(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const user = useSelector(authSelectors.getCurrentUser)
  const isPro = Boolean(useSelector(authSelectors.getCurrentRole))
  const orgId = useSelector(orgSelectors.getOrg)?.id

  const authorized = Boolean(isPro && String(orgId) === props.match?.params?.orgId)

  // make sure the URL path has a valid security token that can be used to log in
  useEffect(() => {
    // if this component mounts with user already populated, no need to refetch the user
    if (!user) {
      const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
      const params = new URLSearchParams(path.substring(path.indexOf('?')))
      const authToken = params.get('url_auth_token')
      dispatch(autoLogin({ url_auth_token: authToken }))
    }
  }, [user])

  useEffect(() => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    const query_param_doc = {
      status: params.get('status'),
      docGenericDescription: params.get('docGenericDescription'),
      docType: params.get('docType'),
      docUnderwriterNotes: params.get('docUnderwriterNotes'),
    } as BlueSnapOnboardingDocument

    if (query_param_doc.docGenericDescription && query_param_doc.status && query_param_doc.docType) {
      setDocument(query_param_doc)
    }
  }, [])

  useEffect(() => {
    if (user && isLoading) setIsLoading(false)
  }, [isLoading, user])

  const handleUploadSuccess = () => {
    setIsSuccess(true)
  }

  if (isLoading) {
    return <LoadingDots />
  }
  if (!authorized) {
    return <div>{translate('You are not authorized to access this page.')}</div>
  }
  if (!document) {
    return (
      <div>
        {translate('Invalid document parameters. Please use the QR code generated from Cashflow Documents Tab.')}
      </div>
    )
  }
  if (isSuccess) {
    return <div>{translate('Documents were uploaded successfully. You may close this window.')}</div>
  }

  return (
    <div className={classes.pageBackground}>
      <Box>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={10} xl={8} className={classes.container}>
            <Typography variant="h6">{translate('Upload Your Document')}</Typography>
            <Box className={classes.contentWrapper}>
              <UploadDocumentContent
                document={document}
                onSuccess={handleUploadSuccess}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                isHosted
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
export default HostedUploadDocumentPage
