import { RootState } from 'types/state'

export const SET_SELECTED_DESIGN_TAB = 'SET_SELECTED_DESIGN_TAB'
export const SET_STUDIO_PORTAL_ID = 'SET_STUDIO_PORTAL_ID'
export const SET_TOGGLE_BOM = 'SET_TOGGLE_BOM'

export type DesignTabs =
  | 'summary'
  | 'panels'
  | 'mounting'
  | 'inverters'
  | 'batteries'
  | 'others'
  | 'scaffolding'
  | 'pricing'
  | 'incentives'
  | 'payment options'

export type DesignViewReducerState = {
  selectedTab?: DesignTabs
  studioPortalId?: string
  studioZIndex?: number
  bomToggle: boolean
}
const initialState: DesignViewReducerState = {
  selectedTab: undefined,
  bomToggle: false,
}

export function viewReducer(state: DesignViewReducerState = initialState, action: ActionType): DesignViewReducerState {
  switch (action.type) {
    case SET_STUDIO_PORTAL_ID:
      return {
        ...state,
        studioPortalId: action.studioPortalId,
        studioZIndex: action.studioZIndex,
      }
    case SET_SELECTED_DESIGN_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      }
     case SET_TOGGLE_BOM:
      return {
        ...state,
        bomToggle: action.value,
      }
    default:
      return state
  }
}

interface SetSelectedTab {
  type: typeof SET_SELECTED_DESIGN_TAB
  selectedTab: DesignTabs | undefined
}
interface SetStudioPortalID {
  type: typeof SET_STUDIO_PORTAL_ID
  studioPortalId: string | undefined
  studioZIndex: number | undefined
}

interface SetToggleBomType {
  type: typeof SET_TOGGLE_BOM
  value: boolean
}

type ActionType = SetSelectedTab | SetStudioPortalID | SetToggleBomType

export function setSelectedDesignTab(value: DesignTabs | undefined): SetSelectedTab {
  return {
    type: SET_SELECTED_DESIGN_TAB,
    selectedTab: value,
  }
}

export function setStudioPortalID(studioPortalId: string | undefined, studioZIndex?: number): SetStudioPortalID {
  return {
    type: SET_STUDIO_PORTAL_ID,
    studioPortalId,
    studioZIndex,
  }
}

export const setToggleBom = (value: boolean) => {
  return {
    type: SET_TOGGLE_BOM,
    value,
  }
}

export const designViewSelectors = {
  selectedTab: (state: RootState) => {
    return state.designer.view.selectedTab
  },
  studioPortalId: (state: RootState) => {
    return state.designer.view.studioPortalId
  },
  studioZIndex: (state: RootState) => {
    return state.designer.view.studioZIndex
  },
}
