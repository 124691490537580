import { orgSelectors } from 'ducks/orgs'
import { omit } from 'lodash'
import { getSupplierConfig, getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import useHandleChangeSelectedDistributor from 'pages/ordering/subheader/useHandleChangeSelectedDistributor'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { Confirm } from 'ra-ui-materialui'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { DISTRIBUTOR_BRAND_FILTER_KEY_V2 } from '../manufacturerV2/DistributorBrandFilterNodeFactoryV2'
import {
  EXHIBITOR_FILTER_KEY_V2,
  MANUFACTURER_FILTER_KEY_V2
} from '../manufacturerV2/FeatureConfigBrandFilterNodeFactoryV2'
import type {
  ConfigurableFilterOptionsType,
  FilterComponentNodeV2,
  FilterGenericPropsType,
  OptionGenericType
} from '../typeV2'
import { DISTRIBUTOR_FILTER_KEY_V2 } from './DistributorFilterConfigV2Factory'

export const GLOBAL_DISTRIBUTOR_NODE_KEY_V2 = 'global_distributors'
const defaultSelectedOptionValue = ''
const SELECT_ALL_OPTION = { id: 'default', title: 'All', value: defaultSelectedOptionValue }

const getDistributorsOptions = (enabledDistributors: HardwareSupplierEnum[]): OptionGenericType<string>[] => {
  const options: OptionGenericType<string>[] = [SELECT_ALL_OPTION]
  enabledDistributors.forEach((distributor) => {
    const config = getSupplierConfig(distributor)
    if (!config) return
    options.push({
      id: config.filterKey,
      title: config.name,
      value: config.filterKey,
    })
  })

  return options
}

const createGlobalDistributorFilterNode = <T,>({
  label = 'View by distributor',
  rendererComponent: RendererComponent,
  ...restConfigs
}: ConfigurableFilterOptionsType<string, string>): FilterComponentNodeV2 => {
  const FilterComponent = ({
    allFilterValues,
    modifiableFilterValues,
    setModifiableFilters,
  }: FilterGenericPropsType) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [desiredSupplier, setDesiredSupplier] = useState<HardwareSupplierFilterKeyType>()
    const handleSelectDistributor = useHandleChangeSelectedDistributor()
    const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
    const distributorOptions = getDistributorsOptions(enabledDistributors)
    const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)

    if (distributorOptions.length === 0) {
      return null
    }

    const selectedValue = allFilterValues[DISTRIBUTOR_FILTER_KEY_V2]

    const radioBoxMutator = ({
      value,
      oldValue,
    }: {
      value: string
      oldValue: string
      options: OptionGenericType<string>[]
      selectedOption: OptionGenericType<string>
    }) => {
      if (value === defaultSelectedOptionValue) {
        // When click on 'All' option, clear the auto apply preference
        appStorage.clear('auto_apply_distributor_filter')
        return value
      } else if (value === getSupplierConfig(selectedDistributor)?.filterKey) {
        // When click on 'distributor' option same as global selected distributor
        appStorage.setBool('auto_apply_distributor_filter', true)
        return value
      } else {
        // When click on 'distributor' option different from global selected distributor
        setShowConfirmation(true)
        setDesiredSupplier(value as HardwareSupplierFilterKeyType)
        appStorage.setBool('auto_apply_distributor_filter', true)
        return oldValue // Do to allow the new value to be selected, instead we will show a confirmation dialog
      }
    }

    const updateFilter = (newValue) => {
      if (selectedValue === newValue) return
      const newFilterValues = omit(modifiableFilterValues, [
        EXHIBITOR_FILTER_KEY_V2,
        MANUFACTURER_FILTER_KEY_V2,
        DISTRIBUTOR_BRAND_FILTER_KEY_V2,
      ])

      newFilterValues[DISTRIBUTOR_FILTER_KEY_V2] = newValue

      setModifiableFilters(newFilterValues)
    }

    const handleConfirm = () => {
      if (desiredSupplier) {
        handleSelectDistributor(getSupplierEnumByFilterKey(desiredSupplier))
      }

      updateFilter(desiredSupplier)
      setShowConfirmation(false)
    }

    return (
      <>
        <RendererComponent
          id={GLOBAL_DISTRIBUTOR_NODE_KEY_V2}
          defaultValue={defaultSelectedOptionValue}
          additionalProps={{ rendererOnChangeMutator: radioBoxMutator }}
          label={label}
          options={distributorOptions}
          disabled={false}
          onSelect={updateFilter}
          value={selectedValue}
        />
        <Confirm
          isOpen={showConfirmation}
          title={"Change this project's preferred distributor?"}
          content={
            "You can order hardware from leading distributors through OpenSolar's Design page. Changing your hardware distributor will show you stock availability and pricing from the selected distributor and will automatically update your project costs."
          }
          onClose={() => {
            setShowConfirmation(false)
          }}
          onConfirm={handleConfirm}
        />
      </>
    )
  }

  return {
    key: GLOBAL_DISTRIBUTOR_NODE_KEY_V2,
    component: FilterComponent,
  }
}

export default createGlobalDistributorFilterNode
