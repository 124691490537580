import { Divider, Grid, Typography } from '@material-ui/core'
import { memo, useContext, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import type { SegenQuoteItemType } from 'services/segen/type'
import { formatCurrency } from 'util/misc'
import { TotalCost } from '../TotalCost'
import { QuoteContext } from './SegenCheckoutProvider'

const getTotalCostFromQuoteItems = (quoteItems: SegenQuoteItemType[]) => {
  return quoteItems.reduce((sum, cur) => {
    if (cur.productCode === 'DELIVERY') {
      return sum
    }
    return sum + cur.totalPrice.amount
  }, 0)
}

const getDeliveryCostFromQuoteItems = (quoteItems: SegenQuoteItemType[]) => {
  return quoteItems.reduce((sum, cur) => {
    if (cur.productCode === 'DELIVERY') {
      return sum + cur.totalPrice.amount
    }
    return sum
  }, 0)
}

const TypographyWithAnimation = ({ loading, children }) => {
  if (loading) {
    return <Skeleton />
  }
  return (
    <Typography variant="body1" gutterBottom>
      {children}
    </Typography>
  )
}

const OrderSummary = ({ currencySymbol }: { currencySymbol: string | undefined }) => {
  const { loading, quote } = useContext(QuoteContext)
  const quoteItems = quote?.items
  const totalsResponse = quote?.totalsResponse
  const itemsCost = useMemo(() => !!quoteItems && getTotalCostFromQuoteItems(quoteItems), [quoteItems])
  const deliveryCost = useMemo(() => !!quoteItems && getDeliveryCostFromQuoteItems(quoteItems), [quoteItems])
  const tax = totalsResponse?.tax.value
  const netPrice = totalsResponse?.netPrice.amount
  const total = totalsResponse?.grossTotal.amount
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            Items:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TypographyWithAnimation loading={loading}>
            {currencySymbol}{formatCurrency(itemsCost)}
          </TypographyWithAnimation>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            Shipping & Handling
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TypographyWithAnimation loading={loading}>
            {currencySymbol}{formatCurrency(deliveryCost)}
          </TypographyWithAnimation>
        </Grid>
        <Divider style={{ width: 100, height: 2, margin: '10px 0', marginLeft: 'auto' }} />
        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            Total before tax:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TypographyWithAnimation loading={loading}>
            {currencySymbol}{formatCurrency(netPrice)}
          </TypographyWithAnimation>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" gutterBottom>
            VAT (20%):
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TypographyWithAnimation loading={loading}>
            {currencySymbol}{formatCurrency(tax)}
          </TypographyWithAnimation>
        </Grid>
        <Divider style={{ width: '100%', height: 2, margin: '20px 0' }} />
        <TotalCost loading={loading} costWithCurrencySymbol={`${currencySymbol}${formatCurrency(total)}`} />
      </Grid>
    </div>
  )
}

export default memo(OrderSummary)
