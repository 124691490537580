import { logAmplitudeEvent } from 'amplitude/amplitude'
import BannerAd from 'elements/BannerAd'
import { useEffect } from 'react'
import OrderByProjectTopBanner from './banner/OrderByProjectTopBanner'
import Order from './order/Order'
import ProjectTable from './projectTable/ProjectTable'

const OrderByProjectPage = () => {
  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'order_by_project',
    })
  }, [])

  return (
    <div>
      <OrderByProjectTopBanner />
      <ProjectTable />
      <Order />
      <BannerAd
        formats={['leaderboard']}
        placement={`shop_order_by_project_bottom`}
        containerStyle={{ marginTop: 10, marginBottom: 20 }}
        requiredWindowSize={{ width: 960 }}
      />
    </div>
  )
}

export default OrderByProjectPage
