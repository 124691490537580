import { useMediaQuery } from '@material-ui/core'
import Disclaimer from '../../elements/Disclaimer'
import { SystemComponentBlock, SystemComponentContainer } from './SystemComponent'

export const populateGeneric = (templateString, data) => {
  for (var key in data) {
    var regex = new RegExp('\\{\\{\\s?' + key + '\\s?\\}\\}', 'g')
    if (regex.test(templateString)) {
      templateString = templateString.replace(regex, data[key])
    }

    // Also replace the variation with {{system.key}} in addition to {{key}}
    // @TODO: Remove the need for this inefficient hack, probably standardize on {{system.key}}
    regex = new RegExp('\\{\\{\\s?system.' + key + '\\s?\\}\\}', 'g')
    if (regex.test(templateString)) {
      templateString = templateString.replace(regex, data[key])
    }
  }

  return templateString
}

const getIsFeatured = (componentData) =>
  !!(
    componentData &&
    componentData.component_content &&
    componentData.component_content.is_featured &&
    componentData.component_content.description_featured &&
    componentData.component_content.description_featured.length
  )

const getImageUrl = (system, type) => {
  try {
    return system.component_content[`${type}_image_url`]
  } catch (error) {
    return null
  }
}

const getContentTemplate = (componentData) => {
  const content =
    componentData?.component_content?.description_featured || componentData?.component_content?.description_standard
  return content || null
}

const getVideoUrl = (component) => {
  try {
    return component.component_content.video_url
  } catch (error) {
    return null
  }
}

const getProductDetails = (component) => {
  try {
    return component.component_content.product_details
  } catch (error) {
    return null
  }
}

const getSpecSheetUrl = (component) => {
  try {
    return component.component_content.spec_sheet_pdf_url
  } catch (error) {
    return null
  }
}

const systemComponentsGeneralContent = (titleDefault, component, content) => {
  const isFeatured = getIsFeatured(component)
  return {
    title: component?.component_content?.title || titleDefault,
    isFeatured: isFeatured,
    content: component ? content : null,
    productImageUrl: getImageUrl(component, 'promotional'),
    logoUrl: getImageUrl(component, 'logo'),
    videoUrl: getVideoUrl(component),
    productDetails: getProductDetails(component),
    specSheetUrl: getSpecSheetUrl(component),
  }
}

const getComponentDetails = (selectedSystem, componentType) => {
  const keyMapping = {
    inverter: {
      details: 'inverter_codes_with_details',
      type: 'inverters',
    },
    battery: {
      details: 'battery_codes_with_details',
      type: 'batteries',
    },
    other: {
      details: 'other_codes_with_details',
      type: 'others',
    },
  }

  const results = []
  for (const [code, details] of Object.entries(selectedSystem[keyMapping[componentType].details])) {
    for (const component of selectedSystem[keyMapping[componentType].type]) {
      // We use !!component.show_customer and !!component.show_in_your_solution as a hack because
      // it looks like these values may sometimes be empty-string instead of false
      let show_customer = component.hasOwnProperty('show_customer') ? !!component.show_customer : true
      let show_in_your_solution = component.hasOwnProperty('show_in_your_solution')
        ? !!component.show_in_your_solution
        : true

      if (component.code === code && show_customer && show_in_your_solution) {
        results.push({ ...details, component })
        break
      }
    }
  }
  return results
}

const allSystemComponentPlaceholders = (selectedSystem) => {
  return {
    systemKwStc: selectedSystem.systemKwStc,
    moduleCode: selectedSystem.moduleCode,
    moduleQuantity: selectedSystem.moduleQuantity,
    moduleEfficiency: selectedSystem.moduleEfficiency,
    moduleManufacturer: selectedSystem.moduleManufacturer,
    moduleWattStc: selectedSystem.moduleWattStc,
    moduleProductWarranty: selectedSystem.moduleProductWarranty,
    modulePerformanceWarranty: selectedSystem.modulePerformanceWarranty,
    systemOutputAnnualkWh: selectedSystem.systemOutputAnnualkWh,
    systemLifetime: selectedSystem.systemLifetime,
    systemTotalGeneration: selectedSystem.systemTotalGeneration,
    inverterTotalRatingKw: selectedSystem.inverterTotalRatingKw,
    inverterManufacturer: selectedSystem.inverterManufacturer,
    inverterQuantity: selectedSystem.inverterQuantity,
    inverterCodes: selectedSystem.inverterCodes,
    inverterProductWarranty: selectedSystem.inverterProductWarranty,
    inverterAdditionalPartsWarranty: selectedSystem.inverterAdditionalPartsWarranty,
    inverterNominalAcPower: selectedSystem.inverterNominalAcPower,
    batteryManufacturer: selectedSystem.batteryManufacturer,
    batteryCodes: selectedSystem.batteryCodes,
    batteryProductWarranty: selectedSystem.batteryProductWarranty,
    batteryThroughput: selectedSystem.batteryThroughput,
    batteryCycleLife: selectedSystem.batteryCycleLife,
    systemBatteryKwhOptimal: selectedSystem.systemBatteryKwhOptimal,
    systemBatteryUsableKwh: selectedSystem.systemBatteryUsableKwh,
    evergenFirstYearSavingsPercent: selectedSystem.evergenFirstYearSavingsPercent,
    evergenFirstYearSavings: selectedSystem.evergenFirstYearSavings,
    quantity: selectedSystem.quantity,
  }
}

const ModuleContent = (selectedSystem) => {
  const templateString = getContentTemplate(selectedSystem.module)
  const data = {
    ...allSystemComponentPlaceholders(selectedSystem),
    moduleManufacturer: selectedSystem.moduleManufacturer,
    systemKwStc: selectedSystem.systemKwStc,
    moduleQuantity: selectedSystem.moduleQuantity,
    moduleWattStc: selectedSystem.moduleWattStc,
    moduleCode: selectedSystem.moduleCode,
    systemOutputAnnualkWh: selectedSystem.systemOutputAnnualkWh,
    moduleProductWarranty: selectedSystem.moduleProductWarranty,
    modulePerformanceWarranty: selectedSystem.modulePerformanceWarranty,
    moduleEfficiency: selectedSystem.moduleEfficiency,
  }
  return populateGeneric(templateString, data)
}

const getModuleBlocks = (selectedSystem, layout, showDialog, proposalData) => {
  return (
    <SystemComponentBlock
      component={systemComponentsGeneralContent('Solar Panels', selectedSystem.module, ModuleContent(selectedSystem))}
      isMobile={layout === 'small'}
      showDialog={showDialog}
      proposalData={proposalData}
    />
  )
}

const inverterContent = (inverterDetails, selectedSystem) => {
  // Beware we are no longer grouping inverters by type!
  const inverterTotalRatingKw = window.roundToDecimalPlaces(
    inverterDetails.max_power_rating * inverterDetails.quantity,
    3
  )
  const inverterCodes = `${inverterDetails.quantity} x ${inverterDetails.code}`
  const inverterManufacturer = inverterDetails.manufacturer_name

  const templateString = getContentTemplate(inverterDetails.component)
  const data = {
    ...allSystemComponentPlaceholders(selectedSystem),
    inverterTotalRatingKw,
    inverterCodes,
    inverterManufacturer,
    inverterProductWarranty: selectedSystem.inverterProductWarranty,
    inverterAdditionalPartsWarranty: selectedSystem.inverterAdditionalPartsWarranty,
    moduleQuantity: selectedSystem.moduleQuantity,
  }
  return populateGeneric(templateString, data)
}

export const getInverterBlocks = (selectedSystem, layout, showDialog, proposalData) => {
  const invertersDetails = getComponentDetails(selectedSystem, 'inverter')
  const results = invertersDetails.map((inverterDetails, index) => {
    const defaultTitle = inverterDetails.component.microinverter ? 'Microinverter' : 'Inverter'
    return (
      <SystemComponentBlock
        key={`inverter_${index}`}
        component={systemComponentsGeneralContent(
          defaultTitle,
          inverterDetails.component,
          inverterContent(inverterDetails, selectedSystem)
        )}
        isMobile={layout === 'small'}
        showDialog={showDialog}
        proposalData={proposalData}
      />
    )
  })
  return results
}

const batteryContent = (batteryDetails, selectedSystem) => {
  // Beware we are no longer grouping batteries by type!
  const systemBatteryKwhOptimal = window.roundToDecimalPlaces(batteryDetails.kwh_optimal * batteryDetails.quantity, 3)
  const batteryCodes = `${batteryDetails.quantity} x ${batteryDetails.code}`
  const batteryManufacturer = batteryDetails.manufacturer_name

  const templateString = getContentTemplate(batteryDetails.component)
  const data = {
    ...allSystemComponentPlaceholders(selectedSystem),
    systemBatteryKwhOptimal,
    batteryCodes,
    batteryManufacturer,
    batteryProductWarranty: selectedSystem.batteryProductWarranty,
    moduleQuantity: selectedSystem.moduleQuantity,
  }
  return populateGeneric(templateString, data)
}

const getBatteryBlocks = (selectedSystem, layout, showDialog, proposalData) => {
  const batteryDetails = getComponentDetails(selectedSystem, 'battery')
  const results = batteryDetails.map((batteryDetails, index) => {
    return (
      <SystemComponentBlock
        key={`battery_${index}`}
        component={systemComponentsGeneralContent(
          'Battery',
          batteryDetails.component,
          batteryContent(batteryDetails, selectedSystem)
        )}
        isMobile={layout === 'small'}
        showDialog={showDialog}
        proposalData={proposalData}
      />
    )
  })
  return results
}

const otherContent = (otherDetails, selectedSystem) => {
  const description = otherDetails.component.description
  const otherComponentCodes = `${otherDetails.quantity} x ${otherDetails.code}`

  const templateString = getContentTemplate(otherDetails.component)
  const data = {
    ...allSystemComponentPlaceholders(selectedSystem),
    description,
    otherComponentCodes,
    moduleQuantity: selectedSystem.moduleQuantity,
  }
  return populateGeneric(templateString, data)
}

const getOtherBlocks = (selectedSystem, layout, showDialog, proposalData) => {
  const otherDetails = getComponentDetails(selectedSystem, 'other')
  const results = otherDetails.map((otherDetails, index) => {
    return (
      <SystemComponentBlock
        key={`other_${index}`}
        component={systemComponentsGeneralContent(
          otherDetails.component.title,
          otherDetails.component,
          otherContent(otherDetails, selectedSystem)
        )}
        isMobile={layout === 'small'}
        showDialog={showDialog}
        proposalData={proposalData}
      />
    )
  })
  return results
}

const getUtilityTariffBlocks = (selectedSystem, layout, showDialog, proposalData) => {
  return selectedSystem.utility_tariffs.map((utility_tariff, index) => (
    <SystemComponentBlock
      key={`utility_tariff_${index}`}
      component={systemComponentsGeneralContent('Utility Tariff', utility_tariff, '')}
      isMobile={layout === 'small'}
      showDialog={showDialog}
      proposalData={proposalData}
    />
  ))
}

const SystemHardwareDisclaimer = ({ selectedSystem }) => {
  if (
    selectedSystem['disclaimer_system_hardware_components'] &&
    selectedSystem['disclaimer_system_hardware_components'].length > 0
  ) {
    return <Disclaimer content={selectedSystem['disclaimer_system_hardware_components']} />
  } else {
    return null
  }
}

const SystemHardware = ({ selectedSystem, showDialog, proposalTemplateSettings, proposalData }) => {
  //If no hardward is included, hide the whole section including heading
  const layout = useMediaQuery((theme) => theme.breakpoints.down('md')) ? 'small' : 'large'
  if (
    !proposalTemplateSettings ||
    !proposalTemplateSettings.show_product_types ||
    proposalTemplateSettings.show_product_types.length === 0
  ) {
    return null
  }

  const showProductTypes = proposalTemplateSettings.show_product_types.split(',')

  return (
    <div>
      <h1 className="mye-section-title">{proposalTemplateSettings.heading_system_hardware}</h1>
      <SystemComponentContainer>
        {showProductTypes.map((productType) => {
          if (productType === 'module' && parseInt(selectedSystem.moduleQuantity) > 0) {
            return getModuleBlocks(selectedSystem, layout, showDialog, proposalData)
          } else if (productType === 'inverter' && Object.keys(selectedSystem.inverter_codes_with_details).length > 0) {
            return getInverterBlocks(selectedSystem, layout, showDialog, proposalData)
          } else if (productType === 'battery' && Object.keys(selectedSystem.battery_codes_with_details).length > 0) {
            return getBatteryBlocks(selectedSystem, layout, showDialog, proposalData)
          } else if (productType === 'other' && Object.keys(selectedSystem.other_codes_with_details).length) {
            return getOtherBlocks(selectedSystem, layout, showDialog, proposalData)
          } else if (productType === 'utility_tariff') {
            return getUtilityTariffBlocks(selectedSystem, layout, showDialog, proposalData)
          } else {
            return null
          }
        })}
      </SystemComponentContainer>
      <SystemHardwareDisclaimer selectedSystem={selectedSystem} />
    </div>
  )
}

// Extra buffer required for page-right-scrollbar, borders etc
export default SystemHardware
