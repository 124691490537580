export type VtacConnectCustomerResultType = {
  status_code?: number
  reason?: string
  content?: { message?: string }
  token?: string
}

export type CreditLimitType = {
  credit_limit: number
  credit_balance: number
  credit_utilisation: string
}

export type VtacShippingInfoType = {
  firstname: string
  lastname?: string
  city?: string
  state?: string
  street?: string
  postcode: string
  country_id: string
  contact_number: string
}

export type VtacBillingInfoType = {
  firstname: string
  lastname?: string
  city?: string
  state?: string
  street?: string
  postcode: string
  country_id: string
  contact_number: string
}

export type VtacLineItemType = {
  // Shopify id
  productCode: string
  quantity: number
}

export type VtacOrderType = {
  requestType: string;
  requestInfo: {}
  billingInfo: VtacShippingInfoType
  dealerId: string
  shippingInfo: VtacShippingInfoType
  skipDealer: boolean
}
export type VtacOrderResultType = {
  status_code?: number
  reason?: string
  content?: { error?: string }
  draft_order_id?: string
  msg?: string
}

export type DealerType = {
  value: string;
  label: string;
}

export enum VtacAddressInfoTypeEnum {
  ShippingInfo = 0,
  BillingIno,
}
