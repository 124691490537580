import Pagination from 'elements/pagination/Pagination'
import { Box, Button } from 'opensolar-ui'
import { useCallback, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioOtherType } from 'types/global'
import type { ComponentTypesV2 } from 'types/selectComponent'
import HardwareSelectorFilterSidebar from '../../elements/HardwareSelectorFilterSidebar'
import HardwareSelectorLayout from '../../elements/HardwareSelectorLayout'
import HardwareSelectorList from '../../elements/HardwareSelectorList'
import HardwareSelectorToolBars, { getPlaceholderByComponentType } from '../../elements/HardwareSelectorToolBars'
import useHardwareSelectorFetchEngine from '../../fetch/useHardwareSelectorFetchEngine'
import useSelectInverterDesignAssistant from '../../inverterWizard/useSelectInverterDesignAssistant'
import InverterAutoListHeader from '../components/InverterAutoListHeader'
import InverterAutoListRow from '../components/InverterAutoListRow'
import { InverterNoResultsFound } from '../components/InverterNoResultsFound'
import { INVERTER_TYPE_SELECTION_NODE_KEY } from '../constants'
import {
  InverterDesignAssistantFlowNodeComponentStandardPropsType,
  InverterTypeSelectionKeyType,
  InverterWizardRecordType,
} from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    minHeight: '0px',
    display: 'flex',
  },
}))

const getInverterDesignAssistantConfig = ({ inverterType }: { inverterType?: InverterTypeSelectionKeyType }) => {
  return {
    componentTypes: ['inverter'] as ComponentTypesV2[],
    defaultFilterValues: {
      inverter_type: inverterType,
    },
    persistentFilterValuesOverride: {
      auto_string: JSON.stringify(window.SceneHelper.autoStringParams(window.editor.selectedSystem, null)),
    },
  }
}

interface InverterDesignAssistantSelectorProps extends InverterDesignAssistantFlowNodeComponentStandardPropsType {}

const InverterSetAutoSelectorNode: React.FC<InverterDesignAssistantSelectorProps> = ({ flowController, flowStore }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [refreshAutoStringData, setRefreshAutoStringData] = useState(0) // Increment this to refresh the system data sent for auto-stringing
  const selectInverterDesignAssistant = useSelectInverterDesignAssistant({
    system: window.editor.selectedSystem,
    onClose: flowStore.onFinish,
  })
  const translate = useTranslate()
  const classes = useStyles()
  const inverterDesignAssistantConfig = useMemo(
    () =>
      getInverterDesignAssistantConfig({
        inverterType: flowStore.type,
      }),
    [flowStore.type, refreshAutoStringData]
  )
  const {
    componentsData,
    setFilters,
    total = 0,
    loading,
    page,
    perPage,
    setPage,
    setPerPage,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  } = useHardwareSelectorFetchEngine({
    componentTypes: inverterDesignAssistantConfig.componentTypes,
    limit: 20,
    defaultFilterValues: inverterDesignAssistantConfig.defaultFilterValues,
    persistentFilterValuesOverride: inverterDesignAssistantConfig.persistentFilterValuesOverride,
  })

  const handleSelectSet = useCallback(
    async (record: InverterWizardRecordType) => {
      setIsLoading(true)
      // microinverter always has quantity 1
      const componentsSet = record.components.map((component) =>
        component.microinverter ? { ...component, quantity: 1 } : component
      )
      await selectInverterDesignAssistant({
        componentsSet,
        invertersDesignData: record.inverters_design_data,
      })
    },
    [selectInverterDesignAssistant]
  )

  const onRemoveOptimizerAndRetry = useCallback(
    (optimizers: StudioOtherType[]) => {
      for (const optimizer of optimizers) {
        window.editor.deleteObject(optimizer)
      }
      setRefreshAutoStringData(refreshAutoStringData + 1)
    },
    [refreshAutoStringData]
  )

  const handleBack = () => {
    flowController.goTo({
      currentNodeKey: INVERTER_TYPE_SELECTION_NODE_KEY,
      options: {},
    })
  }

  const handleUpdate = useCallback(
    (newFilterValues: { [key: string]: unknown }) => {
      setFilters({ ...modifiableFilterValues, ...newFilterValues })
    },
    [modifiableFilterValues]
  )

  const handleSearchTextUpdate = useCallback(
    (searchText) => {
      handleUpdate({ search: searchText })
    },
    [handleUpdate]
  )

  return (
    <div className={classes.wrapper}>
      <HardwareSelectorLayout
        footer={
          <Box display="flex" justifyContent="flex-end">
            <Button color="default" onClick={handleBack} variant="contained">
              {translate('Back')}
            </Button>
          </Box>
        }
        listContent={
          <HardwareSelectorList
            data={componentsData}
            Header={InverterAutoListHeader}
            isSelectDisabled={isLoading}
            loading={loading}
            onSelect={handleSelectSet}
            Pagination={
              <Pagination total={total} page={page} perPage={perPage} setPage={setPage} setPerPage={setPerPage} />
            }
            Row={InverterAutoListRow}
            total={total}
            noResults={
              <InverterNoResultsFound
                system={window.editor.selectedSystem}
                onRemoveOptimizerAndRetry={onRemoveOptimizerAndRetry}
              />
            }
          />
        }
        toolBars={
          <HardwareSelectorToolBars
            componentType={'inverter'}
            placeholder={getPlaceholderByComponentType('inverter')}
            onChange={handleSearchTextUpdate}
          />
        }
        sidebar={
          <HardwareSelectorFilterSidebar
            persistentFilterValues={persistentFilterValues}
            modifiableFilterValues={modifiableFilterValues}
            allFilterValues={allFilterValues}
            setModifiableFilterValues={setFilters}
          />
        }
      />
    </div>
  )
}

export default InverterSetAutoSelectorNode
