import { CircularProgress } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    marginLeft: '15px',
  },
}))

const SyncWebhooksButton: React.FC<PropTypes> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)
  const classes = useStyles()
  const notify = useNotify()

  const syncWebhooks = () => {
    setIsLoading(true)
    const overrideFromDate = appStorage.getString('sync_webhooks_date')
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    const startFromTime = overrideFromDate
      ? overrideFromDate
      : `${yesterday.getFullYear()}-${yesterday.getMonth() + 1}-${yesterday.getDate()} 00:00:00`
    if (overrideFromDate) {
      alert('You are using an override from date: ' + startFromTime)
    }
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/payments_processing/webhooks/events/sync/',
      data: {
        start_from_time: startFromTime,
        sync: 'true',
      },
    })
      .then((res) => {
        console.log('res', res)
        if (res.data.events_queried && !res.data.events_added) {
          notify('You are all caught up', 'success')
        } else if (res.data.events_queried) {
          notify(`${res.data.events_queried} returned from dev and ${res.data.events_added} events added`, 'success')
        } else {
          notify('No events were returned from dev', 'info')
        }
      })
      .catch((err) => {
        console.log('err')
        notify('Something went wrong', 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (!isStaff && !isSuperUser) return null
  if (process.env.NODE_ENV === 'production') return null

  return (
    <div className={classes.wrapper}>
      <Button onClick={syncWebhooks} variant="contained" size="small" disabled={isLoading}>
        {isLoading ? <CircularProgress /> : <span>Sync PSP Webhooks from dev</span>}
      </Button>
    </div>
  )
}

export default SyncWebhooksButton
