import { Grid, Typography } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import React from 'react'

import CashFlowSetupChecklist from 'pages/cashFlow/sharedComponents/CashFlowSetupChecklist'
import { useTranslate } from 'ra-core'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import DocumentTable from './DocumentTable'
import { DocumentPageContext, useGetCashFlowDocuments, useGetSignedTermsAndConditions } from './utils'

import TermsAndConditionsTable from './TermsAndConditionsTable'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  containerWrapper: {
    overflow: 'hidden',
  },
  header: {
    fontSize: 18,
    margin: '10px 0',
    fontWeight: 400,
  },
  container: {
    background: theme.white,
    padding: '16px 24px',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  divider: {
    margin: '24px 0',
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  textContainer: {
    margin: '5px 0',
  },
}))

const DocumentsPage: React.FC<PropTypes> = (props) => {
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const classes = useStyles()
  const translate = useTranslate()

  const [documents, isDocumentsLoading, refreshDocuments] = useGetCashFlowDocuments(orgId)
  const [tnc, isTncLoading, refreshTnc] = useGetSignedTermsAndConditions(orgId)

  return (
    <div className={classes.containerWrapper}>
      <DocumentPageContext.Provider value={{ refreshDocuments, refreshTnc }}>
        <Grid container spacing={2} className={classes.justifyCenter}>
          <Grid item xs={12} md={8}>
            <div className={classes.container}>
              <div className={classes.headerRow}>
                <h1 className={classes.header}>{translate('Upload Documents')}</h1>
              </div>
              <Typography className={classes.textContainer} variant="subtitle2">
                {translate('Additional business verification documents may be required to setup CashFlow.')}
              </Typography>
              <Typography className={classes.textContainer} paragraph>
                {translate('Securely upload required documents to complete CashFlow Registration.')}
              </Typography>
              <DocumentTable documents={documents} isLoading={isDocumentsLoading} />

              <div className={classes.headerRow}>
                <h1 className={classes.header}>{translate('CashFlow Terms and Conditions')}</h1>
              </div>
              <TermsAndConditionsTable agreements={tnc} isLoading={isTncLoading} />
            </div>
          </Grid>
          <Grid item md={3} sm={false}>
            <CashFlowSetupChecklist activeStep={99} />
          </Grid>
        </Grid>
      </DocumentPageContext.Provider>
    </div>
  )
}
export default DocumentsPage
