import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getCompatibleCodesForSystem } from 'elements/hardwareSelector/SearchToolbar'
import { useState } from 'react'
import { useTranslate } from 'react-admin'
import { BSAModeKeyType, BatterySalesAssistantFlowNodeComponentStandardPropsType } from '../../utility/types'
import { hasBatteryInSystem } from '../../utility/utils'
import ModeSelectorPresenter from './modeNodePresenter'

const useModeNodePresenter = ({
  defaultSelectedMode,
  flowController,
  flowStore,
}: {
  defaultSelectedMode?: BSAModeKeyType
  flowController: BatterySalesAssistantFlowNodeComponentStandardPropsType['flowController']
  flowStore: BatterySalesAssistantFlowNodeComponentStandardPropsType['flowStore']
}) => {
  const [selectedMode, setSelectedMode] = useState<BSAModeKeyType | undefined>(defaultSelectedMode ?? undefined)
  const translate = useTranslate()

  const handleContinue = () => {
    const hasBatteries = hasBatteryInSystem(window.editor?.selectedSystem)
    if (selectedMode === 'manual') {
      ModeSelectorPresenter.continueWithManualMode({
        logAmplitudeEvent,
        hasBatteries,
        flowController,
        translate,
        onClose: flowStore.onFinish,
      })
    } else {
      const compatibleBatteries = getCompatibleCodesForSystem('battery')
      ModeSelectorPresenter.continueWithAutoMode({
        logAmplitudeEvent,
        hasBatteries,
        flowController,
        compatibleBatteries,
      })
    }
  }

  const handleSelectMode = (newSelectedMode: BSAModeKeyType) => {
    ModeSelectorPresenter.updateSelectedMode({
      previousSelectedMode: selectedMode,
      newSelectedMode,
      setSelectedMode,
      flowController,
      logAmplitudeEvent,
    })
  }

  return {
    selectedMode,
    handleContinue,
    handleSelectMode,
  }
}

export default useModeNodePresenter
