import { FormApi, FormState } from 'final-form'
import { FC, MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { FormWithRedirect, SimpleFormView } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

interface Props<T> {
  parentForm: FormApi<T>
  onSaved?: () => void
  mutators?: any
  disconnected?: boolean
  saveRef?: MutableRefObject<() => void>
  formRef?: MutableRefObject<FormApi<T>>
  render: (props: { form: FormApi }) => ReactNode
  allowInvalidSubmit?: boolean
  initialValues?: Object
}

export function useSubForm<T = any>({
  parentForm,
  onSaved,
  mutators,
  disconnected,
  saveRef,
  formRef,
  render,
  allowInvalidSubmit,
  initialValues,
}: Props<T>) {
  const form = useRef<FormApi | undefined>()
  const [formStateSave, setFormStateSave] = useState<FormState<any> | undefined>(undefined)
  const [hasValidationErrors, setHasValidationErrors] = useState(false)
  const [formDirty, setFormDirty] = useState(false)

  const record = useMemo(() => {
    return disconnected ? {} : parentForm.getState().values
  }, [parentForm, disconnected])

  const onSave = () => {
    // Get a fresh copy
    const formState = form.current?.getState()
    if (!formState) return

    if (!allowInvalidSubmit && !formState.valid) {
      console.debug('Form not valid, aborting')
      return
    }

    if (formState.validating) {
      console.debug('Form is validating, aborting')
      return
    }

    const updateFunction = parentForm.mutators.updateField || parentForm.change
    let dirtyFields = form.current?.mutators?.getFormDirtyFields?.()
    if (!dirtyFields) {
      dirtyFields = []
      for (const i in formState.dirtyFields) {
        if (formState.dirtyFields[i]) {
          dirtyFields.push(i)
        }
      }
    }
    //Include whole array/object in dirtyFields; exclude subfields
    dirtyFields.forEach((field) => {
      if (field.includes('.')) {
        const splitField = field.split('.')?.[0]
        if (!dirtyFields.includes(splitField)) dirtyFields.push(splitField)
        dirtyFields = dirtyFields.filter((x) => x !== field)
      }
    })

    for (const field of dirtyFields) {
      console.debug('Updating parent form field: ', field, formState.values[field])
      updateFunction(field, formState.values[field])
      if (parentForm.mutators.markFieldAsDirty) parentForm.mutators.markFieldAsDirty(field)
    }

    onSaved?.()
  }

  if (saveRef) saveRef.current = onSave

  const setForm = (formApi: FormApi) => {
    if (formRef) formRef.current = formApi as FormApi<T>
    form.current = formApi
  }

  return {
    render: () => (
      <FormWithRedirect
        //@ts-ignore
        record={record}
        save={onSave}
        mutators={mutators}
        initialValues={initialValues}
        render={(formProps) => {
          return (
            //@ts-ignore
            <SimpleFormView {...formProps} toolbar={<></>}>
              <SubFormWrapper
                render={render}
                setForm={setForm}
                setFormState={setFormStateSave}
                setHasValidationErrors={setHasValidationErrors}
                setFormDirty={setFormDirty}
              />
            </SimpleFormView>
          )
        }}
      />
    ),
    save: onSave,
    hasValidationErrors,
    formDirty,
    formState: formStateSave,
    form: form.current,
  }
}

type SubFormWrapperProps = {
  setForm: (form: FormApi) => void
  render: (props: { form: FormApi }) => ReactNode
  setHasValidationErrors: (value: boolean) => void
  setFormDirty: (value: boolean) => void
  setFormState: (formState: FormState<any>) => void
}
const SubFormWrapper: FC<SubFormWrapperProps> = ({
  render,
  setForm,
  setHasValidationErrors,
  setFormDirty,
  setFormState,
}) => {
  const form = useForm()
  setForm(form)
  const formState = useFormState()
  setHasValidationErrors(formState.hasValidationErrors)
  const dirtyFields = form.mutators?.getFormDirtyFields?.() || formState.dirtyFields
  setFormDirty(formState.dirty || !!dirtyFields.length)

  useEffect(() => {
    setFormState(formState)
  }, [formState.values])

  return <>{render({ form: form })}</>
}
