import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Box, Typography } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { InfoContainer, InputInfoContainerProps } from './InfoContainer'

const AMPLITUDE_TAB = 'house_of_backup'

interface AppliancesManualInputProps extends Omit<InputInfoContainerProps, 'label' | 'type'> {
  description: string
  title: string
}

const AppliancesManualInput: React.FC<AppliancesManualInputProps> = ({ description, title, ...inputProps }) => {
  return (
    <Box alignItems="flex-start" display="flex" flexDirection="column" gridGap={24}>
      <Box display="flex" flexDirection="column" gridGap={8}>
        <Typography colorHex="#212121" textVariant="subtitle2">
          {title}
        </Typography>
        <Typography colorHex="#4D4D4D" textVariant="body1">
          {description}
        </Typography>
      </Box>
      <InfoContainer label={title} type="input" {...inputProps} />
    </Box>
  )
}
interface BSABackupAppliancesManualProps {
  backupHours: number
  handleChangeBackupHours: (string) => void
  handleChangeTotalCP: (string) => void
  handleChangeTotalPP: (string) => void
  totalContinuousPower: number
  totalPeakPower: number
}

export const BSABackupAppliancesManual: React.FC<BSABackupAppliancesManualProps> = ({
  backupHours,
  handleChangeBackupHours,
  handleChangeTotalCP,
  handleChangeTotalPP,
  totalContinuousPower,
  totalPeakPower,
}) => {
  const translate = useTranslate()
  return (
    <Box display="flex" flexDirection="column" gridGap={40}>
      <AppliancesManualInput
        description={translate(
          'Enter the number of hours you need backup power in case of an outage. This helps determine the battery capacity required.'
        )}
        inputEndText={translate('hours')}
        onBlur={() => {
          logAmplitudeEvent('bda_hours_of_backup_text_input_recorded', {
            in_BDA_tab: AMPLITUDE_TAB,
            inputted_data: `${backupHours} hours`,
          })
        }}
        onChange={handleChangeBackupHours}
        onFocus={() => {
          logAmplitudeEvent('bda_hours_of_backup_text_input_selected', { in_BDA_tab: AMPLITUDE_TAB })
        }}
        title={translate('Hours of Backup')}
        value={backupHours}
      />
      <AppliancesManualInput
        description={translate(
          'Specify the total power in kilowatts (kW) needed to run your essential appliances continuously during a backup period.'
        )}
        inputEndText={translate('kWh')}
        onBlur={() => {
          logAmplitudeEvent('bda_total_continuous_power_text_input_recorded', {
            in_BDA_tab: AMPLITUDE_TAB,
            inputted_data: `${totalContinuousPower} kWh`,
          })
        }}
        onChange={handleChangeTotalCP}
        onFocus={() => {
          logAmplitudeEvent('bda_total_continuous_power_text_input_selected', { in_BDA_tab: AMPLITUDE_TAB })
        }}
        title={translate('Total Continuous Power')}
        value={totalContinuousPower}
      />
      <AppliancesManualInput
        description={translate(
          'Indicate the highest power load in kilowatts (kW) your backup system will need to handle at any one time.'
        )}
        inputEndText={translate('kWh')}
        onBlur={() => {
          logAmplitudeEvent('bda_peak_power_text_input_recorded', {
            in_BDA_tab: AMPLITUDE_TAB,
            inputted_data: `${totalPeakPower} kWh`,
          })
        }}
        onFocus={() => {
          logAmplitudeEvent('bda_peak_power_text_input_selected', { in_BDA_tab: AMPLITUDE_TAB })
        }}
        onChange={handleChangeTotalPP}
        title={translate('Peak Power')}
        value={totalPeakPower}
      />
    </Box>
  )
}
