import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TinyBox from 'elements/TinyBox'
import { styled } from 'opensolar-ui'

export const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: 48,
  '& .MuiPopover-paper': {
    borderRadius: 8,
  },
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  '& svg': {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}))

export const StyledMenuBtnItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  padding: 0,
  paddingLeft: 12,
  '& svg': {
    width: 20,
    height: 20,
    marginRight: 6,
  },
}))

export const LoadingOverlay = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999,
  display: 'grid',
  backgroundColor: 'rgba(255,255,255,0.8)',
  '& .MuiLinearProgress-root': {
    position: 'absolute',
    width: 300,
    top: '50%',
    left: 'calc(50% - 150px)',
  },
}))

export const PlaceholderContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  top: '50%',
  '& span': {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
  },
}))

export const StyledTinnyBox = styled(TinyBox)(({ theme }) => ({
  display: 'grid',
  padding: 0,
  marginBottom: 10,
}))

export const TinnyBoxTopRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  //====
  // '& div:first-child': {
  //   display: 'flex',
  //   border: '3px solid red',
  // },
  // titleContainer: {
  //   display: 'flex',
  // },
  //====
}))

export const TinnyBoxBody = styled('div')(({ theme }) => ({
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 'calc(100% - 112px)',
  rowGap: 10,
}))

export const TagsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  columnGap: 8,
  rowGap: 8,
  width: '100%',
}))

export const TinnyboxColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}))

export const TinnyboxGreyText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.grey[500],
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  '& strong': {
    paddingRight: 5,
    fontWeight: 500,
  },
}))

export const InlineFormContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  '& .edit-page': {
    width: '100%',
    padding: 0,
  },
  '& .simple-form': {
    display: 'flex',
    flexDirection: 'row',
  },
  '& .MuiPaper-elevation1': {
    border: 'none !important',
    padding: '2px 0px 0px 0px',
  },

  '& .MuiPaper-root': {
    marginTop: 0,
  },
  '& .MuiFormControl-root': {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 'calc(100% - 10px)',
    width: 'calc(100% - 10px)',
  },
  '& .MuiCardContent-root': {
    padding: 0,
    width: '100%',
    position: 'relative',
  },
  '& button, & button:hover,  & button:active': {
    padding: 0,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
}))
