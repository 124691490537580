import _ from 'lodash'
import { getAllUsageFields, getUsageData } from 'resources/projects/UsageComponents'

// re-use proux1 getUsageData Func as much as possible.

const formatUsageSubmitValues = (submitValues: any, formValues: any, dirtyFields: any[]) => {
  //it should be okay to differentiate create and edit
  //avoid passing another extra param just reuse formValues
  const creatingNewProject = !formValues.id
  const usageData = getUsageData(formValues)
  const formattedUsageData = JSON.stringify(usageData)
  const usageFields = getAllUsageFields()
  const dirtyUsageFields = _.intersection(usageFields, dirtyFields)

  if (creatingNewProject) {
    //set default usage
    submitValues['usage'] = formattedUsageData
  } else if (!formValues['usage'] && dirtyUsageFields.includes('usage_data_source')) {
    // this happens when usage_data_source is changed, see ElectricUsageContent:L235
    submitValues['usage'] = formattedUsageData
  } else if (dirtyUsageFields.length > 0) {
    submitValues['usage'] = formattedUsageData
  }

  return submitValues
}

export default formatUsageSubmitValues
