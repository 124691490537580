import Grid from '@material-ui/core/Grid'
import type { OpenSolarThemeType } from 'Themes'
import { memo } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    borderBottom: '1px solid #e7e7e7',
    height: '48px',
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  overflowItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

const OrderTableHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Grid container className={classes.tableHeaderContainer} alignItems="center">
      <Grid container item xs={12} className={classes.header} spacing={1}>
        <Grid item xs={2} style={{ paddingLeft: 20 }}>
          {translate('Item')}
        </Grid>
        <Grid item xs={1} className={classes.overflowItem}>
          {translate('Category')}
        </Grid>
        <Grid item xs={1} className={classes.overflowItem}>
          {translate('Manufacturer')}
        </Grid>
        <Grid item xs={3}>
          {translate('Quantity')}
        </Grid>
        <Grid item xs={2}>
          {translate('Allocations')}
        </Grid>
        <Grid item xs={1} className={classes.overflowItem}>
          {translate('Cost/Unit')}
        </Grid>
        <Grid item xs={1} className={classes.overflowItem}>
          {translate('Total Cost')}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Grid>
  )
}

export default memo(OrderTableHeader)
