import { TextFieldProps } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import { InputProps } from 'ra-core'
import { ReferenceArrayInput } from 'ra-ui-materialui'
import React, { CSSProperties, FC } from 'react'

type PropTypes = {
  source: string
  label: string
  reference: string
  style?: CSSProperties
  chipsProps?: InputProps<TextFieldProps>
}

export const AssigneeMultiSelectField: FC<PropTypes> = ({ source, label, reference, style, chipsProps }) => {
  return (
    <CustomField
      component={ReferenceArrayInput}
      name={source}
      source={source}
      reference={reference}
      optionValueField="id"
      fullWidth={true}
      defaultValue={[]}
      label={label}
      style={style}
    >
      <ChipsInput
        source={source}
        optionText="display"
        optionValue="id"
        options={{
          style: { minWidth: 256 },
        }}
        {...chipsProps}
      />
    </CustomField>
  )
}

export const NotifyTeamMembersField: FC<{ style?: CSSProperties; chipsProps?: InputProps<TextFieldProps> }> = ({
  style,
  chipsProps,
}) => {
  return (
    <AssigneeMultiSelectField
      source="notify_team_members"
      label="Notify team members"
      reference="roles"
      style={style}
      chipsProps={chipsProps}
    />
  )
}
