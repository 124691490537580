import { Tooltip } from '@material-ui/core'
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  DeleteOutline,
  EditOutlined,
  HelpOutline,
} from '@material-ui/icons'
import CustomField from 'elements/field/CustomField'
import LocalizedCurrencyInput from 'elements/input/LocalizedCurrencyInput'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { NumberInput } from 'ra-ui-materialui'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { currencySymbolForCountry } from 'util/misc'
import PaymentRequestStatusChip from './PaymentRequestStatusChip'

type PropTypes = {
  key: number
  milestone: ExpectedMilestonePaymentType
  allPaymentRequests: PaymentRequestType[]
  isLast: boolean
  pricePayable: number
  editMode: 'milestones' | 'price'
  setEditMode: (val: 'milestones' | 'price') => void
  projectCountryIso2: string | undefined
  deleteMilestone: (paymentNumber: number) => void
  moveMilestoneUp: (pmtNum: number) => void
  moveMilestoneDown: (pmtNum: number) => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  cell: {
    borderBottom: `1px solid ${theme.greyLight1}`,
  },
  arrowsColumn: {
    width: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowWrapper: {
    cursor: 'pointer',
  },
  arrow: {
    flex: 1,
    color: theme.greyMid1,
  },
  centered: {
    textAlign: 'center',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconWrapper: {
    width: '50px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  editIcon: {
    color: theme.greyMid1,
    paddingTop: '10px',
    cursor: 'pointer',
    width: '20px',
  },
  deleteIcon: {
    color: theme.greyMid1,
    paddingTop: '10px',
    cursor: 'pointer',
    width: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  helpIcon: {
    color: theme.greyLight2,
    paddingTop: '10px',
    cursor: 'pointer',
    width: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const MilestoneOverrideRow: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const fieldName = `overrides.${props.milestone.payment_number - 1}.fixed_override_amount`
  const currencySymbol = currencySymbolForCountry(props.projectCountryIso2)
  const translate = useTranslate()
  const formState = useFormState()

  const isCustom = useMemo(() => {
    return !props.milestone.payment_milestone_configuration_id
  }, [props.milestone.payment_milestone_configuration_id, props.milestone.payment_number])

  const isLocked = useMemo(() => {
    return props.isLast || !!props.milestone.payment_request_id
  }, [props.isLast, props.milestone])

  const isDisabled = useMemo(() => {
    return props.isLast || !!props.milestone.payment_request_id || props.editMode !== 'milestones'
  }, [props.isLast, props.editMode, props.milestone])

  const canMoveUp = useMemo(() => {
    if (props.isLast || props.milestone.payment_number <= 2 || isLocked) return false
    const previousMilestone: ExpectedMilestonePaymentType =
      formState.values?.overrides?.[props.milestone.payment_number - 2]
    if (previousMilestone && !previousMilestone.payment_request_id) return true
    return false
  }, [formState.values.overrides, props.milestone.payment_number, props.isLast, isLocked])

  const canMoveDown = useMemo(() => {
    if (props.isLast || props.milestone.payment_number <= 1 || isLocked) return false
    const nextMilestone: ExpectedMilestonePaymentType = formState.values?.overrides?.[props.milestone.payment_number]
    if (nextMilestone && nextMilestone.payment_number !== formState.values.overrides?.length) return true
    return false
  }, [formState.values.overrides, props.milestone.payment_number, props.isLast, isLocked])

  return (
    <tr>
      <td className={classes.cell}>
        <div className={classes.arrowsColumn}>
          <div className={classes.arrowWrapper}>
            {canMoveUp && (
              <ArrowUpwardOutlined
                className={classes.arrow}
                onClick={() => props.moveMilestoneUp(props.milestone.payment_number)}
              />
            )}
          </div>
          <div className={classes.arrowWrapper}>
            {canMoveDown && (
              <ArrowDownwardOutlined
                className={classes.arrow}
                onClick={() => props.moveMilestoneDown(props.milestone.payment_number)}
              />
            )}
          </div>
        </div>
      </td>
      <td className={classes.cell}>{props.milestone.payment_number}</td>
      <td className={classes.cell}>
        {isCustom ? (
          <CustomField
            style={{ width: '100px', padding: '0px', margin: '0px' }}
            name={`overrides.${props.milestone.payment_number - 1}.title`}
            component={NumberInput}
            disabled={isDisabled}
            initialValue={props.milestone.title}
          />
        ) : (
          <>{props.milestone.title}</>
        )}
      </td>
      <td className={classes.cell}>
        {isCustom ? (
          <CustomField
            style={{ width: '100%', padding: '0px', margin: '0px' }}
            name={`overrides.${props.milestone.payment_number - 1}.description`}
            component={NumberInput}
            disabled={isDisabled}
            initialValue={props.milestone.description}
          />
        ) : (
          <>{props.milestone.description}</>
        )}
      </td>
      <td className={classes.cell}>
        {props.allPaymentRequests?.length && (
          <PaymentRequestStatusChip milestone={props.milestone} allPaymentRequests={props.allPaymentRequests} />
        )}
      </td>
      <td className={classes.cell + ' ' + classes.centered}>
        <div>
          <Tooltip title={translate('Percentage is automatically calculated based on the amounts provided')}>
            <div>{props.milestone.percentage}%</div>
          </Tooltip>
        </div>
      </td>
      <td className={classes.cell + ' ' + classes.right}>
        <CustomField
          style={{
            width: '140px',
            padding: '0px',
            margin: '0px',
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'justify-end',
          }}
          name={fieldName}
          component={LocalizedCurrencyInput}
          disabled={isDisabled}
          initialValue={props.milestone.payment_amount}
          currencySymbol={currencySymbol}
        />
      </td>
      <td className={classes.cell}>
        <div className={classes.iconWrapper}>
          {props.editMode !== 'milestones' && !isLocked && (
            <EditOutlined className={classes.editIcon} onClick={() => props.setEditMode('milestones')} />
          )}
          {!isLocked && (
            <DeleteOutline
              className={classes.deleteIcon}
              onClick={() => props.deleteMilestone(props.milestone.payment_number)}
            />
          )}
          {props.isLast && (
            <div>
              <Tooltip title={translate('Final payment is always calculated as the remainder of the amount due.')}>
                <HelpOutline className={classes.helpIcon} />
              </Tooltip>
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}
export default MilestoneOverrideRow
