import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import ManageDocusignDialog from 'resources/integrations/docusign/ManageDocusignDialog'
import { ProjectType } from 'types/projects'
import IntegrationRow from '../IntegrationRow'
interface PropTypes {
  project: ProjectType
}

const DocusignCTA: React.FC<PropTypes> = ({ project }) => {
  const translate = useTranslate()
  const [showDialog, setShowDialog] = useState(false)

  return (
    <>
      <IntegrationRow
        title={'Manage Docusign Contract'}
        description={translate('Automate contract signing through your online proposal.')}
        btnLabel={translate('Connect to DocuSign')}
        onClick={() => {
          setShowDialog(true)
        }}
      />
      {showDialog && <ManageDocusignDialog project={project} open={showDialog} onClose={() => setShowDialog(false)} />}
    </>
  )
}

export default DocusignCTA
