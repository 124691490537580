import { Popover, Theme, Tooltip, makeStyles, useMediaQuery } from '@material-ui/core'
import { UnsubscribeOutlined } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import { Button } from 'opensolar-ui'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { pauseEmailReminders } from 'pages/cashFlow/utils'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import React, { useMemo, useRef, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import PaymentRequestStatusChip from '../projectProgress/cashFlow/milestoneOverride/PaymentRequestStatusChip'
import CancelInvoiceButton from './invoiceActions/CancelInvoiceBtn'
import MarkPaidOfflineButton from './invoiceActions/MarkPaidOffine'
import PreviewInvoiceLink from './invoiceActions/PreviewInvoiceLink'
import SendInvoiceButton from './invoiceActions/SendInvoiceBtn'

type PropTypes = {
  paymentRequest: PaymentRequestType
  pricePayable: number
  systemUuid: string
  allPaymentRequests: PaymentRequestType[]
  index: number
}

const useStyles = makeStyles<OpenSolarThemeType, { isXs: boolean; status: string }>((theme) => ({
  row: {
    border: `1px solid ${theme.greyLight1}`,
  },
  cellText: {
    fontWeight: 400,
    padding: '12px 0px',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.blue,
  },
  actionsMenu: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px',
  },
  actionRow: {
    cursor: 'pointer',
    padding: '10px',
  },
  pausedBadge: {
    color: '#BC1D1C',
    padding: '5px 0 0',
    background: '#F1D7D7',
    borderRadius: 15,
  },
}))

const CashFlowTransactionRow: React.FC<PropTypes> = (props) => {
  const [actionsAreShown, setActionsAreShown] = useState<boolean>(false)
  const [disableButtons, setDisableButtons] = useState<boolean>(false)
  const buttonRef = useRef<HTMLDivElement>(null)
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isXs, status: props.paymentRequest.status })
  const project = useFormState().values
  const currencySymbol = currencySymbolForCountry(project.country_iso2)
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()
  const refresh = useRefresh()

  const refreshMilestones = () => {
    dispatch(triggerMilestoneRefresh())
  }

  const dateRequested = useMemo(() => {
    if (props.paymentRequest.date_requested) {
      return new Date(props.paymentRequest.date_requested).toLocaleDateString()
    }
    return null
  }, [])

  const dateDue = useMemo(() => {
    if (props.paymentRequest.date_due) {
      return new Date(props.paymentRequest.date_due).toLocaleDateString()
    }
    return null
  }, [])

  const datePaid = useMemo(() => {
    if (props.paymentRequest.date_completed) {
      return new Date(props.paymentRequest.date_completed).toLocaleDateString()
    }
    return null
  }, [])

  const isNext = useMemo(() => {
    // if it's already sent then just return false
    if (props.paymentRequest?.id && props.paymentRequest?.status !== 'cancelled') return false
    // if it's not sent but it's the first payment then it has to be next
    if (props.paymentRequest.payment_number === 1 && props.paymentRequest.status === 'unsent') return true

    // otherwise go see if there is a non-cancelled request for any of the previous milestones
    const previousUnsentRequest = props.allPaymentRequests?.find((otherRequest) => {
      return (
        otherRequest.payment_number < props.paymentRequest.payment_number &&
        (otherRequest.status === 'cancelled' || !otherRequest.id)
      )
    })
    // if any previous milestone has not yet been sent then this can't be the next payment so return false
    return previousUnsentRequest ? false : true
  }, [props.paymentRequest, props.allPaymentRequests])

  const isMostRecentRowForThisMilestone = useMemo(() => {
    // if it is next, that doesn't necessarily mean this particular row can be sent
    // because we may have multiple payment requests for the same milestone (for example, it was sent, cancelled then re-sent)
    let isMostRecentForThisPaymentNumber = false
    let nextPaymentRequest = props.allPaymentRequests?.[props.index + 1]

    if (
      !nextPaymentRequest ||
      (nextPaymentRequest && nextPaymentRequest?.payment_number > props.paymentRequest.payment_number)
    )
      isMostRecentForThisPaymentNumber = true

    return isMostRecentForThisPaymentNumber
  }, [props.paymentRequest, props.allPaymentRequests, props.index])

  const paymentMethod = useMemo(() => {
    switch (props.paymentRequest.payment_method) {
      case 'local_bank_transfer':
        return translate('Local Bank Transfer')
      case 'credit_card_standard':
        return translate('Credit Card - Standard')
      case 'credit_card_premium':
        return translate('Credit Card - Premium')
      case 'credit_card_international':
        return translate('Credit Card - International')
      case 'debit_card_standard':
        return translate('Debit Card - Standard')
      case 'debit_card_premium':
        return translate('Debit Card - Premium')
      case 'debit_card_international':
        return translate('Debit Card - International')
      case 'ach':
        return 'ACH'
      default:
        if (props.paymentRequest.date_completed) {
          return translate('Offline')
        }
        return null
    }
  }, [props.paymentRequest.payment_method])

  const showCancel = useMemo(() => {
    if (props.paymentRequest?.status === 'payment failed') return true
    return (
      props.paymentRequest?.id &&
      props.paymentRequest.status !== 'cancelled' &&
      props.paymentRequest.status !== 'pending' &&
      !props.paymentRequest.date_completed
    )
  }, [props.paymentRequest])

  const showMarkPaidOffline = useMemo(() => {
    // Similar logic for "Mark Paid Offline" as "Cancel", except that the PaymentRequest doesn't need to even exist in the DB yet.
    if (props.paymentRequest?.status === 'payment failed') return true
    return (
      props.paymentRequest.status !== 'cancelled' &&
      props.paymentRequest.status !== 'pending' &&
      !props.paymentRequest.date_completed
    )
  }, [props.paymentRequest])

  const showSendReminder = useMemo(() => {
    if (props.paymentRequest?.status === 'payment failed') return true
    return props.paymentRequest?.id && props.paymentRequest.date_due && !props.paymentRequest.date_completed
  }, [props.paymentRequest])

  const showAnyActions = useMemo(() => {
    if (props.paymentRequest?.status === 'payment failed') return true
    return !props.paymentRequest?.date_completed && props.paymentRequest?.status !== 'cancelled'
  }, [props.paymentRequest])

  const remindersAreMuted = useMemo(() => {
    return props.paymentRequest?.invoice_email_reminder_status === 'paused' ? true : false
  }, [props.paymentRequest])

  const handleClickReminders = () => {
    if (props.paymentRequest?.invoice) {
      pauseEmailReminders(props.paymentRequest?.org, props.paymentRequest?.invoice, remindersAreMuted)
        .then(() => {
          refreshMilestones()
          notify(
            translate(
              `Automated email reminders for ${props.paymentRequest.invoice_number} ${
                remindersAreMuted ? 'resumed' : 'stopped'
              }`
            ),
            'success'
          )
        })
        .catch((err) => {
          notify(err, 'warning', { autoHideDuration: undefined })
        })
        .finally(() => {
          refresh()
        })
    }
  }

  if (!props.pricePayable) return null
  return (
    <tr key={props.paymentRequest.id} className={classes.row}>
      {!isMd && (
        <th className={classes.cellText}>
          <PreviewInvoiceLink paymentRequest={props.paymentRequest} />
        </th>
      )}
      <th className={classes.cellText}>{props.paymentRequest.title}</th>
      {!isMd && (
        <th className={classes.cellText}>
          {props.paymentRequest.is_standalone
            ? 'N/A'
            : `${Math.round((props.paymentRequest.amount_requested / props.pricePayable) * 1000) / 10}%`}
        </th>
      )}
      <th className={classes.cellText}>
        <PaymentRequestStatusChip paymentRequest={props.paymentRequest} />
      </th>
      {!isMd && <th className={classes.cellText}>{paymentMethod}</th>}
      {!isXs && <th className={classes.cellText}>{dateRequested}</th>}
      {!isMd && <th className={classes.cellText}>{dateDue}</th>}
      {!isXs && <th className={classes.cellText}>{datePaid}</th>}
      <th className={classes.cellText}>
        {formatCurrencyWithSymbol(props.paymentRequest.amount_requested, currencySymbol)}
      </th>
      <th className={classes.cellText}>
        {remindersAreMuted && (
          <div className={classes.pausedBadge}>
            <Tooltip title="Automated email reminders currently stopped.">
              <UnsubscribeOutlined />
            </Tooltip>
          </div>
        )}
      </th>
      <th className={classes.cellText}>
        {showAnyActions && (
          <>
            <Button onClick={() => setActionsAreShown(true)} variant="contained" size="small">
              <div ref={buttonRef}>{translate('Actions')}</div>
            </Button>
            <Popover
              open={actionsAreShown}
              anchorEl={buttonRef?.current}
              anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              onClose={() => setActionsAreShown(false)}
            >
              <div className={classes.actionsMenu}>
                {isNext && isMostRecentRowForThisMilestone && (
                  <div className={classes.actionRow}>
                    <SendInvoiceButton
                      label="invoice"
                      actionType="invoice"
                      projectId={project?.id}
                      disableButtons={disableButtons}
                      systemUuid={props.systemUuid}
                      setDisableButtons={setDisableButtons}
                      setActionsAreShown={setActionsAreShown}
                      paymentRequest={props.paymentRequest}
                    />
                  </div>
                )}

                {remindersAreMuted && (
                  <div className={classes.actionRow}>
                    <Tooltip title="Resume automated reminders. Email reminders will start from wherever they are in the reminder schedule.">
                      <Button size="small" className={classes.btn} onClick={handleClickReminders}>
                        Resume Automated Reminders
                      </Button>
                    </Tooltip>
                  </div>
                )}

                {showSendReminder && !remindersAreMuted && (
                  <>
                    <div className={classes.actionRow}>
                      <SendInvoiceButton
                        label="reminder"
                        actionType="reminder"
                        projectId={project?.id}
                        disableButtons={disableButtons}
                        systemUuid={props.systemUuid}
                        setDisableButtons={setDisableButtons}
                        setActionsAreShown={setActionsAreShown}
                        paymentRequest={props.paymentRequest}
                      />
                    </div>

                    <div className={classes.actionRow}>
                      <Tooltip title="Turn off email reminders for customers on this project. You can turn them back on later, and they'll pick up where they left off.">
                        <Button size="small" className={classes.btn} onClick={handleClickReminders}>
                          Stop Automated Reminders
                        </Button>
                      </Tooltip>
                    </div>
                  </>
                )}
                {showMarkPaidOffline && (
                  <div className={classes.actionRow}>
                    <MarkPaidOfflineButton
                      paymentRequest={props.paymentRequest}
                      systemUuid={props.systemUuid}
                      disableButtons={disableButtons}
                      setDisableButtons={setDisableButtons}
                      setActionsAreShown={setActionsAreShown}
                    />
                  </div>
                )}
                {showCancel && (
                  <div className={classes.actionRow}>
                    <CancelInvoiceButton
                      paymentRequest={props.paymentRequest}
                      systemUuid={props.systemUuid}
                      disableButtons={disableButtons}
                      setDisableButtons={setDisableButtons}
                      setActionsAreShown={setActionsAreShown}
                    />
                  </div>
                )}
              </div>
            </Popover>
          </>
        )}
      </th>
    </tr>
  )
}
export default CashFlowTransactionRow
