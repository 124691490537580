import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { getDistributorConfigByEnum } from 'pages/ordering/configs'
import { LineItemLoadedDataType } from 'pages/ordering/type'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

export type LoadHardwareStockType = (code: string[] | undefined, length: number | undefined) => Promise<void>

type HardwareStockResponse = {
  loadHardwareStock: LoadHardwareStockType
  loadingStock: boolean
  stockData: LineItemLoadedDataType[] | undefined
}

const useHardwareStock = (): HardwareStockResponse => {
  const orgId = useSelector(authSelectors.getOrgId)
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const supplierFilterKey = getDistributorConfigByEnum(supplier)?.filterKey
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stockData, setStockData] = useState<LineItemLoadedDataType[]>()

  const loadHardwareStock = useCallback(async (codes, length) => {
    setIsLoading(true)
    await restClientInstance('CUSTOM_GET', 'custom', {
      // Codes is unique, so we don't need to check the component type
      url: `components/?catalog=full&fieldset=list&org_id=${orgId}&limit=${length}&require_distributor=${supplierFilterKey}&require_stock=true&codes=${codes}`,
    })
      .then((res) => {
        if (res.data.length > 0) {
          setStockData(res.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    loadingStock: isLoading,
    loadHardwareStock,
    stockData,
  }
}

export default useHardwareStock
