import Portal from '@material-ui/core/Portal'
import { LEFT_HAND_SIDE_HARDWARE_FILTERS } from 'elements/hardwareFilter/constants'
import useAvailableFilterByRendererConfig from 'elements/hardwareFilter/hooks/useAvailableFilterByRendererConfig'
import mapRendererConfigWithAccordion from 'elements/hardwareFilter/renderers/mapRendererConfigWithAccordion'
import type { FilterComponentNode, FilterKeysType, FilterValuesType } from 'elements/hardwareFilter/type'
import { getLastRecentUsedFilters } from 'elements/hardwareFilter/utils/lruFilterCacheStore'
import { useTranslate } from 'ra-core'
import React, { useCallback, useMemo } from 'react'
import type { ComponentTypes } from 'types/selectComponent'

import { logAmplitudeEvent } from '../../../amplitude/amplitude'
import { AVAILABILITY_FILTER_KEY } from '../../../elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactory'
import ClearFilterButton from '../../../elements/hardwareSelector/filters/ClearFilterButton'
import { makeOpenSolarStyles } from '../../../themes/makeOpenSolarStyles'

const HARDWARE_FILTER_SIDEBAR_ID = 'hardware-page-filter-sidebar'

export const HardwareFilterSidebarMenu = () => {
  return <div id={HARDWARE_FILTER_SIDEBAR_ID}></div>
}

const useStyle = makeOpenSolarStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'column' },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'space-between',
  },
  filterTitle: {
    fontSize: '14px',
    color: '#a2a2a2',
    fontWeight: 400,
  },
}))

const HardwareFilterSidebar = ({
  persistentFilterValues,
  modifiableFilterValues,
  allFilterValues,
  setModifiableFilterValues,
  componentType,
  trackSource,
}: {
  componentType: ComponentTypes | undefined
  persistentFilterValues: FilterValuesType
  modifiableFilterValues: FilterValuesType
  allFilterValues: FilterValuesType
  trackSource: string
  setModifiableFilterValues(
    newFilterValues: FilterValuesType,
    displayedFilters?: { [key in FilterKeysType]?: boolean }
  ): void
}) => {
  const translate = useTranslate()
  const classes = useStyle()
  const portalContainer = document.getElementById(HARDWARE_FILTER_SIDEBAR_ID)

  const filtersNodesAndRendererConfig = useMemo(() => {
    return mapRendererConfigWithAccordion({
      config: LEFT_HAND_SIDE_HARDWARE_FILTERS,
      defaultExpanded: getLastRecentUsedFilters(),
      showDivider: true,
      recordLru: true,
    })
  }, [componentType])

  const filterNodes: FilterComponentNode[] = useAvailableFilterByRendererConfig({
    filtersNodesAndRendererConfig,
    componentType,
  })

  const handleClear = useCallback(() => {
    logAmplitudeEvent('hardware_select_filters', {
      action: 'clicked',
      context: 'clear_filter_button',
      source: trackSource,
    })

    // By default, we want to show hardware items that are in-stocks
    const filterValues = { [AVAILABILITY_FILTER_KEY]: 'true' }
    setModifiableFilterValues(filterValues)
  }, [])

  if (!portalContainer) return null

  return (
    <Portal container={portalContainer}>
      <div className={classes.root}>
        <div className={classes.filterContainer}>
          <h1 className={classes.filterTitle}> FILTERS </h1>
          <ClearFilterButton handleClear={handleClear} noPadding />
        </div>

        {filterNodes.map((node: FilterComponentNode | undefined, index: number) => {
          if (node === undefined) return null
          const Filter = node.component

          return (
            <Filter
              key={node.key}
              persistentFilterValues={persistentFilterValues}
              modifiableFilterValues={modifiableFilterValues}
              allFilterValues={allFilterValues}
              setModifiableFilters={setModifiableFilterValues}
            />
          )
        })}
      </div>
    </Portal>
  )
}

export default React.memo(HardwareFilterSidebar)
