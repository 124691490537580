import { UnsavedProjectData, clearUnsavedData, setUnsavedData } from 'projectSections/utils/unsavedDataStore'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { formatSubmitValues } from '../form/formatter'

const Auto_Save_Debounce_Wait = 1000 * 20
const Auto_Save_Debounce_Max_Wait = 1000 * 60

// This hook saves unsaved project changes to local storage.

function debouncedCallback<A extends any[]>(callback: (...args: A) => void, wait: number, maxWait: number) {
  let previousCallTimeStamp: number = 0
  let timeout: any

  return function () {
    const currentTimeStamp = new Date().getTime()
    //@ts-ignore
    const context = this
    const args: any = arguments

    const later = () => {
      previousCallTimeStamp = 0
      callback.apply(context, args)
      timeout = undefined
    }

    if (timeout) {
      clearTimeout(timeout)
      if (currentTimeStamp - previousCallTimeStamp > maxWait) {
        callback.apply(context, args)
        previousCallTimeStamp = currentTimeStamp
        timeout = undefined
      } else {
        timeout = setTimeout(later, wait)
      }
    } else {
      previousCallTimeStamp = currentTimeStamp
      timeout = setTimeout(later, wait)
    }
  }
}

const useLocalAutoSave = (form: any, previousUnsavedData: any) => {
  const location = useLocation()
  const pathnameRef = useRef(location)
  pathnameRef.current = location
  useEffect(() => {
    //recovery auto saved data
    if (previousUnsavedData) {
      // console.log(previousUnsavedData)
      form.batch(() => {
        for (const [key, value] of Object.entries(previousUnsavedData)) {
          if (key === 'design') {
            //@ts-ignore
            form.registerField(key, () => {})
            form.change(key, 'has unsaved change')
          } else {
            //@ts-ignore
            form.registerField(key, () => {})
            form.change(key, value)
          }
        }
      })

      if (previousUnsavedData.design) {
        previousUnsavedData.design = window.CompressionHelper.decompress(previousUnsavedData.design, true)
        if (previousUnsavedData.country_iso2) {
          window.WorkspaceHelper.params.country_iso2 = previousUnsavedData.country_iso2
        }

        if (previousUnsavedData.roof_type_id) {
          window.WorkspaceHelper.params.roofTypeId = previousUnsavedData.roof_type_id
        }

        if (previousUnsavedData.timezone_offset) {
          window.WorkspaceHelper.params.timezoneOffset = previousUnsavedData.timezone_offset
        }

        window.editor.loadScene(previousUnsavedData.design, window.WorkspaceHelper.params)

        //do we need run onLoaded?
        if (!isNaN(previousUnsavedData.projectId as any)) {
          window.WorkspaceHelper.onLoaded(window.editor, true, form.getState().values)
        }
      }
    }
  }, [previousUnsavedData])
  const storeUnsavedData = useCallback(() => {
    const dirtyFields = form.mutators.getFormDirtyFields()
    if (dirtyFields && dirtyFields.length > 0) {
      const values = form.getState().values

      //do we need to store org_id? ??
      const unsavedData: UnsavedProjectData = {
        unsavedData: formatSubmitValues(values, dirtyFields),
        pathname: pathnameRef.current?.pathname,
        orgId: values.org_id,
        address: values.address,
        projectId: values.id,
      }
      try {
        setUnsavedData(unsavedData)
      } catch (error) {
        window.Designer.showNotification(
          window.translate('Auto-save failed: Local storage might already be full.'),
          'warning'
        )
      }
    } else {
      clearUnsavedData()
    }
  }, [])
  return debouncedCallback(storeUnsavedData, Auto_Save_Debounce_Wait, Auto_Save_Debounce_Max_Wait)
}

export default useLocalAutoSave
