import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { authSelectors } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import VimeoPlayer from 'elements/VimeoPlayer'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { reloadEntireApp } from 'util/misc'
import useInAppPromoFiltersData from './hooks/useInAppPromoFiltersData'
import { usePromos } from './hooks/usePromos'
import { IAPModule_ReleaseAlertProgress } from './modules/IAPModule_ReleaseAlertProgress'
import { IAPModule_ReleaseAlertWarning } from './modules/IAPModule_ReleaseAlertWarning'
import { IAPModule_ReleaseMessageProgress } from './modules/IAPModule_ReleaseMessageProgress'
import { IAPModule_ReleaseMessageSuccess } from './modules/IAPModule_ReleaseMessageSuccess'
import { IAPModule_ReleaseMessageWarning } from './modules/IAPModule_ReleaseMessageWarning'
import { IAPModule_UnorderedList } from './modules/IAPModule_UnorderedList'
import { promoConfigType, promoModuleType } from './types'
import { filterPromo } from './utils'

type PropTypes = {}
const keyPrefix = 'os-iap-'

const MIN = 60 * 1000

const InAppPromo: React.FC<PropTypes> = () => {
  const history = useHistory()
  const translate = useTranslate()

  const [matchingPromo, setMatchingPromo] = useState<promoConfigType | undefined>()
  const [minsRemaining, setMinsRemaining] = useState<number | undefined>()
  const [forceCheck, setForceCheck] = useState(0)
  const [showReload, setShowReload] = useState(false)
  const { loaded: actionsLoaded, checkAction, recordAction } = useUserActionsContext()

  const filters = useInAppPromoFiltersData()
  const userId = useSelector(authSelectors.getCurrentUser)?.id || -1
  const { promos: promoConfigs } = usePromos('popup')

  const isMobile = useMediaQuery('(max-width:800px)')

  const matchedPromoTitle = useMemo(() => {
    return matchingPromo ? translate(matchingPromo.title || '', matchingPromo.translationSubs) : null
  }, [matchingPromo])

  const showPromo = (promoConfig: promoConfigType) => {
    setMatchingPromo(promoConfig)
    const title = promoConfig.title ? translate(promoConfig.title, promoConfig.translationSubs) : null
    logAmplitudeEvent('in_app_promo_shown', { title: title, promo_id: promoConfig.id })
  }

  useEffect(() => {
    const debug = window.debugInAppPromos
    if (!promoConfigs) return
    let currentPromo = matchingPromo
    if (matchingPromo) {
      // update promo from server response, including if it goes to null
      currentPromo = promoConfigs.find((promo) => promo.id === currentPromo?.id)

      if (matchingPromo.showReload && matchingPromo.timeShowExpired && (minsRemaining || 0) < 0 && !currentPromo) {
        //TODO: this should be reworked once there is a better way of determining when the update is complete
        trackShown(matchingPromo)
        setShowReload(true)
      } else if (currentPromo) {
        const storageKey = currentPromo ? `${keyPrefix}${currentPromo.id}` : ''

        // When the reload button is shown, we immediately track the user action (before button press)
        // Including the `!showReload` check here prevents the IAP from hiding as soon as the reload button shows
        const alreadyShownSingle = !currentPromo.showMultipleTimes && !showReload && checkAction(storageKey)

        if (
          !dismissed[storageKey] &&
          !alreadyShownSingle &&
          filterPromo(currentPromo, filters, { debug, storageKey })
        ) {
          setMatchingPromo(currentPromo)
        } else {
          setMatchingPromo(undefined)
        }
      } else {
        setMatchingPromo(currentPromo)
      }
    }
    if (!currentPromo) {
      if (debug && promoConfigs.length) console.log('Filtering IAPs: ', promoConfigs, filters)
      for (let i = 0; i < promoConfigs.length; i++) {
        let promoConfig = promoConfigs[i]

        // Check if already shown
        const storageKey = `${keyPrefix}${promoConfig.id}`
        if (storageKey) {
          if (!promoConfig.showMultipleTimes) {
            if (!actionsLoaded) {
              // Don't show until actions are loaded
              if (debug) console.debug('\tPromo Filtered, actions not loaded yet: ', promoConfig.id)
              continue
            }
            if (checkAction(storageKey)) {
              // Promo has already been shown
              if (debug) console.debug('\tPromo Filtered, already shown: ', promoConfig.id, { storageKey })
              continue
            }
          } else if (dismissed[storageKey]) {
            // Promo has already been shown this session
            if (debug)
              console.debug('\tPromo Filtered, already shown this session: ', promoConfig.id, {
                storageKey: storageKey,
              })
            continue
          }
        }

        if (!filterPromo(promoConfig, filters, { debug, storageKey })) {
          continue
        }
        if (debug) console.log('Selected IAP: ', promoConfig.id)
        showPromo(promoConfig)
        break
      }
    } else {
      if (debug) console.log('Retaining current IAP: ', currentPromo)
    }
  }, [promoConfigs, matchingPromo, filters, userId, showReload, actionsLoaded, checkAction, forceCheck])

  const moduleMap = {
    'release-msg-warning': IAPModule_ReleaseMessageWarning,
    'release-msg-progress': IAPModule_ReleaseMessageProgress,
    'release-msg-success': IAPModule_ReleaseMessageSuccess,
    'release-alert-warning': IAPModule_ReleaseAlertWarning,
    'release-alert-progress': IAPModule_ReleaseAlertProgress,
    'unordered-list': IAPModule_UnorderedList,
    ul: IAPModule_UnorderedList,
  }

  const trackShown = (promo: promoConfigType, dismissed = true) => {
    if (!promo) return

    const key = `${keyPrefix}${promo.id}`
    if (userId !== -1) recordAction(key)

    if (dismissed) {
      markDismissed(key)
      setForceCheck(forceCheck + 1) // Because dismissed is not reactive
    }
  }

  const reload = () => {
    reloadEntireApp()
  }

  const dismiss = () => {
    if (!matchingPromo) return

    trackShown(matchingPromo)
    logAmplitudeEvent('in_app_promo_dismissed', { title: matchedPromoTitle, promo_id: matchingPromo.id })
  }

  const onCTAClick = () => {
    trackShown(matchingPromo!)
    logAmplitudeEvent('in_app_promo_accepted', { title: matchedPromoTitle, promo_id: matchingPromo?.id })

    if (matchingPromo?.buttonURL) {
      if (matchingPromo.buttonURL.startsWith('/')) history.push(matchingPromo.buttonURL)
      else {
        window.open(matchingPromo.buttonURL, '_blank')
      }
    }
  }

  useEffect(() => {
    const checkSubs = () => {
      if (!matchingPromo) return

      const now = Date.now()
      const timeShowStart = (matchingPromo.timeShowStart || 0) * 1000
      const timeShowEnd = (matchingPromo?.timeShowEnd || 0) * 1000

      // check if start time has been changed
      if (timeShowStart && timeShowStart > now) {
        setMatchingPromo(undefined)
        return
      }

      // check time remaining
      let remaining: number | undefined = undefined

      if (timeShowStart && matchingPromo.timeDurationMins) {
        remaining = Math.max((timeShowStart + matchingPromo.timeDurationMins * MIN - now) / MIN, 0)
      } else if (timeShowEnd && timeShowEnd > 0) {
        remaining = Math.max((timeShowEnd - now) / MIN, 0)
      }

      if (remaining !== undefined) {
        setMinsRemaining(remaining)

        // Don't clear if time remains, or if timeShowExpired=true
        if (remaining > 0 || matchingPromo.timeShowExpired) {
          if (matchingPromo.showReload) {
            //TODO: this should be reworked once there is a better way of determining when the update is complete
            trackShown(matchingPromo, false)
            setShowReload(true)
          }
        } else {
          setMatchingPromo(undefined)
        }
      }
    }

    const interval = setInterval(checkSubs, MIN / 2)
    checkSubs()
    return () => clearInterval(interval)
  }, [matchingPromo])

  const translationSubs = useMemo(() => {
    let mins = minsRemaining ? Math.ceil(minsRemaining) : undefined
    return {
      timeRemaining: mins ? translate('%{smart_count} minutes', { smart_count: mins }) : '',
      minsRemaining: mins,
      ...matchingPromo?.translationSubs,
    }
  }, [minsRemaining])

  if (!matchingPromo) return null
  return (
    <Dialog open={true}>
      <DialogTitle style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        {matchedPromoTitle}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {matchingPromo.imageURL && (
            <img
              style={{ marginBottom: '20px' }}
              height={matchingPromo.imgHeight || 45}
              width={matchingPromo.imgWidth}
              src={matchingPromo.imageURL}
            />
          )}
          {matchingPromo.videoUrl && <VimeoPlayer videoUrl={matchingPromo.videoUrl} trackingSource="in_app_promo" />}
          {matchingPromo.paragraph1 && <p>{translate(matchingPromo.paragraph1, translationSubs)}</p>}

          {matchingPromo.modules &&
            matchingPromo.modules.map((module) => {
              let Component
              if (typeof module === 'string') {
                Component = moduleMap[module]
                module = { type: module } as promoModuleType
              } else {
                Component = moduleMap[module.type]
              }
              if (!Component) {
                console.warn("Couldn't resolve component for IAP module type:", module)
                return null
              }
              return (
                <Component
                  module={module}
                  promo={matchingPromo}
                  showReload={showReload}
                  minsRemaining={minsRemaining}
                  translationSubs={translationSubs}
                />
              )
            })}

          {matchingPromo.paragraph2 && <p>{translate(matchingPromo.paragraph2, translationSubs)}</p>}
        </div>
      </DialogContent>
      <DialogActions
        style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'center' }}
      >
        {showReload && (
          <div style={{ marginBottom: '15px', marginRight: '15px' }}>
            <ProUXButton
              onClick={reload}
              label={translate(matchingPromo.reloadButtonLabel || 'Reload', translationSubs)}
              type="secondary"
            />
          </div>
        )}

        {!matchingPromo.dismissHide && (
          <div style={{ marginBottom: '15px', marginRight: '15px' }}>
            <ProUXButton
              onClick={dismiss}
              label={translate(matchingPromo.dismissButtonLabel || 'Dismiss', translationSubs)}
              id={'dismiss_promo'}
              type="secondary"
            />
          </div>
        )}

        {matchingPromo?.buttonURL && (
          <div style={{ marginBottom: '15px' }}>
            <ProUXButton
              onClick={onCTAClick}
              label={translate(matchingPromo.buttonLabel || 'Got it, Thanks!', translationSubs)}
              type="primary"
            />
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}
export default InAppPromo

declare global {
  interface Window {
    debugInAppPromos: boolean
  }
}

const dismissed: Record<string, true> = {}
const markDismissed = (storageKey: string) => {
  dismissed[storageKey] = true
}
