import { useMemo, useState } from 'react'
import type { QueryPresenter } from '../query/queryPresenter'
import { Filter } from './filter'
import { FilterPresenter } from './filterPresenter'

const useFilter = ({
  defaultFilter,
  queryPresenter,
}: {
  defaultFilter?: Filter
  queryPresenter: QueryPresenter
}): { filter: Filter; filterPresenter: FilterPresenter } => {
  const [filter, setFilter] = useState(() => new Filter(defaultFilter))
  const filterPresenter = useMemo(() => new FilterPresenter(setFilter, queryPresenter), [])
  return { filter, filterPresenter }
}

export default useFilter
