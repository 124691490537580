import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Popper } from '@material-ui/core'
import { CloseOutlined, FullscreenOutlined } from '@material-ui/icons'
import { IconButton } from 'opensolar-ui'
import panzoom from 'panzoom'
import { CSSProperties, default as React, useEffect, useState } from 'react'

const tester = rankWith(100, and(uiTypeIs('ZoomableImage')))

const Renderer: React.FC<ControlProps> = ({ uischema, visible, enabled }) => {
  const src = uischema['src']
  const style = uischema['style'] || {}
  const [expanded, setExpanded] = useState(false)
  const [imgElement, setImgElement] = useState<HTMLElement | null>(null)

  const popperStyle: CSSProperties = {
    zIndex: 10000,
  }
  const backgroundStyle: CSSProperties = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.5)',
    display: 'flex',
    padding: '50px',
    justifyContent: 'center',
    alignItems: 'stretch',
    boxSizing: 'border-box',
  }
  const expandedImgStyle: CSSProperties = {
    objectFit: 'contain',
  }

  const handleExpand = () => {
    setExpanded(enabled && !expanded)
  }

  useEffect(() => {
    if (expanded && imgElement) {
      var instance = panzoom(imgElement)
      return instance.dispose
    }
  }, [expanded, imgElement])

  if (!visible) return <></>

  const img = <img src={src} style={style} />

  return (
    <>
      <Popper open={expanded} style={popperStyle}>
        <div style={backgroundStyle} onClick={handleExpand}>
          <IconButton
            style={{ position: 'absolute', top: 20, right: 20, background: 'white', zIndex: 1000 }}
            onClick={handleExpand}
          >
            <CloseOutlined />
          </IconButton>
          <div>
            <img
              ref={(e) => setImgElement(e)}
              src={src}
              style={expandedImgStyle}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </Popper>

      <div style={{ position: 'relative', cursor: 'pointer' }} onClick={handleExpand}>
        <IconButton style={{ position: 'absolute', top: 20, right: 20 }} onClick={handleExpand} disabled={!enabled}>
          <FullscreenOutlined />
        </IconButton>
        {img}
      </div>
    </>
  )
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}
