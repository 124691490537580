import { makeStyles } from '@material-ui/core'
import { Button, Checkbox, FieldTitle, Select, SelectProps, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { Theme } from 'types/themes'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  fieldStyle: {
    minWidth: '20%',
  },
  optionStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiIconButton-root:hover': {
      background: 'none',
    },
  },
  popOver: {
    minWidth: 'unset !important',
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      margin: 0,
      marginLeft: 50,
    },
  },
}))

const StyledButton = styled(Button)({
  paddingLeft: 10,
})

export interface ChoiceType<T> {
  id: T
  name: string
}
interface PropsType<T> {
  label?: string | boolean
  selected?: T[]
  choices: ChoiceType<T>[]
  displayLabel?: string
  onChange?: (value: ChoiceType<T>) => void
  customDisplay?: (value) => string
}
export type CheckboxSelectFieldProps<T> = PropsType<T> & SelectProps

const CheckboxSelectField = <T,>(props: CheckboxSelectFieldProps<T>) => {
  const { label, choices, selected = [], displayLabel, onChange, customDisplay, ...selectProps } = props
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.wrapper}>
      {label !== false && <FieldTitle label={label} />}
      <Select
        className={classes.fieldStyle}
        MenuProps={{
          classes: { paper: classes.popOver },
        }}
        {...selectProps}
        value={selected}
        multiple
        native={false}
        renderValue={(value): React.ReactNode => {
          if (value && Array.isArray(value)) {
            if (value?.length === 1) {
              const findItem = choices.find((x) => x.id === value[0])
              return findItem?.name
            } else if (customDisplay) {
              return customDisplay(value)
            } else {
              return `${value?.length} ${translate(displayLabel || 'items')}`
            }
          }
        }}
      >
        <div className={classes.optionStyle}>
          {choices?.map((opt: ChoiceType<T>, i) => (
            <StyledButton
              key={'choice-' + i}
              onClick={() => {
                if (onChange) onChange(opt)
              }}
              variant="text"
              color="default"
            >
              {opt.name}
              <Checkbox checked={selected?.includes(opt.id)} className={classes.checkbox} />
            </StyledButton>
          ))}
        </div>
      </Select>
    </div>
  )
}

export default CheckboxSelectField
