import { PROJECT_ROLES } from 'projectSections/sections/manage3/details/elements/teams/TeamMemberInput'
import { ReactNode } from 'react'
import ActionComplete from './filters/ActionComplete'
import AssignedTeamMember from './filters/AssignedTeamMember'
import BooleanFilter from './filters/BooleanFilter'
import Commission from './filters/Commission'
import ConditionalTextFilter from './filters/ConditionalTextFilter'
import DateFilter from './filters/DateFilter'
import PriceScheme from './filters/PriceScheme'
import Priority from './filters/Priority'
import ProjectShared from './filters/ProjectShared'
import ProjectStage from './filters/ProjectStage'
import ProjectType from './filters/ProjectType'
import ProposalTemplate from './filters/ProposalTemplate'
import RecentlyViewed from './filters/RecentlyViewed'
import SystemSize from './filters/SystemSize'
import Tags from './filters/Tags'
import Workflow from './filters/Workflow'

export const BOOLEAN_CHOICES = [
  { id: true, name: 'Is' },
  { id: false, name: 'Is not' },
]

export const DATE_CHOICES = [
  { id: 0, name: 'Is on or after' },
  { id: 1, name: 'Is on or before' },
]
export interface FilterField {
  fieldId: string | undefined
  value: object
  fields?: any
}

interface ProjectFilter {
  id: string
  name: string
  field: ReactNode
  relatedFields?: string[]
  parseInitialValue?: (filterValues) => object
  props?: object
}
export const OS_PROJECT_FILTERS: ProjectFilter[] = [
  {
    id: 'commissions',
    name: 'Commission Used',
    field: Commission,
    parseInitialValue: (filterValues) => parseConditionalRef('commissions', filterValues),
  },
  {
    id: 'stage_id',
    name: 'Project Stage',
    field: ProjectStage,
    parseInitialValue: (filterValues) => parseConditionalRef('stage_id', filterValues),
  },
  {
    id: 'workflow_id',
    name: 'Project Workflow',
    field: Workflow,
    parseInitialValue: (filterValues) => parseConditionalRef('workflow_id', filterValues),
  },
  {
    id: 'proposal_template',
    name: 'Proposal Template',
    field: ProposalTemplate,
    parseInitialValue: (filterValues) => parseConditionalRef('proposal_template', filterValues),
  },
  {
    id: 'pricingScheme',
    name: 'Pricing Scheme',
    field: PriceScheme,
    relatedFields: ['systems__pricing_scheme', 'systems__pricing_scheme_not'],
    parseInitialValue: (filterValues) =>
      parseDoubleRef(filterValues, 'systems__pricing_scheme', 'systems__pricing_scheme_not'),
  },
  {
    id: 'priority',
    name: 'Priority',
    field: Priority,
    relatedFields: ['priority', 'priority_not'],
    parseInitialValue: (filterValues) => parseDoubleRef(filterValues, 'priority', 'priority_not'),
  },
  {
    id: 'actionComplete',
    name: 'Action',
    field: ActionComplete,
    relatedFields: ['action_complete', 'action_incomplete'],
    parseInitialValue: (filterValues) => parseDoubleRef(filterValues, 'action_complete', 'action_incomplete'),
  },
  {
    id: 'dateSold',
    name: 'Date Sold',
    field: DateFilter,
    relatedFields: ['sold_on_or_after', 'sold_on_or_before'],
    parseInitialValue: (filterValues) => parseDateValues('sold_on_or_after', 'sold_on_or_before', filterValues),
  },
  {
    id: 'dateCreated',
    name: 'Date Created',
    field: DateFilter,
    relatedFields: ['created_date_gte', 'created_date_lte'],
    parseInitialValue: (filterValues) => parseDateValues('created_date_gte', 'created_date_lte', filterValues),
  },
  {
    id: 'dateInstalled',
    name: 'Date Installed',
    field: DateFilter,
    relatedFields: ['installation_date_gte', 'installation_date_lte'],
    parseInitialValue: (filterValues) =>
      parseDateValues('installation_date_gte', 'installation_date_lte', filterValues),
  },
  {
    id: 'dateModified',
    name: 'Date Modified',
    field: DateFilter,
    relatedFields: ['modified_date_gte', 'modified_date_lte'],
    parseInitialValue: (filterValues) => parseDateValues('modified_date_gte', 'modified_date_lte', filterValues),
  },
  {
    id: 'is_unassigned',
    name: 'Unassigned',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Is unassigned' },
        { id: false, name: 'Is not unassigned' },
      ],
    },
  },
  {
    id: 'project_sold',
    name: 'Project Sold',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Is sold' },
        { id: false, name: 'Is not sold' },
      ],
    },
  },
  {
    id: 'project_installed',
    name: 'Project Installed',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Is installed' },
        { id: false, name: 'Is not installed' },
      ],
    },
  },
  {
    id: 'starred',
    name: 'Starred',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Is starred' },
        { id: false, name: 'Is not starred' },
      ],
    },
  },
  {
    id: 'has_systems',
    name: 'System',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Has systems' },
        { id: false, name: 'Does not have systems' },
      ],
    },
  },
  {
    id: 'has_sold_system',
    name: 'Sold System',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Has sold system specified' },
        { id: false, name: 'Does not have sold system specified' },
      ],
    },
  },
  {
    id: 'has_system_without_pricing_scheme',
    name: 'System w/o Pricing Scheme',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Has system without pricing scheme' },
        { id: false, name: 'Does not have system without pricing scheme' },
      ],
    },
  },
  {
    id: 'has_installed_system',
    name: 'Installed System',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Has installed system specified' },
        { id: false, name: 'Does not have an installed system specified' },
      ],
    },
  },
  {
    id: 'has_premium_imagery_activation',
    name: 'Premium Imagery',
    field: BooleanFilter,
    props: {
      choices: [
        { id: true, name: 'Has premium imagery' },
        { id: false, name: 'Does not have premium imagery' },
      ],
    },
  },
  {
    id: 'locality',
    name: 'City',
    field: ConditionalTextFilter,
    parseInitialValue: (filterValues) => parseBaseValue('locality', filterValues),
  },
  {
    id: 'zips',
    name: 'Postcode',
    field: ConditionalTextFilter,
    parseInitialValue: (filterValues) => parseBaseValue('zips', filterValues),
  },
  {
    id: 'states',
    name: 'State',
    field: ConditionalTextFilter,
    parseInitialValue: (filterValues) => parseBaseValue('states', filterValues),
  },
  {
    id: 'lead_source',
    name: 'Lead Source',
    field: ConditionalTextFilter,
    parseInitialValue: (filterValues) => parseBaseValue('lead_source', filterValues),
  },
  {
    id: 'projectType',
    name: 'Project Type',
    field: ProjectType,
    relatedFields: ['is_residential', 'is_lite'],
    parseInitialValue: (filterValues) => {
      let filterStr = filterValues.is_lite !== undefined ? 'is_lite' : ''
      if (filterValues.is_residential !== undefined) {
        filterStr = `${filterStr ? ',' : ''}${filterValues.is_residential ? '' : '-'}is_residential`
      }
      return {
        conditional: filterValues.is_lite || filterValues.is_residential,
        filters: filterStr,
      }
    },
  },
  {
    id: 'assigned',
    name: 'Assigned',
    field: AssignedTeamMember,
    relatedFields: PROJECT_ROLES.map((x) => x.id + '_id'),
    parseInitialValue: (filterValues) => {
      const roleKey = Object.keys(filterValues)[0]
      return {
        roleType: roleKey,
        roleId: filterValues[roleKey],
      }
    },
  },
  {
    id: 'recentlyViewed',
    name: 'Recently Viewed',
    field: RecentlyViewed,
    relatedFields: ['filter', 'filter_out'],
    parseInitialValue: (filterValues) => {
      return {
        conditional: Object.keys(filterValues).includes('filter'),
      }
    },
  },
  {
    id: 'tags',
    name: 'Tags',
    field: Tags,
    relatedFields: ['tagged', 'not_tagged'],
    parseInitialValue: (filterValues) => {
      return {
        conditional: Object.keys(filterValues).includes('tagged'),
        filters: filterValues.tagged || filterValues.not_tagged,
      }
    },
  },

  {
    id: 'projectShared',
    name: 'Project Shared',
    field: ProjectShared,
    relatedFields: ['owner_org_id', 'visible_to'],
    parseInitialValue: (filterValues) => {
      return {
        conditional: Object.keys(filterValues).includes('owner_org_id') ? 0 : 1,
        filters: filterValues.owner_org_id || filterValues.visible_to,
      }
    },
  },
  {
    id: 'systemSize',
    name: 'System Size',
    field: SystemSize,
    relatedFields: ['has_system_larger_than', 'has_system_smaller_than'],
    parseInitialValue: (filterValues) => {
      return {
        conditional: Object.keys(filterValues).includes('has_system_larger_than') ? 0 : 1,
        quantity: filterValues.has_system_larger_than || filterValues.has_system_smaller_than,
      }
    },
  },
]
const fieldChecker = (filterKeys, fields) => fields.some((v) => filterKeys.includes(v))
export const parseFilterValues = (filterValues) => {
  let parsedValues: FilterField[] = []
  const filterKeys = Object.keys(filterValues)
  if (!!filterKeys.length) {
    OS_PROJECT_FILTERS.forEach((filter) => {
      const relatedFields = filter.relatedFields || [filter.id]
      if (fieldChecker(filterKeys, relatedFields)) {
        const relatedValues = relatedFields.reduce((result, filter) => {
          if (filterKeys.includes(filter)) {
            return { ...result, [filter]: filterValues[filter] }
          }
          return result
        }, {})

        parsedValues.push({
          fieldId: filter.id,
          value: relatedValues,
          fields: filter.parseInitialValue ? filter.parseInitialValue(relatedValues) : undefined,
        })
      }
    })
  }
  return parsedValues
}

const parseDateValues = (afterField: string, beforeField: string, filterValues: object) => {
  return {
    conditional: Object.keys(filterValues).includes(afterField) ? 0 : 1,
    date: filterValues[afterField] || filterValues[beforeField],
  }
}

const parseConditionalRef = (fieldKey: string, filterValues: object) => {
  const value = filterValues[fieldKey]
  return {
    conditional: !value.startsWith('-'),
    itemId: parseInt(value.replace('-', '')),
  }
}

const parseDoubleRef = (filterValues: object, trueField: string, falseField: string) => {
  return {
    conditional: Object.keys(filterValues).includes(trueField),
    itemId: filterValues[trueField] || filterValues[falseField],
  }
}

const parseBaseValue = (fieldKey: string, filterValues: object) => {
  const value = filterValues[fieldKey]
  return {
    conditional: !value.startsWith('-'),
    query: value.replace('-', ''),
  }
}

export const getSelectedSortFilter = (orderValue) => ({
  isDescending: orderValue?.includes('-'),
  selectedSortFilter: orderValue?.replaceAll('-', ''),
})
export const composeOrdering = (isOrderDescending, filter) => (isOrderDescending ? '-' : '') + filter
