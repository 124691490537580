import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, HttpError } from 'react-admin'
import appStorage, { getOrgId, setOrg, setOrgId } from 'storage/appStorage'
import { AuthType } from 'types/auth'
import { AUTH_RELOAD, PROCESS_AUTH } from './actions/authActions'
import { convertFileToBase64 } from './restClient'
import { fetchRequest } from './util/fetch'
import { getRoleFromState } from './util/misc'

const onSuccessSaveToLocalStorage = (json, requestExtras) => {
  // If we created a new user, the response will include a populated token so we should remember it
  // But if we were already logged in and creating a new org for the same user then it will not include
  // a token so we should just keep our existing token.
  if (json.token) {
    appStorage.setToken(json.token)
  }

  appStorage.setString('api_key_google', json.api_key_google)

  if (requestExtras && requestExtras.nearmap_token) {
    appStorage.setString('nearmap_token', requestExtras.nearmap_token)
  }

  // if localStorage already contains org_id AND org_id is presend in json.orgs then keep it
  // otherwise use the first match
  let activeOrg
  const orgId = getOrgId()
  const found = orgId ? json.orgs.filter((o) => o.id === orgId) : []

  if (found.length) {
    // keep existing stored org_id
    activeOrg = found[0]
  } else if (json.orgs.length > 0) {
    // no previous org_id found in local storage, use first org as default
    activeOrg = json.orgs[0]
  } else {
    // active org not found
    activeOrg = null
  }

  // Store org fields based on activeOrg
  if (activeOrg) {
    setOrgId(activeOrg.id)
  }

  let activeRole
  const foundRole = orgId ? json.roles.filter((r) => r.org_id === orgId) : []
  if (foundRole.length) {
    activeRole = foundRole[0]
  }

  if (activeRole && activeRole.user_data) {
    const supplier = activeRole.user_data['selected_hardware_supplier']
    if (supplier) {
      appStorage.setString('selected_hardware_supplier', supplier)
    }
  }

  // window.refreshUxVersion()
}

export const getPermissionFromAuthData = (authData: AuthType | undefined) => {
  if (authData === undefined) {
    return []
  }
  const permissions: string[] = []

  if (authData?.is_superuser) {
    permissions.push('is_superuser')
  }

  if (authData?.is_staff) {
    permissions.push('is_staff')
  }

  // authData is not strictly the same as state but it's equivalent in this case
  const role = getRoleFromState({ auth: authData })

  // pros either require an org_id (which signifies they have at least one role) OR they use an NMOS login
  // In theory a user can be logged-in with NMOS but not yet have any role, which gives them time to use their login
  // to setup an org or setup a role for an existing org.

  if (role || (authData?.user && authData?.user?.login_authority === 'nearmap')) {
    permissions.push('is_pro')
  }

  if (role && role.is_admin) {
    permissions.push('is_admin')
  }

  // Add user permission if present
  if (authData?.user) {
    permissions.push('is_user')
  }

  return permissions
}

const isAccessPermissionMissing = () => {
  try {
    const authData = appStorage.getAuth()
    if (authData === undefined) {
      return true
    }

    const role = authData.roles.filter((role) => role.org_id === authData.org_id)[0]
    return !role.permissions
  } catch (err) {
    return true
  }
}

const clearAuthStateFromLocalStorage = () => {
  console.debug('Clearing local auth state')
  appStorage.setAuth()
  appStorage.setToken()
  appStorage.setOrg()
  appStorage.setProjectIdentifiersToken()
  appStorage.clear('user')
  appStorage.clear('orgs')
  appStorage.clear('is_pro')
  appStorage.clear('is_admin')
  appStorage.clear('is_superuser')
  appStorage.clear('is_staff')
  appStorage.clear('api_key_google')
  appStorage.clear('nearmap_token')
  appStorage.clearAllMatching(/os-iap-.*/)
}

export default async (type, params) => {
  let body
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    let request

    if (params.nearmap_token && params.email && params.password) {
      const { email, password, nearmap_token } = params
      body =
        'username=' +
        encodeURIComponent(email) +
        '&password=' +
        encodeURIComponent(password) +
        '&nearmap_token=' +
        encodeURIComponent(nearmap_token)

      if (params.skip_org) {
        body += '&skip_org=' + encodeURIComponent(params.skip_org)
      }

      request = new Request(window.API_ROOT + '/api/nearmap/', {
        method: 'POST',
        body: body,
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
    } else if (params.nearmap_token) {
      const token = appStorage.getToken()
      // Optionally send bearer token too if re-logging in after already being logged in
      const headers =
        params.sendBearerToken && token?.length
          ? new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + token,
            })
          : new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            })

      request = new Request(window.API_ROOT + '/api/nearmap/', {
        method: 'POST',
        body:
          'nearmap_token=' +
          params.nearmap_token +
          '&upgrade_action=' +
          (params.upgrade_action ? params.upgrade_action : '') +
          '&upgrade_org_id=' +
          (params.upgrade_org_id ? params.upgrade_org_id : ''),
        headers: headers,
      })
    } else {
      const { email, password, token, nearmap_token, send_sms, remember } = params
      body = 'username=' + encodeURIComponent(email) + '&password=' + encodeURIComponent(password)
      if (token) {
        body += `&token=${encodeURIComponent(token)}`
      }
      if (nearmap_token && nearmap_token.length > 0) {
        body += '&nearmap_token=' + encodeURIComponent(nearmap_token)
      }
      if (send_sms) {
        body += '&send_sms'
      }
      if (remember) {
        const v = encodeURIComponent(String(remember))
        body += `&remember=${v}`
      }

      // pass the org id that we will try to login to so we can know the MFA requirements for that org
      const orgId = getOrgId()
      if (orgId) {
        body += `&org_id=${orgId}`
      }

      request = new Request(window.API_ROOT + '/api-token-auth/', {
        method: 'POST',
        body: body,
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
    }

    const requestExtras: { [key: string]: any } = {
      credentials: 'include',
    }

    if (params.nearmap_token) {
      // TODO: this is probably an API design bug; nearmap_token will be passed to fetch as a request parameter *and*
      // to the onSuccess callback.
      requestExtras.nearmap_token = params.nearmap_token
    }

    return fetchRequest(request, requestExtras, onSuccessSaveToLocalStorage)
  }

  if (type === PROCESS_AUTH) {
    // requestExtras is undefined
    onSuccessSaveToLocalStorage(params.auth, undefined)
    return
  }

  if (type === AUTH_RELOAD) {
    const orgId = getOrgId()
    const request = new Request(window.API_ROOT + `/api/fetch_token/${orgId ? '?org_id=' + orgId : ''}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + appStorage.getToken(),
      }),
    })

    const ret = fetchRequest(request, undefined, (json, requestExtras) =>
      onSuccessSaveToLocalStorage(json, requestExtras)
    )
    ret.catch((e: HttpError) => {
      if (e.status === 401 || e.status === 403) {
        clearAuthStateFromLocalStorage()
      } else {
        // This can happen when refresh is called during the auth reload call, don't clear auth in this case
        console.error('Failed to reload authentication for unknown reason', e)
      }
    })
    return ret
  }

  if (type === 'AUTH_REGISTER') {
    if (!params) return
    const {
      company_name,
      country_iso2,
      email,
      password,
      exhibit_activation_code,
      phone,
      exhibitDataShareOrgId,
      email_marketing_opt_in,
      logo,
      color_highlight,
      projects_sold_per_month,
      zip,
      user_data,
    } = params
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    // Add Authorization header only when we are already authenticated
    // otherwise we can act as AnonymousUser
    const token = appStorage.getToken()
    if (token) headers['Authorization'] = 'Bearer ' + token

    let logoParam = ''
    if (logo && logo[0]) {
      const picture64 = await convertFileToBase64(logo[0])
      if (picture64) {
        logoParam = '&logo=' + encodeURIComponent(JSON.stringify({ title: logo[0].title, src: picture64 }))
      }
    }

    const colorHighlightParam = color_highlight ? '&color_highlight=' + encodeURIComponent(color_highlight) : ''
    const projectsSoldPerMonthParam = projects_sold_per_month
      ? '&projects_sold_per_month=' + encodeURIComponent(projects_sold_per_month)
      : ''
    const zipParam = zip ? '&zip=' + encodeURIComponent(zip) : ''

    const request = new Request(window.API_ROOT + '/api/register/', {
      method: 'POST',
      body:
        'company_name=' +
        encodeURIComponent(company_name) +
        '&country_iso2=' +
        encodeURIComponent(country_iso2) +
        '&email=' +
        encodeURIComponent(email) +
        logoParam +
        colorHighlightParam +
        projectsSoldPerMonthParam +
        zipParam +
        '&password=' +
        encodeURIComponent(password) +
        '&exhibit_activation_code=' +
        encodeURIComponent(exhibit_activation_code) +
        (phone ? '&phone=' + encodeURIComponent(phone) : '') +
        (user_data ? '&user_data=' + encodeURIComponent(JSON.stringify(user_data)) : '') +
        '&exhibit_data_share_org_id=' +
        encodeURIComponent(exhibitDataShareOrgId || '') +
        (email_marketing_opt_in !== undefined
          ? '&email_marketing_opt_in=' + encodeURIComponent(email_marketing_opt_in)
          : ''),
      headers: new Headers(headers),
    })

    return fetchRequest(request, undefined, onSuccessSaveToLocalStorage)
  }

  if (type === 'PASSWORD_RESET') {
    console.log('PASSWORD_RESET window.API_ROOT', window.API_ROOT)
    const { email } = params
    const request = new Request(window.API_ROOT + '/auth/users/reset_password/', {
      credentials: 'include',
      method: 'POST',
      body: 'email=' + encodeURIComponent(email),
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })

    return fetchRequest(request, undefined)
  }

  if (type === 'PASSWORD_RESET_FORM') {
    console.log('PASSWORD_RESET_FORM window.API_ROOT', window.API_ROOT)
    const { uid, token, new_password } = params
    const request = new Request(window.API_ROOT + '/auth/users/reset_password_confirm/', {
      credentials: 'include',
      method: 'POST',
      body: 'uid=' + uid + '&token=' + token + '&new_password=' + encodeURIComponent(new_password),
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })

    return fetchRequest(request, undefined)
  }

  if (type === 'PASSWORD_CHANGE_FORM') {
    console.log('PASSWORD_CHANGE_FORM window.API_ROOT', window.API_ROOT)
    const { new_password, current_password } = params
    const request = new Request(window.API_ROOT + '/auth/users/set_password/', {
      credentials: 'include',
      method: 'POST',
      body:
        'new_password=' +
        encodeURIComponent(new_password) +
        '&current_password=' +
        encodeURIComponent(current_password),
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + appStorage.getToken(),
      }),
    })

    return fetchRequest(request, undefined)
  }

  if (type === 'EMAIL_CHANGE_FORM') {
    console.log('EMAIL_CHANGE_FORM window.API_ROOT', window.API_ROOT)
    const { new_email, current_password } = params
    const request = new Request(window.API_ROOT + '/auth/users/set_email/', {
      credentials: 'include',
      method: 'POST',
      body: 'new_email=' + encodeURIComponent(new_email) + '&current_password=' + encodeURIComponent(current_password),
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + appStorage.getToken(),
      }),
    })

    return fetchRequest(request, undefined)
  }

  if (type === 'AUTO_LOGIN') {
    const { url_auth_token } = params
    const orgId = getOrgId()
    const request = new Request(
      window.API_ROOT + '/api/fetch_token/?url_auth_token=' + url_auth_token + `${orgId ? '&org_id=' + orgId : ''}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      }
    )

    return fetchRequest(request, undefined, (res) => onSuccessSaveToLocalStorage(res, params))
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    // Fire-and-forget a call to POST /logout/ to inform the backend that the current token should be invalidated.
    const token = appStorage.getToken()
    if (token) {
      fetchRequest(
        new Request(window.API_ROOT + '/api/logout/', {
          method: 'POST',
          headers: new Headers({
            Authorization: 'Bearer ' + token,
          }),
        })
      ).catch((err) => {
        if (err.status === 401) {
          console.debug(`Unauthorised token, probably already called logout, ignoring error`, err)
        } else {
          console.error('Error logging out: ', err)
        }
      })
    }

    clearAuthStateFromLocalStorage()

    // org_id is retained in local storage to allow selecting the previous org automatically
    // when logging in again
    // appStorage.clear('org_id')
    document.title = 'OpenSolar'

    setOrg(undefined)

    // window.refreshUxVersion()

    return Promise.resolve()
  }

  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status, body } = params
    if (status === 403 && body?.permission_error) {
      // stay logged-in if response contain permission error
    } else if (status === 401 || status === 403) {
      clearAuthStateFromLocalStorage()

      // org_id is retained in local storage to allow selecting the previous org automatically
      // when logging in again
      // appStorage.clear('org_id')

      setOrg(undefined)

      // window.refreshUxVersion()

      return Promise.reject()
    }
    return Promise.resolve()
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    // Token always required
    if (!appStorage.getToken()) {
      return Promise.reject()
    }

    // TODO: should access auth data from redux
    const authData = appStorage.getAuth()
    const permissions = getPermissionFromAuthData(authData)

    if (params.permission) {
      if (permissions.indexOf(params.permission) !== -1) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } else {
      const isEndCustomer = permissions.indexOf('is_pro') === -1
      if (isEndCustomer || isAccessPermissionMissing()) {
        //ask end customer to log in
        return Promise.reject()
      }
      // permissions not specified, permit
      return Promise.resolve()
    }
  }

  if (type === AUTH_GET_PERMISSIONS) {
    // TODO: should access auth data from redux
    const authData = appStorage.getAuth()
    const permissions = getPermissionFromAuthData(authData)
    return Promise.resolve(permissions)
  }

  return Promise.reject('Unknown method')
}
