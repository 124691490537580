import { PrivateFileType } from 'types/privateFile'

const FILE_TYPE_TO_TYPE_MAP = {
  pdf: { fileType: 'pdf', hasPreview: true },
  jpg: { fileType: 'img', hasPreview: true },
  jpeg: { fileType: 'img', hasPreview: true },
  png: { fileType: 'img', hasPreview: true },
  gif: { fileType: 'img', hasPreview: true },
  svg: { fileType: 'img', hasPreview: true },
  webp: { fileType: 'img', hasPreview: true },
  bmp: { fileType: 'img', hasPreview: true },
  tiff: { fileType: 'img', hasPreview: true },
  tif: { fileType: 'img', hasPreview: true },
  docx: { fileType: 'doc', hasPreview: false },
  doc: { fileType: 'doc', hasPreview: false },
  xlsx: { fileType: 'sheet', hasPreview: false },
  xls: { fileType: 'sheet', hasPreview: false },
  csv: { fileType: 'sheet', hasPreview: false },
  tsv: { fileType: 'sheet', hasPreview: false },
  other: { fileType: 'other', hasPreview: false },
}

const imgFileExtensions = Object.keys(FILE_TYPE_TO_TYPE_MAP).filter((t) => FILE_TYPE_TO_TYPE_MAP[t].fileType === 'img')

export const imgTypeExtensionsAsString = imgFileExtensions.map((e) => '.' + e).join()

const getFileExtension = ({ file_contents }: PrivateFileType): string => {
  const urlWithoutParams = file_contents.split('?AWS')[0]
  const extension = urlWithoutParams.split('.').pop()
  return extension ?? ''
}

export const generateLink = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  //@ts-ignore
  link.parentNode.removeChild(link)
}

export const downloadFile = (record: PrivateFileType) => {
  const { file_contents, title } = record
  if (window.RUNNING_AS_APP) {
    const ext = getFileExtension(record)
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'downloadFile', file_contents, title, fileType: ext }))
  } else {
    fetch(file_contents).then(function (t) {
      return t.blob().then((b) => {
        generateLink(b, title)
      })
    })
  }
}

export const previewInfo = (record: PrivateFileType) => {
  const ext = getFileExtension(record)
  return FILE_TYPE_TO_TYPE_MAP[ext] || FILE_TYPE_TO_TYPE_MAP.other
}
