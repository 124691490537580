import { Typography } from '@material-ui/core'

import { countryMapping } from 'constants/countries'
import { values } from 'lodash'
import { useNotify } from 'ra-core'
import { memo, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete.js'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import AddressCompletionForm from '../AddressCompletionForm'
import { CheckoutPresenterContext, ShippingAddressContext } from './HdmCheckoutProvider'

const useAddressFieldStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clickableLink: {
    textDecoration: 'underline',
    color: '#1751D0',
    cursor: 'pointer',
  },
}))

const AddressField = ({ handleUpdateShippingAddress }) => {
  const notify = useNotify()

  const hdmCheckoutPresenter = useContext(CheckoutPresenterContext)
  const address = useContext(ShippingAddressContext)
  const { contactName, companyName, country, county, postCode, address1, town } = address || {}
  const [editing, setEditing] = useState<boolean>(false)
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true)
  const classes = useAddressFieldStyles()
  const apiKeyGoogle = useSelector((state: RootState) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )

  const handleClick = () => {
    setEditing(!editing)
    setIsValidAddress(true)
  }

  const onPlaceSelected = (placeAsFields) => {
    const newShippingAddress = {
      ...(address || {}),
      address1: placeAsFields.address,
      town: placeAsFields.locality,
      county: placeAsFields.state,
      postCode: placeAsFields.zip,
      country: countryMapping[placeAsFields.country_iso2]?.name,
    }
    const isValidShippingAddress = hdmCheckoutPresenter?.validateShippingAddress(newShippingAddress)
    if (!isValidShippingAddress) {
      notify(
        'Your preferred address was not able to be automatically populated. Please complete your address manually',
        'warning'
      )
    }

    setEditing(!isValidShippingAddress)
    setIsValidAddress(!!isValidShippingAddress)
    handleUpdateShippingAddress(placeAsFields)
  }

  return (
    <div className={classes.container}>
      {editing ? (
        <div style={{ flexGrow: 1, paddingRight: 50 }}>
          {isValidAddress ? (
            <AddressAutocomplete onPlaceSelected={onPlaceSelected} api_key_google={apiKeyGoogle} types={['address']} />
          ) : (
            <AddressCompletionForm
              handleUpdateAddress={onPlaceSelected}
              initialValues={{
                address: address1 || '',
                locality: town || '',
                state: county || '',
                zip: postCode || '',
                country_iso2: values(countryMapping)?.find((item) => item.name === country)?.iso2 || 'GB',
              }}
              requiredFields={['address', 'locality', 'state', 'zip', 'country_iso2']}
            />
          )}
        </div>
      ) : (
        <div>
          {contactName && (
            <Typography variant="body1" gutterBottom>
              {contactName}
            </Typography>
          )}
          {companyName && (
            <Typography variant="body1" gutterBottom>
              {companyName}
            </Typography>
          )}
          <Typography variant="body1" gutterBottom>
            {address1}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {county} {town} {postCode} {country}
          </Typography>
        </div>
      )}
      <Typography variant="body1" gutterBottom className={classes.clickableLink} onClick={handleClick}>
        {editing ? 'cancel' : 'enter/update address'}
      </Typography>
    </div>
  )
}

const ShippingAddress = () => {
  const hdmCheckoutPresenter = useContext(CheckoutPresenterContext)
  const address = useContext(ShippingAddressContext)

  const handleUpdateShippingAddress = (placeAsFields) => {
    const newShippingAddress = {
      ...(address || {}),
      address1: placeAsFields.address,
      town: placeAsFields.locality,
      county: placeAsFields.state,
      postCode: placeAsFields.zip,
      country: countryMapping[placeAsFields.country_iso2]?.name,
    }
    hdmCheckoutPresenter?.updateShippingAddress(newShippingAddress)
    const isValidShippingAddress = hdmCheckoutPresenter?.validateShippingAddress(newShippingAddress)

    hdmCheckoutPresenter?.updateOrderState({ key: 'isReady', value: isValidShippingAddress })
  }

  return (
    <div>
      <AddressField handleUpdateShippingAddress={handleUpdateShippingAddress} />
    </div>
  )
}

export default memo(ShippingAddress)
