import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@material-ui/core'
import { CloseOutlined, Info } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { SelectInput, TextInput, useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { STATE_CHOICES } from './constants'

type PropTypes = {
  onClose: () => void
  projectId: number
}

const useStyles = makeOpenSolarStyles((theme) => ({
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyLight1,
  },
  closeButton: {
    color: theme.greyMid1,
  },
  buttonsRow: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
  field: {
    margin: '5px 0',
  },
  label: {
    marginTop: 0,
  },
  icon: {
    color: theme.greyMid1,
    fontSize: 20,
    margin: '10px 0px 10px 6px',
    cursor: 'pointer',
  },
  taxIdWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const EditInvoiceDetailsDialog: React.FC<PropTypes> = (props) => {
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const classes = useStyles()
  const form = useForm()
  const translate = useTranslate()
  const notify = useNotify()
  const project = useFormState()?.values
  const contactData = project.contacts_data

  const orgId = useSelector(authSelectors.getOrgId)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [invoiceDetails, setInvoiceDetails] = useState({
    billing_address_line_1: null,
    billing_address_line_2: null,
    billing_address_city: null,
    billing_address_state: null,
    billing_address_country: null,
    billing_address_postal_code: null,
    recipient_email: null,
    recipient_first_name: null,
    recipient_last_name: null,
    recipient_tax_id: null,
  })

  useEffect(() => {
    if (!orgId) return
    setIsLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/invoices/project_settings/${props.projectId}/`,
    })
      .then((res) => {
        setInvoiceDetails(res.data)
        setIsLoading(true)
      })
      .catch((err) => {
        notify(err, 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const onPageSubmit = () => {
    if (!orgId) return
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/invoices/project_settings/${props.projectId}/`,
      data: invoiceDetails,
    })
      .then((res) => {
        form.change('invoice_details', invoiceDetails)
        props.onClose()
        notify('Invoice details successfully updated', 'success')
      })
      .catch((err) => {
        notify(err, 'warning')
      })
  }

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle>
        <div className={classes.titleWrapper}>
          <div>{translate('Edit invoice details')}</div>
          <div>
            <IconButton onClick={props.onClose}>
              <CloseOutlined className={classes.closeButton} />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <p className={classes.label}>
                {translate(
                  'Use the fields below to edit invoice information. Any changes implemented will apply to all future invoices generated for this project.'
                )}
              </p>
            </Grid>
            <Grid item xs={6}>
              <CustomField
                className={classes.field}
                size="small"
                name="recipient_first_name"
                label={translate('First Name')}
                variant="outlined"
                fullWidth
                component={TextInput}
                defaultValue={invoiceDetails?.recipient_first_name || contactData?.[0]?.first_name}
                value={invoiceDetails?.recipient_first_name || contactData?.[0]?.first_name}
                onKeyUp={(e) => {
                  setInvoiceDetails({ ...invoiceDetails, recipient_first_name: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                className={classes.field}
                size="small"
                name="recipient_last_name"
                label={translate('Last Name')}
                variant="outlined"
                fullWidth
                component={TextInput}
                defaultValue={invoiceDetails?.recipient_last_name || contactData?.[0]?.family_name}
                value={invoiceDetails?.recipient_last_name || contactData?.[0]?.family_name}
                onKeyUp={(e) => {
                  setInvoiceDetails({ ...invoiceDetails, recipient_last_name: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomField
                className={classes.field}
                size="small"
                name="recipient_email"
                label={translate('Email address')}
                variant="outlined"
                fullWidth
                component={TextInput}
                defaultValue={invoiceDetails?.recipient_email || contactData?.[0]?.email}
                value={invoiceDetails?.recipient_email || contactData?.[0]?.email}
                onKeyUp={(e) => {
                  setInvoiceDetails({ ...invoiceDetails, recipient_email: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <p className={classes.label}>
                {translate(
                  'OpenSolar uses the site address as the customer address by default. Update the address here to change the address that appears on the invoice'
                )}
              </p>
            </Grid>
            <Grid item xs={12}>
              <CustomField
                className={classes.field}
                size="small"
                name="billing_address_line_1"
                label={translate('Address')}
                variant="outlined"
                fullWidth
                component={TextInput}
                defaultValue={invoiceDetails?.billing_address_line_1 || project.address}
                value={invoiceDetails?.billing_address_line_1 || project.address}
                onKeyUp={(e) => {
                  setInvoiceDetails({ ...invoiceDetails, billing_address_line_1: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                className={classes.field}
                size="small"
                name="billing_address_city"
                label={translate('City')}
                variant="outlined"
                fullWidth
                component={TextInput}
                defaultValue={invoiceDetails?.billing_address_city || project.locality}
                value={invoiceDetails?.billing_address_city || project.locality}
                onKeyUp={(e) => {
                  setInvoiceDetails({ ...invoiceDetails, billing_address_city: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                className={classes.field}
                size="small"
                name="billing_address_state"
                label={translate('State')}
                variant="outlined"
                fullWidth
                component={SelectInput}
                choices={STATE_CHOICES}
                style={{ width: '100%' }}
                defaultValue={invoiceDetails?.billing_address_state || project.state}
                value={invoiceDetails?.billing_address_state || project.state}
                inputProps={{
                  onChange: (e) => {
                    setInvoiceDetails({ ...invoiceDetails, billing_address_state: e.target.value })
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomField
                className={classes.field}
                size="small"
                name="billing_address_postal_code"
                label={translate('Zip Code')}
                variant="outlined"
                fullWidth
                component={TextInput}
                defaultValue={invoiceDetails?.billing_address_postal_code || project.zip}
                value={invoiceDetails?.billing_address_postal_code || project.zip}
                onKeyUp={(e) => {
                  setInvoiceDetails({ ...invoiceDetails, billing_address_postal_code: e.target.value })
                }}
              />
            </Grid>
            {!project?.is_residential && (
              <Grid item xs={6}>
                <div className={classes.taxIdWrapper}>
                  <CustomField
                    className={classes.field}
                    size="small"
                    name="recipient_tax_id"
                    label={translate('Tax ID')}
                    variant="outlined"
                    fullWidth
                    component={TextInput}
                    defaultValue={invoiceDetails?.recipient_tax_id}
                    value={invoiceDetails?.recipient_tax_id}
                    onKeyUp={(e) => {
                      setInvoiceDetails({ ...invoiceDetails, recipient_tax_id: e.target.value })
                    }}
                  />
                  <Tooltip
                    title={translate(
                      "Customer's Tax ID may be recommended to include for certain types of projects, like commercial projects in some regions."
                    )}
                    enterDelay={300}
                  >
                    <Info className={classes.icon} />
                  </Tooltip>
                </div>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonsRow}>
          <div>
            <Button onClick={props.onClose} color="primary" variant="outlined">
              {translate('Cancel')}
            </Button>
          </div>
          <div>
            <Button color="primary" variant="contained" onClick={onPageSubmit}>
              {translate('Save')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default EditInvoiceDetailsDialog
