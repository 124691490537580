import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { hideFullScreenLoader, markCreditAppAsOpened, myEnergySelectors } from 'ducks/myEnergy'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { FinanceCtaType } from 'resources/financeCTAs/constants'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { getCommonCreditDecision } from 'util/misc'
import { TransactionActionButton } from '../../../elements/TransactionStartButton'
import { ActionDataStatusCode, ActionDataType } from '../types'
import { removeCreditAppParams } from '../util'
import ApplicationIframeDialog from './ApplicationIframeDialog'
import HostedCreditApplicationDialog from './hostedForms/HostedCreditApplicationDialog'
import { useInitialValuesByIntegration } from './hostedForms/initialValueSetters'
import { HostedDisclosureType } from './types'
import { doFormRedirect } from './utils'

var restClientInstance = restClient(window.API_ROOT + '/api')

type IframeResponseType = {
  data: {
    success: boolean
    url: string | undefined
    contents: string | undefined
    form_request_body: Object | undefined
  }
}

type PropTypes = {
  actionData: ActionDataType
  translate: (raw: string) => string
  showDialog: boolean
  setWaitingForAction: Function
  myeStyles: CSSProperties
  acceptButtonStyle: CSSProperties
  acceptButtonStyleDisabled: CSSProperties
  isPro: boolean
  selectedPaymentOptionName: string
  paymentOptionData: PaymentOptionType
  selectedProject: ProjectType
  onDecisionRendered: () => void
  loanpalDecisionRendered: boolean
  selectedPaymentOptionId: number
}
const GenericIntegratedFinanceApplicationButton: React.FunctionComponent<PropTypes> = (props) => {
  const {
    actionData,
    myeStyles,
    acceptButtonStyle,
    acceptButtonStyleDisabled,
    paymentOptionData,
    selectedPaymentOptionId,
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [iframeURL, setIframeURL] = useState<undefined | string>(undefined)
  const [iframeContents, setIframeContents] = useState<undefined | string>(undefined)
  const [initialStatus, setInitialStatus] = useState<ActionDataStatusCode>(actionData.status_code)
  const [showHostedApplication, setShowHostedApplication] = useState<boolean>(false)
  const [hostedDisclosures, setHostedDisclosures] = useState<HostedDisclosureType[]>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)
  const isPro = !!useSelector(authSelectors.getCurrentRole)
  const dispatch = useDispatch()
  const translate = useTranslate()
  const notify = useNotify()

  // if the user just returned from docusign but the sold_pmt_option param that we add for OpenSolar contracts isn't present
  // then we can safely say the user just signed a lender contract in DocuSign
  // so forcibly disable the button since we probably haven't heard from the lender that the doc is completed yet
  useEffect(() => {
    if (window.location.href.includes('signing_complete') && !window.location.href.includes('sold_pmt_option')) {
      setIsDisabled(true)
    }
  }, [])

  useEffect(() => {
    if (forceOpenCreditApp && selectedPaymentOptionId === paymentOptionData?.id) {
      openApplication()
      removeCreditAppParams()
    }
  }, [forceOpenCreditApp, selectedPaymentOptionId])

  useEffect(() => {
    if (initialStatus === 'available' && actionData?.status_code !== 'available' && actionData?.status_title) {
      logAmplitudeEvent('integrated_finance_application_submitted', {
        integration: actionData?.finance_integration,
        decision: getCommonCreditDecision(actionData?.status_title),
        payment_option_id: props.selectedPaymentOptionId,
        system_id: props.actionData?.system_id,
      })
    }
  }, [initialStatus, actionData.status_code, actionData.status_title])

  let extra_actions = [] as JSX.Element[]
  const orgId = props.paymentOptionData?.org_id ? props.paymentOptionData?.org_id : actionData.org_id

  const logOpenSuccess = () => {
    logAmplitudeEvent('integrated_finance_application_opened', {
      integration: actionData?.finance_integration,
      project_id: props.selectedProject?.id,
      payment_option_id: props.selectedPaymentOptionId,
      system_id: props.actionData?.system_id,
    })
  }

  const getIframeFromOpenSolarAPI = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/projects/${actionData.project_id}/systems/${actionData.system_uuid}/${actionData.payment_option_id}/application_iframe/`,
    })
      .then(
        (response: IframeResponseType) => {
          if (response.data.url && response?.data.form_request_body) {
            doFormRedirect(response.data.url, response.data.form_request_body)
          } else if (response?.data?.url) {
            setIframeURL(response.data.url)
          } else if (response.data.contents) {
            setIframeContents(response.data.contents)
          }
          logOpenSuccess()
        },
        (reject: any) => {
          console.log(reject)
          notify(translate(reject.message), 'warning')
        }
      )
      .catch((err) => {
        if (err?.body?.message) {
          notify(translate(err.body.message), 'warning')
          logAmplitudeEvent('integrated_finance_application_open_error', {
            integration: actionData?.finance_integration,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        dispatch(hideFullScreenLoader())
        dispatch(markCreditAppAsOpened())
      })
  }

  const getLenderDocsURL = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/projects/${actionData.project_id}/systems/${actionData.system_uuid}/${actionData.payment_option_id}/lender_docs/`,
    })
      .then(
        (response: IframeResponseType) => {
          if (response.data.url) {
            window.location.href = response.data.url
          }
          logOpenSuccess()
        },
        (reject: any) => {
          console.log(reject)
          notify(translate(reject.message), 'warning')
        }
      )
      .catch((err) => {
        if (err?.body?.message) {
          notify(translate(err.body.message), 'warning')
          logAmplitudeEvent('integrated_finance_application_open_error', {
            integration: actionData?.finance_integration,
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        dispatch(hideFullScreenLoader())
        dispatch(markCreditAppAsOpened())
      })
  }

  const getApplicationDisclosures = () => {
    setShowHostedApplication(true)
    setIsLoading(true)
  }

  const doApiSideEffect = () => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/finance/${actionData.finance_integration}/cta_side_effect/`,
      data: {
        project_id: actionData.project_id,
        system_uuid: actionData.system_uuid,
        payment_option_id: actionData.payment_option_id,
        cta_id: actionData.cta_id,
      },
    })
      .then((res) => console.log('res', res))
      .catch((err) => console.log('err', err))
  }

  const openApplication = () => {
    if (props.actionData.application_url) {
      logOpenSuccess()
      setIframeURL(props.actionData.application_url)
    } else if (props.actionData.cta_type === 'submit_credit_application_hosted') {
      getApplicationDisclosures()
    } else if (
      props.actionData.cta_type === FinanceCtaType.FETCH_IFRAME_URL ||
      props.actionData.cta_type === FinanceCtaType.FORM_REDIRECT ||
      props.actionData.cta_type === FinanceCtaType.SUBMIT_CREDIT_APPLICATION_IFRAME
    ) {
      getIframeFromOpenSolarAPI()
    } else if (props.actionData.cta_type === FinanceCtaType.VIEW_LENDER_DOCS) {
      getLenderDocsURL()
    }

    if (props.actionData.has_api_side_effect) {
      doApiSideEffect()
    }
  }

  if (actionData.status_description) {
    extra_actions.push(
      <p className="small" style={{ textAlign: 'center' }} key={extra_actions.length}>
        {actionData.status_description}
      </p>
    )
  }

  const onClose = () => {
    setIframeURL(undefined)
    setIframeContents(undefined)
  }

  const initialValues = useInitialValuesByIntegration(actionData?.finance_integration, props.selectedProject)

  if (isPro && !props.actionData.is_pro_facing) return null
  else if (!isPro && !props.actionData.is_customer_facing) return null
  return (
    <>
      <TransactionActionButton
        label={
          <div>
            {isLoading ? (
              <LoadingDots color="#fff" />
            ) : (
              <>
                {actionData.status_title}
                {actionData?.status_title_secondary && (
                  <div style={{ fontSize: 11 }}>{actionData?.status_title_secondary}</div>
                )}
              </>
            )}
          </div>
        }
        disabled={actionData.status_code !== 'available' || props.loanpalDecisionRendered || isDisabled}
        myeStyles={myeStyles}
        labelStyle={{ padding: 0 }}
        buttonStyle={{
          height: 39,
          ...(actionData.status_code === 'available' && !props.loanpalDecisionRendered && !isDisabled
            ? acceptButtonStyle
            : acceptButtonStyleDisabled),
        }}
        action={openApplication}
        extra_actions={extra_actions}
      />
      {(iframeContents || iframeURL) && (
        <ApplicationIframeDialog
          url={iframeURL}
          contents={iframeContents}
          isOpen={true}
          onClose={onClose}
          paymentOptionTitle={props.selectedPaymentOptionName}
        />
      )}
      {showHostedApplication && hostedDisclosures && actionData.finance_integration && (
        <HostedCreditApplicationDialog
          onClose={() => setShowHostedApplication(false)}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          orgId={orgId}
          projectId={actionData.project_id}
          systemUuid={actionData.system_uuid}
          paymentOptionId={actionData.payment_option_id}
          integration={actionData.finance_integration}
          project={props.selectedProject}
          initialValues={initialValues}
        />
      )}
    </>
  )
}
export default GenericIntegratedFinanceApplicationButton
