import { makeStyles } from '@material-ui/core'
import { Button, DeleteOutlineIcon, EditOutlineIcon, IconButton } from 'opensolar-ui'
import { getProjectSectionStyles, IconButtonTooltip } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { EventActions } from 'types/events'
import { StageActionEventType } from 'types/projects'
import { Theme } from 'types/themes'
import { EVENT_FORM_VARIATIONS } from '../util'
const useStyles = makeStyles((theme: Theme) => ({
  actionsWrapper: {
    marginLeft: 'auto',
  },
  baseBtn: {
    margin: '0 !important',
  },
}))

interface PropTypes {
  event: StageActionEventType
  toggleModal: (event: StageActionEventType) => void
  toggleDelete: (event: StageActionEventType) => void
  minDisplay?: boolean
}

const ActionButtons: React.FC<PropTypes> = ({ event, toggleModal, toggleDelete, minDisplay }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const formConfigActions = EVENT_FORM_VARIATIONS[event.form_config]?.actions
  return (
    <div className={`${sectionStyles.row} ${classes.actionsWrapper}`}>
      {formConfigActions?.includes(EventActions.edit) && (
         <IconButtonTooltip title={translate('Edit')}>
          {minDisplay ? (
              <IconButton
                color="secondary"
                size="small"
                onClick={() => {
                  toggleModal(event)
                }}
              >
                <EditOutlineIcon variant={2} />
              </IconButton>

          ) : (
              <Button
                onClick={() => {
                  toggleModal(event)
                }}
                variant="text"
                size="small"
                color="info"
                className={classes.baseBtn}
                startIcon={<EditOutlineIcon variant={2} />}
              >
                {translate('Edit')}
              </Button>
          )}
         </IconButtonTooltip>
      )}
      {formConfigActions?.includes(EventActions.edit) && (
         <IconButtonTooltip title={translate('Delete')}>
         {minDisplay ? (
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                toggleDelete({ ...event, is_archived: true })
              }}
              style={{ marginLeft: 5 }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          ) : (
            <Button
              onClick={() => {
                toggleDelete({ ...event, is_archived: true })
              }}
              variant="text"
              size="small"
              color="error"
              className={classes.baseBtn}
              startIcon={<DeleteOutlineIcon />}
            >
              {translate('Delete')}
            </Button>
          )}
         </IconButtonTooltip>
      )}
    </div>
  )
}

export default ActionButtons
