import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { createContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useSolarOutletService from 'services/outlet/useSolarOutletService'
import { OrgType } from 'types/orgs'
import CheckoutPresenter from './CheckoutPresenter'
import { ShippingDetailType } from './type'

const defaultShippingDetail: ShippingDetailType = {
  companyName: '',
  contactName: '',
  contactNumber: '',
  contactAddress: {
    address: '',
    locality: '',
    state: '',
    zip: '',
    countryName: '',
    countryIso2: '',
  },
  shippingMethod: '',
  fee: '',
  deliveryInstructions: '',
}

export const CheckoutPresenterContext = createContext<CheckoutPresenter | undefined>(undefined)
export const ShippingDetailContext = createContext<ShippingDetailType>(defaultShippingDetail)
export const ShippingFeeFetchContext = createContext<boolean>(false)

const CheckoutProvider = ({ children }) => {
  const org = useSelector(orgSelectors.getOrg)
  const user = useSelector(authSelectors.getCurrentUser)
  const role = useSelector(authSelectors.getCurrentRole)
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)
  const solarOutletService = useSolarOutletService()
  let contactName = `${role?.first_name} ${role?.family_name}`
  if (contactName.trim() === '') {
    contactName = user?.email || 'Unknown name'
  }
  const [ShippingDetail, setShippingDetail] = useState<ShippingDetailType>({
    ...defaultShippingDetail,
    companyName: org?.name || 'Unknown',
    contactName: contactName,
    contactNumber: org?.sales_phone_number || '',
    contactAddress: {
      address: org?.address || '',
      locality: org?.locality || '',
      state: org?.state || '',
      zip: org?.zip || '',
      countryName: org?.country?.name || '',
      countryIso2: org?.country?.iso2 || '',
    },
    fee: 'TBD',
    shippingMethod: 'commercial_delivery',
  })
  const [isFetchingShippingFee, setIsFetchingShippingFee] = useState<boolean>(false)
  const checkoutPresenter = useMemo(
    () =>
      new CheckoutPresenter(org as OrgType, lineItems, solarOutletService, setShippingDetail, setIsFetchingShippingFee),
    []
  )

  return (
    <ShippingDetailContext.Provider value={ShippingDetail}>
      <ShippingFeeFetchContext.Provider value={isFetchingShippingFee}>
        <CheckoutPresenterContext.Provider value={checkoutPresenter}>{children}</CheckoutPresenterContext.Provider>
      </ShippingFeeFetchContext.Provider>
    </ShippingDetailContext.Provider>
  )
}

export default CheckoutProvider
