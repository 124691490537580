import { currencySymbolForCountry, getTemplateSettings } from '../util/misc'
import { getIsPhoenixV2 } from './elements/phoenix/util'

const DEFAULT_Y_AXIS_SCALING_MARGIN_FACTOR = 1.1

const getPaymentOptionById = (id, paymentOptions, fallbackToFirst) => {
  const matches = paymentOptions.filter((paymentOption) => paymentOption.id === id)
  if (matches.length > 0) {
    return matches[0]
  } else if (fallbackToFirst === true) {
    return paymentOptions[0]
  } else {
    return null
  }
}

const getCashFlowAxisRangeAllSystemsAndPaymentOptions = (
  systems,
  valueKey,
  YAxisMarginFactor = DEFAULT_Y_AXIS_SCALING_MARGIN_FACTOR
) => {
  const getMaxOrMin = (func) =>
    func.apply(
      null,
      systems.map((s) =>
        func.apply(
          null,
          s.payment_options.map((p) =>
            func.apply(
              null,
              p[valueKey].map((v) => v)
            )
          )
        )
      )
    )

  const maxValue = getMaxOrMin(Math.max)
  const minValue = getMaxOrMin(Math.min)

  // We need to make sure we create some padding on the margins for both upper and lower YAxis on a chart.
  // This is required to ensure any chart labels and text do not get cut off.
  const factorIncreaseYAxisMargin = YAxisMarginFactor - 1
  const marginAdjustment = Math.max(Math.abs(maxValue), Math.abs(minValue)) * factorIncreaseYAxisMargin

  return {
    maxYAxis: maxValue + marginAdjustment,
    minYAxis: minValue - marginAdjustment,
  }
}

const getBillSavingsAxisRangeAllSystemsAndPaymentOptions = (systems) => {
  // Previously we only needed to iterate over systems, not payment options because no impact due to payment option
  // But now the regular_payment type impacts this chart so we need to iterate over payment options too.
  const getMaxOrMin = (func, key) =>
    func.apply(
      null,
      systems.map((s) =>
        func.apply(
          null,
          s.payment_options.map((po) =>
            func.apply(
              null,
              Object.values(s.bills.proposed).map((proposed) =>
                window.Designer.LifeTimeSavingsMaxMagnitudeY(
                  s.bills.current.bills_yearly,
                  proposed.bills_yearly,
                  po.payment_type === 'regular_payment' ? po.cash_flows_payments.slice(1) : null
                )[key]
              )
            )
          )
        )
      )
    )

  return {
    maxYAxis: getMaxOrMin(Math.max, 'maxYAxis'),
    minYAxis: getMaxOrMin(Math.min, 'minYAxis'),
  }
}

export default function prepareProposalData(props) {
  const {
    proposalTemplateSettings,
    org,
    selectedProject,
    systems,
    selectedSystem,
    otherDesigns,
    otherAccounts,
    viewUuids,
    selectedViewUuid,
    selectedPaymentOptionId,
    myeStyles,
    fullCalcReady,
  } = props

  if (!selectedSystem) {
    return null
  }

  // Use country on design if set, otherwise fallback to org country
  const countryIso2 =
    selectedProject && selectedProject.country_iso2 && selectedProject.country_iso2.length > 0
      ? selectedProject.country_iso2
      : org
      ? org.country_iso2
      : null

  const currencySymbol = currencySymbolForCountry(countryIso2)

  const selectedPaymentOption =
    selectedSystem.payment_options && selectedSystem.payment_options.length
      ? getPaymentOptionById(selectedPaymentOptionId, selectedSystem.payment_options, true)
      : null

  //@TODO: Fix this hack: Promote the relevant    bills['utility_tariff_id'] => bills    for selected payment option
  //We must detect selectedPaymentOption first so we can detect utility_tariff_id to select the correct proposed bills
  systems.forEach((s) => {
    //Inject the bills for proposed/selected utility tariff
    //
    //It's ok if the utility_tariff_id we are looking for does not exist (e.g. if using dummy value of -1)
    //below we will simply use the first entry if not found.
    var proposedSelected = s.bills.proposed[selectedPaymentOption.utility_tariff_id]

    //If no match found or selectedPaymentOption.utility_tariff_id not set, then just use first bill proposed
    if (!proposedSelected) {
      console.warn('selectedPaymentOption.utility_tariff_id not found, using first proposed bill instead.')
      proposedSelected = s.bills.proposed[Object.keys(s.bills.proposed)[0]]
    }

    s.bills.proposedSelected = proposedSelected

    // strip out any V1 phoenix payment options, these should never be displayed in the payment option selection buttons/dropdown
    s.payment_options = s.payment_options?.filter((pmt) => {
      if (['phoenix'].includes(pmt?.integration) && !getIsPhoenixV2(pmt.integration_external_reference)) {
        return false
      }
      return true
    })
  })

  const cashFlows = {
    bank_balance:
      selectedPaymentOption && selectedPaymentOption['bank_balance'] && selectedPaymentOption['bank_balance'].length
        ? selectedPaymentOption['bank_balance']
        : [],
    cash_flows:
      selectedPaymentOption && selectedPaymentOption['cash_flows'] && selectedPaymentOption['cash_flows'].length
        ? selectedPaymentOption['cash_flows']
        : [],
  }

  const YAxisRangeAllSystemsAndPaymentOptions = {
    bank_balance: getCashFlowAxisRangeAllSystemsAndPaymentOptions(systems, 'bank_balance'),
    cash_flows: getCashFlowAxisRangeAllSystemsAndPaymentOptions(systems, 'cash_flows'),
    bill_savings: getBillSavingsAxisRangeAllSystemsAndPaymentOptions(systems),
  }

  const solarOutputAsPercentage =
    !selectedSystem || !selectedSystem.consumption || !selectedSystem.consumption.consumption_offset_percentage
      ? 0
      : selectedSystem.consumption.consumption_offset_percentage

  const proposal_checkout_buttons = proposalTemplateSettings.proposal_checkout_buttons
  const date = new Date()
  const is_proposal_expired = date.getTime() > Date.parse(selectedProject.valid_until_date)
  const checkoutHidden =
    proposal_checkout_buttons === 'disabled' ||
    (proposal_checkout_buttons === 'disable_after_expiry' && is_proposal_expired)
  // const gridUsageAsPercentage =
  //   !selectedSystem || !selectedSystem.consumption || !selectedSystem.consumption.consumption_offset_percentage
  //     ? 100
  //     : Math.max(0, selectedSystem.consumption.consumption_offset_percentage)

  let isExpired = false
  if (
    proposalTemplateSettings?.proposal_checkout_buttons === 'disable_after_expiry' &&
    selectedProject?.valid_until_date
  ) {
    const validUntilDate = Date.parse(selectedProject?.valid_until_date)
    // Note: Timezones are not considered
    if (!isNaN(validUntilDate) && validUntilDate < new Date()) {
      isExpired = true
    }
  }

  return {
    selectedPaymentOption: selectedPaymentOption,
    proposalTemplateSettings: proposalTemplateSettings,
    org: org,
    selectedProject: selectedProject,
    systems: systems,
    selectedSystem: selectedSystem,
    otherDesigns: otherDesigns,
    otherAccounts: otherAccounts,
    viewUuids: viewUuids,
    selectedViewUuid: selectedViewUuid,
    selectedPaymentOptionId: selectedPaymentOptionId,
    myeStyles: myeStyles,
    fullCalcReady,
    //inject extras too
    currencySymbol: currencySymbol,
    solarOutputAsPercentage: solarOutputAsPercentage,
    cashFlows: cashFlows,
    YAxisRangeAllSystemsAndPaymentOptions: YAxisRangeAllSystemsAndPaymentOptions,
    checkoutHidden: checkoutHidden,
    isExpired: isExpired,
  }
}

export const showCoverMessageDialog = (proposalData) => {
  const showLetter =
    proposalData && getTemplateSettings(proposalData.proposalTemplateSettings, 'sections').indexOf('letter') !== -1
  const usingPVProSellTemplate =
    proposalData &&
    getTemplateSettings(proposalData.proposalTemplateSettings, 'sections').indexOf('iframe_with_post_data') !== -1 &&
    !!proposalData.proposalTemplateSettings?.pvprosell_template_id

  if (usingPVProSellTemplate) {
    // hide the cover message dialog when no cover message is added
    if (showLetter && proposalData?.selectedProject?.proposal_data?.proposal_message?.length > 0) {
      return true
    } else {
      return false
    }
  } else {
    // always show cover message dialog for OpenSolar template even there is no cover message added
    if (showLetter && proposalData?.selectedProject?.proposal_data?.hasOwnProperty('proposal_message')) {
      return true
    } else {
      return false
    }
  }
}

export const letterContentIsEmpty = (proposalData) => {
  const showLetter =
    proposalData && getTemplateSettings(proposalData.proposalTemplateSettings, 'sections').indexOf('letter') !== -1
  if (
    showLetter &&
    proposalData &&
    proposalData.selectedProject &&
    proposalData.selectedProject.proposal_data &&
    proposalData.selectedProject.proposal_data.proposal_message === ''
  ) {
    return true
  } else {
    return false
  }
}

export const letterIsAvailable = (proposalData) => {
  const showLetter =
    proposalData && getTemplateSettings(proposalData.proposalTemplateSettings, 'sections').indexOf('letter') !== -1
  if (
    showLetter &&
    proposalData &&
    proposalData.selectedProject &&
    proposalData.selectedProject.proposal_data &&
    proposalData.selectedProject.proposal_data.proposal_message &&
    proposalData.selectedProject.proposal_data.proposal_message.length > 0
  ) {
    return true
  } else {
    return false
  }
}
