import { permissionsSelectors } from 'ducks/permissions'

import { styled } from 'opensolar-ui'
import { useContext } from 'react'
import { Datagrid, FunctionField, TextField, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { formatTimeStringBaseOnLocale } from 'util/misc'
import { FilesContext } from '../ProjectFilesList'
import { EditTags } from './edit/EditTags'
import { EditTitle } from './edit/EditTitle'
import { TableRowBtns } from './rowButtons/TableRowBtns'
import { SelectTagsField } from './select/SelectTagsField'
import { SelectTitleField } from './select/SelectTitleField'

interface StyledDatagridProps {
  isEditingInline: boolean
}

const StyledDatagrid = styled(Datagrid)<StyledDatagridProps>(({ isEditingInline, theme }) => {
  const checkboxStyle = isEditingInline ? 'none !important' : 'auto'
  return {
    '& .MuiTableCell-head': {
      backgroundColor: 'transparent',
    },
    '& .MuiTableCell-root': {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      minHeight: 70,
      padding: 10,
      width: '100%',
      boxSizing: 'border-box',
      borderBottom: '.5px solid #EFF0F2',
      [theme.breakpoints.down('sm')]: {
        borderBottom: '0px solid #EFF0F2',
        minHeight: 40,
        padding: 5,
      },
    },
    '& .MuiTableCell-root>span': {
      // items within cell are wrapped in span
      width: '100%',
    },
    '& .MuiTableHead-root': {
      backgroundColor: '#F6F9FC',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '& tr, & tr:hover': {
        backgroundColor: '#EFF0F2 !important',
        borderRadius: '8px 8px 0px 0px',
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .MuiTableRow-root': {
      display: 'flex',
      position: 'relative',
    },
    '& tr:hover, & td:hover': {
      backgroundColor: 'white !important',
    },
    '& td:first-of-type, & th:first-of-type': {
      // checkbox col
      width: 60,
      minWidth: 60,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
    },

    '& th:first-of-type > span, & td:first-of-type > span': {
      //checkbox cell
      display: checkboxStyle,
    },
    '& th:nth-of-type(2), & td:nth-of-type(2)': {
      // title col
      width: 'calc(45% - 185px)',
      minWidth: 'calc(45% - 185px)',
    },
    '& th:nth-of-type(3), & td:nth-of-type(3)': {
      // tags col
      width: 'calc(25% - 15px)',
      minWidth: 'calc(25% - 15px)',
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
    },
    '& th:nth-of-type(4), & td:nth-of-type(4)': {
      // filsize col
      width: '10%',
      minWidth: '10%',
    },

    '& th:nth-of-type(5), & td:nth-of-type(5)': {
      // modified date col
      width: '20%',
      minWidth: '20%',
    },
    '& td:nth-of-type(6)': {
      // row actions col
      width: '144px',
      minWidth: '144px !important',
    },
    '& th:nth-of-type(6)': {
      // row actions header cell
      display: 'none',
    },
    //mobile styling
    '& tbody > tr': {
      //affects only body cells
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'end',
        borderBottom: '.5px solid #EFF0F2',
        padding: '10px 0px',
      },
    },
    '& td:nth-of-type(2),& td:nth-of-type(3), & td:nth-of-type(4)': {
      // title and tags column take full row minus
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 60px)',
        minWidth: 'calc(100% - 60px)',
      },
    },
    '& td:nth-of-type(5)': {
      // title and tags column take full row minus
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 204px)',
        minWidth: 'calc(100% - 204px)',
      },
    },
  }
})

export const TableView = (props) => {
  const translate = useTranslate()
  const { allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('info_documents'))
  const { activeInlineEdit, isEditingInline } = useContext(FilesContext)

  const editTooltip = translate(allowEdit ? 'Click to edit' : 'Edit not allowed')

  return (
    <StyledDatagrid isEditingInline={isEditingInline} {...props}>
      <FunctionField
        source="title"
        render={(record) => {
          return (
            <>
              {activeInlineEdit?.record?.id === record.id && activeInlineEdit.fieldName === 'title' && allowEdit ? (
                <EditTitle record={record} {...props} />
              ) : (
                <SelectTitleField record={record} editTooltip={editTooltip} allowEdit={allowEdit} />
              )}
            </>
          )
        }}
      />

      <FunctionField
        source="file_tags_data"
        render={(record) => {
          return (
            <>
              {activeInlineEdit?.record?.id === record.id && activeInlineEdit.fieldName === 'file_tags_data' ? (
                <EditTags record={record} {...props} />
              ) : (
                <SelectTagsField record={record} editTooltip={editTooltip} allowEdit={allowEdit} />
              )}
            </>
          )
        }}
      />
      <TextField
        source="filesize"
        overrideValueFunc={(value) => {
          const size = value / 10000
          const y = `${size.toFixed(2)} MB`
          return y
        }}
      />
      <TextField source="modified_date" overrideValueFunc={(value) => formatTimeStringBaseOnLocale(value)} />
      <FunctionField
        source="id"
        style={{ zIndex: 1 }}
        render={(record) => {
          return <>{!isEditingInline && <TableRowBtns record={record} {...props} />}</>
        }}
      />
    </StyledDatagrid>
  )
}
