import ChartistGraph from 'react-chartist'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getRange } from 'util/misc'
import { convert24AmPm } from 'util/time'

type Props = {
  consumption: number[]
  loading: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  graphWrapper: {
    marginTop: 5,
    marginBottom: 0,
    marginRight: 0,
    width: '100%',
    '& .ct-start': {
      whiteSpace: 'nowrap',
      minWidth: '4em',
      borderBottom: `5px solid ${theme.palette.grey[50]}`,
      backgroundColor: theme.palette.grey[50],
      paddingRight: 5,
    },
  },
  kwhLabel: {
    position: 'absolute',
    marginTop: '-10px',
    marginLeft: '23px',
    backgroundColor: theme.palette.grey[50],
  },
}))

export const DailyConsumptionGraph = (props: Props) => {
  const classes = useStyles()

  const consumptionOnlyData = {
    labels: getRange(24),
    series: [
      {
        data: props.consumption,
        className: 'DailyEnergyFlowsConsumption',
      },
    ],
  }

  return (
    <div className={classes.graphWrapper} id="project-daily-consumption-graph">
      {props.loading ? (
        <Skeleton height={'200px'} />
      ) : (
        <div>
          <p className={classes.kwhLabel}>kWh</p>
          <ChartistGraph
            style={{}}
            data={consumptionOnlyData}
            options={{
              // @ts-ignore
              chartPadding: 0,
              showLabel: true,
              showLine: true,
              showArea: true,
              height: '200px',
              width: '100%',
              showPoint: false,
              lineSmooth: true,
              onlyInteger: false,
              referenceValue: 0,
              fullWidth: true,
              axisY: {
                showGrid: true,
                labelInterpolationFnc: function (value, index) {
                  return `${value.toFixed(2)}`
                },
              },
              axisX: {
                showLabel: true,
                showGrid: false,
                labelInterpolationFnc: function (value, index) {
                  return index < 23 && index % 4 === 3 ? convert24AmPm(value) : null
                },
              },
            }}
            type={'Line'}
            plugins={[]}
          />
        </div>
      )}
    </div>
  )
}
