import { logAmplitudeEvent } from 'amplitude/amplitude'
import BannerAd from 'elements/BannerAd'
import { AVAILABILITY_FILTER_KEY } from 'elements/hardwareFilter/stockAvailability/AvailabilityFilterNodeFactory'
import { memo, useEffect, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes } from 'types/selectComponent'
import ShopResultsPageTopBanner, { getShopResultsPageAdPlacement } from './banner/ShopResultsTopBanner'
import QueryControlledCatalog from './catalog/QueryControlledCatalog'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: { margin: '20px 0' },
}))

const TRACKING_SOURCE = 'hardware_search_catalog_view'

/*
    Page url formats:
      /shop/search/{searchText}
      or
      /shop/search/{componentType}

    Filter query params example:
      /shop/search/module/?filter={filter key value pairs}

    Note: Filter query params change will not trigger a catalog remount, but if the searchText and componentType are changed a remount is expected.

    @searchText:
        search text for filtering
    @componentType:
        component type used for filtering purpose
*/
const ShopResultsPage = ({ searchText, componentType }: { searchText: string; componentType?: ComponentTypes }) => {
  const classes = useStyles()

  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: TRACKING_SOURCE,
      searchText,
      componentType,
    })
  }, [])

  const ShopResultsPageCatalog = useMemo(() => {
    const initialFilterValues = {
      q: searchText,
      [AVAILABILITY_FILTER_KEY]: 'true',
    }

    const title = searchText && <h1>{`Search Results: ${searchText}`}</h1>

    return () => (
      <QueryControlledCatalog
        trackSource={TRACKING_SOURCE}
        initialFilterValues={initialFilterValues}
        title={title}
        componentType={componentType}
      />
    )
  }, [searchText, componentType])

  const adPlacementBottom = getShopResultsPageAdPlacement({ location: 'bottom', searchText, componentType })

  return (
    <div className={classes.container}>
      <ShopResultsPageTopBanner searchText={searchText} componentType={componentType} />
      <ShopResultsPageCatalog />
      {!!adPlacementBottom && (
        <BannerAd
          formats={['leaderboard']}
          placement={adPlacementBottom}
          containerStyle={{ marginTop: 10, marginBottom: 20 }}
          requiredWindowSize={{ width: 960 }}
        />
      )}
    </div>
  )
}

export default memo(ShopResultsPage)
