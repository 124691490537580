import { kw_to_watt, watt_to_kw } from 'util/misc'
import { positiveOrZero, required, requiredPositiveOrZero, validRange } from 'validations'
import SoftWarning from '../AdornmentWithWarning'
import { convertToLegacyCustomFields } from '../common'
import type { ComponentConfig, ComponentTypeInString } from '../types'

const BOOLEAN_OPTIONS = [
  { id: 'Y', name: 'Yes' },
  { id: 'N', name: 'No' },
]

const PHASE_TYPE_OPTIONS = [
  { id: 'single_phase', name: 'Single Phase' },
  { id: 'three_phase', name: 'Three Phase' },
]

export const inverterComponentDataConfigs: ComponentConfig<ComponentTypeInString>[] = [
  {
    source: 'code',
    label: 'Code',
    component: 'String',
    validate: required,
  },
  {
    source: 'manufacturer_name',
    label: 'Manufacturer',
    component: 'AutocompleteInput',
    referenceInputParams: {
      label: 'Manufacturer',
      reference: 'manufacturers',
      allowEmpty: true,
      ignoreWarnings: ['associatedReference'],
    },
    extra: {
      freeSolo: true,
      fullWidth: true,
      optionValue: 'name',
    },
  },
  {
    source: 'microinverter',
    label: 'Microinverter',
    component: 'Select',
    validate: required,
    selectOptions: BOOLEAN_OPTIONS,
  },
  {
    source: 'max_power_rating',
    label: 'Rated Output Power',
    component: 'Number',
    unit: 'Watts',
    formatter: { parse: watt_to_kw, format: kw_to_watt },
    validate: requiredPositiveOrZero,
  },
  {
    source: 'voltage_nominal',
    label: 'Rated Output Voltage',
    component: 'Number',
    unit: 'V',
    validate: requiredPositiveOrZero,
  },
  {
    source: 'nominal_input_voltage',
    label: 'Rated Input Voltage',
    component: 'Number',
    unit: 'V',
    validate: requiredPositiveOrZero,
  },
  {
    source: 'voltage_max',
    label: 'Maximum Input Voltage',
    component: 'Number',
    unit: 'V',
    validate: requiredPositiveOrZero,
  },
  {
    source: 'voltage_minimum',
    label: 'Minimum Input Voltage',
    component: 'Number',
    unit: 'V',
    validate: requiredPositiveOrZero,
  },
  {
    source: 'mppt_voltage_max',
    label: 'MPPT Maximum Input Voltage',
    component: 'Number',
    unit: 'V',
    validate: positiveOrZero,
  },
  {
    source: 'mppt_voltage_min',
    label: 'MPPT Minimum Input Voltage',
    component: 'Number',
    unit: 'V',
    validate: positiveOrZero,
  },
  {
    source: 'current_dc_max',
    label: 'Maximum DC Input Current',
    component: 'Number',
    unit: 'A',
    validate: positiveOrZero,
  },
  {
    source: 'current_isc_max',
    label: 'Maximum Input Short Circuit Current',
    component: 'Number',
    unit: 'A',
    validate: positiveOrZero,
  },
  {
    source: 'mppt_quantity',
    label: 'Number of MPPTs',
    component: 'Number',
    softWarning: <SoftWarning source="custom_data_field_mppt_quantity" max={50} />,
    validate: requiredPositiveOrZero,
  },
  {
    source: 'efficiency',
    label: 'Efficiency',
    unit: '%',
    component: 'Number',
    validate: validRange(0, 1000, true),
  },
  {
    source: 'power_consumption_at_night',
    label: 'Night-time Consumption',
    unit: 'Watts',
    component: 'Number',
    validate: positiveOrZero,
  },
  {
    source: 'product_warranty',
    label: 'Product Warranty',
    unit: 'years',
    component: 'Number',
    softWarning: <SoftWarning source="custom_data_field_product_warranty" max={30} />,
    validate: positiveOrZero,
  },
  {
    source: 'additional_parts_warranty',
    label: 'Additional Parts Warranty',
    unit: 'years',
    component: 'Number',
    softWarning: <SoftWarning source="custom_data_field_additional_parts_warranty" max={30} />,
    validate: positiveOrZero,
  },
  {
    source: 'skus',
    label: 'SKUs',
    unit: 'csv',
    component: 'String',
  },
  {
    source: 'hybrid',
    label: 'Hybrid',
    component: 'Select',
    selectOptions: BOOLEAN_OPTIONS,
  },
  {
    source: 'phase_type',
    label: 'Phase Type',
    component: 'Select',
    selectOptions: PHASE_TYPE_OPTIONS,
  },
  {
    source: 'current_ac_max',
    label: 'Maximum AC Output Current',
    component: 'Number',
    unit: 'A',
    validate: positiveOrZero,
  },
  {
    source: 'inbuilt_dc_isolator',
    label: 'Includes inbuilt DC isolator(s)',
    component: 'Select',
    selectOptions: BOOLEAN_OPTIONS,
  },
]

const osSpecificInverterDataConfigs: ComponentConfig<ComponentTypeInString>[] = []

export const customInverterDataConfigs: ComponentConfig<ComponentTypeInString>[] = [
  ...inverterComponentDataConfigs,
  ...osSpecificInverterDataConfigs,
]

// TODO: to clean up the usage of customInverterDataFields and remove this method
export const customInverterDataFields = convertToLegacyCustomFields(customInverterDataConfigs)

export const inverterDataFields = customInverterDataFields.filter((item) => item.source !== 'manufacturer_name')
