import { ButtonProps as Mui5ButtonPropsType } from '@mui/material/Button'
import inflection from 'inflection'
import { Button, DeleteOutlineIcon, styled } from 'opensolar-ui'
import { CRUD_DELETE_MANY, Identifier, useDeleteMany, useRefresh, useTranslate, useUnselectAll } from 'ra-core'
import React, { useState } from 'react'
import { useNotify } from 'react-admin'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import ConfirmModal from './ConfirmModal'

interface Props {
  basePath?: string
  confirmContent?: string
  confirmTitle?: string
  filterValues?: any
  icon?: ReactElement
  resource: string
  selectedIds: Identifier[]
}

const Btn = styled(Button)(({ theme }) => ({
  border: '1px solid ' + theme.palette.error.contrastText,
  backgroundColor: 'white',
}))

type BulkDeleteWithConfirmButtonProps = Props & Mui5ButtonPropsType

const BulkDeleteWithConfirmButtonV3: React.FC<BulkDeleteWithConfirmButtonProps> = (props) => {
  const notify = useNotify()
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const unselectAll = useUnselectAll()
  const refresh = useRefresh()
  const translate = useTranslate()

  const [deleteMany, { loading }] = useDeleteMany(props.resource, props.selectedIds, {
    action: CRUD_DELETE_MANY,
    onSuccess: () => {
      refresh()
      notify('ra.notification.deleted', 'info', {
        smart_count: props.selectedIds.length,
      })
      unselectAll(props.resource)
    },
    onFailure: (error) => {
      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
      setShowDialog(false)
    },
  })

  const handleClick = (e) => {
    setShowDialog(true)
    e.stopPropagation()
  }

  const handleDelete = (e) => {
    deleteMany()
  }

  const {
    confirmTitle = 'ra.message.bulk_delete_title',
    confirmContent = 'ra.message.bulk_delete_content',
    icon = <DeleteOutlineIcon />,
  } = props

  const translateOptions = {
    smart_count: props.selectedIds.length,
    name: inflection.humanize(
      translate(`resources.${props.resource}.name`, {
        smart_count: props.selectedIds.length,
        _: inflection.inflect(props.resource, props.selectedIds.length),
      }),
      true
    ),
  }

  return (
    <>
      <Btn onClick={handleClick} variant="text" color="error" startIcon={icon}>
        {translate('Delete')}
      </Btn>
      <ConfirmModal
        open={showDialog}
        setOpen={setShowDialog}
        handleSubmit={handleDelete}
        title={translate(confirmTitle, { _: confirmTitle, ...translateOptions })}
        mainText={translate(confirmContent, {
          _: confirmContent,
          ...translateOptions,
        })}
        subText={translate('This action is permanent and cannot be undone.')}
        submitBtnLabel={translate('Yes, delete')}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
    </>
  )
}

export default BulkDeleteWithConfirmButtonV3
