import { FormLabel, makeStyles } from '@material-ui/core'
import { countries } from 'constants/countries'
import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete'
import { Theme } from 'types/themes'
import { InputRow, InputWrapper } from '../../styles'

const StyledButton = styled(Button)({
  justifyContent: 'flex-start',
})

const WithErrorButton = styled(Button)({
  justifyContent: 'flex-start',
  marginTop: 80,
})

const StyledLabel = styled(FormLabel)({
  marginBottom: 5,
})

const useStyles = makeStyles((theme: Theme) => ({
  searchInput: {
    outline: 'none',
    fontSize: 14,
    borderRadius: 8,
    border: '1px solid ' + theme.greyMid2,
    boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);',
    backgroundColor: theme.white,
  },
}))

const AddressFormContent = () => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const [withErrors, setWithErrors] = useState(false)
  const [manualEntry, setManualEntry] = useState(true)
  const ButtonComponent = useMemo(() => (withErrors && !manualEntry ? WithErrorButton : StyledButton), [
    withErrors,
    manualEntry,
  ])

  const apiKeyGoogle = useSelector((state: any) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )
  const addressDefault = useMemo(() => {
    const { address, locality, zip, country_iso2 } = formValues
    const addressValues = [address, locality, zip, country_iso2].filter((part) => part !== null && part !== undefined)
    return addressValues.join(', ')
  }, [formValues])

  const handlePlaceSelected = useCallback((placeAsFields) => {
    form.change('address', placeAsFields.address)
    form.change('country_iso2', placeAsFields.country_iso2)
    form.change('state', placeAsFields.state)
    form.change('lat', placeAsFields.lat)
    form.change('lon', placeAsFields.lon)
    form.change('locality', placeAsFields.locality)
    form.change('zip', placeAsFields.zip)
    setManualEntry(true)
  }, [])

  useEffect(() => {
    const fields = ['address', 'country_iso2', 'county', 'state', 'locality', 'zip', 'lat', 'lon']
    fields.forEach((field) => {
      form.registerField(field, () => {}, {})
    })
  }, [])

  return (
    <InputWrapper>
      {manualEntry ? (
        <>
          <InputRow>
            <TextInput source={'address'} variant="outlined" label={'Address'} fullWidth />
            <SelectInput choices={countries} source={'country_iso2'} variant="outlined" label={'Country'} fullWidth />
          </InputRow>
          <InputRow>
            <TextInput source={'county'} variant="outlined" label={'County'} fullWidth />
            <TextInput source={'state'} variant="outlined" label={'State'} fullWidth />
          </InputRow>
          <InputRow>
            <TextInput source={'locality'} variant="outlined" label={'City'} fullWidth />
            <TextInput type="number" source={'zip'} variant="outlined" label={'Postcode'} fullWidth />
          </InputRow>
          <InputRow>
            <TextInput type="number" source={'lat'} variant="outlined" label={'Latitude'} fullWidth />
            <TextInput type="number" source={'lon'} variant="outlined" label={'Longitude'} fullWidth />
          </InputRow>
        </>
      ) : (
        <>
          <StyledLabel>{translate('Search Address')}</StyledLabel>
          <AddressAutocomplete
            onPlaceSelected={handlePlaceSelected}
            api_key_google={apiKeyGoogle}
            types={[]}
            className={classes.searchInput}
            componentRestrictions={null}
            disableRelative={false}
            defaultValue={addressDefault}
            isWithErrorMessage={setWithErrors}
          />
        </>
      )}
      <ButtonComponent
        variant="text"
        color="info"
        onClick={() => {
          setManualEntry(!manualEntry)
        }}
      >
        {manualEntry ? <>{translate('Search Address')}</> : <>{translate('Enter Manually')}</>}
      </ButtonComponent>
    </InputWrapper>
  )
}
export default AddressFormContent
