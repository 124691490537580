import { FormControl, FormLabel } from '@material-ui/core'
import ArrowDropDown from '@material-ui/icons/ArrowDropDownOutlined'
import { authSelectors } from 'ducks/auth'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import Button from 'elements/button/Button'
import { WithHardwareSelectorV2Props, withHardwareSelectorV2 } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { ProjectType } from 'types/projects'
import PanelInfoDialog from '../PanelInfoDialog'
import { useStyles } from './styles'
interface PropTypes extends WithHardwareSelectorV2Props {
  project: ProjectType
  system: StudioSystemType
}

const PanelsPanel: React.FC<PropTypes> = ({ enableHardwareDialogV2, handleOpenHardwareSelector, system }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const formState = useFormState()

  const component = system.moduleType()
  const availableComponents = window.AccountHelper.getComponentModuleSpecsAvailable()
  const [isArchived, setIsArchived] = useState(false)

  useEffect(() => {
    setIsArchived(availableComponents && component && !availableComponents.find((c) => c.id === component.id))
  }, [component])

  const selectModule = useCallback(() => {
    if (enableHardwareDialogV2)
      handleOpenHardwareSelector({ componentTypes: ['module'], targetUuids: [system.uuid], title: 'Select Panels' })
    else dispatch(setSelectComponentDialog(true, orgId, formState.values.id, [system.uuid], 'module'))
  }, [system])

  const [isInfoOpen, setInfoOpen] = useState(false)
  const close = () => {
    setInfoOpen(false)
  }

  return (
    <div className={classes.rows} style={{ paddingTop: 0 }}>
      <FormControl className={classes.row} size="small">
        <FormLabel className={classes.rowLabel}>{translate('Panel')}</FormLabel>
        <Button
          className={classes.fill}
          variant="outlined"
          color="primary"
          label={component.code + (isArchived ? ` (${translate('archived')})` : '')}
          endIcon={<ArrowDropDown />}
          classes={{ text: classes.selectText, endIcon: classes.selectIcon }}
          onClick={selectModule}
        />
        <Button
          label="Info"
          onClick={() => {
            setInfoOpen(true)
          }}
        />
      </FormControl>
      <PanelInfoDialog isOpen={isInfoOpen} component={component} close={close} system={system} />
    </div>
  )
}

export default withHardwareSelectorV2(PanelsPanel)
