import { AddCircleOutline, CheckCircleOutlineOutlined } from '@material-ui/icons'
import Button from 'elements/button/Button'
import { styled } from 'opensolar-ui'

import { useContext } from 'react'
import { SaveButton, Toolbar } from 'react-admin'
import { FilesContext } from '../../ProjectFilesList'

const StyledCancelButton = styled(Button)(() => ({
  '& svg': {
    transform: 'rotate(45deg)',
    marginLeft: -3,
  },
}))

const InlineEditToolbar = styled(Toolbar)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  backgroundColor: 'white',
  padding: 0,
  margin: 0,
  height: 'calc(100% - 4px) !important',
  top: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'end',
    flexDirection: 'column',
    width: '60px',
    height: '90px !important',
  },
  '& button': {
    minWidth: '40px !important',
    width: '40px',
    height: '40px',
  },
}))

export const EditInlineRowButtons = ({ record, ...props }) => {
  const { selectActiveInlineEdit, defaultActive, onSuccessInlineEdit } = useContext(FilesContext)
  return (
    <InlineEditToolbar>
      <StyledCancelButton
        label=""
        variant="text"
        startIcon={<AddCircleOutline htmlColor="#BC1C1C" />}
        color="secondary"
        onClick={() => {
          selectActiveInlineEdit(defaultActive)
        }}
        id="calcel-inline-edit"
      />
      <SaveButton
        label=""
        variant="text"
        color="secondary"
        submitOnEnter={true}
        onSave={(values: object, redirect: any, form: any, defaultSaveFunc: Function) => {
          return defaultSaveFunc(values, redirect, { onSuccess: onSuccessInlineEdit })
        }}
        {...props}
        icon={<CheckCircleOutlineOutlined htmlColor="#018030" />}
      />
    </InlineEditToolbar>
  )
}
