import { StudioSystemType } from 'types/studio/items'

export const hasBatteryInSystem = (system: StudioSystemType | undefined): boolean => {
  if (!system) return false

  return (
    system.batteries().length > 0 ||
    system
      .others()
      .some(({ other_component_type: oCT }) => oCT && ['battery_accessory', 'battery_inverter'].includes(oCT))
  )
}
