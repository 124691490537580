import { Grid } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import SavingDiscountTag from 'pages/ordering/elements/SavingDiscountTag'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrencyWithSymbol } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  priceAfterDiscount: {
    fontSize: '20px',
    color: theme.secondaryColor,
  },
  originalPrice: {
    fontSize: '14px',
    textDecoration: 'line-through',
  },
  tag: {},
}))

const PriceFigureWithDiscount = ({
  currencySymbol,
  priceAfterDiscount,
  originalPrice,
}: {
  currencySymbol: string
  priceAfterDiscount: number
  originalPrice: number
}) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item className={classes.priceAfterDiscount}>{`${formatCurrencyWithSymbol(
        priceAfterDiscount,
        currencySymbol
      )}`}</Grid>
      <Grid item className={classes.originalPrice}>{`${formatCurrencyWithSymbol(originalPrice, currencySymbol)}`}</Grid>
      <Grid item className={classes.tag}>
        <SavingDiscountTag currencySymbol={currencySymbol} value={originalPrice - priceAfterDiscount} />
      </Grid>
    </Grid>
  )
}

export default PriceFigureWithDiscount
