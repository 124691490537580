import { Divider, FormControlLabel, MenuItem, Paper, Radio, RadioGroup, TextField } from '@material-ui/core'
import { ErrorOutlineOutlined } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import PencilIcon from '@material-ui/icons/EditOutlined'
import { CustomNumberField } from 'elements/field/CustomNumberField'
import Tooltip from 'elements/tooltip/Tooltip'
import _ from 'lodash'
import { IconButton, Switch, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { useTranslate, withTranslate } from 'react-admin'
import { OpenSolarTheme } from 'Themes'
import { handleSetShadingOverride, shadingTypeFromShadingOverride } from './ShadingControls'

import { withStyles } from '@material-ui/core/styles'
import BuildablePanels from 'projectSections/sections/design/systems/tabs/panels/BuildablePanels'
import compose from 'recompose/compose'
import Panel, { StudioPanelContext } from '../Designer/Panel'
import {
  applyToModuleGrid,
  clearTypingInFieldOnUnmount,
  dispatchSignalObjectChanged,
  refreshPanelGeneric,
  stripKeyForActiveTextField,
} from '../Studio/Utils'
import ExpansionPanelShading from './ExpansionPanelShading'

import { UiSwitch } from 'elements/UiSwitch'
import ComponentWarningBox from 'projectSections/sections/design/systems/warning/ComponentWarningBox'
import { connect } from 'react-redux'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getMeasurementsFromState } from '../util/misc'
import { StudioFieldContainer } from './components/StudioFieldContainer'

const trimDecimalPlaces = (v, places) => {
  if (isNaN(v)) {
    console.log('isNaN(v)', v)
    return v
  } else {
    return parseFloat(v.toFixed(places).toString())
  }
}

const inchesToMeters = (v) => {
  //do not format it still typing...
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v / 39.3701
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

const metersToInches = (v) => {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 39.3701
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

// Warning: trimming decimal places is EXTREMELY delicate. So easy to screw it up.
// Known issue: Trying to enter more than 4 decimal places gives strange behavior but at least it's not unsfae.
const toMeters = (value, measurements) =>
  measurements === 'imperial' ? trimDecimalPlaces(inchesToMeters(value), 4) : value

const fromMeters = (value, measurements) =>
  measurements === 'imperial' ? trimDecimalPlaces(metersToInches(value), 2) : value

const useStyles = makeOpenSolarStyles((theme) => ({
  fieldsContainer: {
    marginTop: -10,
    marginBottom: 10,
  },
  fieldsRow: {
    display: 'flex',
    alignItems: 'end',
    marginTop: 10,
  },
  simpleRadioButton: {
    padding: 0,
    marginRight: 5,
  },
  warningIcon: {
    width: 20,
    height: 20,
    color: theme.alertIcon_warning,
    marginRight: 5,
  },
  infoIcon: {
    width: 20,
    height: 20,
    color: theme.alertIcon_info,
    marginRight: 5,
  },
  iconButton: {
    padding: 10,
    marginRight: 20,
    backgroundColor: theme.greyLight1,
  },
}))

const BasicSettingsPanel = ({
  moduleGrid,
  allowEdit,
  measurements,
  handleSetAzimuth,
  handleSetAzimuthAuto,
  handleSetSlope,
  handleSetSlopeAuto,
  handleSetPanelConfiguration,
  handleSetPanelTiltOverride,
  handleSetOrientation,
  handleSetElevationAuto,
  handleSetGroundClearance,
}) => {
  const azimuth = _.isNumber(moduleGrid.getAzimuth()) ? Math.round(moduleGrid.getAzimuth() * 10) / 10 : 0
  const slope = _.isNumber(moduleGrid.getSlope()) ? Math.round(moduleGrid.getSlope() * 10) / 10 : 0
  const panelTilt = _.isNumber(moduleGrid.panelTiltOverride) ? Math.round(moduleGrid.panelTiltOverride * 10) / 10 : 0
  const moduleOrientation = moduleGrid.moduleLayout()
  const isUsingTiltRacks = moduleGrid.panelConfiguration === 'TILT_RACK'
  const groundClearance =
    moduleGrid.groundClearance() && !isNaN(moduleGrid.groundClearance())
      ? fromMeters(moduleGrid.groundClearance(), measurements)
      : 0

  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          style={{ width: 60, marginRight: 5 }}
          label={translate('Azimuth')}
          field={
            <CustomNumberField
              name="moduleGridAzimuth"
              value={azimuth}
              endAdornment="°"
              disabled={!allowEdit || moduleGrid.azimuthAuto}
              minValue={0}
              maxValue={360}
              maxDecimalPlaces={1}
              wrapperStyles={{ marginBottom: 0 }}
              fieldStyles={{ width: '100%' }}
              onChange={(newValue, event) => {
                handleSetAzimuth(newValue)
              }}
            />
          }
        />
        <IconButton
          disabled={!allowEdit}
          className={classes.iconButton}
          title={moduleGrid.azimuthAuto ? translate('Override azimuth') : translate('Remove azimuth override')}
          onClick={(event) => {
            event.stopPropagation()
            handleSetAzimuthAuto(!moduleGrid.azimuthAuto)
          }}
        >
          {moduleGrid.azimuthAuto ? (
            <PencilIcon style={{ width: 16, height: 16 }} />
          ) : (
            <CloseIcon style={{ width: 16, height: 16 }} />
          )}
        </IconButton>

        <StudioFieldContainer
          style={{ width: 60, marginRight: 5 }}
          label={translate('Slope')}
          field={
            <CustomNumberField
              name="moduleGridSlope"
              value={slope}
              endAdornment="°"
              disabled={!allowEdit || moduleGrid.slopeAuto}
              minValue={0}
              maxValue={360}
              maxDecimalPlaces={1}
              wrapperStyles={{ marginBottom: 0 }}
              fieldStyles={{ width: '100%' }}
              onChange={(newValue, _event) => {
                handleSetSlope(newValue)
              }}
            />
          }
        />
        <IconButton
          disabled={!allowEdit}
          className={classes.iconButton}
          title={moduleGrid.slopeAuto ? translate('Override slope') : translate('Remove slope override')}
          onClick={(event) => {
            event.stopPropagation()
            handleSetSlopeAuto(!moduleGrid.slopeAuto)
          }}
        >
          {moduleGrid.slopeAuto ? (
            <PencilIcon style={{ width: 16, height: 16 }} />
          ) : (
            <CloseIcon style={{ width: 16, height: 16 }} />
          )}
        </IconButton>
      </div>

      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          label={translate('Panel Orientation')}
          field={
            <RadioGroup
              name="moduleLayout"
              value={moduleOrientation}
              row
              style={{ marginTop: 10 }}
              onChange={(_event, value) => handleSetOrientation(value, moduleGrid.uuid)}
            >
              <FormControlLabel
                disabled={!allowEdit}
                value={'portrait'}
                control={<Radio color="default" className={classes.simpleRadioButton} />}
                label={<span>{translate('Portrait')}</span>}
              />
              <FormControlLabel
                disabled={!allowEdit}
                value={'landscape'}
                control={<Radio color="default" className={classes.simpleRadioButton} />}
                label={<span>{translate('Landscape')}</span>}
              />
            </RadioGroup>
          }
        />
      </div>

      <div className={classes.fieldsRow} style={{ alignItems: 'start' }}>
        <StudioFieldContainer
          style={{ marginRight: 20 }}
          label={translate('Tilt Racks')}
          field={
            <Switch
              disabled={!allowEdit}
              checked={isUsingTiltRacks}
              onChange={(_event, checked) => {
                handleSetPanelConfiguration(checked ? 'TILT_RACK' : 'STANDARD', moduleGrid.uuid)
              }}
            ></Switch>
          }
        />
        <StudioFieldContainer
          style={{ width: 120, marginRight: 5 }}
          label="&nbsp;"
          field={
            <CustomNumberField
              name="moduleGridPanelTiltOverride"
              value={panelTilt}
              endAdornment="°"
              disabled={!allowEdit || !isUsingTiltRacks}
              minValue={0}
              maxValue={360}
              maxDecimalPlaces={1}
              wrapperStyles={{ marginBottom: 0 }}
              fieldStyles={{ width: '100%' }}
              onChange={(newValue, _event) => {
                handleSetPanelTiltOverride(newValue)
              }}
            />
          }
        />
      </div>

      <Divider style={{ marginTop: 15, marginBottom: 15 }} />

      <div className={classes.fieldsRow} style={{ alignItems: 'start' }}>
        <StudioFieldContainer
          style={{ marginRight: 20 }}
          label={translate('Auto-Elevation')}
          field={
            <Switch
              disabled={!allowEdit}
              checked={moduleGrid.elevationAuto}
              onChange={(_event, checked) => {
                handleSetElevationAuto(checked)
              }}
            ></Switch>
          }
        />
        <StudioFieldContainer
          style={{ width: 120, marginRight: 5 }}
          label={translate('Ground Clearance')}
          field={
            <CustomNumberField
              name="moduleGridGroundClearance"
              value={groundClearance}
              endAdornment={measurements === 'imperial' ? 'in' : 'm'}
              disabled={!allowEdit}
              minValue={0}
              maxDecimalPlaces={2}
              wrapperStyles={{ marginBottom: 0, width: 70 }}
              fieldStyles={{ width: '100%' }}
              onChange={(newValue, _event) => {
                handleSetGroundClearance(newValue)
              }}
            />
          }
        />
      </div>
    </>
  )
}

const AdvancedSettingsPanel = ({
  moduleGrid,
  allowEdit,
  measurements,
  handleSetModuleSpacing,
  handleSetModulesPerCol,
  handleSetModulesPerRow,
  handleSetGroupSpacing,
  handleSetTrackingMode,
  handleSetPanelPlacement,
  handleSetOffsetRows,
}) => {
  const moduleSpacing = moduleGrid.moduleSpacing // these are in meters
  const groupSpacing = moduleGrid.groupSpacing // these are in meters
  const modulesPerCol = moduleGrid.modulesPerCol()
  const modulesPerRow = moduleGrid.modulesPerRow()
  const trackingMode = moduleGrid.trackingMode()
  const panelPlacement = moduleGrid.panelPlacement || 'roof'
  const moduleLayoutOffset = moduleGrid.moduleLayoutOffset()
  const groundCoverageRatio = trimDecimalPlaces(moduleGrid.calculateGroundCoverageRatio(), 2)

  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          style={{ width: 120, marginRight: 10 }}
          label={translate('Horiz. Module Spacing')}
          field={
            <CustomNumberField
              name="moduleGridModuleSpacingX"
              value={fromMeters(moduleSpacing[0], measurements)}
              endAdornment={measurements === 'imperial' ? 'in' : 'm'}
              disabled={!allowEdit}
              maxDecimalPlaces={2}
              wrapperStyles={{ marginBottom: 0, width: 70 }}
              fieldStyles={{ width: '100%' }}
              onChange={(newValue, _event) => {
                // what we save to studio MUST always be in meters
                handleSetModuleSpacing(toMeters(newValue, measurements), moduleSpacing[1])
              }}
            />
          }
        />
        <StudioFieldContainer
          style={{ width: 120, marginRight: 5 }}
          label={translate('Vert. Module Spacing')}
          field={
            <CustomNumberField
              name="moduleGridModuleSpacingY"
              value={fromMeters(moduleSpacing[1], measurements)}
              endAdornment={measurements === 'imperial' ? 'in' : 'm'}
              disabled={!allowEdit}
              maxDecimalPlaces={2}
              wrapperStyles={{ marginBottom: 0, width: 70 }}
              fieldStyles={{ width: '100%' }}
              onChange={(newValue, _event) => {
                // what we save to studio MUST always be in meters
                handleSetModuleSpacing(moduleSpacing[0], toMeters(newValue, measurements))
              }}
            />
          }
        />
      </div>
      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          style={{ width: 120, marginRight: 10 }}
          label={translate('Modules Per Column')}
          field={
            <TextField
              disabled={!allowEdit}
              select
              label={null}
              value={modulesPerCol}
              onChange={(event) => {
                handleSetModulesPerCol(event.target.value)
              }}
              style={{ width: 70 }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          }
        />
        <StudioFieldContainer
          style={{ width: 120, marginRight: 5 }}
          label={translate('Modules Per Row')}
          field={
            <TextField
              disabled={!allowEdit}
              select
              label={null}
              value={modulesPerRow}
              onChange={(event) => {
                handleSetModulesPerRow(event.target.value)
              }}
              style={{ width: 70 }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          }
        />
      </div>
      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          style={{ width: 120, marginRight: 10 }}
          label={translate('Horiz. Group Spacing')}
          field={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomNumberField
                name="moduleGridGroupSpacingX"
                value={fromMeters(groupSpacing[0], measurements)}
                endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                disabled={!allowEdit || modulesPerCol === 1}
                maxDecimalPlaces={2}
                wrapperStyles={{ marginBottom: 0, width: 70 }}
                fieldStyles={{ width: '100%' }}
                onChange={(newValue, _event) => {
                  // what we save to studio MUST always be in meters
                  handleSetGroupSpacing(toMeters(newValue, measurements), groupSpacing[1])
                }}
              />
              {modulesPerCol > 1 && groupSpacing[0] < moduleSpacing[0] && (
                <Tooltip
                  title={
                    <span>{translate('Horizontal group spacing is shorter than the horizontal module spacing.')}</span>
                  }
                  style={{ padding: 0, marginLeft: 10 }}
                >
                  <ErrorOutlineOutlined classes={{ root: classes.warningIcon }} />
                </Tooltip>
              )}
            </div>
          }
        />

        <StudioFieldContainer
          style={{ width: 120, marginRight: 5 }}
          label={translate('Vert. Group Spacing')}
          field={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomNumberField
                name="moduleGridGroupSpacingY"
                value={fromMeters(groupSpacing[1], measurements)}
                endAdornment={measurements === 'imperial' ? 'in' : 'm'}
                disabled={!allowEdit || modulesPerRow === 1}
                maxDecimalPlaces={2}
                wrapperStyles={{ marginBottom: 0, width: 70 }}
                fieldStyles={{ width: '100%' }}
                onChange={(newValue, _event) => {
                  // what we save to studio MUST always be in meters
                  handleSetGroupSpacing(groupSpacing[0], toMeters(newValue, measurements))
                }}
              />
              {modulesPerRow > 1 && groupSpacing[1] < moduleSpacing[1] && (
                <Tooltip
                  title={
                    <span>{translate('Vertical group spacing is shorter than the vertical module spacing.')}</span>
                  }
                  style={{ padding: 0, marginLeft: 10 }}
                >
                  <ErrorOutlineOutlined classes={{ root: classes.warningIcon }} />
                </Tooltip>
              )}
            </div>
          }
        />
      </div>
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <div className={classes.fieldsRow}>
        <StudioFieldContainer
          style={{ width: 120 }}
          label={translate('Tracking Mode')}
          field={
            <TextField
              disabled={!allowEdit}
              select
              value={trackingMode}
              onChange={(event) => {
                handleSetTrackingMode(event.target.value)
              }}
              fullWidth
            >
              {window.trackingModes &&
                window.trackingModes.map((trackingMode) => (
                  <MenuItem key={trackingMode.id} value={trackingMode.id}>
                    {translate(trackingMode.name)}
                  </MenuItem>
                ))}
            </TextField>
          }
        />
        <StudioFieldContainer
          style={{ width: 100, marginLeft: 15 }}
          label={translate('Panel Placement')}
          field={
            <TextField
              disabled={!allowEdit}
              select
              value={panelPlacement}
              onChange={(event) => {
                handleSetPanelPlacement(event.target.value)
              }}
              fullWidth
            >
              <MenuItem key="0" value={'roof'}>
                {translate('Roof')}
              </MenuItem>
              <MenuItem key="1" value={'ground'}>
                {translate('Ground')}
              </MenuItem>
            </TextField>
          }
        />
      </div>
      <div className={classes.fieldsRow} style={{ alignItems: 'start' }}>
        <StudioFieldContainer
          style={{ width: 120 }}
          label={translate('Offset')}
          field={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                disabled={!allowEdit}
                checked={moduleLayoutOffset}
                onChange={(_event, checked) => {
                  handleSetOffsetRows(checked, moduleGrid.uuid)
                }}
              />
              <Tooltip
                title={
                  <span>{translate('Applies a horizontal shift to the panel positions in alternating order.')}</span>
                }
              >
                <ErrorOutlineOutlined classes={{ root: classes.infoIcon }} />
              </Tooltip>
            </div>
          }
        />

        <StudioFieldContainer
          style={{ marginLeft: 15 }}
          label={translate('Ground Coverage Ratio')}
          field={<h3>{groundCoverageRatio}</h3>}
        />
      </div>
    </>
  )
}

const classes = (theme) => ({
  fieldsContainer: {
    marginTop: -10,
    marginBottom: 10,
  },
  fieldsRow: {
    display: 'flex',
    alignItems: 'end',
    marginTop: 10,
  },
})

class _PanelModuleGrid extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      activeTab: 'basic',
      moduleGrid: null,
      moduleGridFacet: null,
      transparent: false,
      shadingOverrideType: null,
      shadingOverride: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.moduleGrid) {
      injectState = this.stateFromObject(props.moduleGrid)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
      console.log('PanelModuleGrid Init with state:', state)
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(
      this.refreshPanel,
      ['objectChanged', 'objectSelected', 'sceneGraphChanged', 'shadingUpdated'],
      this
    )
    this.props.useStudioSignalsLazy(
      (type, animation) => {
        if (type === 'tween' && animation === 'animateSun') this.refreshPanel()
      },
      ['animationStop'],
      this
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.visible === true && this.state.visible === false) {
      clearTypingInFieldOnUnmount.call(this)
    }
  }

  componentWillUnmount() {
    clearTypingInFieldOnUnmount.call(this)
  }

  stateFromObject(moduleGrid) {
    if (!moduleGrid) {
      return {
        visible: false,
      }
    } else if (!moduleGrid.getSystem()) {
      console.warn('No system for moduleGrid!')
      return {
        visible: false,
      }
    }
    var _state = {
      visible: true,
      moduleGrid: moduleGrid,
      num: moduleGrid ? moduleGrid.getSystem().moduleGrids().indexOf(moduleGrid) + 1 : 0,
      moduleGridFacet: moduleGrid.facet,

      azimuth: _.isNumber(moduleGrid.getAzimuth()) ? Math.round(moduleGrid.getAzimuth() * 10) / 10 : 0,
      slope: _.isNumber(moduleGrid.getSlope()) ? Math.round(moduleGrid.getSlope() * 10) / 10 : 0.0,
      panelTilt: _.isNumber(moduleGrid.panelTiltOverride) ? moduleGrid.panelTiltOverride : 0,
      groundClearance:
        moduleGrid.groundClearance() && !isNaN(moduleGrid.groundClearance()) && moduleGrid.groundClearance() !== 0
          ? fromMeters(moduleGrid.groundClearance(), this.props.measurements)
          : 0,

      panelPlacement: moduleGrid.panelPlacement ? moduleGrid.panelPlacement : 'roof',

      shadingOverrideType: shadingTypeFromShadingOverride(moduleGrid.shadingOverride),
      shadingOverride: moduleGrid.shadingOverride ? moduleGrid.shadingOverride : [],
    }

    stripKeyForActiveTextField.call(this, _state, moduleGrid)

    return _state
  }

  updateCheck() {
    this.setState((oldState) => {
      return {
        checked: !oldState.checked,
      }
    })
  }

  handleSelectObjectByUuid = (uuid) => {
    window.editor.selectByUuid(uuid)
  }

  handleDeleteObjectByUuid = (uuid) => {
    window.editor.deleteObjectByUuid(uuid)
  }

  handleSetAzimuth = (value) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'azimuth',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
    window.editor.signals.objectChanged.dispatch(this.state.moduleGrid)
  }

  handleSetSlope = (value) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'slope',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
    window.SceneHelper.snapModuleGridToGroundLevel(this.state.moduleGrid)
    window.editor.signals.objectChanged.dispatch(this.state.moduleGrid)
  }

  handleSetOrientation = (value, uuid) => {
    var moduleGrid = window.editor.objectByUuid(uuid)

    // var flipOrientation = true
    // var { cellsToRemove, newCellsActive, newPosition } = moduleGrid.cleanupHangingPanels(flipOrientation)
    //
    // window.globalCommandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
    //
    // var cmds = []
    // cmds.push(new window.RedrawModulesCommand(moduleGrid, 'moduleLayout', value, moduleGrid.moduleLayout()))
    // cmds.push(new window.SetModuleGridActiveModulesCommand(moduleGrid, newCellsActive))
    // cmds.push(new window.SetPositionCommand(moduleGrid, newPosition, moduleGrid.position))
    //
    // window.editor.execute(new window.MultiCmdsCommand(cmds))
    // window.globalCommandUUID = window.Utils.generateCommandUUIDOrUseGlobal()

    window.editor.execute(new window.RedrawModulesCommand(moduleGrid, 'moduleLayout', value, moduleGrid.moduleLayout()))
    dispatchSignalObjectChanged(uuid)
  }

  handleSetPanelConfiguration = (value, uuid) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'panelConfiguration',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )

    if (value === 'STANDARD') {
      window.editor.execute(
        new window.SetPanelConfigurationCommand(
          this.state.moduleGrid,
          'panelTiltOverride',
          null,
          window.Utils.generateCommandUUIDOrUseGlobal()
        )
      )
    } else if (this.state.moduleGrid.panelTiltOverride === null) {
      window.editor.execute(
        new window.SetPanelConfigurationCommand(
          this.state.moduleGrid,
          'panelTiltOverride',
          20,
          window.Utils.generateCommandUUIDOrUseGlobal()
        )
      )
    }

    applyToModuleGrid.bind(this)(this.state.moduleGrid, undefined, undefined, value)
    //dispatchSignalObjectChanged(uuid)
  }

  handleSetOffsetRows = (value, uuid) => {
    var moduleGrid = window.editor.objectByUuid(uuid)
    window.editor.execute(new window.RedrawModulesCommand(moduleGrid, 'moduleLayoutOffset', value, !value))
    // moduleGrid.moduleLayoutOffset(value)
    // dispatchSignalObjectChanged(uuid)
  }

  handleSetPanelPlacement = (value) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'panelPlacement',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
    let system = this.state.moduleGrid.getSystem()
    window.editor.execute(new window.SetValueCommand(system, 'systemPanelPlacement', system.getSystemPanelPlacement()))
  }

  handleSetAzimuthAuto = (value) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'azimuthAuto',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  handleSetSlopeAuto = (value) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'slopeAuto',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  handleSetPanelTiltOverride = (value) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'panelTiltOverride',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  handleSetElevationAuto = (value) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(
        this.state.moduleGrid,
        'elevationAuto',
        value,
        window.Utils.generateCommandUUIDOrUseGlobal()
      )
    )
  }

  handleSetGroundClearance = (value) => {
    if (this.state.moduleGrid.groundClearance() !== value) {
      window.editor.execute(
        new window.SetPanelConfigurationCommand(
          this.state.moduleGrid,
          'groundClearance',
          toMeters(value, this.props.measurements)
        )
      )
    }
    dispatchSignalObjectChanged(this.state.moduleGrid.uuid)
  }

  handleSetModuleSpacing = (horizontal, vertical) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(this.state.moduleGrid, 'moduleSpacing', [horizontal, vertical])
    )
  }

  handleSetModulesPerCol = (value) => {
    this.state.moduleGrid.modulesPerCol(value)
  }

  handleSetModulesPerRow = (value) => {
    this.state.moduleGrid.modulesPerRow(value)
  }

  handleSetGroupSpacing = (horizontal, vertical) => {
    window.editor.execute(
      new window.SetPanelConfigurationCommand(this.state.moduleGrid, 'groupSpacing', [horizontal, vertical])
    )
  }

  handleSetTrackingMode = (value) => {
    this.state.moduleGrid.trackingMode(value)
    window.editor.signals.objectChanged.dispatch(this.state.moduleGrid)
  }

  select = (object) => {
    window.editor.select(object)
  }

  isSelected = (object) => {
    return object && window.editor && window.editor.selected && window.editor.selected.uuid === object.uuid
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsModuleGrid')
  }

  render() {
    if (!this.state.visible) {
      return null
    }

    const { translate, classes } = this.props
    const { activeTab, allowEdit, moduleGrid } = this.state
    const system = moduleGrid.getSystem()

    return (
      <Panel
        showTools={allowEdit}
        state={{ allowEdit: allowEdit }}
        selectedObject={moduleGrid}
        title={translate('Panel Group') + ' ' + this.state.num}
        summary={
          <div>
            {moduleGrid.moduleQuantity()} {translate('modules')}
          </div>
        }
        content={
          <div>
            <ComponentWarningBox componentIds={[moduleGrid.uuid]} />
            <UiSwitch uiKey="studio.tabs.selected_panel_group.basic_settings">
              <StudioPanelContext.Provider value={{ context: this, object: moduleGrid }}>
                <Paper className={classes.fieldsContainer}>
                  <div style={{ padding: 10 }}>
                    <ToggleButtonGroup
                      fullWidth={true}
                      exclusive
                      value={activeTab}
                      onChange={(e, value) => {
                        if (!value) return
                        this.setState({ activeTab: value })
                      }}
                    >
                      <ToggleButton value="basic">{translate('Basic Settings')}</ToggleButton>
                      <UiSwitch uiKey="studio.tabs.selected_panel_group.advanced_settings">
                        <ToggleButton value="advanced">{translate('Advanced')}</ToggleButton>
                      </UiSwitch>
                    </ToggleButtonGroup>
                  </div>
                  <Divider />
                  <div style={{ padding: 10 }}>
                    {activeTab === 'basic' && (
                      <BasicSettingsPanel
                        moduleGrid={moduleGrid}
                        allowEdit={allowEdit}
                        measurements={this.props.measurements}
                        handleSetAzimuth={this.handleSetAzimuth}
                        handleSetAzimuthAuto={this.handleSetAzimuthAuto}
                        handleSetSlope={this.handleSetSlope}
                        handleSetSlopeAuto={this.handleSetSlopeAuto}
                        handleSetPanelConfiguration={this.handleSetPanelConfiguration}
                        handleSetPanelTiltOverride={this.handleSetPanelTiltOverride}
                        handleSetOrientation={this.handleSetOrientation}
                        handleSetElevationAuto={this.handleSetElevationAuto}
                        handleSetGroundClearance={this.handleSetGroundClearance}
                      />
                    )}
                    {activeTab === 'advanced' && (
                      <AdvancedSettingsPanel
                        moduleGrid={moduleGrid}
                        allowEdit={allowEdit}
                        measurements={this.props.measurements}
                        handleSetModuleSpacing={this.handleSetModuleSpacing}
                        handleSetModulesPerCol={this.handleSetModulesPerCol}
                        handleSetModulesPerRow={this.handleSetModulesPerRow}
                        handleSetGroupSpacing={this.handleSetGroupSpacing}
                        handleSetTrackingMode={this.handleSetTrackingMode}
                        handleSetPanelPlacement={this.handleSetPanelPlacement}
                        handleSetOffsetRows={this.handleSetOffsetRows}
                      />
                    )}
                  </div>
                </Paper>
              </StudioPanelContext.Provider>
            </UiSwitch>
            <UiSwitch uiKey="studio.tabs.selected_panel_group.shading">
              <ExpansionPanelShading
                setStateParent={(_state) => this.setState(_state)}
                object={moduleGrid}
                state={this.state}
                handleSetShadingOverride={handleSetShadingOverride.bind(this)}
                modulesForShadingGrid={moduleGrid.getModules()}
                diffuseShading={moduleGrid.diffuseShading}
                allowEdit={this.state.allowEdit}
              />
            </UiSwitch>
            <UiSwitch uiKey="studio.tabs.selected_panel_group.buildable_panels">
              {system && <BuildablePanels disabled={!this.state.allowEdit} system={system} moduleGrid={moduleGrid} />}
            </UiSwitch>
          </div>
        }
      />
    )
  }
}

_PanelModuleGrid.propTypes = {
  state: PropTypes.object,
}

const PanelModuleGrid = compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(_PanelModuleGrid)

export default withStyles(classes(OpenSolarTheme))(PanelModuleGrid)
