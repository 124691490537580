import { orgSelectors } from 'ducks/orgs'
import {
  CheckOutlineIcon,
  CloseOutlineIcon,
  ListIcon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { SystemPropInterface } from 'projectSections/sections/info/systemSummary/types'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { currencySymbolForCountry, formatCurrency, systemNameOrDefault } from 'util/misc'
import { getProjectSectionStyles } from '../styles'
import { useStyles } from './styles'

const RightTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'right',
  ...theme.mixins.borderStandard,
  borderTop: 'none',
  BorderBottom: 'none',
}))

const BorderedCell = styled(TableCell)(({ theme }) => ({
  ...theme.mixins.borderStandard,
  borderTop: 'none',
  BorderBottom: 'none',
}))

const SystemAccordion: React.FC = (props) => {
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  return (
    <AccordionCard
      name={'system'}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={translate('System Summary')}
      titleIcon={ListIcon}
      content={SystemContent}
      lazyRender={true}
    />
  )
}

const SystemContent = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const calcsReadyFromProjectData = useMemo(() => Boolean(!project.simulate_first_year_only), [
    project.simulate_first_year_only,
  ])
  const currencySymbol = useSelector((state: RootState) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const systems = useMemo(() => {
    let sysList: SystemPropInterface[] = []
    // If design loaded in editor, use it, which will be most up-to-date and will include data which has not yet been saved
    // otherwise if full calcs have returned, use data loaded from database (which may not include all systems)
    // if full calcs have not returned AND design is not loaded in editor, show "refresh" instruction (which we can replace in future
    // when we automatically-detect this scenario and poll/reload systems when finished)
    //
    // Note that formState.values.design never actually holds the full design data, so we can only use it
    // from window.editor if already loaded
    if (window.editor && window.editor.getSystems()) {
      window.editor.getSystems().forEach((osSystem) => {
        var systemName = osSystem.getName()
        sysList.push({
          id: null,
          url: null,
          name: systemName,
          uuid: osSystem.uuid,
          show_customer: osSystem.show_customer,
          discount: osSystem?.pricing?.discount || 0,
          kw_stc: osSystem.kwStc() || 0,
          battery_total_kwh: osSystem.batteryTotalKwh() || 0,
          batteries: osSystem.batteries().map((osBattery) => ({
            battery_id: osBattery.battery_id,
          })),
          price_including_tax: osSystem.pricing?.system_price_including_tax || 0,
          output_annual_kwh: osSystem.output?.annual ? Math.round(osSystem?.output?.annual) : 0,
          consumption_offset_percentage: osSystem.consumption?.consumption_offset_percentage
            ? Math.round(osSystem.consumption?.consumption_offset_percentage)
            : 0,
        })
      })
    } else {
      // Only use systems data from database if calcs are not pending.
      sysList = project?.systems
    }
    return sysList
  }, [window?.editor?.getSystems(), project?.systems])

  if (!calcsReadyFromProjectData) {
    // Cannot pull system details from window.editor and full calcs were not ready when project was loaded from API
    // This will almost never happen beacuse editor is almost always initialized when viewing info page.
    // One rare case would be when systems were just deleted in studio and saved before calcs return.
    // Another rare case could be when initially loading the page if editor is still initializing for some reason (or if it failed)
    return <div>{translate('System calculations in progress. Refresh to view...')}</div>
  } else if (!systems || systems?.length === 0) {
    return (
      <div>
        <p className="notAvailable">{translate('This project does not have any designed systems')}</p>
      </div>
    )
  }
  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableCell>{translate('System')}</TableCell>
          <TableCell>{translate('Show Customer')}</TableCell>
          <RightTableCell>{translate('kW STC')}</RightTableCell>
          <RightTableCell>{translate('Annual Output (kWh)')}</RightTableCell>
          <RightTableCell>{translate('Offset %')}</RightTableCell>
          <RightTableCell>{`${translate('Price')} (${currencySymbol})`}</RightTableCell>
        </TableHead>
        <TableBody>
          {systems.map((system, i: number) => (
            <TableRow>
              <BorderedCell>{systemNameOrDefault(system)}</BorderedCell>
              <BorderedCell>
                {system.show_customer ? <CheckOutlineIcon color="green" /> : <CloseOutlineIcon color="red" />}
              </BorderedCell>
              <RightTableCell>{system.kw_stc}</RightTableCell>
              <RightTableCell>{system.output_annual_kwh}</RightTableCell>
              <RightTableCell>
                {system?.consumption_offset_percentage ? Math.round(system.consumption_offset_percentage) : 'N/A'}
              </RightTableCell>
              <RightTableCell>
                {system.price_including_tax
                  ? formatCurrency(Math.round(system.price_including_tax)).toLocaleString()
                  : formatCurrency(0).toLocaleString()}
              </RightTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default SystemAccordion
