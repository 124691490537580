import { orgSelectors } from 'ducks/orgs'
import useAdBanner from 'elements/ads/useAdBanner'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import HardwareAdBannerContainer from './HardwareAdBannerContainer'
import CityPlumbingBanner from './banners/CityPlumbingBanner'
import HdmBanner from './banners/HdmBanner'
import OutletBanner from './banners/OutletBanner'
import SegenBanner from './banners/SegenBanner'
import VtacBanner from './banners/VtacBanner'

const HomeTopBanner = () => {
  const selectedHardwareSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const AdBanner = useAdBanner({
    formats: ['leaderboard'],
    placement: 'shop_home_top',
    requiredWindowSize: { width: 960, height: 800 },
  })

  //TODO is there a precedence here? Both?
  const OriginalBanner = useMemo(() => {
    if (selectedHardwareSupplier === HardwareSupplierEnum.CityPlumbing) {
      return <CityPlumbingBanner />
    }
    if (selectedHardwareSupplier === HardwareSupplierEnum.Segen) {
      return <SegenBanner renderOBPBanner={true} />
    }
    if (selectedHardwareSupplier === HardwareSupplierEnum.SolarOutlet) {
      return <OutletBanner />
    }
    if (selectedHardwareSupplier === HardwareSupplierEnum.Hdm) {
      return <HdmBanner />
    }
    if (selectedHardwareSupplier === HardwareSupplierEnum.VtacUk || selectedHardwareSupplier === HardwareSupplierEnum.VtacPoland) {
      return <VtacBanner supplier={selectedHardwareSupplier}/>
    }
    return <></>
  }, [selectedHardwareSupplier])

  if (AdBanner != null) {
    return <HardwareAdBannerContainer> {AdBanner} </HardwareAdBannerContainer>
  }

  if (OriginalBanner === null) {
    return <></>
  }

  return OriginalBanner
}

export default memo(HomeTopBanner)
