import type LineItemType from 'pages/ordering/OrderLineItem'

import { getOrderCost } from 'pages/ordering/utils'
import { VtacBillingInfoType, VtacOrderResultType, VtacOrderType, VtacShippingInfoType } from 'services/vtac/type'
import VtacService from 'services/vtac/VtacService'
import { HardwareSupplierFilterKeyType } from '../../type'
import { VtacOrderModeType, VtacOrderState } from './VtacCheckoutProvider'

class VtacCheckoutPresenter {
  private vtacService: VtacService
  private setShippingInfo: React.Dispatch<React.SetStateAction<VtacShippingInfoType>>
  private setOrderState: React.Dispatch<React.SetStateAction<VtacOrderState>>
  private setBillingInfo: React.Dispatch<React.SetStateAction<VtacBillingInfoType>>
  private setDealer: React.Dispatch<React.SetStateAction<string>>

  constructor(
    vtacService: VtacService,
    setShippingInfo: React.Dispatch<React.SetStateAction<VtacShippingInfoType>>,
    setOrderState: React.Dispatch<React.SetStateAction<VtacOrderState>>,
    setBillingInfo: React.Dispatch<React.SetStateAction<VtacBillingInfoType>>,
    setDealer: React.Dispatch<React.SetStateAction<string>>
  ) {
    this.vtacService = vtacService
    this.setShippingInfo = setShippingInfo
    this.setOrderState = setOrderState
    this.setBillingInfo = setBillingInfo
    this.setDealer = setDealer
  }

  updateDealer = (dealer: string) => {
    this.setDealer(dealer)
  }

  updateShippingInfo = (shippingInfo: VtacShippingInfoType) => {
    this.setShippingInfo(shippingInfo)
  }

  updateBillingInfo = (billingInfo: VtacBillingInfoType) => {
    this.setBillingInfo(billingInfo)
  }

  updateOrderState = <T extends keyof VtacOrderState>({ key, value }: { key: T; value: VtacOrderState[T] }) => {
    this.setOrderState((state: VtacOrderState) => ({
      ...state,
      [key]: value,
    }))
  }

  validateShippingInfo = (shippingInfo: VtacShippingInfoType) => {
    if (
      !shippingInfo.city ||
      !shippingInfo.state ||
      !shippingInfo.street ||
      !shippingInfo.postcode ||
      !shippingInfo.country_id
    ) {
      return false
    }
    return true
  }

  validateBillingInfo = (billingInfo: VtacBillingInfoType) => {
    if (
      !billingInfo.city ||
      !billingInfo.state ||
      !billingInfo.street ||
      !billingInfo.postcode ||
      !billingInfo.country_id
    ) {
      return false
    }
    return true
  }

  getDealers = async (distributor: HardwareSupplierFilterKeyType) => {
    const dealers = await this.vtacService.getDealers(distributor)
    return dealers
  }

  placeOrder = async (
    lineItems: LineItemType[],
    shippingInfo: VtacShippingInfoType,
    billingInfo: VtacBillingInfoType,
    distributor: HardwareSupplierFilterKeyType,
    orderType: VtacOrderModeType,
    dealer: string
  ) => {
    const order: VtacOrderType = {
      requestInfo: lineItems.reduce((index, item) => ({ ...index, [item.variantId || '']: item.quantity }), {}),
      shippingInfo,
      billingInfo,
      dealerId: dealer || '',
      skipDealer: true,
      requestType: orderType,
    }
    const orderResult = await this.vtacService.placeOrder(order, distributor)
    if (orderResult?.status_code && ![200, 202].includes(orderResult['status_code']) && orderResult !== undefined) {
      const orderError = orderResult as VtacOrderResultType
      console.error(orderError.reason)
      throw new Error(orderError?.content?.error)
    }
    return orderResult
  }

  getOrderCost = (lineItems: LineItemType[]) => {
    const itemsCost = !!lineItems ? getOrderCost(lineItems) : 0
    const tax = (itemsCost * 20) / 100
    return {
      itemsCost,
      tax,
    }
  }
}

export default VtacCheckoutPresenter
