import { ForecastStatus } from 'pages/inventory/forecast/type'
import type { ComponentTypes } from 'types/selectComponent'
import { generateUUID } from 'util/misc'
import {
  getTotalSavingFromFixedAmountDiscount,
  getTotalSavingFromPercentageDiscount,
  isMinimumAmountRequirement,
  isPercentageDiscount,
} from './discountUtils'
import type {
  DiscountType,
  DistributorDataType,
  HardwareSupplierFilterKeyType,
  InventoryForecastData,
  LineItemLoadedDataType,
  LineItemStatus,
  ProjectOrderDetail,
  StockLevelType,
} from './type'

// TODO: consider to group lazy loaded values into a separate key
export class OrderLineItem {
  uuid: string
  code: string
  selectedDistributor: HardwareSupplierFilterKeyType
  selectedDistributorOrderingData?: DistributorDataType
  variantId?: string // Todo: replace usage by selectedDistributorOrderingData
  variantDiscounts?: DiscountType[] // Todo: replace usage by selectedDistributorOrderingData
  quantityPerItem?: number // Todo: replace usage by selectedDistributorOrderingData
  stockLevels?: StockLevelType[] // Todo: replace usage by selectedDistributorOrderingData
  pricePerUnit?: number // Todo: replace usage by selectedDistributorOrderingData
  quantity: number
  status: LineItemStatus
  projectOrder?: ProjectOrderDetail[]
  orgId?: number
  confirmed: boolean
  componentType?: ComponentTypes
  data?: LineItemLoadedDataType
  allocatedQuantity?: number
  requiredQuantity?: number
  validity?: ForecastStatus
  inventoryForecastData?: InventoryForecastData
  constructor({
    code,
    variantId,
    selectedDistributor,
    selectedDistributorOrderingData,
    quantityPerItem,
    quantity,
    pricePerUnit,
    status,
    orgId,
    componentType,
    variantDiscounts,
    projectOrder,
    stockLevels,
    data,
    confirmed,
    inventoryForecastData,
  }: {
    code: string
    variantId?: string
    quantityPerItem?: number
    quantity: number
    pricePerUnit?: number
    orgId?: number
    confirmed?: boolean
    status?: LineItemStatus
    selectedDistributor: HardwareSupplierFilterKeyType
    selectedDistributorOrderingData?: DistributorDataType
    variantDiscounts?: DiscountType[]
    projectOrder?: ProjectOrderDetail[]
    stockLevels?: StockLevelType[]
    componentType?: ComponentTypes
    data?: LineItemLoadedDataType
    inventoryForecastData?: InventoryForecastData
  }) {
    this.uuid = generateUUID()
    this.code = code
    this.variantId = variantId
    this.selectedDistributor = selectedDistributor
    this.selectedDistributorOrderingData = selectedDistributorOrderingData
    this.variantDiscounts = variantDiscounts
    this.pricePerUnit = pricePerUnit
    this.quantityPerItem = quantityPerItem
    this.quantity = quantity
    this.status = status || 'loading'
    this.orgId = orgId
    this.stockLevels = stockLevels != null ? [...stockLevels] : undefined
    this.projectOrder = projectOrder != null ? [...projectOrder] : undefined
    this.componentType = componentType
    this.data = data
    this.confirmed = confirmed || false
    this.setDefaultSelectedVariant()
    this.inventoryForecastData = inventoryForecastData
  }

  private selectedVariantHealthyCheck = (): boolean => {
    if (!this.selectedDistributor) {
      console.log(`component(${this.code}) attempted healthy variant check without selectedDistributor being set`)
      return false
    }
    if (this.variantId !== this.selectedDistributorOrderingData?.variant_id) {
      console.log(`component(${this.code}) variant id not matched with selected distributor`)
      return false
    }
    const variant = this.data?.distributors?.find(
      (variant) => variant.variant_id === this.variantId && variant.distributor === this.selectedDistributor
    )
    if (variant == null) {
      console.log(`component(${this.code}) variant not found in loaded data`)
      return false
    }
    return true
  }

  static getDefaultSelectedVariant(
    distributors: DistributorDataType[],
    selectedDistributor: HardwareSupplierFilterKeyType
  ): DistributorDataType | undefined {
    // Hightest priority default to available individual variant in primary warehouse
    const defaultDistributorPrimaryWarehouse = distributors.find(
      (distributor) =>
        (distributor.stock_levels?.length || 0) > 0 &&
        distributor.is_available &&
        distributor.quantity_per_item === 1 &&
        distributor.distributor === selectedDistributor
    )

    if (defaultDistributorPrimaryWarehouse !== undefined) {
      return defaultDistributorPrimaryWarehouse
    }

    // If not best matched variant then default to available individual
    const defaultDistributorAvailable = distributors.find(
      (distributor) =>
        distributor.is_available &&
        distributor.quantity_per_item === 1 &&
        distributor.distributor === selectedDistributor
    )

    if (defaultDistributorAvailable !== undefined) {
      return defaultDistributorAvailable
    }

    // fallback to any individual variant
    const defaultIndividualDistributor = distributors?.find(
      (distributor) => distributor.quantity_per_item === 1 && distributor.distributor === selectedDistributor
    )

    if (defaultIndividualDistributor !== undefined) {
      return defaultIndividualDistributor
    }
  }

  private setDefaultSelectedVariant(): boolean {
    this.selectedVariantHealthyCheck()
    if (this.variantId !== undefined) {
      this.switchVariant(this.variantId)
      return true
    }

    const defaultSelectedVariant =
      this.data?.distributors &&
      OrderLineItem.getDefaultSelectedVariant(this.data.distributors, this.selectedDistributor)

    if (defaultSelectedVariant) {
      this.switchVariant(defaultSelectedVariant.variant_id)
      return true
    }
    return false
  }

  switchVariant(variantId: string): boolean {
    const distributor = this.data?.distributors?.find(
      (distributor: DistributorDataType) =>
        distributor.variant_id === variantId && distributor.distributor === this.selectedDistributor
    )
    if (distributor == null) {
      return false
    }
    this.selectedDistributorOrderingData = distributor
    this.pricePerUnit = distributor.price
    this.quantityPerItem = distributor.quantity_per_item
    this.stockLevels = distributor.stock_levels
    this.variantId = distributor.variant_id
    this.variantDiscounts = distributor.volume_discounts
    return true
  }

  isDiscountAvailable(discountId: string): boolean {
    const discount = this.selectedDistributorOrderingData?.volume_discounts.find(
      (discount: DiscountType) => discount.id && discount.id === discountId
    )
    if (!discount) {
      return false
    }
    if (isMinimumAmountRequirement(discount.minimumRequirement)) {
      const currentAmount = this.getTotalCostBeforeDiscount()
      if (currentAmount == null) return false
      return currentAmount >= parseInt(discount.minimumRequirement.greaterThanOrEqualToSubtotal.amount)
    } else {
      return this.quantity >= parseInt(discount.minimumRequirement.greaterThanOrEqualToQuantity)
    }
  }

  getTotalCostBeforeDiscount(): number | undefined {
    if (this.pricePerUnit == null) {
      return undefined
    }
    return this.pricePerUnit * this.quantity
  }

  getBestDiscountOffer(): {
    beforeDiscount?: number
    afterDiscount?: number
    discount?: DiscountType
  } {
    const offer = {}
    const costBeforeDiscount = this.getTotalCostBeforeDiscount()
    if (costBeforeDiscount == null) {
      return offer
    }

    offer['beforeDiscount'] = costBeforeDiscount
    offer['afterDiscount'] = costBeforeDiscount

    if (!this.selectedDistributorOrderingData?.volume_discounts?.length) {
      return offer
    }

    const discounts = this.selectedDistributorOrderingData.volume_discounts

    discounts.forEach((discount) => {
      if (!this.isDiscountAvailable(discount.id)) {
        return
      }
      let saving: number
      if (isPercentageDiscount(discount)) {
        saving = getTotalSavingFromPercentageDiscount(discount.customerGets.value, offer['beforeDiscount'])
      } else {
        saving = getTotalSavingFromFixedAmountDiscount({
          discountValue: discount.customerGets.value,
          quantity: this.quantity,
        })
      }
      if (offer['afterDiscount'] > offer['beforeDiscount'] - saving) {
        offer['discount'] = discount
        offer['afterDiscount'] = offer['beforeDiscount'] - saving
      }
    })

    return offer
  }
}

export default OrderLineItem
