import { makeStyles } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { CentrixCreditCheckButton } from 'projectSections/sections/info/transactions'
import { ButtonsRow } from 'projectSections/sections/manage3/common/ButtonsRow'
import { IconBtnWithPermissions } from 'projectSections/sections/manage3/common/IconBtnWithPermissions'
import React, { ReactElement, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import { reloadEntireApp } from 'util/misc'
import { getOrgFromState } from 'util/org'
import { CRUDFileMenu } from './files/common/CRUDFileMenu'
import { FileSearchField } from './files/section/FileSearchField'
import { FilesAndTransContext, FilesAndTransContextProps } from './FilesAndTransactionsSection'

const useStyles = makeStyles((theme: Theme) => ({
  isHidden: {
    display: 'none',
  },
}))

export interface ToggleGroupTab {
  title: string
  icon: ReactElement
}

type ToggleGroupTabs = {
  [key: string]: ToggleGroupTab
}

interface PropTypes {
  toggleGroupTabs?: ToggleGroupTabs
  btns?: any

  basePath?: any
}

export const ToolBar: React.FC<PropTypes> = () => {
  const { tabSelected, ctaSelected, selectCta, addSearchTerm } = useContext(
    FilesAndTransContext
  ) as FilesAndTransContextProps
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const enableCentrixCreditCheck = useSelector(
    (state: RootState) => getOrgFromState(state)?.enable_centrix_credit_check
  )

  useEffect(() => {
    if (['upload_file', 'upload_site_photos', 'generate_file'].includes(ctaSelected)) {
      setAnchorEl(null)
    }
  }, [ctaSelected])

  const orgId = useSelector(authSelectors.getOrgId) as number
  const projectId = useSelector((state: any) => state.projectId)

  const showSearchField = tabSelected === 'documents' && ctaSelected === 'open_search_field'
  return (
    <>
      {showSearchField && (
        <div>
          <FileSearchField />
        </div>
      )}
      {!showSearchField && tabSelected === 'documents' && (
        <ButtonsRow>
          <IconBtnWithPermissions
            permissionToCheck="info_documents"
            permissions={['allowView']}
            btnType={'search'}
            variant="squared"
            onClick={() => {
              selectCta('open_search_field')
            }}
          />
          <IconBtnWithPermissions
            permissionToCheck="info_documents"
            permissions={['allowCreate']}
            btnType={'add'}
            variant="squared"
            onClick={(e) => {
              selectCta('add_file')
              setAnchorEl(e.currentTarget)
            }}
            tooltip={'Add file'}
          />
          <CRUDFileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} ctaSelected={ctaSelected} selectCta={selectCta} />
          <IconBtnWithPermissions
            permissionToCheck="info_documents"
            permissions={['allowView']}
            btnType={'link'}
            variant="squared"
            to={`private_files/`}
            tooltip={'Go to project files'}
          />
        </ButtonsRow>
      )}
      {tabSelected === 'transactions' && (
        <div>
          {enableCentrixCreditCheck && (
            <CentrixCreditCheckButton
              org_id={orgId}
              project_id={projectId}
              refreshView={() => {
                // Used same implementation as in v2
                // -- We have not implemented a way to refresh the transactions list independently of the rest of the page
                // -- so we are lazy and we just reload the whole page.
                reloadEntireApp()
              }}
            />
          )}
          <IconBtnWithPermissions
            permissionToCheck="info_transactions"
            permissions={['allowCreate']}
            btnType={'add'}
            variant="squared"
            onClick={() => {
              selectCta('add_transaction')
            }}
            tooltip={'Add transaction'}
          />
        </div>
      )}
    </>
  )
}
