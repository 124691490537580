export const toggle_inputs_and_extra_fields = [
  {
    key: 'variable_prepayment_enabled',
    extraFields: [
      'variable_prepayment_formula',
      'variable_prepayment_period',
      'variable_prepayment_percentage',
    ],
  },
  {
    key: 'variable_promotion_enabled',
    extraFields: ['variable_no_interest_period', 'variable_no_payment_period', 'variable_interest_only_period'],
  },
  { key: 'variable_dealer_fee_enabled', extraFields: ['variable_dealer_fee_fixed', 'variable_dealer_fee_percentage'] },
  {
    key: 'variable_down_payment_enabled',
    extraFields: ['variable_down_payment_percentage', 'variable_down_payment_min', 'variable_down_payment_max'],
  },
  { key: 'variable_initial_loan_enabled', extraFields: ['variable_initial_term'] },
  {
    key: 'variable_initial_bullet_enabled',
    extraFields: [
      'variable_initial_bullet_payment_percentage',
      'variable_initial_bullet_payment_min',
      'variable_initial_bullet_payment_max',
    ],
  },
  {
    key: 'variable_final_bullet_enabled',
    extraFields: [
      'variable_final_bullet_payment_percentage',
      'variable_final_bullet_payment_min',
      'variable_final_bullet_payment_max',
    ],
  },
  {
    key: 'variable_advance_settings_enabled',
    extraFields: [
      'variable_payment_frequency',
      'utility_tariff_override',
      'priority',
      'is_archived',
      'variable_collect_signature',
      'contract_template',
      'auto_discount',
      'use_highest_standard_system_price',
      'auto_apply_only_specified_states',
      'auto_apply_only_specified_zips',
      'auto_apply_component_codes',
      'auto_apply_min_system_size',
      'auto_apply_max_system_size',
      'auto_apply_sector',
    ],
    extraFieldsDefaultValues: {
      priority: 1,
      variable_collect_signature: true,
      variable_payment_frequency: 'monthly',
      contract_template: '',
      is_archived: false,
      auto_discount: true,
      use_highest_standard_system_price: true,
      utility_tariff_override: null,
      auto_apply_only_specified_states: null,
      auto_apply_only_specified_zips: null,
      auto_apply_component_codes: null,
      auto_apply_min_system_size: null,
      auto_apply_max_system_size: null,
      auto_apply_sector: 0,
    },
  },
]
