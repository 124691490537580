import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { IconButton, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'
import { PrivateFileType } from 'types/privateFile'

type PropTypes = {
  fileToPreview: PrivateFileType
  isOpen: boolean
  onClose: any
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '20px 24px',
  borderTop: '1px solid',
  borderColor: theme.palette.grey[200],
}))

const StyledDialogContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
  paddingRight: 10,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const PreviewContainer = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  width: '100%',
  height: '720px',
  overflow: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgb(0 0 0 / 82%)', //pdf preview border color
  borderRadius: 4,
  padding: 15,
  boxSizing: 'border-box',
}))

const StyledObject = styled('object')(({ theme }) => ({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  minWidth: '100%',
  minHeight: '100%',
}))

const PreviewDialog: React.FunctionComponent<PropTypes> = ({ fileToPreview, isOpen, onClose }) => {
  const translate = useTranslate()
  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <StyledDialogContainer>
          <DialogTitle>
            {translate('File')}: {fileToPreview.title}
          </DialogTitle>
          <IconButton variant="squared" onClick={onClose}>
            {<ClearOutlined />}
          </IconButton>
        </StyledDialogContainer>
        <StyledDialogContent>
          <PreviewContainer>
            <StyledObject data={`${fileToPreview?.file_contents}`} />
          </PreviewContainer>
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

export default PreviewDialog
