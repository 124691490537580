import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { OpenSolarThemeType } from 'Themes'
import { orderSelectors } from 'ducks/orderComponents'
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import { IconButton } from 'opensolar-ui'
import AddToCartButton from 'pages/ordering/order/AddToCartButton'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ForecastProjectData } from '../type'
import { BOMReviewTable } from './BOMReviewTable'
import { BOMTable } from './BOMTable'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: '0 5px',
  },
}))

const AddMissingItemDialog = ({
  handleCloseAddMissingItem,
  project,
  trackSource,
}: {
  handleCloseAddMissingItem(): void
  project: ForecastProjectData
  trackSource: string
}) => {
  const classes = useStyles()
  const lineItems = useSelector(orderSelectors.getOrderLineItems).filter((lineItem) => !lineItem.confirmed)

  return (
    <StyledDialog open={true} fullWidth maxWidth="xl">
      <section className={classes.title}>
        <StyledDialogTitle>
          <Typography variant="h5">Restock Hardware Inventory</Typography>
        </StyledDialogTitle>
        <IconButton onClick={() => handleCloseAddMissingItem()}>
          <CloseIcon />
        </IconButton>
      </section>
      <StyledDialogContent className={classes.content}>
        <BOMTable lineItems={lineItems} editable={true} projectName={project.title} />
        <BOMReviewTable lineItems={lineItems} editable={false} />
      </StyledDialogContent>
      <StyledDialogActions>
        <AddToCartButton handleCloseAddMissingItem={handleCloseAddMissingItem} trackSource="inventory_order_table" />
      </StyledDialogActions>
    </StyledDialog>
  )
}

export default AddMissingItemDialog
