export const INTEGRATION_OPTIONS = [
  { value: 'dividend', label: 'Dividend', org_field: 'enable_dividend' },
  { value: 'sungage', label: 'Sungage', org_field: 'enable_sungage' },
  { value: 'mosaic', label: 'Mosaic', org_field: 'enable_mosaic' },
  { value: 'sunlight', label: 'Sunlight', org_field: 'enable_sunlight' },
  { value: 'loanpal', label: 'GoodLeap', org_field: 'enable_loanpal' },
]

export const PRODUCT_TYPE_OPTIONS = {
  dividend: [
    { value: 'standard', label: 'Standard (DivLev)' },
    { value: 'io', label: 'I/O' },
    { value: 'simple', label: 'Simple' },
  ],
  sungage: [
    { value: 'standard', label: 'Standard (next June)' },
    { value: '18_mo', label: '18 Month DPP' },
  ],
  mosaic: [
    { value: 'standard', label: 'Choice' },
    { value: 'plus', label: 'Plus' },
    // { value: 'ps0', label: 'PowerSwitch Zero' }, //not showing Ps0 because in general pros don't care about the monthly payment for this, it's meant to be paid off entirely at prepayment
  ],
  sunlight: [
    { value: 'standard', label: 'Standard' }, // limiting to just standard solar loans for now. Not enough volume in the other products to justify the complexity
    // { value: 'solarplus_itc', label: 'SolarPlus ITC' },
    // { value: 'battery', label: 'Battery Only' },
    // { value: 'roof', label: 'Roof' },
  ],
  loanpal: [
    { value: 'standard', label: 'Standard' }, // limiting to just standard solar as well
    // { value: 'fastpay', label: 'Fastpay' },
    // { value: 'flexpay', label: 'Flexpay' },
  ],
}
