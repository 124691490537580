export enum EventCategoryEnum {
  system_activity,
  customer_activity,
  note,
  task,
  finance,
  payments,
  hardware_inventory,
  workflow,
}

export enum EventFormVariant {
  project_action = 2,
  task = 3,
  special_action = 4,
  note = 6,
}

export enum EventIconVariant {
  email = 1,
  customer = 2,
  check = 3,
  file = 4,
  alert = 5,
  arrow = 6,
}

export enum EventActions {
  archive = 0,
  edit = 1,
  delete = 2,
}

export interface FormVariations {
  [key: number]: FormVariation
}
type FormVariation = {
  label?: string
  content: React.ReactNode
  actions: number[]
}
