import CustomField from 'elements/field/CustomField'
import ChipsInput from 'elements/input/ChipsInput'
import { styled } from 'opensolar-ui'

import { Edit, ReferenceArrayInput, SimpleForm } from 'react-admin'
import { InlineFormContainer } from '../../../common/styles'
import { EditInlineRowButtons } from '../rowButtons/EditInlineRowButtons'

export const TagsInlineEditForm = styled(Edit)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: 14,
    //border: '3px solid red',
    '&>div:first-child': {
      display: 'flex',
      flexFlow: 'row wrap',
      columnGap: 8,
      rowGap: 8,
    },
  },
  '& .OSUI-Chip-root': {
    whiteSpace: 'normal',
    boxSizing: 'content-box',
    minHeight: 'fit-content',
    height: 'fit-content',
    padding: '5px 0px',
  },
  '& .OSUI-Chip-label': {
    height: 'fit-content',
    whiteSpace: 'normal',
    minHeight: 'fit-content',
    textOverflow: 'initial',
    marginRight: 5,
  },
}))

export const EditTags = ({ record, ...props }) => {
  return (
    <InlineFormContainer>
      <TagsInlineEditForm
        id={record.id}
        hideTitle={true}
        hideBreadCrumbs={true}
        resource="private_files"
        location={{
          hash: '',
          pathname: '/private_files',
        }}
      >
        <SimpleForm redirect={false} toolbar={<EditInlineRowButtons record={record} {...props} />}>
          <CustomField
            name="file_tags"
            source="file_tags"
            component={ReferenceArrayInput}
            reference="file_tags"
            resource="file_tags"
            optionValueField="url"
            fullWidth
            style={{ width: '100%' }}
            perPage={50}
            autoFocus
            filterToQuery={(searchText) => ({ search: searchText, type: 'private' })}
          >
            <ChipsInput
              source="file_tags"
              optionText="title"
              optionValue="url"
              variant="outlined"
              label=""
              translateChoice={false}
              options={{ style: {} }}
            />
          </CustomField>
        </SimpleForm>
      </TagsInlineEditForm>
    </InlineFormContainer>
  )
}
