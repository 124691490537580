import isEqual from 'lodash/isEqual'
import React from 'react'
import { FormDataConsumer } from 'react-admin'

/**
 * @param { import("./types").DependentInputProps & IntrinsicAttributes } p
 */
const DependentInput = ({ children, dependsOn, value = undefined, resolve, ...props }) => {
  if (!Array.isArray(dependsOn)) {
    dependsOn = [dependsOn]
    value = [value]
  }

  const valueMatched = (formData) => {
    const formValues = dependsOn.map((dependency) =>
      formData.hasOwnProperty(dependency) && formData[dependency] === undefined
        ? null // This allows resolvers to distinguish between `{prop:undefined}` and `{}`
        : formData[dependency]
    )
    return (!resolve && isEqual(formValues, value)) || (resolve && resolve(...formValues))
  }

  return (
    <FormDataConsumer {...props}>
      {({ formData, ...rest }) =>
        valueMatched(formData) &&
        children &&
        React.Children.map(
          children,
          (field) =>
            field &&
            React.cloneElement(field, {
              ...rest,
            })
        )
      }
    </FormDataConsumer>
  )
}
export default DependentInput
