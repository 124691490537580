import { Grid } from '@material-ui/core'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import Button from 'elements/button/Button'
import { ComponentVersionsInherit, styled } from 'opensolar-ui'
import { createContext, useCallback, useMemo, useState } from 'react'
import { List } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { PrivateFileType } from 'types/privateFile'
import { CRUDFilesDialogs } from '../common/dialogs/CRUDFilesDialogs'
import { useProjectFiles } from '../common/hooks/useProjectFiles'
import { FilesGridView } from './gridView/GridView'
import { MainToolBar } from './MainToolBar'
import { TableView } from './tableView/TableView'

export interface FilesContextProps {}

interface ActiveInfo {
  record: PrivateFileType | null
  fieldName: string | null
}

type ViewMode = 'tableView' | 'gridView'

type FileDialog = 'upload_file' | 'upload_site_photos' | 'preview_file' | 'edit_file' | ''

export const FilesContext = createContext<FilesContextProps | null | any>(null)

const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
}))

const Breadcrumbs = styled('div')(({ theme }) => ({
  '& button': {
    padding: '5px 0px',
  },
}))

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  padding: 0,
  '& .MuiToolbar-root': {
    height: 'min-content',
    minHeight: 0,
    '& .MuiTypography-subtitle1': {
      display: 'none',
    },
  },
  '& .Ra-List-Toolbar-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch !important',
    gap: 15,
  },
  '& .MuiTablePagination-root': {
    backgroundColor: '#F6F9FC',
    '& .OSUI-Button-textPrimary': {
      color: theme.palette.grey[500],
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: 70,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '0px 0px 8px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 44,
    },
  },

  '& .MuiPaper-elevation1': {
    border: 'none !important',
  },
}))

export const ProjectFilesList = (props) => {
  const projectId = useSelector((state: any) => state.projectId) || 'new'
  const { updateEventInForm, form, projectFormValues, deleteFileInForm, files } = useProjectFiles()

  const defaultActive: any = { record: null, fieldName: null }
  const [viewMode, setViewMode] = useState<ViewMode>('tableView')
  const selectViewMode = useCallback((v: ViewMode) => setViewMode(v), [])
  const [dialogSelected, setDialogSelected] = useState<FileDialog>('')
  const selectDialog = useCallback((v: FileDialog) => setDialogSelected(v), [])
  const [fileSelected, setFileSelected] = useState<PrivateFileType | null>(null)
  const selectFile = useCallback((v: PrivateFileType | null) => setFileSelected(v), [])
  const [activeInlineEdit, setActiveInlineEdit] = useState<ActiveInfo>(defaultActive)
  const selectActiveInlineEdit = useCallback((v: ActiveInfo) => setActiveInlineEdit(v), [])
  const isEditingInline = useMemo(() => activeInlineEdit?.record !== null, [activeInlineEdit])
  const onSuccessInlineEdit = useCallback((r) => {
    updateEventInForm({ id: r.id, fileRecordUpdated: r.data })
    selectActiveInlineEdit(defaultActive)
    setFileSelected(null)
  }, [])

  const filesContext: FilesContextProps = useMemo<FilesContextProps>(
    () => ({
      defaultActive,
      viewMode,
      selectViewMode,
      dialogSelected,
      selectDialog,
      fileSelected,
      selectFile,
      activeInlineEdit,
      selectActiveInlineEdit,
      onSuccessInlineEdit,
      isEditingInline,
      deleteFileInForm,
    }),
    [selectViewMode, viewMode, fileSelected, activeInlineEdit]
  ) as FilesContextProps

  const CRUDDialogs = useMemo(() => {
    return (
      <>
        {dialogSelected !== '' && (
          <CRUDFilesDialogs
            dialogSelected={dialogSelected}
            onCloseDialog={() => setDialogSelected('')}
            fileSelected={fileSelected}
            form={form}
            projectFormValues={projectFormValues}
            deleteFileInForm={deleteFileInForm}
            updateEventInForm={updateEventInForm}
          />
        )}
      </>
    )
  }, [dialogSelected, fileSelected])

  return (
    <FilesContext.Provider value={filesContext}>
      <ComponentVersionsInherit versions={{ ...ComponentVersions_3_0, table: 1 }}>
        <Wrapper>
          <Breadcrumbs>
            <Link to={`/projects/${projectId}/info`}>
              <Button variant="text" color="info" disabled={isEditingInline}>
                ← Back to Manage
              </Button>
            </Link>
          </Breadcrumbs>
          <Grid container spacing={0}>
            <StyledList
              id={projectId}
              basePath={`/projects/${projectId}/private_files`}
              resource="private_files"
              title={'Project Files'}
              hasSearch={false}
              hasCreate={false}
              filter={{ project: projectId }}
              hideBreadCrumbs={true}
              actions={<MainToolBar hasSearch={false} {...props} />}
              bulkActionButtons={<></>}
              hasBulkActions={true}
            >
              {viewMode === 'tableView' ? <TableView /> : <FilesGridView />}
            </StyledList>
          </Grid>
        </Wrapper>
        {CRUDDialogs}
      </ComponentVersionsInherit>
    </FilesContext.Provider>
  )
}
