import { Grow } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import PremiumProductTermsDialog from 'resources/premiumProducts/PremiumProductTermsDialog'
import restClient from 'restClient'
import { PremiumImageryType } from 'types/global'
import { WalletProductType } from 'types/products'

const PROVIDER_TO_NAME = {
  Nearmap: 'Nearmap Imagery',
  GetMapping: 'GetMapping',
  Google: 'Google Solar API',
}

type PropTypes = {
  provider: 'Nearmap'
  selectedPremiumImageryActivation: PremiumImageryType
  walletProduct: WalletProductType
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const PremiumImageryTerms: React.FC<PropTypes> = (props) => {
  const formState = useFormState()
  const translate = useTranslate()

  const [showTerms, setShowTerms] = useState<boolean>(false)
  const [termsContent, setTermsContent] = useState<string>('')

  useEffect(() => {
    if (!showTerms) return
    restClientInstance('GET_ONE', 'wallet_products', {
      id: props.walletProduct.id,
    })
      .then((response: any) => {
        setTermsContent(response.data.terms)
      })
      .catch((err: any) => {
        console.error('Failed to load product terms: ', err)
        setTermsContent(translate('Something went wrong'))
      })
  }, [showTerms])

  let address = useMemo(() => {
    if (formState.values.address && formState.values.locality) {
      return formState.values.address + ', ' + formState.values.locality
    }
    return 'this address'
  }, [formState.values.address, formState.values.locality])

  const activation = props.selectedPremiumImageryActivation
  const terms = useMemo(() => {
    if (!props.provider || !address || !props.walletProduct) return undefined
    const provider = PROVIDER_TO_NAME[props.provider] || props.provider

    if (provider.includes('Google Solar API')) {
      return (
        <span>
          By activating Google Solar API in OpenSolar you agree to be bound by{' '}
          <a href="http://www.google.com/intl/en/policies/terms" target="_blank" rel="noreferrer">
            Google’s Terms of Service
          </a>{' '}
          and the{' '}
          <a href="http://www.google.com/policies/privacy" target="_blank" rel="noreferrer">
            Google Privacy Policy
          </a>
          .
        </span>
      )
    }

    let action = props.walletProduct.unit_price_ex_tax === 0 ? 'activate imagery' : 'purchase imagery'
    if (activation) action += ' again'
    action = translate(action)

    return translate('By proceeding, I agree to %{provider} Terms and Conditions and to %{action} for %{address}.', {
      provider,
      address,
      action,
    })
  }, [props.provider, address, props.walletProduct, activation])

  if (!terms) return null

  const openTerms = () => {
    setShowTerms(true)
  }

  const dismissTerms = () => {
    setShowTerms(false)
    logAmplitudeEvent('premium_product_terms_dismissed', { provider: 'nearmap', page: 'preimum imagery' })
  }

  return (
    <Grow in={true}>
      <>
        {typeof terms === 'string' || terms instanceof String ? (
          <div
            style={{ fontSize: '12px', marginTop: '8px', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={openTerms}
          >
            {terms}
          </div>
        ) : (
          terms
        )}
        {showTerms && (
          <PremiumProductTermsDialog open={showTerms} onClose={dismissTerms} terms={termsContent} hideDismiss={false} />
        )}
      </>
    </Grow>
  )
}
export default PremiumImageryTerms
