import { FileCheckOutlineIcon } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { complianceForms } from 'projectSections/sections/info/permitsAndServices/constants'
import { ComplianceFormType, RecordType } from 'projectSections/sections/info/permitsAndServices/types'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { getProjectSectionStyles } from '../styles'
import FormDisplay from './FormDisplay'
import { useStyles } from './styles'

interface PropTypes {}

const ServicesAccordion: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const project = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  if (project?.project_sold === null) return null
  return (
    <AccordionCard
      name={'services'}
      elevation={0}
      title={translate('Permits & Services')}
      titleIcon={() => <FileCheckOutlineIcon variant={2} />}
      content={ServicesContent}
      lazyRender={true}
      className={sectionStyles.accordionReskin}
    />
  )
}

const ServicesContent = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const project = useFormState().values as RecordType
  const notAvailableMessage = useMemo(() => {
    if (!project?.systems || project?.systems?.length === 0) {
      return translate('Compliance forms available after system design')
    } else if (project?.systems?.length > 1 && !project?.system_installed) {
      return translate(
        'When a project has multiple systems, compliance forms are only available once the installed system is selected.'
      )
    } else {
      return null
    }
  }, [project?.systems, project?.system_installed])
  const checkGeoFilters = (item: ComplianceFormType): boolean => {
    if (
      project.country_iso2 &&
      project.country_iso2.length > 0 &&
      item.countries &&
      item.countries.indexOf(project.country_iso2) === -1
    ) {
      return false
    }

    // if (project.country_iso2 === 'NZ' && enableDocsForNZ === false) {
    //   return false
    // }

    if (project.state && project.state.length > 0 && item.states && item.states.indexOf(project.state) === -1) {
      return false
    }

    if (project.zip && project.zip.length > 0 && item.zips && item.zips.indexOf(project.zip) === -1) {
      return false
    }

    var tariff_data = project.utility_tariff_proposed_data
      ? project.utility_tariff_proposed_data
      : project.utility_tariff_current_data

    if (
      tariff_data &&
      tariff_data.distributor_name &&
      tariff_data.distributor_name.length > 0 &&
      item.distributors &&
      item.distributors.indexOf(tariff_data.distributor_name) === -1
    ) {
      return false
    }

    return true
  }
  const filterByGeo = (items: ComplianceFormType[]): any[] =>
    items.filter((item: ComplianceFormType) => checkGeoFilters(item))
  const countFiles = (record: RecordType, file_tag: any) =>
    record && record.private_files_data && record.private_files_data.length
      ? record.private_files_data.filter(
          (private_file_data) =>
            private_file_data.file_tags_data.filter((file_tag_data) => file_tag_data.title === file_tag).length > 0
        ).length
      : 0
  if (notAvailableMessage) return <p>{notAvailableMessage}</p>
  return (
    <div className={`${sectionStyles.column} ${classes.fullWidth}`}>
      {filterByGeo(complianceForms).map((item, i) => (
        <div key={'form-' + i}>
          <h3 className={classes.sectionHeader}>{translate(item.heading)}</h3>
          {item.notes && <p className={classes.darkGreyColor}>{item.notes}</p>}
          {!!item.groups.length && (
            <div className={classes.formRow}>
              <div className={'fileCol'}>
                {filterByGeo(item.groups)
                  ?.filter((x) => x.heading !== 'Attached Files')
                  ?.map((itemData, j) => (
                    <FormDisplay
                      key={'formItem-' + j}
                      item={itemData}
                      filterByGeo={filterByGeo}
                      countFiles={(file_tag) => {
                        return countFiles(project, file_tag)
                      }}
                    />
                  ))}
              </div>
              <div className={sectionStyles.column}>
                {filterByGeo(item.groups)
                  ?.filter((x) => x.heading === 'Attached Files')
                  ?.map((itemData, k) => (
                    <FormDisplay
                      key={'formItem-' + k}
                      item={itemData}
                      filterByGeo={filterByGeo}
                      countFiles={(file_tag) => {
                        return countFiles(project, file_tag)
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default ServicesAccordion
