import { ButtonProps as MuiButtonPropsType, makeStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Button from 'elements/button/Button'
import inflection from 'inflection'
import PropTypes from 'prop-types'
import {
  CRUD_DELETE_MANY,
  Identifier,
  useDeleteMany,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
} from 'ra-core'
import { Confirm } from 'ra-ui-materialui'
import { FC, useState } from 'react'

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaBulkDeleteWithConfirmButton' }
)

const BulkDeleteWithConfirmButton: FC<BulkDeleteWithConfirmButtonProps> = (props) => {
  const {
    basePath,
    classes: classesOverride,
    confirmTitle,
    confirmContent,
    label,
    onClick,
    resource,
    selectedIds,
    ...rest
  } = props
  const [isOpen, setOpen] = useState(false)
  const classes = useStyles(props)
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const refresh = useRefresh()
  const translate = useTranslate()
  const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
    action: CRUD_DELETE_MANY,
    onSuccess: () => {
      refresh()
      notify('ra.notification.deleted', 'info', {
        smart_count: selectedIds.length,
      })
      unselectAll(resource)
    },
    onFailure: (error) => {
      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning')
      setOpen(false)
    },
  })

  const handleClick = (e) => {
    setOpen(true)
    e.stopPropagation()
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleDelete = (e) => {
    deleteMany()

    if (typeof onClick === 'function') {
      onClick(e)
    }
  }

  return (
    <>
      <Button onClick={handleClick} label={label} className={classes.deleteButton} {...sanitizeRestProps(rest)} />
      <Confirm
        isOpen={isOpen}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          smart_count: selectedIds.length,
          name: inflection.humanize(
            translate(`resources.${resource}.name`, {
              smart_count: selectedIds.length,
              _: inflection.inflect(resource, selectedIds.length),
            }),
            true
          ),
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  )
}

const sanitizeRestProps = ({
  basePath,
  classes,
  filterValues,
  label,
  ...rest
}: Omit<BulkDeleteWithConfirmButtonProps, 'resource' | 'selectedIds' | 'icon'>) => rest

interface Props {
  basePath?: string
  confirmContent?: string
  confirmTitle?: string
  filterValues?: any
  resource: string
  selectedIds: Identifier[]
  label?: string
}

export type BulkDeleteWithConfirmButtonProps = Props & MuiButtonPropsType

BulkDeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  resource: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
}

BulkDeleteWithConfirmButton.defaultProps = {
  confirmTitle: 'ra.message.bulk_delete_title',
  confirmContent: 'ra.message.bulk_delete_content',
  label: 'ra.action.delete',
}

export default BulkDeleteWithConfirmButton
