import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ACTIVITY, INCOMING_ORDERS, INVENTORY, INVENTORY_DASHBOARD, OUTGOING_ORDERS } from '../HardwareMenu'
import MenuItemLinkText from './MenuItemLinkText'
import { logAmplitudeEvent } from '../../../amplitude/amplitude'

const useStyles = makeOpenSolarStyles(
  (theme) => ({
    wrapper: {
      boxSizing: 'border-box',
      padding: '0px 5px',
      width: '100%',
      alignItems: 'center',
      display: 'inline-flex',
      '&:hover': {
        background: '#F5F5F5',
      },
    },
    arrow: {
      cursor: 'pointer',
      flexBasis: 1,
    },
    navLink: {
      margin: 0,
      flexGrow: 1,
      padding: '10px 0px', // extra padding allow user to easy click
      textDecoration: 'none',
    },
  }),
  { name: 'OSMenuItemLink' }
)

export type MenuItemLinkPropsType = {
  title: string
  url: string
  id: string
  ArrowIconNode?: React.ReactNode
  onClick?: () => void
}

const MenuItemLink = ({ title, url, id, ArrowIconNode, onClick }: MenuItemLinkPropsType) => {
  const classes = useStyles()
  const logEvent = () => {
    switch (title) {
      case INVENTORY_DASHBOARD:
        logAmplitudeEvent('generic_button_clicked', {
          source: title,
          context: 'inventory_LHS_nav',
        })
        break
      case INVENTORY:
        logAmplitudeEvent('generic_button_clicked', {
          source: title,
          context: 'inventory_LHS_nav',
        })
        break
      case INCOMING_ORDERS:
        logAmplitudeEvent('generic_button_clicked', {
          source: title,
          context: 'inventory_LHS_nav',
        })
        break
      case OUTGOING_ORDERS:
        logAmplitudeEvent('generic_button_clicked', {
          source: title,
          context: 'inventory_LHS_nav',
        })
        break
      case ACTIVITY:
        logAmplitudeEvent('generic_button_clicked', {
          source: title,
          context: 'inventory_LHS_nav',
        })
        break
      default:
        break
    }
  }

  return (
    <div className={classes.wrapper}>
      <NavLink
        key={title}
        to={url}
        id={id}
        activeStyle={{
          fontWeight: 'bold',
        }}
        className={classes.navLink}
        onClick={() => {
          logEvent()
          onClick && onClick()
        }}
      >
        <MenuItemLinkText label={title} />
      </NavLink>
      {!!ArrowIconNode && <span className={classes.arrow}>{ArrowIconNode}</span>}
    </div>
  )
}

export default MenuItemLink
