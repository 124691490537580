import { Dialog, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import FileCloudUpload from '@material-ui/icons/CloudUploadOutlined'
import ActionDelete from '@material-ui/icons/DeleteOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import HelpIcon from '@material-ui/icons/HelpOutlined'
import ActionHelpOutline from '@material-ui/icons/HelpOutlineOutlined'
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightnessOutlined'
import Alert from 'elements/Alert'
import { PrimaryButton } from 'elements/button/GenericButton'
import { Checkbox, IconButton } from 'opensolar-ui'
import React, { memo, useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { daysInMonth, getRangeFromTo, getRangeFromToWrapped, hasSelectedSystem, monthNames } from '../util/misc'
import ShadingControls from './ShadingControls'

// var printSunValue = function (value) {
//   if (value !== null) {
//     return Math.round(100 * (1 - value))
//   } else {
//     return '-'
//   }
// }

const pad2 = (value) => (String(value).length === 1 ? '0' + value : value)

const percentageSunAverage = (shadingOverrides, weightings) => {
  return window.Designer.getAverage(
    shadingOverrides.map((shadingOverride) => window.ShadeHelper.percentageSun(shadingOverride, weightings))
  )
}

const dashForNaN = (value) => (!isNaN(value) && value !== null ? Math.round(value) : '-')

const rgb255AsCssColor = (rgb3) => 'rgb(' + rgb3.map((v) => Math.round(v)).join(',') + ')'
const rgb255AndOpacityAsCssColor = (rgb3, opacity) =>
  'rgba(' + rgb3.map((v) => Math.round(v)).join(',') + ', ' + opacity + ')'

var white = [255, 255, 255]
var red = [208, 2, 27]
var orange = [245, 166, 35]
var green = [126, 211, 33]
// var grey = [220, 220, 220]

const colorAndOpacityForPanels = (value, sunIsInFrontOfPanels, opacity) => {
  var mid = 0.8

  if (value === null || isNaN(value)) {
    return rgb255AsCssColor(white)
  } else if (!sunIsInFrontOfPanels) {
    return rgb255AsCssColor(white)
    // return rgb255AsCssColor(grey)
  } else if (value > mid) {
    //above mid, tween between orange and green
    return rgb255AndOpacityAsCssColor(window.Designer.lerp3(orange, green, (value - mid) / (1 - mid)), opacity)
  } else {
    //below mid, tween between red and orange
    return rgb255AndOpacityAsCssColor(window.Designer.lerp3(red, orange, value / mid), opacity)
  }
}

export const combinedPercentage = (modules, weightings, diffuseShading, diffuseWeighting) => {
  if (!weightings) {
    weightings = window._.range(288).map((i) => 1.0)
  }

  if (!modules || !modules.length) {
    return '-'
  } else {
    try {
      var weightedPercentage
      var beamPercentage = percentageSunAverage(
        modules.map((module) => module.shadingOverride),
        weightings
      )

      if (diffuseShading && diffuseWeighting) {
        weightedPercentage = beamPercentage * (1 - diffuseWeighting) + 100 * (1 - diffuseShading) * diffuseWeighting
      } else {
        weightedPercentage = beamPercentage
      }

      return dashForNaN(weightedPercentage)
    } catch {
      return '-'
    }
  }
}

const useStyles = makeOpenSolarStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.themeColor,
    },
  },
}))

const StyledRadio = (props) => {
  const classes = useStyles()
  return <Radio color="default" classes={classes} {...props} />
}

/*
Redistribute opacity between 1.0 and 0.2 instead of the full range of 1.0 and 0.0 because
it is confusing if very low weighting become too close to white.
*/
const rescaleOpacity = (weighting) => weighting * 0.9 + 0.1

const ShadingCell = ({ modules, month, day, hourIndex, toUTC, shading288, sunIsInFrontOfPanels288, weighting288 }) => {
  const hourUTC = toUTC(hourIndex)
  const isSelected = window.SceneHelper.dayOfYear() === day && Math.round(window.SceneHelper.hourOfDayUTC()) === hourUTC
  const index = month * 24 + hourIndex

  // when hourIndex is "hourUTC" it can be used directly
  const weightingIndex = month * 24 + hourUTC

  const valueRaw = shading288[index]
  const value = valueRaw !== null && sunIsInFrontOfPanels288[index] ? Math.round(100.0 * valueRaw) : null
  const style = isSelected
    ? {
        textAlign: 'center',
        backgroundColor: '#ffff00',
        cursor: 'pointer',
        fontSize: value === 100 ? 8 : 11,
      }
    : {
        textAlign: 'center',
        backgroundColor: colorAndOpacityForPanels(
          shading288[index],
          sunIsInFrontOfPanels288[index],
          rescaleOpacity(weighting288[weightingIndex])
        ),
        cursor: 'pointer',
        fontSize: value === 100 ? 8 : 11,
      }

  return (
    <td
      onClick={() => {
        window.SceneHelper.animateSun(day, hourUTC)
      }}
      style={style}
    >
      {dashForNaN(value)}
    </td>
  )
}

const AnnualBeamShading = memo(({ annnualBeamAccess }) => {
  const translate = useTranslate()
  return (
    <p>
      {translate('Annual Beam Shading:')} {Math.round(annnualBeamAccess)}%
    </p>
  )
})

export const ShadingGrid = (props) => {
  const translate = useTranslate()
  var tmpDay = 0
  var days = []
  daysInMonth.forEach((daysInMonth) => {
    days.push(tmpDay)
    tmpDay += daysInMonth
  })

  const system = props.grid.getSystem()
  const disableSunAccess = system.output?.shade_metrics

  if (!window.editor.selectedSystem.raytracedShadingAvailable()) {
    return (
      <p className="small">
        {translate('Automated shading is only available when System Advisor Model (SAM) is activated.')}
      </p>
    )
  }

  if (!window.editor.scene.raytracedShadingAvailable()) {
    return (
      <p className="small">
        {translate('Automated shading is only available when at least one 3D view has been created.')}
      </p>
    )
  }

  if (props.modules && props.modules.some((m) => !m.shadingOverride || m.shadingOverride.length !== 288)) {
    return <p className="small">{translate('Calculating shading raytrace results...')}</p>
  }

  var timezoneOffsetHours = window.SceneHelper.estimateTimezoneOffset()

  // constrain between 0 and 24
  const toLocal = (hourUTC) => (hourUTC + timezoneOffsetHours + 24) % 24
  const toUTC = (hourLocal) => (hourLocal - timezoneOffsetHours + 24) % 24

  var hoursUTCSortedAndFilteredForLocalDayTime = getRangeFromToWrapped(
    0,
    23,
    timezoneOffsetHours < 0 ? -timezoneOffsetHours : 24 - timezoneOffsetHours
  ).filter((h) => toLocal(h) >= 5 && toLocal(h) <= 20)

  var hoursLocal = getRangeFromTo(5, 20)

  if (!props.grid) {
    return null
  }

  var annnualBeamAccess = 100 - combinedPercentage(props.grid.getModules(), props.weightings)

  var shading288 = Array(288).fill(null)
  var shadingMonthly = Array(12).fill(0)
  var sunIsInFrontOfPanels288 = Array(288).fill(true)

  if (props.shadeMetricsMethod === 'sun_access') {
    /*
    sun_access_288 comes from SAM which means it uses local timezone not UTC
    */

    if (!system.output?.shade_metrics?.items || !system.output?.shade_metrics?.items[props.grid.uuid]) {
      shading288 = null
    } else {
      var shadeMetrics = system.output.shade_metrics.items[props.grid.uuid]

      days.forEach((day, month) => {
        hoursLocal.forEach((hourLocal) => {
          let index = month * 24 + hourLocal

          // Cleanup SAM results where sun is behind the panel (they are not skipped because diffuse irradiance still applies)
          // we only need to check one module, not all of them
          var hourUTC = toUTC(hourLocal)
          if (props.modules[0].shadingOverride[month * 24 + hourUTC] === null) {
            sunIsInFrontOfPanels288[index] = false
          }

          shading288[index] = shadeMetrics.sun_access_288[index]
        })
      })

      shadingMonthly = shadeMetrics.sun_access_monthly.map((v) => 100 * v)
    }
  } else {
    days.forEach((day, month) => {
      hoursUTCSortedAndFilteredForLocalDayTime.forEach((hourUTC) => {
        let index = month * 24 + hourUTC
        shading288[index] = window.ShadeHelper.fractionSun(
          props.modules.map((module) => module.shadingOverride[index]),
          undefined,
          true
        )
      })
    })

    shadingMonthly = days.map((day, month) =>
      window.Designer.getAverage(
        props.modules.map((module) => window.ShadeHelper.percentageSun(module.shadingForMonth(month), props.weightings))
      )
    )
  }

  return (
    <div>
      <AnnualBeamShading annnualBeamAccess={annnualBeamAccess} />

      <p>
        Annual Diffuse Shading:{' '}
        {props.grid.diffuseShading === null ? '---' : (100 * Math.round(100 * props.grid.diffuseShading)) / 100}%
      </p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 8,
        }}
      >
        <RadioGroup
          name="shadeMetricsMethod"
          style={{ flexDirection: 'row' }}
          value={props.shadeMetricsMethod}
          onChange={(event) => {
            props.setShadeMetricsMethod(event.target.value)
          }}
        >
          <FormControlLabel
            disabled={!props.allowEdit}
            value={'shade_impact'}
            style={{ marginRight: 8 }}
            control={<StyledRadio style={{ padding: '0px 5px 0px 0px', margin: 0 }} />}
            label={<span style={{ fontSize: 13 }}>{translate('Shade Impact')}</span>}
          />
          {disableSunAccess && (
            <FormControlLabel
              disabled={!props.allowEdit}
              value={'sun_access'}
              style={{ marginRight: 8 }}
              control={<StyledRadio style={{ padding: '0px 5px 0px 0px', margin: 0 }} />}
              label={<span style={{ fontSize: 13 }}>{translate('Sun Access')}</span>}
            />
          )}
        </RadioGroup>
        <Tooltip
          style={{ padding: 0, margin: 0, width: 24 }}
          enterTouchDelay={0}
          title={
            <>
              <div>
                {translate(
                  'Shade impact is the % of plane-of-array (POA) irradiance that can be converted to electrical energy due to partial shading impact of a module array, and before other losses are accounted for.'
                )}
              </div>
              <br />
              <div>
                {translate(
                  'Sun Access is the % of plane-of-array (POA) irradiance that can reach the module group surface unobstructed.'
                )}
              </div>
            </>
          }
        >
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </div>

      {!shading288 ? (
        <p className="small">...</p>
      ) : (
        <table className="ShadingGrid" style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ backgroundColor: '#cccccc' }}></td>
              {hoursUTCSortedAndFilteredForLocalDayTime.map((hourUTC) => (
                <td style={{ backgroundColor: '#cccccc' }} key={hourUTC}>
                  {pad2(Math.floor(toLocal(hourUTC)))}
                </td>
              ))}
              <td style={{ backgroundColor: '#cccccc' }}></td>
            </tr>
            {days.map((day, month) => (
              <tr key={month}>
                <td style={{ backgroundColor: '#eeeeee' }}>{translate(monthNames[month])}</td>
                {props.shadeMetricsMethod === 'sun_access'
                  ? hoursLocal.map((hourLocal) => (
                      <ShadingCell
                        key={hourLocal}
                        month={month}
                        day={day}
                        hourIndex={hourLocal}
                        toUTC={toUTC}
                        shading288={shading288}
                        sunIsInFrontOfPanels288={sunIsInFrontOfPanels288}
                        weighting288={props.weightings}
                      />
                    ))
                  : hoursUTCSortedAndFilteredForLocalDayTime.map((hourUTC) => (
                      <ShadingCell
                        key={hourUTC}
                        month={month}
                        day={day}
                        hourIndex={hourUTC}
                        toUTC={(value) => value}
                        shading288={shading288}
                        sunIsInFrontOfPanels288={sunIsInFrontOfPanels288}
                        weighting288={props.weightings}
                      />
                    ))}
                <td style={{ backgroundColor: '#eeeeee', textAlign: 'right' }}>
                  {' '}
                  {Math.round(shadingMonthly[month] || 0)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <p>
        {translate('Timezone offset:')} {timezoneOffsetHours >= 0 ? '+' + timezoneOffsetHours : timezoneOffsetHours}{' '}
        hours
      </p>
    </div>
  )
}

const applyShadeMitigationIfSet = (value, hasPanelLevelOptimization) => {
  if (hasPanelLevelOptimization) {
    return 0.67 * value
  } else {
    return value
  }
}

const subHeaderMapper = {
  0: {
    method: 'Annual Losses',
    average: function () {
      return 0
    },
  },
  1: {
    method: 'Annual Losses',
    average: function (array, hasPanelLevelOptimization) {
      return Math.round(applyShadeMitigationIfSet(array[0] * 100, hasPanelLevelOptimization))
    },
  },
  4: {
    method: 'Season Average Losses',
    average: function (array, hasPanelLevelOptimization) {
      return Math.round(
        applyShadeMitigationIfSet(
          (array.reduce((sum, cur) => sum + cur) / array.length) * 100,
          hasPanelLevelOptimization
        )
      )
    },
  },
  12: {
    method: 'Monthly Average Losses',
    average: function (array, hasPanelLevelOptimization) {
      return Math.round(
        applyShadeMitigationIfSet(
          (array.reduce((sum, cur) => sum + cur) / array.length) * 100,
          hasPanelLevelOptimization
        )
      )
    },
  },
}

const HorizonShadingDialog = (props) => {
  const translate = useTranslate()
  const fileInput = React.useRef()
  return (
    <Dialog open={props.dialogIsOpened} style={{ maxHeight: 'calc(100vh - 56px)' }} className="site-detail">
      {/* <DialogTitle style={{ borderBottom: '1px solid rgb(108, 108, 108)' }}>{translate('Site Detail')}</DialogTitle> */}
      <DialogTitle>
        {translate('Horizon Shading')}{' '}
        <a
          style={{
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
            color: 'rgb(24, 144, 255)',
            cursor: 'pointer',
          }}
          href="https://support.opensolar.com/hc/en-us/articles/900004041366"
          target="_blank"
          rel="noreferrer"
        >
          ({translate('learn more')})
          <ActionHelpOutline
            style={{ fill: 'rgb(24, 144, 255)', width: 24, height: 24, marginLeft: 10, verticalAlign: 'sub' }}
          />
        </a>
      </DialogTitle>
      <DialogContent>
        <IconButton
          id="SiteDetailCloseIcon"
          style={{
            top: 10,
            right: 10,
            position: 'absolute',
            minWidth: 40,
            backgroundColor: 'rgba(255, 255, 255, 0)',
          }}
          onClick={() => props.setDialogIsOpened(false)}
        >
          <Tooltip title={translate('Close Dialog')} enterDelay={300}>
            <CloseIcon style={{ color: '#000000' }} />
          </Tooltip>
        </IconButton>

        <div style={{ margin: '0 20px 20px' }}>
          {window.SceneHelper.getHorizon() && (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translate('Uploaded File')}:
                <span style={{ margin: '0 5px', color: 'rgb(108, 108, 108)' }}>
                  {window.SceneHelper.getHorizon().fileName}
                </span>
                <IconButton
                  style={{ color: '#000000' }}
                  onClick={() => {
                    window.SceneHelper.clearHorizon()
                    window.Designer.showNotification(translate('Horizon file removed successfully'), 'info')
                    fileInput.current.value = ''
                  }}
                >
                  <ActionDelete style={{ width: 20, height: 20 }} />
                </IconButton>
              </div>
              <hr style={{ borderTop: '1px solid' }} />
            </>
          )}

          {
            <>
              <div>
                <h2>{translate('Import far-horizon from PVGIS (Beta)')}</h2>
                <p>
                  Far-horizon data can be generated and imported automatically from{' '}
                  <a href="https://ec.europa.eu/jrc/en/pvgis" target="_blank" rel="noreferrer">
                    PGVIS
                  </a>
                  . PVGIS (c) European Union, 2001-2021.
                </p>
                <PrimaryButton
                  style={{
                    minWidth: 40,
                    margin: '5px 5px 5px 0px',
                  }}
                  labelWrapperStyle={{
                    minWidth: 32,
                    textTransform: 'none',
                    color: 'rgba(0, 0, 0, 0.87)',
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  endIcon={<FileCloudUpload />}
                  onClick={() => {
                    window.SceneHelper.loadHorizonFromPVGIS()
                  }}
                  label="Import"
                />
              </div>
              <hr style={{ borderTop: '1px solid' }} />
            </>
          }

          <input
            accept={'.hor'}
            ref={fileInput}
            type="file"
            id="uploadHorizonFile"
            hidden
            onChange={(e) => window.editor.loader.loadFile(e.target.files[0], window.Designer.showNotification)}
          />
          <h2>{translate('Upload')}</h2>
          <PrimaryButton
            style={{
              minWidth: 40,
              margin: '5px 5px 5px 0px',
            }}
            labelWrapperStyle={{
              minWidth: 32,
              textTransform: 'none',
              color: 'rgba(0, 0, 0, 0.87)',
              paddingLeft: 8,
              paddingRight: 8,
            }}
            endIcon={<FileCloudUpload />}
            onClick={() => fileInput.current.click()}
            label="Upload New Horizon File"
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

const ExpansionPanelShading = ({ ...props }) => {
  const translate = useTranslate()
  const [dialogIsOpened, setDialogIsOpened] = useState(false)
  const [shadeMetricsMethod, setShadeMetricsMethod] = useState('shade_impact') //shade_impact or sun_access

  const [panelIsExpanded, setPanelIsExpanded] = useState(Boolean(window.Designer._shadingVisibility))
  const handleChange = useCallback((expanded) => {
    // This is a hack to work with slow, synchronous function within React. Let js thread render the DOM first before processing expensive tasks
    window.setTimeout(function () {
      if (expanded === true && window.editor.scene.raytracedShadingAvailable()) {
        window.Designer.shadingVisibility(true)
      } else {
        window.Designer.shadingVisibility(false)
      }
      if (window.editor && window.editor.signals) {
        window.editor.signals.expansionPanelChanged.dispatch(expanded)
      }
    }, 1)
  }, [])
  // Only show for ModuleGrid of System
  if (
    !hasSelectedSystem() ||
    !props.object ||
    (props.object.type !== 'OsModuleGrid' && props.object.type !== 'OsSystem')
  ) {
    return null
  }

  var shadingPercentageLabel, weightings

  if (props.object.type === 'OsModuleGrid') {
    var grid = props.object

    weightings = window.ShadeHelper.getOrBuildCacheSunAlignmentDateTimeUTC(
      window.SceneHelper.getLongitude(),
      window.SceneHelper.getLatitude(),
      grid.getPanelTilt(),
      grid.getAzimuth()
    )

    var diffuseWeighting = 0.15
    var diffuseShading = grid.diffuseShading
  } else if (props.object.type === 'OsSystem') {
  } else {
    return null
  }

  const shadingArray = props.object.detectInheritedShadingOverride()
  var shadingObject

  const hasRayTracing =
    (window.editor.selectedSystem.raytracedShadingAvailable() || window.editor.scene.raytracedShadingAvailable()) &&
    !props.object.hasShadingOverride()

  if (hasRayTracing) {
    // Use combinedPercentage for raytraced values, see below
    shadingObject = {
      method: 'Annual Sun Access',
      average: null,
    }
  } else {
    shadingObject = subHeaderMapper[shadingArray.length]
  }

  if (shadingObject.average) {
    shadingPercentageLabel =
      shadingObject.average(
        shadingArray,
        props.object.hasPanelLevelOptimization &&
          props.object.hasPanelLevelOptimization() &&
          props.allow_shade_mitigation
      ) + '%'
  } else {
    shadingPercentageLabel =
      combinedPercentage(props.object.getModules(), weightings, diffuseShading, diffuseWeighting) +
      '%' +
      (shadingObject.suffix || '')
  }

  if (
    props.object.calculator === 1 &&
    props.object.hasPanelLevelOptimization &&
    props.object.hasPanelLevelOptimization() === true &&
    props.allow_shade_mitigation
  ) {
    shadingPercentageLabel += ' (SMF: 33%)'
  }

  return (
    <Accordion
      className="Accordion"
      style={props.style}
      TransitionProps={{
        timeout: 0,
      }}
      classes={{ expanded: 'ExpansionPanelExpanded' }}
      onChange={(event, expanded) => {
        setPanelIsExpanded(expanded)
        handleChange(expanded)
      }}
    >
      <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
        <div>
          {translate('Shading')}
          <br />
          <span
            style={{
              fontSize: '12px',
              color: 'rgb(108, 108, 108)',
              overflow: 'hidden',
              lineHeight: '14px',
              display: 'inline-block',
            }}
          >
            {translate(shadingObject.method)}
            {': '}
            {shadingPercentageLabel}
          </span>
        </div>
      </AccordionSummary>

      {panelIsExpanded && (
        <AccordionDetails className="AccordionDetails">
          <div style={{ width: '100%' }}>
            <ShadingControls
              setStateParent={props.setStateParent}
              object={props.object}
              state={props.state}
              handleSetShadingOverride={props.handleSetShadingOverride}
              allowEdit={props.allowEdit}
            />
            <div style={{ clear: 'both' }}></div>

            {props.object.type === 'OsModuleGrid' && hasRayTracing && (
              <ShadingGrid
                shadeMetricsMethod={shadeMetricsMethod}
                setShadeMetricsMethod={setShadeMetricsMethod}
                modules={props.modulesForShadingGrid}
                grid={props.object}
                weightings={weightings}
                allowEdit={props.allowEdit}
              />
            )}

            {props.object.type === 'OsSystem' &&
              props.object.calculator === 1 &&
              props.object.hasPanelLevelOptimization &&
              props.object.hasPanelLevelOptimization() === true &&
              props.handleAllowShadeMitigation && (
                <FormControlLabel
                  style={{ marginTop: 20 }}
                  control={
                    <Checkbox
                      id="PanelSystemAllowShadeMitigation"
                      disabled={!props.allowEdit}
                      checked={props.allow_shade_mitigation}
                      onChange={(event) => props.handleAllowShadeMitigation(event.target.checked)}
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>{translate('Apply 33% Shade Mitigation')}</span>}
                />
              )}

            {props.object.type === 'OsSystem' &&
              props.object.calculator === 1 &&
              props.object.hasPanelLevelOptimization &&
              props.object.hasPanelLevelOptimization() === true && (
                <div style={{ fontSize: 12, marginTop: 10 }}>
                  {translate(
                    'Shade Mitigation Factor (SMF) of 33% is applied for microinverters or optimizers by default. For more details see'
                  )}{' '}
                  <a href="https://www.nrel.gov/docs/fy15osti/63463.pdf" target="_blank" rel="noreferrer">
                    {translate('PVWatts documentation')}
                  </a>
                </div>
              )}

            {props.object.type === 'OsModuleGrid' && (
              <div style={{ marginBottom: 20 }}>
                <PrimaryButton
                  style={{
                    minWidth: 40,
                    margin: '0px 5px 5px 0px',
                  }}
                  wrapperStyle={{ display: 'inline-block' }}
                  disabled={!props.allowEdit || !hasRayTracing}
                  labelWrapperStyle={{
                    minWidth: 32,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  startIcon={<SettingsBrightnessIcon />}
                  onClick={(e) => {
                    setDialogIsOpened(true)
                  }}
                  label="Horizon Shading"
                />
                <HorizonShadingDialog setDialogIsOpened={setDialogIsOpened} dialogIsOpened={dialogIsOpened} />
                {!hasRayTracing && (
                  <Alert severity="warning">
                    <div style={{ fontSize: 12 }}>
                      {translate(
                        'Horizon shading is only available when System Advisor Model (SAM) is active and a 3D view has been created'
                      )}
                    </div>
                  </Alert>
                )}
              </div>
            )}
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default ExpansionPanelShading
