import { isNestedWindow } from 'opensolar-sdk'
import { useSdkBridge } from './useSdkBridge'

export const SdkMounter = () => {
  useSdkBridge()
  return <></>
}

export const SdkMounterIfNested = () => {
  let nested = isNestedWindow()
  // Don't mount the SDK at all when running as the top level app
  // This will need to change once:'
  //  - Sales Machine uses the SDK
  //  - Or, we're using SDK to config/validate vanity URL versions of OS
  if (!nested) return <></>
  else return <SdkMounter />
}
