import { makeStyles, Tooltip } from '@material-ui/core'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { OpenSolarThemeType } from 'Themes'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'

type PropTypes = {
  milestone?: ExpectedMilestonePaymentType
  paymentRequest?: PaymentRequestType
  allPaymentRequests?: PaymentRequestType[] // can be used when this component is passed a specifiic payment request
  // this component looks through all requests and finds the one that matches the given milestone
  extraText?: string | undefined
}

const successStatusValues = ['Paid', 'Approved']
const errorStatusValues = ['Overdue', 'Chargeback Pending', 'Payment Failed', 'Refund Pending']
const warningErrorValues = ['Cancelled', 'Chargeback Complete', 'Partially Paid', 'Refunded']
const infoStatusValues = ['Viewed', 'Sent', 'Awaiting Transfer', 'Other', 'Pending']
const plainStatusValues = ['Not Sent', 'Other']

const useStyles = makeStyles<OpenSolarThemeType, { paymentStatus: string | undefined }>((theme) => ({
  statusText: {
    backgroundColor: ({ paymentStatus }) => {
      if (!paymentStatus || plainStatusValues.includes(paymentStatus)) {
        return undefined
      } else if (successStatusValues.includes(paymentStatus)) {
        return 'rgb(215, 233, 220)'
      } else if (errorStatusValues.includes(paymentStatus)) {
        return 'rgb(249, 227, 226)'
      } else if (warningErrorValues.includes(paymentStatus)) {
        return 'rgb(245, 190, 79)'
      } else if (infoStatusValues.includes(paymentStatus)) {
        return 'rgb(236, 240, 250)'
      }
    },
    color: ({ paymentStatus }) => {
      if (!paymentStatus || plainStatusValues.includes(paymentStatus)) {
        return undefined
      } else if (successStatusValues.includes(paymentStatus)) {
        return 'rgb(43, 100, 46)'
      } else if (errorStatusValues.includes(paymentStatus)) {
        return 'rgb(195, 101, 97)'
      } else if (warningErrorValues.includes(paymentStatus)) {
        return theme.greyDark2
      } else if (infoStatusValues.includes(paymentStatus)) {
        return 'rgb(27, 60, 146)'
      } else return undefined
    },
    border: ({ paymentStatus }) => {
      if (paymentStatus && plainStatusValues?.includes(paymentStatus)) {
        return `1px solid ${theme.greyLight2}`
      } else return undefined
    },
    padding: '4px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const PAYMENT_STATUS_DESC_MAP = {
  'Not Sent': 'Invoice has not yet been sent to the customer.',
  Sent: 'Invoice and payment link has been sent to the customer.',
  Viewed: 'Payment link has been viewed by the customer but the payment is not yet complete.',
  'Awaiting Transfer': 'Customer saw the payment instructions but has not yet taken action.',
  Pending: 'Customer authorized the payment, but it has not yet been confirmed.',
  Approved: 'Customer payment was approved and will reach your bank account shortly.',
  'Partially Paid': 'Partial payment was complete and sent to your bank account.',
  Paid: 'Payment has been sent to your bank account.',
  Overdue: 'Payment was not authorized prior to the due date.',
  Cancelled: 'Invoice was cancelled by your team.',
  'Payment Failed':
    'Payment was not successful. Customer has been prompted to retry but you can send the payment link again.',
  'Chargeback pending': 'Customer has initiated chargeback procedure. OpenSolar will be in contact about next steps.',
  'Chargeback complete': 'Chargeback has been processed.',
  'Refund Pending': 'Refund process has been initiated but is not yet complete.',
  Refunded: 'Payment was refunded by your team.',
  Other: 'Invoice is in an exception status - reach out to OpenSolar for more information.',
}

const PaymentRequestStatusChip: React.FC<PropTypes> = (props) => {
  const status = useMemo(() => {
    let paymentRequest = props.paymentRequest
    if (!paymentRequest && props.milestone && props.allPaymentRequests?.length) {
      // If there are multiple payment requests (maybe some are cancelled),
      // sort the payment requests by date_requested to get the latest payment request for a milestone
      const sortedPaymentRequests = props.allPaymentRequests
        .filter((pmt) => pmt.status !== 'cancelled')
        .slice()
        .sort((a, b) => new Date(b.date_requested || 0).valueOf() - new Date(a.date_requested || 0).valueOf())
      paymentRequest = sortedPaymentRequests?.find((req) => {
        return (
          (req.payment_milestone_configuration === props.milestone?.payment_milestone_configuration_id &&
            Boolean(props.milestone?.payment_milestone_configuration_id)) ||
          (req.payment_milestone_override_uuid === props.milestone?.override_uuid &&
            Boolean(props.milestone?.override_uuid))
        )
      })
    }
    // payment requests should be given priority if available
    if (paymentRequest) {
      switch (paymentRequest.status) {
        case 'requested':
          return 'Sent'
        case 'cancelled':
          return 'Cancelled'
        case 'expired':
          return 'Overdue'
        case 'chargeback pending':
          return 'Chargeback Pending'
        case 'chargeback complete':
          return 'Chargeback Complete'
        case 'paid in full':
          return 'Approved'
        case 'partially paid':
          return 'Partially Paid'
        case 'payment failed':
          return 'Payment Failed'
        case 'refund pending':
          return 'Refund Pending'
        case 'refunded':
          return 'Refunded'
        case 'transfer instructions viewed':
          return 'Awaiting Transfer'
        case 'viewed':
          return 'Viewed'
        case 'unsent':
          return 'Not Sent'
        case 'pending':
          return 'Pending'
        case 'completed':
          return 'Paid'
        default:
          return 'Other'
      }
    } else if (props.milestone) {
      if (props.milestone.is_paid) return 'Paid'
      else if (props.milestone.payment_request_id) return 'Sent'
      else return 'Not Sent'
    }
  }, [props.milestone, props.paymentRequest, props.allPaymentRequests])

  const classes = useStyles({ paymentStatus: status })
  const translate = useTranslate()

  if (!props.paymentRequest && !props.milestone) return null
  if (!status) return null

  return (
    <Tooltip title={translate(PAYMENT_STATUS_DESC_MAP[status])}>
      <div className={classes.statusText}>
        {translate(status)}
        {props.extraText ? ` - ${props.extraText}` : ''}
      </div>
    </Tooltip>
  )
}
export default PaymentRequestStatusChip
