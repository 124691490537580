import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import Button from 'elements/button/Button'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  button: { margin: 5 },
}))

type HdmConnectUserButtonProps = {
  variant: 'outlined' | 'contained' | 'text'
  trackSource: string
  color?: 'default' | 'primary' | 'secondary' | 'warning'
  fullWidth?: boolean
  label?: string
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const HdmConnectUserButton: React.FC<HdmConnectUserButtonProps> = ({
  variant,
  trackSource,
  color = 'default',
  fullWidth = false,
  label = 'Connect HDM',
}) => {
  const classes = useStyles()
  // const [confirmingConnect, setConfirmingConnect] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const history = useHistory()

  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({ orgId, distributor: 'hdm' })

  useEffect(() => {
    if (isAuthenticated && !isChecking) {
      setIsConnected(true)
    }
  }, [isAuthenticated, isChecking])

  const onClick = () => {
    // Handle button click logic here

    logAmplitudeEvent('hardware_distributor_connect_button_clicked', {
      source: trackSource,
      distributor: 'hdm',
    })
    if (isConnected) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + '/distributors/hdm/disconnect/',
      })
        .then((response) => {
          setIsConnected(false)
        })
        .catch((err: any) => {
          console.log(err, err)
        })
    } else {
      history.push('/shop/connect_distributor/hdm/email')
    }
  }

  return (
    <>
      <Button
        onClick={onClick}
        variant={variant}
        color={color}
        label={isConnected ? 'Disconnect HDM' : label}
        disabled={false}
        className={classes.button}
        fullWidth={fullWidth}
      />
      {/* <Confirm
        isOpen={confirmingConnect}
        title="Connect to HDM"
        content="You will be redirected to the HDM login page to connect your accounts, please confirm to proceed. Any unsaved changes will be discarded."
        confirmColor="warning"
        onConfirm={() => handleLogin()}
        onClose={() => setConfirmingConnect(false)}
      /> */}
    </>
  )
}

export default HdmConnectUserButton
