import { InputWrapper, StyledDateInput } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { TextInput } from 'react-admin'

const SpecialEventContent: React.FC = (props) => {
  const translate = useTranslate()
  return (
    <InputWrapper>
      <h3>{translate('Task Details')}</h3>
      <TextInput source={'title'} variant="outlined" label={'Task Type'} disabled />
      <StyledDateInput
        disabled
        source={'start'}
        inputVariant="outlined"
        label={'Start Date'}
        options={{ format: 'MM/DD/YYYY' }}
      />

      <TextInput source={'notes'} variant="outlined" label={'Notes'} multiline rows={3} />
    </InputWrapper>
  )
}
export default SpecialEventContent
