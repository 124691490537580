import { Grid } from '@material-ui/core'
import { triggerSDKTokenRefresh } from 'ducks/projectMilestones'
import Alert from 'elements/Alert'
import CustomField from 'elements/field/CustomField'
import { Button } from 'opensolar-ui'
import { renderInput } from 'pages/auth/common'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BlueSnapHostedField from '../commonComponents/BlueSnapHostedField'
import {
  BlueSnapCardDataType,
  BlueSnapCardSubType,
  CreditCardPaymentMethodType,
  DebitCardPaymentMethodType,
  PaymentRequestType,
} from '../types'
import { useGetBlueSnapToken } from '../utils'
import { getCCTypeError, getOpenSolarCCType, initBlueSnapCreditCardForm, submitBlueSnapCardData } from './utils'

type PropTypes = {
  projectId: string
  doSubmitPayment: (cardData?: BlueSnapCardDataType) => void
  paymentRequestData: PaymentRequestType
  countryIso2: string
  cancelEdit: () => void
}

type ErrorMapType = {
  ccn: string | undefined
  exp: string | undefined
  cvv: string | undefined
}

const useStyles = makeOpenSolarStyles((theme) => ({
  ccInput: {
    margin: 0,
    marginTop: '5px',
    width: '100%',
    backgroundColor: '#fff',
  },

  nextBtn: {
    background: '#4272DD',
    color: theme.white,
    fontSize: 13,
    border: '1px solid #4272DD',
    float: 'right',
    margin: '10px 0',

    '&:hover': {
      background: '#4272DD !important',
      color: theme.white,
      fontSize: 13,
      border: '1px solid #4272DD',
    },
  },
  cancelBtn: {
    fontSize: 13,
    margin: '10px 0',
  },
  cancelWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
}))

const CreditCardForm: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const formState = useFormState()
  const translate = useTranslate()
  const firstName = formState.values?.first_name
  const lastName = formState.values?.last_name
  const zipCode = formState.values?.zip_code

  const isEditing = Boolean(formState.values?.saved_payment_method_type)

  // having the blue snap fields live in an iframe form means we need to some kind of clunky things to figure out if the fields
  // are valid or what error message we should show. errorMap is used to track the errorCode on a field and pass that error code to the
  // child component
  const [errorMap, setErrorMap] = useState<ErrorMapType>({ ccn: undefined, exp: undefined, cvv: undefined })
  const [ccError, setCCError] = useState<string | undefined>(undefined)
  const [openSolarCCType, setOpenSolarCCType] = useState<
    CreditCardPaymentMethodType | DebitCardPaymentMethodType | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // then we separately track whether each field is valid. To be valid it most be populated
  const [ccnIsValid, setCcnIsValid] = useState<boolean>(false)
  const [expIsValid, setExpIsValid] = useState<boolean>(false)
  const [cvvIsValid, setCvvIsValid] = useState<boolean>(false)
  const [shouldTriggerTokenRefresh, setShouldTriggerTokenRefresh] = useState<boolean>(false)

  const notify = useNotify()
  const dispatch = useDispatch()
  const { token } = useGetBlueSnapToken(
    props.projectId,
    props.paymentRequestData.payment_request_id,
    props.paymentRequestData?.org_id
  )

  const onFieldValidationChange = useCallback(
    (fieldName: string, errorCode: number | undefined = undefined, errorDescription: string | undefined) => {
      if (errorDescription === 'tokenAlreadyUsed') {
        setShouldTriggerTokenRefresh(true)
      } else {
        switch (fieldName) {
          case 'ccn':
            setCcnIsValid(errorCode === undefined)
            return
          case 'exp':
            setExpIsValid(errorCode === undefined)
            return
          case 'cvv':
            setCvvIsValid(errorCode === undefined)
            return
          default:
            return
        }
      }
    },
    [errorMap]
  )

  const onCardTypeReady = (cardType: string, cardSubType: BlueSnapCardSubType, issuingCountry: string) => {
    const newCCType = getOpenSolarCCType(cardType, cardSubType, issuingCountry, props.countryIso2)
    setOpenSolarCCType(newCCType)
  }

  useEffect(() => {
    if (token) {
      initBlueSnapCreditCardForm(token, onFieldValidationChange, onCardTypeReady)
      // if the token was refreshed because the current one triggered an error for already being used, setShouldTriggerTokenRefresh to false to restart
      if (shouldTriggerTokenRefresh) {
        setShouldTriggerTokenRefresh(false)
      }
    }
  }, [token])

  useEffect(() => {
    if (shouldTriggerTokenRefresh) {
      dispatch(triggerSDKTokenRefresh())
    }
  }, [shouldTriggerTokenRefresh])

  useEffect(() => {
    let newError = getCCTypeError(openSolarCCType, props.paymentRequestData.payment_methods)
    setCCError(newError)
  }, [openSolarCCType])

  const onSuccess = useCallback(
    (cardData: BlueSnapCardDataType) => {
      setIsLoading(false)
      if (firstName && lastName && zipCode && cardData && token) {
        cardData.token = token
        props.doSubmitPayment(cardData)
      } else {
        notify(translate('Please fill out all required fields'))
      }
    },
    [firstName, lastName, zipCode, token]
  )

  const onBlueSnapErrorOnSubmit = (errMsg: string) => {
    // TODO
    alert(errMsg)
  }

  const validateAndSubmitToBlueSnap = useCallback(() => {
    setIsLoading(true)
    if (!firstName) notify(translate('Cardholder first name is required'), 'warning')
    else if (!lastName) notify(translate('Cardholder last name is required'), 'warning')
    else if (!zipCode) notify(translate('Zip Code is required'), 'warning')
    else {
      submitBlueSnapCardData(onSuccess, onBlueSnapErrorOnSubmit)
    }
  }, [firstName, lastName, zipCode, onSuccess])

  const disableButton = useMemo(() => {
    return !ccnIsValid || !expIsValid || !cvvIsValid || !firstName || !lastName || !zipCode || Boolean(ccError)
  }, [ccnIsValid, expIsValid, cvvIsValid, firstName, lastName, zipCode, ccError])

  return (
    <div>
      <Grid container spacing={1}>
        {ccError && (
          <Grid item xs={12}>
            <Alert severity="error">{translate(ccError)}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <BlueSnapHostedField
            inputType="div"
            fieldName="ccn"
            errorCode={errorMap?.ccn}
            fieldLabel={translate('Credit Card Number')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlueSnapHostedField
            inputType="div"
            fieldName="exp"
            errorCode={errorMap?.exp}
            fieldLabel={translate('Expiration Date')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlueSnapHostedField
            inputType="div"
            fieldName="cvv"
            errorCode={errorMap?.cvv}
            fieldLabel={translate('CVV')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <span>{translate('Cardholder First Name')}</span>
          <CustomField className={classes.ccInput} name="first_name" component={renderInput} variant="outlined" />
        </Grid>
        <Grid item xs={12} md={6}>
          <span>{translate('Cardholder Last Name')}</span>
          <CustomField className={classes.ccInput} name="last_name" component={renderInput} variant="outlined" />
        </Grid>
        <Grid item xs={12} md={6}>
          <span>{translate('Zip Code')}</span>
          <CustomField className={classes.ccInput} name="zip_code" component={renderInput} variant="outlined" />
        </Grid>
        {props.paymentRequestData.surcharging_enabled && (
          <Grid item xs={12}>
            <div className="small">
              {translate(
                'Please note: A credit card processing fee may apply to your payment. Click next to view your final payment amount for this card.'
              )}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div>
            {isEditing && (
              <Button onClick={props.cancelEdit} variant="contained" color="default" className={classes.cancelBtn}>
                {translate('Cancel')}
              </Button>
            )}
            <Button
              onClick={validateAndSubmitToBlueSnap}
              variant="contained"
              disabled={disableButton || isLoading}
              className={classes.nextBtn}
            >
              {translate('Next')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default CreditCardForm
