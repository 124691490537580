import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { ExpectedMilestonePaymentType } from 'types/paymentOptions'
import { useIsCalculating } from '../utils'
import MilestoneOverrideDialog from './MilestoneOverrideDialog'

type PropTypes = {
  milestones: ExpectedMilestonePaymentType[]
  projectId: number
  systemUuid: string
  orgId: number
  paymentOptionId: number
  pricePayable: number
}

const MilestoneOverrideButton: React.FC<PropTypes> = (props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)

  const isCalculating = useIsCalculating(props.systemUuid)
  const translate = useTranslate()

  const closeDialog = () => {
    setDialogIsOpen(false)
  }

  const hideButton = useMemo(() => {
    if (props.milestones?.length === 0) return true
    const system = window.editor.getSystems()?.find((sys) => sys.uuid === props.systemUuid)
    const paymentOption = system?.payment_options?.find((pmt) => pmt.id === props.paymentOptionId)
    return paymentOption?.payment_type !== 'cash'
  }, [props.milestones])

  useEffect(() => {
    if (dialogIsOpen) {
      logAmplitudeEvent('cashflow_adjust_payments_dialog_launched', {
        project_id: props.projectId,
        org_id: props.orgId,
        source: 'invoices table',
      })
    }
  }, [dialogIsOpen])

  if (hideButton) return null
  return (
    <div>
      <Button disabled={isCalculating} onClick={() => setDialogIsOpen(true)} variant="contained" size="small">
        {translate('Adjust Payments')}
      </Button>
      {dialogIsOpen && <MilestoneOverrideDialog onClose={closeDialog} {...props} />}
    </div>
  )
}
export default MilestoneOverrideButton
