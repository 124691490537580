import parseTariffInitialValues from 'projectSections/sections/energy/parseTariffInitialValues'
import parseDesignInitialValues from '../sections/design/parseDesignInitialValues'
import parseSimulateFirstYearValues from '../sections/design/parseSimulateFirstYearValues'
import parseUsageInitialValues from '../sections/energy/parseUsageInitialValues'
import parseContactInitialValues from '../sections/info/contacts/parseContactInitialValues'
import parseProjectDatesInitialValues from '../sections/info/parseProjectDatesInitialValues'
import pipe from './pipe'

export const parseInitialValues = (formValues, rawData = {}) => {
  const initialValues = formValues
  try {
    pipe(
      parseContactInitialValues,
      parseUsageInitialValues,
      parseSimulateFirstYearValues,
      parseProjectDatesInitialValues,
      parseDesignInitialValues,
      parseTariffInitialValues
    )(initialValues, rawData)
  } catch (e) {
    console.warn('Error in parseInitialValues: ', e)
    return formValues
  }
  return initialValues
}
