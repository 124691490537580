import { MenuItem } from '@material-ui/core'
import CustomSelectField from 'elements/field/CustomSelectField'
import { Alert } from 'opensolar-ui'
import React, { FC, memo } from 'react'
import { useTranslate } from 'react-admin'
import { getRange } from 'util/misc'
import {
  getSystemYearSimulationYear,
  useGetMaxSystemLifetime,
  useGetRequiredLifetimeByIntegration,
  useGetSystemLifetimeLocked,
} from './utils'

type PropsType = {
  system_lifetime: number
  systemUuid: string
}

const LifetimeSelectInput: FC<PropsType> = ({ system_lifetime, systemUuid }) => {
  const translate = useTranslate()
  //limit options to max panel warranty year
  const maxSystemLifetime = useGetMaxSystemLifetime()
  const lifetimeInputDisabled = useGetSystemLifetimeLocked()
  const lifetimeRequiredbyIntegration = useGetRequiredLifetimeByIntegration()
  const system = window.editor.objectByUuid(systemUuid)
  console.log('system_lifetime', system_lifetime)
  return (
    <>
      {lifetimeInputDisabled && (
        <Alert severity="info">
          {translate('System Lifetime is locked due to the requirements of a finance integration')}
        </Alert>
      )}
      <CustomSelectField
        label={translate('System Lifetime')}
        value={system_lifetime}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const years = event.target.value
          window.editor.execute(new window.SetValueCommand(system, 'system_lifetime', years))
        }}
        disabled={lifetimeInputDisabled}
      >
        {getRange(maxSystemLifetime + 1).map((i, index) => (
          <MenuItem key={index} value={i - 1}>
            {index === 0
              ? `${translate('Default')} (${getSystemYearSimulationYear(
                  system,
                  lifetimeRequiredbyIntegration
                )}${translate('Years')})`
              : i - 1 + ' ' + translate('Years')}
          </MenuItem>
        ))}
        {/* handle edge case like module get changed after manually select simulate year */}
        {maxSystemLifetime < system_lifetime && (
          <MenuItem key={system_lifetime} value={system_lifetime}>
            {system_lifetime + translate('Years')}
          </MenuItem>
        )}
      </CustomSelectField>
    </>
  )
}

export default memo(LifetimeSelectInput)
