import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import ProjectName from 'elements/ProjectName'
import { getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { Link } from 'react-admin'

interface PropTypes {
  record: any
}

const AddressDisplay: React.FC<PropTypes> = ({ record }) => {
  const classes = getProjectSectionStyles()

  return (
    <div className={classes.row} style={{ alignItems: record.business_name ? 'flex-start' : 'center' }}>
      <Link
        to={record.is_lite ? `/projects/${record.id}/design` : `/projects/${record.id}/manage`}
        className={classes.row}
      >
        <ProjectName business_name={record.business_name} address={record.address} />
      </Link>
      {!!record.shared_with?.length && (
        <SharedEntityIcon
          owner={record.org_id}
          owner_name={record.org_name}
          shared_with={record.shared_with}
          margin="wide"
          iconBtnStyles={{ paddingTop: record.business_name ? 3 : undefined }}
          version={3}
        />
      )}
    </div>
  )
}

export default AddressDisplay
