import React from 'react'

import withMediaQuery from 'layout/withMediaQuery'
import ProUXPanel from 'projectSections/sections/design/Panel'
import { withTranslate } from 'react-admin'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { StudioItem } from 'types/global'

type StudioPanelContextType = {
  context: React.Component
  object: StudioItem
}

export const StudioPanelContext = React.createContext<StudioPanelContextType | null>(null)

const PanelSwitcher = (props) => {
  return <ProUXPanel {...props} />
}

const enhance = compose(withTranslate, withMediaQuery, withStudioSignals)
export default enhance(PanelSwitcher)
