import { logAmplitudeEvent } from 'amplitude/amplitude'
import BannerAd from 'elements/BannerAd'
import { useEffect } from 'react'
import type { ComponentTypes } from 'types/selectComponent'
import HomeTopBanner from '../banner/HomeTopBanner'
import CollectionBlock from './CollectionBlock'

type ConfigType = {
  title: string
  viewMoreLink: string
  componentType: ComponentTypes
}

const COLLECTION_CONFIG: ConfigType[] = [
  {
    title: 'Panels',
    componentType: 'module',
    viewMoreLink: '/shop/search/modules',
  },
  {
    title: 'Inverters',
    componentType: 'inverter',
    viewMoreLink: '/shop/search/inverters',
  },
  {
    title: 'Batteries',
    componentType: 'battery',
    viewMoreLink: '/shop/search/batteries',
  },
  {
    title: 'Mounting + BOS',
    componentType: 'other',
    viewMoreLink: '/shop/search/others',
  },
]

const OrderingHome = () => {
  useEffect(() => {
    logAmplitudeEvent('page_viewed', {
      page: 'hardware_home',
    })
  }, [])
  return (
    <div>
      <HomeTopBanner />

      {COLLECTION_CONFIG.map((config) => (
        <CollectionBlock
          trackSource="hardware_home"
          key={config.title}
          title={config.title}
          componentType={config.componentType}
          viewMoreLink={config.viewMoreLink}
        />
      ))}

      <BannerAd
        formats={['leaderboard']}
        placement={`shop_home_bottom`}
        containerStyle={{ marginTop: 10, marginBottom: 20 }}
        requiredWindowSize={{ width: 960 }}
      />
    </div>
  )
}

export default OrderingHome
