import { Divider, FormControlLabel, makeStyles, MenuItem, Slider, TextField, Tooltip } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import FileCloudUpload from '@material-ui/icons/CloudUploadOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  PERFORMANCE_CALCULATORS,
  PERFORMANCE_CALCULATORS_3RD_PARTY,
  PERFORMANCE_CALCULATORS_MCS,
} from 'constants/calculators'
import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import CustomSelectField from 'elements/field/CustomSelectField'
import { Button, Checkbox } from 'opensolar-ui'
import TariffOverride from 'projectSections/sections/design/systems/TariffOverride'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocale } from 'react-admin'
import ChartistGraph from 'react-chartist'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { formatCurrency, formatCurrencyWithSymbol, getRange, monthNames, numberWithCommas, urlToId } from 'util/misc'
import { convert24AmPm, EMPTY24 } from 'util/time'
import useTranslateParse from '../../../../../../util/useTranslateParse'
import useTranslateWithVariable from '../../../../../hooks/useTranslateWithVariable'
import DeleteSystemButton from '../../DeleteSystemButton'
import { inputValueToFloatOrEmptyStringWhenNull, PriceOverrideLink, valueOrEmptyStringIfNull } from '../../PanelSystem'
import PanelGenericWarningBox from '../../warning/PanelGenericWarningBox'
import PaymentOptionRefreshReminder from '../paymentOptions/PaymentOptionRefreshReminder'
import HardwareSection from './hardware/HardwareSection'
import LifetimeSelectInput from './LifetimeSelectInput'
import SystemLosses from './SystemLosses'

const timezoneChoices = [
  ['UTC-12:00', -12],
  ['UTC-11:00', -11],
  ['UTC-10:00', -10],
  ['UTC-09:30', -9.5],
  ['UTC-09:00', -9],
  ['UTC-08:00', -8],
  ['UTC-07:00', -7],
  ['UTC-06:00', -6],
  ['UTC-05:00', -5],
  ['UTC-04:00', -4],
  ['UTC-03:30', -3.5],
  ['UTC-03:00', -3],
  ['UTC-02:00', -2],
  ['UTC-01:00', -1],
  ['UTC±00:00', 0],
  ['UTC+01:00', 1],
  ['UTC+02:00', 2],
  ['UTC+03:00', 3],
  ['UTC+03:30', 3.5],
  ['UTC+04:00', 4],
  ['UTC+04:30', 4.5],
  ['UTC+05:00', 5],
  ['UTC+05:30', 5.5],
  ['UTC+05:45', 5.75],
  ['UTC+06:00', 6],
  ['UTC+06:30', 6.5],
  ['UTC+07:00', 7],
  ['UTC+08:00', 8],
  ['UTC+08:45', 8.75],
  ['UTC+09:00', 9],
  ['UTC+09:30', 9.5],
  ['UTC+10:00', 10],
  ['UTC+10:30', 10.5],
  ['UTC+11:00', 11],
  ['UTC+12:00', 12],
  ['UTC+12:45', 12.75],
  ['UTC+13:00', 13],
  ['UTC+14:00', 14],
]

const useStyles = makeStyles((theme) => ({
  full: {
    fontWeight: 600,
    clear: 'left',
  },
  left: {
    float: 'left',
    fontWeight: 600,
    clear: 'left',
    maxWidth: 'calc(100% - 4em)',
  },
  leftSmall: {
    float: 'left',
    fontWeight: 300,
    lineHeight: '11px',
    clear: 'left',
    fontSize: '10px',
    color: '#a2a2a2',
  },
  right: {
    float: 'right',
  },
  checkBoxLabel: {
    fontSize: 12,
  },
  textFieldHalfSize: {
    width: 100,
    margin: '20px 0',
    marginLeft: 5,
  },
  textFieldHalfSizeLabel: {
    fontSize: '12px',
  },
  panelInfo: {
    backgroundColor: 'rgb(236, 236, 236)',
    fontColor: 'rgb(44,73,157)',
    fontSize: 'bold',
    iconColor: 'rgb(44,73,157)',
    borderRadius: 5,
  },
}))
const getBillReduction = (currentBill, proposedBill) => {
  const oldBill = (currentBill.bills_yearly && currentBill.bills_yearly[0].annual.total) || 0
  const newBill =
    (Object.values(proposedBill).length > 0 && Object.values(proposedBill)[0].bills_yearly[0].annual.total) || 0
  if (newBill) {
    return Math.round(((oldBill - newBill) / oldBill) * 100)
  }
}

const getProposedSelfConsumption = (proposedBill) => {
  return (
    (Object.values(proposedBill).length > 0 &&
      Math.round(Object.values(proposedBill)[0].bills_yearly[0].annual.self_consumption_fraction * 100)) ||
    0
  )
}

const EnergyFlowsDataForDay = (system, monthIndex) => {
  try {
    // var dataGzipped = Object.values(system.bills.proposed)[0].bills_yearly[0].energy_flows_hourly
    var data = JSON.parse(
      window.CompressionHelper.decompress(Object.values(system.bills.proposed)[0].bills_yearly[0].energy_flows_hourly)
    )

    //window.monthIndex

    // var startHourIndex = dayOfYear * 24
    // var endHourIndex = (dayOfYear + 1) * 24
    //
    // for (var key in data) {
    //   data[key] = data[key].slice(startHourIndex, endHourIndex).map(v => v / -1000)
    // }

    var daysInMonth = window.Utils.daysInMonth[monthIndex]
    var firstDayIndexForMonth = window.Utils.firstDayIndexForMonth[monthIndex]

    var dataForMonth = {}
    var hourIndexInYear

    // Higher daysIncrementer is faster but uses fewer days in the month and may be more bumpy due to clouds etc
    // daysIncrementer=1 samples every day, daysIncrementer=2 samples every second day, etc
    var daysIncrementer = 2 //sample approximately 15 days per month

    var daysToSampleThisMonth = Math.floor(daysInMonth / daysIncrementer)

    for (var key in data) {
      dataForMonth[key] = []
      for (var hour = 0; hour < 24; hour++) {
        var sumOfValuesForThisHour = 0
        for (var dayIndex = 0; dayIndex < daysInMonth; dayIndex += daysIncrementer) {
          hourIndexInYear = (firstDayIndexForMonth + dayIndex) * 24 + hour
          sumOfValuesForThisHour += data[key][hourIndexInYear]
        }
        dataForMonth[key].push((-0.001 * sumOfValuesForThisHour) / daysToSampleThisMonth)
      }
    }

    return dataForMonth
  } catch (e) {
    return {
      export_to_grid_before_limiting_losses_profile: EMPTY24,
      consumption: EMPTY24,
      generation: EMPTY24,
      battery: EMPTY24,
      export: EMPTY24,
      net: EMPTY24,
    }
  }
}

export const PaymentOptionsSummary = ({ system }) => {
  const translate = useTranslateWithVariable()
  const archivedSuffix = ` (${translate('archived')})`
  const availablePaymentOptions = window.AccountHelper.getPaymentOptionAvailable()
  const hasUsFinance = useSelector(authSelectors.getHasUsFinanceIntegration)
  const paymentOptionIds = window.AccountHelper.loadedData?.paymentOptions?.map((x) => x.id)
  const getPaymentOptionTitle = (payment, index) => {
    const isAvailable = availablePaymentOptions.some(
      (availablePaymentOption) => availablePaymentOption.id === payment.id
    )
    const isOwnPayment = paymentOptionIds.includes(payment.id)
    return `${index > 0 ? ', ' : ''}${payment.title}${!isAvailable && isOwnPayment ? archivedSuffix : ''}`
  }
  return (
    <>
      <span>{system && system.payment_options ? system.payment_options.map(getPaymentOptionTitle) : null}</span>
      {hasUsFinance && <PaymentOptionRefreshReminder availablePaymentOptions={availablePaymentOptions} />}
    </>
  )
}

export const getPerformanceCalculatorChoices = (selectedCalculator, country_iso2) => {
  return PERFORMANCE_CALCULATORS.filter((calculator) => {
    // Continue showing the calculator option that should be hidden if it was already previously selected,
    // otherwise that calculator option is hidden and cannot be selected.
    if (calculator?.hide_from_choices === true && selectedCalculator !== calculator.id) {
      return false
    } else if (calculator.restrict_to_country) {
      return calculator.restrict_to_country.includes(country_iso2)
    } else {
      return true
    }
  })
}

const loadProduction8760FromFileContents = (contents) => {
  // 8760 values required, they can be separated with newlines of commas or any combination
  var loadingIssues

  var lines = contents.split(/[/\r/\n]+/)

  var production8760 = []

  lines.forEach((line) => {
    line.split(',').forEach((linePart) => {
      let value = parseFloat(linePart)

      //Only contains tow parts that can be parsed into numbers
      //Only two columns
      if (!value) {
        // skip empty value
      } else if (isNaN(value)) {
        loadingIssues = 'error'
      } else {
        production8760.push(value)
      }
    })
  })

  if (production8760.length !== 8760) {
    loadingIssues = 'missingValues'
  }

  return { production8760, loadingIssues }
}

const loadProductionFile8760 = function (file, showNotification, updateGenerationOverride, applyValue) {
  if (!file) {
    // probably cancelled, ignore
    return
  }
  var filename = file.name

  var reader = new FileReader()
  reader.addEventListener('progress', function (event) {
    var size = '(' + Math.floor(event.total / 1000).format() + ' KB)'
    var progress = Math.floor((event.loaded / event.total) * 100) + '%'
    console.log('Loading', filename, size, progress)
  })

  reader.addEventListener(
    'load',
    function (event) {
      var { production8760, loadingIssues } = loadProduction8760FromFileContents(event.target.result)

      if (loadingIssues) {
        showNotification('Production file invalid. Please see HelpCenter article for more information.', 'danger')
      } else {
        updateGenerationOverride(production8760)
        applyValue(production8760)
        showNotification('Production file uploaded successfully', 'info')
      }
    },
    false
  )
  reader.readAsText(file)
}

const AdvancedSetting = ({
  systems,
  system,
  exportLimitRaw,
  selfConsumptionOverrideRaw,
  updateExportLimit,
  updateSelfConsumptionOverride,
  generationOverrideRaw,
  updateGenerationOverride,
}) => {
  const translate = useTranslateWithVariable()
  const classes = useStyles()
  const formValues = useForm().getState().values
  //To do: need to update country url when changing country_iso2
  const countryId = useMemo(() => (formValues.country ? urlToId(formValues.country) : null), [])
  const country_iso2 = window.WorkspaceHelper?.project.country_iso2 || null
  const fileInput = useRef()

  const generationOverrideFormat =
    generationOverrideRaw && generationOverrideRaw.includes(',') && generationOverrideRaw.split(',').length === 8760
      ? 'hourly'
      : 'annual'

  return (
    <div>
      {systems.length > 1 && (
        <FormControlLabel
          classes={{ label: classes.checkBoxLabel }}
          control={
            <Checkbox
              id="PanelSystemShowCustomerCheckbox"
              labelPosition="right"
              size="small"
              checked={!system.show_customer}
              onChange={(event) => {
                const value = !event.target.checked
                window.editor.execute(
                  new window.SetValueCommand(
                    system,
                    'show_customer',
                    value,
                    window.Utils.generateCommandUUIDOrUseGlobal()
                  )
                )
              }}
            />
          }
          label={translate('System Hidden from Customer')}
        />
      )}
      <FormControlLabel
        classes={{ label: classes.checkBoxLabel }}
        control={
          <Checkbox
            id="PanelSystemIsCurrentCheckbox"
            size="small"
            checked={system.is_current}
            onChange={(event) => {
              const value = event.target.checked
              window.editor.execute(new window.SetValueCommand(system, 'is_current', value))
            }}
          />
        }
        label={translate('Existing System To Upgrade')}
      />

      <TextField
        type="number"
        label={translate('Export Limit')}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldHalfSizeLabel,
        }}
        className={classes.textFieldHalfSize}
        name="Export Limit"
        value={valueOrEmptyStringIfNull(exportLimitRaw)}
        onFocus={() => {
          markFieldActive('exportLimitRaw', system)
        }}
        onBlur={() => {
          markFieldInactive()
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{translate('kW')}</InputAdornment>,
        }}
        onChange={(event) => {
          const value = event.target.value
          updateExportLimit(value)
          var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

          handleObjectChangedAsCommand(system.uuid, 'export_limit', valueAsFloatOrNull)
        }}
        errorText={
          isNaN(inputValueToFloatOrEmptyStringWhenNull(exportLimitRaw))
            ? translate('Supply export limit as a number or leave blank')
            : null
        }
        disabled={false}
      />

      <TextField
        type="number"
        label={translate('Self Consumption Override')}
        InputLabelProps={{
          shrink: true,
          className: classes.textFieldHalfSizeLabel,
        }}
        className={classes.textFieldHalfSize}
        name="Self Consumption Override"
        value={valueOrEmptyStringIfNull(selfConsumptionOverrideRaw)}
        onFocus={() => {
          markFieldActive('selfConsumptionOverrideRaw', system)
        }}
        onBlur={() => {
          markFieldInactive()
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onChange={(event) => {
          const value = event.target.value
          updateSelfConsumptionOverride(value)

          var valueAsFloatOrNull = inputValueToFloatOrEmptyStringWhenNull(value)

          handleObjectChangedAsCommand(
            system.uuid,
            'self_consumption_override',
            valueAsFloatOrNull ? valueAsFloatOrNull / 100.0 : null
          )
        }}
        errorText={
          isNaN(inputValueToFloatOrEmptyStringWhenNull(selfConsumptionOverrideRaw))
            ? translate('Supply self consumption override as a number or leave blank')
            : null
        }
        disabled={false}
      />

      <LifetimeSelectInput system_lifetime={system.system_lifetime} systemUuid={system.uuid} />

      <CustomSelectField
        label={translate('Energy Production Calculator')}
        value={system.calculator}
        onChange={(event) => handleObjectChangedAsCommand(system.uuid, 'calculator', event.target.value, true)}
      >
        {system.calculator === PERFORMANCE_CALCULATORS_MCS
          ? PERFORMANCE_CALCULATORS.map((pc) => (
              <MenuItem key={pc.id} value={pc.id}>
                {translate(pc.name)}
              </MenuItem>
            ))
          : getPerformanceCalculatorChoices(system.calculator, country_iso2).map((pc) => (
              <MenuItem key={pc.id} value={pc.id}>
                {translate(pc.name)}
              </MenuItem>
            ))}
      </CustomSelectField>

      {(system.calculator === PERFORMANCE_CALCULATORS_3RD_PARTY || system.generation_override) &&
        window.editor.scene.autoDesignGeoJson && (
          <Alert severity="warning">
            Auto-Design is enabled. Changes to panel layout will override any production values entered here.{' '}
            <div
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              role="button"
              tabindex="0"
              onClick={() => {
                window.editor.execute(new window.SetValueCommand(window.editor.scene, 'autoDesignGeoJson', null))
                window.editor.execute(new window.SetValueCommand(window.editor.scene, 'autoFacetsGeoJson', null))
                window.editor.execute(new window.SetValueCommand(window.editor.scene, 'generation_override', null))
              }}
            >
              Clear
            </div>
          </Alert>
        )}
      {system.calculator === PERFORMANCE_CALCULATORS_3RD_PARTY && generationOverrideFormat === 'annual' && (
        <div>
          <TextField
            type="number"
            label={translate('Production')}
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            className={classes.textField}
            name="Production"
            value={valueOrEmptyStringIfNull(generationOverrideRaw)}
            onFocus={() => {
              markFieldActive('generationOverrideRaw', system)
            }}
            onBlur={() => {
              markFieldInactive()
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{translate('kWh/yr')}</InputAdornment>,
            }}
            onChange={(event) => {
              const value = [inputValueToFloatOrEmptyStringWhenNull(event.target.value) || 0]
              updateGenerationOverride(value)
              handleObjectChangedAsCommand(system.uuid, 'generation_override', value)
            }}
            errorText={
              isNaN(inputValueToFloatOrEmptyStringWhenNull(exportLimitRaw))
                ? translate('Supply annual production as a number')
                : null
            }
            disabled={generationOverrideFormat === 'hourly'}
          />
        </div>
      )}
      {system.calculator === PERFORMANCE_CALCULATORS_3RD_PARTY && (
        <div style={{ marginTop: 5 }}>
          <input
            accept={'.csv, .txt'}
            ref={fileInput}
            type="file"
            id="uploadGenerationOverride"
            hidden
            onChange={(e) =>
              loadProductionFile8760(
                e.target.files[0],
                window.Designer.showNotification,
                updateGenerationOverride,
                (value) => handleObjectChangedAsCommand(system.uuid, 'generation_override', value)
              )
            }
          />
          <Button
            variant="contained"
            color="default"
            size="small"
            endIcon={<FileCloudUpload />}
            onClick={() => fileInput.current.click()}
          >
            Upload 8760
          </Button>
          {generationOverrideFormat === 'hourly' && (
            <Button
              variant="contained"
              color="default"
              size="small"
              onClick={() => {
                updateGenerationOverride([0])
                handleObjectChangedAsCommand(system.uuid, 'generation_override', [0])
              }}
            >
              Clear
            </Button>
          )}
        </div>
      )}
      {window.ViewHelper.hasNoneView() && (
        <CustomSelectField
          style={{ flex: '1 1', width: 230 }}
          label={translate('Default Roof Type')}
          displayEmpty={true}
          value={window.editor.scene.roofTypeId()}
          onChange={(event) => {
            window.editor.scene.roofTypeId(event.target.value)
          }}
        >
          <MenuItem value={null}>{translate('None')}</MenuItem>
          {window.AccountHelper.loadedData.roofTypes.map((roofType) => (
            <MenuItem key={roofType.id} value={roofType.id}>
              {translate(roofType.name)}
            </MenuItem>
          ))}
        </CustomSelectField>
      )}

      {window.ViewHelper.hasNoneView() && (
        <CustomSelectField
          style={{ flex: '1 1', width: 230 }}
          label={translate('Default Wall Type')}
          displayEmpty={true}
          value={window.editor.scene.wallTypeId()}
          onChange={(event) => {
            window.editor.scene.wallTypeId(event.target.value)
          }}
        >
          <MenuItem value={null}>{translate('None')}</MenuItem>
          {window.AccountHelper.loadedData.wallTypes.map((wallType) => (
            <MenuItem value={wallType.id}>{translate(wallType.name)}</MenuItem>
          ))}
        </CustomSelectField>
      )}

      <CustomSelectField
        style={{ flex: '1 1', width: 230 }}
        label={translate('Timezone')}
        displayEmpty={true}
        value={window.editor.scene?.timezoneOffset || 0}
        onChange={(event) => {
          window.editor.execute(new window.SetValueCommand(window.editor.scene, 'timezoneOffset', event.target.value))

          // Call manually beacuse changes to scene do not automatically trigger clearing shading and recalc
          window.SceneHelper.clearShadingAndRecalc()
        }}
      >
        {timezoneChoices.map(([label, timezoneOffset]) => (
          <MenuItem value={timezoneOffset}>{translate(label)}</MenuItem>
        ))}
      </CustomSelectField>

      <TariffOverride system={system} countryId={countryId} />
    </div>
  )
}

export const ActivateComponentsRequests = ({ activateComponentsRequests }) => (
  <>
    {Boolean(activateComponentsRequests && activateComponentsRequests.length > 0) &&
      Object.entries(activateComponentsRequests).map(([key, activateComponentsRequest]) => (
        <Alert key={key} severity="warning">
          <div style={{ whiteSpace: 'pre-line' }}>
            {activateComponentsRequest.message}

            <div style={{ marginTop: 10 }}>
              <button
                onClick={() => {
                  var callback = () => {
                    window.editor.signals.sceneGraphChanged.dispatch()
                  }
                  window.AccountHelper.debouncedActivateComponents(activateComponentsRequest.codes, callback)
                }}
              >
                {'Activate'}
              </button>
            </div>
          </div>
        </Alert>
      ))}
    {Boolean(activateComponentsRequests && activateComponentsRequests.length > 0) && (
      <Divider style={{ height: 2, margin: '20px 0' }} />
    )}
  </>
)

export const handleObjectChangedAsCommand = (uuid, attributeName, newValue) => {
  const valueIsValid =
    !isNaN(newValue) ||
    // allow generation_override to be null, array length 1, array length 8760
    (attributeName === 'generation_override' && (!newValue || newValue?.length === 1 || newValue?.length === 8760))

  if (uuid && valueIsValid && !window.editor.changingHistory) {
    var object = window.editor.objectByUuid(uuid)
    if (object) {
      if (object.type === 'OsSystem' && attributeName === 'calculator') {
        var calculator = PERFORMANCE_CALCULATORS.filter((c) => c.id === newValue)[0]
        if (!calculator.supports_automated_shading && object.moduleGrids().some((mg) => mg.hasRaytracedShading())) {
          // if we have raytraced shading but calculator does not support it then:
          //  - clear raytraced shading
          //  - show message to user
          // @TODO: Should this be done through a command?
          object.clearRaytracedShading()
          window.Designer.showNotification(
            // window.translate(`Automated shading is not supported by ${calculator.name}. Clearing shading simulations.`),
            window.translate('not_support_shading', { calculatorName: calculator.name }),
            'danger'
          )
        }

        // If changing to any calculator other than 3rd party, clear generation_override
        if (calculator !== PERFORMANCE_CALCULATORS_3RD_PARTY && object.generation_override) {
          window.editor.execute(new window.SetValueCommand(object, 'generation_override', null, undefined, true))
        }
      }

      window.editor.execute(new window.SetValueCommand(object, attributeName, newValue, undefined, true))
    }
  }
}

export const detectIsSystemPriceOverriddenByPaymentOption = (system) => {
  if (!system) return false
  function checkSystemPriceByPaymentOptionIds(paymentOptionIds) {
    let hasPriceOverridden = false
    paymentOptionIds.forEach((id) => {
      if (
        system.payment_options_settings_overrides &&
        system.payment_options_settings_overrides[id] &&
        system.payment_options_settings_overrides[id].hasOwnProperty('price')
      ) {
        hasPriceOverridden = true
        return hasPriceOverridden
      }
    })
    return hasPriceOverridden
  }

  if (system.payment_options_override?.length > 0) {
    //check for override payment options
    return checkSystemPriceByPaymentOptionIds(system.payment_options_override)
  } else {
    //check for default applied payment options
    const defaultPaymentOptionIds = system.payment_options?.map((a) => a.id) || []
    return checkSystemPriceByPaymentOptionIds(defaultPaymentOptionIds)
  }
}

const Summary = ({
  systems,
  system,
  currencySymbol,
  exportLimitRaw,
  selfConsumptionOverrideRaw,
  updateExportLimit,
  updateSelfConsumptionOverride,
  pricingIsLocked,
  systemsLoadingUuids,
  hasShadingCalcsAwaitingTrigger,
  generationOverrideRaw,
  updateGenerationOverride,
}) => {
  const translate = useTranslateWithVariable()
  const locale = useLocale()
  const translateParse = useTranslateParse()
  //any payment option has price override
  const isPriceOverriddenByPaymentOption = detectIsSystemPriceOverriddenByPaymentOption(system)
  const [monthIndex, setMonthIndex] = useState(0)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [showDealerFeeText, setShowDealerFeeText] = useState(false)
  const [showInfoPanel, setShowInfoPanel] = useState(false)

  useEffect(() => {
    if (system?.payment_options?.length > 0) {
      let foundDealerFee = false
      system.payment_options.forEach((pmt) => {
        if (pmt?.pricing?.dealer_fee > 0) foundDealerFee = true
      })
      if (foundDealerFee !== showDealerFeeText) setShowDealerFeeText(foundDealerFee)
    }
  }, [system?.payment_options])

  const classes = useStyles()
  const annualEnergyConsumption = useMemo(() => {
    const usageRowValue = window.projectForm?.getState().values?.usage
    if (usageRowValue && typeof usageRowValue === 'string') {
      const usage = JSON.parse(usageRowValue)
      return usage?.normalized?.annual
    } else {
      return
    }
  }, [])
  // Preparing energyFlowsDataForDay is very heavy, avoid recalulating if already processed
  var energyFlowsDataForDay = {}
  var hasExportLimiting = false
  var hasBattery = system.batteryTotalKwh() > 0

  if (system.output) {
    var hashedArgsPlusMonthIndex = system.output.hashed_args + '_' + monthIndex

    if (
      system.output &&
      window.SystemSummaryEnergyFlowsDataForDayCached.data &&
      window.SystemSummaryEnergyFlowsDataForDayCached.hashedArgsPlusMonthIndex === hashedArgsPlusMonthIndex
    ) {
      energyFlowsDataForDay = window.SystemSummaryEnergyFlowsDataForDayCached.data
    } else {
      energyFlowsDataForDay = EnergyFlowsDataForDay(system, monthIndex)
      window.SystemSummaryEnergyFlowsDataForDayCached.data = energyFlowsDataForDay
      window.SystemSummaryEnergyFlowsDataForDayCached.hashedArgsPlusMonthIndex = hashedArgsPlusMonthIndex
    }

    for (var i = 0; i < 24; i++) {
      if (energyFlowsDataForDay.export[i] !== energyFlowsDataForDay.export_to_grid_before_limiting_losses_profile[i]) {
        hasExportLimiting = true
      }
    }
  } else {
    // We will not be displaying this data so skip calculations completely
  }

  var samValidationResults = window.editor && system ? system.validateSamSpecs() : null

  let system_price_including_tax = system.pricing?.system_price_including_tax
  let system_price_payable = system.pricing?.system_price_payable

  return (
    <div style={{ fontSize: 12, lineHeight: '19px' }}>
      <ActivateComponentsRequests activateComponentsRequests={window.AccountHelper?.activateComponentsRequests} />

      <PanelGenericWarningBox category="system" />

      {hasShadingCalcsAwaitingTrigger && (
        <div className={classes.panelInfo} style={{ position: 'sticky', top: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}>
            <Button
              size="small"
              variant="contained"
              fullWidth
              onClick={() => {
                window.ShadeHelper.calculateShadingBlockedAllSystemsAwaitingTrigger()
              }}
            >
              <span>{translate('Recalculate')}</span>
            </Button>
            {showInfoPanel ? (
              <Tooltip title={translate('Hide info panel')}>
                <CancelOutlinedIcon
                  color="primary"
                  onClick={() => {
                    setShowInfoPanel(false)
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={translate('Show info panel')}>
                <InfoOutlinedIcon
                  color="primary"
                  onClick={() => {
                    setShowInfoPanel(true)
                  }}
                />
              </Tooltip>
            )}
          </div>
          {showInfoPanel && (
            <p style={{ textAlign: 'center', margin: 0, paddingTop: 5, paddingBottom: 5, color: '#3f50b5' }}>
              {translate(
                'To ensure optimal performance, automatic recalculation is disabled when the panel number exceeds 50. Click here to recalculate.'
              )}
            </p>
          )}
        </div>
      )}

      <HardwareSection system={system} />

      <div style={{ margin: '20px 0' }}>
        {!!system_price_including_tax && (
          <div>
            <span className={classes.left}>
              {translate('Price')}
              {showDealerFeeText ? '*' : ''}
            </span>
            <span
              style={{ textDecoration: isPriceOverriddenByPaymentOption ? 'line-through' : 'unset' }}
              className={classes.right}
            >
              {formatCurrencyWithSymbol(system_price_including_tax, currencySymbol, locale, 2)} {translate('inc tax')}
            </span>
          </div>
        )}

        {system?.pricing?.incentive_to_installer?.total > 0 && (
          <div>
            <span className={classes.left}>
              {translate('Incentives to Installer')}
              {showDealerFeeText ? '*' : ''}
            </span>
            <span className={classes.right}>
              <span style={{ float: 'right' }}>
                {formatCurrencyWithSymbol(system.pricing.incentive_to_installer.total, currencySymbol, locale, 2)}
              </span>
            </span>
          </div>
        )}
        {!!system_price_payable && (
          <div>
            <span className={classes.left}>
              {translate('Purchase Price')}
              {showDealerFeeText ? '*' : ''}
            </span>
            <span
              style={{ textDecoration: isPriceOverriddenByPaymentOption ? 'line-through' : 'unset' }}
              className={classes.right}
            >
              {formatCurrencyWithSymbol(system_price_payable, currencySymbol, locale, 2)}
            </span>
          </div>
        )}

        <PriceOverrideLink
          linkStyle={{ float: 'right' }}
          linkVisibility={isPriceOverriddenByPaymentOption}
          systemsLoadingUuids={systemsLoadingUuids}
          pricingIsLocked={pricingIsLocked}
          currencySymbol={currencySymbol}
          systems={systems}
          tab="summary"
        />
        {system?.pricing?.incentive_to_customer?.total > 0 && (
          <div>
            <span className={classes.left}>
              {translate('Incentives to Customer')}
              {showDealerFeeText ? '*' : ''}
            </span>
            <span className={classes.right}>
              <span style={{ float: 'right' }}>
                {`${currencySymbol}${formatCurrency(system.pricing.incentive_to_customer.total)}`}
              </span>
            </span>
          </div>
        )}
        {showDealerFeeText && (
          <div style={{ marginBottom: '10px' }}>
            <span className={classes.leftSmall}>
              {translate(
                '*Assumes no dealer fee. Customer will be shown payment option specific pricing. See pricing tab for details'
              )}
            </span>
          </div>
        )}
      </div>

      {system.payment_options?.length > 1 ? (
        <div style={{ margin: '20px 0' }}>
          <div>
            <div className={classes.full}>{translate('Payment Options')}</div>
            <div>
              <PaymentOptionsSummary system={system} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span className={classes.left}>{translate('Payment Options')}</span>
          <span className={classes.right}>
            <PaymentOptionsSummary system={system} />
          </span>
        </div>
      )}
      <div style={{ clear: 'both' }}></div>

      <div style={{ margin: '20px 0' }}>
        <div>
          <span className={classes.left}>{translate('System Size')}</span>
          <span className={classes.right}>{system.getModuleTotalOutput().toFixed(3) + ' kW'}</span>
        </div>
        <div>
          <span className={classes.left}>{translate('Annual Output')}</span>
          {hasShadingCalcsAwaitingTrigger ? (
            <Tooltip title={translate('Recalculate to update value')}>
              <span className={classes.right} style={{ textDecoration: 'line-through' }}>
                {(system.output ? numberWithCommas(system.output.annual, true) : '0') + ' kWh'}
              </span>
            </Tooltip>
          ) : (
            <span className={classes.right}>
              {(system.output ? numberWithCommas(system.output.annual, true) : '0') + ' kWh'}
            </span>
          )}
        </div>
        {annualEnergyConsumption && (
          <div>
            <span className={classes.left}>{translate('Energy Consumption')}</span>
            <span className={classes.right}>{numberWithCommas(annualEnergyConsumption, true) + ' kWh/yr'}</span>
          </div>
        )}
        <div>
          <span className={classes.left}>{translate('Consumption Offset')}</span>
          {hasShadingCalcsAwaitingTrigger ? (
            <Tooltip title={translate('Recalculate to update value')}>
              <span className={classes.right} style={{ textDecoration: 'line-through' }}>
                {system.consumption && system.consumption.consumption_offset_percentage
                  ? system.consumption.consumption_offset_percentage + '%'
                  : '0%'}
              </span>
            </Tooltip>
          ) : (
            <span className={classes.right}>
              {system.consumption && system.consumption.consumption_offset_percentage
                ? system.consumption.consumption_offset_percentage + '%'
                : '0%'}
            </span>
          )}
        </div>
        <div>
          <span className={classes.left}>{translate('Bill Reduction (annual)')}</span>
          {hasShadingCalcsAwaitingTrigger ? (
            <Tooltip title={translate('Recalculate to update value')}>
              <span className={classes.right} style={{ textDecoration: 'line-through' }}>
                {system.bills && system.bills.proposed && system.bills.current
                  ? getBillReduction(system.bills.current, system.bills.proposed) + '%'
                  : '0%'}
              </span>
            </Tooltip>
          ) : (
            <span className={classes.right}>
              {system.bills && system.bills.proposed && system.bills.current
                ? getBillReduction(system.bills.current, system.bills.proposed) + '%'
                : '0%'}
            </span>
          )}
        </div>
        <div>
          <span className={classes.left}>{translate('Self Consumption')}</span>
          {hasShadingCalcsAwaitingTrigger ? (
            <Tooltip title={translate('Recalculate to update value')}>
              <span className={classes.right} style={{ textDecoration: 'line-through' }}>
                {system.bills && system.bills.proposed ? getProposedSelfConsumption(system.bills.proposed) + '%' : '0%'}
              </span>
            </Tooltip>
          ) : (
            <span className={classes.right}>
              {system.bills && system.bills.proposed ? getProposedSelfConsumption(system.bills.proposed) + '%' : '0%'}
            </span>
          )}
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>

      <div style={{ margin: '10px 0', padding: '10px 5px', background: '#F6F6F6' }}>
        {[
          { label: 'Consumption', color: '#333333' },
          { label: 'Generation', color: '#ffd502', opacity: 0.5 },
          { label: "Net Consump'n", color: '#9B9B9B', opacity: 0.5 },
          { label: 'Export to Grid', color: '#D0021B' },
        ]
          .concat(hasBattery ? { label: 'Battery', color: '#4A90E2', opacity: 0.5 } : [])
          .concat(hasExportLimiting ? { label: 'Export Limiting', color: '#750000' } : [])
          .map((item, i) => (
            <div
              key={i}
              style={{
                width: 108,
                height: 20,
                float: 'left',
                display: 'inline-block',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  margin: '0 5px',
                  width: 10,
                  height: 10,
                  backgroundColor: item.color,
                  opacity: item.opacity ? item.opacity : 1.0,
                }}
              />
              <span style={{}} className="small">
                {translate(item.label)}
              </span>
            </div>
          ))}

        <ChartistGraph
          style={{
            marginTop: 5,
            marginLeft: -15,
            marginBottom: 0,
            marginRight: 0,
            width: '100%',
          }}
          data={{
            labels: getRange(24),

            series: [
              {
                data: energyFlowsDataForDay.generation || EMPTY24,
                className: 'DailyEnergyFlowsGeneration',
              },
            ]
              .concat(
                hasBattery
                  ? [{ data: energyFlowsDataForDay.battery || EMPTY24, className: 'DailyEnergyFlowsBattery' }]
                  : []
              )
              .concat([
                { data: energyFlowsDataForDay.net || EMPTY24, className: 'DailyEnergyFlowsNet' },
                {
                  data: energyFlowsDataForDay.consumption || EMPTY24,
                  className: 'DailyEnergyFlowsConsumption',
                },
              ])
              .concat([
                {
                  data: energyFlowsDataForDay.export_to_grid_before_limiting_losses_profile || EMPTY24,
                  className: hasExportLimiting
                    ? 'DailyEnergyFlowsBeforeExportLimitingLosses'
                    : 'DailyEnergyFlowsExport',
                },
              ])
              .concat(
                hasExportLimiting
                  ? [
                      {
                        data: energyFlowsDataForDay.export || EMPTY24,
                        className: 'DailyEnergyFlowsExport',
                      },
                    ]
                  : []
              ),
          }}
          options={{
            chartPadding: 0,
            showLabel: true,
            showLine: true,
            showArea: true,
            height: '200px',
            width: '100%',
            showPoint: false,
            lineSmooth: true,
            scaleMinSpace: 50,
            onlyInteger: true,
            referenceValue: 0,
            fullWidth: true,
            axisY: { showGrid: true },
            axisX: {
              showLabel: true,
              showGrid: false,
              labelInterpolationFnc: function (value, index) {
                return index < 23 && index % 4 === 3 ? convert24AmPm(value) : null
              },
            },
            // low: 0,
            // high: props.maxMonthlyEnergy
            //   ? props.maxMonthlyEnergy
            //   : maxAxisYForAllSystems(
            //       [props.selectedSystem],
            //       props.selectedProject
            //     ),
          }}
          type={'Line'}
          plugins={
            [
              // Chartist.plugins.legend({
              //     position: 'bottom',
              //     classNames: ['ct-blue', 'ct-hidden'],
              // })
            ]
          }
        />

        <div className="row" style={{ height: 10, display: 'flex', width: '100%', alignItems: 'center' }}>
          <Slider
            style={{
              marginLeft: 10,
            }}
            value={monthIndex}
            defaultValue={0}
            min={0}
            max={11}
            onChange={(event, newValue) => {
              setMonthIndex(newValue)
              window.SceneHelper.updateSun(Math.round(newValue), undefined)

              // Hack to ensure panel refreshes
              window.editor.signals.sceneGraphChanged.dispatch()
            }}
          />
          <span style={{ width: 54, marginLeft: 10 }}>{translate(monthNames[monthIndex])}</span>
        </div>
      </div>
      <SystemLosses system={system} />
      {samValidationResults && samValidationResults.modules && samValidationResults.modules.length > 0 && (
        <Alert severity="error">
          {translate(
            'The selected module component in your design has not been configured for System Advisor Model (SAM).'
          )}
          {system.moduleType().module_id ? (
            translateParse(
              'Please email <mailtoLink>3D@opensolar.com<mailtoLink> with the hardware in question, and it will be promptly configured for System Advisor Model (SAM).',
              {
                mailtoLink: () => (
                  <a href="mailto:3D@opensolar.com" target="_blank" rel="noreferrer">
                    3D@opensolar.com
                  </a>
                ),
              }
            )
          ) : (
            <span>{translate('Please ensure all fields have been specified in Control > Design & Hardware.')}</span>
          )}
          <p>
            {translate('Invalid fields:')} <span>{samValidationResults.modules.join(', ')}</span>.
          </p>
        </Alert>
      )}

      {samValidationResults && samValidationResults.inverters && samValidationResults.inverters.length > 0 && (
        <Alert severity="error">
          {translateParse(
            'An inverter in your design has not been configured for System Advisor Model (SAM). If you are using a custom component, please ensure all fields have been specified in Control > Design & Hardware. If you are using standard components from the OpenSolar database, please email <mailtoLink>3D@opensolar.com<mailtoLink> with the hardware in question, and it will be promptly configured for System Advisor Model (SAM).',
            {
              mailtoLink: () => (
                <a href="mailto:3D@opensolar.com" target="_blank" rel="noreferrer">
                  3D@opensolar.com
                </a>
              ),
            }
          )}
          <p>
            {translate('Invalid fields')}:
            <span>{samValidationResults.inverters.map((message) => translate(message)).join(', ')}</span>.
          </p>
        </Alert>
      )}
      <div style={{ margin: '20px 0' }}>
        <span style={{ fontWeight: 600 }}>{translate('Advanced Settings')}</span>
        <span
          className={classes.right}
          style={{ padding: '0 10px', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            setShowAdvanced(!showAdvanced)
          }}
        >
          {translate(showAdvanced ? 'Hide' : 'Show')}
        </span>
      </div>

      {showAdvanced && (
        <AdvancedSetting
          systems={systems}
          system={system}
          exportLimitRaw={exportLimitRaw}
          selfConsumptionOverrideRaw={selfConsumptionOverrideRaw}
          updateExportLimit={updateExportLimit}
          updateSelfConsumptionOverride={updateSelfConsumptionOverride}
          generationOverrideRaw={generationOverrideRaw}
          updateGenerationOverride={updateGenerationOverride}
        />
      )}

      {systems.length > 1 && <DeleteSystemButton system={system} />}
    </div>
  )
}

export default Summary
