import { MenuItem, Select } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { WorkflowType } from 'types/workflows'
import { urlToId } from 'util/misc'
import { getProjectSectionStyles } from '../styles'
import { useStyles } from './styles'

interface PropTypes {
  workflow?: WorkflowType
  activeStageId?: number
  handleChange?: (workflow) => void
  disabled?: boolean
}

const StageStepper: React.FC<PropTypes> = ({ workflow, activeStageId, disabled, handleChange }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sectionStyles = getProjectSectionStyles()
  const activeStage = useMemo(() => workflow?.workflow_stages?.find((x) => x.id === activeStageId), [activeStageId])
  const otherStageSelected = useMemo(() => activeStage?.milestone && activeStage?.milestone > 3, [activeStage])

  const stageClasses = useMemo(
    () => ({
      active: {
        button: classes.stageActive,
        edge: classes.blockActive,
      },
      past: {
        button: classes.pastStage,
        edge: classes.blockPast,
      },
      future: {
        button: classes.futureStage,
        edge: classes.blockFuture,
      },
    }),
    []
  )
  const getStageClass = (stage, index, activeIndex) => {
    if (otherStageSelected) return stageClasses.future
    if (stage.id === activeStageId) return stageClasses.active
    if (index < activeIndex) return stageClasses.past
    if (index > activeIndex) return stageClasses.future
  }

  const stages = useMemo(() => {
    const workflowStages = workflow?.workflow_stages.concat().sort((a, b) => a.order - b.order)
    if (workflowStages && activeStage) {
      const activeStageIndex = workflowStages?.indexOf(activeStage)
      return workflowStages?.map((x, i) => ({
        ...x,
        stageClasses: getStageClass(x, i, activeStageIndex),
      }))
    }
  }, [workflow, activeStage])
  const standardStages = useMemo(() => stages?.filter((x) => x.milestone < 4), [stages])
  const otherStages = useMemo(() => stages?.filter((x) => x.milestone > 3), [stages])
  const handleStageChange = (newStageId) => {
    if (handleChange && !disabled)
      handleChange({ workflow_id: workflow?.id, active_stage_id: newStageId, org_id: urlToId(workflow?.org) })
  }

  if (!!!stages?.length || !workflow) return null
  return (
    <div className={`${sectionStyles.row} ${classes.stageRow} os-scroll`}>
      {standardStages?.map((stage, i) => {
        const atEdge = !(i !== 0 && i !== standardStages.length - 1)
        return (
          <div key={'stage-' + i} className={classes.btnContainer}>
            {atEdge && (
              <div
                className={`${classes.edgeBlock} ${stage.stageClasses?.edge || ''} ${i === 0 ? 'start' : 'end'} `}
              ></div>
            )}
            <RenderStageButton handleStageChange={handleStageChange} stage={stage} first={i === 0} />
          </div>
        )
      })}

      {!!otherStages?.length && (
        <div className={classes.otherWrapper}>
          <Select
            value={activeStageId}
            variant="outlined"
            displayEmpty
            className={`${classes.baseStageBtn} ${
              otherStageSelected ? `${classes.otherActive} ${classes.activeHighlight}` : classes.otherBtn
            }`}
            renderValue={(value) => {
              let emptyText = translate('Other Stages')
              const selectedStage = otherStages?.find((x) => x.id === value)
              if (!value) return emptyText
              return selectedStage ? selectedStage.title : emptyText
            }}
          >
            {otherStages?.map((x, i) => (
              <MenuItem
                key={'stage-' + i}
                value={x.id}
                onClick={() => {
                  handleStageChange(x.id)
                }}
                className={classes.menuItem}
              >
                {x.title}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}

const RenderStageButton = ({ stage, handleStageChange, first }) => {
  const classes = useStyles()

  return (
    <Button
      disableRipple
      variant="outlined"
      color="default"
      onClick={() => {
        handleStageChange(stage.id)
      }}
      className={`${classes.stageBtn} ${classes.baseStageBtn} ${stage.stageClasses.button}`}
    >
      <span className={`${classes.stageLabel} ${first ? classes.firstStageLabel : ''}`}>{stage.title}</span>
    </Button>
  )
}

export default StageStepper
