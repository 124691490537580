import PhoneField from 'elements/field/PhoneField'
import { useTranslate } from 'ra-core'
import { TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { InputRow } from '../../../styles'

interface PropTypes {
  source: string
}

const ContactStandardFields: React.FC<PropTypes> = ({ source }) => {
  const translate = useTranslate()
  const project = useFormState().values
  const country = project?.country_name
  return (
    <>
      <InputRow>
        <TextInput source={`${source}.first_name`} variant="outlined" label={'First Name'} fullWidth />
        <TextInput source={`${source}.family_name`} variant="outlined" label={'Last Name'} fullWidth />
      </InputRow>
      <InputRow>
        <TextInput
          fullWidth
          source={`${source}.email`}
          variant="outlined"
          label={'Customer Email'}
          type="email"
          validate={(value) => {
            let currentEmails = value?.split(',').filter((e) => e && e.trim())
            if (currentEmails) {
              let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i
              for (let i = 0; i < currentEmails.length; i++) {
                if (!regex.test(currentEmails[i].replace(/\s/g, ''))) {
                  return translate('Enter a valid email address.')
                }
              }
            }
          }}
        />
        <PhoneField source={source} initialCountry={country} label={'Customer Phone Number'} />
      </InputRow>
    </>
  )
}

export default ContactStandardFields
