import { makeStyles } from '@material-ui/core/styles'
import { orgSelectors } from 'ducks/orgs'
import { projectViewSettingsSelectors } from 'ducks/projectViewSettings'
import { CheckSquareOutlineIcon, Chip } from 'opensolar-ui'
import { useProjectEntityPermissions } from 'projectSections/sections/manage/projectManagement/workflow/hooks'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import { urlToId } from 'util/misc'
import Notice from '../common/Notice'
import { COLOR_PALETTE, getProjectSectionStyles } from '../styles'
import ActionCard from './ActionCard'
import { idToUrl } from './util'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxHeight: 400,
  },
  content: { flex: 1, overflowY: 'auto' },
  header: {
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    padding: 20,
    paddingLeft: 0,
  },
  sectionTop: {
    padding: 'unset',
  },
  stageLabel: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    marginRight: 10,
  },
  stageWrapper: {
    margin: '10px 0',
  },
  notice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))
interface PropTypes {}

const ActionsSection: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  const currOrgUrl = useSelector(orgSelectors.getOrgUrl)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const { view, externalWorkflow } = useSelector(projectViewSettingsSelectors.getProjectWorkflowView)
  const selectedExternalWorkflow = useMemo(() => project.external_workflow || externalWorkflow?.selectedWorkflow, [
    project.external_workflow,
    externalWorkflow?.selectedWorkflow,
  ])
  const currWorkflow = useMemo(() => {
    if (view === 'internal') {
      return orgWorkflows?.find((x) => x.id === project.workflow?.workflow_id)
    } else {
      return externalWorkflow?.workflows?.find((x) => x.id === selectedExternalWorkflow?.workflow_id)
    }
  }, [orgWorkflows, project.workflow?.workflow_id, view])
  const { allowView, allowEdit } = useProjectEntityPermissions(
    urlToId(currWorkflow?.org),
    'manage_notes_activities_actions'
  )
  const currStage = useMemo(() => {
    const workflowData = view === 'internal' ? project.workflow : selectedExternalWorkflow
    return currWorkflow?.workflow_stages?.find((x) => x.id === workflowData?.active_stage_id)
  }, [currWorkflow, project.workflow?.active_stage_id, selectedExternalWorkflow?.active_stage_id, view])
  const completedActions = useMemo(() => {
    let completedCount = 0
    currStage?.actions?.forEach((action) => {
      const events = project?.actions?.find((x) => x.id === action.id)?.events
      if (events && !!events.length) {
        const completed = events.filter((z) => z.is_complete)
        if (!!completed.length) completedCount += 1
      }
    })
    return completedCount
  }, [currStage])
  return (
    <div className={`${sectionStyles.column} ${classes.wrapper} ${sectionStyles.moduleHalfWidth}`}>
      <div className={`${sectionStyles.sectionCardTop} ${sectionStyles.row} ${classes.sectionTop}`}>
        <div className={sectionStyles.iconPadding}>
          <CheckSquareOutlineIcon size={20} />
        </div>
        <p className={classes.header}>{translate('Actions')}</p>
      </div>
      <div className={`${sectionStyles.sectionCardBottom} ${classes.content} ${sectionStyles.column}`}>
        {allowView ? (
          <>
            {!!currStage?.actions?.length ? (
              <>
                <div className={`${sectionStyles.row} ${classes.stageWrapper}`}>
                  <p className={classes.stageLabel}>
                    {translate('Stage')}: {currStage?.title}
                  </p>
                  <Chip label={`${completedActions}/${currStage?.actions?.length} ` + translate('Actions completed')} />
                </div>
                {currStage?.actions?.map((x, i) => (
                  <ActionCard
                    key={'action-' + i}
                    action={x}
                    disabled={!allowEdit}
                    orgUrl={
                      view === 'internal' && currOrgUrl ? currOrgUrl : idToUrl(selectedExternalWorkflow?.org_id, 'orgs')
                    }
                  />
                ))}
              </>
            ) : (
              <div className={classes.notice}>
                <Notice
                  icon={<CheckSquareOutlineIcon size={26} color={COLOR_PALETTE.darkGrey} />}
                  mainText={translate('No actions found')}
                  subText={translate('You can view all actions assigned to %{stageName} here.', {
                    stageName: currStage?.title,
                  })}
                />
              </div>
            )}
          </>
        ) : (
          translate('You do not have access to view the actions applied on this project.')
        )}
      </div>
    </div>
  )
}

export default ActionsSection
