import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'ra-core'
import { useFormState } from 'react-final-form'
import { Theme } from 'types/themes'
import HoverEditWrapper from '../../common/HoverEditWrapper'
import { COLOR_PALETTE } from '../../styles'
import AddressFormContent from './AddressFormContent'

const useStyles = makeStyles((theme: Theme) => ({
  address: {
    // margin: '0px 20px',
    '& h1': {
      margin: '8px 0',
      fontSize: 20,
    },
    '& p': {
      fontSize: 16,
      margin: 8,
      marginLeft: 0,
      color: COLOR_PALETTE.darkGrey,
    },
  },
}))

const ProjectAddress = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values

  return (
    <HoverEditWrapper
      modalProps={{
        headerText: translate('Address'),
        content: <AddressFormContent />,
      }}
      className={classes.address}
      titleElement={<h1>{project.address || project.org_name}</h1>}
    >
      <p>
        {project.locality && `${project.locality}, `} {project.state} {project.zip}
      </p>
    </HoverEditWrapper>
  )
}
export default ProjectAddress
