import { Table, TableCell, TableRow } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { orgSelectors } from 'ducks/orgs'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { CartDiscount, getCartDiscount, getCartDiscountValue } from 'pages/ordering/order/CartDiscounts'
import { getOrderCost } from 'pages/ordering/utils'
import { FC, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrency } from 'util/misc'

type Props = {
  currencySymbol: string
  orderableLineItems: OrderLineItem[]
}

const useStylesPriceBreakdown = makeOpenSolarStyles((theme) => ({
  textWrapper: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  table: {
    '& td': {
      padding: '2px',
      borderBottom: 'none',
      '&:last-child': {
        textAlign: 'right',
      },
    },
    marginBottom: '10px',
  },
  totalRow: {
    '& td': {
      fontWeight: 'bold',
      borderTop: `1px solid ${theme.greyMid1}`,
    },
    // Hide the separator line if there are no rows above the total (no discounts have been appliedReview )
    '&:first-child td': {
      borderTop: 'none',
    },
  },
}))

const PriceBreakdown: FC<Props> = ({ currencySymbol, orderableLineItems }) => {
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const classes = useStylesPriceBreakdown()
  const translate = useTranslate()
  const [cartDiscount, setCartDiscount] = useState<CartDiscount>(null)

  useEffect(() => {
    getCartDiscount(orderableLineItems, selectedSupplier, (cartDiscount) => {
      setCartDiscount(cartDiscount)
    })
  }, [orderableLineItems, selectedSupplier])

  const cost = getOrderCost(orderableLineItems, false)
  const costWithDiscounts = getOrderCost(orderableLineItems, true)
  const tradeDiscountValue = cost - costWithDiscounts
  const cartDiscountValue = getCartDiscountValue(costWithDiscounts, cartDiscount)
  const subtotal = costWithDiscounts - cartDiscountValue

  const vat = Math.round(subtotal * 20) / 100

  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.textWrapper}>
        {translate('shopping_cart_action_subtotal', { smart_count: orderableLineItems.length })}
      </Typography>

      <Table className={classes.table}>
        {(Boolean(tradeDiscountValue) || Boolean(cartDiscount)) && (
          <TableRow>
            <TableCell>{translate('Subtotal')}:</TableCell>
            <TableCell>{`${currencySymbol}${formatCurrency(cost)}`}</TableCell>
          </TableRow>
        )}
        {Boolean(tradeDiscountValue) && (
          <TableRow>
            <TableCell>{translate('Trade Discount')}:</TableCell>
            <TableCell>{`- ${currencySymbol}${formatCurrency(tradeDiscountValue)}`}</TableCell>
          </TableRow>
        )}
        {cartDiscount && Boolean(cartDiscountValue) && (
          <TableRow>
            <TableCell>{translate(cartDiscount.discountName)}:</TableCell>
            <TableCell>{`- ${currencySymbol}${formatCurrency(cartDiscountValue)}`}</TableCell>
          </TableRow>
        )}
        <TableRow className={classes.totalRow}>
          <TableCell>{translate('Order Total')}:</TableCell>
          <TableCell>{`${currencySymbol}${formatCurrency(subtotal)}`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{translate('VAT')} (20%):</TableCell>
          <TableCell>{`${currencySymbol}${formatCurrency(vat)}`}</TableCell>
        </TableRow>
      </Table>
    </>
  )
}

export default PriceBreakdown
