import { CameraAltOutlined, NoteAddOutlined, PublishOutlined } from '@material-ui/icons'
import { setGenerateDocumentDialog } from 'ducks/generateDocument'
import { useTranslate } from 'ra-core'
import { useDispatch } from 'react-redux'
import { StyledMenu, StyledMenuItem } from './styles'
interface CRUDFileMenuProps {
  anchorEl: HTMLElement | null
  setAnchorEl: any
  ctaSelected: string
  selectCta: any
}

export const CRUDFileMenu: React.FC<CRUDFileMenuProps> = (props) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  return (
    <StyledMenu
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={() => {
        props.setAnchorEl(null)
      }}
    >
      <StyledMenuItem
        onClick={() => {
          dispatch(setGenerateDocumentDialog(true))
          props.selectCta('generate_file')
        }}
      >
        <NoteAddOutlined /> {translate('Generate File')}
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          props.selectCta('upload_file')
        }}
      >
        <PublishOutlined /> {translate('Upload File')}
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          props.selectCta('upload_site_photos')
        }}
      >
        <CameraAltOutlined /> {translate('Upload Site Photos')}
      </StyledMenuItem>
    </StyledMenu>
  )
}
