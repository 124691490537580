import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { ChoicesProps, FieldTitle, InputProps, useChoices, useInput, useTranslate } from 'ra-core'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'

import { ListSubheader } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Chip } from 'opensolar-ui'
import { InputHelperText, ResettableTextField } from 'ra-ui-materialui'

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  alwaysOn,
  emptyValue,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  initialValue,
  input,
  isRequired,
  label,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  disableValue,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}: any) => rest

const useStyles = makeStyles(
  (theme) => ({
    input: {
      width: theme.spacing(32),
    },
  }),
  { name: 'RaSelectInput' }
)

/**
 * An Input component for a select box, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <SelectInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <SelectInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <SelectInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectInput source="gender" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <SelectInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <Select> component
 *
 * You can disable some choices by providing a `disableValue` field which name is `disabled` by default
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 *    { id: 976, first_name: 'William', last_name: 'Rinkerd', disabled: true },
 * ];
 *
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 *    { id: 976, first_name: 'William', last_name: 'Rinkerd', not_available: true },
 * ];
 * <SelectInput source="gender" choices={choices} disableValue="not_available" />
 *
 */

export type GroupedSelectInputProps = GroupProps &
  ChoicesProps &
  Omit<InputProps<TextFieldProps>, 'source'> &
  Omit<TextFieldProps, 'label' | 'helperText'> & {
    classes?: Partial<ClassNameMap<'input'>>
  }
const GroupedSelectInput: FunctionComponent<GroupedSelectInputProps> = <Item, Value extends string | number>(props) => {
  const {
    allowEmpty,
    choices = [],
    classes: classesOverride,
    className,
    disableValue,
    emptyText,
    emptyValue,
    format,
    helperText,
    label,
    onBlur,
    onChange,
    onFocus,
    options,
    optionText,
    optionValue,
    parse,
    resource,
    source,
    translateChoice,
    showArchivedChoices,
    validate,
    groupValue,
    groups,
    showSoloGroupName,
    style,
    renderCustomMenuItem,
    renderItemNotFound,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  const { getChoiceText, getChoiceValue } = useChoices<Item, Value>({
    optionText,
    optionValue,
    translateChoice,
  })

  const {
    id,
    input,
    isRequired,
    meta: { error, touched, submitError },
  } = useInput({
    format,
    onBlur: onBlur as ((event: FocusEvent) => void) | undefined,
    onChange: onChange as ((event: any) => void) | undefined,
    onFocus: onFocus as ((event: FocusEvent) => void) | undefined,
    parse,
    resource,
    source: source || '',
    validate,
    ...rest,
  })

  const filterChoices = useCallback(
    (choice) => {
      if (choice.is_archived) {
        if (input.value && getChoiceValue(choice) === input.value) {
          return true
        }
        return Boolean(showArchivedChoices)
      }
      return true
    },
    [getChoiceValue, showArchivedChoices, input.value]
  )
  const availableChoices = useMemo(() => choices.filter(filterChoices), [choices, filterChoices])
  let errorMessage = error || submitError
  if (errorMessage && typeof errorMessage === 'object' && !Array.isArray(errorMessage)) errorMessage = undefined

  const renderEmptyItemOption = useCallback(() => {
    return React.isValidElement(emptyText)
      ? React.cloneElement(emptyText)
      : emptyText === ''
      ? ' ' // em space, forces the display of an empty line of normal height
      : translate(emptyText, { _: emptyText })
  }, [emptyText, translate])
  const renderMenuItemOption = useCallback(
    (choice) => (renderCustomMenuItem ? renderCustomMenuItem(choice, getChoiceText(choice)) : getChoiceText(choice)),
    [getChoiceText]
  )
  /*const renderGroupedChoices = () => {
    return groupedChoices.map((choice: any) => {
      if (choice.headingType) {
        return (
          <ListSubheader key={getChoiceValue(choice)} className="GroupedSelectHeading">
            <span className="GroupedSelectHeadingTitle">{renderMenuItemOption(choice)}</span>
            {choice.chip && (
              <Chip
                key={getChoiceValue(choice)}
                className="GroupedSelectHeadingChip"
                label={translate(choice.chip)}
                size="small"
                variant="outlined"
                disabled={true}
              />
            )}
          </ListSubheader>
        )
      } else {
        return (
          <MenuItem key={getChoiceValue(choice)} value={getChoiceValue(choice)} disabled={get(choice, disableValue)}>
            {renderMenuItemOption(choice)} {choice.is_archived && `(${translate('archived')})`}
          </MenuItem>
        )
      }
    })
  }*/
  const [groupedChoices, setGroupChoices] = useState<object[]>([])
  useEffect(() => {
    if (groups.length > 1) {
      const groupMap = {}
      for (const group of groups) {
        groupMap[group.id] = { group, items: [] }
      }
      for (const choice of availableChoices) {
        const groupId = typeof groupValue === 'string' ? choice[groupValue] : groupValue(choice)
        const group = groupMap[groupId] || groupMap['default']
        if (group) group.items.push(choice)
      }

      let list: object[] = []
      let groupsFound = 0
      for (const i in groups) {
        const group = groups[i]
        const items = groupMap[group.id].items
        if (!items.length) continue
        list.push({
          ...group,
          [optionValue || 'id']: 1000000000 + i,
          [disableValue || 'disabled']: true,
          [disableValue || 'disabled']: true,
          [optionText || 'name']: group.name,
          headingType: 'heading',
        })
        list = list.concat(items)
        groupsFound++
      }
      if (groupsFound === 1 && !showSoloGroupName) list.shift() // Remove the first group name if only one group found
      setGroupChoices(list)
    } else {
      setGroupChoices(availableChoices)
    }
  }, [availableChoices, groups, groupValue])

  return (
    <ResettableTextField
      id={id}
      style={style}
      {...input}
      onChange={(e) => {
        if (props.source) input.onChange(e)
        if (onChange) onChange(e)
      }}
      select
      label={
        label !== '' &&
        label !== false && <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      InputLabelProps={{ shrink: !!emptyText || undefined }}
      className={`${classes.input} ${className}`}
      clearAlwaysVisible
      error={!!(touched && errorMessage)}
      helperText={<InputHelperText touched={!!touched} error={errorMessage} helperText={helperText} />}
      {...sanitizeRestProps(rest)}
      {...options}
      SelectProps={{
        displayEmpty: true,
        native: undefined,
        renderValue: (value) => {
          const findItem = groupedChoices.find((x: any) => {
            if (optionValue && x[optionValue]) {
              return x[optionValue] === value
            } else {
              return x.id === value
            }
          })
          if (findItem) {
            return renderMenuItemOption(findItem)
          } else if (value === null || value === undefined || value === '') {
            return renderEmptyItemOption()
          } else {
            return renderItemNotFound ? renderItemNotFound(value) : ''
          }
        },
      }}
    >
      {allowEmpty ? (
        <MenuItem
          value={emptyValue}
          key="null"
          aria-label={translate('ra.action.clear_input_value')}
          title={translate('ra.action.clear_input_value')}
        >
          {renderEmptyItemOption()}
        </MenuItem>
      ) : null}
      {groupedChoices.map((choice: any) => {
        if (choice.headingType) {
          return (
            <ListSubheader key={getChoiceValue(choice)} className="GroupedSelectHeading">
              <span className="GroupedSelectHeadingTitle">{renderMenuItemOption(choice)}</span>
              {choice.chip && (
                <Chip
                  key={getChoiceValue(choice)}
                  className="GroupedSelectHeadingChip"
                  label={translate(choice.chip)}
                  size="small"
                  variant="outlined"
                  disabled={true}
                />
              )}
            </ListSubheader>
          )
        } else {
          return (
            <MenuItem key={getChoiceValue(choice)} value={getChoiceValue(choice)} disabled={get(choice, disableValue)}>
              {renderMenuItemOption(choice)} {choice.is_archived && `(${translate('archived')})`}
            </MenuItem>
          )
        }
      })}
    </ResettableTextField>
  )
}

GroupedSelectInput.propTypes = {
  allowEmpty: PropTypes.bool,
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyValue: PropTypes.any,
  choices: PropTypes.array,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  optionText: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  resettable: PropTypes.bool,
  resource: PropTypes.string,
  translateChoice: PropTypes.bool,
  groups: PropTypes.array.isRequired,
  groupValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
}

GroupedSelectInput.defaultProps = {
  emptyText: '',
  emptyValue: '',
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
  disableValue: 'disabled',
}

export default GroupedSelectInput

interface GroupProps {
  source?: string
  optionText?: string
  groups: GroupType[]
  groupValue: string | Function
  showSoloGroupName?: boolean
  renderCustomMenuItem?: (value: any, getChoiceText: string) => React.ReactNode
  renderItemNotFound?: (value: any) => React.ReactNode
}
export interface GroupType {
  id: number | string | 'default'
  name: string
  chip?: string
}
