import { Divider, Paper, useMediaQuery, withStyles } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ExpandLessIcon from '@material-ui/icons/ExpandLessOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { useSystemName } from 'Designer/hooks/useSystemName'
import Tools from 'Designer/Tools'
import { useViewShow, useViewShowAsDict } from 'ducks/viewMode'
import { Button } from 'opensolar-ui'
import { Component, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useStudioSignals } from 'Studio/signals/useStudioSignals'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { closablePanels } from 'util/closablePanels'
import SystemProgressBar from './SystemProgressBar'
import AddPanelButton from './systems/accordion/AddPanelButton'
import { selectedFontColor } from './systems/accordion/SystemAccordion'
import { OverviewFigures } from './systems/accordion/SystemOverview'

const StyledPaper = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 330,
    margin: 10,
    border: '0px solid transparent',
    backgroundColor: 'rgb(255,255,255)',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      //higher than other buttons in mobile layouts
      zIndex: 1101,
    },
  },
}))(Paper)

const CancelButton = () => {
  const [showButton, setShowButton] = useState(false)
  const translate = useTranslate()
  const getDrawingObject = useCallback(() => {
    return window.editor?.controllers?.AddObject?.objectType || window.editor?.controllers?.FingerPaint?.active
  }, [])
  useEffect(() => {
    setShowButton(!!getDrawingObject())
  }, [])
  const open = useCallback((open) => {
    setShowButton(!!open)
  }, [])

  useStudioSignals(
    (name, active) => {
      if (name === 'FingerPaint' || name === 'AddObject') open(active)
    },
    ['controllerStatusChanged']
  )

  const handleClick = () => {
    window.editor.signals?.escapePressed.dispatch()
  }

  return showButton ? (
    <Button style={{ marginLeft: 10 }} onClick={handleClick} size="small" variant="contained">
      <span>{translate('Quit Drawing')}</span>
    </Button>
  ) : null
}

const BackToSelectedSystem = ({ hasShadingCalcsAwaitingTrigger, onClose }) => {
  const showSystemsOverview = useViewShow('studio.systems.overview')
  const systemCalculation = useSelector((state) => state.designer.systemCalculation)
  const { debounceQueue, processQueue } = systemCalculation

  const system = window.editor.selectedSystem
  const systemName = useSystemName(system)

  if (!system) {
    return null
  }

  // Beware: system may be null/undefined if it was recently deleted, avoid crashing react
  const systemUuid = system?.uuid
  const isLoading = debounceQueue.includes(systemUuid)
  const isCalculating = processQueue[systemUuid]?.status === 'waitingCalculation'

  // const isLoading = system && systemsLoadingUuids.indexOf(system.uuid) !== -1
  // const isCalculating = system && systemCalculatingUuids.indexOf(system.uuid) !== -1
  return (
    <div id="back-system-button" style={{ height: 65 }}>
      <SystemProgressBar isLoading={isLoading} isCalculating={isCalculating} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: 16,
          margin: '4px 0 9px 0',
          height: 47,
          fontSize: 16,
          cursor: 'pointer',
        }}
        onClick={() => {
          window.editor.unlockSelection()
          window.editor.select(system)
          if (onClose) onClose()
        }}
      >
        <ArrowBackIosIcon style={{ width: 25, height: 25, marginLeft: 5 }} id="ArrowBackIosIcon" />
        <div>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              verticalAlign: 'middle',
              textOverflow: 'ellipsis',
              maxWidth: 280,
              display: 'inline-block',
              fontSize: 18,
              height: 21,
              marginBottom: 2,
              color: selectedFontColor,
            }}
          >
            Back to {systemName}
          </div>
          {showSystemsOverview && (
            <OverviewFigures
              system={system}
              isSelectedSystem={false}
              isWaiting={isLoading || !!processQueue[systemUuid]}
              hasShadingCalcsAwaitingTrigger={hasShadingCalcsAwaitingTrigger}
            />
          )}
        </div>
      </div>
      <Divider style={{ background: 'rgb(194 194 194)' }} />
    </div>
  )
}

const PanelContent = ({ title, summary, feature, content, showTools, disableTools }) => {
  const [expanded, setExpanded] = useState(false)
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const showElements = useViewShowAsDict(
    [
      'studio.tabs.selected_panel_group.tools.FillRectangle',
      'studio.tabs.selected_panel_group.tools.FillFacet',
      'studio.tabs.selected_panel_group.tools.SelectFacet',
      'studio.tabs.selected_panel_group.tools.DetachFacet',
      'studio.tabs.selected_panel_group.tools.Duplicate',
      'studio.tabs.selected_panel_group.tools.AutoString',
      'studio.tabs.selected_panel_group.tools.Delete',
    ],
    true
  )

  if (isMobile && expanded === false) {
    return (
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setExpanded(true)}
      >
        {title && (
          <div>
            <h2
              style={{ display: 'inline-block', fontWeight: 'bold', fontSize: 16, margin: '5px 0', color: '#3F3F3F' }}
            >
              {translate(title)}
            </h2>
            {isMobile && <CancelButton />}
          </div>
        )}
        <ExpandMoreIcon />
      </div>
    )
  }

  return (
    <>
      {title || summary || (!isMobile && disableTools !== true) ? (
        <div style={{ padding: 10 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: isMobile ? 'pointer' : 'auto',
            }}
            onClick={() => isMobile && setExpanded(false)}
          >
            {title && (
              <div>
                <h2
                  style={{
                    display: 'inline-block',
                    fontWeight: 'bold',
                    fontSize: 16,
                    margin: '5px 0',
                    color: '#3F3F3F',
                  }}
                >
                  {translate(title)}
                </h2>
                {isMobile && <CancelButton />}
              </div>
            )}
            {isMobile && <ExpandLessIcon />}
          </div>
          {summary && <div style={{ fontSize: 12, lineHeight: '14px' }}>{summary}</div>}
        </div>
      ) : null}

      {feature && <div style={{ padding: 10 }}>{feature}</div>}

      <div style={{ marginLeft: 0, marginRight: 0, padding: 10 }}>{content}</div>

      {title || summary || (!isMobile && disableTools !== true)
        ? showTools === true && <Tools name="ToolsPanel" showElements={showElements} />
        : null}
    </>
  )
}

class Panel extends Component {
  constructor(props) {
    super(props)
    var _state = {
      visible: true,
      systemsLoadingUuids: [], //managed directly by Designer queue
      systemCalculatingUuids: [], //managed directly by Designer queue
      hasShadingCalcsAwaitingTrigger: false,
      allowEdit: true,
    }

    //Override with any supplied state - for use in storybook
    if (props.state) {
      for (var key in props.state) {
        _state[key] = props.state[key]
      }
    }

    this.state = _state
    this.name = 'Panel_' + Math.round(Math.random() * 1000000)
  }

  componentDidMount() {
    closablePanels.add(this.name, this.close, this)
    this.props.useStudioSignals(this.refreshPanel, ['windowResize'], this)

    this.props.useStudioSignals(
      (info) => {
        if (
          info.systemsLoadingUuids.join(',') === this.state.systemsLoadingUuids.join(',') &&
          info.systemCalculatingUuids.join(',') === this.state.systemCalculatingUuids.join(',')
        )
          return
        this.setState({ ...this.state, ...info })
      },
      ['queueProcessed']
    )
  }

  componentWillUnmount() {
    closablePanels.remove(this.name)
  }

  open() {
    this.setState({ visible: true })

    //hack to force marking tooltips as dirty
    window.editor && window.editor.signals.windowResize.dispatch()
  }

  close(closeAllOthers) {
    if (closeAllOthers === true) {
      // Be sure to call handleCloseOtherMenus before setState because otherwise
      // `this` is corrupted with and old version from before setState
      // window.Designer.handleCloseOtherMenus('all')
      closablePanels.closeAll()
    }
    this.setState({ visible: false })
    //hack to force marking tooltips as dirty
    window.editor && window.editor.signals.windowResize.dispatch()
  }

  toggle() {
    if (this.state.visible) {
      this.close()
      return false
    } else {
      this.open()
      return true
    }
  }

  refreshPanel() {
    if (window.Designer && !window.Designer.allowUiUpdates()) {
      return
    }
    this.setState({
      hasShadingCalcsAwaitingTrigger: window.ShadeHelper.hasShadingCalcsAwaitingTrigger(
        window.editor.selectedSystem?.uuid
      ),
    })
  }

  render() {
    // only apply state.visible if small screen, otherwise always show regardless of visible value
    if (!this.state.visible) {
      return null
    }
    const { systemsLoadingUuids, systemCalculatingUuids, hasShadingCalcsAwaitingTrigger } = this.state
    return (
      <StyledPaper id="Panel">
        {window.editor.selected?.type === 'OsModuleGrid' && <AddPanelButton disabled={!this.state.allowEdit} />}
        <div
          style={{
            WebkitOverflowScrolling: 'touch',
            maxHeight: 'calc(100vh - 120px)',
            overflow: 'auto',
          }}
        >
          <BackToSelectedSystem
            onClose={this.props.onClose}
            systemsLoadingUuids={systemsLoadingUuids}
            systemCalculatingUuids={systemCalculatingUuids}
            hasShadingCalcsAwaitingTrigger={hasShadingCalcsAwaitingTrigger}
          />
          <PanelContent {...this.props} />
        </div>
      </StyledPaper>
    )
  }
}
export default withStudioSignals(Panel)
