import { FormControl, FormControlLabel, RadioGroup, Tooltip, Typography } from '@material-ui/core'
import { FileUpload } from 'elements/fileUpload/FileUpload'
import DateFormInput, { formatDate } from 'elements/input/DateInput'
import Modal from 'elements/modal/Modal'
import { isEmpty } from 'lodash'
import { Button, Checkbox, DialogCloseButton, HelpCircleIcon, Radio } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React, { useState } from 'react'
import { TextInput, useLocale, useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import appStorage from 'storage/appStorage'
import { ProjectType } from 'types/projects'
import { doNotTranslate, formatCurrencyWithSymbol } from 'util/misc'
import DiscardDialog from './ConfirmDialog'
import useStyles from './scaffoldStyle'

interface QuoteProps {
  isOpen: boolean
  onClose: () => void
  createQuote: () => void
}

const CreateQuote: React.FunctionComponent<QuoteProps> = ({ isOpen, onClose, createQuote }) => {
  const classes = useStyles()
  const locale = useLocale()
  const project = useFormState().values as ProjectType
  const form = useForm()
  const notify = useNotify()
  let customData = project?.custom_data?.scaffolding

  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)
  const [step, setStep] = useState(1)
  const [errors, setErrors] = useState({})

  const handleNextStep = () => {
    if (Object.keys(errors).length > 0) return
    const error = validateForm(customData)
    setErrors((prevErrors) => ({ ...prevErrors, ...error }))
    if (Object.keys(error).length > 0) return
    setStep(step + 1)
  }

  const handlePreviousStep = () => {
    setStep(step - 1)
  }

  const handleSaveDraft = (e) => {
    form.change('custom_data.scaffolding.draft', true)
    setStep(1)
    onClose()
  }

  const validateForm = (values) => {
    const errors = {}
    if (step === 2) {
      if (
        !values.form_data.customer_last_name ||
        !values.form_data.customer_phone ||
        !values.form_data.project_installation_date ||
        !values.form_data.project_manager_email
      ) {
        errors['form_data'] =
          'Please enter the required fields: Last Name, Phone Number, Project Manager Email and Installation Date.'
      }
    } else if (step === 3) {
      if (!values.submit_order_consent) {
        errors['form_data'] = 'Please accept the agreement before submitting order.'
      } else if (!values.form_data.site_photos.length) {
        errors['form_data'] = 'Please upload site photographs before submitting order.'
      } else if (!values.form_data.scaffold_properties.require_shrouding) {
        errors['form_data'] = 'Please answer if property fed by overhead powerlines requires shrouding.'
      } else if (values.form_data.site_notes.length > 250) {
        errors['form_data'] = 'Please limit site notes to less than 250 characters.'
      }
    }
    return errors
  }
  const handleQuoteCreation = (e) => {
    const error = validateForm(customData)
    setErrors(error)
    if (Object.keys(error).length > 0) return

    createQuote()
    onClose()
    setStep(1)
  }

  const uploadFiles = async (formData) => {
    try {
      const response = await fetch(`${window.API_ROOT}/api/orgs/1/private_files/`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + appStorage.getToken(),
        },
      })
      return await response.json()
    } catch (error) {
      notify(doNotTranslate(`Error uploading files: ${error}`))
      console.error('Error uploading files:', error)
    }
  }

  const addFile = async (files: File[]) => {
    if (isEmpty(files)) {
      return
    }
    try {
      const uploadedFilePromises = files.map(async (file) => {
        const formData = new FormData()
        formData.append('file_contents', file)
        const res = await uploadFiles(formData)
        return {
          file,
          name: file.name,
          size: file.size,
          url: res.file_contents,
        }
      })

      const uploadedFiles = await Promise.all(uploadedFilePromises)

      const site_photos = customData?.form_data?.site_photos || []
      customData = {
        ...customData,
        form_data: {
          ...customData.form_data,
          site_photos: [...site_photos, ...uploadedFiles],
        },
      }
      form.change('custom_data.scaffolding', customData)
      setErrors({})
    } catch (error) {
      notify(doNotTranslate(`Error uploading files: ${error}`))
      console.error('Error uploading files:', error)
    }
  }

  const addFileShroud = async (files: File[]) => {
    if (isEmpty(files)) {
      return
    }
    try {
      const uploadedFilePromises = files.map(async (file) => {
        const formData = new FormData()
        formData.append('file_contents', file)
        const res = await uploadFiles(formData)
        return {
          file,
          name: file.name,
          size: file.size,
          url: res.file_contents,
        }
      })

      const uploadedFiles = await Promise.all(uploadedFilePromises)

      const site_connecting_photos = customData?.form_data?.site_connecting_photos || []
      customData = {
        ...customData,
        form_data: {
          ...customData.form_data,
          site_connecting_photos: [...site_connecting_photos, ...uploadedFiles],
        },
      }
      form.change('custom_data.scaffolding', customData)
    } catch (error) {
      notify(doNotTranslate(`Error uploading files: ${error}`))
      console.error('Error uploading files:', error)
    }
  }
  const deleteFile = (index: number) => {
    const customDataFiles = [...customData.form_data['site_photos']]
    customDataFiles.splice(index, 1)
    form.change('custom_data.scaffolding.form_data.site_photos', customDataFiles)
  }
  const deleteFileShroud = (index: number) => {
    const customDataFiles = [...customData.form_data['site_connecting_photos']]
    customDataFiles.splice(index, 1)
    form.change('custom_data.scaffolding.form_data.site_connecting_photos', customDataFiles)
  }
  const handleInputChange = (e, name: string) => {
    if (name === 'submit_order_consent') {
      form.change('custom_data.scaffolding.submit_order_consent', !customData?.submit_order_consent)
    } else {
      form.change(`custom_data.scaffolding.form_data.scaffold_properties.${name}`, e.target.value)
    }
    setErrors({})
  }
  const handleCustomerInfoChange = (e, name: string) => {
    if (name === 'project_installation_date') {
      if (!e) {
        setErrors({ ...errors, project_installation_date: '' })
        return
      }
      const today = new Date()
      const tomorrow = new Date()
      tomorrow.setDate(today.getDate() + 1)
      const installationDate = new Date(e)
      if (installationDate < today) {
        setErrors({
          ...errors,
          project_installation_date: 'Installation date must be at least 2 business days from today.',
        })
        return
      }
      if (
        installationDate.toDateString() === today.toDateString() ||
        installationDate.toDateString() === tomorrow.toDateString()
      ) {
        setErrors({
          ...errors,
          project_installation_date: 'Installation date must be at least 2 business days from today.',
        })
        return
      }
      form.change(`custom_data.scaffolding.form_data.${name}`, e)
    } else {
      form.change(`custom_data.scaffolding.form_data.${name}`, e.target.value)
    }
    setErrors({})
  }

  return (
    <Modal
      isOpen={isOpen}
      header={
        <div className={classes.modalHeader}>
          <span className={classes.modalHeaderText}>{doNotTranslate('Create Quote')}</span>
          <DialogCloseButton onClick={() => setDiscardDialogOpen(true)} />
          <DiscardDialog parentClose={onClose} isOpen={discardDialogOpen} setOpen={setDiscardDialogOpen} />
        </div>
      }
      body={
        <div>
          <div className={`${classes.wrapper} ${classes.gap24}`}>
            <div className={classes.stepper}>
              <div className={`${classes.stepperProgressBar} ${step >= 1 ? classes.stepperProgressBarFilled : ''}`} />
              <div className={`${classes.infoContainerSubTitle} ${step >= 1 ? classes.stepperTextFilled : ''}`}>
                {doNotTranslate('Cost Estimate')}
              </div>
            </div>
            <div className={classes.stepper}>
              <div className={`${classes.stepperProgressBar} ${step >= 2 ? classes.stepperProgressBarFilled : ''}`} />
              <div className={`${classes.infoContainerSubTitle} ${step >= 2 ? classes.stepperTextFilled : ''}`}>
                {doNotTranslate('Customer Information')}
              </div>
            </div>
            <div className={classes.stepper}>
              <div className={`${classes.stepperProgressBar} ${step >= 3 ? classes.stepperProgressBarFilled : ''}`} />
              <div className={`${classes.infoContainerSubTitle} ${step >= 3 ? classes.stepperTextFilled : ''}`}>
                {doNotTranslate('Site Information')}
              </div>
            </div>
          </div>
          {step === 1 && (
            <>
              <form>
                <div className={`${classes.formContent} ${classes.formFont}`}>
                  <div className={classes.fieldStyle}>
                    <Typography className={classes.fieldText}>{doNotTranslate('Building Height (m)')}</Typography>
                    <Typography className={classes.infoContainerSubTitle}>
                      {customData?.form_data?.scaffold_properties.height}
                    </Typography>
                  </div>
                  <div className={classes.fieldStyle}>
                    <Typography className={classes.fieldText}>
                      {doNotTranslate('Scaffold Length Required (m)')}
                    </Typography>
                    <Typography className={classes.infoContainerSubTitle}>
                      {customData?.form_data?.scaffold_properties.length}
                    </Typography>
                  </div>
                  <div className={classes.fieldStyle}>
                    <Typography className={classes.fieldText}>
                      {doNotTranslate('Are there multiple properties that require scaffolding?')}
                    </Typography>
                    <Typography className={classes.infoContainerSubTitle}>
                      {customData?.form_data?.scaffold_properties.multiple_properties
                        ? customData.form_data.scaffold_properties.multiple_properties
                        : 'No'}
                    </Typography>
                  </div>
                  <div className={classes.fieldStyle}>
                    <Typography className={classes.fieldText}>
                      {doNotTranslate('Is the scaffold on a pavement or road?')}
                    </Typography>
                    <Typography className={classes.infoContainerSubTitle}>
                      {customData?.form_data?.scaffold_properties.scaffold_on_pavement_or_road
                        ? customData.form_data.scaffold_properties.scaffold_on_pavement_or_road
                        : 'No'}
                    </Typography>
                  </div>
                  <div className={classes.fieldStyle}>
                    <Typography className={classes.fieldText}>
                      {doNotTranslate('Does the scaffold site require access through the house/building?')}
                    </Typography>
                    <Typography className={classes.infoContainerSubTitle}>
                      {customData?.form_data?.scaffold_properties.require_access_through_house_building
                        ? customData.form_data.scaffold_properties.require_access_through_house_building
                        : 'No'}
                    </Typography>
                  </div>
                  <div className={classes.fieldStyle}>
                    <Typography className={classes.fieldText}>
                      {doNotTranslate('Are there any obstructions that exceed 7 metres in width?')}
                    </Typography>
                    <Typography className={classes.infoContainerSubTitle}>
                      {customData?.form_data?.scaffold_properties.obstructions_exceeding_7m
                        ? customData.form_data.scaffold_properties.obstructions_exceeding_7m
                        : 'No'}
                    </Typography>
                  </div>
                </div>
              </form>
              {customData?.estimated_cost ? (
                <div className={classes.infoContainer}>
                  <div className={classes.wrapper}>
                    <Typography className={classes.infoContainerTitle}>{doNotTranslate('Scaffolding Cost')}</Typography>
                    <Typography className={classes.infoContainerTitle}>
                      {formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)}
                    </Typography>
                  </div>
                  <div className={classes.divider} />
                  <div className={classes.internalDetails}>
                    <div className={classes.wrapper}>
                      <Typography className={classes.costText}>
                        {doNotTranslate('Building Width and Scaffold Length')}
                      </Typography>
                      <Typography className={classes.costText}>
                        {formatCurrencyWithSymbol(
                          customData.estimated_cost -
                            (customData.form_data.scaffold_properties.require_access_through_house_building === 'Yes'
                              ? 100
                              : 0) -
                            (customData.form_data.scaffold_properties.scaffold_on_pavement_or_road === 'Yes' ? 100 : 0),
                          '£',
                          locale,
                          2
                        )}
                      </Typography>
                    </div>
                    <div className={classes.wrapper}>
                      <Typography className={classes.costText}>{doNotTranslate('Access through house')}</Typography>
                      <Typography className={classes.costText}>
                        {formatCurrencyWithSymbol(
                          customData.form_data.scaffold_properties.require_access_through_house_building === 'Yes'
                            ? 100
                            : 0,
                          '£',
                          locale,
                          2
                        )}
                      </Typography>
                    </div>
                    {customData?.pavement_cost && (
                      <div className={classes.internalInfoContainer}>
                        <div className={classes.wrapper}>
                          <Typography className={classes.costText}>
                            {doNotTranslate('Pavement Permit - Non London')}
                          </Typography>
                          <Typography className={classes.costText}>
                            {formatCurrencyWithSymbol(
                              customData.form_data.scaffold_properties.scaffold_on_pavement_or_road === 'Yes' ? 100 : 0,
                              '£',
                              locale,
                              2
                            )}
                          </Typography>
                        </div>
                        <div className={classes.infoContainerSubTitle}>
                          {doNotTranslate(
                            'Because the project location is outside London, we’ve included an additional cost.'
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={classes.infoContainer}>
                  <div className={classes.wrapper}>
                    <Typography className={classes.infoContainerTitle}>{doNotTranslate('Scaffolding Cost')}</Typography>
                  </div>
                  <div className={classes.internalDetails}>
                    <div className={classes.wrapper}>
                      <Typography className={classes.costText}>
                        {doNotTranslate(
                          'Because the project site has an obstruction that exceeds 7 metres in width, our team must conduct a review of the project. This should take in approximately 2 hours.'
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {step === 2 && (
            <>
              <form>
                <div className={`${classes.formContent} ${classes.formFont} ${classes.gap24} ${classes.marginBottom0}`}>
                  <div className={`${classes.wrapper} ${classes.gap24}`}>
                    <FormControl className={classes.fullWidth}>
                      <Typography className={classes.marginBottom5}>{doNotTranslate('Customer First Name')}</Typography>
                      <TextInput
                        source={'firstName'}
                        variant="outlined"
                        label={false}
                        className={`${classes.textInput} ${classes.margin0}`}
                        fullWidth={true}
                        placeholder="First Name"
                        value={customData.form_data.customer_first_name}
                        onChange={(e) => handleCustomerInfoChange(e, 'customer_first_name')}
                      />
                    </FormControl>
                    <FormControl className={classes.fullWidth}>
                      <Typography className={classes.marginBottom5}>
                        {doNotTranslate('Customer Last Name')}
                        <span>*</span>
                      </Typography>
                      <TextInput
                        source={'lastName'}
                        variant="outlined"
                        label={false}
                        className={`${classes.textInput} ${classes.margin0}`}
                        fullWidth={true}
                        placeholder="Last Name"
                        value={customData.form_data.customer_last_name}
                        onChange={(e) => handleCustomerInfoChange(e, 'customer_last_name')}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={classes.fullWidth}>
                      <Typography className={classes.marginBottom5}>{doNotTranslate('Customer Email')}</Typography>
                      <TextInput
                        source={'email'}
                        variant="outlined"
                        label={false}
                        className={`${classes.textInput} ${classes.margin0}`}
                        fullWidth={true}
                        placeholder="Customer Email"
                        value={customData.form_data.customer_email}
                        onChange={(e) => handleCustomerInfoChange(e, 'customer_email')}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={classes.fullWidth}>
                      <Typography className={classes.marginBottom5}>
                        {doNotTranslate('Customer Phone Number')}
                        <span>*</span>
                      </Typography>
                      <TextInput
                        source={'.phone'}
                        variant="outlined"
                        label={false}
                        className={`${classes.textInput} ${classes.margin0}`}
                        fullWidth={true}
                        placeholder="Phone Number"
                        value={customData.form_data.customer_phone}
                        onChange={(e) => handleCustomerInfoChange(e, 'customer_phone')}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <div className={`${classes.wrapper} ${classes.gap24}`}>
                      <FormControl className={classes.fullWidth}>
                        <Typography className={classes.marginBottom5}>
                          {doNotTranslate('Project Installation Date')}
                          <span>*</span>
                        </Typography>
                        <DateFormInput
                          label=""
                          source="myDateField"
                          resource="myResource"
                          defaultValue={
                            customData?.form_data?.project_installation_date
                              ? formatDate(new Date(customData.form_data.project_installation_date))
                              : ''
                          }
                          inputVariant="outlined"
                          disabled={false}
                          hideClearButton={false}
                          className={`${classes.fullWidth} ${classes.textInput}`}
                          onChange={(e) => handleCustomerInfoChange(e, 'project_installation_date')}
                        />
                      </FormControl>
                      <FormControl className={classes.fullWidth}>
                        <Typography className={classes.marginBottom5}>
                          {doNotTranslate('Project Manager Email')}
                          <span>*</span>
                        </Typography>
                        <TextInput
                          source={'managerEmail'}
                          variant="outlined"
                          label={false}
                          className={`${classes.textInput} ${classes.margin0}`}
                          fullWidth={true}
                          placeholder="Project Manager Email"
                          value={customData.form_data.project_manager_email}
                          onChange={(e) => handleCustomerInfoChange(e, 'project_manager_email')}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div>
                  {Object.keys(errors).length > 0 && (
                    <div className={`${classes.errorText} ${classes.marginTop24}`}>
                      {errors['form_data'] && <div>{doNotTranslate(errors['form_data'])}</div>}
                      {errors['project_installation_date'] && (
                        <div>{doNotTranslate(errors['project_installation_date'])}</div>
                      )}
                    </div>
                  )}
                </div>
              </form>
            </>
          )}
          {step === 3 && (
            <>
              <form>
                <div className={`${classes.formContent} ${classes.formFont} ${classes.gap24}`}>
                  <div className={classes.fieldsContainer}>
                    <Typography>
                      {doNotTranslate('Is the property fed by overhead powerlines that require shrouding?')}
                      <span>*</span>
                    </Typography>
                    <RadioGroup
                      value={customData.form_data.scaffold_properties.require_shrouding}
                      onClick={(e) => handleInputChange(e, 'require_shrouding')}
                      style={{ display: 'flex', flexDirection: 'column', marginRight: 'auto' }}
                    >
                      <FormControlLabel
                        label={'Yes'}
                        value="Yes"
                        control={
                          <Radio checked={customData.form_data.scaffold_properties.require_shrouding === 'Yes'} />
                        }
                      />
                      <FormControlLabel
                        label={'No'}
                        value="No"
                        control={
                          <Radio checked={customData.form_data.scaffold_properties.require_shrouding === 'No'} />
                        }
                      />
                    </RadioGroup>
                  </div>
                  {customData?.form_data?.scaffold_properties?.require_shrouding === 'Yes' && (
                    <div>
                      <div className={classes.marginBottom24}>
                        <Typography className={classes.costText}>
                          {doNotTranslate('Please upload a photo of where it connects to the house (optional)')}
                        </Typography>
                        <Typography
                          className={classes.costText}
                          style={{ color: '#7F7D83', width: '850px', marginTop: 8 }}
                        >
                          {doNotTranslate(
                            'Shrouding is completed by the local DNO. This is not a chargeable service but be aware times for completion can vary depending on the DNO’s workload and availability. We will work to get you the soonest possible date and update you.'
                          )}
                        </Typography>
                      </div>
                      <div>
                        <div
                          style={{ position: 'relative', zIndex: 90, marginBottom: 0, width: '100%' }}
                          className={classes.imageUpload}
                        >
                          <FileUpload
                            keyName="house-connecting-photos"
                            uploadFileSize={2}
                            unit="MB"
                            deleteFile={deleteFileShroud}
                            addFile={addFileShroud}
                            fileLinks={[...(customData?.form_data?.site_connecting_photos || [])]}
                            acceptedFormats={['png', 'jpg', 'jpeg']}
                            description={'PNG, JPG or JPEG - Max file size 2MB'}
                            fileFormatIncorrectText="Failed to upload the file. Format is incorrect."
                            fileUploadContainerTitle="Drag & drop files or "
                            variant={'small'}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    <Typography className={classes.costText}>
                      {doNotTranslate('Site Photographs')}
                      <span>*</span>
                    </Typography>
                    <Typography className={classes.costText} style={{ color: '#7F7D83', width: '850px', marginTop: 8 }}>
                      {doNotTranslate(
                        'Please upload site photographs showing the target roof, relevant elevation, ground elevation, and access.'
                      )}
                    </Typography>
                  </div>
                  <div>
                    <div
                      style={{ position: 'relative', zIndex: 90, marginBottom: 0, width: '100%' }}
                      className={classes.imageUpload}
                    >
                      <FileUpload
                        keyName="site-photos"
                        uploadFileSize={2}
                        unit="MB"
                        deleteFile={deleteFile}
                        addFile={addFile}
                        fileLinks={[...(customData?.form_data?.site_photos || [])]}
                        acceptedFormats={['png', 'jpg', 'jpeg']}
                        description={'PNG, JPG or JPEG - Max file size 2MB'}
                        fileFormatIncorrectText="Failed to upload the file. Format is incorrect."
                        fileUploadContainerTitle="Drag & drop files or "
                        variant={'large'}
                      />
                    </div>
                  </div>
                  <div className={`${classes.gap6} ${classes.displayFlex} ${classes.alignItemsCenter}`}>
                    <Typography className={classes.costText}>{doNotTranslate('Site notes (optional)')}</Typography>
                    <Tooltip
                      title={
                        <div className={classes.tooltipContent}>
                          <div>{doNotTranslate('Any site access issues?')}</div>
                          <div>{doNotTranslate('Any non weight bearing lower roofs to be aware of?')}</div>
                          <div>{doNotTranslate('Is neighbors access permission required?')}</div>
                          <div>{doNotTranslate('Parking restrictions?')}</div>
                          <div>{doNotTranslate('Site opening/closing times?')}</div>
                        </div>
                      }
                      PopperProps={{
                        style: {
                          zIndex: 2002,
                        },
                      }}
                      interactive
                      placement="bottom"
                      arrow
                      classes={{
                        arrow: classes.tooltipArrow,
                        tooltip: classes.tooltip,
                      }}
                    >
                      <span className={classes.tooltipIconSize}>
                        <HelpCircleIcon size={14} color={COLOR_PALETTE.iconColor} />
                      </span>
                    </Tooltip>
                  </div>
                  <div>
                    <TextInput
                      source={'siteNotes'}
                      variant="outlined"
                      label={false}
                      className={`${classes.textInput} ${classes.margin0}`}
                      fullWidth={true}
                      placeholder="Site notes"
                      multiline
                      rows={3}
                      value={customData.form_data.site_notes}
                      onChange={(e) => handleCustomerInfoChange(e, 'site_notes')}
                      inputProps={{ maxLength: 250 }}
                    />
                  </div>
                </div>
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    checked={customData.submit_order_consent}
                    onChange={(e) => handleInputChange(e, 'submit_order_consent')}
                  />
                  <div className={classes.costText}>
                    {doNotTranslate(
                      'I confirm that we have obtained all the required and necessary consents from the owner, or occupier of the property or site, to share their details to allow for the lawful processing of their data, to enable the performance of this scaffold request and any subsequent scaffold hire.'
                    )}
                    <span>*</span>
                  </div>
                </div>
                <div className={classes.costText}>
                  {customData?.estimated_cost > 0
                    ? doNotTranslate(
                        'Clicking book now will secure this scaffolding job with PVF. You will receive an invoice within 48 business hours to make payment.'
                      )
                    : doNotTranslate(
                        'Clicking Create PVF Quote will send a quote request to PVF. You will receive a quote within 2 business hours.'
                      )}
                </div>
                <div>
                  {Object.keys(errors).length > 0 && (
                    <div className={`${classes.errorText} ${classes.marginTop24}`}>
                      {doNotTranslate(errors['form_data'])}
                    </div>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      }
      footer={
        <div className={classes.modalFooter}>
          <div>
            {step === 1 ? (
              <></>
            ) : (
              <Button onClick={handlePreviousStep} color={'default'} className={classes.saveExitBtnStyle}>
                {'Back'}
              </Button>
            )}
          </div>
          <div className={classes.modalFooterButtonsRight}>
            <Button onClick={handleSaveDraft} color={'default'} className={classes.saveExitBtnStyle}>
              {'Save Draft'}
            </Button>
            {step === 3 ? (
              <Button onClick={handleQuoteCreation} color={'primary'} className={classes.enableBtnStyle}>
                {customData?.estimated_cost > 0 ? 'Book Now' : 'Create PVF Quote'}
              </Button>
            ) : (
              <Button onClick={handleNextStep} color={'primary'} className={classes.enableBtnStyle}>
                {'Next'}
              </Button>
            )}
          </div>
        </div>
      }
    />
  )
}

export default CreateQuote
