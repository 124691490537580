import { sortByIntegration } from 'projectSections/sections/design/systems/tabs/paymentOptions/util'
import { CalculatedPaymentOption } from 'projectSections/sections/payments/paymentOptionSelection/types'
import { ReduxActionType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { RootState } from 'types/state'
import { parseJsonSafe } from 'util/misc'

export const INITIALIZE_PAYMENT_OPTION_SELECTION = 'INITIALIZE_PAYMENT_OPTION_SELECTION'
export const UPDATE_SELECTED_PAYMENT_OPTIONS = 'UPDATE_SELECTED_PAYMENT_OPTIONS'
export const REMOVE_SELECTED_PAYMENT_OPTION = 'REMOVE_SELECTED_PAYMENT_OPTION'
export const ADD_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION = 'ADD_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION'
export const REMOVE_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION = 'REMOVE_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION'
export const CLEAR_PAYMENT_OPTION_OVERRIDE_IDS = 'CLEAR_PAYMENT_OPTION_OVERRIDE_IDS'
export const MARK_SYSTEM_AS_CALCULATING = 'MARK_SYSTEM_AS_CALCULATING'
export const MARK_SYSTEM_CALC_AS_COMPLETE = 'MARK_SYSTEM_CALC_AS_COMPLETE'
export const REFRESH_SYSTEMS_IN_PAYMENTS_PAGE = 'REFRESH_SYSTEMS_IN_PAYMENTS_PAGE'
export const SET_PENDING_PAYMENT_OPTIONS = 'SET_PENDING_PAYMENT_OPTIONS'
export const CLEAR_PENDING_PAYMENT_OPTIONS = 'CLEAR_PENDING_PAYMENT_OPTIONS'
export const UPDATE_AVAILABLE_PAYMENT_OPTIONS = 'UPDATE_AVAILABLE_PAYMENT_OPTIONS'

export const updateAvailablePaymentOptions = (availablePaymentOptions: PaymentOptionDataType[]) => {
  return {
    type: UPDATE_AVAILABLE_PAYMENT_OPTIONS,
    payload: {
      availablePaymentOptions,
    },
  }
}

export const triggerSystemRefresh = () => {
  return {
    type: REFRESH_SYSTEMS_IN_PAYMENTS_PAGE,
  }
}

export const markSystemAsCalculating = (systemUuid: string) => {
  return {
    type: MARK_SYSTEM_AS_CALCULATING,
    payload: systemUuid,
  }
}

export const markSystemCalcAsComplete = (systemUuid: string) => {
  return {
    type: MARK_SYSTEM_CALC_AS_COMPLETE,
    payload: systemUuid,
  }
}

export const removeSelectedPaymentOption = (pmtId: number) => {
  return {
    type: REMOVE_SELECTED_PAYMENT_OPTION,
    payload: pmtId,
  }
}

export const updateSelectedPaymentOptions = (pmt: CalculatedPaymentOption) => {
  return {
    type: UPDATE_SELECTED_PAYMENT_OPTIONS,
    payload: pmt,
  }
}

export const addAllPaymentOptionsForIntegration = (integration: string) => {
  return {
    type: ADD_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION,
    payload: integration,
  }
}

export const removeAllPaymentOptionsForIntegration = (integration: string) => {
  return {
    type: REMOVE_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION,
    payload: integration,
  }
}

export const setPendingPaymentOptions = (pmts: CalculatedPaymentOption[], systemUuid: string) => {
  return {
    type: SET_PENDING_PAYMENT_OPTIONS,
    payload: {
      paymentOptions: pmts,
      systemUuid,
    },
  }
}

export const clearPendingPaymentOptions = () => {
  return {
    type: CLEAR_PENDING_PAYMENT_OPTIONS,
  }
}

export const initializeDialog = (pmts: CalculatedPaymentOption[]) => {
  return {
    type: INITIALIZE_PAYMENT_OPTION_SELECTION,
    payload: pmts,
  }
}

export const clearPaymentOptionOverrideIds = () => {
  return {
    type: CLEAR_PAYMENT_OPTION_OVERRIDE_IDS,
  }
}

export type PaymentOptionSelectionReduxType = {
  selectedPaymentOptions: CalculatedPaymentOption[]
  isInitialized: boolean
  uuidsCalculating: string[]
  refreshSystemsTrigger: string | undefined
  paymentOptionsPendingCalcs: CalculatedPaymentOption[]
  systemUuidPendingCalcs: string | undefined
  availablePaymentOptions: PaymentOptionDataType[]
}
const initialState: PaymentOptionSelectionReduxType = {
  selectedPaymentOptions: [],
  isInitialized: false,
  uuidsCalculating: [],
  refreshSystemsTrigger: undefined,
  paymentOptionsPendingCalcs: [],
  systemUuidPendingCalcs: undefined,
  availablePaymentOptions: [],
}

export default function reducer(state = initialState, action: ReduxActionType) {
  switch (action.type) {
    case INITIALIZE_PAYMENT_OPTION_SELECTION:
      return {
        ...state,
        isInitialized: true,
        selectedPaymentOptions: action.payload,
      }
    case SET_PENDING_PAYMENT_OPTIONS:
      return {
        ...state,
        paymentOptionsPendingCalcs: action.payload.paymentOptions,
        systemUuidPendingCalcs: action.payload.systemUuid,
      }
    case CLEAR_PENDING_PAYMENT_OPTIONS:
      return {
        ...state,
        paymentOptionsPendingCalcs: [],
        systemUuidPendingCalcs: undefined,
      }
    case CLEAR_PAYMENT_OPTION_OVERRIDE_IDS:
      return {
        ...state,
        selectedPaymentOptions: [],
        isInitialized: false,
      }
    case REMOVE_SELECTED_PAYMENT_OPTION:
      return {
        ...state,
        selectedPaymentOptions: state.selectedPaymentOptions?.filter((pmt) => pmt.payment_option_id !== action.payload),
      }
    case ADD_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION:
      let newValWithAllIntegrationPmts = [...state.selectedPaymentOptions]
      let unarchivedProductsForIntegration = state?.availablePaymentOptions?.filter((pmt) => {
        let pmtIntegration = pmt.configuration_json ? parseJsonSafe(pmt.configuration_json)?.integration : undefined
        return pmtIntegration && pmtIntegration === action.payload && !pmt?.is_archived
      })
      // if the integration is phoenix we need to ensure the payment options are added to the end and that the phoenix products are sorted by first the priority if they have one
      // and then the loan term ASC if priorities are not saved

      if (action.payload === 'phoenix') {
        unarchivedProductsForIntegration = sortByIntegration(unarchivedProductsForIntegration)
      }
      unarchivedProductsForIntegration?.forEach((pmtToAdd) => {
        let pmtAlreadyAdded = newValWithAllIntegrationPmts?.find(
          (existingPmt) => existingPmt.payment_option_id === pmtToAdd.id
        )
        let configJson = parseJsonSafe(pmtToAdd.configuration_json)
        if (!pmtAlreadyAdded) {
          newValWithAllIntegrationPmts.push({
            payment_option_id: pmtToAdd.id,
            title: pmtToAdd.title,
            repayment: null,
            system_price: null,
            payment_type: pmtToAdd?.payment_type,
            payment_frequency: configJson?.payment_frequency || 'monthly',
            integration: action.payload,
            interest_rate: configJson?.interest_rate,
            term_periods: configJson?.term,
            term_years: configJson?.term_years,
            is_popular: false,
            interest_rate_disclaimer: null,
            messages: [],
          })
        }
      })
      return {
        ...state,
        selectedPaymentOptions: [...newValWithAllIntegrationPmts],
      }
    case REMOVE_ALL_PAYMENT_OPTIONS_FOR_INTEGRATION:
      return {
        ...state,
        selectedPaymentOptions: state.selectedPaymentOptions?.filter((pmt) => pmt?.integration !== action.payload),
      }

    case UPDATE_SELECTED_PAYMENT_OPTIONS:
      let newVal = [...state.selectedPaymentOptions]
      let isAlreadySelected = !!state.selectedPaymentOptions?.find(
        (pmt) => pmt.payment_option_id === action.payload.payment_option_id
      )
      if (isAlreadySelected) {
        newVal = state.selectedPaymentOptions?.filter(
          (pmt) => pmt.payment_option_id !== action.payload.payment_option_id
        )
      } else {
        newVal.push(action.payload)
      }
      return {
        ...state,
        selectedPaymentOptions: newVal,
      }
    case MARK_SYSTEM_AS_CALCULATING:
      let newUuidsCalculating: string[] = []
      if (state.uuidsCalculating?.length) newUuidsCalculating = [...state.uuidsCalculating]
      if (!newUuidsCalculating.includes(action.payload.uuid)) newUuidsCalculating.push(action.payload.uuid)
      return {
        ...state,
        uuidsCalculating: newUuidsCalculating,
      }
    case MARK_SYSTEM_CALC_AS_COMPLETE:
      const uuids = state.uuidsCalculating?.filter((uuid) => uuid !== action.payload.uuid)
      return {
        ...state,
        uuidsCalculating: uuids,
      }
    case REFRESH_SYSTEMS_IN_PAYMENTS_PAGE:
      return {
        ...state,
        refreshSystemsTrigger: new Date(),
      }
    case UPDATE_AVAILABLE_PAYMENT_OPTIONS:
      return {
        ...state,
        availablePaymentOptions: action.payload.availablePaymentOptions,
      }
    default:
      return { ...state }
  }
}

export const paymentOptionSelectionSelectors = {
  getPaymentOptionOverrideIds: (state: RootState) =>
    state.paymentOptionSelection.selectedPaymentOptions.map((pmt) => pmt?.payment_option_id),
  getSelectedPaymentOptions: (state: RootState) => {
    return state.paymentOptionSelection.selectedPaymentOptions
  },
  getIsSelected: (state: RootState, pmtId: number) => {
    return !!state.paymentOptionSelection?.selectedPaymentOptions?.find((pmt) => pmt?.payment_option_id === pmtId)
  },
  getIsDialogInitialized: (state: RootState) => state.paymentOptionSelection.isInitialized,
  getIsSystemCalculating: (state: RootState, uuid: string) => {
    return state.paymentOptionSelection.uuidsCalculating?.includes(uuid)
  },
  getSystemRefreshTrigger: (state: RootState) => state.paymentOptionSelection.refreshSystemsTrigger,
  getPaymentOptionsPendingCalcs: (state: RootState) => state.paymentOptionSelection.paymentOptionsPendingCalcs,
  getSystemUuidPendingCalcs: (state: RootState) => state.paymentOptionSelection.systemUuidPendingCalcs,
  getAvailablePaymentOptions: (state: RootState) => state.paymentOptionSelection.availablePaymentOptions,
}
