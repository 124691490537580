import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { TextInput } from 'react-admin'
import { useBusinessIdentifierLabel } from 'resources/wallet/useBusinessIdentifierLabel'
import { lengthExact, lengthRange } from 'validations/length'
import { InputRow } from '../styles'

interface PropTypes {
  countryCode: string
}

const CommercialSiteFields: React.FC<PropTypes> = ({ countryCode }) => {
  const translate = useTranslate()
  const { businessIdLabelName, businessIdHelperText } = useBusinessIdentifierLabel(countryCode)

  const identifierValidator = useMemo(() => {
    if (countryCode === 'NZ') return lengthExact(11, translate)
    if (countryCode === 'AU') return lengthExact(8, translate)
    if (countryCode === 'GB') return lengthRange(6, 8, translate)
  }, [countryCode])

  return (
    <>
      <InputRow>
        <TextInput source={'business_name'} variant="outlined" label={'Business Name'} fullWidth />
        {['NZ', 'GB', 'AU'].includes(countryCode) && (
          <TextInput
            source={'business_identifier'}
            variant="outlined"
            label={translate(businessIdLabelName)}
            helperText={businessIdHelperText}
            fullWidth
            validate={identifierValidator}
          />
        )}
      </InputRow>
      {countryCode === 'US' && (
        <InputRow>
          <TextInput
            source={'federal_income_tax_rate_percentage'}
            variant="outlined"
            label={'Federal Income Tax Rate Percentage'}
            fullWidth
            InputProps={{
              endAdornment: '%',
            }}
          />
          <TextInput
            source={'state_income_tax_rate_percentage'}
            variant="outlined"
            label={'State Income Tax Rate Percentage'}
            fullWidth
            InputProps={{
              endAdornment: '%',
            }}
          />
        </InputRow>
      )}
    </>
  )
}

export default CommercialSiteFields
