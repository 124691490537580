import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { getDistributorConfigByEnum } from 'pages/ordering/configs'
import useHardwareDetailsDynamic from 'projectSections/sections/design/sideDrawer/bom/useHardwareDetailsDynamic'
import useMapHardwareDetailsToBomLineItems from 'projectSections/sections/design/sideDrawer/bom/useMapHardwareDetailsToBomLineItems'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { doNotTranslate } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  gapBottom: {
    marginBottom: 10,
  },
  gapTop: {
    marginTop: 10,
  },
}))

const NotStockedWarningForUK = () => {
  const classes = useStyles()
  const hardwareDetails = useHardwareDetailsDynamic()
  const bomLineItemsHashMap = useMapHardwareDetailsToBomLineItems(hardwareDetails)
  const supplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const selectedDistributor = getDistributorConfigByEnum(supplier)?.name

  const notStockedItemCodes = useMemo(
    () =>
      bomLineItemsHashMap.all
        ?.filter(
          (item) => !item.stockLevels && window.AccountHelper.getComponentActivationFromCode(item.code)?.cost === 0
        )
        .map(({ code }) => code) ?? [],
    [bomLineItemsHashMap]
  )

  if (!notStockedItemCodes.length) return null

  return (
    <Alert severity="warning">
      <div>
        <div className={classes.gapBottom}>
          {doNotTranslate(
            'Cost has not been updated for the following components because they are not currently stocked by'
          )}{' '}
          {selectedDistributor}:
        </div>
        {notStockedItemCodes.map((code) => (
          <div key={code}>{code}</div>
        ))}
        <div className={classes.gapTop}>
          {doNotTranslate(
            'These component costs are calculated from other distributor prices if available, or manual pricing if set.'
          )}
        </div>
      </div>
    </Alert>
  )
}

const NotStockedItemsWarning = () => {
  const hasEnabledUKDistributor = useSelector(orgSelectors.getEnableUKHardwareSuppliers).length > 0

  if (!hasEnabledUKDistributor) return null
  return <NotStockedWarningForUK />
}

export default NotStockedItemsWarning
