import { useSelectedSystem } from 'Designer/hooks/useSelectedSystem'
import { orgSelectors } from 'ducks/orgs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { useSelector } from 'react-redux'
import BomDrawer from './bom/BomDrawer'

//Installer: logic to show which drawer is design area
const DesignSideDrawer = () => {
  const system = useSelectedSystem()
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)

  if (
    system &&
    (selectedSupplier === HardwareSupplierEnum.Segen ||
      selectedSupplier === HardwareSupplierEnum.CityPlumbing ||
      selectedSupplier === HardwareSupplierEnum.Hdm ||
      selectedSupplier === HardwareSupplierEnum.VtacUk ||
      selectedSupplier === HardwareSupplierEnum.VtacPoland)
  ) {
    return <BomDrawer />
  }

  return null
}

export default DesignSideDrawer
