import { InputAdornment } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import UiContainer from 'elements/UiContainer'
import lodashDebounce from 'lodash/debounce'
import { Button, FilterIcon, SearchOutlineIcon, styled, TextField } from 'opensolar-ui'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { useCallback, useMemo, useState } from 'react'
import FilterDrawer from './filtering/FilterDrawer'

const uiKey = 'projects.search'
const DEFAULT_DEBOUNCE = 500

const Wrapper = styled('div')({
  width: '100%',
  margin: '10px 0',
})

const LeftContainer = styled('div')({
  marginRight: 'auto',
})

const SearchField = styled(TextField)({
  margin: 0,
  width: 400,
  marginRight: 10,
})

const SearchToolbar = ({ setFilters, filterValues }) => {
  const translate = useTranslate()
  const classes = getProjectSectionStyles()
  const [searchText, setSearchText] = useState('')
  const [filterOpen, setFilterOpen] = useState(false)

  const trackValueChanged = useCallback(() => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'updated',
      key: 'q',
    })
  }, [])

  const debouncedTrackValueChanged = useMemo(() => {
    return lodashDebounce(trackValueChanged, DEFAULT_DEBOUNCE)
  }, [trackValueChanged])

  const handleSearch = (e) => {
    const queryVal = e?.target?.value
    debouncedTrackValueChanged()
    setFilters({ ...filterValues, q: queryVal })
    setSearchText(e.target.value)
  }
  return (
    <UiContainer uiKey={uiKey}>
      <Wrapper className={classes.row}>
        <LeftContainer className={classes.row}>
          <SearchField
            label={false}
            size="small"
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlineIcon color={COLOR_PALETTE.iconColor} />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            variant="outlined"
            placeholder={translate('Search')}
          />
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FilterIcon />}
            onClick={() => {
              setFilterOpen(!filterOpen)
            }}
          >
            {translate('Sort & Filter')}
          </Button>
        </LeftContainer>
        {/* <BtnIcon variant="outlined" color="secondary" startIcon={<SettingsIcon />}>
          {translate('View Settings')}
        </BtnIcon> */}
      </Wrapper>
      <FilterDrawer
        open={filterOpen}
        onClose={() => {
          setFilterOpen(false)
        }}
        filterValues={filterValues}
        setFilters={setFilters}
      />
    </UiContainer>
  )
}

export default SearchToolbar
