import { FormControl, FormControlLabel, FormGroup, Grid } from '@material-ui/core'
import useOptimisticValue from 'elements/input/useOptimisticValue'
import { Checkbox } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { Theme } from 'types/themes'
import type { BasicRendererComponentPropsTypeV2, OptionGenericType } from '../typeV2'
import useLimitedOptionsController from './useLimitedOptionsController'
import useSearchInputController from './useSearchInputController'

const useStyles = makeOpenSolarStyles((theme: Theme) => ({
  filterOptions: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    margin: 0,
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  showMore: {
    color: '#0094FF',
    margin: '0 10px',
    fontWeight: 400,
    cursor: 'pointer',
  },
}))

/*
  A Checkbox renderer for hardware filters
*/
const BasicMultiCheckBoxRendererV2 = <OptionValue,>({
  id,
  label,
  options,
  disabled,
  onSelect,
  additionalProps,
  defaultValue,
  value,
}: BasicRendererComponentPropsTypeV2<OptionValue, Set<OptionValue>>) => {
  const classes = useStyles({ disabled })
  const { optimisticValue, updateOptimisticValue } = useOptimisticValue<Set<OptionValue>>({
    defaultValue,
    value,
  })

  const shouldShowSearch = options.length > 4
  const { SearchInputNode, filteredOptions } = useSearchInputController({ options, disabled })
  // Limit the options to 4
  const { limitedOptions, LimitedOptionsController } = useLimitedOptionsController(filteredOptions)

  const handleChange = (option: OptionGenericType<OptionValue>, newValue: boolean) => {
    const newOptimisticValue = new Set(optimisticValue)
    if (newValue) {
      newOptimisticValue.add(option.value)
    } else {
      newOptimisticValue.delete(option.value)
    }
    const finalValue = additionalProps?.rendererOnChangeMutator
      ? additionalProps.rendererOnChangeMutator({
          value: newOptimisticValue,
          oldValue: optimisticValue,
          selectedOption: option,
          options,
        })
      : newOptimisticValue
    updateOptimisticValue(finalValue)
    onSelect(finalValue, option)
  }

  if (!(optimisticValue instanceof Set)) {
    console.error(`Misusage of BasicMultiCheckBoxRendererV2 Id:${id}. Value should be an instance of Set`)
    return <></>
  }

  return (
    <FormControl id={id} disabled={disabled}>
      <Grid>
        {!!shouldShowSearch && SearchInputNode}
        <FormGroup>
          {limitedOptions.map((option, i) => {
            const checked = optimisticValue.has(option.value)
            return (
              <FormControlLabel
                className={classes.filterOptions}
                value={option.value}
                key={option.id}
                control={
                  <Checkbox onChange={() => handleChange(option, !checked)} checked={checked} name={option.title} />
                }
                label={option.title}
              />
            )
          })}
          {!disabled && LimitedOptionsController}
        </FormGroup>
      </Grid>
    </FormControl>
  )
}

export default BasicMultiCheckBoxRendererV2
