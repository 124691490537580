import { useCallback } from 'react'

const useLoadComponentSpecs = () => {
  const loadComponentSpecs = useCallback(async () => {
    const loadComponentSpecsPromise = new Promise((resolve, reject) => {
      var currentAjaxSessionCounter = window.Designer.AjaxSession.new('useLoadComponentSpecs()')
      window.AccountHelper.loadComponentSpecs(resolve, currentAjaxSessionCounter)
    })
    await loadComponentSpecsPromise
    return true
  }, [])
  return loadComponentSpecs
}

export default useLoadComponentSpecs
