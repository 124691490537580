// in src/users.js
import { useMediaQuery } from '@material-ui/core'
import ContentCreate from '@material-ui/icons/CreateOutlined'
import QuickFilter from 'elements/QuickFilter'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { StandardInputs } from 'elements/input/StandardInputs'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import ListActions from 'elements/react-admin/ListActions'
import React from 'react'
import { BooleanField, Create, Datagrid, FilterComp, SimpleForm, TextInput, useTranslate } from 'react-admin'
import { connect, useSelector } from 'react-redux'
import compose from 'recompose/compose'
import ShareabilityFilters from 'resources/connectedOrgs/ShareabilityFilters'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { styles as standardStyles } from 'styles'
import { duplicate as duplicateAction } from '../../actions/restActions'
import SimpleList from '../../elements/CustomSimpleList'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import DependentInput from '../../elements/input/DependentInput'
import { currencySymbolForCountry, getRoleFromState } from '../../util/misc'
import { getOrgCountryCodeFromState } from '../../util/org'
import AutoApplyInputs from '../autoApply/AutoApplyInputs'
import {
  INCENTIVE_PAID_TO_CUSTOMER,
  INCENTIVE_TYPES_WITH_APPLIED_OVER_TIME,
  INCENTIVE_TYPE_CHOICES,
  PRICE_BASED_INCENTIVE,
  SELF_GENERATION_INCENTIVE_PROGRAM,
  getIncentiveVariables,
} from './IncentiveConstants'
import IncentiveInputFields from './IncentiveInputFields'

const mapStateToProps = (state) => {
  return {
    currencySymbol: currencySymbolForCountry(getOrgCountryCodeFromState(state)),
    orgCountryCode: getOrgCountryCodeFromState(state),
  }
}

export const IncentiveFilter = (props) => (
  <FilterComp {...props}>
    <TextInput style={standardStyles.FILTER_FIELD_STYLE} label="pos.search" source="q" />
    <QuickFilter source="show_only_archived" defaultValue={1} />
  </FilterComp>
)

const _IncentiveList = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => {
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete, allowEdit)

  return (
    <List
      hasSearch={true}
      actions={<ListActions hasArchived={true} />}
      perPage={20}
      {...props}
      hasCreate={allowCreate}
      filters={<ShareabilityFilters />}
      bulkActionButtons={bulkActionButtons}
    >
      {isMobile ? (
        <SimpleList
          primaryText={(record) => `${record.title}`}
          secondaryText={(record) => (
            <span>
              {translate('Auto-apply')}: {record.auto_apply_enabled ? translate('Yes') : translate('No')}
            </span>
          )}
          linkTo={(basePath, id) => `${basePath}/${id}`}
          rightIcon={() => (allowCreate ? <ContentCreate /> : null)}
        />
      ) : (
        <Datagrid>
          <TextFieldWithSharedEntityIcon source="title" label="Title" />
          <BooleanField
            source="auto_apply_enabled"
            label="resources.incentives.fields.auto_apply"
            textAlign={'center'}
          />
          <EditOrImportButton
            sortable={false}
            source="actions"
            label="Actions"
            org_id={props.org_id}
            resource="incentives"
            duplicate={props.duplicate}
            isAdmin={isAdmin}
            allowEdit={allowEdit}
            allowDelete={allowDelete}
          />
        </Datagrid>
      )}
    </List>
  )
}

export const IncentiveList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
    }
  },
  { duplicate: duplicateAction }
)(_IncentiveList)

const csvFloatsToArray = (csvFloats) => {
  if (!csvFloats) {
    return null
  } else if (!csvFloats.split) {
    // Hacky method for checking that this is a string. If .split does not exist then we will not be able to reformat it
    // just return the value because it has probably already been converted to an array
    return csvFloats
  }
  return csvFloats.split(',').map((v) => parseFloat(v.trim()))
}

const validateIncentiveData = (values, translate) => {
  const errors = {}
  const missingFieldErrorMessage = 'field is required'
  const requiredFieldsForAllIncentives = ['title', 'incentive_type']
  requiredFieldsForAllIncentives.forEach((field) => {
    if (values[field] === undefined || values[field.key] === null) {
      errors[field] = [missingFieldErrorMessage]
    }
  })

  const fieldsForSelectedIncentive = getIncentiveVariables(values.incentive_type)
  // Make sure we throw a missing validation error for required fields that are not provided
  // TODO: We should consider following the pattern that uses validators defined in: spa/app/src/validations/index.js
  fieldsForSelectedIncentive.forEach((field) => {
    if (field.required && (values[field.key] === undefined || values[field.key] === null || values[field.key] === '')) {
      errors[field.key] = [missingFieldErrorMessage]
    }

    // Check to make sure valid comma-separated numbers are entered.
    if (field.isCsvField && values[field.key]?.length > 0) {
      const csvFloats = csvFloatsToArray(values[field.key])
      if (csvFloats.some((i) => isNaN(i))) {
        errors[field.key] = ['field is invalid - must be comma-separated numbers']
      }
    }
  })

  // If "Is Paid PBI" field is checked for SGIP incentive, then check that related PBI fields are set.
  if (values.incentive_type === SELF_GENERATION_INCENTIVE_PROGRAM && values.is_paid_pbi) {
    const pbiVariables = ['percentage_paid_in_pbi', 'max_pbi_years', 'full_discharges_per_year']
    pbiVariables.forEach((field) => {
      if (!values[field] && values[field] !== 0) {
        errors[field] = [missingFieldErrorMessage]
      }
    })
  }

  // If incentive applied over time period fields is enabled, then check that related time period fields are set.
  const timePeriodEnabled =
    values.is_applied_over_timeperiod && INCENTIVE_TYPES_WITH_APPLIED_OVER_TIME.includes(values.incentive_type)
  if (timePeriodEnabled) {
    const timePeriodFields = ['frequency', 'terms']
    timePeriodFields.forEach((field) => {
      if (!values[field] && values[field] !== 0) {
        errors[field] = [missingFieldErrorMessage]
      }
    })
  }

  return errors
}

const cleanUpTimePeriodFields = (values) => {
  // Clean up incentive applied over timeperiod fields by reference
  const timePeriodEnabled =
    values.is_applied_over_timeperiod && INCENTIVE_TYPES_WITH_APPLIED_OVER_TIME.includes(values.incentive_type)

  if (!timePeriodEnabled) {
    // If time period is not enabled, clear the relevant fields, which can otherwise mess with calcs
    console.debug('Clearing props associated with applying incentive over time')

    values.is_applied_over_timeperiod = false
    values.is_applied_to_utility_bill = false
    values.frequency = null
    values.terms = null
    values.multiplier_by_terms = null
  } else {
    // If time period is enabled, force the incentive to be paid to customer
    values.incentive_paid_to = INCENTIVE_PAID_TO_CUSTOMER
  }

  if (values.incentive_type === PRICE_BASED_INCENTIVE) {
    // Price based incentives cannot be applied to utility bill
    values.is_applied_to_utility_bill = false
  }
}

const cleanUpSGIPFields = (values) => {
  // Clean up SGIP incentive PBI fields by reference
  if (SELF_GENERATION_INCENTIVE_PROGRAM === values.incentive_type && !values.is_paid_pbi) {
    values.is_paid_pbi = false
    values.percentage_paid_in_pbi = null
    values.max_pbi_years = null
    values.full_discharges_per_year = null
    values.use_non_discounted_value_in_quotation = true
  }
}

const formatSubmitValues = (values) => {
  cleanUpTimePeriodFields(values)
  cleanUpSGIPFields(values)

  const fieldsForSelectedIncentive = getIncentiveVariables(values.incentive_type)
  const settingsObject = {}
  fieldsForSelectedIncentive.forEach((field) => {
    if (field.isCsvField) {
      //convert csv text to array of floats
      settingsObject[field.source] = csvFloatsToArray(values[field.source])
    } else if (field.component === 'NumberInput') {
      values[field.source] = parseFloat(values[field.source])
      settingsObject[field.source] = values[field.source]
    } else {
      settingsObject[field.source] = values[field.source]
    }
  })

  values.settings = settingsObject
  return values
}

const defaultValueEdit = (record) => {
  var defaultValuesObject = Object.assign(
    {
      incentive_type: undefined,
      incentive_paid_to: INCENTIVE_PAID_TO_CUSTOMER,
      cap_amount: undefined,
      auto_apply_enabled: false,
      use_for_loan_pay_down: false,
      order: 1,
      is_applied_over_timeperiod: false,
    },
    record
  )

  if (!record) {
    return defaultValuesObject
  }

  var settingsObject = {}
  // @TODO: Why is settings sometimes JSON string and sometimes object, after saving?
  if (record) {
    if (typeof record.settings === 'object') {
      settingsObject = record.settings
    } else if (typeof record.settings === 'string') {
      settingsObject = JSON.parse(record.settings)
    }
  }

  const obj = defaultValuesObject

  getIncentiveVariables(obj.incentive_type).forEach((field) => {
    // Extract settings data into form data
    if (field.isCsvField && Array.isArray(settingsObject[field.key])) {
      obj[field.key] = settingsObject[field.key].join(',')
    } else {
      obj[field.key] = settingsObject[field.key]
    }

    // Inject empty values for any other variables which are not yet included
    if (!obj.hasOwnProperty(field.key)) {
      obj[field.key] = null
    }
  })

  return obj
}

const getIncentiveTypeSelectionData = (orgCountryCode) => {
  const incentiveTypeChoices = INCENTIVE_TYPE_CHOICES.filter(
    (incentiveType) =>
      incentiveType.countryCodeFilter.length === 0 || incentiveType.countryCodeFilter.includes(orgCountryCode)
  )

  return {
    label: 'Incentive Type',
    sources: 'incentive_type',
    name: 'incentive_type',
    component: 'SelectInput',
    choices: incentiveTypeChoices,
  }
}

const IncentiveInputs = (props) => {
  const translate = useTranslate()
  let withToolBar = props.disabled ? { toolbar: false } : {}

  const incentiveTypeSelectionData = getIncentiveTypeSelectionData(props.orgCountryCode)
  return (
    <SimpleForm
      validate={(values) => validateIncentiveData(values, translate)}
      formatSubmitValues={formatSubmitValues}
      defaultValue={defaultValueEdit}
      {...props}
      {...withToolBar}
    >
      <StandardInputs autoApply="auto_apply_enabled" extraFields={[incentiveTypeSelectionData]} />
      <IncentiveInputFields currencySymbol={props.currencySymbol} />
      <DependentInput dependsOn="auto_apply_enabled" value={true}>
        <AutoApplyInputs enableByOrder={true} enableByComponentCode={false} disabled={props.disabled} />
      </DependentInput>
      <ShareabilitySelector />
    </SimpleForm>
  )
}

export const _IncentiveEdit = ({ accessRights, ...props }) => {
  return useEditShareable({
    accessRights: accessRights,
    editProps: props,
    render: ({ access }) => <IncentiveInputs {...props} disabled={!access.allowEdit} />,
  }).editPage
}

export const IncentiveEdit = connect(mapStateToProps, {})(_IncentiveEdit)

const _IncentiveCreate = ({ accessRights: { allowView, allowCreate, allowEdit, allowDelete }, ...props }) => (
  <Create {...props}>
    <IncentiveInputs {...props} disabled={!allowCreate} />
  </Create>
)
export const IncentiveCreate = compose(connect(mapStateToProps, {}))(_IncentiveCreate)
