import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  LinearProgress,
  Tooltip
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import { CameraAltOutlined, Description, ErrorOutlineOutlined, PublishOutlined, SaveOutlined } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import ChipsInput from 'elements/input/ChipsInput'

import { Button, IconButton } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React, { useCallback, useRef, useState } from 'react'
import { FileField, FileInput, FunctionField, ReferenceArrayInput, useRefresh, useTranslate } from 'react-admin'
import { Field, Form, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { projectInfoSelector } from 'reducer/project/projectInfoReducer'
import appStorage from 'storage/appStorage'
import { getDialogStyles } from '../dialogAndFieldsStyles'
import { useProjectFiles } from '../hooks/useProjectFiles'
import { LoadingOverlay } from '../styles'
import { imgTypeExtensionsAsString } from '../utils'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
  },
  dialogTitleSection: {
    borderBottom: '1px solid',
    borderColor: `${COLOR_PALETTE.neutralGrey500} !important`,
  },
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& :last-child': {
      paddingRight: 0,
    },
  },
}))

type PropTypes = {
  record: any
  isOpen: boolean
  onDismiss: any
  dialogSelected: string
}

type ProgressBarProps = {
  filesUploaded: number[]
  selectedFiles: number[]
}

const MultipleFilesDialog: React.FunctionComponent<PropTypes> = (props) => {
  const form = useForm()
  const formState = form.getState()
  const formValues = formState.values
  const projectUrl = formValues.url
  const projectId = useSelector(projectInfoSelector.getProjectId)
  const orgId = appStorage.getOrgId()
  const translate = useTranslate()
  const classes = useStyles()
  const dialogStyles = getDialogStyles()
  const { addMultipleFilesToForm } = useProjectFiles()

  const formRef = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filesUploaded, setFilesUploaded] = useState<any[]>([])
  const refresh = useRefresh()

  const [total, setTotal] = useState(0)

  const fileTypesAccepted = props.dialogSelected === 'upload_file' ? '' : imgTypeExtensionsAsString
  const placeholderIcon = props.dialogSelected === 'upload_file' ? <PublishOutlined /> : <CameraAltOutlined />

  const filesUploadedArr: any[] = []

  const defaultTags = props.dialogSelected === 'upload_file' ? [] : [`${window.API_ROOT}/api/file_tags/12/`]

  const dismiss = useCallback(() => {
    props.onDismiss()
  }, [])

  const confirmUploaded = async (res) => {
    filesUploadedArr.push(res)
    setFilesUploaded([...filesUploadedArr])
    return res
  }

  const uploadFile = async (formData: any) => {
    const response = await fetch(`${window.API_ROOT}/api/orgs/${orgId}/private_files/`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + appStorage.getToken(),
      },
    })
    const data = await response.json()
    return data
  }

  const formatFormData = async (file, tags) => {
    const formData = new FormData()
    formData.append('title', file.rawFile.name)
    formData.append('file_contents', file.rawFile)
    formData.append('project', projectUrl)
    formData.append('file_tags', tags)
    const resp = await uploadFile(formData)
    const data2 = await confirmUploaded(resp)
    return data2
  }

  const handleSubmit = (values) => {
    const tags = values.file_tags
    const uploadResponses = values.file_contents.map((f) => formatFormData(f, tags))
    Promise.all(uploadResponses)
      .then((res: any) => {
        addMultipleFilesToForm(res)
        setIsLoading(false)
        refresh()
        dismiss()
      })
      .catch((err) => {
        console.error('Failed to upload files', err)
      })
  }

  const handleExternalSubmit = () => {
    if (formRef.current) {
      const total = formRef.current.values.file_contents.length
      setTotal(total)
      formRef.current.handleSubmit()
    }
  }

  return (
    <>
      <Dialog
        open={props.isOpen}
        onBackdropClick={() => {
          if (!isLoading) {
            dismiss()
          }
        }}
      >
        {total > 0 && (
          <LoadingOverlay>
            <LinearProgress variant="determinate" value={(filesUploaded.length / total) * 100} />
          </LoadingOverlay>
        )}
        <DialogTitle className={classes.dialogTitleSection}>
          <div className={classes.dialogTitleContainer}>
            {translate(props.dialogSelected === 'upload_file' ? 'Upload File' : 'Upload Site Photos')}
            <IconButton onClick={dismiss} disabled={isLoading}>
              {
                <Tooltip title={translate('Close Dialog')} enterDelay={300}>
                  <CloseIcon />
                </Tooltip>
              }
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent ref={formRef}>
          <div className={`${dialogStyles.extraPaddingY}`}>
            <Form
              onSubmit={handleSubmit}
              FormState
              render={({ handleSubmit, values }) => {
                formRef.current = { handleSubmit, values }
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={`${dialogStyles.fieldsStyling}`}>
                      <Field
                        name="files"
                        render={() => (
                          <div className={dialogStyles.fileInput}>
                            <FileInput
                              source="file_contents"
                              accept={fileTypesAccepted}
                              placeholder={
                                <div className={dialogStyles.fileInputPlaceholderContainer}>
                                  <div className={dialogStyles.fileInputPlaceholderIcon}>{placeholderIcon}</div>
                                  <p>
                                    {translate('Drag & drop or')}
                                    <span className={dialogStyles.spanText}> {translate('browse files')}</span>
                                  </p>
                                </div>
                              }
                              label=""
                              multiple
                            >
                              <FunctionField
                                render={(record) => {
                                  const size = record.rawFile.size / 1000000
                                  return (
                                    <div className={dialogStyles.fileFieldContainer}>
                                      <Description className={dialogStyles.fileFieldIcon} />
                                      <p>{record.rawFile.name}</p>
                                      <span>{size.toFixed(2)}MB</span>
                                      <FileField {...record} />
                                    </div>
                                  )
                                }}
                              />
                            </FileInput>
                          </div>
                        )}
                      />
                      <div>
                        <FormLabel>{translate('Tags')}</FormLabel>
                      </div>
                      <Field
                        name="tags"
                        render={() => (
                          <div className={dialogStyles.chipsInput}>
                            <ReferenceArrayInput
                              source="file_tags"
                              reference="file_tags"
                              optionValueField="url"
                              filter={{ type: 'private' }}
                              perPage={50}
                              hideLabel
                              resource="file_tags"
                              filterToQuery={(searchText) => ({ search: searchText, type: 'private' })}
                              id={projectId}
                              onBlur={undefined}
                              onChange={undefined}
                              onFocus={undefined}
                              validate={undefined}
                              parse={undefined}
                              format={undefined}
                              defaultValue={defaultTags}
                              children={
                                <ChipsInput
                                  source="file_tags"
                                  optionText="title"
                                  optionValue="url"
                                  variant="outlined"
                                  label=""
                                  translateChoice={false}
                                  options={{ style: {} }}
                                />
                              }
                            />
                          </div>
                        )}
                      />
                    </div>
                  </form>
                )
              }}
            />
          </div>
        </DialogContent>

        <DialogActions className={dialogStyles.footerWrapper}>
          <Button
            startIcon={<ErrorOutlineOutlined />}
            onClick={dismiss}
            disabled={isLoading}
            variant="contained"
            color="default"
          >
            <span>{translate('Cancel')}</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveOutlined />}
            onClick={handleExternalSubmit}
            disabled={isLoading}
          >
            <span>{translate('Save')}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default withWidth()(MultipleFilesDialog)
