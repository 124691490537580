import { updateSelectedHardwareSupplier } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { useNotify } from 'ra-core'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getSupplierConfig } from './configs'
import { HardwareSupplierEnum } from './type'

const ShopDistributorRedirectPage = ({ distributor }: { distributor: string | undefined }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const notify = useNotify()
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)

  const selectedDistributor = useMemo(() => {
    switch (distributor) {
      case 'segen':
        return HardwareSupplierEnum.Segen
      case 'outlet':
        return HardwareSupplierEnum.SolarOutlet
      case 'city_plumbing':
        return HardwareSupplierEnum.CityPlumbing
      default:
        return undefined
    }
  }, [distributor])

  useEffect(() => {
    if (selectedDistributor && enabledDistributors.includes(selectedDistributor)) {
      dispatch(updateSelectedHardwareSupplier(selectedDistributor))
    } else {
      notify(`${getSupplierConfig(selectedDistributor)?.name} is not enabled for your org.`, 'error')
    }
    history.push('/shop')
  }, [selectedDistributor])
  return null
}

export default ShopDistributorRedirectPage
