import { Paper } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import type { OpenSolarThemeType } from 'Themes'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { DatagridLoading } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { InventoryFiltersType } from '../../filter/type'
import useForecastInventoryTransfers from '../hooks/useForecastInventoryTransfers'
import ForecastDetailRow from './ForecastDetailRow'
import ForecastDetailSummaryRow from './ForecastDetailSummaryRow'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    borderBottom: '1px solid #e7e7e7',
    minHeight: '48px',
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  overflowItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
  },
}))

const InventoryForecastDetail = ({
  code,
  projectId,
  filters,
  forecastEndDate,
  handleTouched,
}: {
  code: string
  projectId?: number
  filters?: InventoryFiltersType
  forecastEndDate?: string
  handleTouched?(): void
}) => {
  const classes = useStyles()
  const {
    loadingForecastInventoryTransfers,
    loadForecastInventoryTransfers,
    forecastInventoryTransfers,
  } = useForecastInventoryTransfers()

  const transferSummary = useMemo(() => {
    return forecastInventoryTransfers.find((transfer) => transfer.type === 'summary')
  }, [forecastInventoryTransfers])

  React.useEffect(() => {
    // Move fetching logic to controller if it getting more complicated
    loadForecastInventoryTransfers({ filters, code, projectId, forecastEndDate })
  }, [code, projectId, forecastEndDate])

  if (loadingForecastInventoryTransfers) {
    return <DatagridLoading classes={classes} hasBulkActions={true} nbChildren={5} size="small" />
  }

  return (
    <Paper elevation={1}>
      <Table size="small">
        <Grid container className={classes.tableHeaderContainer} alignItems="center">
          <Grid container item xs={12} className={classes.header} spacing={1}>
            <Grid item xs={1}>
              {/* icon placeholder */}
            </Grid>
            <Grid item xs={4} className={classes.overflowItem} style={{ paddingLeft: 15 }}>
              {'Activity'}
            </Grid>
            <Grid item xs={3}>
              {'Date'}
            </Grid>
            <Grid item xs={2}>
              {'Delta'}
            </Grid>
            <Grid item xs={2}>
              {'On hand'}
            </Grid>
          </Grid>
        </Grid>
        <TableBody>
          {transferSummary !== undefined && (
            <ForecastDetailSummaryRow
              code={code}
              transfer={transferSummary}
              reloadForecastTransfers={() =>
                loadForecastInventoryTransfers({ filters, code, projectId, forecastEndDate })
              }
              handleTouched={handleTouched}
            />
          )}
          {_.dropWhile(forecastInventoryTransfers, (transfer) => transfer === transferSummary).map((record, i) => {
            return (
              <ForecastDetailRow
                reloadForecastTransfers={() =>
                  loadForecastInventoryTransfers({ filters, code, projectId, forecastEndDate })
                }
                key={record.type + i}
                transfer={record}
                handleTouched={handleTouched}
              />
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default InventoryForecastDetail
