import { FormApi } from 'final-form'
import { debounce, pick } from 'lodash'
import { IntegrationJsonType, StudioSystemType } from 'types/global'
import { MountingID, NativeMountingID } from 'types/mounting'
import { saveInputs } from './Inputs'
import getCompatibility from './compatibility'
import { possibleInputVariables } from './constants'
import { CallbackType, getStructuralOptionsMCS } from './mcs'
import { getMcsRoofFacetData } from './mcsRoofFacets'
import getNativeMountingSystems from './mountingSystems'
import { MountingCalcInput, MountingCalcResult, PanelBlockPrecursor, RoofTypeName, StructuralOptions } from './types'
import { addItemsToSystem, handleFastenerResult, saveMountingWeight, setPanelSpacing } from './utils'

function initMountingData(system: StudioSystemType, mountingName: MountingID) {
  if (!system.integration_json) {
    system.integration_json = {} as IntegrationJsonType
  }
  if (!system.integration_json[mountingName]) system.integration_json[mountingName] = {}
  if (system?.integration_json?.[mountingName]?.enabled === undefined) {
    if (mountingName && system.integration_json[mountingName] !== undefined) {
      system.integration_json[mountingName] = { ...system.integration_json[mountingName], enabled: true }
    }
    window.editor.signals.objectChanged.dispatch(system, 'mounting')
  }
}

function getStructuralOptions(
  target,
  system: StudioSystemType,
  mountingName: MountingID,
  callback: CallbackType
): void {
  if (system.integration_json && system.integration_json?.[mountingName]?.roofHookCalculationMethod === 'mcs') {
    return getStructuralOptionsMCS(system, target, callback)
  } else {
    const structuralOptions: StructuralOptions = {}
    callback(structuralOptions)
  }
}

function getRoofInputs(system: StudioSystemType): MountingCalcInput[] {
  const panel = system.moduleType()
  const projectData = window.projectForm.getState().values

  //added function to get rooftype to avoid assigning undefined to var roofType
  const getRoofType = (): RoofTypeName | null => {
    const roofTypeUrl = projectData.roof_type
    if (roofTypeUrl?.length) {
      let roofId = Number(roofTypeUrl.split('roof_types/')[1].split('/')[0])
      const roof = window.AccountHelper.getRoofTypeById(roofId)?.name
      return roof
    } else return null
  }
  const roofTypeName = getRoofType()
  if (!projectData.custom_data) projectData.custom_data = {}
  const roofFacets = getMcsRoofFacetData(system, projectData.custom_data)
  // Create roofInputs
  const roofInputs: MountingCalcInput[] = []
  if (roofFacets && roofFacets.length) {
    roofFacets.forEach((roofFacet, roofIndex) => {
      let panelBlocks: PanelBlockPrecursor[] = []
      if (roofFacet.moduleGridUuids) {
        roofFacet.moduleGridUuids.forEach((moduleGridUuid) => {
          const moduleGrid = window.editor.objectByUuid(moduleGridUuid)
          if (moduleGrid)
            panelBlocks.push({
              moduleGrid,
              top: 0,
              left: 0,
              panel: panel,
            })
        })
      }
      roofInputs.push({
        panelBlocks,
        roof: {
          roofTypeName,
          roofIndex,
        },
        options: {},
      })
    })
  }
  return roofInputs
}

function calculate(form: FormApi, system: StudioSystemType): void {
  const nativeMountingSystems = getNativeMountingSystems()
  const mountingName = system?.mounting
  if (!mountingName) return
  const roofInputs = getRoofInputs(system)

  roofInputs.every((roofInput) => {
    const target = new nativeMountingSystems[mountingName as NativeMountingID].class(roofInput)

    const compatibility = getCompatibility(system, target.getCompatibilityParameters())
    if (!compatibility) return false

    getStructuralOptions(target, system, mountingName, async (structuralOptions) => {
      target.input.options.structuralOptions = structuralOptions

      const getInputOptions = () => {
        const integrationJson = window.editor.selectedSystem.integration_json
        if (integrationJson && integrationJson[mountingName]) {
          const options = pick(integrationJson[mountingName], possibleInputVariables)
          return options
        } else return {}
      }

      const inputOptions = getInputOptions()

      target.input.options = { ...target.input.options, ...inputOptions }

      const result: MountingCalcResult = await target.calculate()

      setPanelSpacing(system, result)
      addItemsToSystem(system, mountingName, result)

      handleFastenerResult(system, result)
      saveMountingWeight(system, result)
    })

    return true
  })
}

function sync(form: FormApi, system: StudioSystemType): void {
  const nativeMountingSystems = getNativeMountingSystems()
  const mountingName = system?.mounting
  const nativeMountingSelected =
    typeof mountingName === 'string' && Object.keys(nativeMountingSystems).includes(mountingName)
  const autoCalcEnabled =
    mountingName && nativeMountingSelected && system?.integration_json && system.integration_json[mountingName]?.enabled

  if (mountingName) {
    if (nativeMountingSelected) {
      initMountingData(system, mountingName)
    } else {
      clear(system)
    }
  }
  if (autoCalcEnabled) {
    clear(system)
    calculate(form, system)
  } else {
    // Force System panel to refresh and exit
    //@ts-ignore
    OsOther.refreshVisibility(system.others())
  }
  return
}

export function clear(system: StudioSystemType): void {
  const nativeMountingSystems = getNativeMountingSystems()
  // Remove all mounting components from system
  const manufacturersToClear = Object.keys(nativeMountingSystems).map(
    (nativeMountingSystem) => nativeMountingSystems[nativeMountingSystem].manufacturer
  )
  manufacturersToClear.push('Genius Roof Solutions')
  manufacturersToClear.forEach((manufacturer) => {
    system
      .others()
      .filter((c) => c.manufacturer_name.toLowerCase() === manufacturer.toLowerCase())
      .forEach((c) => {
        if (c.getSystem && c.getSystem()?.uuid === system.uuid) {
          window.editor.removeObject(c)
        }
      })
  })
  // Remove any warnings
  Object.keys(nativeMountingSystems).forEach((mountingSystem) => {
    window.WorkspaceHelper.removeProjectErrorFromReduxStore(
      `${mountingSystem.toUpperCase()}_PLUGIN_WARNING`,
      system.uuid,
      'plugin'
    )
    window.WorkspaceHelper.removeProjectErrorFromReduxStore(
      `${mountingSystem.toUpperCase()}_INPUT_WARNING`,
      system.uuid,
      'plugin'
    )
    window.WorkspaceHelper.removeProjectErrorFromReduxStore(
      `${mountingSystem.toUpperCase()}_STRUCTURAL_INFO`,
      system.uuid,
      'plugin'
    )
  })
}

// function refreshAllSystems(): void {
//   window.editor.getSystems().forEach((system) => {
//     sync(system)
//   })
// }

export function refreshSystemFromObject(form: FormApi, object: any, attributeName?: string) {
  // Only refresh racking due to chages to a) OsModule b) OsModuleGrid
  if (['OsModuleGrid', 'OsModule'].includes(object.type)) {
    // proceed with sync
  } else if (object.type === 'OsSystem' && attributeName === 'mounting') {
    // proceed with sync
  } else {
    return
  }

  if (object.getSystem) {
    var system = object.getSystem()
    if (!system) {
      // Deleted objects do not have getSystem()
      // but we can use object.parentBeforeRemoval.getSystem instead
      if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
        system = object.parentBeforeRemoval.getSystem()
      }
    }
    if (system) {
      sync(form, system)
    }
  }
}

export const refreshSystemFromObjectDebounced = debounce(
  function (form, object, attributeName = null) {
    refreshSystemFromObject(form, object, attributeName)
  },
  350,
  { leading: false, trailing: true }
)

export function syncMounting(form, system) {
  if (system.mounting) {
    saveInputs(form, system)
    refreshSystemFromObject(form, system, 'mounting')
  }
}
