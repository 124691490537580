import { ProjectType } from 'types/projects'
import { SystemDataType } from 'types/systems'
import { parseIntegrationJson } from 'util/misc'
import DocusignCTA from './DocusignCTA'
import GreenLancerCTA from './GreenLancerCTA'
import IronRidgeCTA from './IronRidgeCTA'
import PVFScaffoldCTA from './PVFScaffoldCTA'

//logic for including/excluding project CTAs
export const getProjectCTAs = (project: ProjectType, featureFlagsEnabled: string[]) => {
  let currList: { component: any; props?: object }[] = []
  //Greenlancer
  if (project.project_sold !== null && project.country_iso2 === 'US') currList.push({ component: GreenLancerCTA })
  //Docusign
  if (isDocusignFound(project)) currList.push({ component: DocusignCTA })
  //BOM
  const ironRidgeResult = showIronRidge(project)
  if (ironRidgeResult && project.country_iso2 === 'US')
    currList.push({ component: IronRidgeCTA, props: ironRidgeResult })
  //PVF
  if (featureFlagsEnabled.includes('pvf_integration')) currList.push({ component: PVFScaffoldCTA, props: { project } })

  return currList
}

const isDocusignFound = (project: ProjectType): boolean => {
  return (
    !!project?.docusign_contract_envelope_id ||
    !!project?.available_customer_actions?.some((action) =>
      action.actions_available.some((x) => x.payment_method === 'docusign')
    )
  )
}
//returns an object for component props if true
const showIronRidge = (project: ProjectType): { hasIronRidge: boolean } | false => {
  let isBOMEligible = false
  let hasIronRidge = false
  if (project.country_iso2 !== 'US') return false
  project.systems?.forEach((sys: SystemDataType) => {
    if (sys?.modules && sys?.modules.length > 0) {
      isBOMEligible = true
    }
    if (sys.url === project.system_sold && sys.integration_json) {
      try {
        if (parseIntegrationJson(sys.integration_json)?.ironridge) {
          hasIronRidge = true
        }
      } catch (ex) {}
    }
    try {
      if (parseIntegrationJson(sys.integration_json)?.ironridge) hasIronRidge = true
    } catch (ex) {}
  })
  if (!hasIronRidge && !isBOMEligible) return false
  return { hasIronRidge }
}
