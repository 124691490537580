export enum FinanceCtaType {
  SUBMIT_CREDIT_APPLICATION_IFRAME = 'submit_credit_application_iframe',
  FETCH_IFRAME_URL = 'fetch_iframe_url',
  FORM_REDIRECT = 'form_redirect',
  PROPOSAL_TOOLBAR_DIALOG = 'proposal_toolbar_dialog',
  SUBMIT_CREDIT_APPLICATION_HOSTED = 'submit_credit_application_hosted',
  ALERT_INFO = 'ALERT_INFO',
  ALERT_WARNING = 'ALERT_WARNING',
  ALERT_ERROR = 'ALERT_ERROR',
  VIEW_LENDER_DOCS = 'view_lender_docs',
}
export const CTAS_THAT_REQUIRE_REFRESH = [
  FinanceCtaType.FETCH_IFRAME_URL,
  FinanceCtaType.SUBMIT_CREDIT_APPLICATION_IFRAME,
  FinanceCtaType.FETCH_IFRAME_URL,
  FinanceCtaType.SUBMIT_CREDIT_APPLICATION_HOSTED,
]
// only show the supported options
export const FINANCE_CTA_TYPE_CHOICES = [
  { id: FinanceCtaType.SUBMIT_CREDIT_APPLICATION_IFRAME, label: 'Submit Credit Application (iframe)' },
  { id: FinanceCtaType.FETCH_IFRAME_URL, label: 'Fetch Iframe URL' },
  // { id: 'fetch_iframe_content', label: 'Fetch Iframe Contents' },
  { id: FinanceCtaType.FORM_REDIRECT, label: 'Form Redirect' },
  { id: FinanceCtaType.PROPOSAL_TOOLBAR_DIALOG, label: 'Proposal Toolbar Dialog' },
  { id: FinanceCtaType.SUBMIT_CREDIT_APPLICATION_HOSTED, label: 'Submit Credit Application (hosted form)' },
  { id: FinanceCtaType.ALERT_INFO, label: 'Alert - Info' },
  { id: FinanceCtaType.ALERT_WARNING, label: 'Alert - Warning' },
  { id: FinanceCtaType.ALERT_ERROR, label: 'Alert - Error' },
  // { id: 'send_credit_application', label: 'Email Credit Application' },
  { id: FinanceCtaType.VIEW_LENDER_DOCS, label: 'View Lender Docs From Opensolar' },
  // { id: 'send_lender_docs', label: 'Email Loan Docs' },
]
