import { Backdrop, Drawer } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import { FilterValuesType } from 'elements/hardwareFilter/type'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { SimpleForm } from 'ra-ui-materialui'
import React from 'react'
import CustomToolbar from './CustomToolbar'
import { parseFilterValues } from './fields'
import FilterSection from './FilterSection'
import SortSection from './SortSection'

export const SectionHeader = styled('h2')(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: 12,
  fontWeight: 500,
  marginBottom: 20,
  textTransform: 'uppercase',
}))

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 600,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}))

const Header = styled('h1')({
  fontSize: 18,
  marginBottom: 30,
})

const FormStyled = styled(SimpleForm)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const ContentWrapper = styled('div')({
  padding: 10,
  marginRight: 0,
})

interface PropTypes {
  open: boolean
  onClose: () => void
  filterValues: FilterValuesType
  setFilters: Function
}

const FilterDrawer: React.FC<PropTypes> = ({ open, onClose, filterValues, setFilters }) => {
  const translate = useTranslate()
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 1100 }} onClick={onClose}></Backdrop>
      <StyledDrawer variant="persistent" anchor="right" open={open}>
        <FormStyled
          toolbar={<CustomToolbar onClose={onClose} />}
          initialValues={{
            ordering: filterValues.ordering,
            filters: parseFilterValues(filterValues),
          }}
          save={(values) => {
            const filterValues = values.filters.reduce((result, filter) => {
              if (filter.value) {
                return { ...result, ...filter.value }
              }
              return result
            }, {})
            setFilters({ ordering: values.ordering, ...filterValues })
            onClose()
          }}
        >
          <ContentWrapper>
            <Header>{translate('Sort & Filter')}</Header>
            <CustomField source="ordering" name="ordering" component={SortSection} />
            <SectionHeader>{translate('Filter')}</SectionHeader>
            <CustomField source="filters" name="filters" component={FilterSection} />
          </ContentWrapper>
        </FormStyled>
      </StyledDrawer>
    </>
  )
}

export default FilterDrawer
