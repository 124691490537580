import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useNotify, useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import GreenLancerDialog from 'resources/projects/GreenLancer'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { getRoleIdFromState, parseIntegrationJson } from 'util/misc'
import IntegrationRow from '../IntegrationRow'
const restClientInstance = restClient(window.API_ROOT + '/api')
interface PropTypes {
  project: ProjectType
}

const GreenLancerCTA: React.FC<PropTypes> = ({ project }) => {
  const notify = useNotify()
  const translate = useTranslate()
  const [showDialog, setShowDialog] = useState(false)
  const roleId: number | undefined = useSelector(getRoleIdFromState)
  const greenLancerId = useMemo(() => project.greenlancer_project_id, [project.greenlancer_project_id])
  const [forceShowConnected, setForceShowConnected] = useState<boolean>(false)
  const [fetchingProjectURL, setFetchingProjectURL] = useState<boolean>(false)
  const [forceShowExistingProject, setForceShowExistingProject] = useState<boolean>(false)

  const hasIronRidge = useMemo(() => {
    project.systems?.forEach((sys) => {
      if (sys.url === project.system_sold && sys.integration_json) {
        try {
          if (parseIntegrationJson(sys.integration_json)?.ironridge) {
            return true
          }
        } catch (ex) {}
      }
    })
    return false
  }, [project.systems])

  const getProjectURL = () => {
    logAmplitudeEvent('greenlancer_view_project_clicked', {
      page: window.location.hash?.includes('/manage') ? 'Manage Page' : 'Info Page',
    })

    if (fetchingProjectURL) return
    setFetchingProjectURL(true)
    let url = `orgs/${project?.org_id}/roles/${roleId}/projects/${project?.id}/get_greenlancer_project_url/`
    restClientInstance('CUSTOM_GET', 'accept_terms', {
      url,
    })
      .then((res: any) => {
        if (res && res.data && res.data.url) window.open(res.data.url, '_blank')
        else
          notify(
            "Sorry, we are unable to bring you to this project on GreenLancer's site. Please be sure your account is connected",
            'warning'
          )
      })
      .catch((err: any) => {
        console.log('err', err)
        notify(
          "Sorry, we are unable to bring you to this project on GreenLancer's site. Please be sure your account is connected",
          'warning'
        )
      })
      .finally(() => setFetchingProjectURL(false))
  }

  return (
    <>
      {greenLancerId || forceShowExistingProject ? (
        <IntegrationRow
          title={'Greenlancer'}
          disabled={fetchingProjectURL}
          description={translate("Click the button below to view your project on GreenLancer's site")}
          logo={<img src={`${window.PUBLIC_URL}/images/GreenLancer-logo.png`} alt="" height={23} width={23} />}
          btnLabel={translate('View Project in GreenLancer')}
          onClick={getProjectURL}
        />
      ) : (
        <IntegrationRow
          title={'Greenlancer'}
          description={translate(
            "Don't have time to draw up the plan set? We can help! Our partners at GreenLancer can deliver a full, PE stamped set within as little as 3 days"
          )}
          logo={<img src={`${window.PUBLIC_URL}/images/GreenLancer-logo.png`} alt="" height={23} width={23} />}
          btnLabel={translate('Send Project to Greenlancer')}
          onClick={() => {
            setShowDialog(true)
          }}
        />
      )}
      {showDialog && (
        <GreenLancerDialog
          //@ts-ignore
          dismiss={() => setShowDialog(false)}
          isOpen={showDialog}
          project={project}
          onAccountConnected={() => {
            setForceShowConnected(true)
          }}
          forceShowConnected={forceShowConnected}
          onProjectCreated={() => {
            setForceShowExistingProject(true)
          }}
          isIronRidge={hasIronRidge}
        />
      )}
    </>
  )
}

export default GreenLancerCTA
