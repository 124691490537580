import React from 'react'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import OrderByProjectBanner from './OrderByProjectBanner'
import SegenConnectBanner from './SegenConnectBanner'

const SegenBanner = ({ renderOBPBanner }: { renderOBPBanner: boolean }) => {
  const authenticationStatus = useSegenAuthenticationStatus()
  if (authenticationStatus === 'authenticated' && renderOBPBanner === true) {
    return <OrderByProjectBanner />
  } else if (authenticationStatus === 'unauthenticated') {
    return <SegenConnectBanner />
  }
  return <></>
}
export default SegenBanner
