// @ts-nocheck
import { MenuItem } from '@material-ui/core'
import { CostsBreakdown } from 'Designer/PanelSystem/Pricing'
import { permissionsSelectors } from 'ducks/permissions'
import CustomSelectField from 'elements/field/CustomSelectField'
import React, { FC, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { currencySymbolForCountry } from 'util/misc'
import { getOrgCountryCodeFromState } from 'util/org'

type PropTypes = {
  paymentOptions?: PaymentOptionDataType[]
  onPmtChange: (pmtId: number) => void
}

const DisclaimerForPaymentOption = ({ translate, paymentType }) => {
  return <span>^ {translate(getDisclaimerForPaymentOption(paymentType))}</span>
}

const getDisclaimerForPaymentOption = (paymentType) => {
  if (paymentType === 'ppa') {
    return 'Pricing schemes, Adders or Price Adjustments do not impact PPA payment options.'
  } else if (paymentType === 'regular_payment') {
    return 'Pricing schemes, Adders or Price Adjustments do not impact Regular Payments payment options.'
  } else {
    return 'Base price and dealer fee breakdown not visible to customer.'
  }
}

const PriceBreakdownContent: FC<PropTypes> = (props) => {
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<number>(props.paymentOptions[0]?.id)

  useEffect(() => {
    if (selectedPaymentOptionId) props.onPmtChange(selectedPaymentOptionId)
  }, [selectedPaymentOptionId])

  const translate = useTranslate()
  const currencySymbol = useSelector((state: any) => {
    const countryIso2 = getOrgCountryCodeFromState(state)
    return currencySymbolForCountry(countryIso2)
  })
  const { allowView, allowCreate, allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('design_cost_breakdown')
  )

  const selectedPaymentOption = props.paymentOptions.find(
    (paymentOption: PaymentOptionDataType) => paymentOption.id === selectedPaymentOptionId
  )

  const breakdownSections = selectedPaymentOption?.pricing?.breakdown

  const hasAdjustedMargin =
    breakdownSections?.find((section: any) => section.label?.includes('Margin'))?.items?.length > 0

  const includeVariableCommission =
    breakdownSections &&
    breakdownSections[2]?.find((section: any) => section?.label?.includes('Variable commission')) !== undefined

  if (!allowView) {
    return null
  }
  return (
    <div>
      <p style={{ fontSize: 14, fontWeight: 'bold' }}>{translate('Price Breakdown')}</p>
      {/* <CustomSelectField
        style={{ flex: '1 1', width: 'auto' }}
        label={<span>{translate('Price breakdown for')}</span>}
        MenuProps={{ style: { zIndex: 2500 } }}
        id={'price-breakdown-select-input'}
        value={selectedPaymentOptionId}
        onChange={(event: React.ChangeEvent<{ value: number }>) => {
          const value = event.target.value as number
          setSelectedPaymentOptionId(value)
        }}
      >
        {paymentOptions.map((paymentOption: PaymentOptionDataType) => (
          <MenuItem key={paymentOption.name} value={paymentOption.id}>
            {paymentOption.name}
          </MenuItem>
        ))}
      </CustomSelectField> */}
      {breakdownSections && breakdownSections.length > 0 ? (
        <>
          {breakdownSections.map((sectionData, index) => {
            return (
              <div key={'top' + index}>
                <CostsBreakdown sectionData={sectionData} currencySymbol={currencySymbol} />
                {index === 0 && (
                  <CustomSelectField
                    style={{ flex: '1 1', width: 'auto' }}
                    label={<span>{translate('Price breakdown for')}</span>}
                    MenuProps={{ style: { zIndex: 2500 } }}
                    id={'price-breakdown-select-input'}
                    value={selectedPaymentOptionId}
                    onChange={(event: React.ChangeEvent<{ value: number }>) => {
                      const value = event.target.value as number
                      setSelectedPaymentOptionId(value)
                    }}
                  >
                    {props.paymentOptions.map((paymentOption: PaymentOptionDataType) => (
                      <MenuItem key={paymentOption.name} value={paymentOption.id}>
                        {paymentOption.title}
                      </MenuItem>
                    ))}
                  </CustomSelectField>
                )}
              </div>
            )
          })}
          {includeVariableCommission && (
            <div style={{ padding: '0 5px', fontSize: 11, margin: '-15px 0 10px' }}>
              <span>
                *<span> {translate('calculated including variable commission.')} </span>
              </span>
            </div>
          )}
          <div style={{ padding: '0 5px', fontSize: 12 }}>
            {hasAdjustedMargin && (
              <span>
                *
                <span>
                  {' '}
                  {translate(
                    'Pricing calculations do not include payment specific variations due to dealer fee or discounts.'
                  )}{' '}
                </span>
              </span>
            )}
            <DisclaimerForPaymentOption translate={translate} paymentType={selectedPaymentOption.payment_type} />
          </div>
        </>
      ) : (
        <span>{translate('Not available.')}</span>
      )}
    </div>
  )
}

export default PriceBreakdownContent
