import { useCallback, useState } from 'react'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { StudioSystemType } from 'types/global'

export const useSelectedSystem = () => {
  const [system, setSystem] = useState<StudioSystemType | undefined>(window.editor.selectedSystem)

  const checkSystem = useCallback(() => {
    setSystem(window.editor.selectedSystem)
  }, [])

  useStudioSignalsLazy(checkSystem, ['systemSelected'])

  return system
}
