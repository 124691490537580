import { usePromos } from 'persistentContent/inAppPromo/hooks/usePromos'
import { useEffect, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import { generateUUID } from 'util/misc'
import { RecommendBatterySetType, RecommendedBatterySetsRequestParams } from '../utility/types'

type PropsType = {
  params: RecommendedBatterySetsRequestParams
  projectId: number
  systemUuid: string
}

const useFetchRecommendedBatterySets = ({
  params,
  projectId,
  systemUuid,
}: PropsType): {
  isLoading: boolean
  recommendedBatterySets: RecommendBatterySetType[]
} => {
  const [isLoading, setIsLoading] = useState(true)
  const [recommendedBatterySets, setRecommendedBatterySets] = useState([])

  const { promos, state: promosLoadingState } = usePromos('sponsored_content')
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const sponsoredBatteryCodes = promos
    .map(({ sponsoredContent }) => sponsoredContent!.component_codes)
    .flat()
    .filter(Boolean)

  if (sponsoredBatteryCodes.length) {
    params['sponsored_battery_codes'] = sponsoredBatteryCodes
  }

  // TODO: this should be replaced by composeUrlWithQueryParams once this PR get merged https://github.com/open-solar/sunsuite/pull/8096
  const queryParams = Object.entries(params).reduce((query, [key, value]) => {
    if (Array.isArray(value)) {
      return query + `${key}=${encodeURIComponent(JSON.stringify(value))}&`
    }
    if (value === undefined || value === null) {
      return query
    }
    return query + `${key}=${value}&`
  }, '')

  useEffect(() => {
    if (promosLoadingState !== 'not-loaded') {
      dataProvider
        .CUSTOM_GET(
          'recommend_battery_sets',
          {
            url: `projects/${projectId}/systems/${systemUuid}/recommend_battery_sets/?${queryParams}`,
          },
          {
            onSuccess: ({ data }) => {
              setIsLoading(false)
              setRecommendedBatterySets(
                data.map((batterySet) => ({
                  ...batterySet,
                  batteries: batterySet.batteries.map((battery) => ({ ...battery, type: 'battery' })),
                  id: generateUUID(),
                }))
              )
            },
          }
        )
        .catch((error) => {
          setIsLoading(false)
          notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
        })
    }
  }, [promosLoadingState])

  return { isLoading, recommendedBatterySets }
}

export default useFetchRecommendedBatterySets
