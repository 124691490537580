import { Divider } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import Alert from 'elements/Alert'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { CartDiscountNotification } from 'pages/ordering/order/CartDiscounts'
import React, { memo, useEffect, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BomComponentDetail from './BomComponentDetail'
import BomDrawerFooter from './BomDrawerFooter'
import BomDrawerHeader from './BomDrawerHeader'
import useHardwareDetailsDynamic from './useHardwareDetailsDynamic'
import useMapHardwareDetailsToBomLineItems from './useMapHardwareDetailsToBomLineItems'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    flexShrink: 1,
    padding: 10,
  },
  footer: {},
  divider: {
    height: 10,
    background: 'linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.12))',
  },
}))

const BomDrawerContent = () => {
  const classes = useStyles()
  const hardwareDetails = useHardwareDetailsDynamic()
  const bomLineItemsHashMap = useMapHardwareDetailsToBomLineItems(hardwareDetails)

  const [availableLineItems, unavailableLineItems] = useMemo(() => {
    const availableLineItems: LineItemType[] = []
    const unavailableLineItems: LineItemType[] = []
    bomLineItemsHashMap['all'].forEach((lineItem: LineItemType) => {
      const stockLevelStatus = ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)
      if (stockLevelStatus !== 'not_available') {
        availableLineItems.push(lineItem)
      } else {
        unavailableLineItems.push(lineItem)
      }
    })
    return [availableLineItems, unavailableLineItems]
  }, [bomLineItemsHashMap['all']])

  useEffect(() => {
    logAmplitudeEvent('generic_element_viewed', {
      source: 'design_page',
      context: 'bom_slide_content',
    })
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {/* editor.selectedSystem not react state here 
        but useHardwareDetailsDynamic will trigger re-render once selected system changed */}
        <BomDrawerHeader systemUuid={window.editor.selectedSystem?.uuid} />
        <CartDiscountNotification lineItems={availableLineItems} context={'project'} />
        {!!unavailableLineItems.length && (
          <Alert severity="warning">
            <div>
              {unavailableLineItems.length === 1 ? '1 item' : `${unavailableLineItems.length} items`} not available.
              Find a replacement.
            </div>
          </Alert>
        )}
        <BomComponentDetail title={'Panels'} lineItems={bomLineItemsHashMap.module} />
        <BomComponentDetail title={'Mounting'} lineItems={bomLineItemsHashMap.mounting} />
        <BomComponentDetail title={'Inverters'} lineItems={bomLineItemsHashMap.inverter} />
        <BomComponentDetail title={'Batteries'} lineItems={bomLineItemsHashMap.battery} />
        <BomComponentDetail title={'Others'} lineItems={bomLineItemsHashMap.other} />
      </div>
      <div className={classes.footer}>
        <Divider className={classes.divider} />
        <BomDrawerFooter lineItems={availableLineItems} />
      </div>
    </div>
  )
}

export default memo(BomDrawerContent)
