import { useUxVersion } from 'hooks/useUxVersion'
import { NotFound } from 'react-admin'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Design } from './design/Design'
import Energy from './energy/Energy'
import { Explore } from './explore/Explore'
import Info from './info/InfoPageRoot'
import Manage from './manage'
import Manage3 from './manage3'
import { ProjectFiles } from './manage3/details/elements/filesAndTransactions/files/page/FilesPage'
import PaymentsPage from './payments'
import { OnlineProposal } from './proposal/MyEnergy'
import ProposalPreview from './proposal/preview'
import Sld from './sld/Sld'
const Sections = ({ submitting }) => {
  // We are not using this in react but requesting the feature flag will populate window.featureFlags which is used
  // by ReplayHelper so it is important that we call it here even though it is not used.
  // const saveReplayIsEnabled = useFeatureFlag('save_replay', 'on')
  const ui3Enabled = useUxVersion() === 3
  return (
    <div>
      <Switch>
        <Route exact path="/projects/new/explore" component={Explore} />
        <Route exact path={'/projects/:id/design'} component={Design} />
        <Route exact path={'/projects/:id/payments'} component={PaymentsPage} />
        <Route exact path={'/projects/:id/manage'} component={ui3Enabled ? Manage3 : Manage} />
        <Route
          exact
          path={'/projects/:id/info'}
          render={(routeProps) => {
            const projectId = routeProps?.match?.params?.id || ''
            if (ui3Enabled && projectId.match(/^\d+$/)) {
              return <Redirect to={`/projects/${projectId}/manage`} />
            } else {
              return <Info {...routeProps} />
            }
          }}
        />
        <Route
          exact
          path={'/projects/:id/proposal'}
          render={(routeProps) => <OnlineProposal submitting={submitting} {...routeProps} />}
        />
        <Route exact path={'/projects/:id/private_files'} component={ProjectFiles} />
        <Route exact path={'/projects/:id/preview'} component={ProposalPreview} />
        <Route exact path={'/projects/:id/energy'} component={Energy} />
        <Route exact path={'/projects/:id/sld'} component={Sld} />
        <Route
          exact
          path={'/projects/:id'}
          render={(routeProps) => {
            const projectId = routeProps?.match?.params?.id || ''
            if (projectId.match(/^\d+$/)) {
              // handle ux1.0 project page
              return <Redirect to={`/projects/${projectId}/info`} />
            } else {
              return <NotFound {...routeProps} />
            }
          }}
        />
        <Route path="/" component={NotFound} />
      </Switch>
    </div>
  )
}

export default Sections
