import { Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { orgsActions } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    textAlign: 'center',
    lineHeight: 1.5,
    padding: '1rem',
  },
  icon: {
    fontSize: '2rem',
    color: theme.greyMid1,
  },
  header: {
    fontWeight: 500,
    fontSize: '1.5rem',
  },
}))

const useDialogStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDialog-root': {
      zIndex: '1000 !important',
    },
  },
}))

const Denied: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()

  // reload the org on mount so we can get the new decision in redux
  useEffect(() => {
    dispatch(orgsActions.reloadOrg())
  }, [])

  return (
    <Dialog open={true} maxWidth="md" classes={dialogClasses}>
      <DialogContent>
        <div className={classes.container}>
          <InfoOutlined className={classes.icon} />
          <h1 className={classes.header}>{translate('CashFlow Registration Unsuccessful')}</h1>

          <p>
            {translate(
              "Your registration for OpenSolar's CashFlow product was not approved at this time. If you need more information or if anything has changed in the business and you'd like to reapply, reach out to payments@opensolar.com."
            )}
          </p>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default Denied
