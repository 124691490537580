"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OSSDKBootstrap = void 0;
var action_1 = require("./core/action");
var peers_1 = require("./core/peers");
var signal_1 = require("./core/signal");
var state_1 = require("./core/state");
var auth_1 = require("./state/auth");
var current_org_1 = require("./state/current_org");
var flows_1 = require("./state/flows");
var project_form_1 = require("./state/project_form");
var projects_1 = require("./state/resources/projects");
var route_1 = require("./state/route");
var studio_1 = require("./state/studio");
var genid_1 = require("./util/genid");
var logger_1 = require("./util/logger");
var view_1 = require("./view/view");
var scope_const = {
    current_org: current_org_1.current_org,
    project_form: project_form_1.project_form,
    route: route_1.route,
    studio: studio_1.studio,
    auth: auth_1.auth,
    flows: flows_1.flows,
    resources: {
        projects: projects_1.projects,
    },
};
var OBJECT_TYPES_TO_PROPAGATE = [
    'OsSystem',
    'OsInverter',
    'OsBattery',
    'OsOther',
    'OsModuleGrid',
    'OsMppt',
    'OsString',
    'OsTree',
    'OsObstruction',
    'OsFacet',
    'OsClipper',
];
var filterSignalForPrimaryObjectTypes = function (path, args) {
    var _a;
    // This assumes the object is always the first argument. This holds for object* signals
    // but not if we extend this into other types of signals.
    var objectType = (_a = args[0]) === null || _a === void 0 ? void 0 : _a.type;
    if (!OBJECT_TYPES_TO_PROPAGATE.includes(objectType)) {
        // console.info('skip signal ' + path + ' for object type ' + objectType)
        return false;
    }
    else {
        return true;
    }
};
var SIGNAL_PROPAGATION_FILTERS_BY_OBJECT_TYPE = {
    'scope.studio.objectSelected': filterSignalForPrimaryObjectTypes,
    'scope.studio.objectAdded': filterSignalForPrimaryObjectTypes,
    'scope.studio.objectChanged': filterSignalForPrimaryObjectTypes,
    'scope.studio.objectRemoved': filterSignalForPrimaryObjectTypes,
    'scope.studio.sceneLoaded': undefined,
};
var checkIfSignalShouldBePropagated = function (path, args) {
    // Avoid spamming unnecessary signals which require significant processing.
    var filterFunc = SIGNAL_PROPAGATION_FILTERS_BY_OBJECT_TYPE[path];
    if (filterFunc && filterFunc(path, args) === false) {
        return false;
    }
    else {
        return true;
    }
};
var OSSDKBootstrap = /** @class */ (function () {
    function OSSDKBootstrap() {
        this.logger = new logger_1.Logger('OSSDK', { color: '#FFCC26' });
        this.ready = new state_1.Value(false);
        this.stateMap = {};
        this.actionMap = {};
        this.signalMap = {};
        this.configResolved = new signal_1.Signal();
        this.peers = new peers_1.Peers();
        this.views = [];
        this.scope = __assign({}, scope_const);
        this.pendingActions = [];
        // scopeMixin allows us to remove the distinction between the scope and the SDK for SDK users
        this.scopeMixin = Object.assign(this, this.scope);
    }
    Object.defineProperty(OSSDKBootstrap.prototype, "isMaster", {
        get: function () {
            return this.peers.id === 'master';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OSSDKBootstrap.prototype, "isReady", {
        get: function () {
            return this.ready;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OSSDKBootstrap.prototype, "onConfigResolved", {
        get: function () {
            return this.configResolved;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OSSDKBootstrap.prototype, "resolvedConfig", {
        get: function () {
            return this.config;
        },
        enumerable: false,
        configurable: true
    });
    OSSDKBootstrap.prototype.setConfig = function (origConfig, config) {
        if (this.config) {
            throw new Error('SDK already initialized');
        }
        this.origConfig = origConfig;
        this.config = config;
        if (config.loglevel)
            window.os_loglevel = config.loglevel;
        this.weave(this.scope, 'scope');
        this.peers.onStateChange = this.onStateChange.bind(this);
        this.peers.onSignalDispatched = this.onSignalDispatched.bind(this);
        this.peers.onAction = this.onAction.bind(this);
        this.peers.join(this.config.master ? 'master' : 'slave_' + (0, genid_1.genid)());
        if (window.parent !== window) {
            this.peers.addPeer(window.parent, true, true);
        }
        this.configResolved.dispatch();
        if (!this.config.explicit_ready)
            this.markReady();
    };
    OSSDKBootstrap.prototype.markReady = function () {
        var _a;
        if (this.ready.value)
            return;
        if (!this.config)
            throw new Error('SDK not initialized');
        this.ready.value = true;
        if (this.pendingActions.length) {
            // Run any pending actions
            for (var _i = 0, _b = this.pendingActions; _i < _b.length; _i++) {
                var pending_1 = _b[_i];
                (_a = this.actionMap)[pending_1.path].apply(_a, pending_1.args).then(pending_1.resolve)
                    .catch(pending_1.reject);
            }
            this.pendingActions = [];
        }
        this.peers.markReady();
        if (this.config.onReady)
            this.config.onReady(this.scopeMixin);
    };
    OSSDKBootstrap.prototype.createView = function (_a) {
        var _b = _a === void 0 ? {} : _a, parent = _b.parent;
        if (!this.config) {
            throw new Error('SDK not ready yet');
        }
        var ret = new view_1.OsView(this.scopeMixin, this.config, this.origConfig);
        this.views.push(ret);
        if (parent)
            ret.spawn(parent);
        return ret;
    };
    OSSDKBootstrap.prototype.removeView = function (view) {
        var idx = this.views.indexOf(view);
        if (idx >= 0) {
            this.views.splice(idx, 1);
            view.destroy();
        }
        else {
            this.logger.error('View not found');
        }
    };
    OSSDKBootstrap.prototype.getLoginOverrides = function () {
        if (this.login_configs_resolved)
            return this.login_configs_resolved;
        var config = this.config;
        if (!config)
            return undefined;
        var overrides = config.login_configs_override;
        if (!overrides)
            return undefined;
        this.login_configs_resolved = [];
        for (var _i = 0, overrides_1 = overrides; _i < overrides_1.length; _i++) {
            var override = overrides_1[_i];
            var resolved = __assign({}, override);
            for (var key in override) {
                var value = override[key];
                if (typeof value === 'string') {
                    value = value.replace(/\{hostname_spa\}/g, config.hostname_spa);
                    value = value.replace(/\{hostname_api\}/g, config.hostname_api);
                    resolved[key] = value;
                }
            }
            this.login_configs_resolved.push(resolved);
        }
        return this.login_configs_resolved;
    };
    /*
    weave recursively traverses the scope and:
    - Stores all state objects in a map for easy lookup
    - Stores all actions in a map for easy lookup
    - Registers action handlers
    */
    OSSDKBootstrap.prototype.weave = function (scope, path) {
        var _this = this;
        var _loop_1 = function () {
            value = scope[i];
            var valuePath = "".concat(path, ".").concat(i);
            if (value instanceof state_1.State) {
                // Is state, send updates to peers
                this_1.stateMap[valuePath] = value;
                value.path = valuePath;
                value.isAllowed = function (state, value, old) {
                    return _this.checkStateChangeAllowed(valuePath, state, value, old);
                };
                value.onOwn = function (state, priority) {
                    // Below the check in value.add(...) for `this.peers.isOwner(valuePath)` relies on setting
                    // peers.ownershipMap[valuePath] so be sure that this.peers.setOwnership sets it.
                    _this.peers.setOwnership(valuePath, priority);
                    if (state.value !== undefined) {
                        _this.peers.sendStateChange(valuePath, state.value, undefined);
                    }
                    // Clear pendingMessages for certain states that relate to an old master.
                    // We should consider in detail which states should be cleared, of perhaps all of them?
                    // For now we will only clear states that we know are problematic
                };
                value.add(function (val, old) {
                    // This is tricky, previously it prevented all variables using useBindSelector from propagating to
                    // other peers from the master until this.peers.setOwnership() was fixed to also set
                    // peers.ownershipMap[valuePath] but can all these ownership fields be simplified?
                    if (!_this.peers.isOwner(valuePath)) {
                        // This is important in scenarios where the state gets updated before `own()` gets called.
                        // The value will get sent once `own()` is called.
                        // Do not send state change because we are not the owner
                        return;
                    }
                    _this.settingStateNow !== valuePath && _this.peers.sendStateChange(valuePath, val, old);
                });
            }
            else if (value instanceof action_1.Action) {
                // Is action, send unhandled actions to owner peer
                // Register ownership with peers
                this_1.actionMap[valuePath] = value;
                value.onOwn = function (action, priority) { return _this.peers.setOwnership(valuePath, priority); };
                value.unownedHandler = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return _this.sendUnownedAction(valuePath, args);
                };
            }
            else if (value instanceof signal_1.Signal) {
                // Is signal, forward on to peers
                this_1.signalMap[valuePath] = value;
                value.add(function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    if (valuePath === _this.signalDispatchingNow)
                        return;
                    _this.peers.sendSignal(valuePath, args);
                });
            }
            else if (typeof value !== 'function') {
                // Is scope, weave recursively
                this_1.weave(value, valuePath);
            }
            else {
                // Is function, ignore
            }
        };
        var this_1 = this, value;
        for (var i in scope) {
            _loop_1();
        }
    };
    OSSDKBootstrap.prototype.sendUnownedAction = function (path, args) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.ready.value) {
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            // Still initing, store action for later
                            _this.pendingActions.push({ path: path, args: args, resolve: resolve, reject: reject });
                        })];
                }
                else {
                    return [2 /*return*/, this.peers.sendUnownedAction(path, args)];
                }
                return [2 /*return*/];
            });
        });
    };
    OSSDKBootstrap.prototype.checkStateChangeAllowed = function (valuePath, state, value, old) {
        if (!this.peers.isOwnerOrNotOwned(valuePath)) {
            var msg = "State change not allowed: ".concat(valuePath);
            this.logger.error(msg);
            //TODO: throw error in some cases
            return false;
        }
        return true;
    };
    OSSDKBootstrap.prototype.onStateChange = function (path, value, old, isOwnerChange) {
        if (this.settingStateNow === path)
            return;
        var state = this.stateMap[path];
        if (state) {
            if (!state.matchValue(old)) {
                if (isOwnerChange === true) {
                    this.logger.warn("Failed to match old state but isOwnerChange===true. Accept state change and clear isOwnerChange flag: ".concat(path));
                }
                else if (old === undefined) {
                    this.logger.warn("Failed to match old state but old value is undefined, accept state change. Owner has probably changed: ".concat(path));
                }
                else {
                    this.logger.error("Failed to match old state, ignoring update: ".concat(path));
                    return;
                }
            }
            this.settingStateNow = path;
            state.innerSet(value);
            this.settingStateNow = undefined;
        }
        else {
            this.logger.error("Received state change for unknown state: ".concat(path));
        }
    };
    OSSDKBootstrap.prototype.onSignalDispatched = function (path, args) {
        if (this.signalDispatchingNow === path)
            return;
        var signal = this.signalMap[path];
        if (signal) {
            if (checkIfSignalShouldBePropagated(path, args) === false) {
                return;
            }
            this.signalDispatchingNow = path;
            signal.dispatch.apply(signal, args);
            this.signalDispatchingNow = undefined;
        }
        else {
            this.logger.error("Received signal dispatch for unknown signal: ".concat(path));
        }
    };
    OSSDKBootstrap.prototype.onAction = function (path, args) {
        var action = this.actionMap[path];
        if (action && action.handler) {
            return action.handler.apply(null, args);
        }
        else {
            this.logger.error("Received action for unknown action: ".concat(path));
            return Promise.reject();
        }
    };
    return OSSDKBootstrap;
}());
exports.OSSDKBootstrap = OSSDKBootstrap;
