import { TableCell, TableHead, TableRow, Tooltip, makeStyles } from '@material-ui/core'
import { ArrowDownward, ArrowUpward, Info } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useHistory, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/query'

type PropTypes = {
  filter: string
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  row: {
    background: '#E7E7E7',
  },

  cell: {
    padding: '15px 10px',
    textAlign: 'left',
  },

  cellFlex: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
  },

  icon: {
    fontSize: 13,
  },

  sortIcon: {
    fontSize: 15,
  },

  actionsHeader: {
    textAlign: 'center',
  },
}))

const InvoiceTableHeader: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()

  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [sortColumn, setSortColumn] = useState<
    '' | 'issued_date' | 'due_date' | 'date_paid_out' | 'amount_requested' | 'amount_paid_out'
  >('')

  const location = useLocation()
  const params = parseQueryStringToDictionary(location.search)

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'ASC' ? 'DESC' : 'ASC'
    setSortDirection(direction)
    setSortColumn(column)

    history.push(
      `/cashflow/invoices?filter=${
        params.filter === undefined ? '{}' : params.filter
      }&order=${direction}&page=1&perPage=${params.perPage === undefined ? '{}' : params.perPage}&sort=${column}`
    )
  }

  const renderSortIcon = (column) => {
    if (sortColumn !== column) return null
    return sortDirection === 'ASC' ? (
      <ArrowUpward className={classes.sortIcon} />
    ) : (
      <ArrowDownward className={classes.sortIcon} />
    )
  }

  return (
    <TableHead className={classes.row}>
      <TableRow>
        <TableCell className={classes.cell}>{translate('Invoice No')}</TableCell>

        <TableCell className={classes.cell}>{translate('Project')}</TableCell>

        <TableCell className={classes.cell}>
          <div>{translate('Milestone')}</div>
        </TableCell>

        <TableCell className={classes.cell}>
          <div>{translate('Status')}</div>
        </TableCell>

        <TableCell className={classes.cell} onClick={() => handleSort('issued_date')}>
          <div className={classes.cellFlex}>
            {translate('Issue Date')} {renderSortIcon('issued_date')}
          </div>
        </TableCell>

        <TableCell className={classes.cell} onClick={() => handleSort('due_date')}>
          <div className={classes.cellFlex}>
            {translate('Due Date')} {renderSortIcon('due_date')}
          </div>
        </TableCell>

        <TableCell className={classes.cell} onClick={() => handleSort('date_paid_out')}>
          <div className={classes.cellFlex}>
            {translate('Paid Date')} {renderSortIcon('date_paid_out')}
          </div>
        </TableCell>

        <TableCell className={classes.cell} onClick={() => handleSort('amount_requested')}>
          <div className={classes.cellFlex}>
            <span>{translate('Amount Invoiced')}</span>
            <Tooltip title={translate('Shows total amount presented to the customer on the invoice before any fees')}>
              <Info className={classes.icon} />
            </Tooltip>
            {renderSortIcon('amount_requested')}
          </div>
        </TableCell>

        <TableCell className={classes.cell} onClick={() => handleSort('amount_paid_out')}>
          <div className={classes.cellFlex}>
            <span>{translate('Amount Received')}</span>
            <Tooltip
              title={translate(
                'Shows actual amount paid out to your bank account. Where fees apply, this amount may differ from the invoice amount.'
              )}
            >
              <Info className={classes.icon} />
            </Tooltip>
            {renderSortIcon('amount_paid_out')}
          </div>
        </TableCell>

        <TableCell className={classes.cell + ' ' + classes.actionsHeader}>{translate('Actions')}</TableCell>
      </TableRow>
    </TableHead>
  )
}
export default InvoiceTableHeader
