import { initSdk_v1, OSSDK, SdkConfig } from 'opensolar-sdk'
import { useEffect, useMemo, useState } from 'react'

/**
 * This allows the host application to access and configure the SDK.
 * It should only be used in one location in the host application.
 * Other locations should instead use useSdkReady.
 */

export const useSdkInit = (opts?: SdkConfig): { sdk: OSSDK; configResolved: boolean } => {
  const sdk = useMemo<OSSDK>(() => initSdk_v1(opts), [])

  const [configResolved, setConfigResolved] = useState<boolean>(!!sdk.resolvedConfig)

  useEffect(() => {
    let callback = () => setConfigResolved(!!sdk.resolvedConfig)
    sdk.onConfigResolved.add(callback)
    return () => sdk.isReady.remove(callback)
  }, [sdk])

  return { sdk, configResolved }
}
