import { Grid, Link, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo, useContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import CityPlumbingConnectUserButton from 'services/cityPlumbing/CityPlumbingConnectUserButton'
import { OidcContext } from 'services/cityPlumbing/OidcWrapper'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HardwareStandardBannerContainer from '../HardwareStandardBannerContainer'
import OrderByProjectBanner from './OrderByProjectBanner'

const CITYPLUMBING_SUPPORT_LINK = 'https://pages.opensolar.com/partners/city-plumbing'
const restClientInstance = restClient(window.API_ROOT + '/api')

const useCityPlumbingBannerStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 20,
  },
  partner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  badge: {
    width: 110,
    background: theme.themeColorLight,
    padding: '3px 10px',
    borderRadius: '12px',
  },
  img: {
    maxWidth: 100,
  },
  link: { color: theme.blue, textDecoration: 'underline', cursor: 'pointer' },
}))

interface BannerProps {
  renderOBPBanner?: boolean
}

const CityPlumbingConnectBanner = () => {
  const classes = useCityPlumbingBannerStyles()

  const handleClickRegisterLink = () => {
    window.open('https://share.hsforms.com/1ArJCBRFsSReMQzJtbzqw7gcne2p', '_blank')
    logAmplitudeEvent('hardware_banner_register_click', { supplier: 'city_plumbing' })
  }

  return (
    <Grid container className={classes.container} wrap={'wrap'} spacing={3}>
      <Grid item xs={12} sm={2} container alignItems="center" justify="center" direction="column">
        <div style={{ maxWidth: 120 }}>
          <img
            className={classes.img}
            alt="supplier-logo"
            src={getSupplierConfig(HardwareSupplierEnum.CityPlumbing)?.logoUrl || ''}
          />
        </div>
        <div className={classes.badge}>
          <Typography align="center" variant="caption">
            OpenSolar Partner
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={7}>
        <div>
          <Typography variant="h6" align="center" gutterBottom>
            Connect your hardware supplier City Plumbing
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            An award-winning supplier with over 360 branches across the UK, we have been serving our loyal customers for
            over 40 years. We offer next-day delivery and click & collect options to suit you – because nobody likes to
            be kept waiting. For the best quality supplies from reputable manufacturers.
          </Typography>
          <Link href={CITYPLUMBING_SUPPORT_LINK} target="_blank">
            <Typography align="center" variant="body1" className={classes.link}>
              Learn more
            </Typography>
          </Link>
        </div>
      </Grid>
      <Grid item xs={12} sm={3} container alignItems="center" justify="center" direction="column">
        <CityPlumbingConnectUserButton
          layout={null}
          allowOrdering={false}
          variant="contained"
          trackSource="hardware_banner"
          color="primary"
        />
        <Typography variant="caption" align="center">
          OR
        </Typography>
        <Button onClick={handleClickRegisterLink} variant="outlined" color="primary">
          Register to View Pricing
        </Button>
      </Grid>{' '}
    </Grid>
  )
}

const CityPlumbingBanner: React.FC<BannerProps> = ({ renderOBPBanner = true }) => {
  const { isAuthenticated: isOidcAuthenticated } = useAuth()
  const [isAuthenticated, setIsAuthenticated] = useState(isOidcAuthenticated)
  const orgId = useSelector(authSelectors.getOrgId)

  useEffect(() => {
    if (!isAuthenticated && orgId) {
      // If not authenticated then check if we have a refresh token on the backend.
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + '/distributors/city_plumbing/authentication_status/',
      })
        .then((response) => {
          if (response?.data?.result?.is_authenticated) {
            setIsAuthenticated(true)
          }
        })
        .catch((err: any) => {
          console.log(err, err)
        })
    }
  }, [isAuthenticated, orgId])

  if (isAuthenticated && !renderOBPBanner) return null
  else if (!isAuthenticated) {
    return (
      <HardwareStandardBannerContainer>
        <CityPlumbingConnectBanner />
      </HardwareStandardBannerContainer>
    )
  } else return <OrderByProjectBanner />
}

const Wrapper: React.FC<BannerProps> = ({ renderOBPBanner = true }) => {
  const authContext = useContext(OidcContext)

  if (!authContext?.isWrapped) return null
  return <CityPlumbingBanner renderOBPBanner={renderOBPBanner} />
}

export default memo(Wrapper)
