import React from 'react'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getLocalisedAndFormattedDate } from 'util/misc'

const useStyles = makeOpenSolarStyles((them) => ({
  dateWrapper: {
    position: 'absolute',
    top: 5,
    right: 0,
    textAlign: 'right',
    width: 120,
  },
}))

const CheckoutContentsHeader: React.FC = () => {
  const translate = useTranslate()
  const classes = useStyles()

  const capitalizedFormattedDate = getLocalisedAndFormattedDate()

  return (
    <div style={{ position: 'relative' }}>
      <h2 data-testid="terms-and-conditions-label">{translate('Terms & Conditions')}</h2>
      <div className={classes.dateWrapper}>
        <span className="small">{capitalizedFormattedDate}</span>
      </div>
    </div>
  )
}
export default CheckoutContentsHeader
