import { DialogActions } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import {
  generateDocument,
  generateDocumentSelectors,
  getDocumentPlaceholders,
  setChangeOrderPlaceholders,
  setOpenDialog,
} from 'ducks/generateDocument'
import Button from 'elements/button/Button'
import { usePlaceholders } from 'elements/wysiwyg/usePlaceholders'
import { clearUnsavedData } from 'projectSections/utils/unsavedDataStore'
import { useContext, useEffect, useMemo, useState } from 'react'
import { FormContext, useNotify, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { formatPlaceholderValues, sendDocusignEnvelope } from './utils'

import { getDialogStyles } from 'projectSections/sections/manage3/details/elements/filesAndTransactions/files/common/dialogAndFieldsStyles'

const restClientInstance = restClient(window.API_ROOT + '/api')
const GenerateDocumentButtons = (props) => {
  const {
    orgId,
    projectId,
    doc,
    docId,
    setDocId,
    projectForm,
    hasForms,
    projectFormDirtyFields,
    saveCustomFormRef,
    customSubFormRef,
  } = props

  const translate = useTranslate()
  const dispatch = useDispatch()
  const formState = useFormState()
  const subForm = customSubFormRef.current
  const formValues = formState?.values
  const notify = useNotify()
  const [subFormInvalid, setSubFormInvalid] = useState(false)
  const [subFormDirty, setSubFormDirty] = useState(false)

  useEffect(
    () =>
      subForm?.subscribe(
        (state) => {
          setSubFormDirty(state.dirty)
          setSubFormInvalid(state.invalid)
        },
        { invalid: true, dirty: true }
      ),
    [subForm]
  )

  const documentType = formValues?.document_type
  const documentTemplateId = formValues?.document_template_id
  const isDocusign = useSelector((state) => state.generateDocument.dialogOptions.isDocusign)
  const selectedSystem = formValues?.selected_system
  const selectedPaymentOptionId = formValues?.selected_payment_option_id
  const disableGeneration = useSelector((state) => state.generateDocument.dialogOptions.disableGeneration)
  const documentForm = useSelector((state) => state.generateDocument.dialogOptions.documentForm)
  const openDialog = useSelector((state) => state.generateDocument.dialogOptions.openDialog)
  const countersignerFields = useSelector(generateDocumentSelectors.getCountersignerFields)
  const generatedDoc = useSelector(generateDocumentSelectors.getDocById)(docId)
  const [requiresForm, setRequiresForm] = useState(false)
  const projectClean = !projectFormDirtyFields?.length && (!subForm || !subFormDirty)
  const projectValid = projectForm?.getState().valid && (!subForm || !subFormInvalid)
  const { setOnSave } = useContext(FormContext)
  const projectValues = projectForm?.getState().values
  const [isLoading, setIsLoading] = useState(false)
  const showChangeOrderInput = useMemo(() => documentType === 'change_order' && openDialog === 'initial', [
    documentType,
    openDialog,
  ])
  const changeOrderPlaceholders = useSelector((state) => state.generateDocument.dialogOptions.changeOrderPlaceholders)
  const placeholderGetter = usePlaceholders()
  const changeOrderPlaceholderDefs = useMemo(() => placeholderGetter('change_order'), [])

  useEffect(() => {
    setRequiresForm((documentForm || hasForms) && openDialog !== 'input')
  }, [documentForm, openDialog, hasForms])

  useEffect(() => {
    if (isDocusign && generatedDoc?.downloadUrl) {
      const setErrorMsg = (err) => notify(err, 'warning')
      const systems = window.editor.getSystems()
      sendDocusignEnvelope(
        projectId,
        orgId,
        documentType === 'generic_document'
          ? selectedSystem
          : systems.find((x) => formValues?.revisedSystem?.uuid === x.uuid),
        documentType === 'generic_document' ? selectedPaymentOptionId : formValues?.revisedSystem?.payment_option_id,
        setErrorMsg,
        documentTemplateId,
        generatedDoc,
        () => null,
        !!countersignerFields?.email,
        countersignerFields
      )
    }
  }, [isDocusign, generatedDoc?.downloadUrl])

  const gotoForm = () => {
    dispatch(setOpenDialog('input'))
  }

  // taken from: spa\app\src\projectSections\elements\header\actions\SaveButton.tsx
  const onSave = (values, redirect, form, handleSubmit) => {
    return handleSubmit(values, undefined, form)
      .then((response) => {
        //customized actions onsuccess
        clearUnsavedData()
      })
      .catch(() => {
        //customized actions onerror
      })
  }

  const handleSubmit = () => {
    if (saveCustomFormRef.current) {
      // Commit custom form changes to main project form
      saveCustomFormRef.current()
    }

    if (showChangeOrderInput) {
      setIsLoading(true)
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/document_templates/get_placeholder_data/`,
        data: {
          document_template_id: formValues?.document_template_id,
          revisedSystem: formValues?.revisedSystem,
          originalSystem: formValues?.originalSystem,
          project_id: projectValues?.id,
          language: appStorage.getLocale(),
        },
      })
        .then((res) => {
          dispatch(setChangeOrderPlaceholders(getDocumentPlaceholders(res.data, changeOrderPlaceholderDefs)))
          dispatch(setOpenDialog('CO-input'))
          setIsLoading(false)
        })
        .catch((err) => {
          notify('Failed to retrieve doc template placeholders: ' + err, 'warning')
        })
    } else if (!projectClean) {
      saveProject()
    } else {
      generateDocumentNow()
    }
  }
  const saveProject = () => {
    setOnSave(onSave)
    let ret = projectForm.submit()
    ret.then(() => generateDocumentNow())
  }
  const generateDocumentNow = () => {
    const otherData =
      documentType === 'change_order'
        ? {
            originalSystem: formValues?.originalSystem,
            revisedSystem: formValues?.revisedSystem,
            placeholderData: formatPlaceholderValues(changeOrderPlaceholders, formValues?.placeholderData),
          }
        : {}
    const doc = generateDocument(
      orgId,
      projectId,
      documentType,
      documentTemplateId,
      selectedSystem ? selectedSystem.uuid : null,
      selectedPaymentOptionId,
      otherData
    )
    setDocId(doc.payload.id)
    dispatch(doc)
  }

  const handleDownloadDocument = (downloadUrl) => {
    window.open(downloadUrl, '_blank')
  }

  const buttonLabel = useMemo(() => {
    if (showChangeOrderInput) {
      return 'Next'
    } else if (isDocusign) {
      return projectClean ? 'Send Docusign Envelope' : 'Save & Send Docusign Envelope'
    } else {
      return projectClean ? 'Generate' : 'Save & Generate'
    }
  }, [isDocusign, projectClean, documentType, showChangeOrderInput])

  const changeOrderValidation = useMemo(() => {
    // const availableSystems = projectValues?.systems?.filter((x) => x.show_customer)
    const availableSystems = projectValues?.systems
    return (
      documentType === 'change_order' &&
      (availableSystems?.length < 2 ||
        !formValues?.originalSystem ||
        !formValues?.revisedSystem ||
        !formValues?.revisedSystem?.uuid ||
        !formValues?.revisedSystem?.payment_option_id ||
        !formValues?.originalSystem?.uuid ||
        !formValues?.originalSystem?.payment_option_id)
    )
  }, [formValues, documentType, projectValues])

  const docuSignValidation = useMemo(() => {
    if (documentType === 'generic_document') return isDocusign && (!selectedSystem || !selectedPaymentOptionId)
    else return isDocusign && changeOrderValidation
  }, [documentType, isDocusign, changeOrderValidation, selectedSystem, selectedPaymentOptionId])

  const dialogStyles = getDialogStyles()

  return (
    <DialogActions className={`${dialogStyles.footerWrapper} generate-document-footer`}>
      {doc?.downloadUrl && !isDocusign && (
        <Button
          startIcon={<CloudDownloadIcon color="#FFFFFF" />}
          id="GenerateDocumentButtonDownload"
          variant="contained"
          onClick={() => handleDownloadDocument(doc?.downloadUrl)}
          label={translate('Download')}
        />
      )}
      {requiresForm ? (
        <Button id="GenerateDocumentButton" color="primary" variant="contained" onClick={gotoForm} label="Next" />
      ) : (
        orgId &&
        projectId && (
          <Button
            loading={isLoading}
            id="GenerateDocumentButton"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !documentType ||
              (docId && !doc) ||
              ['loading', 'success'].includes(doc?.status) ||
              docuSignValidation ||
              disableGeneration ||
              changeOrderValidation ||
              (!showChangeOrderInput && !projectClean && !projectValid)
            }
            label={buttonLabel}
          />
        )
      )}
    </DialogActions>
  )
}

export default GenerateDocumentButtons
