import { CheckCircleOutline } from '@material-ui/icons'
import classnames from 'classnames'
import Alert from 'elements/Alert'
import { useTranslate } from 'ra-core'
import { Button } from 'ra-ui-materialui'
import React, { useEffect, useMemo, useRef } from 'react'
import { ProjectWorkflowBrief } from 'types/projects'
import { WorkflowType } from 'types/workflows'
import { useStyles } from '../styles'

type PropTypes = {
  workflow: ProjectWorkflowBrief
  workflows: WorkflowType[]
  handleChange?: (workflow) => void
  allowEdit: boolean
  statusNotifs?: string[]
}

const StageStepper: React.FunctionComponent<PropTypes> = (props) => {
  const { workflow, handleChange, workflows, allowEdit, statusNotifs } = props
  const classes = useStyles()
  const translate = useTranslate()
  const stages = useMemo(
    () =>
      (workflows?.find((x) => x.id === workflow?.workflow_id) || workflows[0])?.workflow_stages
        .concat()
        .sort((a, b) => a.order - b.order),
    [workflow]
  )
  const itemEls = useRef([])

  const activeStageIndex: number | undefined = useMemo(() => {
    const stageItem = stages.find((x) => x.id === workflow?.active_stage_id)
    if (stageItem) return stages.indexOf(stageItem)
    return !!stages.length && typeof stages[0].id === 'number' ? stages[0].id : undefined
  }, [stages, workflow])
  const handleStageChange = (newStage) => {
    if (handleChange && allowEdit) handleChange({ ...workflow, active_stage_id: newStage })
  }
  const getCurrentStepClasses = (base: string, stepIndex: number) => {
    const firstLast = (stepIndex === 0 ? 'first' : '') + ' ' + (stepIndex === filteredList.length - 1 ? 'last' : '')
    const selectedIndex = activeStageIndex ? activeStageIndex : 0
    if (activeStageIndex && stages[selectedIndex]?.milestone > 3) {
      return classnames(base, 'inactive', firstLast)
    } else {
      if (selectedIndex === stepIndex) {
        return classnames(base, 'selected', firstLast)
      } else if (stepIndex > selectedIndex) {
        return classnames(base, 'inactive', firstLast)
      } else {
        return classnames(base, 'active', firstLast)
      }
    }
  }
  const filteredList = useMemo(
    () => stages.filter((s, i) => s.milestone <= 3 && (s.is_archived || s.forDelete ? activeStageIndex === i : true)),
    [stages, activeStageIndex]
  )

  useEffect(() => {
    if (activeStageIndex !== undefined) {
      const wrapperEl: HTMLDivElement = itemEls.current[activeStageIndex]
      if (wrapperEl) {
        wrapperEl.parentElement?.parentElement?.scroll({
          left: wrapperEl.offsetLeft + wrapperEl.offsetWidth / 2 - wrapperEl.parentElement.offsetWidth / 2,
          behavior: 'smooth',
        })
      } else return
    }
  }, [activeStageIndex !== undefined ? itemEls.current[activeStageIndex] : undefined])
  return (
    <>
      <div className={`${classes.wrapper} os-scroll`} id={'stage-wrapper'}>
        <div className={classes.normalRow}>
          {filteredList.map((s, i) => {
            return (
              <div
                key={i}
                className={getCurrentStepClasses(classes.stepButtonBase, i)}
                onClick={() => handleStageChange(s.id)}
                id={'stage-' + i}
                //@ts-ignore
                ref={(element) => (itemEls.current[i] = element)}
              >
                <div className={classes.stepButtonLabel}>
                  <CheckCircleOutline className={getCurrentStepClasses(classes.stepButtonIcon, i)} />
                  {s.title}{' '}
                  {s.is_archived && <span className={classes.archiveLabel}>{` (${translate('archived')})`}</span>}
                </div>

                <div className={getCurrentStepClasses(classes.itemGraphics, i)}>
                  {i === 0 && <div className={'circle'} />}
                  {i === filteredList.length - 1 && <div className={'circle last'} />}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className={classes.otherStages}>
        {translate('or move to: ')}
        {stages.map((s, i) => {
          if (s.is_archived || s.milestone <= 3) return null
          return (
            <Button
              label={s.title}
              hideLabelAt={null}
              className={activeStageIndex === i ? classes.selectedOther : undefined}
              variant="contained"
              color="default"
              onClick={() => handleStageChange(s.id)}
            />
          )
        })}
      </div>
      {statusNotifs && !!statusNotifs.length && (
        <>
          {statusNotifs.includes('sold_warning') && (
            <Alert severity={'warning'}>
              {translate('Your project is marked as sold but your project stage does not reflect a sold project.')}
            </Alert>
          )}
          {statusNotifs.includes('installed_warning') && (
            <Alert severity={'warning'}>
              {translate(
                'Your project is marked as installed but your project stage does not reflect an installed project.'
              )}
            </Alert>
          )}
          {statusNotifs.includes('sold_info') && statusNotifs.includes('installed_info') ? (
            <Alert severity={'info'}>
              {translate('Advancing to the next project stage will mark the project as sold and installed.')}
            </Alert>
          ) : (
            <>
              {statusNotifs.includes('sold_info') && (
                <Alert severity={'info'}>
                  {translate('Advancing to the next project stage will mark the project as sold.')}
                </Alert>
              )}
              {statusNotifs.includes('installed_info') && (
                <Alert severity={'info'}>
                  {translate('Advancing to the next project stage will mark the project as installed.')}
                </Alert>
              )}
            </>
          )}
          {statusNotifs.includes('unsold_info') && statusNotifs.includes('uninstalled_info') ? (
            <Alert severity={'info'}>
              {translate('Reverting to a previous project stage will mark the project as unsold and uninstalled.')}
            </Alert>
          ) : (
            <>
              {statusNotifs.includes('unsold_info') && (
                <Alert severity={'info'}>
                  {translate('Reverting to a previous project stage will mark the project as unsold.')}
                </Alert>
              )}
              {statusNotifs.includes('uninstalled_info') && (
                <Alert severity={'info'}>
                  {translate('Reverting to a previous project stage will mark the project as uninstalled.')}
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
export default StageStepper
