import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { useSystem } from 'projectSections/sections/payments/hooks'
import { useNotify } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { parseIntegrationJson, urlToId } from 'util/misc'
import { LightReachAvailableAdderMap, LightReachConfig, LightReachVendorMap } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetLightReachConfig = (systemUuid: string) => {
  const getLightReachConfig = () => {
    const system = window.editor.getSystems().find((sys) => sys.uuid === systemUuid)
    const integrationJson = parseIntegrationJson(system?.integration_json) || {}
    return integrationJson?.lightreach || {}
  }
  const [lightReachConfig, setLightReachConfig] = useState<LightReachConfig | undefined>(getLightReachConfig())

  useEffect(() => {
    setLightReachConfig(getLightReachConfig())
  }, [systemUuid])

  return lightReachConfig
}

export const useGetLightReachAdderConfig = (systemUuid) => {
  const lightReachConfig = useGetLightReachConfig(systemUuid)

  return lightReachConfig === undefined ? undefined : lightReachConfig?.adders || {}
}

export const useGetLightReachAdders = (projectId, systemUuid) => {
  const [adders, setAdders] = useState<LightReachAvailableAdderMap | undefined>(undefined)
  const [addersAreBeingFetched, setAddersAreBeingFetched] = useState<boolean>(false)

  const system = useSystem(systemUuid)
  const notify = useNotify()
  const refreshTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  const orgId = useMemo(() => {
    if (system) {
      return system.payment_options?.find((pmt) => pmt.integration === 'lightreach')?.org_id
    }
  }, [system])

  const accountId = useMemo(() => {
    if (system) {
      const integrationJson = parseIntegrationJson(system.integration_json) || {}
      return integrationJson?.lightreach?.account_id
    }
  }, [system?.payment_options])

  useEffect(() => {
    // available adders won't change once we fetch them, so only make this request once
    if (accountId && projectId && orgId && (!adders || !Object.keys(adders)?.length)) {
      setAddersAreBeingFetched(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/lightreach/adders/?lightreach_account_id=${accountId}`,
      })
        .then((res) => {
          setAdders(res.data.adders)
        })
        .catch((err) => notify(err?.body?.message || 'Failed to fetch LightReach adders', 'warning'))
        .finally(() => setAddersAreBeingFetched(false))
    }
  }, [projectId, accountId, orgId, refreshTrigger, adders])

  return { adders, addersAreBeingFetched }
}

export const useGetLightReachVendors = (systemUuid) => {
  const [vendors, setVendors] = useState<LightReachVendorMap | undefined>(undefined)
  const [vendorsAreBeingFetched, setVendorsAreBeingFetched] = useState<boolean>(false)
  const [orgId, setOrgId] = useState<number | undefined>(undefined)

  const system = useSystem(systemUuid)
  const notify = useNotify()
  const project = useFormState().values

  useEffect(() => {
    if (system) {
      const targetOrgId = system.payment_options?.find((pmt) => pmt.integration === 'lightreach')?.org_id
      setOrgId(targetOrgId)
    }
  }, [])

  useEffect(() => {
    if (orgId) {
      setVendorsAreBeingFetched(true)
      const moduleId = system?.moduleId
      const invertedId = system?.inverters()?.[0]?.inverter_id
      const batteryId = system?.batteries()?.[0]?.battery_id

      // matches the back-end logic that determines which role to use. If the salesperson has a full name we use them
      // otherwise fallback to the assigned_role
      const salespersonId =
        project?.assigned_salesperson?.first_name && project?.assigned_salesperson?.family_name
          ? urlToId(project?.assigned_salesperson)
          : urlToId(project?.assigned_role)
      const integrationJson = parseIntegrationJson(system?.integration_json)?.lightreach
      const selectedPanelManufacturer = integrationJson?.panelManufacturer
      const selectedPanelModel = integrationJson?.panelModel
      const selectedInverterManufacturer = integrationJson?.inverterManufacturer
      const selectedInverterModel = integrationJson?.inverterModel
      const selectedStorageManufacturer = integrationJson?.storageManufacturer
      const selectedStorageModel = integrationJson?.storageModel
      const selectedMountingManufacturer = integrationJson?.mountingManufacturer
      let url = `orgs/${orgId}/lightreach/equipment_confirmation/?module_id=${moduleId}&inverter_id=${invertedId}&battery_id=${batteryId}&salesperson_id=${salespersonId}`
      if (project?.state) {
        url += `&state=${project.state}`
      }
      if (selectedPanelManufacturer) {
        url += `&selected_panel_manufacturer=${selectedPanelManufacturer}`
      }
      if (selectedPanelModel) {
        url += `&selected_panel_model=${selectedPanelModel}`
      }
      if (selectedInverterManufacturer) {
        url += `&selected_inverter_manufacturer=${selectedInverterManufacturer}`
      }
      if (selectedInverterModel) {
        url += `&selected_inverter_model=${selectedInverterModel}`
      }
      if (selectedStorageManufacturer) {
        url += `&selected_inverter_manufacturer=${selectedStorageManufacturer}`
      }
      if (selectedStorageModel) {
        url += `&selected_storage_model=${selectedStorageModel}`
      }
      if (selectedMountingManufacturer) {
        url += `&selected_mounting_manufacturer=${selectedMountingManufacturer}`
      }

      restClientInstance('CUSTOM_GET', 'custom', {
        url,
      })
        .then((res) => {
          setVendors(res.data)
        })
        .catch((err) => notify(err?.body?.message || 'Failed to fetch LightReach vendors', 'warning'))
        .finally(() => setVendorsAreBeingFetched(false))
    }
  }, [orgId])

  return { vendors, vendorsAreBeingFetched }
}

export const useValidateLightReachEquipment = (
  systemUuid,
  vendors: LightReachVendorMap | undefined,
  licenseNumber?: string | null
) => {
  const system = window.editor.getSystems().find((sys) => sys.uuid === systemUuid)

  const [validation, setValidation] = useState<string[]>([])
  const [hasBatteryInDesign, setHasBatteryInDesign] = useState<boolean>(Boolean(system?.batteryTotalKwh()))

  const integrationJson = parseIntegrationJson(system?.integration_json)?.lightreach

  const isLicenseRequired = vendors?.salesperson_license_required
  const isModelRequired = vendors?.model_numbers_required
  // const isModelRequired = true

  const selectedPanelManufacturer = integrationJson?.panelManufacturer
  const selectedPanelModel = integrationJson?.panelModel
  const selectedInverterManufacturer = integrationJson?.inverterManufacturer
  const selectedInverterModel = integrationJson?.inverterModel
  const selectedStorageManufacturer = integrationJson?.storageManufacturer
  const selectedStorageModel = integrationJson?.storageModel
  const selectedMountingManufacturer = integrationJson?.mountingManufacturer

  useEffect(() => {
    const errors: string[] = []

    if (isLicenseRequired && !licenseNumber) {
      errors.push('licenseNumber')
    }

    if (!selectedPanelManufacturer) {
      errors.push('Modules_manufacturer')
    }
    if (isModelRequired && !selectedPanelModel) {
      errors.push('Modules_product_code')
    }

    if (!selectedInverterManufacturer) {
      errors.push('Inverters_manufacturer')
    }
    if (isModelRequired && !selectedInverterModel) {
      errors.push('Inverters_product_code')
    }

    if (!selectedMountingManufacturer) {
      errors.push('Mounting_manufacturer')
    }

    if (!selectedStorageManufacturer && hasBatteryInDesign) {
      errors.push('Batteries_manufacturer')
    }
    if (isModelRequired && !selectedStorageModel && hasBatteryInDesign) {
      errors.push('Batteries_product_code')
    }

    setValidation(errors)
  }, [
    isLicenseRequired,
    licenseNumber,
    isModelRequired,
    selectedPanelManufacturer,
    selectedPanelModel,
    selectedInverterManufacturer,
    selectedInverterModel,
    selectedStorageManufacturer,
    selectedStorageModel,
    selectedMountingManufacturer,
    hasBatteryInDesign,
  ])

  return validation
}

export const saveLicenseNumber = (licenseNum, project, orgId) => {
  const state = project.state
  const salespersonId =
    project?.assigned_salesperson?.first_name && project?.assigned_salesperson?.family_name
      ? urlToId(project?.assigned_salesperson)
      : urlToId(project?.assigned_role)

  var restClientInstance = restClient(window.API_ROOT + '/api')
  return new Promise<boolean>((resolve, reject) => {
    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: `orgs/${orgId}/lightreach/license_number/`,
      data: { role_id: salespersonId, license_number: licenseNum, state: state },
    })
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        reject(false)
      })
  })
}
