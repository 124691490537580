import { CircularProgress, Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Form, useFormState } from 'react-final-form'
import PaymentMethodSelection from './PaymentMethodSelection'
import {
  ACHPaymentDataType,
  BlueSnapCardDataType,
  PaymentExtraFields,
  PaymentRequestType,
  PaymentStaticCopy,
} from './types'
import { recodPaymentFormView } from './utils'

type PropTypes = {
  doSubmitPayment: (args: PaymentExtraFields) => void
  paymentRequestData: PaymentRequestType
  countryIso2: string
  projectId: string
  orgName: string
  savedPaymentMethodData: BlueSnapCardDataType | ACHPaymentDataType | undefined
  setSavedPaymentMethodData: (pmtData: BlueSnapCardDataType | ACHPaymentDataType | undefined) => void
  paymentStaticCopy: PaymentStaticCopy
  isSubmitting: boolean
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  paymentsContainer: {
    border: '1px solid #E7E7E7',
    borderRadius: '10px',
    padding: '0 !important',
    backgroundColor: '#fff',
  },

  header: {
    fontSize: '20px',
    fontWeight: 400,
    margin: 0,
    padding: '16px 24px',
  },

  expressPaymentsWrapper: {
    padding: '8px 24px',
    borderTop: '1px solid #E7E7E7',
    borderBottom: '1px solid #E7E7E7',
  },

  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
  },

  epBtn: {
    cursor: 'pointer',
  },

  expressLabel: {
    textAlign: 'center',
    marginTop: '1rem',
  },

  spanLine: {
    color: theme.greyMid1,
    textAlign: 'center',
    borderBottom: '1px solid',
    lineHeight: '0.1em',
    margin: '2rem auto',
  },
  spanLineContent: {
    background: theme.white,
    padding: '0 10px',
  },

  payBtn: {
    width: ({ isMobile }) => (isMobile ? '100%' : 'auto'),
    background: '#4272DD',
    color: theme.white,
    fontSize: 13,
    border: '1px solid #4272DD',
    margin: '1rem',
    '&:hover': {
      background: '#4272DD !important',
      color: theme.white,
      fontSize: 13,
      border: '1px solid #4272DD',
    },
  },
}))

const PaymentFormContent: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const formState = useFormState()
  const classes = useStyles({ isMobile })
  const translate = useTranslate()

  const saveSelectedPaymentMethod = useCallback(
    (cardData?: BlueSnapCardDataType | ACHPaymentDataType) => {
      props.setSavedPaymentMethodData(cardData)
    },
    [formState.values]
  )

  const submitSelectedPaymentMethod = useCallback(() => {
    const requestFields = formState.values as PaymentExtraFields
    requestFields.payment_method_data = props.savedPaymentMethodData
    requestFields.payment_method_type = formState.values.payment_method_type

    // don't let certain sensitive fields get sent to our API
    const sensitiveFields = ['ecpRoutingNumber', 'ecpAccountNumber', 'ecpAccountNumberConfirm', 'ecpAccountType']
    sensitiveFields?.forEach((fieldName) => {
      if (requestFields?.[fieldName]) delete requestFields[fieldName]
    })
    props.doSubmitPayment(requestFields)
  }, [formState.values, props.savedPaymentMethodData, props.doSubmitPayment])

  const disablePay = useMemo(() => {
    if (props.isSubmitting) return true
    if (!props.savedPaymentMethodData) return true
    if (formState.values?.is_editing) return true
    if (formState.values.payment_method_type !== formState.values.saved_payment_method_type) return true
    return false
  }, [
    props.savedPaymentMethodData,
    props.isSubmitting,
    formState.values.payment_method_type,
    formState.values.saved_payment_method_type,
    formState.values?.is_editing,
  ])

  return (
    <div className={classes.paymentsContainer}>
      <h2 className={classes.header}>{isMobile ? translate('Payment') : translate('How would you like to pay?')}</h2>

      <div className={classes.expressPaymentsWrapper}>
        <p>{translate('All transactions are secure and encrypted')}</p>
        {/* <p className={classes.expressLabel}>Express checkout</p>

          <div className={classes.flexCenter}>
            <img src={`${window.PUBLIC_URL}/images/cashflow/apple-pay-btn.svg`} className={classes.epBtn} />
            <img src={`${window.PUBLIC_URL}/images/cashflow/google-pay-btn.svg`} className={classes.epBtn} />
            <img src={`${window.PUBLIC_URL}/images/cashflow/paypal-btn.svg`} className={classes.epBtn} />
          </div>

          <p className={classes.spanLine}>
            <span className={classes.spanLineContent}>OR</span>
          </p> */}
      </div>

      <div>
        <PaymentMethodSelection
          paymentRequestData={props.paymentRequestData}
          doSubmitPayment={saveSelectedPaymentMethod}
          countryIso2={props.countryIso2}
          orgName={props.orgName}
          paymentStaticCopy={props.paymentStaticCopy}
          projectId={props.projectId}
        />
      </div>

      <div className={classes.flexCenter}>
        <Button
          variant="contained"
          className={classes.payBtn}
          disabled={disablePay}
          onClick={submitSelectedPaymentMethod}
        >
          {props.isSubmitting ? <CircularProgress /> : translate('Pay')}
        </Button>
      </div>
    </div>
  )
}

const PaymentForm: React.FC<PropTypes> = (props) => {
  const onSubmit = () => {}

  useEffect(() => {
    recodPaymentFormView(props.projectId, props.paymentRequestData.payment_request_id)
  }, [])
  return (
    <Form
      initialValues={{}}
      onSubmit={onSubmit}
      render={() => (
        <>
          <PaymentFormContent {...props} />
        </>
      )}
    />
  )
}
export default PaymentForm
