// @ts-nocheck
import { TableCell, TableRow } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Checkbox } from 'opensolar-ui'
import React, { ChangeEvent, useState } from 'react'
import { useForm } from 'react-final-form'
import { PremiumProductDetailType } from 'types/global'
import PremiumProductDescription from './PremiumProductDescription'
import PremiumProductTermsDialog from './PremiumProductTermsDialog'

type PropTypes = {
  product: PremiumProductDetailType
  i: number
  saveUpdate: () => void
}

const PremiumProductFormRow: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const [showTermsDialog, setShowTermsDialog] = useState<boolean>(false)
  const terms = props.product?.terms
  const provider = props.product.title.toLowerCase().split(' ')[0]

  const handleChange = (e: ChangeEvent) => {
    if (e.target.checked && !terms) {
      onConfirm()
    } else if (e.target.checked && terms) {
      setShowTermsDialog(true)
    } else {
      form.change(`${props.i}.is_active`, false)
      props.saveUpdate()
    }
  }

  const onConfirm = () => {
    form.change(`${props.i}.is_active`, true)
    props.saveUpdate()
    setShowTermsDialog(false)
    logAmplitudeEvent('premium_product_terms_agreed', { provider: provider, page: 'wallet_products' })
  }

  const onTermsDismiss = () => {
    form.change(`${props.i}.is_active`, false)
    setShowTermsDialog(false)
    logAmplitudeEvent('premium_product_terms_dismissed', { provider: provider, page: 'wallet_products' })
  }

  return (
    <TableRow>
      <TableCell style={{ width: '15%' }}>{props.product.title} </TableCell>
      <TableCell style={{ width: '60%' }}>
        <PremiumProductDescription text={props.product.description} />
      </TableCell>
      <TableCell style={{ width: '20%' }}>
        {/* Old version which formats the label on the front end
          `${currencySymbolForCurrencyCode(props.product.currency)}${props.product.unit_price_ex_tax} ${
          props.product.unit
        }`*/}
        {props.product.price_description}
      </TableCell>
      <TableCell style={{ width: '5%' }}>
        <Checkbox
          checked={form.getState().values[props.i]?.is_active}
          onChange={handleChange}
          inputProps={{ id: provider + '-checkbox' }}
        />
        {showTermsDialog && (
          <PremiumProductTermsDialog
            open={showTermsDialog}
            onClose={onTermsDismiss}
            onAccept={onConfirm}
            terms={terms}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default PremiumProductFormRow
