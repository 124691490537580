import { makeStyles, MenuItem, Select } from '@material-ui/core'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { useCallback, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'types/state'
import { useProjectNavItems } from './hooks'

const useStyles = makeStyles(
  {
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    chip: {
      marginRight: 5,
      height: '16px !important',
    },
    select: {
      marginRight: 'auto',
      height: 30,
      fontSize: 14,
      backgroundColor: '#f1f1f1',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ' + COLOR_PALETTE.grey2,
      },
      '& .MuiChip-root': {
        marginRight: 8,
      },
    },
  },
  { name: 'MobileNavSelector' }
)

const MobileNavSelector = () => {
  const form = useForm()
  const classes = useStyles()
  const history = useHistory()
  const translate = useTranslate()
  const links = useProjectNavItems()
  const project = useFormState().values
  const projectId = useMemo(() => project.id, [project.id])
  const section = useSelector((state: RootState) => state.project.section)

  const handleChange = useCallback(
    (e) => {
      const newSection = e.target.value
      form.mutators.recordFormDirtyFields()
      history.push(`/projects/${projectId}/${newSection}`)
    },
    [projectId]
  )
  if (!!!links?.length) return null
  return (
    <Select
      defaultValue={section || 'design'}
      variant="outlined"
      className={classes.select}
      onChange={handleChange}
      renderValue={(value: any) => {
        const item = links.find((x) => x.id === value)
        if (item) return <div className={classes.row}>{translate(item?.label)}</div>
      }}
    >
      {links.map((navLink, i) => (
        <MenuItem key={'navlink-' + i} value={navLink.id}>
          {translate(navLink.label)}
        </MenuItem>
      ))}
    </Select>
  )
}

export default MobileNavSelector
