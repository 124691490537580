import { orgSelectors } from 'ducks/orgs'
import { DISTRIBUTOR_FILTER_KEY_V2 } from 'elements/hardwareFilter/distributor/DistributorFilterConfigV2Factory'
import Pagination from 'elements/pagination/Pagination'
import { Box, Button } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import { useTranslate } from 'ra-core'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HardwareListRow from '../../elements/HardwareListRow'
import HardwareSelectorFilterSidebar from '../../elements/HardwareSelectorFilterSidebar'
import HardwareSelectorLayout from '../../elements/HardwareSelectorLayout'
import HardwareSelectorList from '../../elements/HardwareSelectorList'
import HardwareSelectorListHeader from '../../elements/HardwareSelectorListHeader'
import HardwareSelectorToolBars from '../../elements/HardwareSelectorToolBars'
import useHardwareSelectorFetchEngine from '../../fetch/useHardwareSelectorFetchEngine'
import useHandleSelect from '../../useHandleSelect'
import { INVERTER_SELECTOR_MODE_NODE_KEY } from '../constants'
import { InverterDesignAssistantFlowNodeComponentStandardPropsType } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    minHeight: '0px',
    display: 'flex',
  },
}))

interface InverterDesignAssistantSelectorProps extends InverterDesignAssistantFlowNodeComponentStandardPropsType { }

const InverterManualSelectorNode: React.FC<InverterDesignAssistantSelectorProps> = ({ flowController, flowStore }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)

  const defaultFilterValues = useMemo(() => {
    const autoApplyDistributorFilter = appStorage.getBool('auto_apply_distributor_filter')
    return {
      [DISTRIBUTOR_FILTER_KEY_V2]: autoApplyDistributorFilter ? getSupplierConfig(selectedDistributor)?.filterKey : '', // default to selected distributor
    }
  }, [])

  const {
    componentsData,
    setFilters,
    total = 0,
    loading,
    page,
    perPage,
    setPage,
    setPerPage,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  } = useHardwareSelectorFetchEngine({
    componentTypes: ['inverter'],
    limit: 20,
    defaultFilterValues,
  })

  const handleSelect = useHandleSelect({
    onClose: flowStore.onFinish,
    config: {
      targetUuids: flowStore.targetUuids,
      componentTypes: ['inverter'],
    },
  })

  const handleBack = () => {
    flowController.goTo({
      title: 'Add Inverter',
      currentNodeKey: INVERTER_SELECTOR_MODE_NODE_KEY,
      options: {},
    })
  }

  const handleUpdate = useCallback(
    (newFilterValues: { [key: string]: unknown }) => {
      setFilters({ ...modifiableFilterValues, ...newFilterValues })
    },
    [modifiableFilterValues]
  )

  const handleSearchTextUpdate = useCallback(
    (searchText) => {
      handleUpdate({ search: searchText })
    },
    [handleUpdate]
  )

  return (
    <div className={classes.wrapper}>
      <HardwareSelectorLayout
        footer={
          <Box display="flex" justifyContent="flex-end">
            <Button color="default" onClick={handleBack} variant="contained">
              {translate('Back')}
            </Button>
          </Box>
        }
        listContent={
          <HardwareSelectorList
            data={componentsData}
            Header={HardwareSelectorListHeader}
            loading={loading}
            onSelect={handleSelect}
            Pagination={
              <Pagination total={total} page={page} perPage={perPage} setPage={setPage} setPerPage={setPerPage} />
            }
            Row={HardwareListRow}
            total={total}
          />
        }
        toolBars={
          <HardwareSelectorToolBars
            placeholder={'Search by inverter code and/or brand name...'}
            onChange={handleSearchTextUpdate}
            componentType={'inverter'}
          />
        }
        sidebar={
          <HardwareSelectorFilterSidebar
            persistentFilterValues={persistentFilterValues}
            modifiableFilterValues={modifiableFilterValues}
            allFilterValues={allFilterValues}
            setModifiableFilterValues={setFilters}
          />
        }
      />
    </div>
  )
}

export default InverterManualSelectorNode
