import { makeStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import { addProjectError, removeProjectError } from 'actions/project'
import { PrimaryButton } from 'elements/button/GenericButton'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { ModuleGridType, StudioItem, StudioSystemType } from 'types/global'

type PropTypes = {
  system: StudioSystemType
  moduleGrid?: ModuleGridType
  disabled?: Boolean
}

const useStyles = makeStyles({
  bpButton: {
    width: '100%',
    fontSize: '0.9em',
  },
  bpHelpLinkIcon: {
    width: '23px',
    height: '23px',
    position: 'absolute',
    top: 0,
    right: -3,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  bpAccordionDetails: {
    position: 'relative',
    padding: 10,
  },
  bpContent: {
    display: 'flex',
    height: 90,
    width: '100%',
  },
  bpLeftContent: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
  },
  bpRightContent: {
    width: '15%',
    position: 'relative',
  },
})

const ErrorTemplate: ProjectErrorType = {
  message:
    'There are some panels that have been added to the design which are not included in the buildable panels. These will not be editable unless the user has the Design - Edit permission.',
  messageType: 'text',
  severity: 'info',
  source: 'buildablePanels',
  category: 'module',
  key: 'BUILDABLE_PANELS_INFO',
}

const BuildablePanels = ({ system, moduleGrid, disabled = false }: PropTypes) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [hasBuildablePanels, setHasBuildablePanels] = useState(false)
  const warningShownSysId = useRef<string | undefined>()
  const helpCenterLink = 'https://support.opensolar.com/hc/en-us/articles/6754108498319'

  const checkSystemCoverage = useCallback(
    (system: StudioSystemType) => {
      const toShowSysId = system.hasBuildablePanels() && !system.allActivePanelsAreBuildable() ? system.uuid : undefined
      if (toShowSysId === warningShownSysId.current) return
      if (warningShownSysId.current) hidePartialCoverageInfo(warningShownSysId.current)
      if (toShowSysId) showPartialCoverageInfo(toShowSysId)
      warningShownSysId.current = toShowSysId
    },
    [system]
  )

  const updateHasBuildablePanels = useCallback(() => {
    setHasBuildablePanels(moduleGrid ? moduleGrid.hasBuildablePanels() : system.hasBuildablePanels())
    checkSystemCoverage(system)
  }, [moduleGrid, system, checkSystemCoverage])

  useEffect(() => {
    checkSystemCoverage(system)
  }, [system, checkSystemCoverage])

  useEffect(updateHasBuildablePanels, [moduleGrid, system])

  const onStudioObjectChange = useCallback(
    (item: StudioItem, property?: string) => {
      if (
        (item.type === 'OsModuleGrid' && (item as ModuleGridType).getSystem()?.uuid === system.uuid) ||
        (item === system && (property === 'buildableCells' || property === 'output'))
      ) {
        updateHasBuildablePanels()
      }
    },
    [system, updateHasBuildablePanels]
  )

  const showPartialCoverageInfo = (systemId: string) => {
    dispatch(addProjectError({ ...ErrorTemplate, systemId }))
  }

  const hidePartialCoverageInfo = (systemId: string) => {
    dispatch(removeProjectError({ ...ErrorTemplate, systemId }))
  }

  //////////////////////
  // SYSTEM-WIDE
  //////////////////////
  const setAllBuildableSystem = (system: StudioSystemType) => {
    system.setAllPanelsAsBuildable()
  }

  const clearAllBuildableSystem = (system: StudioSystemType) => {
    system.clearBuildablePanels()
  }

  ////////////////////
  // MODULE-GRID ONLY
  ////////////////////
  const setAllBuildableModuleGrid = (moduleGrid: ModuleGridType) => {
    moduleGrid.setAllPanelsAsBuildable()
  }

  const clearAllBuildableModuleGrid = (moduleGrid: ModuleGridType) => {
    moduleGrid.clearBuildablePanels()
  }

  useStudioSignalsLazy(onStudioObjectChange, ['objectChanged'], undefined, { trackHandler: true })

  return (
    <Accordion>
      <AccordionSummary className="AccordionSummary" expandIcon={<ExpandMoreIcon />}>
        {translate('Buildable Panels')}
      </AccordionSummary>
      <AccordionDetails className={classes.bpAccordionDetails}>
        <div className={classes.bpContent}>
          <div className={classes.bpLeftContent}>
            <PrimaryButton
              className={classes.bpButton}
              label={'Set all as Buildable'}
              disabled={disabled}
              onClick={() => {
                moduleGrid ? setAllBuildableModuleGrid(moduleGrid) : setAllBuildableSystem(system)
              }}
            />
            <PrimaryButton
              className={classes.bpButton}
              label={'Clear Buildable Panels'}
              disabled={disabled || !hasBuildablePanels}
              onClick={() => {
                moduleGrid ? clearAllBuildableModuleGrid(moduleGrid) : clearAllBuildableSystem(system)
              }}
            />
          </div>
          <div className={classes.bpRightContent}>
            <a href={helpCenterLink} target="_blank" rel="noopener noreferrer">
              <HelpOutlinedIcon className={classes.bpHelpLinkIcon} />
            </a>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default BuildablePanels
