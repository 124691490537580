// @ts-nocheck
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import DateTimeInput from 'elements/input/DateTimeInput'
import React from 'react'
import { BooleanInput, SelectInput, SimpleForm, TextInput, useTranslate } from 'react-admin'
import { TRANSACTION_TYPE_CHOICES } from 'resources/transactions/Transactions'
import CreateTransactionDialogFooter from './CreateTransactionDialogFooter'

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    width: '100%',
  },
  actionViewRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px 15px 15px',
  },
  rightButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancelButton: {
    marginRight: '20px',
  },
  footerView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  booleanFieldWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}))

const CreateTransactionDialogFormContent: React.FunctionComponent = (props: any) => {
  const redirect = props.redirect
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <SimpleForm
      {...props}
      redirect={redirect}
      toolbar={
        <CreateTransactionDialogFooter
          {...props}
          onDismiss={props.onDismiss}
          onSuccess={props.onSuccess}
          onFailure={props.onFailure}
          onDelete={props.onDelete}
        />
      }
      hideCancelButton={!!props.hideCancelButton}
      margin={'dense'}
      initialValues={props.record}
    >
      <div style={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextInput disabled={!props.record} className={classes.inputStyle} source="amount" />
          </Grid>
          <Grid item xs={4}>
            <TextInput disabled={!props.record} className={classes.inputStyle} source="tax_included" />
          </Grid>
          <Grid item xs={4}>
            <TextInput disabled={!props.record} className={classes.inputStyle} source="surcharge_amount" />
          </Grid>
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center', padding: '0px', margin: '0px' }}>
              {translate('Details for Completed Payments')} ({translate('leave empty for payment requests')})
            </h2>
          </Grid>
          <Grid item xs={6}>
            <DateTimeInput source="transaction_datetime" fullWidth={true} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              source="transaction_type"
              choices={TRANSACTION_TYPE_CHOICES}
              allowEmpty
              fullWidth={true}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <div className={classes.booleanFieldWrapper}>
              <BooleanInput source="is_complete" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.booleanFieldWrapper}>
              <BooleanInput source="funds_confirmed" />
            </div>
          </Grid>
        </Grid>
      </div>
    </SimpleForm>
  )
}

export default CreateTransactionDialogFormContent
