import { FormControl, Typography } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import { ReferenceInput, SelectInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'

const RoofTypeSelector = () => {
  const orgId = useSelector(authSelectors.getOrgId)
  const translate = useTranslate()

  return (
    <FormControl variant="outlined" size="small" fullWidth={true}>
      <Typography variant="subtitle2" gutterBottom>
        {translate('Selected Roof Type')}
      </Typography>
      <CustomField
        component={ReferenceInput}
        resource="projects"
        source="roof_type"
        name="roof_type"
        reference="roof_types"
        perPage={1000}
        allowEmpty
        fullWidth
        style={{ width: '100%' }}
        filter={{
          fav: 1,
          org_id: orgId,
        }}
        id="roof_type_field"
      >
        {/* @ts-ignore */}
        <SelectInput
          optionText={(choice: any) => translate(choice?.name)}
          optionValue="url"
          fullWidth
          style={{ width: '100%' }}
          id="roof_type_select_input"
        />
      </CustomField>
    </FormControl>
  )
}

export default RoofTypeSelector
