import { Grid, Typography } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { deleteUnconfirmedLineItems } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import OrderTableContainer from 'pages/ordering/order/table/OrderTableContainer'
import OrderTableContent from 'pages/ordering/order/table/OrderTableContent'
import { useTranslate } from 'ra-core'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    borderBottom: '1px solid #e7e7e7',
    height: '48px',
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  overflowItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  table: {
    marginTop: 30,
    marginLeft: '25px',
    marginRight: '25px',
  },
  title: {
    marginBottom: '14px',
  },
}))

const BOMTableHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Grid container className={classes.tableHeaderContainer} alignItems="center">
      <Grid container item xs={12} className={classes.header} spacing={1}>
        <Grid item xs={1} style={{ paddingLeft: 20 }}>
          {translate('Category')}
        </Grid>
        <Grid item xs={2} className={classes.overflowItem}>
          {translate('Item')}
        </Grid>
        <Grid item xs={2} className={classes.overflowItem}>
          {translate('Availability on hand')}
        </Grid>
        <Grid item xs={2}>
          {translate('Distributor')}
        </Grid>
        <Grid item xs={2}>
          {translate('Restock Qty')}
        </Grid>
        <Grid item xs={2} className={classes.overflowItem}>
          {translate('Total Cost')}
        </Grid>
        <Grid item xs={1}>
          {translate('Actions')}
        </Grid>
      </Grid>
    </Grid>
  )
}

export const BOMTable = ({
  editable,
  lineItems,
  projectName,
}: {
  editable: boolean
  lineItems: LineItemType[]
  projectName: string
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clearUnconfirmedOrder = useCallback(() => {
    dispatch(deleteUnconfirmedLineItems())
  }, [])

  useEffect(() => {
    return clearUnconfirmedOrder
  }, [])

  return (
    <div className={classes.table}>
      <Grid container spacing={0} justify="space-between" className={classes.title}>
        <Grid container direction="column" xs={6}>
          <Grid item xs={6}>
            <Typography variant="h5">Bill of Materials</Typography>
            <Typography variant="subtitle1">{projectName}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <OrderTableContainer isAvailable={true}>
        <BOMTableHeader />
        <OrderTableContent
          editable={editable}
          lineItems={lineItems}
          location={{ isBOMReviewTable: false, isBOMTable: true }}
        />
      </OrderTableContainer>
    </div>
  )
}
