import { Grid, Typography } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Button } from 'opensolar-ui'
import { getSupplierConfig } from 'pages/ordering/configs'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import { memo, useCallback } from 'react'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import HardwareStandardBannerContainer from '../HardwareStandardBannerContainer'
import OrderByProjectButton from '../button/OrderByProjectButton'
import SegenLoginButton from '../button/SegenLoginButton'

const SEGEN_SUPPORT_LINK = 'https://pages.opensolar.com/partners/segen'
const SEGEN_REGISTER_LINK = 'https://www.segen.co.uk/contact-installer/'

const useSegenBannerStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: 20,
  },
  partner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  badge: {
    width: 110,
    background: theme.themeColorLight,
    padding: '3px 10px',
    borderRadius: '12px',
  },
  img: {
    width: 160,
    height: 80,
  },
  link: { color: theme.blue, textDecoration: 'underline', cursor: 'pointer' },
}))

const SegenConnectBanner = () => {
  const classes = useSegenBannerStyles()
  const segenConnectStatus = useSegenAuthenticationStatus()

  const handleLearnMoreClicked = useCallback(() => {
    window.open(SEGEN_SUPPORT_LINK, '_blank', 'noreferrer')
  }, [])

  const handleClickRegisterLink = () => {
    window.open('https://share.hsforms.com/1r-gtFaxoQj-vksZvB23FFAcne2p', '_blank')
    logAmplitudeEvent('hardware_banner_register_click', { supplier: 'segen' })
  }

  return (
    <Grid container className={classes.container} wrap={'wrap'} spacing={3}>
      <Grid item xs={12} sm={2} container alignItems="center" justify="center" direction="column">
        <img
          className={classes.img}
          alt="supplier-logo"
          src={getSupplierConfig(HardwareSupplierEnum.Segen)?.logoUrl || ''}
        />
        <div className={classes.badge}>
          <Typography align="center" variant="caption">
            OpenSolar Partner
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={7}>
        <div>
          <Typography variant="h6" align="center" gutterBottom>
            Connect your hardware supplier Segen
          </Typography>
          <Typography align="center" variant="body1" gutterBottom>
            Segen is one of the UK’s leading renewables distributors and is fully integrated with OpenSolar's world
            class design software.
          </Typography>
          <Typography align="center" variant="body1" className={classes.link} onClick={handleLearnMoreClicked}>
            Learn more
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={3} container alignItems="center" justify="center" direction="column">
        {segenConnectStatus === 'authenticated' ? (
          <OrderByProjectButton />
        ) : (
          <SegenLoginButton fullWidth color="primary" />
        )}
        <Typography variant="caption" align="center">
          OR
        </Typography>
        <Button onClick={handleClickRegisterLink} variant="outlined" color="primary">
          Register to View Pricing
        </Button>
      </Grid>
    </Grid>
  )
}

const HardwareSegenConnectBanner = () => {
  return (
    <HardwareStandardBannerContainer>
      <SegenConnectBanner />
    </HardwareStandardBannerContainer>
  )
}

export default memo(HardwareSegenConnectBanner)
