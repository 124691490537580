import classnames from 'classnames'
import { memo, useContext, useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import SegenPlaceOrderButton from './ButtonCheckoutSegen'

import CreditLimit from '../CreditLimit'
import OrderSummary from './OrderSummary'
import { creditLimitOverride } from './SegenCartActions'
import { AccountContext } from './SegenCheckoutProvider'
import SegenDisclaimer from './SegenDisclaimer'

const useSideActionStyles = makeOpenSolarStyles((theme) => ({
  sectionWrapper: {
    padding: 15,
    marginBottom: 15,
  },
  container: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
  },
  actionWrapper: {
    borderRadius: '0 0 8px 8px',
    background: theme.greyLight3,
    padding: 15,
  },
  button: {
    textAlign: 'center',
  },
}))

const PlaceOrderSideAction = ({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const { loading, account } = useContext(AccountContext)
  const currencySymbol = account?.currency.symbol
  const classes = useSideActionStyles()
  const creditLimit = creditLimitOverride() || account?.accountSummary.creditLimit.amount
  const CreditLimitComponent = useMemo(
    () => (
      <CreditLimit loading={loading} currencySymbol={currencySymbol} creditLimit={creditLimit} supplierName="Segen" />
    ),
    [loading, account]
  )

  return (
    <div>
      <div className={classnames(classes.sectionWrapper, classes.container)}>{CreditLimitComponent}</div>
      <div className={classes.container}>
        <div className={classes.sectionWrapper}>
          <OrderSummary currencySymbol={currencySymbol} />
        </div>
        <div className={classes.actionWrapper}>
          <div className={classes.button}>
            <SegenPlaceOrderButton onClick={handlePlaceOrder} trackSource="side_action" />
          </div>
          <SegenDisclaimer />
        </div>
      </div>
    </div>
  )
}

export default memo(PlaceOrderSideAction)
