import { Card } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type GreyCardContentType = {
  id: number
  icon: JSX.Element
  header: string
  description: string
}

type PropTypes = {
  content: GreyCardContentType[]
}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: '1rem',
  },

  card: {
    background: theme.greyLight3,
    border: 'none !important',
    borderRadius: '8px',
    padding: '1rem',
    margin: '2rem 0 1rem 0',
  },

  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },

  cardDescription: {
    marginLeft: '35px',
    lineHeight: 1.5,
  },
}))

const GreyInfoCard: React.FC<PropTypes> = ({ content, children }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Card className={classes.card}>
      {content.map((cont) => {
        return (
          <div key={cont.id}>
            <h2 className={classes.cardHeader}>
              {cont.icon}
              {translate(cont.header)}
            </h2>
            <p className={classes.cardDescription}>{translate(cont.description)}</p>
          </div>
        )
      })}
      {children}
    </Card>
  )
}
export default GreyInfoCard
