import { DialogTitle } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '.5rem',
    paddingBottom: '1rem',
    borderBottom: '1px #DADBE9 solid',
  },
  title: {
    marginRight: '10px',
  },
}))

const IntroTitle: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <DialogTitle>
      <div className={classes.row}>
        <span className={classes.title}>
          {translate('Welcome to CashFlow, the only payments system built for solar professionals')}
        </span>
      </div>
    </DialogTitle>
  )
}
export default IntroTitle
