import { Grid } from '@material-ui/core'
import { InfoOutlined, VisibilityOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BlueInfoCard from '../components/BlueInfoCard'
import GreyInfoCard from '../components/GreyInfoCard'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    padding: '.5rem 2rem',
  },

  addtlValueContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    width: '21rem',
    margin: '0.5rem',
  },

  addtlValuePriceContainer: {
    background: '#FFD800',
    borderRadius: '8px',
    fontWeight: 500,
    fontSize: '13px',
    marginRight: '24px',
    position: 'relative',

    // '&:after': {
    //   zIndex: 2,
    //   marginTop: '3px',
    //   content: `''`,
    //   position: 'fixed',
    //   height: '2px',
    //   width: '35px',
    //   backgroundColor: '#FFD800',
    // },
  },

  addtlValueDescContainer: {
    textAlign: 'left',
  },

  addtlValueHeader: {
    fontSize: '13px',
    margin: 0,
  },

  addtlValueDesc: {
    fontSize: '13px',
    margin: 0,
    fontStyle: 'italic',
  },

  softCost: {
    padding: '10px 16px',
  },

  increasedConversion: {
    padding: '6px 16px',
  },

  feeSavings: {
    padding: '16px',
  },

  missedRevenue: {
    padding: '2px 16px',
  },
}))

const ControlDescriptionRight: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  const infoContent = [
    {
      id: 1,
      icon: <InfoOutlined />,
      header: 'Why do we need this information?',
      description:
        'Regulations applicable to OpenSolar require financial institutions to obtain identifying information of certain owners or controllers of a business. This requirement ensures compliance with regulations by OpenSolar.',
    },
    {
      id: 2,
      icon: <VisibilityOutlined />,
      header: 'How do we secure your information?',
      description:
        'Information captured as part of this process is securely transferred to our Payment Service Provider. Access to this information is strictly limited to individuals who need it for internal business processes, such as reviewing your registration information, onboarding purposes, and technical troubleshooting.',
    },
  ]

  const addtlValueContent = [
    {
      id: 1,
      price: '28',
      header: 'Soft Cost Savings',
      description: 'Automated payment collection & follow-up',
      class: classes.addtlValuePriceContainer + ' ' + classes.softCost,
    },
    {
      id: 2,
      price: '21',
      header: 'Increased Conversion Rate',
      description: 'Improved customer payment experience',
      class: classes.addtlValuePriceContainer + ' ' + classes.increasedConversion,
    },
    {
      id: 3,
      price: '35',
      header: 'Fee Savings',
      description: 'Reduced credit card & invoicing costs',
      class: classes.addtlValuePriceContainer + ' ' + classes.feeSavings,
    },
    {
      id: 4,
      price: '6',
      header: 'Avoid “Missed” Revenue',
      description: 'Collect every payment on time',
      class: classes.addtlValuePriceContainer + ' ' + classes.missedRevenue,
    },
  ]

  return (
    <div>
      <BlueInfoCard header={'%{currencySymbol}90 of additional value per created project'}>
        {addtlValueContent.map((content) => {
          return (
            <Grid container spacing={2} className={classes.addtlValueContainer} key={content.id}>
              <Grid xs={1} className={content.class}>
                <span>
                  {currencySymbol}
                  {content.price}
                </span>
              </Grid>
              <Grid xs={11} className={classes.addtlValueDescContainer}>
                <h3 className={classes.addtlValueHeader}>{translate(content.header)}</h3>
                <p className={classes.addtlValueDesc}>{translate(content.description)}</p>
              </Grid>
            </Grid>
          )
        })}
      </BlueInfoCard>
      <GreyInfoCard content={infoContent} />
    </div>
  )
}
export default ControlDescriptionRight
