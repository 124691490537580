import DateInput from 'elements/input/DateInput'
import lodash from 'lodash'
import { styled } from 'opensolar-ui'
import { FC, useEffect, useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { dateParserYYYYMMDD } from 'util/misc'
import { DATE_CHOICES } from '../fields'

interface PropTypes {
  source: string
  fieldId: 'dateSold' | 'dateCreated' | 'dateInstalled' | 'dateModified'
}
const sourceString = {
  dateCreated: 'created',
  dateInstalled: 'installation',
  dateModified: 'modified',
}
const getFilterKey = (source, conditional) => {
  if (source === 'dateSold') return `sold_on_${conditional === 0 ? 'or_after' : 'or_before'}`
  return `${sourceString[source]}_date_${conditional === 0 ? 'gte' : 'lte'}`
}

const StyledDateInput = styled(DateInput)({
  width: 180,
})

const DateFilter: FC<PropTypes> = ({ source, fieldId }) => {
  const form = useForm()
  const formValues = useFormState().values
  const value = useMemo(() => lodash.get(formValues, `${source}.fields`), [formValues, source])
  useEffect(() => {
    let newValue = {}
    if (value?.conditional !== undefined && value?.date) {
      newValue[getFilterKey(fieldId, value.conditional)] = value.date
    }
    form.change(`${source}.value`, newValue)
  }, [value, source])
  return (
    <>
      <SelectInput
        choices={DATE_CHOICES}
        label={false}
        source={`${source}.fields.conditional`}
        variant="outlined"
        margin={'none'}
      />
      <StyledDateInput
        inputVariant={'outlined'}
        source={`${source}.fields.date`}
        label={false}
        options={{ format: 'MM/DD/YYYY', color: 'secondary' }}
        parse={dateParserYYYYMMDD}
      />
    </>
  )
}

export default DateFilter
