import { makeStyles } from '@material-ui/core'
import useBrandingConfig from 'branding/useBrandingConfig'
import { orgSelectors } from 'ducks/orgs'
import { setUploadLogo as setUploadLogoAction } from 'ducks/uploadLogo'
import { viewModeSelectors } from 'ducks/viewMode'
import { Button } from 'opensolar-ui'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { getRoleFromState } from 'util/misc'

const OpenSolarLogo = window.PUBLIC_URL + '/images/logo.svg'
const OpenSolarLiteLogo = window.PUBLIC_URL + '/images/OpenSolar-Lite-logo.png'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  logoContainer: {
    width: '100%',
    transform: 'translate(-50%,-50%)',
    left: '50%',
    top: -115,
    position: 'absolute',
  },
  img: {
    maxWidth: 250,
    maxHeight: 100,
    display: 'block',
    margin: '0 auto',
    verticalAlign: 'bottom',
  },
  button: {
    // padding: '5px 10px',
    minWidth: 50,
    marginTop: 10,
    // fontWeight: 'normal',
    // width: 'fit-content',
    // backgroundColor: '#f6f6f6',
    // border: 'none',
    // boxShadow: '0px 4px 4px 0px #0000001F',
  },
  label: {
    textTransform: 'none',
  },
  endIcon: {
    margin: 0,
  },
  icon: {
    margin: 0,
    width: 24,
    height: 24,
    verticalAlign: 'middle',
  },
}))
const Logo = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const org = useSelector(orgSelectors.getOrg)
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  const brandingConfig = useBrandingConfig()
  const logoUrl = useMemo(() => {
    if (brandingConfig.homeOverride?.placeholderLogo) {
      return `${window.PUBLIC_URL}/images/${brandingConfig.homeOverride.placeholderLogo}`
    } else {
      return org?.logo_public_url
    }
  }, [org?.logo_public_url, brandingConfig])

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoContainer}>
        {isUserLite ? (
          <img alt="" className={classes.img} src={(logoUrl || OpenSolarLiteLogo) as string} />
        ) : (
          <img alt="" className={classes.img} src={(logoUrl || OpenSolarLogo) as string} />
        )}
        {org && !logoUrl && isAdmin && (
          <Button
            variant="outlined"
            color="default"
            classes={{ root: classes.button, label: classes.label, endIcon: classes.endIcon }}
            className="UploadLogoButton"
            onClick={() => dispatch(setUploadLogoAction(true))}
          >
            {<span style={{ textTransform: 'none' }}>{translate('Change to your Company Logo')}</span>}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Logo
