export const PERFORMANCE_BASED_INCENTIVE = 0
export const SIZE_BASED_INCENTIVE = 1
export const PRICE_BASED_INCENTIVE = 2
export const FIXED_INCENTIVE = 3
export const STC_INCENTIVE = 4
export const DEPRECIATION_BENEFIT_INCENTIVE = 5
export const RENEWABLE_ENERGY_TECHNOLOGIES_INCOME_TAX_CREDIT = 6
export const SELF_GENERATION_INCENTIVE_PROGRAM = 7
export const IBI_TAX_INCENTIVE = 8
export const SWEDEN_GREEN_DEDUCTION_INCENTIVE = 9

export const getIncentiveVariables = (incentiveType: number, currencySymbol: string = '$') => {
  const incentiveVariables = incentiveVariablesByType(currencySymbol).find((i) => i.incentiveType === incentiveType)
  return incentiveVariables?.variables ? incentiveVariables?.variables : []
}

export const incentiveVariablesByType = (currencySymbol: string) => {
  return [
    {
      incentiveType: FIXED_INCENTIVE,
      title: 'Fixed',
      variables: [
        {
          key: 'value',
          source: 'value',
          label: 'Incentive Value',
          startAdornment: currencySymbol,
          component: 'NumberInput',
          required: true,
        },
      ],
    },
    {
      incentiveType: SIZE_BASED_INCENTIVE,
      title: 'Size Based Incentive',
      variables: [
        {
          key: 'size_based_incentive_type',
          source: 'size_based_incentive_type',
          label: 'Size Based Incentive Type',
          choices: [
            { id: 'solar_kw', name: 'PV System DC Capacity (kW)' },
            { id: 'system_ac_capacity', name: 'PV System AC Capacity (kW)' },
            { id: 'battery_kwh', name: 'Battery Usable Capacity (kWh)' },
            { id: 'battery_nameplate_kwh', name: 'Battery Nameplate Capacity (kWh)' },
          ],
          component: 'SelectInput',
          required: true,
        },
        {
          key: 'value',
          source: 'value',
          label: 'Value per unit',
          startAdornment: currencySymbol,
          endAdornment: 'per unit',
          component: 'NumberInput',
          required: true,
        },
      ],
    },
    {
      incentiveType: PRICE_BASED_INCENTIVE,
      title: 'Price Based Incentive',
      variables: [
        {
          key: 'price_based_incentive_type',
          source: 'price_based_incentive_type',
          label: 'Price Based Incentive Type',
          choices: [
            { id: 'price_including_tax', name: 'Price Including Tax' },
            { id: 'price_excluding_tax', name: 'Price Excluding Tax' },
            { id: 'price_payable_including_tax', name: 'Price Payable Including Tax' },
            { id: 'price_payable_excluding_tax', name: 'Price Payable Excluding Tax' },
          ],
          component: 'SelectInput',
          required: true,
        },
        {
          key: 'value',
          source: 'value',
          label: 'Percentage of Price',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
      ],
    },
    {
      incentiveType: PERFORMANCE_BASED_INCENTIVE,
      title: 'Performance Based Incentive',
      variables: [
        {
          key: 'value_by_year',
          source: 'value_by_year',
          label: 'Value By Year',
          endAdornment: `${currencySymbol}/kWh CSV`,
          component: 'TextInput',
          isCsvField: true,
          required: true,
        },
        {
          key: 'number_of_years',
          source: 'number_of_years',
          label: 'Number Of Years',
          endAdornment: 'years',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'annual_value_adjustment_percentage',
          source: 'annual_value_adjustment_percentage',
          label: 'Annual Value Adjustment',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
      ],
    },
    {
      incentiveType: DEPRECIATION_BENEFIT_INCENTIVE,
      title: 'Depreciation Benefit',
      variables: [
        {
          key: 'depreciation_basis',
          source: 'depreciation_basis',
          label: 'Depreciation Basis',
          choices: [
            { id: 'system_price_excluding_tax', name: 'System Price Excluding Tax' },
            { id: 'system_price_including_tax', name: 'System Price Including Tax' },
            { id: 'purchase_price_ex_tax', name: 'Purchase Price Excluding Tax' },
            { id: 'purchase_price_inc_tax', name: 'Purchase Price Including Tax' },
            { id: 'net_system_price_ex_tax', name: 'Net System Price Excluding Tax' },
            { id: 'net_system_price_inc_tax', name: 'Net System Price Including Tax' },
          ],
          component: 'SelectInput',
          required: true,
        },
        {
          key: 'percent_of_basis',
          source: 'percent_of_basis',
          label: 'Percent of Incentive Basis',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'tax_rate_percent',
          source: 'tax_rate_percent',
          label: 'Tax Rate',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'tax_jurisdiction',
          source: 'tax_jurisdiction',
          label: 'Tax Jurisdiction',
          choices: [
            { id: 'federal_tax_rate', name: 'Federal Tax Rate' },
            { id: 'state_tax_rate', name: 'State Tax Rate' },
          ],
          component: 'SelectInput',
          required: true,
        },
        {
          key: 'depreciation_percent_per_year',
          source: 'depreciation_percent_per_year',
          label: 'Depreciation per Year',
          endAdornment: '%/year CSV',
          component: 'TextInput',
          isCsvField: true,
          required: true,
        },
        {
          key: 'use_non_discounted_value_in_quotation',
          source: 'use_non_discounted_value_in_quotation',
          label: 'Use non discounted value in quotation',
          component: 'BooleanInput',
        },
      ],
    },
    {
      incentiveType: STC_INCENTIVE,
      title: 'STC Point of Sale Discount',
      variables: [
        {
          key: 'incentive_price',
          source: 'incentive_price',
          label: 'Incentive Price',
          startAdornment: currencySymbol,
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'years_remaining',
          source: 'years_remaining',
          label: 'Years Remaining',
          endAdornment: 'years',
          component: 'NumberInput',
          required: true,
        },
      ],
    },
    {
      incentiveType: RENEWABLE_ENERGY_TECHNOLOGIES_INCOME_TAX_CREDIT,
      title: 'Renewable Energy Technologies Income Tax Credit',
      variables: [
        {
          key: 'max_system_kw_size_per_system',
          source: 'max_system_kw_size_per_system',
          label: 'Max System Size Per System',
          endAdornment: 'kW',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'percent_of_price_per_system',
          source: 'percent_of_price_per_system',
          label: 'Percentage of Price Per System',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'incentive_cap_per_system',
          source: 'incentive_cap_per_system',
          label: 'Incentive Cap Per System',
          startAdornment: currencySymbol,
          component: 'NumberInput',
          required: true,
        },
      ],
    },
    {
      incentiveType: SELF_GENERATION_INCENTIVE_PROGRAM,
      title: 'Self-Generation Incentive Program',
      variables: [
        {
          key: 'incentive_price',
          source: 'incentive_price',
          label: 'Incentive Price',
          startAdornment: currencySymbol,
          endAdornment: 'per kWh',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'opt_out_of_resiliency_operation',
          source: 'opt_out_of_resiliency_operation',
          label: 'Opted out of Resiliency Operation',
          component: 'BooleanInput',
        },
        {
          key: 'is_paid_pbi',
          source: 'is_paid_pbi',
          label: 'Is Paid PBI',
          component: 'BooleanInput',
          skip: true, // Flag to indicate that this is render in a separate react component: SelfGenerationPBIInputs
        },
        {
          key: 'percentage_paid_in_pbi',
          source: 'percentage_paid_in_pbi',
          label: 'Base Incentive Paid in PBI',
          endAdornment: '%',
          component: 'NumberInput',
          skip: true, // Flag to indicate that this is render in a separate react component: SelfGenerationPBIInputs
        },
        {
          key: 'max_pbi_years',
          source: 'max_pbi_years',
          label: 'Maximum PBI period',
          endAdornment: 'years',
          component: 'NumberInput',
          skip: true, // Flag to indicate that this is render in a separate react component: SelfGenerationPBIInputs
        },
        {
          key: 'full_discharges_per_year',
          source: 'full_discharges_per_year',
          label: 'Full Battery Discharges',
          endAdornment: 'per year',
          component: 'NumberInput',
          skip: true, // Flag to indicate that this is render in a separate react component: SelfGenerationPBIInputs
        },
        {
          key: 'use_non_discounted_value_in_quotation',
          source: 'use_non_discounted_value_in_quotation',
          label: 'Use non discounted value in quotation',
          component: 'BooleanInput',
          skip: true, // Flag to indicate that this is render in a separate react component: SelfGenerationPBIInputs
        },
      ],
    },
    {
      incentiveType: IBI_TAX_INCENTIVE,
      title: 'IBI Tax Incentive',
      variables: [
        {
          key: 'tax_reduction_per_year',
          source: 'tax_reduction_per_year',
          label: 'Tax Reduction Per Year',
          endAdornment: '%/year CSV',
          component: 'TextInput',
          isCsvField: true,
          required: true,
        },
      ],
    },
    {
      incentiveType: SWEDEN_GREEN_DEDUCTION_INCENTIVE,
      title: 'Grön Teknik',
      variables: [
        {
          key: 'solar_pv_rebate_percent',
          source: 'solar_pv_rebate_percent',
          label: 'Solar PV Rebate',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'battery_rebate_percent',
          source: 'battery_rebate_percent',
          label: 'Battery Rebate',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'ev_charger_rebate_percent',
          source: 'ev_charger_rebate_percent',
          label: 'EV Charger Rebate',
          endAdornment: '%',
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'cap_amount_one_person',
          source: 'cap_amount_one_person',
          label: 'Cap Amount 1 Person',
          endAdornment: currencySymbol,
          component: 'NumberInput',
          required: true,
        },
        {
          key: 'cap_amount_more_than_one_person',
          source: 'cap_amount_more_than_one_person',
          label: 'Cap Amount More Than 1 Person',
          endAdornment: currencySymbol,
          component: 'NumberInput',
          required: true,
        },
      ],
    },
  ]
}

export const INCENTIVE_TYPE_CHOICES = [
  { id: FIXED_INCENTIVE, name: 'Fixed', countryCodeFilter: [] },
  { id: SIZE_BASED_INCENTIVE, name: 'Size Based Incentive', countryCodeFilter: [] },
  { id: PRICE_BASED_INCENTIVE, name: 'Price Based Incentive', countryCodeFilter: [] },
  { id: PERFORMANCE_BASED_INCENTIVE, name: 'Performance Based Incentive', countryCodeFilter: [] },
  { id: DEPRECIATION_BENEFIT_INCENTIVE, name: 'Depreciation Benefit', countryCodeFilter: [] },
  { id: STC_INCENTIVE, name: 'STC Point of Sale Discount', countryCodeFilter: ['AU'] },
  {
    id: RENEWABLE_ENERGY_TECHNOLOGIES_INCOME_TAX_CREDIT,
    name: 'Renewable Energy Technologies Income Tax Credit',
    countryCodeFilter: ['US'],
  },
  { id: SELF_GENERATION_INCENTIVE_PROGRAM, name: 'Self-Generation Incentive Program', countryCodeFilter: ['US'] },
  { id: IBI_TAX_INCENTIVE, name: 'IBI Tax Incentive', countryCodeFilter: ['ES'] },
  { id: SWEDEN_GREEN_DEDUCTION_INCENTIVE, name: 'Grön Teknik', countryCodeFilter: ['SE'] },
]

export const INCENTIVE_TYPES_WITH_APPLIED_OVER_TIME = [SIZE_BASED_INCENTIVE, PRICE_BASED_INCENTIVE, FIXED_INCENTIVE]
export const INCENTIVES_WITH_PAID_TO_SELECTION = [
  SIZE_BASED_INCENTIVE,
  PRICE_BASED_INCENTIVE,
  FIXED_INCENTIVE,
  STC_INCENTIVE,
  RENEWABLE_ENERGY_TECHNOLOGIES_INCOME_TAX_CREDIT,
]

export const INCENTIVE_PAID_TO_CUSTOMER = 0
export const INCENTIVE_PAID_TO_INSTALLER = 1

export const INCENTIVE_PAID_TO_CHOICES = [
  { id: INCENTIVE_PAID_TO_CUSTOMER, name: 'Customer' },
  { id: INCENTIVE_PAID_TO_INSTALLER, name: 'Installer' },
]

export const FREQUENCY_CHOICES = [
  { id: 'weekly', name: 'Weekly (52 per year)' },
  { id: 'fortnightly', name: 'Fortnightly (26 per year)' },
  { id: 'bimonthly', name: 'Bimonthly (24 per year)' },
  { id: 'monthly', name: 'Monthly (12 per year)' },
  { id: 'quarterly', name: 'Quarterly (4 per year)' },
  { id: 'yearly', name: 'Yearly (1 per year)' },
]

export const FREQUENCY_CHOICES_WITH_BILL = [
  ...FREQUENCY_CHOICES,
  { id: 'per_bill_frequency', name: 'Per Utility Billing Frequency' },
]

export const ALL_SECTOR_CHOICE = 0
export const RESIDENTIAL_SECTOR_CHOICE = 1
export const COMMERCIAL_SECTOR_CHOICE = 2
export const INDUSTRIAL_SECTOR_CHOICE = 3
export const LIGHTING_SECTOR_CHOICE = 4

export const SECTOR_CHOICES = [
  [ALL_SECTOR_CHOICE, 'All'],
  [RESIDENTIAL_SECTOR_CHOICE, 'Residential'],
  [COMMERCIAL_SECTOR_CHOICE, 'Commercial'],
  [INDUSTRIAL_SECTOR_CHOICE, 'Industrial'],
  [LIGHTING_SECTOR_CHOICE, 'Lighting'],
]
