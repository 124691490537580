import { AccountBalance, CreditCard } from '@material-ui/icons'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BlueInfoCard from '../components/BlueInfoCard'
import GreyInfoCard from '../components/GreyInfoCard'
import PaymentChannels from '../components/PaymentChannels'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  img: {
    minWidth: '95%',
  },
  paymentContainer: {
    padding: '.5rem',
  },
  bankImgContainer: {
    marginLeft: '35px',
  },
}))

const BankInfoRight: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()

  const infoContent = [
    {
      id: 1,
      icon: <AccountBalance />,
      header: 'Why do you need my business bank account?',
      description:
        'We require your business bank account to start receiving payments from customers - personal bank accounts cannot be used.',
    },
    {
      id: 2,
      icon: <CreditCard />,
      header: 'Where can I find my bank account info?',
      description:
        "To find your bank account info online, log into your bank's website and look for account details. You can also find bank account info on your paper checks.",
    },
  ]
  return (
    <div>
      <BlueInfoCard header={'Offer your customers the most trusted and convenient payment methods'}>
        <div className={classes.paymentContainer}>
          <PaymentChannels />
        </div>
      </BlueInfoCard>

      <GreyInfoCard content={infoContent}>
        <div className={classes.bankImgContainer}>
          <img src={`${window.PUBLIC_URL}/images/cashflow/BankInfo.svg`} className={classes.img} />
        </div>
      </GreyInfoCard>
    </div>
  )
}
export default BankInfoRight
