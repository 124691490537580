import { makeStyles } from '@material-ui/core'
import DateTimeInput from 'elements/input/DateTimeInput'
import { TASK_STATUS_CHOICES } from 'projectSections/sections/manage/projectManagement/constants'
import { getProjectSectionStyles, InputRow, InputWrapper } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { ReferenceInput, SelectInput, TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { Theme } from 'types/themes'
import ChipArrayInput from '../../../../common/ChipsArrayInput'
const useStyles = makeStyles((theme: Theme) => ({
  chipStyle: {
    margin: '10px 5px',
    '& .MuiChip-deleteIcon': {
      margin: 0,
    },
  },
}))

export const EVENT_TYPE_CHOICES = [
  { id: 20, name: 'Call (outbound)' },
  { id: 21, name: 'Call (outbound, no answer)' },
  { id: 22, name: 'Call (inbound)' },
  { id: 23, name: 'Email (outbound)' },
  { id: 24, name: 'Email (inbound)' },
  { id: 25, name: 'SMS' },
  { id: 26, name: 'Instant Message' },
  { id: 27, name: 'In Person Meeting' },
  { id: 28, name: 'Appointment' },
  { id: 29, name: 'Activity (Miscellaneous)' },
  { id: 30, name: 'Busy' },
  { id: 40, name: 'On-site Installation' },
  { id: 41, name: 'On-site Maintenance Visit' },
  { id: 42, name: 'On-site Sales Visit' },
]

interface PropTypes {}

const TaskFormContent: React.FC<PropTypes> = (props) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const sectionStyles = getProjectSectionStyles()
  return (
    <InputWrapper>
      <h3>{translate('Task Details')}</h3>
      <InputRow>
        <SelectInput
          source={'event_type_id'}
          variant="outlined"
          label={'Task Type'}
          choices={EVENT_TYPE_CHOICES}
          fullWidth
        />
        <SelectInput
          source={'task_status'}
          variant="outlined"
          label={'Status'}
          choices={TASK_STATUS_CHOICES}
          fullWidth
        />
      </InputRow>
      <InputRow>
        {
          //@ts-ignore
          <DateTimeInput
            source={'start'}
            label={'Start Date'}
            options={{
              inputVariant: 'outlined',
              format: 'MM/DD/YYYY h:m A',
            }}
            fullWidth
          />
        }
        {
          //@ts-ignore
          <DateTimeInput
            source={'end'}
            label={'End Date'}
            options={{
              inputVariant: 'outlined',
              format: 'MM/DD/YYYY h:m A',
            }}
            fullWidth
          />
        }
      </InputRow>
      <TextInput source={'notes'} variant="outlined" label={'Description'} multiline rows={3} fullWidth />
      <h3>{translate('Team and Communication')}</h3>
      <ChipArrayInput
        source={'team_members'}
        resource={'team_members'}
        reference={'roles'}
        optionText="display"
        chipsInputProps={{
          source: 'team_members',
          options: {
            placeholder: !!formValues.team_members?.length ? undefined : 'Select a team member',
            label: translate('Assign to'),
          },
          chipClassName: `${sectionStyles.detailChip} ${classes.chipStyle}`,
        }}
        onChange={(values) => {
          form.change(
            'team_members',
            values.map((x) => x.url)
          )
        }}
      />
      <ChipArrayInput
        source={'notify_team_members'}
        resource={'team_members'}
        reference={'roles'}
        optionText="display"
        optionValue="id"
        chipsInputProps={{
          source: 'notify_team_members',
          options: {
            placeholder: !!formValues.notify_team_members?.length ? undefined : 'Select a team member',
            label: translate('Notify'),
          },
          chipClassName: `${sectionStyles.detailChip} ${classes.chipStyle}`,
        }}
        onChange={(values) => {
          form.change(
            'notify_team_members',
            values.map((x) => x.id)
          )
        }}
      />
      <ReferenceInput
        source="contact"
        reference="contacts"
        allowEmpty
        label={'Contact'}
        resource="contacts"
        renderChildrenBeforeRecord={true}
        filter={{ type: 0 }}
        fullWidth
      >
        <SelectInput
          source={'contact'}
          variant="outlined"
          optionText="display"
          optionValue="url"
          choices={TASK_STATUS_CHOICES}
        />
      </ReferenceInput>
    </InputWrapper>
  )
}
export default TaskFormContent
