import { styled } from 'opensolar-ui'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { InlineFormContainer } from '../../../common/styles'
import { handleKeyDown } from '../../utils'
import { EditInlineRowButtons } from '../rowButtons/EditInlineRowButtons'

export const TitleInlineEditForm = styled(Edit)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: 14,
  },
}))

export const EditTitle = ({ record, ...props }) => {
  return (
    <InlineFormContainer>
      <TitleInlineEditForm
        id={record.id}
        hideTitle={true}
        hideBreadCrumbs={true}
        resource="private_files"
        submitOnEnter={true}
        location={{
          hash: '',
          pathname: '/private_files',
        }}
      >
        <SimpleForm redirect={false} toolbar={<EditInlineRowButtons record={record} {...props} />}>
          <TextInput
            submitOnEnter={true}
            multiline={true}
            source="title"
            variant="outlined"
            label={''}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        </SimpleForm>
      </TitleInlineEditForm>
    </InlineFormContainer>
  )
}
