import { Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { IconButton } from 'opensolar-ui'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import React, { memo, useEffect } from 'react'
import { DatagridLoading } from 'react-admin'
import useHardwareStock from '../../hooks/useHardwareStock'
import type { InventoryType } from '../../type'
import InventoryListTableHeader from './InventoryListTableHeader'
import InventoryListTableRow from './InventoryListTableRow'

type StatelessInventoryTableProps = {
  inventoryData: InventoryType[] | undefined
  handleAvailableIconClicked({ code }: { code: string }): void
  toggleEditOn(arg: string): void
  editable: boolean
  loading: boolean
  toolbar?: React.ReactNode
  actions?: React.ReactNode
}

const StatelessInventoryTable = ({
  toolbar,
  inventoryData,
  loading,
  editable,
  toggleEditOn,
  actions,
  handleAvailableIconClicked,
}: StatelessInventoryTableProps) => {
  const { loadingStock, loadHardwareStock, stockData } = useHardwareStock()
  const stockItemCode = stockData?.map((item) => item.code)

  useEffect(() => {
    if (inventoryData?.length === 0) return
    const codes = inventoryData?.map((inventory) => inventory.code)
    const length = codes?.length
    if (!codes || !length || length === 0) {
      return
    }
    loadHardwareStock(codes, length)
  }, [])

  const editMinIcon = editable ? (
    <IconButton size="small" onClick={() => toggleEditOn('min')}>
      <EditOutlinedIcon />
    </IconButton>
  ) : null

  const editOnHandIcon = editable ? (
    <IconButton size="small" onClick={() => toggleEditOn('onHand')}>
      <EditOutlinedIcon />
    </IconButton>
  ) : null

  if (loading) {
    return <DatagridLoading hasBulkActions={true} nbChildren={5} />
  }

  if (inventoryData === undefined) {
    // TODO: add UI
    // Must specify warehouse location
    return <div>No warehouse found</div>
  }

  if (inventoryData.length === 0) {
    // TODO: consider the UI if no data
  }

  const checkStock = (inventoryItemCode) => {
    let hasStock
    if (stockItemCode?.includes(inventoryItemCode)) {
      const distributors = stockData?.map((data) => data.distributors)
      hasStock = distributors?.some((distributor) => {
        const status = ProjectOrderPresenter.getStockLevelStatusMain({
          quantity: 1,
          stockLevels: distributor[0]?.stock_levels,
          isAvailable: distributor[0]?.is_available,
        })
        return status
      })
    }
    return hasStock
  }

  return (
    <>
      {!!toolbar && toolbar}
      <Paper elevation={1}>
        <Table size="small">
          <InventoryListTableHeader />
          {inventoryData.map((inventory: InventoryType) => {
            return (
              <InventoryListTableRow
                key={inventory.code}
                inventory={inventory}
                editMinIcon={editMinIcon}
                editOnHandIcon={editOnHandIcon}
                handleAvailableIconClicked={handleAvailableIconClicked}
                loadingStock={loadingStock}
                hasStock={checkStock(inventory.code)}
              />
            )
          })}
          {!!actions && actions}
        </Table>
      </Paper>
    </>
  )
}

export default memo(StatelessInventoryTable)
