import { makeStyles, Theme, Typography } from '@material-ui/core'
import ActionHelpOutline from '@material-ui/icons/HelpOutlineOutlined'
import { authSelectors } from 'ducks/auth'
import CSVUploadDialog from 'elements/CSVUploadDialog'
import ProUXPaginationWithActions from 'elements/ProUXPaginationWithActions'
import { Button } from 'opensolar-ui'
import { FC, useCallback, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { handleCsvDownload } from 'resources/projects/util'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const useExtraActionStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flexGrow: 1,
    paddingLeft: 16,
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  // text: {
  //   display: 'flex',
  //   alignItems: 'center',
  // },
  textWithAction: {
    textDecoration: 'underline',
    color: (theme as any).blue,
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  textWithNoneTransform: {
    textTransform: 'none',
  },
  icon: {
    verticalAlign: 'middle',
    width: 18,
    height: 18,
    margin: '0 10px',
  },
}))

type ExtraActionTypes = {
  downloadUrl?: string
  downloadName: string
}

const ExamplesMapper: { [key: string]: string } = {
  pricing: window.PUBLIC_URL + '/files/OpenSolar_pricing_schemes_example.csv',
  costings: window.PUBLIC_URL + '/files/OpenSolar_costings_list_example.csv',
  module: window.PUBLIC_URL + '/files/OpenSolar_component_module_activations_list_example.csv',
  inverter: window.PUBLIC_URL + '/files/OpenSolar_component_inverter_activations_list_example.csv',
  battery: window.PUBLIC_URL + '/files/OpenSolar_component_battery_activations_list_example.csv',
  other: window.PUBLIC_URL + '/files/OpenSolar_component_other_activations_list_example.csv',
}

const CSVUploadExtraActions: FC<ExtraActionTypes> = ({ downloadUrl, downloadName }) => {
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useExtraActionStyles()
  // const downloadExampleCsv = useCallback(() => {
  //   // handleCsvDownloadGeneric(ExamplesMapper.pricing, downloadName, {}, notify)
  //   window.open(ExamplesMapper.pricing)
  // }, [])
  const handleClickHelpIcon = useCallback(() => {
    window.open('https://support.opensolar.com/hc/en-us/articles/4545941304975', '_blank')
  }, [])
  const renameDownloadName = (item: string) => {
    if (item?.includes('component')) {
      return item?.replace('OpenSolar_component_', '').replace('activations_list', ' ').replace('_', ' ').trim()
    } else {
      if (item?.includes('schemes')) {
        return item?.replace('OpenSolar_', '').replace('schemes_list', ' ').replace('_', ' ').trim()
      } else {
        return item?.replace('OpenSolar_', '').replace('list', ' ').replace('_', ' ').trim()
      }
    }
  }
  let itemName = renameDownloadName(downloadName)
  const handleDownloadComponentList = useCallback(() => {
    if (downloadUrl) {
      handleCsvDownload(downloadUrl, notify, {}, downloadName)
    }
  }, [])
  return (
    <div className={classes.wrapper}>
      {/* <div className={classes.text}>{translate('Download an example template here')}:</div> */}
      <div className={classes.rowWrapper}>
        {/* <div className={classes.textWithAction} onClick={downloadExampleCsv}>
          Example {renameDownloadName(downloadName)} upload.<span className={classes.textWithNoneTransform}>csv</span>
        </div>{' '} */}
        {/* <a
          href={ExamplesMapper[itemName]}
          download={downloadName}
          className={classes.textWithAction}
          style={{ color: '#0094FF' }}
        >
          Example {renameDownloadName(downloadName)} list upload.
          <span className={classes.textWithNoneTransform}>csv</span>
        </a>{' '} */}
        <div className={classes.textWithAction} onClick={handleDownloadComponentList}>
          Download {renameDownloadName(downloadName)} list
        </div>
        <ActionHelpOutline classes={{ root: classes.icon }} onClick={handleClickHelpIcon} />{' '}
      </div>
    </div>
  )
}
const DEFAULT_TITLE = 'CSV Upload'
const PaginationActions = ({
  resource,
  filterValues = {},
  csvUploadDialogDescription,
  csvUploadDialogTitle = DEFAULT_TITLE,
}: any) => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const notify = useNotify()
  const classes = useStyles()
  const orgId = useSelector(authSelectors.getOrgId)
  const translate = useTranslate()
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false)
  }, [])
  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const CSV_DOWNLOAD_URL = useMemo(
    () => `${(window as any).API_BASE_URL}orgs/${orgId}/${resource}/?format=csv&limit=1000&ordering=-id`,
    [resource]
  )
  const CSV_DOWNLOAD_NAME = useMemo(() => `OpenSolar_${resource}_list`, [resource])
  const CSV_UPLOAD_URL = useMemo(() => `${(window as any).API_BASE_URL}orgs/${orgId}/${resource}/csv_upload/`, [
    resource,
  ])
  return (
    <div className={classes.wrapper}>
      <Button onClick={(event) => handleCsvDownload(CSV_DOWNLOAD_URL, notify, filterValues, CSV_DOWNLOAD_NAME)}>
        <Typography variant="body2" gutterBottom>
          {translate('Download')}
        </Typography>
      </Button>
      <Button onClick={handleDialogOpen}>
        <Typography variant="body2" gutterBottom>
          {translate('Upload')}
        </Typography>
      </Button>
      <CSVUploadDialog
        uploadUrl={CSV_UPLOAD_URL}
        title={csvUploadDialogTitle}
        description={csvUploadDialogDescription}
        isOpen={isDialogOpen}
        extraActions={<CSVUploadExtraActions downloadUrl={CSV_DOWNLOAD_URL} downloadName={CSV_DOWNLOAD_NAME} />}
        handleClose={handleDialogClose}
      />
    </div>
  )
}

const PaginationWithCsvActions = (props: any) => {
  return <ProUXPaginationWithActions actions={PaginationActions} {...props} />
}

export default PaginationWithCsvActions
