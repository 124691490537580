import { useCallback } from 'react'
import addComponentSetToSystem from '../../wizardUtils/addComponentSetToSystem'
import type { WizardComponentLikeType } from '../../wizardUtils/types'
import useMaybeActivateAndReload from '../../wizardUtils/useMaybeActivateAndReload'

type SelectInverterSetProps = {
  systemUuid: string
  componentsSet: WizardComponentLikeType[]
}

const clearSystemBatteries = ({ systemUuid }: { systemUuid: string }) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)
  const components = system?.batteries() || []
  var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
  components.forEach((component) => {
    window.editor.execute(new window.RemoveObjectCommand(component, true, false, commandUUID))
  })
}

const useSelectBatteryDesignAssistant = ({ onClose }: { onClose(): void }) => {
  const maybeActivateAndReload = useMaybeActivateAndReload()

  return useCallback(async ({ systemUuid, componentsSet }: SelectInverterSetProps) => {
    await maybeActivateAndReload({
      componentsSet,
    })

    clearSystemBatteries({ systemUuid })

    addComponentSetToSystem({ systemUuid, componentsSet })

    onClose()
  }, [])
}

export default useSelectBatteryDesignAssistant
