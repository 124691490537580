import { DialogHelper } from 'util/misc'
import { enableScroll } from '../Studio/Utils'

const isPathInCrmZone = function (path) {
  if (path.startsWith('/projects')) {
    return true
  } else {
    return false
  }
}

const isPathInControlZone = function (path) {
  if (
    path === '/' ||
    path.startsWith('/control') ||
    path.startsWith('/contacts') ||
    path.startsWith('/transactions') ||
    path.startsWith('/events') ||
    path.startsWith('/public_files') ||
    path.startsWith('/private_files') ||
    path.startsWith('/calendar') ||
    path.startsWith('/custom_fields') ||
    path.startsWith('/global_orgs') ||
    path.startsWith('/global_projects') ||
    path.startsWith('/global_contacts') ||
    path.startsWith('/global_roles') ||
    path.startsWith('/global_users') ||
    path.startsWith('/lead_recipients') ||
    path.startsWith('/sales_territories') ||
    path.startsWith('/external_api_keys') ||
    path.startsWith('/customer_checkout') ||
    path.startsWith('/quick-start') ||
    path.startsWith('/business-process') ||
    path.startsWith('/configure-mfa') ||
    path.startsWith('/orgs') ||
    path.startsWith('/connected_orgs') ||
    path.startsWith('/roles') ||
    path.startsWith('/permissions_role') ||
    path.startsWith('/myroles') ||
    path.startsWith('/content') ||
    // path.startsWith('/public_files') ||
    path.startsWith('/utility_tariffs') ||
    path.startsWith('/proposal_templates') ||
    path.startsWith('/lead_capture') ||
    path.startsWith('/contracts') ||
    path.startsWith('/pricing_schemes') ||
    path.startsWith('/adders') ||
    path.startsWith('/battery_schemes') ||
    path.startsWith('/payment_options') ||
    path.startsWith('/incentives') ||
    path.startsWith('/roof_types') ||
    path.startsWith('/testimonials') ||
    path.startsWith('/component_content') ||
    path.startsWith('/costs') ||
    path.startsWith('/commissions') ||
    path.startsWith('/component_module_activations') ||
    path.startsWith('/component_inverter_activations') ||
    path.startsWith('/component_battery_activations') ||
    path.startsWith('/component_other_activations') ||
    path.startsWith('/component_modules') ||
    path.startsWith('/component_inverters') ||
    path.startsWith('/component_batteries') ||
    path.startsWith('/component_others') ||
    path.startsWith('/landing_pages') ||
    path.startsWith('/project_configurations') ||
    path.startsWith('/costings') ||
    path.startsWith('/tags') ||
    path.startsWith('/utilities') ||
    path.startsWith('/distributors') ||
    path.startsWith('/manufacturers') ||
    path.startsWith('/workflows') ||
    path.startsWith('/actions') ||
    path.startsWith('/csv_upload') ||
    path.startsWith('/csv_file_upload_requests') ||
    path.startsWith('/csv_file_upload_logs') ||
    path.startsWith('/document_templates') ||
    path.startsWith('/ordering_quotes') ||
    path.startsWith('/ordering_orders') ||
    path.startsWith('/wallet_transactions') ||
    path.startsWith('/global_wallet_transactions') ||
    path.startsWith('/superuser_wallet_products') ||
    path.startsWith('/wallet') ||
    path.startsWith('/premium_products') ||
    path.startsWith('/proposal_viewers') ||
    path.startsWith('/global_custom_forms') ||
    path.startsWith('/custom_forms') ||
    path.startsWith('/finance_partners') ||
    path.startsWith('/finance_products') ||
    path.startsWith('/finance_ctas') ||
    path.startsWith('/finance_product_terms') ||
    path.startsWith('/finance_validation_sets') ||
    path.startsWith('/payment_factors') ||
    path.startsWith('/payments_configurations')
  ) {
    return true
  } else {
    return false
  }
}

export default (previousState = 'none', { type, payload }) => {
  // Imperfect code to set zone when loading directly in designer or myenergy
  // rather than defaulting incorrectly to CRM
  if (type === '@@router/LOCATION_CHANGE') {
    //prevent scrolling issue
    if (window.isScrollDisabled) {
      enableScroll()
    }
    if (window.isDialogHelperActived) {
      DialogHelper.beforeClose()
    }

    if (payload.location.pathname) {
      if (payload.location.pathname === '/') {
        return 'home'
      } else if (payload.location.pathname === '/home') {
        return 'home'
      } else if (payload.location.pathname === '/zone') {
        return 'partnerzone'
      } else if (payload.location.pathname.startsWith('/studio')) {
        return 'studio'
      } else if (payload.location.pathname.startsWith('/myenergy')) {
        return 'myenergy'
      } else if (payload.location.pathname.startsWith('/community')) {
        return 'community'
      } else if (isPathInCrmZone(payload.location.pathname)) {
        return 'crm'
      } else if (isPathInControlZone(payload.location.pathname)) {
        return 'control'
      } else if (payload.location.pathname === '/academy') {
        return 'learn'
      } else if (payload.location.pathname.startsWith('/shop')) {
        return 'shop'
      } else if (payload.location.pathname.startsWith('/manage_orders')) {
        return 'manage_orders'
      } else if (payload.location.pathname.startsWith('/inventory')) {
        return 'inventory'
      } else {
        return 'none'
      }
    }
  }
  return previousState
}
