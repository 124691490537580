import { Popover, Tooltip, makeStyles } from '@material-ui/core'
import { UnsubscribeOutlined } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import { Button } from 'opensolar-ui'
import CancelInvoiceButton from 'projectSections/sections/payments/cashFlowTransactions/invoiceActions/CancelInvoiceBtn'
import MarkPaidOfflineButton from 'projectSections/sections/payments/cashFlowTransactions/invoiceActions/MarkPaidOffine'
import SendInvoiceButton from 'projectSections/sections/payments/cashFlowTransactions/invoiceActions/SendInvoiceBtn'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import React, { useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pauseEmailReminders } from '../utils'
import { InvoiceRecord } from './types'

type PropTypes = {
  record: InvoiceRecord
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  actionRow: {
    cursor: 'pointer',
    padding: '10px',
  },
  pausedBadge: {
    color: '#BC1D1C',
    padding: '5px 7px 0px',
    background: '#F1D7D7',
    borderRadius: 15,
  },
  row: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
    alignItems: 'center',
  },
}))

const InvoiceActions: React.FC<PropTypes> = ({ record }) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const buttonRef = useRef<HTMLDivElement>(null)

  const [actionsAreShown, setActionsAreShown] = useState<boolean>(false)
  const [disableButtons, setDisableButtons] = useState<boolean>(false)

  const invoicePaymentDetails = {
    invoice: record.id,
    project: record.project.id,
    id: record.payment_request_id,
    invoice_number: record.invoice_number,
    payment_option: record.project.payment_option_id,
    payment_number: record.payment_number,
    org: record.org_id,
  }

  const refreshMilestones = () => {
    dispatch(triggerMilestoneRefresh())
  }

  const remindersAreMuted = useMemo(() => {
    return record.invoice_email_reminder_status === 'paused' ? true : false
  }, [record.invoice_email_reminder_status])

  const handleClickReminders = () => {
    if (record.id) {
      pauseEmailReminders(record.org_id, record.id, remindersAreMuted)
        .then(() => {
          refreshMilestones()
          notify(
            translate(
              `Automated email reminders for ${record.invoice_number} ${remindersAreMuted ? 'resumed' : 'stopped'}`
            ),
            'success'
          )
        })
        .catch((err) => {
          notify(err, 'warning', { autoHideDuration: undefined })
        })
        .finally(() => {
          refresh()
        })
    }
  }

  const status = record.status
  if (
    status === 'paid' ||
    status === 'paid in full' ||
    status === 'pending' ||
    status === 'partially paid' ||
    status === 'approved' ||
    status === 'completed' ||
    status === 'cancelled' ||
    status === 'draft'
  )
    return null
  return (
    <div>
      <div className={classes.row}>
        <div className={classes.reminderWrapper}>
          {record.invoice_email_reminder_status === 'paused' ? (
            <div className={classes.pausedBadge}>
              <Tooltip title="Automated email reminders currently stopped.">
                <UnsubscribeOutlined />
              </Tooltip>
            </div>
          ) : null}
        </div>
        <Button onClick={() => setActionsAreShown(true)} variant="contained" size="small">
          <div ref={buttonRef}>Actions</div>
        </Button>
      </div>
      <Popover
        open={actionsAreShown}
        anchorEl={buttonRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setActionsAreShown(false)}
      >
        {remindersAreMuted && (
          <div className={classes.actionRow}>
            <Tooltip title="Resume automated reminders. Email reminders will start from wherever they are in the reminder schedule.">
              <Button size="small" className={classes.btn} onClick={handleClickReminders}>
                Resume Automated Reminders
              </Button>
            </Tooltip>
          </div>
        )}
        {!remindersAreMuted && (
          <>
            <div className={classes.actionRow}>
              <SendInvoiceButton
                label="reminder"
                actionType="reminder"
                projectId={record?.project?.id}
                systemUuid={record?.project?.system_sold_uuid}
                paymentRequest={invoicePaymentDetails}
                disableButtons={disableButtons}
                setDisableButtons={setDisableButtons}
                setActionsAreShown={setActionsAreShown}
              />
            </div>

            <div className={classes.actionRow}>
              <Tooltip title="Turn off email reminders for customers on this project. You can turn them back on later, and they'll pick up where they left off.">
                <Button size="small" className={classes.btn} onClick={handleClickReminders}>
                  Stop Automated Reminders
                </Button>
              </Tooltip>
            </div>
          </>
        )}

        <div className={classes.actionRow}>
          <MarkPaidOfflineButton
            paymentRequest={invoicePaymentDetails}
            systemUuid={record?.project?.system_sold_uuid}
            disableButtons={disableButtons}
            setDisableButtons={setDisableButtons}
            setActionsAreShown={setActionsAreShown}
          />
        </div>
        <div className={classes.actionRow}>
          <CancelInvoiceButton
            paymentRequest={invoicePaymentDetails}
            systemUuid={record?.project?.system_sold_uuid}
            disableButtons={disableButtons}
            setDisableButtons={setDisableButtons}
            setActionsAreShown={setActionsAreShown}
          />
        </div>
      </Popover>
    </div>
  )
}

export default InvoiceActions
