import React, { useState } from 'react'
import PaymentsOnboardingWrapper from './PaymentsOnboardingWrapper'

type PropTypes = {}

const LaunchPaymentsOnboardingDialog: React.FC<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(true)

  const closeDialog = () => {
    setShowDialog(false)
  }
  return <div>{showDialog && <PaymentsOnboardingWrapper closeDialog={closeDialog} />}</div>
}
export default LaunchPaymentsOnboardingDialog
