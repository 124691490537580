import { withTheme } from '@material-ui/core'
import { getPolyglotI18nProvider, loadMessagesForLocale } from 'App.js'
import { autoLogin as autoLoginAction } from 'actions/authActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { push } from 'connected-react-router'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { authSelectors } from 'ducks/auth'
import {
  clearAvailableActions,
  clearProposalData,
  forceOpenCreditApp,
  getAvailableActions,
  myEnergySelectors,
  resetSoldOptions,
  setProposalData,
  setProposalDataLoading,
  showFullScreenLoader,
} from 'ducks/myEnergy'
import { permissionsSelectors } from 'ducks/permissions'
import { setStudioHasHighlights } from 'ducks/studio'
import { showTransactionForm } from 'ducks/transaction'
import {
  setWelcomeCustomer as setWelcomeCustomerAction,
  setWelcomeDialogOpen as setWelcomeDialogOpenAction,
} from 'ducks/welcome'
import defaultTranslations from 'i18n'
import withMediaQuery from 'layout/withMediaQuery'
import _ from 'lodash'
import BasicLayout from 'myenergy/BasicLayout'
import MyEnergyNotAvailable from 'myenergy/MyEnergyNotAvailable'
import { getCustomizedStyles, updateCssRules } from 'myenergy/customizedStyles'
import parseDataToState from 'myenergy/parseDataToState'
import prepareProposalData, { showCoverMessageDialog } from 'myenergy/prepareProposalData'
import { PureComponent, useContext, useEffect } from 'react'
import {
  DEFAULT_LOCALE,
  NotFound,
  TranslationContext,
  TranslationProvider,
  showNotification,
  withTranslate,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import compose from 'recompose/compose'
import { CTAS_THAT_REQUIRE_REFRESH } from 'resources/financeCTAs/constants'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { getDocusignAllSignedParam, getDocusignEventParam, getIsFirstOfThisEvent, saveEventKey } from 'util/docusign'
import {
  detectBespokeProposalTemplate,
  getQueryVariable,
  getRoleFromState,
  parseQueryStringToDictionary,
  reloadEntireApp,
} from 'util/misc'
import { throttled } from 'util/throttled'
import { formatSubmitValues } from '../../form/formatter'
import SectionContainer from '../SectionContainer'
import LoadingSpinner from './LoadingSpinner'
import ProposalDrawer from './ProposalDrawer'
import ProposalToolbar from './ProposalToolbar'

/*
Features implemented:

  * Auto-scroll to prevent page jump on selection change
  * Record Event
  * Google Analytics Tracking
  * Refresh on partial results
  * "Updating" state when editing data which greys out elements and closes dialogs when finished
  * Auto-Login if not logged-in and token is present in URL
  * Ignore results from obsolete ajax requests with window.Designer.AjaxSession

*/

const API_URL = window.API_ROOT + '/api'
const restClientInstance = restClient(API_URL)
const MAX_FULL_CALCS_ATTEMPTS = 30 // Approximately 90 seconds

// Store loadCountOnLastLoad on the window instead of in component state because otherwise we cannot manage reloading
// data using componentWillReceiveProps because it triggers infinite loop
window.myEnergyloadCountOnLastLoadHack = null

//////////////////////////////////////
// Start window.getAnchorElements hack
//////////////////////////////////////
window.getAnchorElements = function () {
  // get window scrollTop value
  const scrollTop = window.$(window).scrollTop()

  //find suitable anchor elements on the page
  const candidateAnchorElement = window.$.merge(
    window.$.merge(window.$('#main-content-wrapper h1'), window.$('#main-content-wrapper h2')),
    window.$.merge(window.$('#main-content-wrapper h3'), window.$('#main-content-wrapper section'))
  )

  //set initial value
  let firstAnchorElementOnPage = {}
  let firstAnchorElementToTop = 0
  let sectionNodeForAnchorElement = {}

  //remove "anchor class" for old anchor element if exist
  window.$('.mye-anchor-element')[0] && window.$('.mye-anchor-element').removeClass('mye-anchor-element')

  //add "anchor class" for the first anchor element on the page
  for (let i = 0; i < candidateAnchorElement.length; i++) {
    const elementTop = window.$(candidateAnchorElement[i]).offset().top
    const distanceToTopOfScreen = elementTop - scrollTop

    if (distanceToTopOfScreen > 0) {
      // console.log('First heading found on page: ' + candidateAnchorElement[i].textContent)
      firstAnchorElementOnPage = candidateAnchorElement[i]
      sectionNodeForAnchorElement = window.$(candidateAnchorElement[i]).parents('section')[0] || {}
      firstAnchorElementToTop = distanceToTopOfScreen
      window.$(candidateAnchorElement[i]).addClass('mye-anchor-element')
      break
    }
  }

  return {
    AnchorElementNode: firstAnchorElementOnPage,
    AnchorElementToTop: firstAnchorElementToTop,
    sectionNodeForAnchorElement,
  }
}

window.scrollWindowToSetAnchorElementToHeight = function (
  AnchorElementNode,
  AnchorElementToTop,
  sectionNodeForAnchorElement
) {
  if (Object.keys(AnchorElementNode).length === 0) {
    return
  }

  let anchorElementOnNewPage = window.$('.mye-anchor-element') || {}

  //if anchor element is missing, use sectionNodeForAnchorElement search for similar element
  if (!anchorElementOnNewPage.length && AnchorElementNode.tagName) {
    //search for element with same tag name and same text content
    anchorElementOnNewPage = window
      .$(sectionNodeForAnchorElement)
      .find(`${AnchorElementNode.tagName.toLowerCase()}:contains("${AnchorElementNode.textContent}")`)
    if (!anchorElementOnNewPage.length) {
      //search for element with same tag name
      anchorElementOnNewPage = window.$(sectionNodeForAnchorElement).find(`${AnchorElementNode.tagName.toLowerCase()}`)
      if (!anchorElementOnNewPage.length) {
        //search for same section
        anchorElementOnNewPage = window.$(sectionNodeForAnchorElement)
        if (!anchorElementOnNewPage.length) {
          console.warn('anchor Element not found as either h1/h2/h3/section, unable to adjust scroll')
          return
        }
      }
    }
  }

  var currentTopFromPage = anchorElementOnNewPage.offset().top
  var currentTopFromScreen = currentTopFromPage - window.$(window).scrollTop()
  var adjustmentRequired = currentTopFromScreen - AnchorElementToTop
  // console.log('adjustmentRequired', adjustmentRequired)
  window.scrollBy(0, adjustmentRequired)
}
//////////////////////////////////////
// End window.getAnchorElements hack
//////////////////////////////////////

const getProposalViewedEventId = (isExpired) => (isExpired ? 105 : 2)

class MyEnergyContainer extends PureComponent {
  constructor(props) {
    super(props)

    // This hack saves a bound method to the component so we can safely call it from window event listeners
    // and it ensures a persistent reference so we can remove the listener too.
    // See https://stackoverflow.com/a/33386309/1767169
    this.markTrackingActiveBound = this.markTrackingActive.bind(this)
    this.markTrackingInactiveBound = this.markTrackingInactive.bind(this)
    this.markTrackingActiveThrottled = throttled(500, this.markTrackingActive.bind(this))

    const queryParams = parseQueryStringToDictionary(this.props.location.search)

    this.state = {
      org: {},
      selectedProject: {},
      systems: [],
      selectedSystem: {},
      otherDesigns: [],
      otherAccounts: [],
      viewUuids: [],
      selectedViewUuid: null,
      selectedPaymentOptionId: null,
      loadingInitialData: true,
      waitingForAction: false,
      waitingForProposalUpdate: false,
      fullCalcReady: false,
      fullCalcsAttempts: 0,
      queryParams: queryParams,
      // Moved to window.myEnergyloadCountOnLastLoadHack
      // loadCountOnLastLoad: null,
      myeStyles: getCustomizedStyles({}),
      trackingIsActive: true,
      forceOpenApplication: false,
      paymentOptionSwapMessage: undefined,
      awaitingArchivedPmtCheck: false,
      awaitingArchivedPmtCheckMessage: undefined,
      forceCreditAppOpenPmtId: undefined,
    }

    // Used for detecting significant updates to browser size, not part of state
    // Should we debounce? We assume withLayout already debounces sufficiently
    this.lastLayout = this.props.layout
    this.currentAjaxSessionCounter = 0
  }

  getUrlAuthToken() {
    return getQueryVariable('token')
  }

  autoLogin = (url_auth_token) => {
    // The redirect path will be ignored if user isLoggedInAsAnonymousShareUser
    this.props.autoLogin({
      url_auth_token,
      path: this.props.location.pathname,
      autoLoginProject: this.props.unauthedProjectId,
    })
  }

  getErrorsForMyEnergy(_state, isPro) {
    const { translate } = this.props
    if (!_state.selectedProject || Object.keys(_state.selectedProject).length === 0) {
      return translate('No proposal was found.' + translate('Please contact your Solar Pro or Account Admin.'))
    } else if (_state.systems.length === 0 && _state.selectedProject.payment_option_sold) {
      return (
        translate('No systems are available for viewing.') +
        (isPro
          ? ' (' +
            translate(
              'A sold payment option has been selected, but that payment option was not found in any available systems.'
            ) +
            ')'
          : '')
      )
    } else if (_state.systems.length === 0) {
      return (
        translate('No systems are available for viewing.') +
        (isPro ? ' (' + translate('No systems were found.') + ')' : '')
      )
    } else if (_state.systems[0].payment_options.length === 0) {
      return (
        translate('No systems are available for viewing.') +
        (isPro ? ' (' + translate('No payment options were applied.') + ')' : '')
      )
    } else if (_state.viewUuids.length === 0) {
      translate('No views are available for viewing.') +
        (isPro ? ' (' + translate('Ensure "Show Customer" for at least one view.') + ')' : '')
    } else {
      return null
    }
  }

  isAvailable(_state) {
    if (_state.selectedProject) {
      if (this.getErrorsForMyEnergy(_state) !== null) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  componentDidMount() {
    const { calculationStatus, projectId } = this.props
    window.editor.tweenLeftMargin(0)

    const token = this.getUrlAuthToken()
    if (token) {
      this.autoLogin(token)
    } else if (this.props.authenticated === true) {
      if (projectId > 0 || projectId === 'new') {
        if (
          calculationStatus &&
          (calculationStatus.debounceQueue.length > 0 || Object.keys(calculationStatus.processQueue).length > 0)
        ) {
          // have to update window.myEnergyloadCountOnLastLoadHack otherwise componentWillReceiveProps will trigger fetchProjectData immediately
          window.myEnergyloadCountOnLastLoadHack = this.props.loadCount
          const fetchProjectDataLater = () => this.fetchProjectData(projectId)
          window.ShadeHelper?.calculateShadingBlockedAllSystemsAwaitingTrigger()
          window.Designer.functionsToCallWhenQueueEmpty?.push(fetchProjectDataLater)
        } else {
          this.fetchProjectData(projectId)
        }
      } else if (!this.props.isPro) {
        //we are a customer, load any available projects
        this.fetchProjectData()
      } else {
        //do nothing, the project selector will appear
      }
    } else {
      //not authenticated and no login token... die...
      //@TODO: Add prompt for user to login or find a link?
      this.props.push('/login')
    }

    //@TODO: Can we remove?
    //Force designer viewport to resize/reformat
    console.info('Possible optimisation: can we remove deferred call to window.Designer.onWindowResize()?')
    setTimeout(function () {
      window.Designer.onWindowResize()
    }, 1)

    var _this = this

    this.attachActivityEventListeners()
    this.setInactivityTimer()
    // Record every 10 seconds (we will also record immediately after data is loaded when loadProject completes)
    // Do not record Pros, stop recording after period of inactivity
    if (!this.props.isPro) {
      this.recordIntervalEventId = setInterval(function () {
        if (_this.state.trackingIsActive) {
          _this.recordEvent(getProposalViewedEventId(_this.state.proposalData?.isExpired), false)
        }
      }, 10 * 1000)
    }

    this.pollApplicationStatusIntervalEventId = setInterval(function () {
      // Poll for updates to loan applications here too
      // @TODO: Refactor this so we avoid possibly stacking many polling requests on top of each other
      // by adding a delay after the last request has returned, instead of just triggering them at fixed intervals
      try {
        /*
        Do not poll for updates when we have an unsaved change, otherwise we will be reloading data from the database
        instead of using or unsaved data.
        */
        const dirtyFields = (_this.props.form && _this.props.form.mutators.getFormDirtyFields()) || []
        const hasUnsavedChange = dirtyFields.length > 0

        if (
          !hasUnsavedChange &&
          _this.state.proposalData &&
          _this.state.proposalData.selectedProject &&
          _this.state.proposalData.selectedProject.available_customer_actions &&
          _this.state.trackingIsActive
        ) {
          var available_customer_actions = _this.state.proposalData.selectedProject.available_customer_actions.filter(
            (a) => a.system_uuid === _this.state.proposalData.selectedSystem.uuid
          )
          var actions = available_customer_actions[0] ? available_customer_actions[0].actions_available : []

          var refreshable_action_types = [
            'sunlight_loan_application',
            'sunlight_loan_prequal',
            'plenti_loan_application',
            'plenti_bnpl_application',
            'energy_ease_application',
            'loanpal_application',
            'brighte_application',
          ]
          const ctasThatRequireRefresh = CTAS_THAT_REQUIRE_REFRESH
          var has_refreshable_action_types =
            actions.filter(
              (action) =>
                (action.cta_type && ctasThatRequireRefresh.includes(action.cta_type)) ||
                refreshable_action_types.indexOf(action.payment_method) !== -1
            ).length > 0

          var has_unsaved_systems = actions.filter((action) => !action.system_id).length > 0

          if (has_refreshable_action_types || has_unsaved_systems) {
            // Do not rely on the response showing that an update occurred because
            // we might miss the update event or it could happen as a cron, not in reponse
            // to our refresh-status request.
            // Therefore, we need to compare the status against the available actions to determine
            // if anything has changed.

            // how to detect if something has changed on the action?
            // Inputs: available_actions, applicationStatus (for either application or prequal)

            // We cannot simply compare action.transaction_modified against application_status.transaction_modified
            // because some actions are modified by other actions (e.g. completing action 1  may activate action 2)
            // Therefore we will simply make a call to detect available actions and if the result differs from actions
            // loaded already then reload them.

            // When reloading available actions we will also check the status of any loan applications before
            // returning the results.
            // Request the email send (which will automatically record an incomplete transaction)
            _this.props.getAvailableActions(_this.state.org.id, _this.state.proposalData.selectedProject.id)
          }
        }
      } catch (error) {
        console.error('application_status check error', error)
      }
    }, 10 * 1000)

    if (this.state.queryParams.view_src === 'qr_code') {
      logAmplitudeEvent('proposal_qr_code_scanned', {
        project_id: projectId,
      })
    }
  }

  componentWillUnmount() {
    const { isNestedInProjectForm } = this.props
    //set window.translate back to app language
    window.translate = this.context.i18nProvider.translate
    window.locale = this.context.locale.replace('_', '-')

    // clear myEnergy redux store
    this.props.clearAvailableActions()

    // Stop setInterval for record event
    if (this.recordIntervalEventId) {
      clearInterval(this.recordIntervalEventId)
    }

    // Stop setInterval for poll application statuses
    if (this.pollApplicationStatusIntervalEventId) {
      clearInterval(this.pollApplicationStatusIntervalEventId)
    }

    // Stop setTimeout for calcs ready check
    if (this.checkFullCalcsReadyTimeoutId) {
      clearTimeout(this.checkFullCalcsReadyTimeoutId)
      this.checkFullCalcsReadyTimeoutId = null
    }

    //remove event listeners used to track if user is active
    this.removeActivityEventListeners()

    //remove temporary org id in local storage
    if (!this.props.isPro) this.clearTemporaryOrgIdInHelper()

    // remove reference to prevent old data from lingering in memory after leaving MyEnergy
    delete window.proposalData
    this.props.clearProposalData()

    if (!isNestedInProjectForm) {
      // window.launch no longer used in proUX 2
      window.launch({}, 'hidden')
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.getUrlAuthToken() && nextProps.isLoggedInAsAnonymousShareUser !== true) {
      // No data loading until auto-login has been processed and we have redirected back to the page without hash token
      // Except for a user who has already logged in as anon share user. THey will keep the token in the URL but should now be
      // allowed to proceed with loading after auto-login is complete.
    } else {
      if (nextProps.projectId > 0 && window.myEnergyloadCountOnLastLoadHack !== nextProps.loadCount) {
        // This allows refreshing the page simply by incrementing
        // Without this the project will not reload when projectId is changed in the URL
        // console.log('this.state.loadCountOnLastLoad !== nextProps.loadCount - reloading project')
        // console.log('window.myEnergyloadCountOnLastLoadHack !== nextProps.loadCount - reloading project')
        this.fetchProjectData(nextProps.projectId, nextProps.loadCount)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Ensure a chance of layout forces a redraw of Designer
    // Otherwise the viewport may change without the scene being redrawn
    if (this.lastLayout !== this.props.layout) {
      window.Designer.resize()
      this.lastLayout = this.props.layout
    }

    // listen to changes in store for available actions. If there are any, update state accordingly
    var existingActions = this.state.proposalData?.selectedProject?.available_customer_actions
    var newActions = this.props.refreshedAvailableCustomerActions

    if (existingActions && newActions && newActions.length > 0) {
      // Beware: This assumes the keys are in the same order
      var hasChanged = JSON.stringify(existingActions) !== JSON.stringify(newActions)

      if (hasChanged) {
        // Just change the actions in the existing loaded data
        // Deep clone proposalData first
        var proposalData = JSON.parse(JSON.stringify(this.state.proposalData))
        proposalData.selectedProject.available_customer_actions = newActions

        // Call setState to apply updated data
        this.setState({ proposalData: proposalData })
      }
    }

    //If system has changed and we have anchorData move window height to reposition at same location and clear state
    if (
      prevState.selectedSystem &&
      this.state.selectedSystem &&
      (prevState.selectedSystem.uuid !== this.state.selectedSystem.uuid ||
        prevState.selectedPaymentOptionId !== this.state.selectedPaymentOptionId)
    ) {
      //system has changed
      if (this.state.anchorData) {
        window.scrollWindowToSetAnchorElementToHeight(
          this.state.anchorData.AnchorElementNode,
          this.state.anchorData.AnchorElementToTop,
          this.state.anchorData.sectionNodeForAnchorElement
        )
        this.setState({ anchorData: undefined })
      }

      window.pdfGenerationPreference = {
        system_uuid: this.state.proposalData.selectedSystem?.uuid,
        payment_option_id: this.state.proposalData.selectedPaymentOption?.id,
        project_id: this.state.proposalData.selectedProject?.id,
      }
    }

    if (getDocusignEventParam() && getIsFirstOfThisEvent()) {
      const eventParam = getDocusignEventParam()
      const allSignedParam = getDocusignAllSignedParam()
      const { translate } = this.props
      if (eventParam === 'session_timeout') {
        window.Designer.showNotification(
          translate('Your Docusign session ended due to inactivity.') + ' ' + translate('Please try again.'),
          'warning'
        )
      } else if (eventParam === 'ttl_expired') {
        window.Designer.showNotification(
          translate('We were unable to bring you to Docusign.') + ' ' + translate('Please try again.'),
          'warning'
        )
      } else if (eventParam === 'signing_complete' && allSignedParam === 'true') {
        window.Designer.showNotification(translate('Your contract has been signed!'), 'success')
        logAmplitudeEvent('docusign_contract_signed', {})
      }
      saveEventKey()
    }

    this.props.setStudioHasHighlights(
      !!(this.state.proposalData?.proposalTemplateSettings?.highlight_rows?.split(',').length > 0)
    )

    // for debugging convenience
    window.proposalData = this.state.proposalData

    // listen for updates from redux to see if we should automatically be opening a credit application
    if (
      this.state.proposalData &&
      this.props.appHasBeenForcedOpen &&
      this.props.forceOpenAppSystemId &&
      this.props.forceOpenAppPmtId
    ) {
      const targetPmt = this.state.proposalData?.selectedSystem?.payment_options?.find(
        (pmt) => pmt.id === this.props.forceOpenAppPmtId
      )
      if (targetPmt) {
        let newProposalData = this.state.proposalData
        newProposalData.selectedPaymentOption = targetPmt
        newProposalData.selectedPaymentOptionId = targetPmt.id
        this.setState({
          selectedPaymentOptionId: this.props.forceOpenAppPmtId,
          proposalData: newProposalData,
        })
      }
    }

    if (this.state.proposalData !== prevState.proposalData) {
      this.props.setProposalData(this.state.proposalData)
    }
  }

  attachActivityEventListeners = () => this.handleEventListeners(true)

  removeActivityEventListeners = () => this.handleEventListeners(false)

  //handles both adding and removing to make sure we just have one place with these listeners

  handleEventListeners = (shouldAttach) => {
    let addOrRemove = (eventType, listenerFn) => {
      shouldAttach ? window.addEventListener(eventType, listenerFn) : window.removeEventListener(eventType, listenerFn)
    }
    //throttle the higher frequency event listeners
    addOrRemove('scroll', this.markTrackingActiveThrottled)
    addOrRemove('mousemove', this.markTrackingActiveThrottled)

    addOrRemove('click', this.markTrackingActiveBound)
    addOrRemove('keydown', this.markTrackingActiveBound)
    addOrRemove('focus', this.markTrackingActiveBound)

    //for when tab goes to background, mark as inactive
    addOrRemove('blur', this.markTrackingInactiveBound)
  }

  setInactivityTimer = () => {
    this.inactivityTimeoutId = setTimeout(() => {
      this.setState({ trackingIsActive: false })
    }, 60 * 1000)
  }

  markTrackingActive = () => {
    if (!this.state.trackingIsActive) {
      this.setState({ trackingIsActive: true })
    }
    clearTimeout(this.inactivityTimeoutId)
    this.setInactivityTimer()
  }

  markTrackingInactive = () => {
    this.setState({ trackingIsActive: false })
  }

  schedulCheckFullCalcsReady(projectId, orgId, fullResultsIdentifier, requestAjaxSession) {
    this.checkFullCalcsReadyTimeoutId = setTimeout(() => {
      // While we could use state to detect projectId and orgId, we need to explicitly pass along fullResultsIdentifier
      // as an argument
      var nextFullCalcsAttempts = this.state.fullCalcsAttempts + 1

      if (nextFullCalcsAttempts <= MAX_FULL_CALCS_ATTEMPTS) {
        this.setState({ fullCalcsAttempts: nextFullCalcsAttempts })

        this.checkFullCalcsReady.bind(this)(projectId, orgId, fullResultsIdentifier, requestAjaxSession)
      } else {
        console.warn('Attempt to load full calcs abandoned, too many attempts')
        this.setState({ fullCalcsAttempts: nextFullCalcsAttempts })
      }
    }, 3000)
  }

  checkFullCalcsReady(projectId, orgId, fullResultsIdentifier, requestAjaxSession) {
    if (this.currentAjaxSessionCounter !== requestAjaxSession) {
      console.info(
        'Notice: Ajax response ignored because currentAjaxSessionCounter is different from original request. checkFullCalcsReady() not processed.'
      )
      return false
    }

    var _this = this
    const projectIdOrNew = projectId || 'new'

    // If we have unsaved changes we will keep polling but we will not attempt to reload myenergy data
    // until the unsafed data is committed (or cleared)
    // This keeps things simple because we do not need to add/remove the polling for checkFullCalcsReady
    const dirtyFields = (this.props.form && this.props.form.mutators.getFormDirtyFields()) || []
    const hasUnsavedChange = dirtyFields.length > 0
    if (hasUnsavedChange) {
      if (fullResultsIdentifier) {
        // Attempt to load proposal data from S3
        // If not found then schedule another poll later
        // If found, then download from fullProjectData and then call this.fetchProjectData(projectIdOrNew, fullProjectData)
        // with injected data so it will not attempt to generate again

        // rest client cannot access outside URLs so I have used $.ajax instead
        // @TODO: Should we update restClientInstance to allow passing in http/https absolute urls?
        // and strip sending token to non-API urls for security protection?

        // Old/abandoned method which passed around the full S3 path and accessed S3 directly
        // window.$.ajax({
        //   type: 'GET',
        //   url: fullResultsUrl,
        //   success: function (response) {
        //     _this.fetchProjectData(projectIdOrNew, null, response)
        //   },
        //   error: function (xhr, ajaxOptions, thrownError) {
        //     // 404 is fine, just ignore and poll again
        //     if (xhr.status == 404 || xhr.status == 403 || !xhr.status) {
        //       console.log('Awaiting full results, try again soon', xhr.status)
        //       _this.schedulCheckFullCalcsReady(projectId, orgId, fullResultsUrl)
        //     } else {
        // // @TODO: anything other than 404 could be fatal, we may need to show an error?
        // console.error('Unexpected error loading full results', xhr.status)
        //     }
        //   },
        // })

        // New method which only passes the key around and uses the API to check or return the file contents
        // This avoids the need to share full S3 paths with the client and also allows compression between
        // AWS and the WebApp/Client because the API will compress payloads greater than 100KB.
        restClientInstance('CUSTOM_GET', 'custom', {
          url:
            'orgs/' +
            orgId +
            '/projects/' +
            projectIdOrNew +
            '/full_calcs_for_unsaved/?full_results_identifier=' +
            fullResultsIdentifier,
        })
          .then((response) => {
            _this.onFetchProjectFullResultSuccess(response.data, projectIdOrNew, requestAjaxSession)
          })
          .catch((error) => {
            if (error.status === 404) {
              console.log('Awaiting full results, try again soon', error.status)
              _this.schedulCheckFullCalcsReady(projectId, orgId, fullResultsIdentifier, requestAjaxSession)
            } else {
              // @TODO: anything other than 404 could be fatal, we may need to show an error?
              console.error('Unexpected error loading full results', error.status)
            }
          })
      } else {
        // No full results URL supplied, we will not poll for full results. Just display with first year
        // only until after we have saved.
      }
    } else {
      // Warning: Do not call this using setInterval because network delays could mean that multiple calls start to
      // overlap. Instead, after finishing processing, call another check with using setInterval until finished
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + '/projects/' + projectId + '/full_calcs_ready/',
      })
        .then((response) => {
          if (response.data.simulate_first_year_only === false) {
            //reload
            this.fetchProjectData(projectId, null, { showLoader: false })
          } else {
            //continue polling...
            this.schedulCheckFullCalcsReady(projectId, orgId, fullResultsIdentifier, requestAjaxSession)
          }
        })
        .catch((error) => {
          console.log('Error checking full calcs ready, cancelling polling.', error)
        })
    }
  }

  recordEvent(event_type_id = 2, forceRecording = false) {
    //this.state.trackingIsActive is used to limit recording of event_type_id 2. Docusign event types will ignore this state variable and force a record
    if (!this.state.trackingIsActive && forceRecording) return
    var projectId = this.state.selectedProject ? this.state.selectedProject.id : null
    if (projectId) {
      restClientInstance('CUSTOM_POST', 'custom', {
        url: 'orgs/' + this.state.org.id + '/projects/' + projectId + '/record_event/',
        data: { event_type_id }, //Customer Viewed Online Proposal
      }).catch((error) => {
        console.log('Event not recorded', error)
      })
    } else {
      console.warn('Skip recordEvent, missing orgId or projectId')
    }
  }

  getRestConfig = () => {
    const { form, isNestedInProjectForm } = this.props
    if (!isNestedInProjectForm) {
      return { method: 'CUSTOM_GET', data: undefined, header: {} }
    }
    const dirtyFields = form.mutators.getFormDirtyFields()
    var hasUnsavedChange = dirtyFields.length > 0
    const formValues = form.getState().values
    const requireFullCalcs = window.editor.getSystems().some((s) => s.simulateFirstYearOnly())
    const postData = formatSubmitValues(formValues, dirtyFields)

    // Always inject org_id into the post data because the API needs a way to determine which org_id this relates to
    // because we do not specify this in the URL and the project could theoretically belong to any of the orgs
    // that the user has access to
    postData['org_id'] = formValues['org_id']

    // In rare cases formValues may not have org_id populated (e.g. if skip address was used)
    // In this case we simply inject the current org_id from auth
    if (!postData['org_id']) {
      postData['org_id'] = this.props.org_id
    }

    var REST_METHOD = hasUnsavedChange ? 'CUSTOM_POST' : 'CUSTOM_GET'
    var REST_DATA = hasUnsavedChange ? postData : undefined
    var REST_HEADERS = hasUnsavedChange
      ? {
          'content-type': 'application/json;charset=UTF-8',
        }
      : {}
    return { method: REST_METHOD, data: REST_DATA, header: REST_HEADERS, requireFullCalcs: requireFullCalcs }
  }

  swapArchivedPaymentOptions = (integratedPmts, projectId, orgId) => {
    if (appStorage.has(`pmt_swap_${projectId}`)) {
      return
    }
    let paymentOptionIds = integratedPmts.map((pmt) => pmt.id)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/projects/' + projectId + '/stale_payment_options/check/',
      data: {
        payment_option_ids: paymentOptionIds,
      },
    })
      .then((pmtRes) => {
        if (pmtRes.data.is_recalculating === false) {
          this.setState({ awaitingArchivedPmtCheck: false, awaitingArchivedPmtCheckMessage: undefined })
        } else if (pmtRes.data.calcs_complete) {
          logAmplitudeEvent('archived_payment_option_replaced', {})
          if (this.props.isPro) {
            // if this is a pro just reload the page. This ensures that the project will be updated in the form. Not as smooth as this can be but it's safe and this should not be a terribly common scenario
            reloadEntireApp()
          } else {
            // but if it's the customer we can just re-fetch the project since we only need the proposal to be updated for them
            this.fetchProjectData(projectId, undefined, { skipArchivePmtsCheck: true })
          }
          appStorage.setBool(`pmt_swap_${projectId}`, true)
        }
      })
      .catch((pmtErr) => {
        console.log('pmtErr')
        this.setState({ awaitingArchivedPmtCheck: false, awaitingArchivedPmtCheckMessage: undefined })
      })
    let loadingMessage = this.props.isPro
      ? 'This project has at least one archived integrated payment option. We are checking to see if we should replace it with a new version. Thank you for your patience'
      : 'One moment while we ensure your proposal is up-to-date. Thank you for your patience'
    this.setState({
      waitingForProposalUpdate: true,
      awaitingArchivedPmtCheck: true,
      awaitingArchivedPmtCheckMessage: loadingMessage,
    })
  }

  onFetchProjectDataSuccess = (data, projectId, requestAjaxSession, skipArchivePmtsCheck = false) => {
    if (data?.length === 0) {
      throw new Error(`No project found (ID: ${projectId})`)
    }

    if (this.currentAjaxSessionCounter !== requestAjaxSession) {
      console.info(
        'Notice: Ajax response ignored because currentAjaxSessionCounter is different from original request. onFetchProjectDataSuccess() not processed.'
      )
      return false
    }

    let arhivedPmtsToSwap = []
    data.forEach((responseElement) => {
      responseElement?.projects?.forEach((project) => {
        project?.proposal_data?.systems?.forEach((sys) => {
          sys?.data?.payment_options?.forEach((pmt) => {
            if (pmt.is_archived && !!pmt?.integration) {
              arhivedPmtsToSwap.push(pmt)
            }
          })
        })
      })
    })
    if (arhivedPmtsToSwap?.length && !skipArchivePmtsCheck) {
      let pmtOwningOrgId = data[0].projects[0].proposal_data.org.id
      if (
        data[0].projects[0].proposal_data?.systems?.length &&
        data[0].projects[0].proposal_data?.systems[0]?.data?.payment_options?.length
      ) {
        pmtOwningOrgId = data[0].projects[0].proposal_data?.systems[0]?.data?.payment_options[0].org_id
      }
      this.swapArchivedPaymentOptions(arhivedPmtsToSwap, data[0].projects[0].id, pmtOwningOrgId)
    }

    try {
      data[0].projects.forEach(function (p) {
        p.proposal_data.design = window.CompressionHelper.decompress(p.proposal_data.design, true)
      })
    } catch (err) {
      console.log(err)
    }

    this.initializeTranslation(data)
    this.handleProjectData(data, projectId, skipArchivePmtsCheck, requestAjaxSession)

    if (!this.props.isPro) {
      this.refreshTitle(data)
    }
  }
  onFetchProjectFullResultSuccess = (data, projectIdOrNew, requestAjaxSession) => {
    if (this.currentAjaxSessionCounter !== requestAjaxSession) {
      console.info(
        'Notice: Ajax response ignored because currentAjaxSessionCounter is different from original request. onFetchProjectFullResultSuccess() not processed.'
      )
      return false
    }
    this.onFetchProjectDataSuccess(data, projectIdOrNew, requestAjaxSession)
    const { form, isNestedInProjectForm } = this.props
    const requireFullCalcs = window.editor.getSystems().some((s) => s.simulateFirstYearOnly())
    const dirtyFields = form?.mutators.getFormDirtyFields()
    const hasUnsavedChange = dirtyFields.length > 0
    const isProjectRequireSystemCalcs =
      isNestedInProjectForm && (form.mutators.isUnsavedProjectDataRequireSystemCalcs() || requireFullCalcs)

    if (hasUnsavedChange && isProjectRequireSystemCalcs) {
      function getSystemsFromSceneData(sceneData) {
        return sceneData['object']['children']
          .filter(function (o) {
            return o['type'] === 'OsSystem'
          })
          .map(function (s) {
            return s['userData']
          })
      }
      try {
        // Only attempt to load calcs into the current design if receiving full calcs results
        // There is no reason to try and load results from the initial load because we no longer run calcs
        // on the first load.
        const designData = data[0].projects[0].proposal_data.design

        var cmds = []
        getSystemsFromSceneData(designData).forEach(function (systemUserData) {
          var keys = [
            'id',
            'version',
            'output',
            'environmentals',
            'pricing',
            'payment_options',
            'bills',
            'consumption',
            'override_price_locking',
          ]
          var newSystemUserData = {}
          keys.forEach(function (key) {
            if (systemUserData.hasOwnProperty(key)) {
              newSystemUserData[key] = systemUserData[key]
            }
          })
          cmds.push(
            new window.UpdateSystemCalculationCommand(
              window.editor.scene.getObjectByProperty('uuid', systemUserData.uuid),
              newSystemUserData,
              'output',
              systemUserData.inverters
            )
          )
        })
        window.editor.execute(new window.MultiCmdsCommand(cmds))
        window.globalCommandUUID = window.Utils.generateCommandUUIDOrUseGlobal()

        if (isNestedInProjectForm) {
          const formValues = form.getState().values
          formValues.design = window.CompressionHelper.compress(JSON.stringify(designData))
          form.mutators.markFieldAsDirty('design')
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  fetchProjectData = (projectId, nextPropsLoadCount, { skipArchivePmtsCheck = false, showLoader = true } = {}) => {
    // Record loadCount before triggering the load to allow refresh when loadCount updates in redux
    // this.setState({ loadCountOnLastLoad: this.props.loadCount })

    // Workaround bug which sometimes MyEnergy data being loaded twice
    // If we use this.props.loadCount in response to being called by componentWillReceiveProps responding to a change
    // in nextProps.loadCount then this.props.loadCount will already be outdated.
    // Instead, we can pass through the nextProps.loadCount and use that
    window.myEnergyloadCountOnLastLoadHack = nextPropsLoadCount ? nextPropsLoadCount : this.props.loadCount

    //to ignore outdated ajax calls
    this.currentAjaxSessionCounter += 1
    const requestAjaxSession = this.currentAjaxSessionCounter
    if (showLoader) this.setState({ waitingForProposalUpdate: true })
    //set loading to true to re-render DesignerComponent so as to be able to grab window.proposalData
    this.props.setProposalDataLoading(true)
    const { method, data, header } = this.getRestConfig()

    let url = projectId ? 'user_logins/?project_ids=' + projectId : 'user_logins/'
    if (window.location.href.includes('&url_src=next_steps')) url += '&include_unsold=1'
    restClientInstance(method, 'user_logins', {
      url: url,
      data: data,
      options: {
        headers: header,
      },
    })
      .then((response) => {
        this.onFetchProjectDataSuccess(response.data, projectId, requestAjaxSession, skipArchivePmtsCheck)
      })
      .catch((error) => {
        console.log('MyEnergyContainer:GET:user_logins:error: ', error)
        this.props.showNotification(error.message, 'warning')
        this.setState({ waitingForProposalUpdate: false })
        if (error && error.status === 401) {
          window.Designer.loginPromptIfRequired(error)
        }
      })
      //stop loading after fetchData and update
      .finally(() => {
        this.props.setProposalDataLoading(false)
      })
  }

  prepareApplicationViewMode = (initialState) => {
    const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
    const params = new URLSearchParams(path.substring(path.indexOf('?')))
    if (params && params.get('view_mode') === 'application') {
      if (params.get('target_system') && params.get('target_pmt')) {
        if (initialState.proposalData) {
          const targetSystem = initialState.proposalData.systems?.find(
            (sys) => `${sys.id}` === params.get('target_system')
          )
          if (targetSystem) {
            // TO-DO make URL firnedly to homeowners, figure out why link won't open when pmt is sold
            const targetPmt = targetSystem.payment_options?.find((pmt) => `${pmt.id}` === params.get('target_pmt'))
            if (targetPmt) {
              if (!this.props.isShowingFullScreenLoader) this.props.showFullScreenLoader()
              this.props.forceOpenCreditApp(parseInt(params.get('target_system')), parseInt(params.get('target_pmt')))
              logAmplitudeEvent('application_automatically_opened', {
                payment_option_id: targetPmt?.id,
                system_id: targetSystem?.id,
                integration: targetPmt?.integation,
                source: params.get('url_src'),
              })
            } else if (!targetPmt && params.get('url_src') === 'next_steps' && params.get('target_pmt')) {
              initialState.forceCreditAppOpenPmtId = params.get('target_pmt')
            }
          }
        }
      }
    }
  }

  autoSelectProgressingPaymentOption(initialState) {
    // auto select the first progressing payment option in all systems EXCEPT any that are related to a FinanceCTA model
    // there isn't currently a reliable way to know whether a FinanceCTA-related action is new or has progressed
    const initialStatus = [null, 'NotInitiated', 'PrequalAvailable']
    const actions = (initialState.selectedProject && initialState.selectedProject.available_customer_actions) || []
    let firstProgressingAction
    for (let i = 0; i < actions.length; i++) {
      const progressingActions =
        actions[i].actions_available &&
        actions[i].actions_available.filter(
          (action) =>
            action.status_title &&
            !initialStatus.includes(action.status_title) &&
            action.payment_option_id &&
            action.system_uuid &&
            !action.cta_id
        )
      if (progressingActions && progressingActions.length > 0) {
        firstProgressingAction = progressingActions[0]
        break
      }
    }

    if (firstProgressingAction) {
      const firstProgressingActionSystem = initialState.systems.find(
        (system) => system.uuid === firstProgressingAction.system_uuid
      )
      if (firstProgressingActionSystem) {
        initialState.selectedSystem = firstProgressingActionSystem
        initialState.selectedPaymentOptionId = firstProgressingAction.payment_option_id
      }
    }
  }

  initializeTranslation = (data, projectId) => {
    //set mye language base on project language
    const projectLanguage = data?.[0].projects?.[0]?.language_override || data?.[0].projects?.[0]?.language
    const appLanguage = this.context.locale
    const initialLocale = projectLanguage || appLanguage
    loadMessagesForLocale(initialLocale)
      .then((messages) => {
        this.setState({ initialLocale, translationMessages: messages.default })
      })
      .catch((error) => {
        console.warn('getLocale error', error)
        this.setState({ initialLocale, translationMessages: defaultTranslations[DEFAULT_LOCALE] })
      })
  }

  refreshTitle(state) {
    //set page title to dealer's name (end customer only)
    const dealerName = state[0]?.name
    if (dealerName) {
      document.title = dealerName
    }
  }

  updateTemporaryOrgIdInHelper(state) {
    //in order to load 3D imagery by correct org_id
    // if (window.WorkspaceHelper) {
    //   window.WorkspaceHelper.org_id = state.org && state.org.id
    // } else {
    //   console.warn('WorkspaceHelper not ready')
    // }
    if (window.getStorage) {
      window.getStorage().setItem('org_id', state.org?.id)
    } else {
      console.warn('Unable to inject org_id, window.getStorage not available')
    }
  }

  clearTemporaryOrgIdInHelper() {
    // if (window.WorkspaceHelper) {
    //   window.WorkspaceHelper.org_id = null
    // } else {
    //   console.warn('WorkspaceHelper not ready')
    // }
    if (window.getStorage) {
      window.getStorage().removeItem('org_id')
    } else {
      console.warn('Unable to clear org_id, window.getStorage not available')
    }
  }

  isPVProSellTemplate = (proposalData) => {
    return !!proposalData?.proposalTemplateSettings?.pvprosell_template_id
  }

  handleProjectData = (data, projectId, skipArchivePmtsCheck, requestAjaxSession) => {
    const { isNestedInProjectForm } = this.props
    var initialState = {}
    try {
      initialState = {
        ...this.state,
        ...parseDataToState(data),
        loadingInitialData: false,
        waitingForProposalUpdate: false,
        proposalDataRaw: data,
      }
    } catch (err) {
      initialState = { loadingInitialData: false, waitingForProposalUpdate: false }
    }

    if (skipArchivePmtsCheck) {
      initialState.awaitingArchivedPmtCheck = false
      initialState.awaitingArchivedPmtCheckMessage = undefined
    }

    // automatically select the payment option that use has made progress on unless this is a link to directly open a particular application
    if (!window.location.href?.includes('view_mode=application')) this.autoSelectProgressingPaymentOption(initialState)

    if (!this.props.isPro) this.updateTemporaryOrgIdInHelper(initialState)

    initialState.myeStyles = getCustomizedStyles(initialState.selectedProject.proposal_data.colors)
    //ensure the selected view, system and payment option remains after full calculations are complete.
    var selectedSystemUuid = this.state.selectedSystem && this.state.selectedSystem.uuid
    var selectedPaymentOptionId = this.state.selectedPaymentOptionId
    var selectedViewUuid = this.state.selectedViewUuid
    if (selectedSystemUuid && selectedSystemUuid !== initialState.selectedSystem.uuid) {
      initialState.selectedSystem = initialState.systems.filter((system) => system.uuid === selectedSystemUuid)[0]
    }
    if (selectedPaymentOptionId && selectedPaymentOptionId !== initialState.selectedPaymentOptionId) {
      initialState.selectedPaymentOptionId = selectedPaymentOptionId
    }

    if (selectedViewUuid && selectedViewUuid !== initialState.selectedViewUuid) {
      initialState.selectedViewUuid = selectedViewUuid
    }

    initialState.proposalData = prepareProposalData(initialState)
    updateCssRules(initialState.myeStyles)

    // if it's a link to directly open an app then configure initial state so the system and pmt are selected, then start the process of opening the app
    if (window.location.href?.includes('view_mode=application')) this.prepareApplicationViewMode(initialState)

    if (this.isPVProSellTemplate(initialState.proposalData)) {
      window.setStudioMode('hidden')
    } else if (!isNestedInProjectForm) {
      window.setStudioMode('myenergy')
    }

    if (this.isAvailable(initialState)) {
      if (initialState.org && (!initialState.org.api_key_google || initialState.org.api_key_google.length === 0)) {
        initialState.org.api_key_google = this.props.api_key_google_default
      }

      window.AccountHelper.loadedData.org = initialState.org

      if (!isNestedInProjectForm) {
        const studioMode = this.isPVProSellTemplate(initialState.proposalData) ? 'hidden' : 'myenergy'
        window.launch(
          {
            id: projectId,
            selectedSystemUuid: initialState.selectedSystem.uuid,
            selectedViewUuid: initialState.selectedViewUuid,
            data: data[0].projects[0].proposal_data.design,

            // Beware: if layout changes after scene already loaded the scaling will not be updated.
            // We expect this is very rare for real use cases so not attempting to fix it.
            // Plus, fixing it could require significant computation on resize, which should be avoided
            scaleInflationFactor: this.props.layout === 1 ? 0.75 : 1.0,
          },
          studioMode
        )
      } else {
        window.editor.selectByUuid(initialState.selectedSystem?.uuid)
        window.ViewHelper.loadViewByUuid(initialState.selectedViewUuid, window.editor)
      }

      // Welcome dialog will launch automatically if terms not yet accepted. But otherwise, we must manually launch
      // if we want it to appear
      // @TODO: Is this unnecessary processing?
      if (!this.state.setWelcomeCustomerApplied && showCoverMessageDialog(initialState)) {
        this.props.setWelcomeCustomer(true)
        this.props.setWelcomeDialogOpen(true)
        // Ensure this will not trigger again when full calcs are loaded
        initialState.setWelcomeCustomerApplied = true
      }

      // If chat is enabled, update visitor details
      // Add delay to give Olark time to load (todo: add retries if unsuccessful)
      var attempts = 0
      var attemptsMax = 3
      var delay = 3000

      var updateOlarkVisitor = () => {
        // find correct contact based on current logged-in user
        // not yet supported because contact.user_id is not included in the MyEnergy data
        // var contact = initialState.selectedProject.contacts.filter(
        //   contact => contact.user_id === initialState.user.id
        // )[0]
        //
        // Alternative, assume this user is the first contact (not always true!!)
        var contact = initialState.selectedProject.contacts[0]

        if (!contact) {
          console.warn('Contact not found')
          return
        }

        if (contact.email && contact.email.length > 0) {
          window.olark('api.visitor.updateEmailAddress', {
            emailAddress: contact.email,
          })
        }

        if (contact.full_name && contact.full_name.length > 0) {
          window.olark('api.visitor.updateFullName', {
            fullName: contact.full_name,
          })
        }

        if (contact.phone && contact.phone.length > 0) {
          window.olark('api.visitor.updatePhoneNumber', {
            phoneNumber: contact.phone,
          })
        }

        // window.olark('api.chat.updateVisitorStatus', {
        //   snippet: 'has 10 items in cart',
        // })
      }

      var scheduleUpdateOlarkVisitor = () => {
        setTimeout(function () {
          if (window.olark) {
            updateOlarkVisitor()
          } else if (attempts++ < attemptsMax) {
            scheduleUpdateOlarkVisitor()
          }
        }, delay)
      }

      scheduleUpdateOlarkVisitor()
    } else {
      //Proposal is not available
      console.log('MyEnergy not available... ensure we display MyEnergyNotAvailable component')

      if (isNestedInProjectForm) {
        window.setStudioMode('hidden')
      } else {
        //isn't this doing same thing as window.launch
        window.launch(
          {
            id: null,
            selectedSystemUuid: null,
            data: {},
          },
          'hidden'
        )
      }
    }

    if (data[0] && data[0].projects && data[0].projects[0] && data[0].projects[0].simulate_first_year_only === true) {
      // console.log('simulate_first_year_only == true, poll for completion of full calcs')
      var fullResultsIdentifier = data[0].full_results_identifier
      this.checkFullCalcsReady(
        data[0].projects[0].id,
        data[0].projects[0].proposal_data.org.id,
        fullResultsIdentifier,
        requestAjaxSession
      )
    } else {
      const lastCalculationError =
        data[0] && data[0].projects && data[0].projects[0] && data[0].projects[0].last_calculation_error
      window.WorkspaceHelper?.updateLastCalcError(this.props.projectId, lastCalculationError)
    }

    this.setState(initialState)

    if (isNestedInProjectForm) {
      window.ViewHelper.loadViewByUuid(initialState.selectedViewUuid, window.editor)
    }
    // Record Event. Do not record Pros
    // Must happen after setState so we can access selectedProject
    if (!this.props.isPro) {
      this.recordEvent(getProposalViewedEventId(initialState.proposalData?.isExpired))
    }
  }

  onSelectionChange = (keyName, id) => {
    if (keyName === 'selectedSystem') {
      var system = this.state.systems.filter((system) => system.uuid === id)[0]

      // Beware: If the previously-selected payment option is not included as a payment option in the newly selected
      // system, revert to the first payment option otherwise we will attempt to select a payment option that is not
      // present in the newly-selected system.
      var paymentOptionId = system.payment_options.find((po) => po.id === this.state.selectedPaymentOptionId)
        ? this.state.selectedPaymentOptionId
        : system.payment_options[0].id

      // If bespoke then tell the iFrame to update
      if (window.$('#BespokeHtml')[0]) {
        window.$('#BespokeHtml')[0].contentWindow.postMessage(
          {
            type: 'selectSystemAndPaymentOption',
            systemUuid: system.uuid,
            paymentOptionId: paymentOptionId,
          },
          '*'
        )
      }

      let newState = Object.assign({}, this.state, {
        selectedSystem: system,
        anchorData: window.getAnchorElements(), //Record screen height for auto-scroll
        selectedPaymentOptionId: paymentOptionId,
      })
      newState.proposalData = prepareProposalData(newState)
      this.setState(newState)

      if (system) {
        window.editor.selectByUuid(system.uuid)
      }
    } else if (keyName === 'selectedPaymentOptionId') {
      // If bespoke then tell the iFrame to update
      if (window.$('#BespokeHtml')[0]) {
        window.$('#BespokeHtml')[0].contentWindow.postMessage(
          {
            type: 'selectSystemAndPaymentOption',
            systemUuid: this.state.selectedSystem.uuid,
            paymentOptionId: id,
          },
          '*'
        )
      }

      let newState = Object.assign({}, this.state, {
        selectedPaymentOptionId: id,
        anchorData: window.getAnchorElements(), //Record screen height for auto-scroll
      })
      newState.proposalData = prepareProposalData(newState)
      this.setState(newState)
    }
  }

  setSelectedViewUuid = (selectedViewUuid, newViewUuids) => {
    var newState = Object.assign({}, this.state, {
      selectedViewUuid,
      viewUuids: newViewUuids || this.state.viewUuids,
    })
    newState.proposalData = prepareProposalData(newState)
    this.setState(newState)
    window.ViewHelper.loadViewByUuid(selectedViewUuid, window.editor)
  }

  isUpdating() {
    // return this.state.loadCountOnLastLoad !== this.props.loadCount
    return window.myEnergyloadCountOnLastLoadHack !== this.props.loadCount
  }

  setWaitingForAction = (value) => {}

  render() {
    const {
      loadingInitialData,
      waitingForAction,
      waitingForProposalUpdate,
      proposalData,
      proposalDataRaw,
      awaitingArchivedPmtCheck,
      awaitingArchivedPmtCheckMessage,
    } = this.state
    const { layout, viewAsCustomer, isPro, isNestedInProjectForm } = this.props
    const isMobile = layout < 2
    // muiTheme.textField.focusColor = '#4d4d4d'
    // muiTheme.checkbox.checkedColor = '#42c311'

    const { initialLocale, translationMessages } = this.state
    const translationLoaded = initialLocale && translationMessages

    const sectionControllerMode = Boolean(proposalData?.proposalTemplateSettings?.pvprosell_template_id)
      ? 'pvsell'
      : 'myenergy'

    const { isBespoke } = detectBespokeProposalTemplate(proposalData?.proposalTemplateSettings)
    const { translate } = this.props

    return (
      <CurrencySymbolContext.Provider value={proposalData?.currencySymbol || this.props.orgCurrencySymbol}>
        <MyEnergyWrapper
          section="proposal"
          isNestedInProjectForm={isNestedInProjectForm}
          sectionControllerMode={sectionControllerMode}
        >
          <div>
            {loadingInitialData || !translationLoaded || awaitingArchivedPmtCheck ? (
              <LoadingSpinner
                style={{ opacity: 1 }}
                text={
                  awaitingArchivedPmtCheckMessage
                    ? translate(
                        'One moment while we replace archived integrated payment options with available payment options'
                      )
                    : undefined
                }
              />
            ) : (
              <TranslationProvider i18nProvider={getPolyglotI18nProvider(initialLocale, translationMessages)}>
                <div>
                  {(waitingForAction || waitingForProposalUpdate) && <LoadingSpinner />}

                  <div
                    id="mye-container"
                    style={{
                      maxWidth: isBespoke ? '100%' : 1024,
                      margin: 'auto',
                      background: 'rgb(255,255,255)',
                      opacity: this.isUpdating() ? 0.1 : 1.0,
                    }}
                  >
                    {this.isAvailable(this.state) ? (
                      <>
                        <BasicLayout
                          isMobile={isMobile}
                          isPro={isPro}
                          activeDialog={null}
                          proposalData={proposalData}
                          proposalDataRaw={proposalDataRaw}
                          setSelectedViewUuid={this.setSelectedViewUuid}
                          onPaymentMethodChange={(id) => this.onSelectionChange('selectedPaymentOptionId', id)}
                          onSystemChange={(id) => this.onSelectionChange('selectedSystem', id)}
                          layout={layout}
                          updating={this.isUpdating()}
                          setWaitingForAction={this.setWaitingForAction}
                          forceCreditAppOpenPmtId={this.state.forceCreditAppOpenPmtId}
                        />
                        {isPro && isNestedInProjectForm && !this.props.submitting && <ProposalToolbar />}
                      </>
                    ) : (
                      <MyEnergyNotAvailable
                        errorMessage={this.getErrorsForMyEnergy(this.state, this.props.isPro)}
                        org={this.state.org}
                        selectedProject={this.state.selectedProject}
                        user={this.state.user}
                        hasBottomToolbar={this.props.isPro}
                        myeStyles={this.state.myeStyles}
                      />
                    )}

                    {isPro && isNestedInProjectForm && (
                      <>
                        {!viewAsCustomer && (
                          <ProposalDrawer
                            handleChange={_.debounce(() => this.fetchProjectData(this.props.projectId), 1000)}
                            proposalData={proposalData}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </TranslationProvider>
            )}
          </div>
        </MyEnergyWrapper>
      </CurrencySymbolContext.Provider>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    //hide projectId until authenticated is true, which will trigger loading of projectId once authenticated is true
    projectId: state.auth && Boolean(state.auth.user) ? state.projectId : null,
    // auto-login code needs projectId though, so make it available
    unauthedProjectId: state.projectId,
    calculationStatus: state.designer?.systemCalculation,
    authenticated: state.auth && Boolean(state.auth.user),
    isPro: Boolean(getRoleFromState(state)),
    org_id: state.auth ? state.auth.org_id : null,
    loadCount: state.loadCount,
    viewAsCustomer: state.viewAsCustomer,
    api_key_google_default: state.auth ? state.auth.api_key_google : null,
    refreshedAvailableCustomerActions: state.myEnergy?.availableCustomerActions,
    isShowingFullScreenLoader: myEnergySelectors.getShowFullScreenLoader(state),
    appHasBeenForcedOpen: myEnergySelectors.getShouldForceOpenCreditApp(state),
    isLoggedInAsAnonymousShareUser: authSelectors.isAnonymousShareUser(state),
    forceOpenAppSystemId: myEnergySelectors.getForceOpenAppSystemId(state),
    forceOpenAppPmtId: myEnergySelectors.getForceOpenAppPmtId(state),
  }
}

const dispatchActions = {
  showNotification: showNotification,
  autoLogin: autoLoginAction,
  push: push,
  setWelcomeCustomer: setWelcomeCustomerAction,
  setWelcomeDialogOpen: setWelcomeDialogOpenAction,
  showTransactionForm: showTransactionForm,
  getAvailableActions: getAvailableActions,
  clearAvailableActions: clearAvailableActions,
  setProposalDataLoading: setProposalDataLoading,
  setStudioHasHighlights: setStudioHasHighlights,
  forceOpenCreditApp: forceOpenCreditApp,
  showFullScreenLoader: showFullScreenLoader,
  clearProposalData: clearProposalData,
  setProposalData: setProposalData,
}

MyEnergyContainer.contextType = TranslationContext

const enhance = compose(withTranslate, withMediaQuery, withTheme, connect(mapStateToProps, dispatchActions))
const EnhancedMyEnergyContainer = enhance(MyEnergyContainer)

const MyEnergyWrapper = ({ isNestedInProjectForm, sectionControllerMode, children }) => {
  return isNestedInProjectForm ? (
    <SectionContainer section="proposal" mode={sectionControllerMode}>
      {children}
    </SectionContainer>
  ) : (
    children
  )
}

export const OnlineProposal = ({ submitting }) => {
  const form = useForm()
  const dispatch = useDispatch()
  const formValues = useFormState().values
  const location = useLocation()
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('proposal'))
  const orgCurrencySymbol = useContext(CurrencySymbolContext)
  const forceSystemUuidSold = useSelector(myEnergySelectors.getSystemUuidForcedMarkedAsSold)
  const forcedSoldPmtId = useSelector(myEnergySelectors.getPmtIdForcedMarkedAsSold)
  useEffect(() => {
    if (forceSystemUuidSold) {
      const soldSystem = formValues.systems.find((x) => x.uuid === forceSystemUuidSold)
      if (soldSystem) {
        form.mutators.updateFieldSilently('system_sold', soldSystem.url)
        form.mutators.updateFieldSilently('project_sold', 2)
        if (forcedSoldPmtId) {
          const systemInfo = window.editor.getSystems()?.find((x) => x.uuid === forceSystemUuidSold)
          if (systemInfo) {
            const paymentOption = systemInfo?.payment_options?.find((x) => x.id === forcedSoldPmtId)
            if (paymentOption)
              form.mutators.updateFieldSilently(
                'payment_option_sold',
                `${window.API_BASE_URL_ABSOLUTE}orgs/${paymentOption.org_id}/payment_options/${paymentOption.id}/`
              )
          }
        }
        dispatch(resetSoldOptions())
      }
    }
  }, [forceSystemUuidSold, forcedSoldPmtId])

  if (!allowView) {
    return <NotFound />
  }
  return (
    <EnhancedMyEnergyContainer
      form={form}
      isNestedInProjectForm={true}
      location={location}
      submitting={submitting}
      orgCurrencySymbol={orgCurrencySymbol}
    />
  )
}

export const SharedMyEnergy = () => {
  const location = useLocation()
  const projectId = useSelector((state) => state.projectId)
  if (!projectId) {
    return <MyEnergyNotAvailable />
  } else {
    /*
    Shows for:
      - Customers (all)
      - Pros with (when viewing a share link)
    */
    return <EnhancedMyEnergyContainer location={location} isNestedInProjectForm={false} />
  }
}
