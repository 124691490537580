import { InfoOutlined, VisibilityOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BlueInfoCard from '../components/BlueInfoCard'
import GreyInfoCard from '../components/GreyInfoCard'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  chart: {
    height: '7rem',
  },
}))

const VerifyBusinessRight: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const orgCountry: string = useSelector(orgSelectors.getOrgIso2)?.toString() || ''

  const GOV_ID_DESCRIPTION_MAP = {
    GB:
      "A VAT number is a unique ID that HMRC provides to businesses when they register for VAT. In the UK, VAT numbers are nine digits long and always have the prefix 'GB'.<strong> Do not include the 'GB' country code in this field when submitting.<strong>",
    US: 'Your Federal Employer Identification Number (FEIN) is a unique number assigned to your business by the IRS.',
    default:
      'Your Federal Employer Identification Number (FEIN) is a unique number assigned to your business by the IRS.',
  }

  const GOV_ID_DOCUMENT_MAP = {
    GB:
      "VAT registration certificate issued by HMRC contains your VAT number and it's included on VAT returns. If you're unable to find it, check your accounting software or you can contact HMRC for assistance.",
    US: 'Your business legal name and FEIN are in the header of your IRS Form CP-575',
    default: 'Your business legal name and FEIN are in the header of your IRS Form CP-575',
  }

  const govIdDescription = useMemo(() => {
    return GOV_ID_DESCRIPTION_MAP[orgCountry] || GOV_ID_DESCRIPTION_MAP.default
  }, [orgCountry])

  const govIdDocument = useMemo(() => {
    return GOV_ID_DOCUMENT_MAP[orgCountry] || GOV_ID_DOCUMENT_MAP.default
  }, [orgCountry])

  const infoContent = [
    {
      id: 1,
      icon: <InfoOutlined />,
      header: 'What Business Tax ID is required?',
      description: govIdDescription,
    },
    {
      id: 2,
      icon: <VisibilityOutlined />,
      header: 'Where can I find this information?',
      description: govIdDocument,
    },
  ]

  return (
    <div>
      <BlueInfoCard
        header={'Solar businesses can save up to 20% on credit card transactions with CashFlow'}
        description={'Compared to consumer point of sale transaction fees'}
      >
        <img src={`${window.PUBLIC_URL}/images/cashflow/pie-chart.svg`} className={classes.chart} />
      </BlueInfoCard>

      <GreyInfoCard content={infoContent}>
        {orgCountry !== 'GB' && <img src={`${window.PUBLIC_URL}/images/cashflow/IRS Form.svg`} />}
      </GreyInfoCard>
    </div>
  )
}
export default VerifyBusinessRight
