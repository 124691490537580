import Chip from 'elements/chip/Chip'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { MountingID, mountingSystemDisplayInfo } from 'types/mounting'

export const mountingSystems: mountingSystemDisplayInfo[] = [
  { displayName: 'Viridian', mountingId: 'viridian', imageFile: 'viridian_logo.jpg', automated: true },
  { displayName: 'SolarRoof Pro', mountingId: 'solarRoofPro', imageFile: 'clenergy_logo.png', automated: true },
  { displayName: 'Renusol Configurator', mountingId: 'renusol', imageFile: 'renusol_logo.jpg' },
  { displayName: 'Renusol VS+', mountingId: 'variosole', imageFile: 'renusol_logo.jpg', automated: true },
  { displayName: 'Esdec ClickFit EVO', mountingId: 'esdec', imageFile: 'esdec_logo.png', automated: true },
  { displayName: 'Esdec FlatFix Fusion', mountingId: 'flatFix', imageFile: 'esdec_logo.png', automated: true },
  { displayName: 'Renusol MS+, MS+P', mountingId: 'metasole', imageFile: 'renusol_logo.jpg', automated: true },
  { displayName: 'Esdec', mountingId: 'esdec', imageFile: 'esdec_logo.png' },
  { displayName: 'Schletter', mountingId: 'schletter', imageFile: 'schletter_logo.png', automated: true },
  { displayName: 'Fastensol', mountingId: 'fastensol', imageFile: 'fastensol_logo.png', automated: true },
  {
    displayName: 'MS Pitched ProLine',
    mountingId: 'msPitchedProLine',
    imageFile: 'ms-pitched-pro-line-logo.svg',
    automated: true,
  },
  {
    displayName: 'MS Trapeze ProLine',
    mountingId: 'msTrapezeProLine',
    imageFile: 'ms-pitched-pro-line-logo.svg',
    automated: true,
  },
]

const useStyles = makeOpenSolarStyles((theme) => ({
  menuItemWrapper: ({ clickable }: { clickable: boolean }) => ({
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    alignItems: 'center',
    columnGap: '15px',
    height: '40px',
    border: theme.border.default,
    borderRadius: '4px',
    margin: '10px 0',
    padding: '5px 0',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: clickable ? 'pointer' : 'default',
      background: clickable ? theme.greyLight1 : 'inherit',
    },
  }),
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '25%',
    paddingLeft: 5,
  },
  image: {
    height: 'fit-content',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  label: {
    width: '75%',
    whiteSpace: 'pre-wrap',
    lineHeight: 'normal',
    paddingRight: 5,
  },
  chip: {
    cursor: 'inherit',
  },
}))

const MountingSystemCapsule = ({ mountingId, onClick }: { mountingId: MountingID; onClick?(): void }) => {
  const translate = useTranslate()
  const classes = useStyles({ clickable: !!onClick })
  const selectedMountingSystemConfig = useMemo(
    () => mountingSystems.find((config) => config.mountingId === mountingId),
    [mountingId]
  )
  if (selectedMountingSystemConfig === undefined) return null
  return (
    <div className={classes.menuItemWrapper} onClick={onClick}>
      <div className={classes.imageWrapper}>
        <img src={`${window.PUBLIC_URL}/images/${selectedMountingSystemConfig.imageFile}`} className={classes.image} />
      </div>
      <div className={classes.label}>
        <div>{selectedMountingSystemConfig.displayName}</div>
        {selectedMountingSystemConfig.automated && (
          <Chip className={classes.chip} label={translate('Automated')} size="small" variant="info" />
        )}
      </div>
    </div>
  )
}

export default MountingSystemCapsule
