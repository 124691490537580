import { memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import VtacConnectAccountAlert from './VtacConnectAccountAlert'
import VtacDisclaimer from './VtacDisclaimer'
import VtacPlaceOrderButton from './VtacPlaceOrderButton'
import OrderSummary from './OrderSummary'

const useSideActionStyles = makeOpenSolarStyles((theme) => ({
  sectionWrapper: {
    padding: 15,
    marginBottom: 15,
  },
  container: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    marginBottom: 15,
  },
  actionWrapper: {
    borderRadius: '0 0 8px 8px',
    background: theme.greyLight3,
    padding: 15,
  },
  button: {
    textAlign: 'center',
  },
}))

const PlaceOrderSideAction = ({ handlePlaceOrder }: { handlePlaceOrder(): void }) => {
  const classes = useSideActionStyles()
  const currencySymbol = '£'

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.sectionWrapper}>
          <OrderSummary currencySymbol={currencySymbol} />
        </div>
        <div className={classes.actionWrapper}>
          <div className={classes.button}>
            {<VtacPlaceOrderButton onClick={handlePlaceOrder} trackSource="side_action" />}
          </div>
          <VtacDisclaimer />
        </div>
      </div>
      <VtacConnectAccountAlert />
    </div>
  )
}

export default memo(PlaceOrderSideAction)
