import ActionsIconButton from 'elements/button/ActionsIconButton'
import { BankNoteOutlineIcon, DataOutlineIcon, LayersIcon, LightningIcon, SendOutlineIcon, styled } from 'opensolar-ui'
import ProjectDeleteButton from 'projectSections/sections/manage3/details/elements/ProjectDeleteButton'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { ProjectType } from 'types/projects'
import { ProjectDuplicateButton } from './ProjectDuplicateButton'
import ProjectLinkButton from './ProjectLinkButton'

const MenuSubtitle = styled('span')({
  color: COLOR_PALETTE.iconColor,
  fontWeight: 500,
  textTransform: 'uppercase',
})

const MenuWrapper = styled('div')({
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
})

const Divider = styled('div')({
  borderTop: '1px solid ' + COLOR_PALETTE.lightGrey,
  width: '100%',
  marginBottom: 10,
})

interface PropTypes {
  disabled?: boolean
  project: ProjectType
}

const ProjectActionsButton: React.FC<PropTypes> = ({ disabled, project }) => {
  const translate = useTranslate()
  return (
    <ActionsIconButton disabled={disabled || false} btnProps={{ variant: 'rounded', color: 'default' }}>
      <>
        <MenuWrapper>
          <MenuSubtitle>{translate('Go To')}</MenuSubtitle>
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/manage`}
            label={'Manage'}
            startIcon={<DataOutlineIcon variant={2} color={COLOR_PALETTE.iconColor} />}
            permissionToCheck={'manage'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/energy`}
            label={'Energy'}
            startIcon={<LightningIcon color={COLOR_PALETTE.iconColor} />}
            permissionToCheck={'project'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/design`}
            label={'Design'}
            startIcon={<LayersIcon color={COLOR_PALETTE.iconColor} />}
            permissionToCheck={'design'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/payments`}
            label={'Payments'}
            startIcon={<BankNoteOutlineIcon color={COLOR_PALETTE.iconColor} />}
            permissionToCheck={'project'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/proposal`}
            label={'Proposal'}
            startIcon={<SendOutlineIcon variant={1} color={COLOR_PALETTE.iconColor} />}
            permissionToCheck={'proposal'}
          />
        </MenuWrapper>
        <Divider />
        <MenuWrapper>
          <MenuSubtitle>{translate('Actions')}</MenuSubtitle>
          <ProjectDuplicateButton projectId={project.id} />
          <ProjectDeleteButton project={project} btnType={'list'} />
        </MenuWrapper>
      </>
    </ActionsIconButton>
  )
}

export default ProjectActionsButton
