import { makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import TinyBox from 'elements/TinyBox'
import CustomField from 'elements/field/CustomField'
import CheckboxInput from 'elements/input/CheckboxInput'
import React from 'react'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& .MuiFormControlLabel-root': {
      marginRight: '0 !important',
    },
  },
  fieldLabel: { margin: 0 },
  subLabel: {
    fontSize: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 300,
    margin: 0,
  },
}))

interface PropTypes {
  label: string
  subLabel?: string
  source: string
  checked: boolean
  onChange: (e) => void
}

export const ProjectToggleField: React.FC<PropTypes> = ({ label, source, subLabel, checked, onChange }) => {
  const classes = useStyles()
  return (
    <TinyBox>
      <div
        className={classes.row}
        onClick={() => {
          onChange(!checked)
        }}
      >
        <CustomField
          component={() => (
            <CheckboxInput
              input={{
                value: checked,
                onChange,
              }}
            />
          )}
          name={source}
          source={source}
          label=""
        />
        <div>
          <p className={classes.fieldLabel}>{label}</p>
          <p className={classes.subLabel}>{subLabel}</p>
        </div>
      </div>
    </TinyBox>
  )
}
