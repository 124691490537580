import { makeStyles as makeStyles5 } from '@mui/styles';
/**
 * This helper is deprectated and must be removed before we can transition to React 18.
 * It will be useful in the meantime to allow us to upgrade existing components/pages to MUI5 / OS3.0.
 *
 * For new components/pages, please instead use the `styled` helper from `opensolar-ui`.
 *
 * @deprecated
 */
export var makeStyles = function (styles, options) {
    return makeStyles5(styles, options);
};
