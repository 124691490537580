import { makeStyles } from '@material-ui/core/styles'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import getRenderFunction from 'customForm/util/render'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { FormPlacementEnum } from 'resources/customForms/types'
import { Theme } from 'types/themes'
import DeleteButton from '../../../common/DeleteButton'
import { InputWrapper } from '../../../styles'
import { ContactMoreDetailsSection } from './ContactMoreDetailsSection'
import ContactStandardFields from './ContactStandardFields'
import { CustomFormExpandSection } from './CustomFormExpandSection'

const useStyles = makeStyles((theme: Theme) => ({
  contactWrapper: {
    padding: '0 13px',
  },
  buttonClass: {
    justifyContent: 'flex-start !important',
  },
}))

interface PropTypes {
  source: string
  handleDeleteContact: (id: number) => void
}

const getContactFormSections = (source) => {
  return [
    {
      component: ContactStandardFields,
      shouldRender: true,
      priority: 10,
      props: { source },
    },
    {
      component: ContactMoreDetailsSection,
      shouldRender: true,
      priority: 20,
      props: { source },
    },
  ]
}

const addFormsAndSort = getRenderFunction(CustomFormExpandSection)
const ContactInput: React.FC<PropTypes> = ({ source, handleDeleteContact }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const contact = useMemo(() => {
    const index = source?.split('.')[1]
    return formValues?.contacts_data?.[index]
  }, [source, formValues.contacts_data])
  const customForms = useGetCustomForms({ location: FormPlacementEnum.info_page_contact, isCreateForm: !contact?.id })
  const sortAndRenderComponents = useMemo(() => {
    return addFormsAndSort(getContactFormSections(source), customForms)
  }, [customForms])

  return (
    <InputWrapper className={classes.contactWrapper}>
      {sortAndRenderComponents}
      {contact?.id && (
        <DeleteButton
          onSuccess={() => {
            handleDeleteContact(contact.id)
          }}
          btnLabel={<>{translate('Delete Contact')}</>}
          title={'contact'}
          confirmMode="dialog"
          resource={'contacts'}
          record={contact}
          btnProps={{ startIcon: <DeleteOutlineOutlined />, className: classes.buttonClass }}
        />
      )}
    </InputWrapper>
  )
}

export default ContactInput
