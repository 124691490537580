import { Grid, Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { projectMilestonesSelectors } from 'ducks/projectMilestones'
import { GetExpectedMilestonesResponseType, PaymentRequestType } from 'pages/cashFlow/types'
import { getExpectedMilestones } from 'pages/cashFlow/utils'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { StudioSystemType } from 'types/global'
import { ExpectedMilestonePaymentType, PaymentOptionDataType } from 'types/paymentOptions'
import { urlToId } from 'util/misc'
import MilestoneTimeline from './MilestoneTimeline'
import PaymentsPieChart from './PaymentsPieChart'
import { useIsCalculating } from './utils'

type PropTypes = {}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    justifyContent: 'space-between',
    alignItems: ({ isMobile }) => (isMobile ? 'flex-start' : 'center'),
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.greyMid1,
    fontSize: '30px',
  },
  cashFlowImg: {
    height: '2.5rem',
  },
  cashFlowText: {
    color: theme.greyMid1,
  },
  soldPmt: {
    color: theme.greyDark2,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}))

const ProjectCashFlowView: React.FC<PropTypes> = (props) => {
  const project = useFormState().values
  const soldSystemURL = project?.system_sold
  const soldPmtURL = project?.payment_option_sold
  const [selectedSystem, setSelectedSystem] = useState<StudioSystemType | undefined>(undefined)
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOptionDataType | undefined>(undefined)
  const [milestones, setMilestones] = useState<ExpectedMilestonePaymentType[]>([])
  const [adHocPaymentRequests, setAdHocPaymentRequests] = useState<PaymentRequestType[]>([])
  const [totalPaid, setTotalPaid] = useState<number | undefined>(undefined)
  const [totalPayable, setTotalPayable] = useState<number | undefined>(undefined)
  const [refreshOnCalcsComplete, setRefreshOnCalcsComplete] = useState<boolean>(false)

  // means we are fetching the initial data to populate the child components
  const [isFetchingMilestones, setIsFetchingMilestones] = useState<boolean>(false)

  const isCalculating = useIsCalculating(selectedSystem?.uuid)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()
  const milestoneRefreshTrigger = useSelector(projectMilestonesSelectors.getRefreshMilestonesTrigger)

  useEffect(() => {
    if (soldSystemURL && soldPmtURL) {
      const allSystemsOnProject = window.editor.getSystems()
      const soldSystem = project?.systems?.find((sys) => sys.url === soldSystemURL)
      setSelectedSystem(soldSystem)
      if (soldSystem) {
        const soldPmtId = urlToId(soldPmtURL)
        const soldPaymentOption = allSystemsOnProject
          ?.find((sys) => sys.uuid === soldSystem.uuid)
          ?.payment_options?.find((pmt) => pmt.id === soldPmtId)
        setSelectedPaymentOption(soldPaymentOption)
      }
    } else {
      if (selectedSystem && !soldSystemURL) setSelectedSystem(undefined)
      if (selectedPaymentOption && !soldPmtURL) setSelectedPaymentOption(undefined)
    }
  }, [soldSystemURL, soldPmtURL])

  useEffect(() => {
    if (!refreshOnCalcsComplete && isCalculating) setRefreshOnCalcsComplete(true)
    else if (refreshOnCalcsComplete && !isCalculating) {
      refreshMilestones()
      setRefreshOnCalcsComplete(false)
    }
  }, [isCalculating])

  useEffect(() => {
    if (milestoneRefreshTrigger) refreshMilestones()
  }, [milestoneRefreshTrigger])

  const refreshMilestones = useCallback(() => {
    if (selectedPaymentOption?.org_id && project?.id && selectedSystem?.uuid && selectedPaymentOption?.id) {
      const system = window.editor.getSystems()?.find((sys) => sys.uuid === selectedSystem?.uuid)
      const paymentOption = system?.payment_options?.find((pmt) => pmt.id === selectedPaymentOption?.id)
      getExpectedMilestones(
        selectedPaymentOption?.org_id,
        project.id,
        selectedSystem.uuid,
        selectedPaymentOption.id,
        system?.milestone_payment_overrides?.[selectedPaymentOption.id],
        paymentOption?.pricing?.system_price_payable
      )
        .then((res: GetExpectedMilestonesResponseType) => {
          setMilestones(res.data.expected_milestone_payments)
          setAdHocPaymentRequests(res.data.ad_hoc_payment_requests)
          setTotalPaid(res.data.total_amount_paid)
          setTotalPayable(res.data.total_amount_payable)
        })
        .catch((err) => {
          console.log('fetch err', err)
        })
        .finally(() => {
          setIsFetchingMilestones(false)
        })
    }
  }, [selectedPaymentOption, selectedSystem])

  // get up-to-date milestones whenever the selected payment option or selected system are updated
  useEffect(() => {
    setIsFetchingMilestones(true)
    refreshMilestones()
  }, [selectedPaymentOption, selectedSystem])

  if (!selectedPaymentOption || !selectedSystem) return null
  return (
    <div>
      {selectedPaymentOption && (
        <div className={classes.soldPmt}>
          <strong>{translate('Sold Payment Option')}:</strong> {selectedPaymentOption?.title}
        </div>
      )}
      <Grid container spacing={0} className={classes.container}>
        {!isMobile && (
          <Grid item xs={1}>
            <div className={classes.iconWrapper}>
              <img src={`${window.PUBLIC_URL}/images/cashflow/cash-icon.svg`} className={classes.cashFlowImg} />
              <span className={classes.cashFlowText}>{translate('CashFlow')}</span>
            </div>
          </Grid>
        )}
        <Grid item sm={12} md={9} className={classes.centered}>
          <MilestoneTimeline
            paymentOption={selectedPaymentOption}
            system={selectedSystem}
            milestones={milestones}
            refreshMilestones={refreshMilestones}
            adHocPaymentRequests={adHocPaymentRequests}
            isFetchingMilestones={isFetchingMilestones}
            projectId={project?.id}
          />
        </Grid>
        <Grid item sm={12} md={2} className={classes.centered}>
          {totalPayable && <PaymentsPieChart totalPaid={totalPaid || 0} totalPayable={totalPayable} />}
        </Grid>
      </Grid>
    </div>
  )
}
export default ProjectCashFlowView
