import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { getCountryDefaultSupplier, getCountrySuppliers } from 'pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSegenAuthenticationStatus from 'services/segen/useSegenAuthenticationStatus'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { ExtendedHardwareSupplierConfig } from '../DistributorChip'

type GetDistributorConnectionStatusAndConfigResponse = {
  isChecking: boolean
  atLeastOneDistributorEnabled: boolean
  atLeastOneDistributorConnected: boolean
  enabledAndConnectedDistributor: HardwareSupplierFilterKeyType | undefined
  distributorConfigs: ExtendedHardwareSupplierConfig[]
}

const useGetDistributorConnectionStatusAndConfig = (
  selectedSupplier
): GetDistributorConnectionStatusAndConfigResponse => {
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry)
  const countryDefaultSupplier = getCountryDefaultSupplier(orgCountry || '')
  const countrySuppliers = getCountrySuppliers(orgCountry || '')
  const segenEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_segen
  const outletEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_outlet
  const cityPlumbingEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_city_plumbing
  const hdmEnabled = window.reduxStore?.getState()?.orgs?.org?.enable_hdm
  const segenConnectStatus = useSegenAuthenticationStatus()
  const cityPlumbingConnectStatus = useDistributorAuthenticationStatus({ orgId, distributor: 'city_plumbing' })
  const hdmConnectStatus = useDistributorAuthenticationStatus({ orgId, distributor: 'hdm' })
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)

  const distributorConnectionStatus = useMemo(() => {
    const status = {
      segen: { isAuthenticated: segenConnectStatus === 'authenticated', isEnabled: segenEnabled },
      city_plumbing: { isAuthenticated: cityPlumbingConnectStatus.isAuthenticated, isEnabled: cityPlumbingEnabled },
      hdm: { isAuthenticated: hdmConnectStatus.isAuthenticated, isEnabled: hdmEnabled },
    }

    return status
  }, [
    segenConnectStatus,
    cityPlumbingConnectStatus.isAuthenticated,
    hdmConnectStatus.isAuthenticated,
    segenEnabled,
    cityPlumbingEnabled,
    hdmEnabled,
  ])

  const atLeastOneDistributorEnabled = useMemo(() => {
    return (outletEnabled || cityPlumbingEnabled || hdmEnabled || segenEnabled) && enabledDistributors.length > 0
  }, [outletEnabled, segenEnabled, cityPlumbingEnabled, hdmEnabled, enabledDistributors.length])

  const atLeastOneDistributorConnected = useMemo(() => {
    return (
      (outletEnabled ||
        (cityPlumbingConnectStatus.isAuthenticated && cityPlumbingEnabled) ||
        (hdmConnectStatus.isAuthenticated && hdmEnabled) ||
        (segenConnectStatus === 'authenticated' && segenEnabled)) &&
      enabledDistributors.length > 0
    )
  }, [outletEnabled, distributorConnectionStatus, enabledDistributors.length])

  const enabledAndConnectedDistributor = useMemo(() => {
    if (orgCountry === 'AU') return countryDefaultSupplier?.filterKey

    for (const [key, { isAuthenticated, isEnabled }] of Object.entries(distributorConnectionStatus)) {
      if (isAuthenticated && isEnabled) {
        return key
      }
    }

    return undefined
  }, [distributorConnectionStatus])

  const getEnabledStatus = (filterKey) => {
    switch (filterKey) {
      case 'segen':
        return segenEnabled
      case 'city_plumbing':
        return cityPlumbingEnabled
      case 'hdm':
        return hdmEnabled
      case 'outlet':
        return outletEnabled
      default:
        return false
    }
  }

  const getConnectionStatus = (filterKey) => {
    switch (filterKey) {
      case 'segen':
        return segenConnectStatus === 'authenticated'
      case 'city_plumbing':
        return cityPlumbingConnectStatus.isAuthenticated
      case 'hdm':
        return hdmConnectStatus.isAuthenticated
      case 'outlet':
        return outletEnabled
      default:
        return false
    }
  }

  const distributorConfigs = useMemo(() => {
    return countrySuppliers?.map((config) => ({
      ...config,
      isEnabled: getEnabledStatus(config.filterKey),
      isConnected: getConnectionStatus(config.filterKey),
      isSelected: selectedSupplier === config.filterKey,
    }))
  }, [distributorConnectionStatus, selectedSupplier])

  return {
    isChecking:
      segenConnectStatus === 'processing' && cityPlumbingConnectStatus.isChecking && hdmConnectStatus.isChecking,
    atLeastOneDistributorEnabled,
    atLeastOneDistributorConnected,
    enabledAndConnectedDistributor: enabledAndConnectedDistributor as HardwareSupplierFilterKeyType | undefined,
    distributorConfigs: distributorConfigs || [],
  }
}

export default useGetDistributorConnectionStatusAndConfig
