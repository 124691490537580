import { MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Search } from '@material-ui/icons'
import { Button, Select, styled } from 'opensolar-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { RoleType } from 'types/roles'
import { Theme } from 'types/themes'

export const PROJECT_ROLES = [
  { title: 'Assigned Team Member', id: 'assigned_role' },
  { title: 'Installer', id: 'assigned_installer_role' },
  { title: 'Designer', id: 'assigned_designer_role' },
  { title: 'Site Inspector', id: 'assigned_site_inspector_role' },
  { title: 'Salesperson', id: 'assigned_salesperson_role' },
]

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 30,
  gap: 10,
})

const InputWrapper = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
})

const useStyles = makeStyles((theme: Theme) => ({
  assignInput: {
    '& .OSUI-OutlinedInput-notchedOutline': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  roleInput: {
    '& .OSUI-OutlinedInput-notchedOutline': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}))
interface PropTypes {
  roleChoices: { title: string; id: string }[]
  assignedTeamIds: number[]
}

const TeamMemberInput: React.FC<PropTypes> = ({ roleChoices, assignedTeamIds }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const [newTeamAssignment, setNewTeam] = useState<RoleType | undefined>(undefined)
  const [newRoleAssignment, setNewRole] = useState<string | undefined>(
    !!roleChoices?.length ? roleChoices[0]?.id : undefined
  )

  const handleAssignment = () => {
    if (newRoleAssignment && newTeamAssignment) {
      form.change('internalTeam', [
        ...formValues.internalTeam,
        { ...newTeamAssignment, projectRoleId: newRoleAssignment },
      ])
      form.change('selected_member', undefined)
      setNewTeam(undefined)
      const filteredRoles = roleChoices.filter((x) => x.id !== newRoleAssignment)
      if (!!filteredRoles.length) {
        setNewRole(filteredRoles[0].id)
      }
    }
  }

  useEffect(() => {
    if (!newRoleAssignment || (!!roleChoices.length && !roleChoices?.map((x) => x.id)?.includes(newRoleAssignment))) {
      setNewRole(roleChoices[0]?.id)
    }
  }, [newRoleAssignment, roleChoices])

  return (
    <Wrapper>
      <InputWrapper>
        <ReferenceInput
          label={''}
          allowEmpty
          variant="outlined"
          className={classes.assignInput}
          source={'selected_member'}
          reference="roles___aliases"
          resource="roles"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <CustomAutoCompleteInput
            source={'selected_member'}
            optionValue="url"
            optionText="display"
            options={{
              containerStyle: { width: '100%' },
              InputProps: {
                startAdornment: <Search />,
                placeholder: translate('Add a team member'),
                fullWidth: true,
                label: false,

                style: { margin: 0 },
              },
            }}
            onChange={(value) => {
              setNewTeam(value)
            }}
            assignedTeamIds={assignedTeamIds}
          />
        </ReferenceInput>
        <Select
          value={newRoleAssignment}
          className={classes.roleInput}
          variant="outlined"
          onChange={(e: any) => {
            setNewRole(e.target.value)
          }}
        >
          {roleChoices?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </InputWrapper>

      <Button
        disabled={!newTeamAssignment || !newRoleAssignment}
        variant="contained"
        color="primary"
        onClick={handleAssignment}
      >
        {translate('Assign')}
      </Button>
    </Wrapper>
  )
}
const CustomAutoCompleteInput = (props) => {
  const { choices, assignedTeamIds, input, onChange, optionValue } = props
  const newChoices = useMemo(() => choices.filter((x) => !assignedTeamIds.includes(x.id)), [choices, assignedTeamIds])
  const handleChange = (value) => {
    input.onChange(value)
    if (onChange) {
      const findItem = choices.find((item) => (optionValue ? item[optionValue] : item.url) === value)
      if (findItem) onChange(findItem)
    }
  }
  const inputProps = useMemo(() => ({ ...input, onChange: handleChange }), [input])
  return <AutocompleteInput {...props} choices={newChoices} input={inputProps} />
}

export default TeamMemberInput
