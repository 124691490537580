import { makeStyles } from '@material-ui/core'
import { HomeIcon } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { Link } from 'react-router-dom'
import { NORMAL_CONTACT } from 'types/contacts'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: COLOR_PALETTE.iconColor,
    '& a': {
      color: COLOR_PALETTE.iconColor,
    },
  },
  divide: {
    margin: '0 5px',
  },
  iconMargin: {
    marginLeft: 5,
  },
  mobile: {
    fontWeight: 500,
    color: COLOR_PALETTE.black,
  },
})

interface PropTypes {
  mobile?: boolean
}

const Summary: React.FC<PropTypes> = ({ mobile }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const firstContact = useMemo(
    () => (project?.contacts_data ? project.contacts_data?.find((c) => c?.type === NORMAL_CONTACT) : null),
    [project.contacts_data]
  )
  const display = useMemo(() => {
    let name = project.is_residential && project.business_name ? project.business_name : firstContact && firstContact?.display
        return [name, project.address].filter(v => !!v).join(", ");
  }, [project.is_residential, project.business_name, project.address, firstContact])
  return (
    <div className={classes.wrapper}>
      {mobile ? (
        <span className={classes.mobile}>{display}</span>
      ) : (
        <>
          <Link to="/projects">{translate('Projects')}</Link>
          <span className={classes.divide}>{' / '}</span>
          <HomeIcon color={COLOR_PALETTE.iconColor} size={12} />
          <span className={classes.iconMargin}>{display}</span>
        </>
      )}
    </div>
  )
}

export default Summary
