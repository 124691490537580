import { Tooltip } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import { useContext } from 'react'
import { previewInfo } from '../../../common/utils'
import { FilesContext } from '../../ProjectFilesList'

export const SelectTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& img': {
    marginRight: 10,
  },
  '& p': {
    margin: 0,
  },
}))

export const SelectTitleField = ({ record, editTooltip, allowEdit }) => {
  const { fileType } = previewInfo(record)
  const { selectActiveInlineEdit } = useContext(FilesContext)

  return (
    <Tooltip title={editTooltip} placement={'bottom-start'}>
      <SelectTitle>
        <img src={`${window.PUBLIC_URL}/images/doc_icons/${fileType}.svg`} alt={`${fileType} icon`} />
        <p
          onClick={() => {
            if (allowEdit) {
              selectActiveInlineEdit({ record, fieldName: 'title' })
            }
          }}
        >
          {record.title}
        </p>
      </SelectTitle>
    </Tooltip>
  )
}
