import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Theme,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { autoLogin } from 'actions/authActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import LoadingDots from 'layout/widgets/LoadingDots'
import { PaymentRequestStatusType } from 'pages/cashFlow/types'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import PaymentAlreadyComplete from './PaymentAlreadyComplete'
import PaymentForm from './PaymentForm'
import PaymentSuccesful from './PaymentSuccessful'
import PaymentSummary from './PaymentSummary'
import PaymentsPageHeader from './PaymentsPageHeader'
import TransferInstructions from './localBankTransfers/TransferInstructions'
import { ACHPaymentDataType, BlueSnapCardDataType, LocalBankTransferDataType, PaymentExtraFields } from './types'
import { submitPayment, useGetPaymentRequestData, useGetPaymentSummaryData, useLoadBlueSnapPaymentsSDK } from './utils'

type PropTypes = {
  match: {
    params: {
      paymentRequestId: string
      projectId: string
    }
  }
  isIntegratedCheckout?: boolean
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean; isIntegratedCheckout: boolean }>((theme) => ({
  pageBackground: {
    backgroundColor: 'rgb(252, 252, 253)',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: ({ isIntegratedCheckout }) => (isIntegratedCheckout ? undefined : '0 1rem'),
    marginTop: '1rem',
    flexFlow: ({ isMobile }) => (isMobile ? 'column-reverse' : 'unset'),
  },
  accordion: {
    borderRadius: '10px !important',
    border: '1px solid #E7E7E7 !important',
    background: '#F5F5F5',
    margin: 0,
  },
  accordionHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  accordionHeader: {
    color: '#0094FF',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  icon: {
    fontSize: 20,
  },
  accordionContent: {
    padding: 0,
  },
  accordionTotalText: {
    fontWeight: 600,
    fontSize: 15,
  },
}))

const HostedPaymentsPage: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [transferInstructions, setTransferInstructions] = useState<undefined | LocalBankTransferDataType>(undefined)
  const [isPaid, setIsPaid] = useState<boolean>(false)
  const [savedPaymentMethodData, setSavedPaymentMethodData] = useState<
    BlueSnapCardDataType | ACHPaymentDataType | undefined
  >(undefined)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [initialPaymentRequestStatus, setInitialPaymentRequestStatus] = useState<PaymentRequestStatusType | undefined>(
    undefined
  )
  const [hasNotifiedPro, setHasNotifiedPro] = useState<boolean>(false)

  const dispatch = useDispatch()
  const notify = useNotify()
  const classes = useStyles({ isMobile, isIntegratedCheckout: Boolean(props.isIntegratedCheckout) })
  const translate = useTranslate()

  const user = useSelector(authSelectors.getCurrentUser)
  const isPro = Boolean(useSelector(authSelectors.getCurrentRole))

  // make sure the URL path has a valid security token that can be used to log in
  useEffect(() => {
    // if this component mounts with user already populated then it means it's embedded in the proposal, no need to go refetch the user
    if (!user) {
      const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
      const params = new URLSearchParams(path.substring(path.indexOf('?')))
      const authToken = params.get('url_auth_token')

      dispatch(autoLogin({ url_auth_token: authToken, autoLoginProject: props.match?.params?.projectId }))
    }
  }, [user])

  useEffect(() => {
    if (isMobile) setIsExpanded(false)
  }, [isMobile])

  const { isLoading, paymentRequestData, errorMsg } = useGetPaymentRequestData(props.match.params, user)
  const { blueSnapIsLoaded, loadError } = useLoadBlueSnapPaymentsSDK()

  const { pmtSummaryData, isLoading: summaryIsLoading } = useGetPaymentSummaryData(
    savedPaymentMethodData,
    props.match?.params?.projectId,
    paymentRequestData?.payment_request?.payment_request_id,
    paymentRequestData?.country_iso2,
    user
  )

  // if the pro got here from the Pay Deposit CTA in that payment option card then notify them that the invoice has been emailed
  useEffect(() => {
    if (user && isPro && paymentRequestData && !hasNotifiedPro) {
      const path = window.location.hash.substring(window.location.hash.indexOf('#') + 2)
      const params = new URLSearchParams(path.substring(path.indexOf('?')))
      const notify_pro = params.get('notify_pro')
      if (notify_pro) {
        notify(
          translate('Invoice %{invoice_number} has been sent to %{customer_email}', {
            invoice_number: paymentRequestData?.payment_request?.invoice_number,
            customer_email: paymentRequestData?.contact?.email,
          }),
          'info'
        )
        setHasNotifiedPro(true)
        logAmplitudeEvent('cashflow_payment_form_viewed', {
          source: 'launch application',
          is_pro: isPro,
          project_id: props.match?.params?.projectId,
          org_id: paymentRequestData?.payment_request?.org_id,
        })
      } else {
        logAmplitudeEvent('cashflow_payment_form_viewed', {
          source: props.isIntegratedCheckout ? 'integrated checkout' : 'direct link',
          is_pro: isPro,
          project_id: props.match?.params?.projectId,
          org_id: paymentRequestData?.payment_request?.org_id,
        })
      }
    }
  }, [user, isPro, paymentRequestData, hasNotifiedPro])

  // save the initial status of the payment request to make sure we handle users returning to already paid/cancelled/etc requests
  useEffect(() => {
    if (paymentRequestData?.payment_request?.status && !initialPaymentRequestStatus)
      setInitialPaymentRequestStatus(paymentRequestData?.payment_request?.status)
  }, [paymentRequestData?.payment_request?.status])

  useEffect(() => {
    const paidStatusValues: PaymentRequestStatusType[] = ['paid in full', 'pending', 'partially paid']
    if (
      paymentRequestData?.payment_request?.status &&
      paidStatusValues?.includes(paymentRequestData?.payment_request?.status)
    ) {
      setIsPaid(true)
    }
  }, [paymentRequestData?.payment_request?.status])

  // for mobile screens if the user has already entered a payment then force the payment summary to be expanded
  // so the user sees any surcharge disclosure
  const forceExpandSummary = useMemo(() => {
    return isMobile && !!savedPaymentMethodData
  }, [isMobile, savedPaymentMethodData])

  const currencySymbol = currencySymbolForCountry(paymentRequestData?.country_iso2)

  const isReady = useMemo(() => {
    return Boolean(blueSnapIsLoaded) && Boolean(paymentRequestData) && Boolean(user)
  }, [blueSnapIsLoaded, paymentRequestData, user])

  const doSubmitPayment = (args: PaymentExtraFields) => {
    if (!pmtSummaryData?.payment_amount_with_surcharge) {
      // this shouldn't be possible, but if some sort of bug causs this field to be be null we shouldn't try to process the payment
      notify(translate('We are unable to process this payment, please refresh your page and try again'))
      return
    } else if (savedPaymentMethodData?.token) {
      setIsSubmitting(true)
      submitPayment(
        props.match.params,
        args,
        savedPaymentMethodData?.token,
        paymentRequestData?.payment_request?.org_id,
        pmtSummaryData?.payment_amount_with_surcharge
      )
        .then((response) => {
          logAmplitudeEvent('cashflow_payment_authrized', {
            payment_method: args?.payment_method_type,
            project_id: props.match?.params?.projectId,
            payment_reuest_id: paymentRequestData?.payment_request?.payment_request_id,
          })
          if (response?.data?.transaction_data) setTransferInstructions(response?.data?.transaction_data)
          setIsPaid(true)
        })
        .catch((err: string) => {
          console.log('err', err)
          notify(translate(err), 'warning')
          logAmplitudeEvent('cashflow_payment_method_error', {
            payment_method: args?.payment_method_type,
            project_id: props.match?.params?.projectId,
            payment_reuest_id: paymentRequestData?.payment_request?.payment_request_id,
            error_message: err,
          })
        })
        .finally(() => setIsSubmitting(false))
    } else {
      notify('We are unable to save this payment method data', 'warning')
    }
  }

  if (initialPaymentRequestStatus && !['requested', 'viewed', 'payment failed'].includes(initialPaymentRequestStatus)) {
    return (
      <PaymentAlreadyComplete
        orgName={paymentRequestData?.org_name}
        contact={paymentRequestData?.contact?.first_name}
        initialPaymentRequestStatus={initialPaymentRequestStatus}
      />
    )
  } else {
    return (
      <div className={classes.pageBackground}>
        {isLoading && <LoadingDots text={translate('Loading your payment details...')} />}
        {isReady && paymentRequestData && (
          <div>
            {!props.isIntegratedCheckout && (
              <PaymentsPageHeader orgName={paymentRequestData.org_name} logoURL={paymentRequestData.org_logo} />
            )}
            <Grid container spacing={2} className={classes.gridContainer}>
              <Grid item xs={12} md={7} lg={6} xl={5}>
                {isPaid ? (
                  <PaymentSuccesful
                    orgName={paymentRequestData?.org_name}
                    contact={paymentRequestData?.contact?.first_name}
                    savedPaymentMethodData={savedPaymentMethodData}
                  />
                ) : (
                  <PaymentForm
                    doSubmitPayment={doSubmitPayment}
                    paymentRequestData={paymentRequestData.payment_request}
                    countryIso2={paymentRequestData.country_iso2}
                    projectId={props.match?.params?.projectId}
                    setSavedPaymentMethodData={setSavedPaymentMethodData}
                    savedPaymentMethodData={savedPaymentMethodData}
                    orgName={paymentRequestData.org_name}
                    paymentStaticCopy={paymentRequestData.static_copy}
                    isSubmitting={isSubmitting}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                {isMobile && !forceExpandSummary ? (
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      onClick={() => {
                        setIsExpanded(!isExpanded)
                      }}
                    >
                      <div className={classes.accordionHeaderContainer}>
                        {isExpanded ? (
                          <span className={classes.accordionHeader}>
                            {translate('Hide order summary')} <ExpandLess className={classes.icon} />
                          </span>
                        ) : (
                          <span className={classes.accordionHeader}>
                            {translate('Show order summary')} <ExpandMore className={classes.icon} />
                          </span>
                        )}
                        <span className={classes.accordionTotalText}>
                          {formatCurrencyWithSymbol(
                            paymentRequestData?.payment_request?.payment_amount,
                            currencySymbol
                          )}
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionContent}>
                      <PaymentSummary
                        isPaid={isPaid}
                        savedPaymentMethodData={savedPaymentMethodData}
                        projectId={props.match?.params?.projectId}
                        countryIso2={paymentRequestData.country_iso2}
                        paymentRequestData={paymentRequestData.payment_request}
                        pmtSummaryData={pmtSummaryData}
                        summaryIsLoading={summaryIsLoading}
                      />
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <PaymentSummary
                    isPaid={isPaid}
                    savedPaymentMethodData={savedPaymentMethodData}
                    projectId={props.match?.params?.projectId}
                    countryIso2={paymentRequestData.country_iso2}
                    paymentRequestData={paymentRequestData.payment_request}
                    pmtSummaryData={pmtSummaryData}
                    summaryIsLoading={summaryIsLoading}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}
        {transferInstructions && paymentRequestData && (
          <TransferInstructions
            instructions={transferInstructions}
            paymentRequestData={paymentRequestData.payment_request}
          />
        )}
      </div>
    )
  }
}
export default HostedPaymentsPage
