import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { CashFlowConfiguration } from '../types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetCashFlowConfigurations = (
  orgId: number | undefined,
  page: number,
  limit: number = 10,
  isUpdated?: boolean
) => {
  const [results, setResults] = useState<CashFlowConfiguration[]>([])
  useEffect(() => {
    if (orgId) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/payments/configuration/`,
      })
        .then((res) => {
          setResults(res.data)
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }, [orgId, page, limit, isUpdated])

  return results
}

export const sanitizeCashFlowConfigForm = (vals) => {
  vals.payment_milestone_configurations?.forEach((milestone, i) => {
    // make sure the last milestone has set_value_to_remaining_amount_due=true but no others do
    // and if this is a deposit_only config set_value_to_remaining_amount_due must be false
    if (i + 1 < vals.payment_milestone_configurations.length || vals.deposit_only) {
      vals.payment_milestone_configurations[i].set_value_to_remaining_amount_due = false
    } else {
      vals.payment_milestone_configurations[i].set_value_to_remaining_amount_due = true
      vals.payment_milestone_configurations[i].percentage = null
      vals.payment_milestone_configurations[i].min_fixed = null
      vals.payment_milestone_configurations[i].max_fixed = null
    }

    if (milestone.remove_from_percentage_calcs) {
      if (i === 0) {
        // if the first milestone has remove_from_percentage_calcs set to true, remove percentage, min and max
        milestone.percentage = null
        milestone.min_fixed = null
        milestone.max_fixed = null
      } else {
        // if this is not the first milestone then remove_from_percentage_cals should be false and fixed_amount should be null
        // those fields are only allowed on the first milestone
        milestone.remove_from_percentage_cals = false
        milestone.fixed_amount = null
      }
    } else {
      milestone.fixed_amount = null
    }

    // don't allow any milestone other than the first to be marked as a deposit
    if (i > 0 && milestone.is_deposit) {
      milestone.is_deposit = false
    }

    // add payment_number to each milestone before submitting to make sure things like re-sorting or deleting milestones doesn't end up
    // leading to incorrect payment numbers being submitted
    vals.payment_milestone_configurations[i].payment_number = i + 1
  })
  return vals
}

export const useGetPaymentMethodChoices = () => {
  const [countryChoices, setCountryChoices] = useState<{ id: string; name: string }[]>([])

  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2
  const translate = useTranslate()

  useEffect(() => {
    let choices = [
      { id: 'credit_card_standard', name: translate('Credit Card - Standard') },
      { id: 'credit_card_premium', name: translate('Credit Card - Premium') },
      { id: 'credit_card_international', name: translate('Credit Card - International') },
      { id: 'debit_card_standard', name: translate('Debit Card - Standard') },
      { id: 'debit_card_premium', name: translate('Debit Card - Premium') },
      { id: 'debit_card_international', name: translate('Debit Card - International') },
    ]
    if (orgCountry === 'US') {
      choices.push({
        id: 'ach',
        name: 'ACH',
      })
    }
    if (orgCountry === 'GB') {
      choices.push({
        id: 'local_bank_transfer',
        name: translate('Local Bank Transfer'),
      })
    }
    setCountryChoices(choices)
  }, [orgCountry])
  return countryChoices
}

export const getDefaultPaymentMethodsForCountry = (countryIso2: string, isDeposit: boolean) => {
  if (countryIso2 === 'GB') {
    if (isDeposit)
      return ['credit_card_standard', 'credit_card_premium', 'credit_card_international', 'local_bank_transfer']
    else return ['local_bank_transfer']
  } else if (countryIso2 === 'US') {
    if (countryIso2 === 'US') {
      return ['credit_card_standard', 'credit_card_premium', 'credit_card_international']
    }
  }
  return []
}

export const getIsSurchargingAllowed = (countryIso2: string | undefined) => {
  const surcharingCountries = ['US', 'AU']
  return countryIso2 && surcharingCountries.includes(countryIso2)
}

export const showDebitCardSurchargeWarning = (surchargingEnabled: boolean, paymentMethods: any) => {
  if (!surchargingEnabled) return false
  if (
    paymentMethods.includes('debit_card_standard') ||
    paymentMethods.includes('debit_card_premium') ||
    paymentMethods.includes('debit_card_international')
  )
    return true
}

export const checkAutoApplyConfigExists = (data) => {
  let id
  if (data) {
    const config = data.find((config) => config.auto_apply === true)
    if (config) {
      id = config.id
    }
  }
  return id
}
