import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { PRIORITY_CHOICES } from 'resources/projects/choices'
import { Theme } from 'types/themes'
import { formatDate } from 'util/date'
import ManageSelectDropdown from '../common/ManageSelectDropdown'
import { COLOR_PALETTE, getProjectSectionStyles } from '../styles'
import ProjectContacts from './elements/contacts/ProjectContacts'
import ProjectTags from './elements/ProjectTags'
import ProjectTeams from './elements/teams/ProjectTeams'

/*
Seperator implementation taken from https://stackoverflow.com/questions/64174517/how-to-implement-separators-between-wrapped-flex-items
Hides the last seperator on each row by clipping it (`overflow: 'hidden'`)
*/
const divider = 1
const columnGap = 20
const border = 2

const useStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'stretch',
      marginLeft: columnGap / -2,
      overflow: 'hidden',
      rowGap: 10,
    },
    detail: {
      height: 65,
      marginLeft: columnGap / 2,
      marginRight: columnGap / 2,
      position: 'relative',
      '& p': {
        fontWeight: 500,
        margin: 0,
        color: COLOR_PALETTE.darkGrey,
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        right: '100%',
        top: '50%',
        display: 'inline-block',
        width: divider,
        height: '100%',
        backgroundColor: COLOR_PALETTE.lightGrey,
        transform: 'translateY(-50%)',
        marginRight: (columnGap - divider) / 2 + border,
      },
    },
    tagsCustomWidth: {
      flexGrow: 1,
    },
    teamsCustomWidth: {
      flexGrow: 1,
      maxWidth: 200,
    },
    contactsCustomWidth: {
      flexGrow: 1,
    },
    detailContent: {
      marginTop: 8,
      minHeight: 30,
      display: 'flex',
      alignItems: 'center',
      color: COLOR_PALETTE.darkGrey,
    },
    projectTypeContent: {
      marginTop: 1,
      minHeight: 30,
      display: 'flex',
      alignItems: 'center',
      color: COLOR_PALETTE.darkGrey,
    },
    dropSelect: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    selectInput: {
      margin: 0,
      width: 'unset',
      borderRadius: 4,
      '& .MuiOutlinedInput-root': {
        height: 30,
        fontSize: 14,
      },
    },
    prioSquareColor: {
      width: 10,
      height: 10,
      marginRight: 5,
      borderRadius: 2,
    },
  }),
  { name: 'ProjectDetailFooter' }
)
const PRIORITY_CHOICE_COLORS = {
  1: '#005D7A',
  2: '#C47E0A',
  3: '#961616',
}
const PRIORITY_COLORS = {
  1: 'info',
  2: 'warning',
  3: 'error',
}

const DetailFooter = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const priorityColor = useMemo(() => {
    switch (project.priority) {
      case 1:
        return 'info'
      case 2:
        return 'warning'
      case 3:
        return 'error'
      default:
        return 'secondary'
    }
  }, [project.priority])
  const { change } = useForm()
  const dropdownClasses = getProjectSectionStyles()
  const handlePriorityChange = (event) => {
    change('priority', event.target.value)
  }
  const formattedDate = useMemo(() => formatDate(project.created_date), [project.created_date])

  return (
    <div className={classes.wrapper}>
      <div className={classes.detail} style={{ width: 90 }}>
        <p>{translate('Project ID')}</p>
        <div className={classes.detailContent}>
          <span>{project.id}</span>
        </div>
      </div>
      <div className={classes.detail} style={{ width: 130 }}>
        <p>{translate('Date Created')}</p>
        <div className={classes.detailContent}>
          <span>{formattedDate}</span>
        </div>
      </div>
      <div className={classes.detail} style={{ width: 130 }}>
        <p>{translate('Priority')}</p>
        <div className={classes.detailContent}>
          <ManageSelectDropdown
            choices={PRIORITY_CHOICES}
            source="priority"
            project={project}
            color={PRIORITY_COLORS[project.priority]}
            renderValue={(choice) => `● ${translate(choice.name)}`}
            handlePriorityChange={handlePriorityChange}
            renderChoiceValue={(choice) => (
              <span className={dropdownClasses.row}>
                <div
                  className={classes.prioSquareColor}
                  style={{ backgroundColor: PRIORITY_CHOICE_COLORS[choice.id] }}
                />
                {translate(choice.name)}
              </span>
            )}
          />
        </div>
      </div>
      <div className={classes.detail} style={{ width: 155 }}>
        <p>{translate('Project Type')}</p>
        <div className={classes.projectTypeContent}>
          <SelectInput
            choices={[
              {
                id: true,
                title: 'Residential',
              },
              {
                id: false,
                title: 'Commercial',
              },
            ]}
            optionText={'title'}
            source="is_residential"
            variant="outlined"
            label={false}
            size="small"
            color={'info'}
          />
        </div>
      </div>
      <ProjectContacts wrapperClass={`${classes.detail} ${classes.contactsCustomWidth}`} />
      <ProjectTeams wrapperClass={`${classes.detail} ${classes.teamsCustomWidth}`} />
      <ProjectTags wrapperClass={`${classes.detail} ${classes.tagsCustomWidth}`} />
    </div>
  )
}

export default DetailFooter
