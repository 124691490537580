import { withStyles } from '@material-ui/core'
import Tooltip from 'elements/tooltip/Tooltip'
import UserIcon from 'elements/UserIcon'
import { styled } from 'opensolar-ui'
import { RoleType } from 'types/roles'

const ToolTipRestyle = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    padding: '10px 15px',
  },
}))(Tooltip)

interface PropTypes {
  teamMember: RoleType
}

const MainText = styled('p')({
  fontSize: 14,
  margin: 0,
})

const Subtext = styled('span')({
  color: 'grey',
  fontWeight: 400,
})

const TeamDisplay: React.FC<PropTypes> = ({ teamMember }) => {
  return teamMember ? (
    <ToolTipRestyle
      title={
        <>
          <MainText>{`${teamMember.first_name} ${teamMember.family_name}`}</MainText>
          <Subtext>
            {`${teamMember.user_email || ''} ${teamMember.phone ? `● ${teamMember.phone}` : ''} ${
              teamMember.permissions_role_title ? `● ${teamMember.permissions_role_title}` : ''
            }`}
          </Subtext>
        </>
      }
    >
      <div>
        <UserIcon user={teamMember} />
      </div>
    </ToolTipRestyle>
  ) : (
    <>{'－'}</>
  )
}

export default TeamDisplay
