import { Typography } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  showMore: {
    color: '#0094FF',
    margin: '0 10px',
    fontWeight: 400,
    cursor: 'pointer',
  },
}))

const useLimitedOptionsController = <T,>(options: T[], limit = 4) => {
  const [showMore, setShowMore] = useState(false)
  const classes = useStyles()
  const handleShowMore = () => {
    setShowMore(true)
  }

  const handleShowLess = () => {
    setShowMore(false)
  }

  const LimitedOptionsController = useMemo(() => {
    if (options.length <= limit) {
      return null
    }
    if (showMore === false) {
      return (
        <Typography className={classes.showMore} onClick={handleShowMore}>
          Show More
        </Typography>
      )
    } else {
      return (
        <Typography className={classes.showMore} onClick={handleShowLess}>
          Show Less
        </Typography>
      )
    }
  }, [options, showMore])

  const limitedOptions = useMemo(() => {
    if (showMore) {
      return options
    }

    return options.slice(0, limit)
  }, [showMore, options, limit])

  return {
    limitedOptions,
    LimitedOptionsController,
  }
}

export default useLimitedOptionsController
