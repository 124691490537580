import { authSelectors } from 'ducks/auth'
import lodash from 'lodash'
import { CreateProps } from 'ra-core/src/controller/useCreateController'
import React, { useEffect, useMemo, useState } from 'react'
import { Create, Edit, NotFound, SimpleForm, TextInput, useGetListFromStore, useTranslate } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { CUSTOM_ROLE_TYPES, CustomRolePermissionsType, CustomRoleType, USER_ROLE_TYPE } from 'types/customRole'
import { required } from 'validations'
import AccessControl from './AccessControl'
import AccessRightCheckboxTable from './AccessRightCheckboxTable'
import BaseTemplateSelector from './BaseTemplateSelector'
import { getPredefinedAccessRights } from './PredefinedRoles'
import { getRoleType } from './constants'

const parseFormValues = (record: any) => {
  if (record && record.permissions) {
    try {
      const parsedPermissions = JSON.parse(record.permissions)
      const newRecord = { ...record }
      newRecord.permissions = parsedPermissions
      return newRecord
    } catch (e) {
      console.warn('invalid permissions json data')
      delete record.permissions
      return record
    }
  }
  return record
}

const formatFormValues = (values: any) => {
  if (values.permissions) {
    values.permissions = JSON.stringify(values.permissions)
  }
  return values
}

const CustomRoleForm = (props: any) => {
  const translate = useTranslate()
  const { data: predefinedRoles, loading } = useGetListFromStore<CustomRoleType>(
    'permissions_role',
    undefined,
    undefined,
    {
      predefined_only: 1,
    }
  )
  const roleTypeData = useMemo(() => {
    return getRoleType(props.record.role_type)
  }, [props.record.role_type])

  const predefinedAccessRights = useMemo(() => {
    const adminPermissions =
      predefinedRoles && lodash.find(predefinedRoles, (custom_role: any) => custom_role.template === 'admin')
    let adminSetting: CustomRolePermissionsType | undefined
    try {
      adminSetting = adminPermissions ? JSON.parse(adminPermissions.permissions) : undefined
    } catch (e) {
      console.warn('invalid permissions json data: ', e, adminPermissions?.permissions)
    }
    return getPredefinedAccessRights(adminSetting)
  }, [predefinedRoles])

  const [filteredRoles, filterRoles] = useState<CustomRoleType[]>([])
  useEffect(() => {
    if (predefinedRoles) {
      filterRoles(lodash.filter(predefinedRoles, (x) => x.role_type === props.record.role_type))
    }
  }, [predefinedRoles, props.record.role_type])

  if (loading) {
    return (
      <div style={{ padding: 20 }}>
        <Skeleton height="200px" />
      </div>
    )
  } else {
    return (
      <SimpleForm
        // onFormChange={handleFormChange}
        formatSubmitValues={formatFormValues}
        defaultValue={parseFormValues}
        {...props}
      >
        <TextInput name={'title'} source="title" label="Title" required validate={required} />
        {!roleTypeData?.hide_templates && <BaseTemplateSelector predefinedRoles={filteredRoles} />}
        {!roleTypeData?.hide_project_access && <AccessControl disabled={false} />}
        <AccessRightCheckboxTable predefinedAccessRights={predefinedAccessRights} roleType={roleTypeData} />
      </SimpleForm>
    )
  }
}

export const CustomRoleEdit = (props: any) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  if (!isAdmin) {
    return <NotFound />
  }
  return (
    <Edit {...props} hasDelete={isAdmin}>
      <CustomRoleForm {...props} disabled={!isAdmin} isEdit={true} />
    </Edit>
  )
}

interface CustomRoleCreateProps {
  roleType?: CUSTOM_ROLE_TYPES
}

export const CustomRoleCreate: React.FC<CustomRoleCreateProps & CreateProps> = ({
  roleType = USER_ROLE_TYPE,
  ...props
}) => {
  const isAdmin = useSelector(authSelectors.getIsAdmin)
  if (!isAdmin) {
    return <NotFound />
  }
  return (
    <Create {...props} record={{ role_type: roleType }}>
      <CustomRoleForm {...props} isEdit={false} />
    </Create>
  )
}
