import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Button } from 'opensolar-ui'
import React from 'react'
import { Theme } from 'types/themes'
import { COLOR_PALETTE } from '../styles'

export const ButtonRestyled = withStyles(() => ({
  root: {
    backgroundColor: COLOR_PALETTE.black,
  },
}))(Button)

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    padding: '20px 0',
    borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  detailWrapper: {
    maxWidth: '75%',
  },
  desc: {
    fontSize: 12,
    marginTop: 8,
    color: COLOR_PALETTE.darkGrey,
  },
  header: {
    fontSize: 16,
    margin: 0,
    fontWeight: 600,
    color: COLOR_PALETTE.black,
  },
  iconWrapper: {
    marginLeft: 'auto',
    border: '1px solid ' + COLOR_PALETTE.lightGrey,
    borderRadius: 6,
    padding: 3,
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionBtn: {
    //possibly add as new variant?
    backgroundColor: '#4272DD !important',
    color: '#fff !important',
  },
}))
interface PropTypes {
  title: string
  description: string
  btnLabel: string
  onClick: () => void
  logo?: React.ReactNode
  disabled?: boolean
}

const IntegrationRow: React.FC<PropTypes> = ({ title, description, btnLabel, onClick, logo, disabled }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.detailWrapper}>
        <h3 className={classes.header}>{title}</h3>
        <p className={classes.desc}>{description}</p>
        <Button className={classes.actionBtn} onClick={onClick} disabled={disabled}>
          {btnLabel}
        </Button>
      </div>
      {logo && <div className={classes.iconWrapper}>{logo}</div>}
    </div>
  )
}

export default IntegrationRow
