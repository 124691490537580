import React, { Component } from 'react'
import { withTranslate } from 'react-admin'
import { Card } from '@material-ui/core'
import AboutUsDialog from './dialogs/AboutUsDialog'
import { DialogHelper } from '../util/misc'

const styles = {
  mainFlexContainer: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'column',
    display: 'flex',
    WebkitFlexDirection: 'column',
    zIndex: 1,
  },
  cardOnly: {
    minWidth: 210,
    padding: '0.5em 1em 1em 1em',
  },
}

class MyEnergyNotAvailable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeDialog: null,
    }
  }

  showDialog(dialogName) {
    DialogHelper.afterOpen()
    this.setState({ activeDialog: dialogName })
  }

  closeDialog() {
    DialogHelper.beforeClose()
    this.setState({ activeDialog: null })
  }

  render = () => {
    const { hasBottomToolbar, translate } = this.props
    return (
      <div
        style={Object.assign({}, styles.mainFlexContainer, {
          paddingBottom: hasBottomToolbar ? 56 : 0,
        })}
      >
        {/*
        <MyEnergyHeader
          org={org}
          showDialog={this.showDialog.bind(this)}
        />*/}

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card style={Object.assign({ width: '308px' }, styles.cardOnly)}>
            <h1>{translate('Welcome to MyEnergy')}</h1>
            {this.props.errorMessage && this.props.errorMessage.length > 0 && (
              <h2 style={{ color: '#C1292E' }}>{translate(this.props.errorMessage)}</h2>
            )}
          </Card>
        </div>
        <AboutUsDialog
          org={this.props.org}
          testimonials={this.props.testimonials}
          isOpen={this.state.activeDialog === 'AboutUsDialog'}
          closeDialog={() => this.closeDialog()}
        />
      </div>
    )
  }
}

export default withTranslate(MyEnergyNotAvailable)
