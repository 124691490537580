import { OpenSolarThemeType } from 'Themes'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { DateInput, formatDate } from 'elements/input/DateInput'
import React, { useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { dateParser } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: theme.typography.subtitle2.fontSize,
    },
  },
  installationDateBackground: {
    background: theme.ValidityPalette.warning.background,
  },
}))

const InstallationDataInput = ({
  projectId,
  installationDate,
  onSuccess,
  onFailed,
  trackSource,
  isHistorical,
}: {
  projectId: number
  installationDate?: string
  onSuccess?(): void
  onFailed?(): void
  trackSource: string
  isHistorical?: boolean
}) => {
  const classes = useStyles({ isHistorical })
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [internalValue, setInternalValue] = useState(formatDate(installationDate))
  const [isUpdating, setIsUpdating] = useState(false)
  const handleChange = async (date) => {
    setIsUpdating(true)
    setInternalValue(date)
    await dataProvider
      .update('projects', {
        id: projectId,
        data: { installation_date: dateParser(date) },
        // previousData should be optional, restClient never handles previousData
        previousData: { id: projectId },
      })
      .then(() => {
        notify('Installation date saved', 'success')
        onSuccess?.()
      })
      .catch(() => {
        // handle error
        // add notification
        onFailed?.()
      })
    logAmplitudeEvent('generic_button_clicked', {
      source: trackSource,
      context: trackSource,
    })
    setIsUpdating(false)
  }

  return isUpdating ? (
    <Skeleton />
  ) : (
    <div className={isHistorical ? classes.installationDateBackground : undefined}>
      <DateInput
        size="small"
        value={internalValue}
        onChange={handleChange}
        inputVariant="outlined"
        placeholder="--/--/----"
        className={classes.root}
      />
    </div>
  )
}

export default InstallationDataInput
