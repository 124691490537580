import CustomForm from 'customForm/CustomForm'
import { FormPlacementInfo } from 'customForm/util/findFormPlacements'
import { useSubForm } from 'elements/react-admin/useSubForm'
import { FormApi } from 'final-form'
import mutations from 'projectSections/form/mutations'
import React, { FC, MutableRefObject } from 'react'
import { useFormState } from 'react-final-form'

interface GenerateDocumentInputDialogProps {
  parentForm: FormApi
  customFormsForDoc: FormPlacementInfo[]
  saveRef: MutableRefObject<() => void>
  formRef: MutableRefObject<FormApi>
}

export const GenerateDocumentInputDialog: FC<GenerateDocumentInputDialogProps> = (props) => {
  const formState = useFormState()

  const subForm = useSubForm({
    parentForm: props.parentForm,
    mutators: mutations,
    saveRef: props.saveRef,
    formRef: props.formRef,
    render: ({ form }) =>
      props.customFormsForDoc?.map((customForm) => (
        <>
          <CustomForm
            key={customForm.form.id}
            isLoading={!customForm.form.configuration}
            customForm={customForm.form}
            parentForm={form}
            selectedSystem={formState.values.selected_system}
          />
        </>
      )),
  })

  return !props.customFormsForDoc?.length ? <></> : subForm.render()
}
