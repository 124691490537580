import { logAmplitudeEvent } from 'amplitude/amplitude'
import restClient from 'restClient'
import { MountingID } from 'types/mounting'
import { BomGeneratorAbstract } from '../../bom_generators/BomGeneratorAbstract'
import { BasicSpacingLayoutAdjusterAbstract } from '../../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import { MountingSystemAbstract } from '../../MountingSystemAbstract'
import { CompatibilityParameters, MountingCalcResult, RoofTypeName } from '../../types'
import { EsdecRoofType, FormValuesType, RoofSectionsType } from './types'
import { getDesign } from './util'

const API_URL = window.API_ROOT + '/api'

type System = 'CLICKFIT_EVO' | 'FLATFIX_FUSION'

class ClickFitSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = {
    interPanel: { horizontalSpacing: 12, verticalSpacing: 12 },
  }
}

class FlatFixSpacingAdjuster extends BasicSpacingLayoutAdjusterAbstract {
  spacingRuleSet = {
    interPanel: { horizontalSpacing: 25, verticalSpacing: 25 },
  }
}

const restClientInstance = restClient(API_URL)

const roofTypeToEsdecRoofType: Partial<Record<RoofTypeName, EsdecRoofType>> = {
  'Tile Clay': 'TILES',
  'Tile Concrete': 'TILES',
  'Tile Slate': 'TILES',
  Trapezoidal: 'STEEL',
  'Metal Standing Seam': 'STANDING_SEAM',
  'Membrane TPO': 'TPO',
  'Tar and Gravel / Bitumen': 'BITUMEN_INSULATED',
}

async function generateBomWithEsdecAPI(
  result: MountingCalcResult,
  inputOptions,
  system: System,
  mountingId: MountingID
): Promise<MountingCalcResult> {
  var roofTypeName
  const roofTypeUrl = window.WorkspaceHelper.project.roof_type
  if (roofTypeUrl?.length) {
    let roofId = Number(roofTypeUrl.split('roof_types/')[1].split('/')[0])
    roofTypeName = window.AccountHelper.getRoofTypeById(roofId)?.name
  }

  var formVals: FormValuesType = {
    roofs: [],
    terrainCategory: inputOptions.terrainCategory,
    country: 'EUROCODE',
    windSpeed: inputOptions.windSpeed,
    orography: 1,
  }

  const moduleType = window.editor.selectedSystem.moduleType()

  for (const panelBlock of result.panelBlocks) {
    const panelArray = window.editor.objectByUuid(panelBlock.uuid)
    const designResults = getDesign(panelArray)
    const getRowConnectorLength = (panelConfiguration: 'SINGLE' | 'DUAL', rowDistance: string) => {
      if (panelConfiguration === 'DUAL') {
        return {
          ROW_DISTANCE_2100: 'ROW_CONNECTOR_LENGTH_210',
          ROW_DISTANCE_2260: 'ROW_CONNECTOR_LENGTH_370',
          ROW_DISTANCE_2440: 'ROW_CONNECTOR_LENGTH_550',
          ROW_DISTANCE_2640: 'ROW_CONNECTOR_LENGTH_750',
          ROW_DISTANCE_2830: 'ROW_CONNECTOR_LENGTH_940',
        }[rowDistance]
      } else {
        return {
          ROW_DISTANCE_1320: 'ROW_CONNECTOR_LENGTH_370',
          ROW_DISTANCE_1500: 'ROW_CONNECTOR_LENGTH_550',
          ROW_DISTANCE_1700: 'ROW_CONNECTOR_LENGTH_750',
          ROW_DISTANCE_1890: 'ROW_CONNECTOR_LENGTH_940',
        }[rowDistance]
      }
    }
    if (designResults) {
      formVals.roofs.push({
        orientation: designResults.orientation,
        angle: panelArray.userData.slope,
        solarPanel: {
          width: panelBlock.panel.width,
          length: panelBlock.panel.height,
          thickness: panelBlock.panel.thickness,
          weight: moduleType.weight,
          peakPower: Math.round(moduleType.kw_stc * 1000),
        },
        width: 100,
        length: 100,
        height: inputOptions.roofHeight,
        fields: [
          {
            topLeftPosition: {
              x: 400,
              y: 400,
            },
            ...(system === 'CLICKFIT_EVO'
              ? {
                  railSystem: inputOptions.railDirection?.toUpperCase(),
                  orientation: designResults.orientation,
                }
              : {
                  // For FLATFIX_FUSION system
                  panelConfiguration: inputOptions.panelConfiguration,
                  rowConnectorLength: getRowConnectorLength(inputOptions.panelConfiguration, inputOptions.rowDistance),
                  orientation: designResults.orientation,
                }),
            panels: designResults.design,
          },
        ],
        ...(system === 'CLICKFIT_EVO'
          ? {
              hookType: inputOptions.roofHook,
            }
          : {}),
        tpoMats: inputOptions.tpoMats || false,
        material: roofTypeToEsdecRoofType[roofTypeName],
        product: system,
        blackArticles: inputOptions.blackArticles || false,
        type: system === 'CLICKFIT_EVO' ? 'SLOPED' : 'FLAT',
        seamType: inputOptions.seamType,
        flangeDistance: inputOptions.flangeDistance,
      } as RoofSectionsType)

      await restClientInstance('CUSTOM_POST', 'custom', {
        url:
          'orgs/' +
          window.WorkspaceHelper.project.org_id +
          '/projects/' +
          window.WorkspaceHelper.project.id +
          '/systems/' +
          window.editor.selectedSystem.uuid +
          '/esdec/bom/',
        data: formVals,
      })
        .then((response: any) => {
          response.data.bom.items.forEach((responseItem) => {
            // Note: We only add products that are required, and ignore optional products
            if (!responseItem.optional) {
              for (var i = 0; i <= responseItem.suggested_count; i++) {
                result.items.push({ name: responseItem.mfr_code, components: [] })
              }
            }
          })
        })
        .catch((err) => {
          logAmplitudeEvent('integrated_racking_dialog_error', {
            integration: 'ESDEC',
            errorType: 'api',
            error_msg: err.body?.detail || err.message,
          })
          window.WorkspaceHelper.addProjectErrorToReduxStore({
            message: `Error fetching BOM from Esdec's API. ${err.body?.detail || ''}`,
            key: `${mountingId.toUpperCase()}_PLUGIN_WARNING`,
            severity: 'warning',
            systemId: panelArray.getSystem().uuid,
            source: 'plugin',
            category: 'mounting',
            options: {},
          })
        })
    }
  }

  return result
}

class ClickFitBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const inputOptions = this.input.options
    return generateBomWithEsdecAPI(result, inputOptions, 'CLICKFIT_EVO', 'esdec')
  }
}

class FlatFixBomGenerator extends BomGeneratorAbstract {
  async generateBom(result: MountingCalcResult): Promise<MountingCalcResult> {
    const inputOptions = this.input.options
    return generateBomWithEsdecAPI(result, inputOptions, 'FLATFIX_FUSION', 'flatFix')
  }
}

export class ClickFit extends MountingSystemAbstract {
  layoutAdjusterClass = ClickFitSpacingAdjuster
  bomGeneratorClass = ClickFitBomGenerator

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['Segen'],
      roofTypes: ['Tile Clay', 'Tile Concrete', 'Tile Slate', 'Trapezoidal', 'Metal Standing Seam'],
      roofTypeRequired: true,
      slopeRange: [10, 60],
      moduleHasSpecs: ['width', 'height', 'thickness', 'weight', 'kw_stc'],
    }
  }
}

export class FlatFix extends MountingSystemAbstract {
  layoutAdjusterClass = FlatFixSpacingAdjuster
  bomGeneratorClass = FlatFixBomGenerator

  getCompatibilityParameters(): CompatibilityParameters {
    return {
      integrations: ['Segen'],
      roofTypes: ['Tar and Gravel / Bitumen', 'Membrane TPO'],
      roofTypeRequired: true,
      panelOrientation: 'landscape',
      slopeRange: [0, 3],
      moduleThicknessRange: [30, 50],
      moduleWidthRanges: [
        [980, 1053],
        [1068, 1104],
        [1114, 1150],
      ],
      moduleHeightRange: [0, 2080], // Up to 2080 for SINGLE panelConfiguration, but 2180 for DUAL panelConfiguration (not yet supported in OpenSolar)
      moduleHasSpecs: ['width', 'height', 'thickness', 'weight', 'kw_stc'],
    }
  }
}
