import moment from 'moment'
import { showNotification } from 'react-admin'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import restClient from 'restClient'
import { AuthState } from 'types/auth'
import { RoleType, RoleUserData } from 'types/roles'
import { RootState } from 'types/state'
import { clearQueryParam, loadQueryVariable } from 'util/query'
import { authSelectors } from './auth'
import { viewModeActions } from './viewMode'

export const SET_ROLE_IS_LITE = 'SET_ROLE_IS_LITE'
export const UPDATE_IS_LITE = 'UPDATE_IS_LITE'

export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_ROLE_LOADING = 'UPDATE_ROLE_LOADING'
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'

export const UPDATE_ROLE_WITH_GREENLANCER_TOKEN = 'UPDATE_ROLE_WITH_GREENLANCER_TOKEN'
export const REMOVE_GREENLANCER_TOKEN_FROM_ROLE = 'REMOVE_GREENLANCER_TOKEN_FROM_ROLE'
export const UPDATE_ROLE_MOSAIC_ENABLED = 'UPDATE_ROLE_MOSAIC_ENABLED'
export const UPDATE_ROLE_SUNGAGE_ENABLED = 'UPDATE_ROLE_SUNGAGE_ENABLED'
export const UPDATE_ROLE_IRONRIDGE_ENABLED = 'UPDATE_ROLE_IRONRIDGE_ENABLED'
export const UPDATE_ROLE_BRIGHTE_ENABLED = 'UPDATE_ROLE_BRIGHTE_ENABLED'

const API_URL = window.API_ROOT + '/api'

// when a user connects their account with GreenLancer we don't go fetch the newly upated role record. Instead upon success
// we'll just fudge it with a future date and a fake token. They aren't used client side for anything other than to tell components
export const updateRoleWithGreenLancerToken = (roleId: number | undefined) => ({
  type: UPDATE_ROLE_WITH_GREENLANCER_TOKEN,
  payload: { token: 'FAKE_TOKEN', date: moment().add(30, 'days'), roleId: roleId },
})

export const removeGreenLancerTokenFromRole = (roleId: any) => ({
  type: REMOVE_GREENLANCER_TOKEN_FROM_ROLE,
  payload: { roleId },
})

export const updateRoleMosaicEnabled = (isNowEnabled: boolean, roleId: number | undefined) => ({
  type: UPDATE_ROLE_MOSAIC_ENABLED,
  payload: { isNowEnabled, roleId },
})

export const updateRoleSungageEnabled = (isNowEnabled: boolean, roleId: number | undefined) => ({
  type: UPDATE_ROLE_SUNGAGE_ENABLED,
  payload: { isNowEnabled, roleId },
})

export const updateRoleIronridgeEnabled = (email: string, accepted_terms: boolean, roleId: number) => ({
  type: UPDATE_ROLE_IRONRIDGE_ENABLED,
  payload: { email, accepted_terms, roleId },
})

export const updateRoleBrighteEnabled = (roleId: number) => ({
  type: UPDATE_ROLE_BRIGHTE_ENABLED,
  payload: { roleId },
})

export const setRoleIsLite = (value: boolean, roleId?: number): SetRoleIsLiteAction => ({
  type: SET_ROLE_IS_LITE,
  payload: { roleId, value },
})

export const updateRole = (updates: Partial<RoleType>, roleId?: number): UpdateRoleAction => ({
  type: UPDATE_ROLE,
  payload: { roleId, updates },
})

export const updateIsLite = (checkQuery?: boolean): UpdateIsLiteAction => ({
  type: UPDATE_IS_LITE,
  payload: { checkQuery },
})

interface UpdateIsLiteAction {
  type: typeof UPDATE_IS_LITE
  payload: { checkQuery?: boolean }
}

interface UpdateRoleAction {
  type: typeof UPDATE_ROLE
  payload: { roleId: number | undefined; updates: Partial<RoleType> }
}

interface SetRoleIsLiteAction {
  type: typeof SET_ROLE_IS_LITE
  payload: { roleId: number | undefined; value: boolean }
}

export default function rolesReducer(previousState: AuthState, { type, payload }: { type: string; payload: any }) {
  if (!previousState) return previousState

  if (type === UPDATE_ROLE_WITH_GREENLANCER_TOKEN) {
    if (!previousState.roles || !payload.roleId) return { ...previousState }
    let roles = previousState.roles?.map((role: any) => {
      if (role.id === payload.roleId) {
        role.greenlancer_token = payload.token
        role.greenlancer_token_expiration = payload.date
      }
      return role
    })
    return {
      ...previousState,
      roles: roles,
    }
  } else if (type === REMOVE_GREENLANCER_TOKEN_FROM_ROLE) {
    if (!previousState || !previousState.roles || !payload.roleId) return previousState
    let roles = previousState.roles?.map((role: any) => {
      if (role.id === payload.roleId) {
        role.greenlancer_token = null
        role.greenlancer_token_expiration = null
      }
      return role
    })
    return {
      ...previousState,
      roles: roles,
    }
  } else if (type === UPDATE_ROLE_MOSAIC_ENABLED) {
    if (!previousState || !previousState.roles || !payload.roleId) return previousState
    let roles = previousState.roles?.map((role: any) => {
      if (role.id === payload.roleId) {
        role.mosaic_enabled = payload.isNowEnabled
      }
      return role
    })
    return {
      ...previousState,
      roles: roles,
    }
  } else if (type === UPDATE_ROLE_SUNGAGE_ENABLED) {
    if (!previousState || !previousState.roles || !payload.roleId) return previousState
    let roles = previousState.roles?.map((role: any) => {
      if (role.id === payload.roleId) {
        role.sungage_enabled = payload.isNowEnabled
      }
      return role
    })
    return {
      ...previousState,
      roles: roles,
    }
  } else if (type === UPDATE_ROLE_IRONRIDGE_ENABLED) {
    if (!previousState || !previousState.roles) return previousState
    let roles = previousState.roles?.map((role) => {
      if (role.id === payload.roleId) {
        role.ironridge_email = payload.email
        role.ironridge_terms_accepted = payload.accepted_terms
      }
      return role
    })
    return {
      ...previousState,
      roles: roles,
    }
  } else if (type === UPDATE_ROLE_BRIGHTE_ENABLED) {
    if (!previousState || !previousState.roles) return previousState
    let roles = previousState.roles?.map((role) => {
      if (role.id === payload.roleId) {
        role.brighte_connected = true
      }
      return role
    })
    return {
      ...previousState,
      roles: roles,
    }
  } else if (type === UPDATE_ROLE_SUCCESS) {
    if (!previousState || !previousState.roles || !payload.role) return previousState
    let roles = previousState.roles?.map((role) => {
      return role.id === payload.role.id ? payload.role : role
    })
    return {
      ...previousState,
      roles: roles,
    }
  }

  return previousState
}

// selectors
export const rolesSelectors = {
  getRoleById: (state: RootState, roleId: string): RoleType | undefined => {
    if (roleId) return state.auth?.roles.filter((role) => `${role.id}` === roleId)[0]
    else return undefined
  },
  getCurrentRole: (state: RootState): RoleType | undefined => {
    const orgId = authSelectors.getOrgId(state)
    if (orgId) return state.auth?.roles.filter((role) => role.org_id === orgId)[0]
    else return undefined
  },
  getCurrentRoleId: (state: RootState): number | undefined => {
    const role = rolesSelectors.getCurrentRole(state)
    return role?.id
  },
  getRoleHasPremiumImageryAccess: (state: RootState): boolean => {
    const role = rolesSelectors.getCurrentRole(state)
    if (!role) return false
    return role.permissions?.purchases_for_projects?.create === 1
  },
  getBrightRoleConnectedById: (state: any, roleId: string) => {
    return roleId ? rolesSelectors.getRoleById(state, roleId)?.brighte_connected : false
  },
  getBrighteRoleConnected: (state: any) => {
    const role = rolesSelectors.getCurrentRole(state)
    return role?.brighte_connected
  },
  getIsRoleConnectedToDividend: (state: RootState): boolean => {
    const role = authSelectors.getCurrentRole(state)
    if (!role) return false
    //TODO: Fix this, doesn't look like it will ever return true
    //@ts-ignore
    return !!role.permissions?.dividend_connected
  },
  getUserData: (state: RootState): RoleUserData => {
    const role = authSelectors.getCurrentRole(state)
    return role?.user_data || {}
  },
}

// Sagas

export function* rolesSagas() {
  yield all([takeEvery(UPDATE_ROLE, updateRoleSaga)])
  yield all([takeEvery(SET_ROLE_IS_LITE, setRoleIsLiteSaga)])
  yield all([takeEvery(UPDATE_IS_LITE, updateIsLiteSaga)])
}

export function* setRoleIsLiteSaga(action: SetRoleIsLiteAction) {
  let user_data: RoleUserData = yield select(rolesSelectors.getUserData)
  user_data.is_lite = action.payload.value
  const res: RoleType | undefined = yield put(
    updateRole(
      {
        user_data,
      },
      action.payload.roleId
    )
  )
}

export function* updateIsLiteSaga(action: UpdateIsLiteAction) {
  if (action.payload?.checkQuery) {
    const isLiteStr = loadQueryVariable('is_lite')
    if (isLiteStr !== undefined) {
      // Sometimes, instead of loading from the user's data, we
      // update the user's data from the query string instead
      const isLite = isLiteStr !== '0' && isLiteStr !== 'false'
      yield put(setRoleIsLite(isLite))
      clearQueryParam('is_lite')
      return
    }
  }
  let user_data: RoleUserData = yield select(rolesSelectors.getUserData)
  yield put(viewModeActions.setIsUserLite(!!user_data.is_lite))
}

export function* updateRoleSaga(action: UpdateRoleAction) {
  yield put({ type: UPDATE_ROLE_LOADING, payload: {} })
  const orgId = yield select(authSelectors.getOrgId)
  let roleId: number = action.payload.roleId || (yield select(rolesSelectors.getCurrentRoleId))
  try {
    const role = yield select(rolesSelectors.getRoleById, roleId + '')
    const new_role: Partial<RoleType> = {
      permissions_role: role.permissions_role,
      ...action.payload.updates,
    }
    const res: RoleType | undefined = yield call(saveRoleRest, orgId, roleId, new_role)
    yield put({ type: UPDATE_ROLE_SUCCESS, payload: { role: res } })
    yield put(updateIsLite())
  } catch (e) {
    yield put({ type: UPDATE_ROLE_FAIL })
    yield put(showNotification('Failed to update role', 'warning'))
  }
}
export function saveRoleRest(orgId: number, roleId: number, role: Partial<RoleType>): Promise<RoleType> {
  return new Promise<RoleType>((resolve, reject) => {
    const restClientInstance = restClient(API_URL)
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/roles/${roleId}/`,
      data: role,
      headers: {
        'Response-Format': 'as_object',
      },
    })
      .then((response: any) => {
        console.debug('Role Updated: ', response)
        resolve(response.data as RoleType)
      })
      .catch((err: any) => {
        if (!err.body?.mfa_status) console.warn('Failed to update role: ', err)
        reject(err)
      })
  })
}
