"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildConfig = void 0;
var browser_1 = require("./browser");
var client_check_1 = require("./client_check");
var network_1 = require("./network");
var query_1 = require("./query");
function buildConfig(config_1) {
    return __awaiter(this, arguments, void 0, function (config, logger) {
        var nested, master, queryOptsStr, queryOpts, env, hostname_spa, hostname_api, is_internal, onLocalhost, appRoot, remoteConfigs, _a, e_1, ret;
        if (logger === void 0) { logger = console; }
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    nested = (0, browser_1.isNestedWindow)();
                    master = config.master === undefined ? nested : config.master;
                    if (nested) {
                        queryOptsStr = (0, query_1.getQueryVariable)('config');
                        if (queryOptsStr) {
                            try {
                                queryOpts = JSON.parse(decodeURIComponent(queryOptsStr));
                                config = __assign(__assign({}, queryOpts), config);
                            }
                            catch (e) {
                                throw new Error('Failed to parse config from query string, SDK will not load: ' + queryOptsStr);
                            }
                        }
                    }
                    env = config.env;
                    if (!env) {
                        // Fallback to 'production', this is only used to assume the right hostnames
                        env = 'production';
                    }
                    hostname_spa = config.hostname_spa;
                    if (!hostname_spa && env) {
                        hostname_spa = (0, network_1.getOsUrl)('spa', env);
                    }
                    if (!hostname_spa) {
                        throw new Error("Couldn't resolve 'hostname_spa', specify an 'env' or explicitly define 'hostname_spa'");
                    }
                    hostname_api = config.hostname_api;
                    if (!hostname_api && env) {
                        hostname_api = (0, network_1.getOsUrl)('api', env);
                    }
                    if (!hostname_api) {
                        throw new Error("Couldn't resolve 'hostname_api', specify an 'env' or explicitly define 'hostname_api'");
                    }
                    is_internal = window.location.origin === hostname_spa;
                    if (!config.key && !is_internal) {
                        onLocalhost = window.location.hostname === 'localhost';
                        // Note: this is just to help 3rd party devs, not a security check
                        if (!onLocalhost)
                            throw new Error('Must provide a key when running in a 3rd party context');
                        else
                            logger.warn("Running in localhost without a key, this won't work in other environments");
                    }
                    if (is_internal) {
                        appRoot = window.API_ROOT_ABSOLUTE;
                        if (appRoot !== hostname_api)
                            throw new Error("hostname_spa doesn't match configuration in loaded app (via hostname_spa), please check configuration.");
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 5, , 6]);
                    if (!config.key) return [3 /*break*/, 3];
                    return [4 /*yield*/, getRemoteConfigs(config.key, hostname_api)];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = { low: {}, high: {} };
                    _b.label = 4;
                case 4:
                    // The real security check happens here, and this is execute on both side of the iframe
                    remoteConfigs = _a;
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _b.sent();
                    throw new Error("Invalid client key: ".concat(config.key));
                case 6:
                    ret = __assign(__assign(__assign(__assign({}, remoteConfigs.low), config), { is_internal: is_internal, nested: nested, master: master, hostname_spa: hostname_spa, hostname_api: hostname_api }), remoteConfigs.high);
                    if (ret.domain_whitelist && !(0, client_check_1.client_check)(ret.domain_whitelist, nested, document.referrer)) {
                        throw new Error('Domain not whitelisted: ' + document.referrer);
                    }
                    logger.info('SDK Config resolved: ', ret);
                    return [2 /*return*/, ret];
            }
        });
    });
}
exports.buildConfig = buildConfig;
function getRemoteConfigs(key, hostname_api) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(hostname_api, "/api/sdk_client_config/").concat(key, "/");
                    return [4 /*yield*/, fetch(url, {
                            method: 'GET',
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
