import { makeStyles } from '@material-ui/core/styles'
import { FileOutlineIcon, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'
import { useTranslate } from 'ra-core'

import React, { createContext, useCallback, useMemo, useState } from 'react'
import { Theme } from 'types/themes'

import { SwapHoriz } from '@material-ui/icons'
import { COLOR_PALETTE, getProjectSectionStyles } from '../../../styles'
import { FilesSection } from './files/section/FilesSection'
import { ToolBar } from './FilesAndTransactionsToolBar'
import { TransactionsSection } from './transactions/TransactionsSection'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: 5,
    height: 10,
  },
  sectionContainer: {
    maxHeight: 400,
    display: 'flex',
    minWidth: 'calc(50% - 15px)', // set so it takes at least half of container if there's no ad
    flex: 3, // set so it expands to 3/4 of container if there is an add
    flexDirection: 'column',
    borderRadius: 8,
    backgroundColor: theme.white,
    boxSizing: 'border-box',
    '& > nav': {
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
      flexWrap: 'wrap', // so buttons fit on screen (this section has a search bar and other sections don't)
      gap: 10, //so rows have a gap if wrapped
    },
    '& > main': {
      overflow: 'auto',
      overflowX: 'hidden',
      marginBottom: 15,
      display: 'grid',
      height: '100%',
      flex: 1,
    },
    '& > main > section': {
      margin: '15px 10px 0px', // set to accomodate for react-admin list/tinny box padding which is '5px 10px 0px 10px' so totals 20px
      display: 'grid',
      alignSelf: 'start',
    },
  },
}))

export interface FilesAndTransContextProps {
  tabSelected: string
  ctaSelected: string
  selectCta: (i: string) => void
  searchTerm: string
  addSearchTerm: (i: string) => void
}

export const FilesAndTransContext = createContext<FilesAndTransContextProps | null>(null)

const FilesAndTransactionsSection: React.FC = () => {
  const classes = useStyles()
  const sectionStyles = getProjectSectionStyles()

  const [tabSelected, setTabSelected] = useState('documents')
  const [ctaSelected, setCtaSelected] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const selectCta = useCallback((cta) => setCtaSelected(cta), [])
  const addSearchTerm = useCallback((term) => setSearchTerm(term), [])
  const selectTab = useCallback((tab) => {
    setTabSelected(tab)
    setCtaSelected('')
    setSearchTerm('')
  }, [])

  const viewContext: FilesAndTransContextProps = useMemo<FilesAndTransContextProps>(
    () => ({
      tabSelected,
      ctaSelected,
      selectCta,
      searchTerm,
      addSearchTerm,
    }),
    [tabSelected, selectTab, ctaSelected, selectCta, searchTerm, addSearchTerm]
  ) as FilesAndTransContextProps

  const translate = useTranslate()

  return (
    <FilesAndTransContext.Provider value={viewContext}>
      <div className={`${classes.sectionContainer} ${sectionStyles.moduleHalfWidth}`}>
        <nav>
          <ToggleButtonGroup exclusive value={tabSelected}>
            <ToggleButton value="documents" onClick={() => selectTab('documents')}>
              <div className={`${classes.icon} ${sectionStyles.row}`}>
                <FileOutlineIcon variant={1} size={15} />
              </div>
              {translate('Documents')}
            </ToggleButton>
            <ToggleButton value="transactions" onClick={() => selectTab('transactions')}>
              <div className={`${classes.icon} ${sectionStyles.row}`}>
                <SwapHoriz />
              </div>
              {translate('Transactions')}
            </ToggleButton>
          </ToggleButtonGroup>
          <ToolBar />
        </nav>
        <main>
          <section>{tabSelected === 'documents' && <FilesSection />}</section>
          <section> {tabSelected === 'transactions' && <TransactionsSection />}</section>
        </main>
      </div>
    </FilesAndTransContext.Provider>
  )
}

export default FilesAndTransactionsSection
