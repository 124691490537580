import { FormLabel } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { orgSelectors } from 'ducks/orgs'
import DateTimeInput from 'elements/input/DateTimeInput'
import React, { useContext } from 'react'
import { AutocompleteInput, BooleanInput, ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { TRANSACTION_TYPE_CHOICES } from 'resources/transactions/Transactions'
import { taxNameForCountryIso2 } from 'util/misc'
import { getDialogStyles } from '../files/common/dialogAndFieldsStyles'

import { LOCKED_TRANSACTION_TYPES } from './consts'

export const TransactionFormFields: React.FunctionComponent<any> = (props) => {
  const translate = useTranslate()
  const dialogStyles = getDialogStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const taxName = taxNameForCountryIso2(countryIso2)
  const capitalizedTaxName = taxName.charAt(0).toUpperCase() + taxName.slice(1)
  const currencySymbol = useContext(CurrencySymbolContext)

  const fincoSearchKey = props?.record?.transaction_type || ''

  const isFinco =
    props.dialogType === 'create'
      ? false
      : props?.record?.transaction_type && LOCKED_TRANSACTION_TYPES.includes(fincoSearchKey)

  return (
    <div style={{ width: '100%' }} className={dialogStyles.fieldsStyling}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={`${dialogStyles.dialogContent} ${dialogStyles.extraPadding}`}>
            {props.dialogType === 'edit' && (
              <Grid item xs={12}>
                <div>
                  <FormLabel>{translate('ID')}</FormLabel>
                </div>
                <TextInput label={''} hiddenLabel variant="outlined" disabled={true} source="id" fullWidth />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormLabel>{translate('Amount')} *</FormLabel>
              <TextInput
                startAdornment={currencySymbol}
                label={''}
                hiddenLabel
                variant="outlined"
                disabled={isFinco}
                fullWidth
                source="amount"
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{translate('%{taxName} Included', { taxName: capitalizedTaxName })}</FormLabel>
              <TextInput
                startAdornment={currencySymbol}
                fullWidth
                label={''}
                hiddenLabel
                variant="outlined"
                disabled={!props.record}
                source="tax_included"
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{translate('Surcharge Amount')}</FormLabel>
              <TextInput
                startAdornment={currencySymbol}
                fullWidth
                label={''}
                hiddenLabel
                variant="outlined"
                disabled={!props.record}
                source="surcharge_amount"
              />
            </Grid>
            {props.dialogType === 'edit' && (
              <Grid item xs={12}>
                <FormLabel>{translate('Project')}</FormLabel>
                <ReferenceInput
                  source="project"
                  reference="projects"
                  optionValueField="url"
                  allowEmpty
                  renderChildrenBeforeRecord={true}
                  fullWidth
                  label={''}
                  hiddenLabel
                >
                  {/* @ts-ignore */}
                  <AutocompleteInput
                    options={{
                      placeholder: 'Enter a project',
                      disabled: isFinco,
                    }}
                    fullWidth
                    optionText="address"
                    optionValue="url"
                    variant="outlined"
                  />
                </ReferenceInput>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={`${dialogStyles.booleanFieldWrapper} `}>
                <p>{translate('Payment is complete')} </p>
                <BooleanInput options={{ color: 'primary' }} label="" source="is_complete" disabled={isFinco} />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <h2 className={dialogStyles.innerTitle}>
            {translate('Details for Completed Payments')} ({translate('leave empty for payment requests')})
          </h2>
        </Grid>
        <Grid container spacing={1} className={dialogStyles.innerSection}>
          <Grid item xs={12}>
            <FormLabel>{translate('Transaction Type')}</FormLabel>
            <SelectInput
              source="transaction_type"
              choices={TRANSACTION_TYPE_CHOICES}
              allowEmpty
              fullWidth={true}
              style={{ width: '100%' }}
              disabled={isFinco}
              variant="outlined"
              label={false}
            />
          </Grid>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <DateTimeInput
              source="transaction_datetime"
              options={{
                inputVariant: 'outlined',
                format: 'MM/DD/YYYY h:m A',
              }}
              label={'Transaction Date and Time'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <div className={`${dialogStyles.booleanFieldWrapper} ${dialogStyles.booleanFieldWrapperBottom}`}>
              <p>{translate('Funds are confirmed')} </p>
              <BooleanInput options={{ color: 'primary' }} source="funds_confirmed" label={''} disabled={isFinco} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default TransactionFormFields
