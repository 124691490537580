import { makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { useGetCashFlowNavVisible } from 'pages/cashFlow/utils'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OpenSolarThemeType } from 'Themes'
import { OrgType } from 'types/orgs'
import { useFeatureFlag } from 'util/split'
import StyledNavLink, { useNavLinkStyles } from './StyledNavLink'

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface PropTypes {
  org: OrgType
  layout: number
}

const getActiveNavLinkOverrideConfig = (projectSection, link, classes) => {
  //nasty hack to bold "Home" instead of bolding "Projects" for explore page
  const isExplorePage = projectSection === 'explore'
  if (isExplorePage && link === 'home') {
    return {
      activeStyle: classes.navLinkActive,
      style: classes.navLinkActive,
    }
  } else if (isExplorePage && link === 'projects') {
    return {
      activeStyle: classes.navLink,
      style: classes.navLink,
    }
  } else {
    return {}
  }
}

const Navigation: React.FC<PropTypes> = ({ org, layout, ...props }) => {
  const classes = useStyles()
  const navLinkStyles = useNavLinkStyles({})
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const projectSection = useSelector((state: any) => state.project?.section)
  const cashFlowEnabled = useGetCashFlowNavVisible()
  const zoneOrg = org?.zone_org
  const uiKey = 'header.nav'
  const btnProps = { layout, ...props, uiKey }

  const cashFlowOnboardingStatus = useSelector(orgSelectors.getBlueSnapOnboardingStatus)

  const cashFlowBadgeSeverity = useMemo(() => {
    if (cashFlowOnboardingStatus === 'created' || cashFlowOnboardingStatus === null) {
      return 'info'
    }
    return undefined
  }, [cashFlowOnboardingStatus])

  return (
    <div className={classes.linkWrapper}>
      {layout >= 2 && (
        <>
          <StyledNavLink
            uiKey={uiKey + '.home'}
            to="/home"
            label="Home [App Navigation]"
            layout={layout}
            {...getActiveNavLinkOverrideConfig(projectSection, 'home', navLinkStyles)}
          />

          <StyledNavLink
            uiKey={uiKey + '.projects'}
            to="/projects"
            label="Projects"
            layout={layout}
            {...getActiveNavLinkOverrideConfig(projectSection, 'projects', navLinkStyles)}
          />

          <StyledNavLink
            uiKey={uiKey + '.control'}
            to="/control"
            label="Control"
            layout={layout}
            {...getActiveNavLinkOverrideConfig(projectSection, 'control', navLinkStyles)}
          />

          {enableHardwareOrdering && <StyledNavLink uiKey={uiKey + '.shop'} to="/shop" label="Shop" layout={layout} />}

          {!!zoneOrg && (
            <StyledNavLink uiKey={uiKey + '.zone'} to="/zone" label={zoneOrg.zone_title || 'Zone'} layout={layout} />
          )}
          {cashFlowEnabled ? (
            <StyledNavLink
              uiKey={uiKey + 'cashflow'}
              to="/cashflow"
              label="CashFlow"
              layout={layout}
              badgeSeverity={cashFlowBadgeSeverity}
              {...getActiveNavLinkOverrideConfig(projectSection, 'cashflow', navLinkStyles)}
            />
          ) : null}
        </>
      )}
    </div>
  )
}
export default Navigation
