import CustomField from 'elements/field/CustomField'
import { styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { SelectInput, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { FilterField, OS_PROJECT_FILTERS } from './fields'

interface PropTypes {
  source: string
  filter: FilterField
  filtersAdded: string[]
}

const FilterRow = styled('div')({
  display: 'flex',
  width: '100%',
  gap: 10,
})

const Placeholder = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}))

const FilterDisplay: React.FC<PropTypes> = ({ source, filter, filtersAdded }) => {
  const form = useForm()
  const translate = useTranslate()
  const filteredOptions = useMemo(
    () => OS_PROJECT_FILTERS.filter((x) => !filtersAdded.includes(x.id) || filter.fieldId === x.id),
    [filter.fieldId, filtersAdded]
  )
  const fieldData = useMemo(() => OS_PROJECT_FILTERS.find((x) => x.id === filter.fieldId), [filter.fieldId])
  return (
    <FilterRow>
      <SelectInput
        source={`${source}.fieldId`}
        label={false}
        variant="outlined"
        choices={filteredOptions}
        margin="none"
        onChange={(e) => {
          //clear field values
          form.change(`${source}.fields`, {})
          form.change(`${source}.value`, {})
        }}
        SelectProps={{
          displayEmpty: true,
          native: undefined,
          renderValue: (value) => {
            const findItem = filteredOptions.find((x: any) => x.id === value)
            return findItem?.name || <Placeholder>{translate('Select filter')}</Placeholder>
          },
        }}
      />
      {fieldData?.field && (
        <CustomField
          source={source}
          name={source}
          component={fieldData?.field}
          fieldId={fieldData.id}
          {...(fieldData.props || {})}
        />
      )}
    </FilterRow>
  )
}

export default FilterDisplay
