import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import ChartistGraph from 'react-chartist'
import { useSelector } from 'react-redux'
import { formatCurrencyWithSymbol } from 'util/misc'

type PropTypes = {
  totalPayable: number
  totalPaid: number
}

const useStyles = makeStyles<OpenSolarThemeType, { isMobile: boolean }>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'row' : 'row'),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  chartWrapper: {
    flex: 3,
  },
  textWrapper: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    color: theme.greyLight2,
    margin: '5px',
  },
  paidValue: {
    color: theme.alertIcon_success,
    fontWeight: 500,
  },
  payableValue: {
    color: theme.greyDark2,
  },
  paid: {
    fill: theme.alertIcon_success,
  },
  payable: {
    fill: theme.greyLight2,
  },
}))

const PaymentsPieChart: React.FC<PropTypes> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles({ isMobile })
  const translate = useTranslate()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)

  const startAngle = useMemo(() => {
    let percentPaid = props.totalPaid / props.totalPayable
    return percentPaid * 360
  }, [props.totalPaid, props.totalPayable])

  return (
    <div className={classes.wrapper}>
      <div className={classes.chartWrapper}>
        <ChartistGraph
          data={{
            labels: ['Payable', 'Paid'],
            series: [
              { value: props.totalPayable - (props.totalPaid || 0), className: classes.payable },
              { value: props.totalPaid, className: classes.paid },
            ],
          }}
          //@ts-ignore
          options={{ showLabel: false, startAngle: startAngle, height: '100px', width: '100px' }}
          type={'Pie'}
        />
      </div>
      <div className={classes.textWrapper}>
        <div className={classes.label}>{translate('Collected')}</div>
        <div className={classes.paidValue}>
          {formatCurrencyWithSymbol(props.totalPaid, currencySymbol, undefined, 0)}
        </div>
        <div className={classes.label}>{translate('Job Value')}</div>
        <div className={classes.payableValue}>
          {formatCurrencyWithSymbol(props.totalPayable, currencySymbol, undefined, 0)}
        </div>
      </div>
    </div>
  )
}
export default PaymentsPieChart
