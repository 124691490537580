import CircularProgress from '@material-ui/core/CircularProgress'
import type { TextFieldProps } from '@material-ui/core/TextField'
import TextField from '@material-ui/core/TextField'
import type { OpenSolarThemeType } from 'Themes'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type BasicInputProps = {
  loading?: boolean
  disabled?: boolean
  fullWidth?: boolean
  value: string
  placeholder?: string
  type?: string
  inputProps?: TextFieldProps['inputProps']
  InputProps?: TextFieldProps['InputProps']
  className?: TextFieldProps['className']
  onChange: (value: string) => void
  onFocus?(): void
  onBlur?(value: string): void
  onEnter?(value: string): void
  variant?: TextFieldProps['variant']
}

const useStyles = makeOpenSolarStyles(
  (theme: OpenSolarThemeType) => ({
    textField: {
      zIndex: 1,
      padding: 0,
      border: 0,
      background: 'rgb(255,255,255)',
      borderRadius: '4px',
    },
    input: {
      fontSize: '16px',
      padding: '5px 8px',
    },
    loadingProgress: {
      // TODO: fix me when BasicInput accept size props.
      // For now let's hard coded for small size
      width: 20,
    },
  }),
  { name: 'Basic-Input' }
)

const BasicInput = ({
  loading,
  value,
  disabled,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  onEnter,
  inputProps = {},
  InputProps = {},
  type,
  variant,
  fullWidth,
  className,
}: BasicInputProps) => {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [internalValue, setInternalValue] = useState(value || '')

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback((e) => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value
      onEnter?.(value)
    }
  }, [])

  const handleBlur = useCallback(() => {
    setIsEditing(false)
    onBlur?.(internalValue)
  }, [internalValue])

  const handleFocus = useCallback(() => {
    setIsEditing(true)
    onFocus?.()
  }, [])

  useEffect(() => {
    if (isEditing) {
      return
    } else {
      if (value !== internalValue) {
        setInternalValue(value)
      }
    }
  }, [internalValue, isEditing, value])

  const handleChange = (eventOrValue: React.ChangeEvent<{ value: string }>) => {
    const event = eventOrValue as React.ChangeEvent<{ value: string }>
    const value = event.target && event.target.value
    onChange(value)
    setInternalValue(value)
  }

  return (
    <TextField
      className={className}
      value={internalValue}
      placeholder={placeholder}
      size="small"
      variant={variant}
      fullWidth={fullWidth}
      inputProps={{
        className: classes.input,
        disableUnderline: true,
        ...inputProps,
      }}
      disabled={disabled}
      type={type}
      InputProps={{
        className: classes.textField,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onChange: handleChange,
        onKeyDown: handleKeyDown,
        endAdornment: loading ? <CircularProgress size={'small'} className={classes.loadingProgress} /> : undefined,
        ...InputProps,
      }}
    />
  )
}

export default memo(BasicInput)
