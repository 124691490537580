import { Card, FormControlLabel, MenuItem, Select } from '@material-ui/core'
import UiContainer from 'elements/UiContainer'
import { AVAILABLE_HOST_CONFIGS } from 'host/useHostConfig'
import { ComponentVersionsKey, setStoredComponentVersion, Switch, useComponentVersion } from "opensolar-ui"
import ProductsSyncButton from 'pages/ordering/sync/ProductsSyncButton'
import { FC } from 'react'
import appStorage from 'storage/appStorage'
import { doNotTranslate, reloadEntireApp } from 'util/misc'
import ComponentsSyncButton from '../ordering/sync/ComponentsSyncButton'
import SyncWebhooksButton from './SyncWebhooksButton'

type Props = {
  uiKey: string
  classes: {
    page: string
    wrapper: string
    label: string
    button: string
    box: string
  }
}

export const StaffUserSettings: FC<Props> = ({ uiKey, classes }) => {
  return (
    <UiContainer uiKey={uiKey}>
      <Card className={classes.page}>
        <div className={classes.wrapper}>
          <div className={classes.label}>{doNotTranslate('Staff Only:')}</div>
          <StaffLocalStorageToggle
            label="Show Translation Icons"
            className={classes.button}
            field="showTranslationIcons"
            reloadApp={true}
          />
          <StaffLocalStorageToggle
            label="Enable UI Inspector"
            className={classes.button}
            field="enableUiInspector"
            reloadApp={true}
          />
          <UiComponentVersionSwitcher
            className={classes.box}
            label="Button"
            componentName="button"
            options={ButtonVersions}
          />
          <UiComponentVersionSwitcher
            className={classes.box}
            label="IconButton"
            componentName="icon_button"
            options={IconButtonVersions}
          />
          <UiComponentVersionSwitcher
            className={classes.box}
            label="Chip"
            componentName="chip"
            options={ChipVersions}
          />
          <UiComponentVersionSwitcher
            className={classes.box}
            label="Switch"
            componentName="switch"
            options={SwitchVersions}
          />
          <UiComponentVersionSwitcher
            className={classes.box}
            label="Alert"
            componentName="alert"
            options={AlertVersions}
          />
          <LocalStorageDropdownSwitcher
            className={classes.box}
            label="Host Variant"
            field="host_config"
            options={HostVariants}
          />
          <ProductsSyncButton />
          <ComponentsSyncButton />
          <SyncWebhooksButton />
        </div>
      </Card>
    </UiContainer>
  )
}

type StaffLocalStorageToggleProps = {
  label: string
  className?: string
  field: string
  reloadApp?: boolean
}

const StaffLocalStorageToggle = ({ label, className, reloadApp, field }: StaffLocalStorageToggleProps) => {
  const value = appStorage.getBool(field)

  const toggleValue = () => {
    appStorage.setBool(field, !value)
    if (reloadApp) reloadEntireApp()
  }

  return (
    <FormControlLabel
      className={className}
      control={<Switch defaultChecked={value} onChange={() => toggleValue()} />}
      label={doNotTranslate(label)}
    />
  )
}

type UiComponentVersionSwitcherProps = Omit<GenericSwitcherProps<number>, 'value' | 'onChange'> & {
  componentName: ComponentVersionsKey
}

const UiComponentVersionSwitcher = ({ componentName, ...props }: UiComponentVersionSwitcherProps) => {
  const value = useComponentVersion(componentName)
  const onChange = (value: number) => {
    setStoredComponentVersion(componentName, value)
    reloadEntireApp()
  }

  return <GenericDropdownSwitcher {...props} value={value} onChange={onChange} />
}

type LocalStorageSwitcherProps<T> = Omit<GenericSwitcherProps<T>, 'value' | 'onChange'> & {
  field: string
}

// Only supports string values atm
const LocalStorageDropdownSwitcher = ({ field, ...props }: LocalStorageSwitcherProps<string>) => {
  const value = appStorage.getString(field)
  const onChange = (value: string) => {
    appStorage.setString(field, value)
    reloadEntireApp()
  }

  //@ts-ignore
  return <GenericDropdownSwitcher value={value} onChange={onChange} {...props} />
}

type GenericSwitcherProps<T> = {
  label: string
  className?: string
  value?: T
  onChange: (value: T) => void
  options: {
    label: string
    value: T | undefined
  }[]
}

const GenericDropdownSwitcher = <T extends string | number>({
  label,
  className,
  value,
  onChange,
  options,
}: GenericSwitcherProps<T>) => {
  return (
    <Card className={className}>
      <h2>{doNotTranslate(label)}</h2>
      <Select
        value={value}
        displayEmpty={true}
        onChange={(event) => {
          onChange(event.target.value as T)
        }}
      >
        {options.map(({ label, value }) => {
          return (
            <MenuItem key={label + value} value={value}>
              {doNotTranslate(label)}
            </MenuItem>
          )
        })}
      </Select>
    </Card>
  )
}

const ButtonVersions = [
  {
    value: undefined,
    label: 'Auto (V1)',
  },
  {
    value: 1,
    label: 'V1 (MUI4 + OS Style)',
  },
  {
    value: 2,
    label: 'V2 (Style fixes)',
  },
  {
    value: 3,
    label: 'V3 (MUI5 + Stratis Style)',
  },
]

const IconButtonVersions = [
  {
    value: undefined,
    label: 'Auto (V1)',
  },
  {
    value: 1,
    label: 'V1 (MUI4 + OS Style)',
  },
  {
    value: 2,
    label: 'V2 (MUI5 + Stratis Style)',
  },
]

const ChipVersions = [
  {
    value: undefined,
    label: 'Auto (V1)',
  },
  {
    value: 1,
    label: 'V1 (MUI4 Default)',
  },
  {
    value: 2,
    label: 'V2 (MUI4 + Stratis Style)',
  },
]

const SwitchVersions = [
  {
    value: undefined,
    label: 'Auto (V1)',
  },
  {
    value: 1,
    label: 'V1 (MUI4 + OS Style)',
  },
  {
    value: 2,
    label: 'V2 (MUI5 + Stratis Style)',
  },
]

const AlertVersions = [
  {
    value: undefined,
    label: 'Auto (V1)',
  },
  {
    value: 1,
    label: 'V1 (OS UI 2.0)',
  },
  {
    value: 2,
    label: 'V2 (OS UI 3.0 Stratis)',
  },
]

const HostVariants = [
  {
    value: undefined,
    label: 'Default',
  },
  ...AVAILABLE_HOST_CONFIGS.map((config) => ({
    value: config.name,
    label: config.name,
  })),
]
