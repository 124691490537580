import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import Tooltip from 'elements/tooltip/Tooltip'
import { getOrderCost } from 'pages/ordering/utils'
import React, { memo } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import PriceBreakdown from '../PriceBreakdown'
import Alert from 'elements/Alert'
import { doNotTranslate } from 'util/misc'
import { CityPlumbingConnectUserButton } from 'services/cityPlumbing/CityPlumbingConnectUserButton'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'

const ToolTipMessage = 'Create a job list with City Plumbing.'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    padding: 15,
    marginBottom: 15,
  },
  textWrapper: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const CityPlumbingCartActions = () => {
  const classes = useStyles()
  const orderableLineItems = useSelector(orderSelectors.getOrderableLineItems)
  const orgId = useSelector(authSelectors.getOrgId) || 0
  const { isAuthenticated } = useDistributorAuthenticationStatus({orgId, distributor: 'city_plumbing'})
  const history = useHistory()
  const notify = useNotify()
  const translate = useTranslate()

  // TODO discounting on CP? I don't think so.
  const cost = getOrderCost(orderableLineItems)

  const handleCtaClick = async (event) => {
    event.preventDefault()

    logAmplitudeEvent('hardware_review_order_cta_clicked', {
      distributor: 'city_plumbing',
      source: 'shopping_cart',
      codes: orderableLineItems.map((lineItem) => lineItem.code),
    })
    console.log('lineItems', orderableLineItems)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: 'orgs/' + orgId + '/_orders/city_plumbing/create/',
      data: {
        items: orderableLineItems,
        project_ids: orderSelectors.getProjectListByLineItems(orderableLineItems),
      },
    })
      .then((response) => {
        console.log('send job. got data', response.data)
        if (!response.data?.status) {
          notify(response.data?.result.error)
          return
        }

        const jobListId = response.data['result']['jobList']['id']
        history.push(`/shop/cart/city_plumbing/${jobListId}`)
      })
      .catch((error) => {
        if (error) {
          console.warn('Error in City Plumbing Order', error)
        }
      })
  }

  return (
    <>
      {!isAuthenticated && (
        <div className={classes.wrapper}>
          <Alert severity="warning">
            {doNotTranslate(
              'You need to be logged into City Plumbing to place your quote.'
            )}
          </Alert>
        </div>
      )}
      <div className={classes.wrapper}>
        <PriceBreakdown
          currencySymbol="£"
          orderableLineItems={orderableLineItems}
        />
        <Tooltip title={ToolTipMessage} interactive={true} arrow placement="top">
          {isAuthenticated ? (
            <Button color="primary" disabled={orderableLineItems.length === 0} fullWidth={true} onClick={handleCtaClick}>
              Create City Plumbing Job List
            </Button>
          ) : (
            <CityPlumbingConnectUserButton
              layout={null}
              allowOrdering={false}
              variant={'contained'}
              trackSource={'city_plumbing_page'}
            />
          )}
        </Tooltip>
      </div>
    </>
  )
}

export default memo(CityPlumbingCartActions)
