import { stringify } from 'query-string'
import HttpError from './HttpError'
import { ExtendedRequestInfo, ExtendedRequestInfoSafe, RequestMiddleware } from './fetch/types'

export const fetchRequestMiddleware: RequestMiddleware[] = []

export const fetchJson = async (url: RequestInfo, options: ExtendedRequestInfo) => {
  if (!options) options = {}
  options.format = 'json'
  return await fetchRequest(url, options)
}

export const fetchRequest = async (
  req: RequestInfo,
  options?: ExtendedRequestInfo,
  onSuccess?: (res: any, requestExtras?: RequestInit) => void
) => {
  const requestExtras = processRequestInfo(req, options)

  if (fetchRequestMiddleware.length === 0) {
    console.warn('fetch middleware not initialised yet')
  }
  let request: Request = new Request(req, requestExtras)

  for (const middleware of fetchRequestMiddleware) {
    await middleware(request, requestExtras)
  }

  // Need to regenerate the request object after middleware has run
  request = new Request(request, requestExtras)

  return fetch(request, requestExtras)
    .then((response) => {
      return response.text().then((text) => {
        return {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        }
      })
    })
    .then(({ status, statusText, headers, body }) => {
      let json
      try {
        json = JSON.parse(body)
      } catch (e) {
        // not json, no big deal
        //if succssful then return the raw body instead
        //avoid keeping raw error body if not json to avoid unexpected behavor due to error messages
        if (status >= 200 && status < 300) {
          json = body
        }
      }

      if (status >= 200 && status < 300) {
        if (onSuccess) {
          onSuccess(json, requestExtras)
        }

        return { status, headers, body, json }
      } else if (status === 503) {
        //@TODO: Use Redux to broadcast message instead of this hack
        // @ts-ignore
        window.setMaintenanceMode(true)
        // @ts-ignore
        return Promise.reject(new HttpError('Maintenance In Progress', 503, {}))
      } else {
        const messageExtracted = json ? json.error_msg || extractErrorsAsMessage(json) : 'Internal Server Error'

        // var message = (json && json.message) || statusText
        // //Inject message if available
        // if (messageExtracted.length > 0) {
        //   message = messageExtracted
        // }
        //
        return Promise.reject(new HttpError(messageExtracted, status, json))
      }
    })
    .catch((error) => {
      console.log('fetch.js: There has been a problem with your fetch operation: ', error.message)

      return Promise.reject(
        new HttpError(
          error && error.message && error.message !== 'Failed to fetch' ? error.message : 'ra.notification.http_error',
          error.status,
          error.body ? error.body : undefined
        )
      )
    })
}

export const queryParameters = stringify

const processRequestInfo = (req: RequestInfo, requestExtras?: ExtendedRequestInfo): ExtendedRequestInfoSafe => {
  if (!requestExtras) requestExtras = {}
  let headers: Headers = new Headers(requestExtras.headers)

  // Must copy these over, otherwise they get lost later
  if (req instanceof Request) {
    req.headers.forEach((value, key) => headers.set(key, value))
  }

  return {
    ...requestExtras,
    headers: headers,
  }
}

const isValidObject = (value) => {
  if (!value) {
    return false
  }

  const isArray = Array.isArray(value)
  const isBuffer = Buffer.isBuffer(value)
  const isObject = Object.prototype.toString.call(value) === '[object Object]'
  const hasKeys = !!Object.keys(value).length

  return !isArray && !isBuffer && isObject && hasKeys
}

export const flattenObject = (value: any, path: any[] = []) => {
  if (isValidObject(value)) {
    return Object.assign({}, ...Object.keys(value).map((key) => flattenObject(value[key], path.concat([key]))))
  } else {
    return path.length ? { [path.join('.')]: value } : value
  }
}
const upperFirst = (str) => {
  try {
    return str[0].toUpperCase() + str.slice(1)
  } catch (e) {
    return str
  }
}

const stripPeriodFromEnd = (str) => {
  try {
    return str.slice(-1) === '.' ? str.slice(0, -1) : str
  } catch (e) {
    return str
  }
}

const stripPeriodFromEndUpperFirst = (str) => {
  return upperFirst(stripPeriodFromEnd(str)).split('_').join(' ')
}

export const extractErrorsAsMessage = (json) => {
  const messageParts: string[] = []
  for (const key in json) {
    // If field errors is an array combine with commas
    let item

    const fieldNamePrefix = key !== 'non_field_errors' ? stripPeriodFromEndUpperFirst(key) + ': ' : ''
    const value = json[key]

    if (value === null) {
      continue
    } else if (value.constructor === Array && value.length > 0) {
      if (value[0].length) {
        // list of strings, join them.
        item = fieldNamePrefix + value.join(', ')
      } else {
        // assume nested object, only use first item and join it's strings.
        // Using Object.values would be cleaner here but per tsconfig we are targeting ES5.
        item =
          fieldNamePrefix +
          Object.keys(value[0])
            .map((key) => value[0][key][0])
            .join(', ')
      }
    } else {
      item = fieldNamePrefix + value
    }

    //If item is object, combine the key and value
    if (typeof item !== 'string') {
      const key2 = Object.keys(item)[0]
      item = key2 + ': ' + item[key2]
    }

    if (item.constructor === Boolean) {
      //continue
    } else {
      messageParts.push(stripPeriodFromEndUpperFirst(item))
    }
  }

  return messageParts.map((msg) => stripPeriodFromEndUpperFirst(msg)).join('. ')
}
