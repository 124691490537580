import { OpenSolarTheme } from 'Themes'
import React from 'react'

export const StudioFieldContainer = ({ style, label, field }) => {
  return (
    <>
      <div style={style}>
        <div style={{ fontSize: 11, color: OpenSolarTheme.greyMid1, marginBottom: 5 }}>{label}</div>
        {field}
      </div>
    </>
  )
}
