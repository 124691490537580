import { makeStyles, MenuItem, Select } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { PermissionRoleText } from 'resources/connectedOrgs/RoleSelectInput'
import { CustomRolePermissionsType, CustomRoleType } from 'types/customRole'

const useStyles = makeStyles(() => ({
  wrapper: { padding: '20px 0', display: 'flex', alignItems: 'center' },
  header: {
    padding: 5,
    fontWeight: 'bold',
  },
}))

type PropsType = {
  predefinedRoles: CustomRoleType[]
}

const BaseTemplateSelector: React.FC<PropsType> = ({ predefinedRoles = [] }) => {
  const [baseTemplate, setBaseTemplate] = useState<string | undefined>()
  const translate = useTranslate()
  const form = useForm()
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBaseTemplate(event.target.value as string)
  }
  const updateBaseTemplate = useCallback(() => {
    const record = predefinedRoles.find((role: any) => role.id === baseTemplate)
    if (!record) return

    let permissions: CustomRolePermissionsType
    try {
      permissions = JSON.parse(record.permissions)
    } catch (e) {
      console.warn('invalid permissions json data: ', e, record.permissions)
      return
    }

    const updates = {
      permissions,
      access_projects: record.access_projects,
      access_contacts: record.access_contacts,
      access_transactions: record.access_transactions,
      access_files: record.access_files,
      access_activities: record.access_activities,
    }
    for (const field in updates) {
      const value = updates[field]
      if (value === undefined) continue
      const isFieldExist = form.getFieldState(field)
      if (!isFieldExist) {
        form.registerField(field, () => {}, {})
      }
      if (permissions) {
        form.change(field, value)
      }
    }
  }, [baseTemplate])

  return (
    <div className={classes.wrapper}>
      <span>{translate('Custom role base template')}:</span>
      <Select value={baseTemplate} onChange={handleChange} style={{ minWidth: 100, margin: 10 }}>
        {predefinedRoles.map((role: any) => {
          return <MenuItem value={role.id}>{PermissionRoleText(role.title, translate)}</MenuItem>
        })}
      </Select>
      <Button variant="outlined" onClick={updateBaseTemplate} disabled={!baseTemplate}>
        {translate('Apply')}
      </Button>
    </div>
  )
}

export default BaseTemplateSelector
