import { Typography } from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrency } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme) => ({
  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  skeleton: {
    height: '20px',
    minWidth: '50px',
  },
}))

const CreditLimit = ({
  loading,
  currencySymbol = '',
  creditLimit,
  supplierName,
}: {
  loading: boolean
  currencySymbol: string | undefined
  creditLimit: number | undefined
  supplierName: string
}) => {
  const classes = useStyles()
  return (
    <Typography variant="h6" gutterBottom className={classes.textWrapper}>
      <span>{supplierName} Credit limit:</span>
      <span>
        {loading ? <Skeleton className={classes.skeleton} /> : `${currencySymbol}  ${formatCurrency(creditLimit)}`}
      </span>
    </Typography>
  )
}

export default CreditLimit
