import Grid from '@material-ui/core/Grid'
import type { OpenSolarThemeType } from 'Themes'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { useTranslate } from 'react-admin'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  tableHeaderContainer: {
    backgroundColor: theme.greyLight3,
    borderBottom: '1px solid #e7e7e7',
    minHeight: '48px',
  },
  header: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
  overflowItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

type ForecastTableHeaderPropsType = {}

const ForecastTableHeader = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Grid container xs={12} className={classes.tableHeaderContainer} alignItems="center">
      <Grid container alignItems="center" justify="center" item className={classes.header}>
        <Grid item xs={1} className={classes.overflowItem} style={{ paddingLeft: 10 }}>
          Project
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          Stage
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={2}>
          <span>{translate('Install Date')}</span>
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={2}>
          <span>{translate('Forecast Date')}</span>
          <InfoTooltip size="small" title="Add a tentative date for when you don't have an install date." />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <span>{translate('Forecast')}</span>
          <InfoTooltip size="small" title="Includes project in the installation forecast." />
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1} className={classes.overflowItem}>
          <span>{translate('Panels')}</span>
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1} className={classes.overflowItem}>
          <span>{translate('Inverters')}</span>
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <span>Batteries</span>
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <span>Others</span>
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={1}>
          <span>Missing items</span>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForecastTableHeader
