import { Portal } from '@material-ui/core'
import { withElementSize } from 'hooks/useElementBox'
import withMediaQuery from 'layout/withMediaQuery'
import { restartDesignMode } from 'projectSections/sections/design/util/restartDesignMode'
import { Component } from 'react'
import { showNotification as showNotificationAction } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { designViewSelectors } from 'reducer/designer/view'
import { getRoleFromState } from 'util/misc'
const getStudioStyle = (studioMode, layout) => {
  // Detect change in studioStyle due to change in mode or size

  if (studioMode === 'hidden') {
    return studioMode
  } else if (studioMode === 'background') {
    return studioMode
  } else if (
    studioMode === 'project' ||
    studioMode === 'myenergy' ||
    studioMode === 'studio' ||
    studioMode === 'studioLite' ||
    studioMode === 'explore'
  ) {
    let sizeToWord = {
      1: 'Small',
      2: 'Medium',
      3: 'Large',
      4: 'Huge',
      5: 'ExtraHuge', //new layout
    }
    return studioMode + sizeToWord[layout]
  } else {
    console.error(
      'Calling DesignerComponent.getStudioStyle() with studioMode not reocognized:' + studioMode + '. Assume hidden'
    )
    return 'hidden'
  }
}

const buildMapContainerStyles = () => {
  const studioHuge = {
    position: 'fixed',
    top: 100,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 20,
  }
  const studioSmall = {
    position: 'fixed',
    top: 43,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 20,
  }

  const studioLiteHuge = {
    ...studioHuge,
    top: 50,
  }
  const studioLiteSmall = {
    ...studioSmall,
    top: 50,
  }

  const myenergyHuge = {
    position: 'absolute',
    top: 0,
    left: 20,
    maxWidth: '704px',
    width: 'calc(100% - 320px)',
    height: 330,
    zIndex: 100,
    margin: 'auto',
  }

  const projectHuge = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 202,
    maxWidth: 1024,
    margin: 'auto',
  }

  return {
    hidden: {
      display: 'none',
    },
    background: {
      position: 'fixed',
      top: 100,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 0,
    },
    studioExtraHuge: studioHuge,
    studioHuge: studioHuge,
    studioLarge: studioHuge,
    studioMedium: studioHuge,
    studioSmall: studioSmall,

    studioLiteExtraHuge: studioLiteHuge,
    studioLiteHuge: studioLiteHuge,
    studioLiteLarge: studioLiteHuge,
    studioLiteMedium: studioLiteHuge,
    studioLiteSmall: studioLiteSmall,

    myenergyExtraHuge: myenergyHuge,
    myenergyHuge: myenergyHuge,
    myenergyLarge: {
      position: 'absolute',
      top: 0,
      left: 20,
      maxWidth: '704px',
      width: 'calc(100% - 320px)',
      height: 330,
      zIndex: 100,
      margin: 'auto',
    },
    myenergyMedium: {
      /* same as myenergySmall */
      position: 'absolute',
      top: 0,
      left: 20,
      maxWidth: '704px',
      width: 'calc(100% - 320px)',
      height: 330,
      zIndex: 100,
      margin: 'auto',
    },
    myenergySmall: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 200,
      zIndex: 100,
      margin: 'auto',
    },
    exploreExtraHuge: {
      position: 'absolute',
      top: 100,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      margin: 'auto',
    },
    exploreHuge: {
      position: 'absolute',
      top: 100,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      margin: 'auto',
      background:
        'radial-gradient(circle at top right, #ffffff, transparent), radial-gradient(circle at bottom right, #c0c0c0, #efefef)',
    },
    exploreLarge: {
      position: 'absolute',
      top: 100,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      margin: 'auto',
      background:
        'radial-gradient(circle at top right, #ffffff, transparent), radial-gradient(circle at bottom right, #c0c0c0, #efefef)',
    },
    exploreMedium: {
      position: 'absolute',
      top: 100,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      margin: 'auto',
      background:
        'radial-gradient(circle at top right, #ffffff, transparent), radial-gradient(circle at bottom right, #c0c0c0, #efefef)',
    },
    exploreSmall: {
      position: 'absolute',
      top: 100,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      margin: 'auto',
      background:
        'radial-gradient(circle at top right, #ffffff, transparent), radial-gradient(circle at bottom right, #c0c0c0, #efefef)',
    },
    projectExtraHuge: projectHuge,
    projectHuge: projectHuge,
    projectLarge: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      maxWidth: 1024,
      margin: 'auto',
      backgroundColor: '#dedede',
    },
    projectMedium: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      maxWidth: 1024,
      margin: 'auto',
    },
    projectSmall: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 202,
      maxWidth: 1024,
      margin: 'auto',
    },
    // proposal layout not actually implemented in React...
    // proposal: {
    //   position: 'absolute',
    //   top: 43,
    //   left: 0,
    //   right: 0,
    //   height: 200,
    //   zIndex: 100,
    // },
  }
}

const parentStyle = (studioStyle, showCustomerView, isFeaturedFiguresShown) => {
  switch (studioStyle) {
    case 'background':
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        WebkitUserSelect: 'none',

        // This ensures the element sits behind all other forms etc otherwise it could block
        // interactivity with page elements
        zIndex: -1,
      }
    case 'studioExtraHuge':
    case 'studioHuge':
    case 'studioLarge':
    case 'studioMedium':
    case 'studioSmall':
    case 'studioLiteExtraHuge':
    case 'studioLiteHuge':
    case 'studioLiteLarge':
    case 'studioLiteMedium':
    case 'studioLiteSmall':
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background:
          'radial-gradient(circle at top right, #ffffff, transparent), radial-gradient(circle at bottom right, #c0c0c0, #efefef)',
        WebkitUserSelect: 'none',
      }
    case 'myenergyExtraHuge':
    case 'myenergyHuge':
    case 'myenergyLarge':
    case 'myenergyMedium':
      return {
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 0,
        // top: !showCustomerView ? 330 : 230,
        // top: isFeaturedFiguresShown? 305 : 241,
        top: !showCustomerView ? (isFeaturedFiguresShown ? 313 : 241) : isFeaturedFiguresShown ? 230 : 141,

        bottom: 0,
        left: 0,
        right: 0,
        maxWidth: '1024px',
        width: '100%',
        margin: 'auto',
        backgroundColor: 'transparent',
        WebkitUserSelect: 'none',
      }
    case 'myenergySmall':
      return {
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 0,
        top: !showCustomerView ? 460 : 360,
        bottom: 0,
        left: 0,
        right: 0,
        height: '200px',
        width: '100%',
        backgroundColor: 'transparent',
        WebkitUserSelect: 'none',
      }
    case 'exploreExtraHuge':
    case 'exploreHuge':
    case 'exploreLarge':
    case 'exploreMedium':
    case 'exploreSmall':
      return {
        pointerEvents: 'none',
        position: 'absolute',
        bottom: 0,
        left: 0,
        top: 0,
        width: '100%',
        height: '100vh',
        WebkitUserSelect: 'none',
      }
    case 'projectExtraHuge':
    case 'projectHuge':
      return {
        pointerEvents: 'none',
        position: 'absolute',
        top: 118 + 44,
        left: 340 /*24+300+16*/,
        width: 'calc(100% - 48px - 16px - 16px - 300px - 300px)',
        height: 220,
        backgroundColor: 'transparent',
        WebkitUserSelect: 'none',
      }
    case 'projectLarge':
      return {
        pointerEvents: 'none',
        position: 'absolute',
        top: 118 + 44,
        left: 330 /*24+280+16*/,
        width: 'calc(100% - 48px - 24px - 20px - 280px - 280px)',
        height: 220,
        backgroundColor: 'transparent',
        WebkitUserSelect: 'none',
      }
    case 'projectMedium':
      return {
        pointerEvents: 'none',
        position: 'absolute',
        top: 112 + 44,
        left: 24,
        right: 24,
        height: 140,
        backgroundColor: 'transparent',
        WebkitUserSelect: 'none',
      }
    case 'projectSmall':
      return {
        pointerEvents: 'none',
        position: 'absolute',
        top: 92 + 44,
        left: 24,
        right: 24,
        height: 140,
        backgroundColor: 'transparent',
        WebkitUserSelect: 'none',
      }
    case 'hidden':
      return {
        display: 'none',
        WebkitUserSelect: 'none',
      }

    default:
      console.log('Warning DesignerComponent.parentStyle() did not find studioStyle:' + studioStyle)
      return {
        display: 'none',
        WebkitUserSelect: 'none',
      }
  }
}

class DesignerComponent extends Component {
  constructor(props) {
    super(props)
    //
    // this.start = this.start.bind(this)
    // this.stop = this.stop.bind(this)
    // this.animate = this.animate.bind(this)
    window.lastStudioStyle = getStudioStyle('hidden', props.layout)
  }

  componentDidMount() {
    // Throws illegal invocation on Chrome for some reason...?
    // window.Designer.init.call(this, this.mount, window.API_BASE_URL, null)

    window.Designer.init(this.mount, window.API_BASE_URL, null)
  }

  componentWillUpdate(nextProps, nextState) {
    // Super nasty hack to communicate with redux from outside
    window.Designer.showNotification = function (text, _type, options) {
      if (!text) {
        console.log('showNotification called with no message, ignoring')
        return
      }

      // convert error object to string
      if (text.message) {
        text = text.message
      }

      var type

      switch (_type) {
        case 'danger':
        case 'warning':
          type = 'warning'
          break
        case 'error':
          type = 'error'
          break
        case 'ok':
        case 'success':
        default:
          type = 'info'
      }

      // nextProps.showNotification(this.context.translate(text), type)
      nextProps.showNotification(text, type, options)
    }

    // Used to remove the views and restart when DSM fails to load
    window.Designer.restartDesignMode = restartDesignMode

    // Hacky way of sharing zone with non-react code
    window.Designer.getZone = function () {
      return window.lastZone
    }

    //@TODO: Find a better place to store lastZone and lastStudioStyle instead of
    // window.lastZone and window.lastStudioStyle but beware
    //using this.lastZone/this.lastStudioStyle is dangerous because references can be lost
    if (window.lastZone !== nextProps.zone) {
      // Only trigger change-of-zone behaviors if a lastZone was recorded
      if (window.lastZone) {
        if (nextProps.zone === 'crm') {
          // skip set new AjaxSession for proux2
          // To do: refactor the logic for AjaxSession
          // only update new AjaxSession when project sections mounting and unMounting
        } else {
          window.Designer.AjaxSession.new(
            'DesignerComponent detected zone change from ' + window.lastZone + ' to ' + nextProps.zone
          )
        }

        console.log('check WorkspaceHelper.cancelLoading', nextProps.zone)
        if (nextProps.zone !== 'studio' && window.WorkspaceHelper) {
          window.WorkspaceHelper.cancelLoading()
        }
      }

      window.lastZone = nextProps.zone
    }

    //Should probably move to App because it governs how the whole app behaves

    var newStudioStyle = getStudioStyle(nextProps.studioMode, nextProps.layout)

    if (newStudioStyle !== window.lastStudioStyle) {
      //style changed...handle necessary updates?

      if (
        newStudioStyle === 'studioExtraHuge' ||
        newStudioStyle === 'studioHuge' ||
        newStudioStyle === 'studioLarge' ||
        newStudioStyle === 'studioMedium' ||
        newStudioStyle === 'studioSmall'
      ) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }

      window.lastStudioStyle = newStudioStyle
    }
  }

  render() {
    var showCustomerView = !this.props.isPro || this.props.viewAsCustomer
    var studioStyle = getStudioStyle(this.props.studioMode, this.props.layout)
    var mapContainerStyles = buildMapContainerStyles()
    var isFeaturedFiguresShown = this.props.hasHighlightsRow

    // Studio Portal logic
    var portalId = this.props.studioPortalId
    var container = portalId ? document.getElementById(portalId) : undefined
    var parentStyleOverride = null
    var mapsStyleOverride = null
    if (this.props.proposalSize.valid) {
      // Switch to portal mode
      parentStyleOverride = {
        position: 'absolute',
        top: this.props.proposalSize.documentBox.y,
        left: this.props.proposalSize.documentBox.x,
        width: this.props.proposalSize.documentBox.width,
        height: this.props.proposalSize.documentBox.height,
        zIndex: this.props.studioZIndex,
      }
      mapsStyleOverride = {
        position: 'absolute',
        inset: 0,
      }
    }

    return (
      <div
        id="designer-component"
        style={parentStyleOverride || parentStyle(studioStyle, showCustomerView, isFeaturedFiguresShown)}
        rel={studioStyle}
      >
        {container && (
          <Portal container={() => container}>
            {/* This div gets 'portalled' into some other spot in the hierarchy, when it can report it's size/pos back */}
            <div
              id="studio-size-detection"
              style={{ position: 'absolute', pointerEvents: 'none', inset: 0 }}
              ref={this.props.proposalSize.setTarget}
            />
          </Portal>
        )}
        <div
          style={mapsStyleOverride || mapContainerStyles[studioStyle]}
          rel={studioStyle}
          id={`${this.props.studioMode}-map-container`}
          class="map-blocker"
        >
          <div
            id="DesignerContainer"
            className="map-blocker"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflow: 'hidden',
            }}
          >
            <div
              id="DesignerRootDiv"
              ref={(mount) => {
                this.mount = mount
              }}
              className="map-blocker"
            />

            {/*<div id="design-address"></div>*/}
            <div
              id="insetBorderForMaps"
              style={{
                display: 'none',
                position: 'absolute',
                pointerEvents: 'none',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: '10px solid rgba(0, 0, 0, 0.5)',
              }}
            />
            <div
              className="DesignerContainerAttribution"
              style={{
                //start hidden and only reveal when populated with content
                display: 'none',
              }}
            />
            <div id="mapNone" />
            <div id="mapGoogle" />
            <div id="mapGoogleTop" />
            <div id="mapGoogleRoadMap" />
            <div id="mapStreetView" />
            <div id="mapOpenStreetMap" className="mapOpenStreetMap" />
            <div id="mapBing" style={{ width: '100vw', height: '100vh' }} />
            <div id="mapSixMaps" />
            <div id="mapLocationSA" />
            <div id="mapNearmap" />
            <div id="mapGetMapping"></div>
            <div id="mapNearmapOblique" />
            <div id="mapNearmapSource" />
            <div id="mapCyclomedia" className="mapOpenStreetMap" />
            <div id="mapCyclomediaOblique" />
            <div id="mapImage" />
            <div id="mapQldGlobe" className="mapOpenStreetMap" />
            <div id="mapMetroMap" />
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  isPro: Boolean(getRoleFromState(state)),
  viewAsCustomer: state.viewAsCustomer,
  zone: state.zone,
  theme: state.theme,
  locale: state.locale,
  studioMode: state.studioMode,
  loading: state.myEnergy.loading,
  hasHighlightsRow: state.studio.hasHighlightsRow,
  studioPortalId: designViewSelectors.studioPortalId(state),
  studioZIndex: designViewSelectors.studioZIndex(state),
})

const enhance = compose(
  withMediaQuery,
  withElementSize('proposalSize', { watchLevelsUp: 5 }), //TODO: `watchLevelsUp` should be determined by StudioContainer
  connect(mapStateToProps, { showNotification: showNotificationAction })
)

export default enhance(DesignerComponent)
