import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { permissionsSelectors } from 'ducks/permissions'
import { ComponentVersionsInherit, HourGlassOutlineIcon, styled } from 'opensolar-ui'
import Notice from 'projectSections/sections/manage3/common/Notice'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React, { useCallback, useContext, useState } from 'react'
import { Datagrid, FunctionField, List } from 'react-admin'
import { useSelector } from 'react-redux'
import { PrivateFileType } from 'types/privateFile'
import { FilesAndTransContext, FilesAndTransContextProps } from '../../FilesAndTransactionsSection'
import { CRUDFilesDialogs } from '../common/dialogs/CRUDFilesDialogs'
import { useProjectFiles } from '../common/hooks/useProjectFiles'
import { PlaceholderContainer } from '../common/styles'
import { FileRow } from './FileRow'

const StyledList = styled(List)(({ theme }) => ({
  minHeight: 195,
  borderWidth: '0px !important',
  padding: '0px !important',
  '& .Ra-List-Toolbar-wrapper, & thead, & .MuiTablePagination-root': {
    display: 'none !important',
  },
  '& .Ra-List-Main': {
    paddingTop: 0,
  },
  '& .td, & .MuiTableCell-sizeSmall:last-child': {
    padding: 0,
    paddingRight: 0,
    borderBottom: 'none',
  },
  '& .MuiPaper-elevation1': {
    borderColor: 'transparent !important',
  },
  '& .OSUI-Button-containedSizeMedium': {
    display: 'none !important',
  },
  '& tr:hover, & td:hover': {
    backgroundColor: 'white !important',
  },
}))

export const FilesSection: React.FunctionComponent = () => {
  const translate = useTranslate()
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('info_documents'))
  const { ctaSelected, selectCta, searchTerm } = useContext(FilesAndTransContext) as FilesAndTransContextProps
  const [fileSelected, setFileSelected] = useState<PrivateFileType | null>(null)
  const { form, projectFormValues, deleteFileInForm, updateEventInForm } = useProjectFiles()

  const projectId = useSelector((state: any) => state.projectId) || 'new'

  const openDialog = useCallback((privateFileRecord: PrivateFileType, dialogToOpen: 'edit_file' | 'preview_file') => {
    setFileSelected(privateFileRecord)
    selectCta(dialogToOpen)
  }, [])

  const onCloseDialog = () => selectCta('')

  const Placeholder = () => {
    const subtitle = !allowView
      ? "You don't have permission to view files for this project"
      : 'No file matches found for your query'
    return (
      <PlaceholderContainer>
        <span>
          <Notice
            icon={<HourGlassOutlineIcon variant={3} size={26} color={COLOR_PALETTE.darkGrey} />}
            mainText={translate('No files found')}
            subText={translate(subtitle)}
          />
        </span>
      </PlaceholderContainer>
    )
  }

  return (
    <ComponentVersionsInherit versions={{ ...ComponentVersions_3_0, table: 1 }}>
      <section>
        <StyledList
          id={'private_files'}
          basePath={`/projects/${projectId}/private_files`}
          resource="private_files"
          hasSearch={true}
          hasCreate={true}
          filter={{ project: projectId }}
          hideBreadCrumbs={true}
          hasBulkActions={false}
          perPage={100}
          breadcrumbs={null}
          empty={<Placeholder />}
        >
          <Datagrid>
            <FunctionField
              source="id"
              style={{ zIndex: 1 }}
              render={(record) => {
                return [...record?.file_tags_data.map((t) => t.title), record?.title]
                  .join('|')
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ? (
                  <FileRow privateFileRecord={record} deleteFileInForm={deleteFileInForm} openDialog={openDialog} />
                ) : (
                  <div />
                )
              }}
            />
          </Datagrid>
        </StyledList>

        <CRUDFilesDialogs
          dialogSelected={ctaSelected}
          onCloseDialog={onCloseDialog}
          fileSelected={fileSelected}
          form={form}
          projectFormValues={projectFormValues}
          deleteFileInForm={deleteFileInForm}
          updateEventInForm={updateEventInForm}
        />
      </section>
    </ComponentVersionsInherit>
  )
}
