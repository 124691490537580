import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import { Button } from 'opensolar-ui'
import { InvoicePaymentDetails } from 'pages/cashFlow/invoices/types'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { markPaymentRequestPaidOffline } from 'pages/cashFlow/utils'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  paymentRequest: PaymentRequestType | InvoicePaymentDetails
  disableButtons: boolean
  systemUuid: string
  setDisableButtons: (val: boolean) => void
  setActionsAreShown: (val: boolean) => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyLight1,
  },
  content: {
    lineHeight: 1.5,
  },
  closeButton: {
    color: theme.greyMid1,
  },
  buttonsRow: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },
  progressSpinner: {
    margin: '4px 20px 0',
    color: theme.greyDark2,
  },
}))

const MarkPaidOfflineButton: React.FC<PropTypes> = (props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
  const [paymentMethod, setPaymentMethod] = useState<string>()
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()
  const refresh = useRefresh()

  const closeDialog = () => {
    setDialogIsOpen(false)
    props.setActionsAreShown(false)
  }

  const refreshMilestones = () => {
    dispatch(triggerMilestoneRefresh())
  }

  const markPaid = () => {
    if (paymentMethod) {
      if (props.paymentRequest) {
        props.setDisableButtons(true)
        markPaymentRequestPaidOffline(
          props.paymentRequest.org,
          props.paymentRequest.project,
          props.systemUuid,
          props.paymentRequest.payment_option,
          props.paymentRequest.id,
          paymentMethod,
          props.paymentRequest.payment_number
        )
          .then(() => {
            refreshMilestones()
            notify(translate(`${props.paymentRequest.invoice_number || 'Invoice'} marked as paid.`), 'success')
            props.setActionsAreShown(false)
            logAmplitudeEvent('cashflow_invoice_marked_paid_offline', {
              source: 'invoices table',
              org_id: props.paymentRequest?.org,
              project_id: props.paymentRequest?.project,
              payment_number: props.paymentRequest?.payment_number,
              was_already_sent: Boolean(props.paymentRequest?.id),
            })
          })
          .catch((err) => {
            notify(err, 'warning', { autoHideDuration: undefined })
          })
          .finally(() => {
            refresh()
            props.setDisableButtons(false)
          })
      }
    } else {
      notify('Please enter how payment was collected', 'warning')
    }
  }

  return (
    <>
      <Button disabled={props.disableButtons} size="small" onClick={() => setDialogIsOpen(true)}>
        {translate('Mark Paid Offline')}
      </Button>

      {dialogIsOpen && (
        <Dialog open={true}>
          <DialogTitle>
            <div className={classes.titleWrapper}>
              <div>{translate('Mark Invoice Paid Offline')}</div>
              <div>
                <IconButton onClick={closeDialog}>
                  <CloseOutlined className={classes.closeButton} />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <p className={classes.content}>
              {translate(
                'Please confirm you would like to mark %{invoice} on this project as paid offline. Note that marking an invoice as paid offline will move that invoice to a paid status and it will appear that way on all reporting.',
                { invoice: props.paymentRequest.invoice_number || translate('this unsent invoice') }
              )}
            </p>
            <div>
              <p>{translate('How was payment collected?')}</p>
              <TextField
                fullWidth
                label=""
                multiline
                variant="outlined"
                rows={4}
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                required
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className={classes.buttonsRow}>
              <div>
                <Button onClick={closeDialog} color="primary" variant="outlined" disabled={props.disableButtons}>
                  {translate('Cancel')}
                </Button>
              </div>
              <div>
                <Button color="primary" variant="contained" onClick={markPaid}>
                  {props.disableButtons ? (
                    <CircularProgress size={20} className={classes.progressSpinner} />
                  ) : (
                    <span> {translate('Confirm')} </span>
                  )}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default MarkPaidOfflineButton
