export const handleKeyDown = (event) => {
  if (event.key === 'Enter' || event.key === 'Escape') {
    event.preventDefault()
    event.stopPropagation()
    const form = event.target.form
    if (form) {
      const submitButton = form.querySelector('button[name="raSaveButton"]')
      if (submitButton && event.key === 'Enter') {
        submitButton.click()
      }
      const calcelButton = form.querySelector('button[id="calcel-inline-edit"]')
      if (calcelButton && event.key === 'Escape') {
        calcelButton.click()
      }
    }
  }
}
