import { authSelectors } from 'ducks/auth'
import { createContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { VtacBillingInfoType, VtacShippingInfoType } from 'services/vtac/type'
import useVtacService from 'services/vtac/useVtacService'
import VtacCheckoutPresenter from './VtacCheckoutPresenter'

export type VtacOrderModeType = 'order'

export type VtacOrderState = {
  submitting: boolean
  isReady?: boolean
  orderType?: VtacOrderModeType
  orderResult?: any
}

const defaultOrderState: VtacOrderState = {
  submitting: false,
  isReady: false,
  orderType: 'order',
}

const defaultShippingInfo = {
  firstname: '',
  lastname: '',
  city: '',
  state: '',
  street: '',
  postcode: '',
  country_id: '',
  contact_number: '',
}

const defaultBillingInfo = {
  firstname: '',
  lastname: '',
  city: '',
  state: '',
  street: '',
  postcode: '',
  country_id: '',
  contact_number: '',
}

const defaultDealer = ''

export const CheckoutPresenterContext = createContext<VtacCheckoutPresenter | undefined>(undefined)
export const ShippingInfoContext = createContext<VtacShippingInfoType>(defaultShippingInfo)
export const BillingInfoContext = createContext<VtacBillingInfoType>(defaultBillingInfo)
export const OrderStateContext = createContext<VtacOrderState>(defaultOrderState)
export const DealerContext = createContext<string>('')

const VtacCheckoutProvider = ({ children }) => {
  const [orderState, setOrderState] = useState<VtacOrderState>(defaultOrderState)
  const user = useSelector(authSelectors.getCurrentUser)
  const role = useSelector(authSelectors.getCurrentRole)

  const [shippingInfo, setShippingInfo] = useState<VtacShippingInfoType>({
    ...defaultShippingInfo,
    firstname: role?.first_name || 'Open',
    lastname: role?.family_name || 'Solar',
    contact_number: role?.phone || '',
  })
  const [billingInfo, setBillingInfo] = useState<VtacBillingInfoType>({
    ...defaultBillingInfo,
    firstname: role?.first_name || 'Open',
    lastname: role?.family_name || 'Solar',
    contact_number: role?.phone || '',
  })

  const [dealer, setDealer] = useState<string>(defaultDealer)

  const vtacService = useVtacService()

  const vtacCheckoutPresenter = useMemo(
    () => new VtacCheckoutPresenter(vtacService, setShippingInfo, setOrderState, setBillingInfo, setDealer),
    []
  )

  return (
    <OrderStateContext.Provider value={orderState}>
      <ShippingInfoContext.Provider value={shippingInfo}>
        <BillingInfoContext.Provider value={billingInfo}>
          <DealerContext.Provider value={dealer}>
            <CheckoutPresenterContext.Provider value={vtacCheckoutPresenter}>
              {children}
            </CheckoutPresenterContext.Provider>
          </DealerContext.Provider>
        </BillingInfoContext.Provider>
      </ShippingInfoContext.Provider>
    </OrderStateContext.Provider>
  )
}

export default VtacCheckoutProvider
