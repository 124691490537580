import { makeStyles } from '@material-ui/core'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { Theme } from 'types/themes'

export const getDialogStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiPaper-elevation1': {
      borderColor: 'transparent !important',
    },
  },
  fieldsStyling: {
    width: '100%',
    '& .MuiFormLabel-root': {
      width: '100%',
      fontSize: 14,
      color: COLOR_PALETTE.darkGrey,
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      backgroundColor: theme.white,
      '& .MuiSvgIcon-root': {
        fill: COLOR_PALETTE.iconColor,
      },
    },
    '& .MuiOutlinedInput-notchedOutline, & .MuiSelect-root': {
      borderRadius: 8,
      border: '1px solid ' + theme.greyMid2,
      boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);',
    },
    '& .MuiFormControl-fullWidth': {
      boxSizing: 'border-box',
      width: '99% !important',
    },
  },
  noMargin: {
    margin: '0px !important',
  },
  innerTitle: {
    textAlign: 'center',
    padding: '0px',
    margin: '0px',
    marginTop: 20,
    marginBottom: 20,
  },
  innerSection: {
    backgroundColor: theme.greyLight1,
    borderRadius: 8,
    margin: '0px 5px',
    padding: 10,
    paddingTop: 20,
    marginBottom: 10,
  },
  footerWrapper: {
    padding: '16px 20px !important',
    borderTop: '1px solid',
    borderColor: `${COLOR_PALETTE.neutralGrey500} !important`,
  },
  fileInput: {
    overflow: 'hidden',
    marginTop: -20,
    marginBottom: 15,
    '& .previews > div': {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignContent: 'baseline',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 5,
      marginTop: 12,
      paddingLeft: 12,
      border: '2px solid',
      borderColor: COLOR_PALETTE.midGrey,
    },
  },
  fileFieldContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    '& p': {
      fontWeight: 500,
    },
    '& span': {
      color: COLOR_PALETTE.iconColor,
      fontWeight: 400,
      fontSize: 12,
    },
  },
  dialogContent: {
    width: '100%',
    boxSizing: 'border-box',
  },
  extraPaddingTop: {
    paddingTop: 20,
    boxSizing: 'border-box',
  },
  extraPaddingY: {
    paddingTop: 20,
    paddingBottom: 20,
    boxSizing: 'border-box',
  },
  extraPadding: {
    padding: 10,
  },
  booleanFieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    marginTop: 5,
    marginLeft: 5,
    '& p': {
      marginTop: 7,
    },
  },
  dateInput: {
    marginTop: '-15px',
    '& .MuiInputBase-root': {
      boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);',
      border: '1px solid ' + theme.greyMid2,
      borderRadius: 8,
    },
    '& .MuiInputBase-inputMarginDense': {
      height: 26,
      paddingLeft: 10,
      borderRadius: 8,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.greyLight1,
    },
  },
  booleanFieldWrapperBottom: {
    marginTop: -5,
    marginLeft: 0,
  },
  fileFieldIcon: {
    backgroundColor: COLOR_PALETTE.lightGrey5,
    padding: 4,
    borderRadius: 5,
    color: COLOR_PALETTE.iconColor,
  },
  spanText: {
    color: COLOR_PALETTE.blue3,
  },
  fileInputPlaceholderContainer: {
    border: '3px dashed',
    borderColor: theme.palette.grey[300],
    backgroundColor: 'white',
    margin: -7,
    borderRadius: 5,
    padding: 24,
  },
  fileInputPlaceholderIcon: {
    '& svg': {
      width: 50,
      height: 50,
      marginTop: 10,
      fill: COLOR_PALETTE.iconColor,
      backgroundColor: COLOR_PALETTE.midGrey,
      borderRadius: 8,
      padding: 8,
    },
  },
  displayNone: {
    display: 'none',
  },
  chipsInput: {
    marginTop: 10,
    display: 'flex',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      backgroundColor: theme.white,
      height: 'auto !important',
      '& .MuiSvgIcon-root': {
        fill: COLOR_PALETTE.iconColor,
      },
      '&>div': {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        flexWrap: 'wrap',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 8,
      border: '1px solid ' + theme.greyMid2,
      boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07);',
    },
  },
  uploadProgress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))
