import { LayoutAdjuster, PanelBlock, PanelBlockWithUnpositionedPanels } from '../types'

export abstract class BasicSpacingLayoutAdjusterAbstract implements LayoutAdjuster {
  abstract spacingRuleSet: SpacingRuleSet

  adjustBlock(block: PanelBlockWithUnpositionedPanels): PanelBlock[] {
    if (!block.rows.length || !block.rows[0].length) return []

    // We assume all panels are the same dimensions and orientation
    const panel = block.panel
    const isPortrait = block.orientation === 'portrait'
    const effectiveWidth = isPortrait ? panel.width : panel.height
    const effectiveHeight = isPortrait ? panel.height : panel.width

    const positionedBlock = adjustBlockPosition(block)
    const spacedBlock = this.initialisePositionsWithInterpanelSpacing(positionedBlock, effectiveWidth, effectiveHeight)
    const thermalAdjustedBlocks = this.adjustBlockForThermalSpacing(spacedBlock)

    // TODO: Throw out panels which don't fit in the usable roof area

    return thermalAdjustedBlocks
  }

  initialisePositionsWithInterpanelSpacing(
    block: PanelBlockWithUnpositionedPanels,
    effectiveWidth: number,
    effectiveHeight: number
  ): PanelBlock {
    const { horizontalSpacing, verticalSpacing } = this.spacingRuleSet.interPanel
    return {
      ...block,
      rows: block.rows.map((row, y) =>
        row.map((panel, x) => {
          return {
            ...panel,
            orientation: block.orientation,
            width: effectiveWidth,
            height: effectiveHeight,
            left: block.left + x * (effectiveWidth + horizontalSpacing),
            top: block.top + y * (effectiveHeight + verticalSpacing),
          }
        })
      ),
      panelSpacing: [horizontalSpacing / 1000, verticalSpacing / 1000],
    }
  }

  adjustBlockForThermalSpacing(block: PanelBlock): PanelBlock[] {
    const thermalRuleSet = this.spacingRuleSet.thermal
    if (!thermalRuleSet) return [block]

    // TODO - NOT YET IMPLEMENTED

    // We have to make this work with weird layouts
    // A single horizontal run of panels that goes past the limit incurs a split
    // But only the panels reachable from that panel need to be moved

    // I suspect this is hard
    // Might be much easier for a human to do
    // Or perhaps a middle path, where we auto-solve it in simple cases

    // An example of why this is hard:
    // Consider this block, where the max row-length is 5:
    //
    //    ====
    // =====
    // =======
    //   =====
    //
    // The desired split is:
    //
    //    ====              == ==
    // =====       not    ====
    // ===== ==           ==== ==
    //   === ==             == ==
    throw Error('adjustBlockForThermalSpacing not implemented')
  }
}

// Move block to avoid exclusion zones around roof border
function adjustBlockPosition(block: PanelBlockWithUnpositionedPanels): PanelBlockWithUnpositionedPanels {
  // TODO: Implement
  return block
}

export type SpacingRuleSet = {
  interPanel: InterPanelRule
  thermal?: ThermalRuleSet
  roofEdge?: RoofEdgeRule
}

type InterPanelRule = {
  horizontalSpacing: number // mm
  verticalSpacing: number // mm
}

type ThermalRuleSet = {
  horizontalRule?: ThermalRule
  verticalRule?: ThermalRule
}

type ThermalRule = {
  maxDistance: number // mm, panel edge to panel edge
  gapSize: number // mm, between blocks, panel edge to panel edge
}

type RoofEdgeRule = {
  distance: number // mm // This might be naive. Maybe there's vertical/horizontal edge rules, etc
}
