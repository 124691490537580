import lodash from 'lodash'
import { PROJECT_ROLES } from 'projectSections/sections/manage3/details/elements/teams/TeamMemberInput'
import { FC, useEffect, useMemo } from 'react'
import { AutocompleteInput, ReferenceInput, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

interface PropTypes {
  source: string
}

const AssignedTeamMember: FC<PropTypes> = ({ source }) => {
  const form = useForm()
  const formValues = useFormState().values
  const assigned = useMemo(() => lodash.get(formValues, `${source}.fields`), [formValues, source])

  useEffect(() => {
    let newValue = {}
    if (assigned?.roleType && assigned?.roleId) {
      newValue[`${assigned.roleType}_id`] = assigned.roleId
    }
    form.change(`${source}.value`, newValue)
  }, [assigned])
  return (
    <>
      <SelectInput
        choices={PROJECT_ROLES.map((x) => ({ ...x, id: x.id + '_id' }))}
        optionText={'title'}
        label={false}
        source={`${source}.fields.roleType`}
        variant="outlined"
        margin={'none'}
      />

      <ReferenceInput source={`${source}.fields.roleId`} reference="roles" label={''}>
        <AutocompleteInput
          source="email"
          optionText="display"
          optionValue="id"
          options={{ variant: 'outlined', style: { margin: 0 } }}
          label={false}
        />
      </ReferenceInput>
    </>
  )
}

export default AssignedTeamMember
