import { InputAdornment, TextField } from '@material-ui/core'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { ToolsInverters } from 'Designer/Toolbars'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import Alert from 'elements/Alert'
import AssociatedComponentsAlert from 'elements/associatedComponents/AssociatedComponentsAlert'
import InverterDesignAssistantDialog from 'elements/hardwareSelectorV2/inverterWizard/InverterDesignAssistantDialog'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { MagicWandIcon } from 'icons/MagicWandIcon'
import { Button, ComponentVersionsInherit } from 'opensolar-ui'
import { addDefaultComponentOrChooseFromDialog } from 'projectSections/sections/design/util/addDefaultComponentOrChooseFromDialog'
import { handleAddComponent } from 'projectSections/sections/design/util/handleAddComponent'
import { useTranslate } from 'ra-core'
import { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestAutoStringing } from 'reducer/designer/autoString'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { markFieldActive, markFieldInactive } from 'Studio/Utils'
import { StudioSystemType } from 'types/global'
import { useFeatureFlag } from 'util/split'
import { handleObjectChangedAsCommand, inputValueToFloat, styles, valueOrEmptyStringIfZero } from '../../PanelSystem'
import PanelGenericWarningBox from '../../warning/PanelGenericWarningBox'
import ComponentCollection from '../ComponentCollection'
import InverterList from './DraggableInverterBlock'
import QuickStringing from './QuickStringing'
import UnstrungPanelWarning from './UnstrungPanelWarning'

interface Props extends WithHardwareSelectorV2Props {
  system: StudioSystemType
  allowEdit: boolean

  //TODO: Should remove the need for this
  panelSystem: any
}

const InverterPanelComponent: FC<Props> = ({
  allowEdit,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
  panelSystem,
  system,
}) => {
  const [unstrungModulesInverterEfficiency, setUnstrungModulesInverterEfficiency] = useState(
    system && system.unstrungModulesInverterEfficiency ? system.unstrungModulesInverterEfficiency : ''
  )
  const [isInverterDesignAssistantOpen, setIsInverterDesignAssistantOpen] = useState(false)
  const enableInverterWizard = useFeatureFlag('enable_inverter_wizard', 'on')
  const translate = useTranslate()
  const currencySymbol = useContext(CurrencySymbolContext)
  const dispatch = useDispatch()

  const [hasInverterConflict, setHasInverterConflict] = useState(false)

  useStudioSignalsLazy(
    (item) => {
      if (item.type !== 'OsInverter') return
      setHasInverterConflict(
        system.inverters().length > 1 &&
          system.inverters().some((inverter) => inverter.code !== system.inverters()[0].code)
      )
    },
    ['objectAdded', 'objectRemoved', 'objectChanged']
  )

  const onOpenManualSelector = () => {
    // I don't see why BatterySpecsAvailable need to be passed as default filtersToOverride
    // so I'm not passing it in new selector
    if (enableHardwareDialogV2)
      handleOpenHardwareSelector({
        componentTypes: ['inverter'],
        title: 'Select Inverters',
      })
    else
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          null,
          'inverter',
          window.AccountHelper.getComponentInverterSpecsAvailable(),
          null
        )
      )
  }

  const AutoStringButton = () => {
    return (
      <ComponentVersionsInherit versions={ComponentVersions_2_1}>
        <Button
          style={{
            margin: '10px 0px',
          }}
          variant="contained"
          fullWidth={true}
          onClick={() => {
            dispatch(requestAutoStringing(system.uuid, 'best', undefined, system.inverters()[0].inverter_id, true))
          }}
          disabled={!allowEdit || system.inverters().length === 0 || hasInverterConflict}
        >
          <MagicWandIcon /> {translate('Auto String')}
        </Button>
      </ComponentVersionsInherit>
    )
  }

  return (
    //@ts-ignore
    <div style={styles.expansionPanelDetailsContainer}>
      <UnstrungPanelWarning system={system} />
      <PanelGenericWarningBox category="inverter" />
      <AssociatedComponentsAlert associationType="require" filter={{ componentTypes: ['inverter'] }} />
      <AssociatedComponentsAlert associationType="recommend" filter={{ componentTypes: ['inverter'] }} />

      <ToolsInverters
        disabled={!allowEdit}
        plusOnClick={() => {
          if (enableInverterWizard) {
            setIsInverterDesignAssistantOpen(true)
          } else {
            addDefaultComponentOrChooseFromDialog('inverter', system.uuid, onOpenManualSelector)
          }
        }}
      />

      {!enableInverterWizard && <AutoStringButton />}

      {hasInverterConflict && (
        <Alert severity="warning">
          {translate('Auto String is only available when a single inverter type is used.')}
        </Alert>
      )}
      {/*@ts-ignore*/}
      <div style={styles.expansionPanelDetailsContainer}>
        {system.inverters().length === 0 && (
          <TextField
            id="InverterEfficiency"
            type="number"
            disabled={!allowEdit}
            label={translate('Inverter Efficiency')}
            fullWidth={true}
            name="Inverter Efficiency"
            value={valueOrEmptyStringIfZero(unstrungModulesInverterEfficiency)}
            onFocus={() => {
              markFieldActive.call(panelSystem, 'unstrungModulesInverterEfficiencyRaw', system)
            }}
            onBlur={() => {
              markFieldInactive.call(panelSystem)
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={(event) => {
              const value = event.target.value
              setUnstrungModulesInverterEfficiency(value)

              var valueAsFloat = inputValueToFloat(value)
              if (!isNaN(valueAsFloat)) {
                handleObjectChangedAsCommand(system.uuid, 'unstrungModulesInverterEfficiency', valueAsFloat)
              }
            }}
          />
        )}
        <InverterList system={system} allowEdit={allowEdit} handleAddComponent={handleAddComponent} />
        {system.inverters().length > 0 && (
          <ComponentCollection
            style={{ marginTop: 20 }}
            title="Inverter Accessories"
            dialogSelectorTitle={translate('Select Inverter Accessories')}
            addButtonLabel="+"
            systemUuid={system.uuid}
            others={system.others()}
            filterParams={{ other_component_type: 'inverter_accessory' }}
            disabled={!allowEdit}
            hideDivider={true}
          />
        )}

        {!enableInverterWizard && (
          <QuickStringing
            systemUuid={system.uuid}
            inverterRange={system.inverterRange}
            currencySymbol={currencySymbol}
            disabled={!allowEdit}
          />
        )}
      </div>
      <InverterDesignAssistantDialog
        isOpen={isInverterDesignAssistantOpen}
        onClose={() => setIsInverterDesignAssistantOpen(false)}
      />
    </div>
  )
}

export const InverterPanel = withHardwareSelectorV2(InverterPanelComponent)
