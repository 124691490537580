import { BottomNavigation, BottomNavigationAction, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ShoppingCart, ShoppingCartOutlined } from '@material-ui/icons'
import ControlSelectedIcon from '@material-ui/icons/BusinessCenter'
import ControlIcon from '@material-ui/icons/BusinessCenterOutlined'
import ProjectsIcon from '@material-ui/icons/FolderOutlined'
import ProjectsSelectedIcon from '@material-ui/icons/FolderRounded'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import HomeSelectedIcon from '@material-ui/icons/HomeRounded'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { useViewShow } from 'ducks/viewMode'
import { UiSwitch } from 'elements/UiSwitch'
import withMediaQuery from 'layout/withMediaQuery'
import { useGetCashFlowNavVisible } from 'pages/cashFlow/utils'
import { FC, useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import compose from 'recompose/compose'
import { getRoleFromState } from 'util/misc'
import { getOrgFromState } from 'util/org'
import { useFeatureFlag } from 'util/split'
import LogoOrTitle from '../myenergy/mainContent/components/LogoOrTitle'

type bottomNavigationMobileType = {
  org?: any
}
const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: 50,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
  },
  action: {
    marginTop: 2,
    '&$selected': {
      marginTop: 0,
      color: 'rgb(0,0,0)',
      borderTop: '2px solid rgba(0,0,0)',
    },
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: '0.75rem',
    },
  },

  selected: {},
  iconheight: { lineHeight: 'none' },
}))

const ZONE_PATH_MAP: { [key: string]: string } = {
  crm: '/projects',
  home: '/home',
  control: '/control',
  cashflow: '/cashflow',
  partnerzone: '/zone',
  shop: '/shop',
}

const BottomNavigationMobile: FC<bottomNavigationMobileType> = ({ org }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const zone = useSelector((state: any) => {
    return state.zone
  })
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const cashFlowEnabled = useGetCashFlowNavVisible()
  const isCustomer = useSelector((state) => !Boolean(getRoleFromState(state)))
  const viewAsCustomer = useSelector((state: any) => state.viewAsCustomer)
  const dispatch = useDispatch()
  const history = useHistory()
  const handleClick = useCallback((event, newValue: string) => {
    event.preventDefault()
    const newPathName = ZONE_PATH_MAP[newValue]
    if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: newPathName,
          prompt_heading: `Leave Studio?`,
        })
      )
    } else {
      history.push(newPathName)
    }
  }, [])

  if (!isMobile || isCustomer || viewAsCustomer) {
    return null
  }
  const uiKey = 'header.nav.'
  const zoneOrg = org?.zone_org
  return (
    <UiSwitch uiKey={uiKey}>
      <BottomNavigation value={zone} onChange={handleClick} showLabels className={classes.root}>
        <BottomNavItem
          uiKey={uiKey + 'home'}
          value="home"
          classes={{ root: classes.action, selected: classes.selected }}
          label={translate('Home [App Navigation]')}
          icon={zone === 'home' ? <HomeSelectedIcon /> : <HomeIcon />}
        />
        <BottomNavItem
          uiKey={uiKey + 'projects'}
          classes={{ root: classes.action, selected: classes.selected }}
          value="crm"
          label={translate('Projects')}
          icon={zone === 'crm' ? <ProjectsSelectedIcon /> : <ProjectsIcon />}
        />
        <BottomNavItem
          uiKey={uiKey + 'control'}
          classes={{ root: classes.action, selected: classes.selected }}
          value="control"
          label={translate('Control')}
          icon={zone === 'control' ? <ControlSelectedIcon /> : <ControlIcon />}
        />
        {cashFlowEnabled && (
          <BottomNavItem
            uiKey={uiKey + 'cashflow'}
            classes={{ root: classes.action, selected: classes.selected }}
            value="cashflow"
            label={translate('CashFlow')}
            icon={<img src={`${window.PUBLIC_URL}/images/cashflow/cash-icon.svg`} />}
          />
        )}
        {enableHardwareOrdering && (
          <BottomNavItem
            uiKey={uiKey + '.hardware'}
            classes={{ root: classes.action, selected: classes.selected }}
            value="shop"
            label={translate('Hardware')}
            icon={zone === 'shop' ? <ShoppingCart /> : <ShoppingCartOutlined />}
          />
        )}
        {zoneOrg && (
          <BottomNavItem
            uiKey={uiKey + '.zone'}
            classes={{
              root: classes.action,
              selected: classes.selected,
              iconOnly: classes.iconheight,
            }}
            value="partnerzone"
            label={zoneOrg.zone_title || 'Zone'}
            icon={
              <LogoOrTitle
                style={{ maxWidth: 50, height: 24 }}
                org={zoneOrg}
                allowUploadButton={false}
                type="mobilecobranding"
                skipTitle={undefined}
                skipTitleStyle={undefined}
              />
            }
          />
        )}
      </BottomNavigation>
    </UiSwitch>
  )
}

const BottomNavItem = ({ uiKey, ...rest }) => {
  const show = useViewShow(uiKey)

  return !show ? <></> : <BottomNavigationAction {...rest} />
}

const mapStateToProps = (state: any) => {
  return {
    org: getOrgFromState(state),
  }
}

export default compose<bottomNavigationMobileType, {}>(
  withMediaQuery,
  connect(mapStateToProps, {})
)(BottomNavigationMobile)
