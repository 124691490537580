import { Backdrop, Drawer, makeStyles } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { EventCategoryEnum } from 'types/events'
import { Theme } from 'types/themes'
import EmptyEventsContent from './EmptyEventsContent'
import EventsDisplay from './EventsDisplay'
import HistoryToolbar from './HistoryToolbar'
import ProjectEventWrapper from './ProjectEventWrapper'
import { getEventsData } from './util'

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    '& .MuiPaper-root': {
      width: 650,
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
  },
  wrapper: {
    padding: '40px 24px',
  },
}))

interface PropTypes {
  open: boolean
  onClose: () => void
}

const ProjectHistoryDrawer: React.FC<PropTypes> = ({ open, onClose }) => {
  const classes = useStyles()
  const project = useFormState().values
  const [eventFilter, setEventFilter] = useState<string | EventCategoryEnum>('all')
  const allEvents = useMemo(() => getEventsData(project.events_data), [project.events_data])
  const filteredEvents = useMemo(() => {
    if (eventFilter === 'all') return allEvents
    let filteredEvents = { ...allEvents }
    Object.keys(filteredEvents).forEach((key) => {
      const unfilteredVals = [...filteredEvents[key]]
      filteredEvents[key] = unfilteredVals.filter((x) => x.categories?.includes(eventFilter))
    })
    return filteredEvents
  }, [eventFilter, allEvents])
  const emptyEvents = useMemo(() => {
    let isEmpty = true
    Object.keys(filteredEvents).forEach((listKey) => {
      if (!!filteredEvents[listKey].length) isEmpty = false
    })
    return isEmpty
  }, [filteredEvents])

  return (
    <>
      <Backdrop open={open} style={{ zIndex: 1100 }} onClick={onClose}></Backdrop>
      <Drawer variant="persistent" anchor="right" open={open} className={classes.drawer}>
        <ProjectEventWrapper>
          {({ toggleModal, toggleDelete }) => {
            return (
              <div className={classes.wrapper}>
                <HistoryToolbar
                  filterApplied={eventFilter}
                  filterEvents={(filter) => {
                    setEventFilter(filter)
                  }}
                  toggleModal={toggleModal}
                />
                {emptyEvents ? (
                  <EmptyEventsContent eventFilter={eventFilter} />
                ) : (
                  <>
                    {Object.keys(filteredEvents).map((x, i) => (
                      <>
                        {!!filteredEvents[x].length && (
                          <EventsDisplay
                            key={'event-' + i}
                            toggleModal={toggleModal}
                            toggleDelete={toggleDelete}
                            title={x.toUpperCase()}
                            events={filteredEvents[x]}
                          />
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            )
          }}
        </ProjectEventWrapper>
      </Drawer>
    </>
  )
}

export default ProjectHistoryDrawer
