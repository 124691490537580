import { FormControl, FormControlLabel, RadioGroup, makeStyles } from '@material-ui/core'
import useOptimisticValue from 'elements/input/useOptimisticValue'
import { Radio } from 'opensolar-ui'
import { ChangeEvent } from 'react'
import { Theme } from 'types/themes'
import type { BasicRendererComponentPropsType, OptionGenericType } from '../type'
import useLimitedOptionsController from './useLimitedOptionsController'
import { DISTRIBUTOR_FILTER_KEY } from '../distributor/DistributorFilterNodeFactory'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  filterOptions: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
    margin: '0',
    padding: '0px',
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
      lineHeight: '20px',
    },
    '& .MuiIconButton-root': {
      padding: '3px 12px',
    },
  },
  showMore: {
    color: '#0094FF',
    margin: '0 10px',
    fontWeight: 400,
    cursor: 'pointer',
  },
}))

/*
  A radio renderer for hardware filters
*/
const BasicRadioRenderer = <T extends OptionGenericType<string | number>[]>({
  id,
  label,
  options,
  disabled,
  onSelect,
  defaultValue,
  value,
}: BasicRendererComponentPropsType<T>) => {
  const classes = useStyles({ disabled: !!disabled })
  const { optimisticValue, updateOptimisticValue } = useOptimisticValue<T[number]['value']>({ defaultValue, value })
  const limit = id === DISTRIBUTOR_FILTER_KEY ? 5 : 4
  const { limitedOptions, LimitedOptionsController } = useLimitedOptionsController(options, limit)

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = event.target.value as T[number]['value']
    updateOptimisticValue(newValue)
    onSelect(newValue)
  }

  return (
    <FormControl id={id} disabled={disabled}>
      <RadioGroup value={optimisticValue} onChange={handleChange}>
        {limitedOptions.map((option, i) => {
          return (
            <FormControlLabel
              className={classes.filterOptions}
              value={option.value}
              key={option.id}
              control={<Radio checked={optimisticValue.toString() === option.value.toString()} />}
              label={option.title}
            />
          )
        })}
      </RadioGroup>
      {!disabled && LimitedOptionsController}
    </FormControl>
  )
}

export default BasicRadioRenderer
