import type { IPublicClientApplication } from '@azure/msal-browser'
import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orderSelectors } from 'ducks/orderComponents'
import type LineItemType from 'pages/ordering/OrderLineItem'
import type { LegacyDataProvider, Notify } from 'react-admin'
import appStorage from 'storage/appStorage'
import type { SegenConfigType } from './SegenAuthenticationService'
import SegenAuthenticationService from './SegenAuthenticationService'
import type { AccountDetailType, SegenCountryDetailType, SegenQuoteResult, ShippingDetailsType } from './type'

const getTomorrowYYYYMMDD = () => {
  return new Date(+new Date() + 86400000).toISOString().split('T')[0]
}

class SegenServiceV2 extends SegenAuthenticationService {
  static _instance: SegenServiceV2 | undefined
  private accountDetail: AccountDetailType | undefined
  private countryIds: SegenCountryDetailType[] | undefined
  private deliveryMethods: string[] | undefined

  private restClientInstance: LegacyDataProvider
  private notify: Notify

  constructor(
    segenConfig: SegenConfigType,
    restClientInstance: LegacyDataProvider,
    notify: Notify,
    msalInstance: IPublicClientApplication
  ) {
    super({ msalInstance, segenConfig })
    this.restClientInstance = restClientInstance
    this.notify = notify

    SegenServiceV2._instance = this
  }

  static resetInstanceOnSetOrgId = async (): Promise<void> => {
    if (SegenServiceV2._instance) {
      const isAuthenticated = await SegenServiceV2._instance.isAuthenticated()
      if (isAuthenticated) {
        await SegenServiceV2._instance.disconnect()
        logAmplitudeEvent('disconnect_segen_clicked', { source: 'reset_org' })
      }
    }
  }

  syncProducts = async (): Promise<void> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `ordering/segen/sync/`,
    })

    const segenAccessToken = await this.maybeGetSegenAccessTokenSilently()
    window.segenAccessToken = segenAccessToken
    return
  }

  syncPrices = async (): Promise<void> => {
    try {
      const response = await this.restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${appStorage.getOrgId()}/segen/sync_prices`,
      })
    } catch (error) {
      logAmplitudeEvent('hardware_segen_service_failed', {
        context: 'sync_pricing',
      })
      this.notify('Could not sync prices')
      console.warn('sync prices error, ', error)
    }
  }

  placeQuote = async (lineItems: LineItemType[]): Promise<SegenQuoteResult | undefined> => {
    const projectIds = orderSelectors.getProjectListByLineItems(lineItems)
    const items = lineItems.map((lineItem: LineItemType) => ({
      code: lineItem.code,
      productCode: lineItem.variantId,
      componentType: lineItem.componentType,
      quantity: lineItem.quantity,
      projectOrder: lineItem.projectOrder,
    }))
    try {
      const quoteDetail = await this.restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${appStorage.getOrgId()}/segen_api/place_quote/`,
        data: { items: items, project_ids: projectIds },
      })
      return quoteDetail?.data.result
    } catch (error) {
      logAmplitudeEvent('hardware_segen_service_failed', {
        context: 'place_quote',
      })
      this.notify('Segen service is currently unavailable')
      console.warn('place quote error, ', error)
    }
  }

  editQuote = async ({
    reference,
    shippingDetails,
  }: {
    reference: string
    shippingDetails: ShippingDetailsType
  }): Promise<SegenQuoteResult | undefined> => {
    const quoteDetail = await this.restClientInstance('CUSTOM_PATCH', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/segen_api/edit_quote/${reference}/`,
      data: { shippingDetails },
    }).catch(() => {
      logAmplitudeEvent('hardware_segen_service_failed', {
        context: 'edit_quote',
      })
    })
    return quoteDetail?.data.result
  }

  placeOrder = async (id: number, shippingDetails: ShippingDetailsType, stockLocationName: string | undefined) => {
    const addressToShippingAddress = (address) => {
      return {
        companyName: address.organisation, // Is this a mistake?
        contactName: address.contactName,
        contactPhone: address.contactPhone,
        country: address.country,
        countryId: address.countryId,
        county: address.county,
        postCode: address.postCode,
        street: address.street,
        town: address.town,
      }
    }
    let addressErrorMessage = ''
    /*
    The following fields are marked as nullable in Segen API Docs so we avoid sending any that can be avoided:
    customerPo, despatchDate, stockLocationId, comments, customAutoMargin, description
    */
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/segen_api/place_order/${id}/`,
      data: {
        customerPo: null,
        // Even though docs say despatchDate is nullable, an empty value will throw an error
        // Populate with a dummy value of tomorrow.
        despatchDate: getTomorrowYYYYMMDD(), //Format: '2023-07-20',
        shippingAddress: addressToShippingAddress(shippingDetails.address),
        // Currently only default stock location will be supported
        stockLocationId: null, //stockLocationNameToId(stockLocationName),
        comments: null,
        customAutoMargin: null,
        description: null,
        hasLiftingEquipment: shippingDetails.hasLiftingEquipment,
        deliveryMethodPreference: shippingDetails.deliveryMethodPreference,
      },
    }).catch((err: any) => {
      /* @TODO: Add handling for this exception rather than supressing it. For now this will be ok because we can
      monitor these exceptions individually and decide how they should be handled but this is not a suitable
      long-term solution.
      */
      if (err.message.includes('ShippingAddress')) {
        addressErrorMessage = err.message
        this.notify(
          'Your preferred address was not able to be automatically populated. Please complete your address manually',
          'warning'
        )
      } else {
        this.notify('Segen service is currently unavailable')
      }

      logAmplitudeEvent('hardware_segen_service_failed', {
        context: 'place_order',
      })
      // TODO: send error detail to FE
      Sentry.captureException(err)
      console.log('error', err)
    })
    if (addressErrorMessage) {
      return {
        error: addressErrorMessage,
      }
    }
    return response?.data
  }

  getCountryIds = async () => {
    // Cache segen country ids
    if (this.countryIds != null) {
      return this.countryIds
    }
    const response = await this.restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/segen_api/country/`,
    })
    this.countryIds = response?.data.result
    return this.countryIds
  }

  getDeliveryMethodPreference = async () => {
    // Cache delivery methods
    if (this.deliveryMethods != null) {
      return this.deliveryMethods
    }
    const response = await this.restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/segen_api/delivery_method_preference/`,
    })

    // Production currently returns a simple array of strings but UAT returns in the form [{id:1, name:"ABC"},...]
    // Normalize so we always set the same options regardless of the format we receive.
    if (response?.data?.result?.[0]?.name) {
      this.deliveryMethods = response?.data.result.map((deliveryMethodItem) => deliveryMethodItem.name)
    } else {
      this.deliveryMethods = response?.data.result
    }
    return this.deliveryMethods
  }

  getAccountDetail = async (): Promise<AccountDetailType | undefined> => {
    // Cache account detail
    if (this.accountDetail != null) {
      return this.accountDetail
    }
    try {
      const response = await this.restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${appStorage.getOrgId()}/segen_api/account/`,
      })
      this.accountDetail = response?.data.result
    } catch (error) {
      this.notify('Segen service is currently unavailable')
      console.warn('getAccountDetail error, ', error)
    }
    return this.accountDetail
  }
}

export default SegenServiceV2
