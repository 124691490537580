// @ts-nocheck
import { makeStyles, Portal } from '@material-ui/core'
import { studioSelectors } from 'ducks/studioMode'
import { UiSwitch } from 'elements/UiSwitch'
import { useAutoSaveProjectEnabled } from 'projectSections/hooks/useAutoSaveProjectEnabled'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import DiscardButton from './DiscardButton'
import SaveButton from './SaveButton'
import UndoRedoButtons from './UndoRedoButtons'

const useStyles = makeStyles((theme: any) => ({
  actions: {
    height: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionsMobileCont: {
    height: 0, // Doesn't take up any layout room atm
  },
  actionsMobile: {
    display: 'flex',
    height: 50,
    padding: '0 20px',
    zIndex: 1000,
    boxSizing: 'border-box',
    width: '100%',
    background: 'rgba(219, 219, 219, 0.8)',
    transform: 'translateY(-100%)',
    alignItems: 'center',
  },
  formActions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItem: 'center',
    // [theme.breakpoints.down('lg')]: { minWidth: 185 },
  },
}))

export const Actions = ({ layout, submitting }: { submitting: boolean }) => {
  const form = useForm()
  useFormState() //hacky, but without adding a useFormState() here this cmponent does not reliably listen notice dirty fields if they're changed via form.change()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const autoSaveEnabled = useAutoSaveProjectEnabled()
  const disableActions = dirtyFields?.length === 0 || autoSaveEnabled
  const classes = useStyles()
  const isInStudio = useSelector(studioSelectors.isInStudio)

  return (
    <UiSwitch uiKey="project_form.actions">
      <div className={classes.actions}>
        <div style={{ marginRight: layout === 3 ? 5 : 10, minWidth: layout === 3 ? 56 : 64, display: 'flex' }}>
          {isInStudio && <UndoRedoButtons layout={layout} disableActions={disableActions} />}
        </div>
        {!disableActions && (
          <>
            <DiscardButton layout={layout} submitting={submitting} />
            <SaveButton size="small" layout={layout} submitting={submitting} />
          </>
        )}
      </div>
    </UiSwitch>
  )
}

export const ActionsMobile = ({ submitting }: { submitting: boolean }) => {
  const form = useForm()
  useFormState() //hacky, but without adding a useFormState() here this cmponent does not reliably listen notice dirty fields if they're changed via form.change()
  const studioMode = useSelector((state: any) => state.studioMode)
  const dirtyFields = form.mutators.getFormDirtyFields()
  const autoSaveEnabled = useAutoSaveProjectEnabled()
  const disableActions = dirtyFields?.length === 0 || autoSaveEnabled
  const classes = useStyles()
  const isInStudio = useSelector(studioSelectors.isInStudio)
  const showFormActionButtons = !disableActions

  if (studioMode === 'myenergy') {
    return null
  }
  return (
    <UiSwitch uiKey="project_form.actions">
      <Portal container={() => document.getElementById('secondary-footer')}>
        {(isInStudio || showFormActionButtons) && (
          <div className={classes.actionsMobileCont}>
            <div className={classes.actionsMobile}>
              {isInStudio && <UndoRedoButtons disableActions={disableActions} />}
              {showFormActionButtons && (
                <div className={classes.formActions} style={{}}>
                  <DiscardButton submitting={submitting} />
                  <SaveButton size="small" submitting={submitting} />
                </div>
              )}
            </div>
          </div>
        )}
      </Portal>
    </UiSwitch>
  )
}
