import { IconButton } from '@material-ui/core'
import { EditOutlined, GetAppOutlined, VisibilityOutlined } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { permissionsSelectors } from 'ducks/permissions'

import { DeleteOutlineIcon } from 'opensolar-ui'
import DeleteButton from 'projectSections/sections/manage3/common/DeleteButton'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { StyledMenu, StyledMenuBtnItem, StyledMenuItem } from '../../common/styles'
import { downloadFile, previewInfo } from '../../common/utils'
import { FilesContext } from '../ProjectFilesList'

export const GridItemDropdownMenu = ({ record, ...props }) => {
  const translate = useTranslate()

  const { selectDialog, selectFile, deleteFileInForm } = useContext(FilesContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { hasPreview } = previewInfo(record)
  const { allowDelete, allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('info_documents'))

  return (
    <>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreVertIcon />
      </IconButton>
      {anchorEl && (
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            selectFile(null)
            selectDialog('')
            setAnchorEl(null)
          }}
        >
          <StyledMenuItem
            onClick={(e) => {
              selectFile(record)
              selectDialog('edit_file')
            }}
            disabled={!allowEdit}
          >
            <EditOutlined htmlColor={COLOR_PALETTE.darkGrey} /> {translate('Edit')}
          </StyledMenuItem>
          <StyledMenuItem
            onClick={(e) => {
              selectFile(record)
              selectDialog('preview_file')
            }}
            disabled={!hasPreview}
          >
            <VisibilityOutlined htmlColor={COLOR_PALETTE.darkGrey} /> {translate('Preview')}
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              downloadFile(record)
            }}
          >
            <GetAppOutlined htmlColor={COLOR_PALETTE.darkGrey} /> {translate('Download')}
          </StyledMenuItem>
          <StyledMenuBtnItem>
            <DeleteButton
              btnLabel={'Delete'}
              title={'file'}
              record={record}
              resource={'private_files'}
              {...props}
              onSuccess={() => {
                deleteFileInForm(record.id)
              }}
              confirmMode="dialog"
              isDisabled={!allowDelete}
              btnProps={{
                startIcon: <DeleteOutlineIcon size={23} />,
                variant: 'text',
                color: 'error',
              }}
            />
          </StyledMenuBtnItem>
        </StyledMenu>
      )}
    </>
  )
}
