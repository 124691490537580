import { Grid } from '@material-ui/core'
import { Check, VerifiedUser } from '@material-ui/icons'
import ResponsiveVimeoPlayer from 'elements/ResponsiveVimeoPlayer'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import PaymentChannels from '../components/PaymentChannels'
import { FormRouteInfoPropTypes } from '../types'

const useStyles = makeOpenSolarStyles((theme) => ({
  mainContainer: {
    margin: ' 0 12px',
  },

  headerContainer: {
    textAlign: 'center',
  },

  header: {
    color: '#2C499D',
    fontSize: 15,
    fontWeight: 500,
  },

  description: {
    color: theme.greyMid1,
    fontSize: 11,
  },

  container: {
    background: '#EDF3FF',
    borderRadius: '8px',
    padding: '1rem',
    minHeight: '70%',
  },

  shieldIcon: {
    height: '1.25rem',
    color: '#019939',
    padding: '10px',
    background: '#DAE5FF',
    borderRadius: 7,
  },

  listContainer: {
    display: 'flex',
    gap: 15,
    margin: 18,
    lineHeight: 1.5,
  },

  checkIcon: {
    fontSize: 18,
    color: '#2C499D',
    marginTop: '2.5px',
  },
  channelsWrapper: {
    margin: '1rem',
  },
  dialog: {
    width: '100%',
    height: '100%',
    maxWidth: 840,
    maxHeight: 472,
  },
}))
const WelcomeTop: React.FC<FormRouteInfoPropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()

  return (
    <div className={classes.mainContainer} data-testid="bs-welcome-intro-top">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <div className={classes.dialog}>
            <ResponsiveVimeoPlayer
              videoUrl="https://player.vimeo.com/video/986846106?h=a7b7fca7f0"
              trackingSource={'cashflow_onboarding'}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <VerifiedUser className={classes.shieldIcon} />
              <h2 className={classes.header}>{translate('Effortless cash collection and accounting')}</h2>
            </div>
            <div className={classes.listContainer}>
              <Check className={classes.checkIcon} />
              <span>
                {translateParse('<strong> Save money <strong> on credit card fees', {
                  strong: (label: string) => <strong>{label}</strong>,
                })}
              </span>
            </div>
            <div className={classes.listContainer}>
              <Check className={classes.checkIcon} />
              <span>
                {translateParse('<strong> Save time <strong> and hassle processing all payments', {
                  strong: (label: string) => <strong>{label}</strong>,
                })}
              </span>
            </div>
            <div className={classes.listContainer}>
              <Check className={classes.checkIcon} />
              <span>
                {translateParse('Present a <strong> secure and easy-to-use <strong> payment interface', {
                  strong: (label: string) => <strong>{label}</strong>,
                })}
              </span>
            </div>

            <div className={classes.channelsWrapper}>
              <PaymentChannels />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default WelcomeTop
