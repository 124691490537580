import HostedPaymentsPage from 'myenergy/dialogs/checkout/cashFlow/HostedPaymentsPage'
import StipUploadPage from 'myenergy/selectionComponent/financeStipulations/StipUploadPage'
import GenericErrorPage from 'pages/auth/sso/GenericErrorPage'
import CashFlowLandingPage from 'pages/cashFlow/CashFlowLandingPage'
import CashFlowConfigurationPage from 'pages/cashFlow/configuration/CashFlowConfigurationPage'
import ConnectedOrgsHome from 'pages/ConnectedOrgsHome'
import { DashboardRedirect } from 'pages/dashboard/DashboardRedirect.tsx'
import QueryParamOrgSwitcher, { ORG_SWITCHER_PATH } from 'pages/orgSwitcher/QueryParamOrgSwitcher'
import PartnerOrgLinking from 'pages/partners/PartnerOrgLinking'
import UILibrary from 'pages/uiLibrary/UILibrary'
import { SharedMyEnergy } from 'projectSections/sections/proposal/MyEnergy'
import { NotFound, WithPermissions } from 'react-admin'
import { Redirect, Route } from 'react-router-dom'
import ShareSummary from 'resources/connectedOrgs/ShareSummary'
import SwapPaymentOptionsPage from 'resources/paymentOptions/swapPaymentOptions/SwapPaymentOptionsPage'
import PageRegister from './pages/auth'
import ChangeEmail from './pages/auth/ChangeEmail'
import ChangePassword from './pages/auth/ChangePassword'
import EmailChangeComplete from './pages/auth/EmailChangeComplete'
import EmailPreferences from './pages/auth/EmailPreferences'
import LoggingInWithNearmap from './pages/auth/LoggingInWithNearmap'
import LoginNearmapAdminRequired from './pages/auth/LoginNearmapAdminRequired'
import LoginNearmapChooseCreateOrUpgrade from './pages/auth/LoginNearmapChooseCreateOrUpgrade'
import LoginNearmapEmailClashed from './pages/auth/LoginNearmapEmailClashed'
import LoginNearmapEmailClashLoggedIn from './pages/auth/LoginNearmapEmailClashLoggedIn'
import LoginNearmapProductionNotProvisioned from './pages/auth/LoginNearmapProductionNotProvisioned'
import LoginWithQueryToken from './pages/auth/LoginWithQueryToken'
import PasswordChangeComplete from './pages/auth/PasswordChangeComplete'
import PasswordResetComplete from './pages/auth/PasswordResetComplete'
import PasswordResetForm from './pages/auth/PasswordResetForm'
import PasswordResetPage from './pages/auth/PasswordResetPage'
import PasswordResetRequestedPage from './pages/auth/PasswordResetRequestedPage'
import SSOAuthFlow from './pages/auth/sso/SSOAuthFlow'
import BusinessProcess from './pages/BusinessProcess'
import HostedUploadDocumentPage from './pages/cashFlow/configuration/documents/HostedUploadDocumentPage'
import { PageHome } from './pages/home/PageHome'
import InventoryPage from './pages/inventory/routes/LazyInventoryPage'
import { MyLearning } from './pages/learning/MyLearning'
import ManageOrdersPage from './pages/manageOrders/LazyManageOrdersPage'
import ConfigureMFA from './pages/mfa/ConfigureMFA'
import MfaActiveSessionWrapper from './pages/mfa/MfaActiveSessionWrapper'
import ShopPage from './pages/ordering/LazyShopPage'
import PageConfigSave from './pages/PageConfigSave'
import PageConfiguration from './pages/PageConfiguration'
import PageLogout from './pages/PageLogout'
import QuickStart from './pages/PageQuickStart'
import PageStaffReports from './pages/PageStaffReports'
import PageUploadCsv from './pages/PageUploadCsv'
import PageZone from './pages/zone'
import ProjectSections from './projectSections'
import { EditCustomerCheckout } from './resources/checkout/EditCustomerCheckout'
import { EventCalendar } from './resources/events/Events'
import { EditExternalKeys } from './resources/externalKeys/EditExternalKeys'
import DataSharingDialog from './resources/orgs/DataSharing'
import WalletHomePage from './resources/wallet/WalletHome'
// import OrgWalletTransactionList from './resources/walletTransactions/OrgWalletTransactionList'

//To do: refactor PageRegister by using props

export default [
  /*
  AP: I have no idea why this extra route is required to prevent redirecting from / to /projects which is
  the first resource registered
  */
  <Route path={'/'} exact render={(routeProps) => <Redirect to="/home" />} />,
  <Route
    path={'/projects/:id'}
    render={({ match, location }) => {
      const projectId = match.params.id || ''
      const isSpecialId = projectId === 'new' || projectId === 'replay'
      if (projectId.match(/^\d+$/) || isSpecialId) {
        return (
          <WithPermissions
            resource={'projects'}
            authParams={{ permission: 'is_pro' }}
            projectId={isSpecialId ? projectId : parseInt(projectId)}
            previousUnsavedData={!isSpecialId && location.state}
            component={ProjectSections}
          />
        )
      } else {
        return <NotFound />
      }
    }}
  />,
  /*
  home is UX2 only but we include it here to avoid problems with loading async in proUXRoutes.js
  */
  <Route path={'/home'} render={(routeProps) => <PageHome {...routeProps} />} />,
  <Route
    path={'/academy'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_pro' }} component={MyLearning} {...routeProps} />
    )}
  />,
  <Route path={`/configuration`} component={PageConfiguration} />,
  <Route exact path="/zone" component={PageZone} />,
  <Route exact path="/register" component={PageRegister} noLayout />,
  <Route path="/register-*" component={PageRegister} noLayout />,
  <Route exact path="/logout" component={PageLogout} noLayout />,
  <Route exact path="/password-reset" component={PasswordResetPage} noLayout />,
  <Route exact path="/login-modal" component={PageRegister} noLayout />,

  <Route exact path="/login-with-nearmap" component={LoggingInWithNearmap} noLayout />,
  <Route exact path="/login-nearmap-admin-required" component={LoginNearmapAdminRequired} noLayout />,
  <Route exact path="/login-nearmap-email-clash-logged-in" component={LoginNearmapEmailClashLoggedIn} noLayout />,
  <Route exact path="/login-nearmap-email-clash" component={LoginNearmapEmailClashed} noLayout />,
  <Route exact path="/login-nearmap-existing-account-new-user-email-clash" component={PageRegister} noLayout />,
  <Route
    exact
    path="/login-nearmap-existing-account-new-user-email-clash-logged-in"
    component={PageRegister}
    noLayout
  />,
  <Route exact path="/login-nearmap-choose-create-or-upgrade" component={LoginNearmapChooseCreateOrUpgrade} noLayout />,
  <Route
    exact
    path="/login-nearmap-product-not-provisioned"
    component={LoginNearmapProductionNotProvisioned}
    noLayout
  />,
  <Route exact path="/login-nearmap-email-mismatch" component={PageRegister} noLayout />,
  <Route
    exact
    path="/sso-login"
    render={({ match, location }) => {
      const ssoConfig = location.state?.ssoConfig
      const options = location.state?.options
      return <SSOAuthFlow ssoConfig={ssoConfig} options={options} />
    }}
    noLayout
  />,
  <Route
    exact
    path="/sso-login-complete"
    render={({ match, location }) => {
      return (
        <GenericErrorPage
          message={'Login complete. You may now close this window and return to the application.'}
          showBackButton={false}
        />
      )
    }}
    noLayout
  />,
  <Route
    exact
    path="/logging-in-with-query-token"
    render={() => {
      return <LoginWithQueryToken />
    }}
    noLayout
  />,
  // Move login-* route until after all the login-nearmap... and login-with.nearmap... and login-modal so they will
  // be matched before the wildcard login- is matched
  <Route
    path="/login"
    render={(routeProps) => {
      return <PageRegister {...routeProps} />
    }}
    noLayout
  />,
  <Route
    path="/login-*"
    render={(routeProps) => {
      return <PageRegister {...routeProps} />
    }}
    noLayout
  />,

  <Route exact path="/redirect-to-login-with-nearmap" component={PageRegister} noLayout />,
  <Route path="/password-reset-requested" component={PasswordResetRequestedPage} noLayout />,
  <Route path="/password-set-form" component={PasswordResetForm} noLayout />,
  <Route path="/password-reset-form" component={PasswordResetForm} noLayout />,
  <Route path="/password-reset-complete" component={PasswordResetComplete} noLayout />,
  <Route exact path="/password-change-form" component={ChangePassword} />,
  <Route exact path="/email-preferences" component={EmailPreferences} />,
  <Route exact path="/password-change-complete" component={PasswordChangeComplete} />,
  <Route exact path="/email-change-form" component={ChangeEmail} />,
  <Route exact path="/email-change-complete" component={EmailChangeComplete} />,
  <Route path="/myenergy" component={SharedMyEnergy} noLayout />,
  <Route
    path="/data_sharing"
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_admin' }} component={DataSharingDialog} {...routeProps} />
    )}
  />,
  <Route
    path={'/control'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_pro' }} component={DashboardRedirect} {...routeProps} />
    )}
  />,
  <Route
    path={'/shop'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_pro' }} component={ShopPage} {...routeProps} />
    )}
  />,
  <Route
    path={'/manage_orders'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_pro' }} component={ManageOrdersPage} {...routeProps} />
    )}
  />,
  <Route
    path={'/inventory'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_pro' }} component={InventoryPage} {...routeProps} />
    )}
  />,
  <Route
    path={`/staff_reports`}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_admin' }} component={PageStaffReports} {...routeProps} />
    )}
  />,
  <Route
    path={`/config_save`}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_admin' }} component={PageConfigSave} {...routeProps} />
    )}
  />,
  <Route exact path="/calendar" component={EventCalendar} />,
  <Route
    path={`/quick-start`}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_admin' }} component={QuickStart} {...routeProps} />
    )}
  />,
  <Route exact path="/business-process" component={BusinessProcess} />,
  <Route exact path="/configure-mfa" component={ConfigureMFA} />,
  <Route exact path="/payments_configurations" component={CashFlowConfigurationPage} />,
  <Route exact path="/mfa/:page" component={MfaActiveSessionWrapper} />,
  <Route
    exact
    path={'/csv_upload'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_admin' }} component={PageUploadCsv} {...routeProps} />
    )}
  />,
  <Route
    path={'/ui_library'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_staff' }} component={UILibrary} {...routeProps} />
    )}
  />,

  <Route
    exact
    path="/customer_checkout/:id"
    render={(routeProps) => <EditCustomerCheckout resource="orgs" {...routeProps} />}
  />,
  <Route
    exact
    path="/external_api_keys/:id/:result?/:partner?"
    render={(routeProps) => <EditExternalKeys resource="orgs" {...routeProps} />}
  />,
  <Route exact path="/wallet" render={(routeProps) => <WalletHomePage resource="wallet" {...routeProps} />} />,
  <Route
    path="/stipulation_upload/:orgId/:projectId/:partnerId/:partner"
    render={(routeProps) => <StipUploadPage {...routeProps} />}
    noLayout
  />,
  <Route
    path="/projects/:projectId/payment/:paymentRequestId"
    render={(routeProps) => <HostedPaymentsPage {...routeProps} />}
    noLayout
  />,
  <Route exact path="/payment_options/swap" render={(routeProps) => <SwapPaymentOptionsPage {...routeProps} />} />,
  <Route exact path="/connected_orgs_home" component={ConnectedOrgsHome} />,
  <Route
    exact
    path="/connected_orgs/:id/shared"
    render={(routeProps) => <ShareSummary resource="connected_orgs" {...routeProps} />}
  />,
  <Route
    exact
    path="/org_linking/:partner"
    render={({ match }) => {
      const partner = match.params.partner
      return <WithPermissions authParams={{ permission: 'is_pro' }} component={PartnerOrgLinking} partner={partner} />
    }}
  />,
  <Route
    exact
    path={`/${ORG_SWITCHER_PATH}/:id`}
    render={({ match }) => {
      const switchId = match.params.id
      return (
        <WithPermissions authParams={{ permission: 'is_pro' }} component={QueryParamOrgSwitcher} switchId={switchId} />
      )
    }}
  />,
  <Route
    path="/cashflow_documents/:orgId"
    render={(routeProps) => <HostedUploadDocumentPage {...routeProps} />}
    noLayout
  />,
  <Route
    path={'/cashflow'}
    render={(routeProps) => (
      <WithPermissions authParams={{ permission: 'is_pro' }} component={CashFlowLandingPage} noLayout {...routeProps} />
    )}
  />,
]
