export const CONTACT_FIELD_MAP = [
  { owner: 'address', companyRep: 'companyRepAddress' },
  { owner: 'city', companyRep: 'companyRepCity' },
  { owner: 'country', companyRep: 'companyRepCountry' },
  { owner: 'ownerDateOfBirth', companyRep: 'companyRepDateOfBirth' },
  { owner: 'ownerFirstName', companyRep: 'companyRepFirstName' },
  { owner: 'ownerGovID', companyRep: 'companyRepGovID' },
  { owner: 'ownerLastName', companyRep: 'companyRepLastName' },
  { owner: 'ownerPhone', companyRep: 'companyRepPhone' },
  { owner: 'zip', companyRep: 'companyRepZip' },
]
