import { Scenario } from './constants'

export const canCalculateScaffoldCost = (customData) => {
  const scaffoldProperties = customData?.form_data?.scaffold_properties
  if (scaffoldProperties?.multiple_properties === 'Yes' || scaffoldProperties?.obstructions_exceeding_7m === 'Yes') {
    return false
  }
  return true
}

export const findClosestValue = (value, options) => {
  return options.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev))
}

export const validateScaffoldProperties = (customData) => {
  const errors = {}
  if (
    !customData.form_data ||
    !customData.form_data.scaffold_properties ||
    !customData.form_data.scaffold_properties.height ||
    !customData.form_data.scaffold_properties.length ||
    !customData.form_data.scaffold_properties.multiple_properties ||
    !customData.form_data.scaffold_properties.obstructions_exceeding_7m ||
    !customData.form_data.scaffold_properties.require_access_through_house_building ||
    !customData.form_data.scaffold_properties.scaffold_on_pavement_or_road
  ) {
    errors['form_data'] = 'Please complete the missing fields above'
  }
  return errors
}

export const initializePvfCustomData = (project) => {
  const customData = project?.custom_data?.scaffolding
  const initializedPvfData = {
    ...customData,
    zendesk_ticket_id: customData?.zendesk_ticket_id ?? '',
    updated_cost: customData?.updated_cost ?? null,
    estimated_cost: customData?.estimated_cost ?? null,
    total_settled: null,
    order_status: customData?.order_status ?? '',
    order_status_note: customData?.order_status_note ?? '',
    form_data: {
      ...customData?.form_data,
      scaffold_properties: {
        length: customData?.form_data?.scaffold_properties?.length ?? null,
        height: customData?.form_data?.scaffold_properties?.height ?? null,
        multiple_properties: customData?.form_data?.scaffold_properties?.multiple_properties ?? '',
        scaffold_on_pavement_or_road: customData?.form_data?.scaffold_properties?.scaffold_on_pavement_or_road ?? '',
        require_access_through_house_building:
          customData?.form_data?.scaffold_properties?.require_access_through_house_building ?? '',
        obstructions_exceeding_7m: customData?.form_data?.scaffold_properties?.obstructions_exceeding_7m ?? '',
        require_shrouding: customData?.form_data?.scaffold_properties?.require_shrouding ?? '',
      },
      customer_first_name: customData?.form_data?.customer_first_name ?? '',
      customer_last_name: customData?.form_data?.customer_last_name ?? '',
      customer_email: customData?.form_data?.customer_email ?? '',
      customer_phone: customData?.form_data?.customer_phone ?? '',
      project_installation_date: customData?.form_data?.project_installation_date ?? '',
      site_notes: customData?.form_data?.site_notes ?? '',
      site_photos: customData?.form_data?.site_photos ?? [],
      site_connecting_photos: customData?.form_data?.site_connecting_photos ?? [],
      project_manager_email: project?.assigned_role_email ?? '',
    },
    draft: customData?.draft ?? false,
    scenario: customData?.scenario ?? Scenario.ESTIMATE_COST,
    submit_order_consent: customData?.submit_order_consent ?? false,
    pavement_cost: customData?.pavement_cost ?? null,
    access_through_house_cost: customData?.access_through_house_cost ?? null,
    order_submission_timestamp: customData?.order_submission_timestamp ?? '',
    rag_status: customData?.rag_status ?? '',
    rag_notes: customData?.rag_notes ?? '',
    erect_date: customData?.erect_date ?? '',
    dismantle_date: customData?.dismantle_date ?? '',
    booked: customData?.booked ?? 'not_booked',
  }

  return initializedPvfData
}

export const initializeEmptyPvfData = (project) => {
  const customData = project?.custom_data?.scaffolding
  const initializedPvfData = {
    ...customData,
    zendesk_ticket_id: '',
    updated_cost: null,
    estimated_cost: null,
    total_settled: null,
    order_status: '',
    order_status_note: '',
    form_data: {
      ...customData?.form_data,
      scaffold_properties: initializeScaffoldProperties(),
      customer_first_name: '',
      customer_last_name: '',
      customer_email: '',
      customer_phone: '',
      project_installation_date: '',
      site_notes: '',
      site_photos: [],
      site_connecting_photos: [],
      project_manager_email: project?.assigned_role_email ?? '',
    },
    draft: false,
    scenario: Scenario.ESTIMATE_COST,
    is_pvf_enabled: false,
    submit_order_consent: false,
    pavement_cost: null,
    access_through_house_cost: null,
    order_submission_timestamp: '',
    rag_status: '',
    rag_notes: '',
    erect_date: '',
    dismantle_date: '',
    booked: 'not_booked',
  }

  return initializedPvfData
}

export const initializeScaffoldProperties = () => ({
  length: null,
  height: null,
  multiple_properties: '',
  scaffold_on_pavement_or_road: '',
  require_access_through_house_building: '',
  obstructions_exceeding_7m: '',
  require_shrouding: '',
})
