import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { projectViewSettingsActions, projectViewSettingsSelectors } from 'ducks/projectViewSettings'
import { splitSelectors } from 'ducks/split'
import { DataOutlineIcon } from 'opensolar-ui'
import { useProjectEntityPermissions } from 'projectSections/sections/manage/projectManagement/workflow/hooks'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import { ExternalWorkflow } from 'types/workflows'
import { urlToId } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import SelectChipField from '../common/SelectChipField'
import HardwareSection from '../hardware/HardwareSection'
import { getProjectSectionStyles } from '../styles'
import { useProjectExternalOrg } from './hooks'
import { retrieveExternalWorkflows, updateProjectWorkflows } from './util'
import Workflow from './Workflow'
import WorkflowSelect from './WorkflowSelect'

const useStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      width: '100%',
    },
    header: {
      fontSize: 16,
      margin: 0,
      marginLeft: 2,
      marginRight: 20,
      fontWeight: 500,
    },
    fullWidth: {
      width: '100%',
    },
    marginLeft: {
      marginLeft: 'auto',
    },
    sectionTop: {
      padding: 'unset',
    },
    sectionContentWrapper: {
      padding: 20,
      paddingLeft: 0,
      width: '100%',
    },
    selectView: {
      marginLeft: 20,
    },
  }),
  { name: 'WorkflowSection' }
)

const WorkflowSection = (props) => {
  const form = useForm()
  const notify = useNotify()
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const project = useFormState().values
  const org = useSelector(orgSelectors.getOrg)
  const externalOrg = useProjectExternalOrg()
  const sectionStyles = getProjectSectionStyles()
  const currOrgId = useSelector(authSelectors.getOrgId)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const { view } = useSelector(projectViewSettingsSelectors.getProjectWorkflowView)
  const externalDisplay = useMemo(() => view === 'external', [view])
  const [externalWorkflows, setWorkflows] = useState<ExternalWorkflow[] | undefined>(undefined)
  const enableManageShop = useFeatureFlag('manage_shop', 'on')
  const splitIsReady = useSelector(splitSelectors.getSplitIsReady)

  useEffect(() => {
    retrieveExternalWorkflows(project, currOrgId, notify, setWorkflows)
  }, [])

  //display currently only supports 1 external org at a time
  const externalWorkflow = useMemo(() => (!!externalWorkflows?.length ? externalWorkflows[0] : undefined), [
    externalWorkflows,
  ])
  const noSharedWorkflows = useMemo(() => externalDisplay && !!!externalWorkflows?.length, [
    externalWorkflows,
    externalDisplay,
  ])
  const selectedWorkflow = useMemo(() => {
    if (externalDisplay && externalWorkflow) {
      return externalWorkflow?.workflows?.find((x) => x.id === externalWorkflow?.selectedWorkflow?.workflow_id)
    } else {
      return orgWorkflows?.find((x) => x.id === project.workflow?.workflow_id)
    }
  }, [externalDisplay, externalWorkflows, orgWorkflows, project.workflow])
  const { allowView, allowEdit } = useProjectEntityPermissions(
    selectedWorkflow?.org ? urlToId(selectedWorkflow?.org) : undefined,
    'manage_workflow_stages'
  )

  const handleWorkflowChange = (workflow) => {
    if (externalDisplay && externalWorkflow) {
      updateProjectWorkflows(externalWorkflow?.selectedWorkflow, workflow, project, form, 'external_workflow')
      let updatedExternal = externalWorkflows ? [...externalWorkflows] : []
      updatedExternal[0] = { ...externalWorkflow, selectedWorkflow: { ...workflow } }
      setWorkflows(updatedExternal)
    } else {
      updateProjectWorkflows(project.workflow, workflow, project, form, 'workflow')
    }
  }
  useEffect(() => {
    const fields = [
      'project_sold',
      'project_installed',
      'installation_date',
      'contract_date',
      'external_workflow',
      'workflow',
    ]
    fields.forEach((field) => {
      form.registerField(field, () => {}, {})
    })
  }, [])
  return (
    <div className={`${classes.wrapper} ${sectionStyles.moduleFullWidth}`}>
      <div className={`${sectionStyles.sectionCardTop} ${sectionStyles.row} ${classes.sectionTop}`}>
        <div className={sectionStyles.iconPadding}>
          <DataOutlineIcon size={18} variant={3} />
        </div>
        <div className={`${sectionStyles.row} ${classes.sectionContentWrapper}`}>
          <p className={classes.header}>{translate('Workflow')}</p>
          {allowView && !noSharedWorkflows && (
            <WorkflowSelect
              workflows={externalDisplay && externalWorkflow ? externalWorkflow.workflows : orgWorkflows}
              selectedWorkflow={selectedWorkflow}
              handleChange={handleWorkflowChange}
              disabled={!allowEdit}
            />
          )}
          {!!project.shared_with?.length && (
            <div className={`${sectionStyles.row} ${classes.marginLeft}`}>
              <SelectChipField
                className={classes.selectView}
                choices={[
                  { value: 'internal', title: org?.name },
                  { value: 'external', title: externalOrg },
                ]}
                chipLabel={translate('View As')}
                optionValue={'value'}
                optionText={'title'}
                value={view}
                onChange={(e) => {
                  const value: any = e.target.value
                  dispatch(
                    projectViewSettingsActions.setWorkflowView({
                      view: value,
                      externalWorkflow: value === 'internal' ? undefined : externalWorkflow,
                    })
                  )
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Workflow
        selectedWorkflow={selectedWorkflow}
        externalWorkflow={externalDisplay ? externalWorkflow : undefined}
        handleChange={handleWorkflowChange}
        noSharedWorkflows={noSharedWorkflows}
      />
      {enableManageShop ? (
        splitIsReady ? (
          <>
            <Divider light />
            <HardwareSection project={project} />
          </>
        ) : (
          <Skeleton />
        )
      ) : null}
    </div>
  )
}

export default WorkflowSection
