import { Grid, Paper, Tooltip } from '@material-ui/core'
import type { OpenSolarThemeType } from 'Themes'
import Skeleton from 'react-loading-skeleton'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => {
  return {
    paper: {
      width: 180,
      height: 100,
      padding: 10,
      boxSizing: 'border-box',
      borderRadius: '10px',
    },
    container: {
      height: '100%',
    },
    box: {
      height: '50%',
    },
    figure: {
      fontWeight: 500,
      fontSize: 36,
      lineHeight: '28px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    description: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '12px',
    },
  }
})

const FigureCard = ({
  figure,
  description,
  loading,
}: {
  figure?: number | string
  description: string
  loading: boolean
}) => {
  const classes = useStyles()

  return (
    <Paper variant="outlined" classes={{ root: classes.paper }}>
      <Grid container className={classes.container}>
        <Grid container item xs={12} className={classes.box} justify="center" alignItems="flex-end">
          {loading ? (
            <Skeleton width={80} height={28} />
          ) : (
            <Tooltip title={figure ?? ''}>
              <span className={classes.figure}>{figure}</span>
            </Tooltip>
          )}
        </Grid>
        <Grid container item xs={12} className={classes.box} justify="center" alignItems="center">
          {loading ? <Skeleton width={100} height={12} /> : <span className={classes.description}>{description}</span>}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FigureCard
