import { Chip } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import compose from 'recompose/compose'
import Panel from '../Designer/Panel'

import withStudioSignals from 'Studio/signals/withStudioSignals'
import { refreshPanelGeneric } from '../Studio/Utils'
class PanelOsGroup extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      group: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.group) {
      injectState = this.stateFromObject(props.group)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state

    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  stateFromObject(group) {
    if (!group) {
      return { visible: false }
    }
    const allObjectsAreFacets = group.objects.every((child) => child.type === 'OsFacet')
    return {
      title: allObjectsAreFacets ? 'Structure' : 'Selection',
      visible: true,
      group: group,
    }
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsGroup')
  }

  // @TODO: Standardize this so the mapping is consistent across the entire UI
  getDisplayStringForObject(object) {
    const translate = this.props.translate

    switch (object.type) {
      case 'OsModuleGrid': {
        const panels = object.moduleQuantity()
        return panels === 1 ? translate('1 Panel') : translate('%{panels} Panels', { panels })
      }

      case 'OsFacet': {
        return translate('Roof Facet')
      }

      case 'OsStructure': {
        return translate('Quick Roof')
      }

      default: {
        return object.type || translate('Object')
      }
    }
  }

  render() {
    if (!this.state.visible) return null

    const objectsInGroup = this.state.group?.objects || []

    return (
      <Panel
        showTools={this.state.allowEdit}
        selectedObject={this.state.group}
        title={this.state.title}
        summary={null}
        feature={
          <>
            {objectsInGroup.map((object, index) => {
              return (
                <Chip
                  id={`Object-${index}`}
                  key={`Object-${index}`}
                  style={{ margin: 4, border: '1px solid transparent' }}
                  label={this.getDisplayStringForObject(object)}
                />
              )
            })}
          </>
        }
      />
    )
  }
}

PanelOsGroup.propTypes = {
  state: PropTypes.object,
}

export default compose(withTranslate, withStudioSignals)(PanelOsGroup)
