import React from 'react'
import { RenderablePanel } from 'types/renderablePanel'
import { FormPlacementInfo } from './findFormPlacements'

const DEFAULT_CUSTOM_FORM_PRIORITY = 2

export default function getRenderFunction(component) {
  return function addFormsAndSort(
    toRender: RenderablePanel[] = [],
    customForms: FormPlacementInfo[],
    props: { [key: string]: any } = {}
  ) {
    if (customForms?.length > 0) {
      let formsToAdd = customForms?.map((customForm) => {
        const placement = customForm.placements[0]
        return {
          key: 'custom-form-' + customForm.form.id,
          component: component,
          shouldRender: true,
          // Add 0.5 to make sure custom forms are always rendered after standard components
          priority: (placement.priority !== null ? placement.priority : DEFAULT_CUSTOM_FORM_PRIORITY) + 0.5,
          props: {
            customForm,
            ...props,
          },
        }
      })

      toRender = toRender.concat(formsToAdd)
      toRender.sort((a, b) => {
        if (a.priority === b.priority) {
          return 0
        } else if (a.priority < b.priority) {
          return -1
        } else {
          return 1
        }
      })
    }
    return toRender
      .filter((comp) => comp.shouldRender)
      .map((comp, i) => <comp.component key={comp.key || comp.component.displayName || i} {...comp.props} />)
  }
}
