import { orgSelectors } from 'ducks/orgs'
import _ from 'lodash'
import type { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useStudioSignalsLazy } from '../../../../Studio/signals/useStudioSignalsLazy'
import useHardwareDetailsDynamic from '../sideDrawer/bom/useHardwareDetailsDynamic'
import useMapHardwareDetailsToBomLineItems, {
  BomLineItemsHashMap,
} from '../sideDrawer/bom/useMapHardwareDetailsToBomLineItems'

const defaultComponentOverrides = {}

const useInjectDistributorCostOverride = () => {
  const [componentOverrides, setComponentOverrides] = useState(defaultComponentOverrides)
  const componentOverridesRef = useRef<object>()
  componentOverridesRef.current = componentOverrides
  const hardwareDetails = useHardwareDetailsDynamic()
  const supplierFilterKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  const bomLineItemsHashMap = useMapHardwareDetailsToBomLineItems(hardwareDetails)
  const bomLineItemsHashMapRef = useRef<BomLineItemsHashMap>()
  const supplierFilterKeyRef = useRef<HardwareSupplierFilterKeyType>()
  const ukHardwareSuppliers = useSelector(orgSelectors.getEnableUKHardwareSuppliers)
  bomLineItemsHashMapRef.current = bomLineItemsHashMap
  supplierFilterKeyRef.current = supplierFilterKey

  const overrideComponentCost = useCallback(() => {
    const component_overrides =
      bomLineItemsHashMapRef.current?.all.reduce(function (map, obj) {
        const key = obj.data?.id || ''
        let distributorPrice = obj?.getBestDiscountOffer()?.afterDiscount
        if (distributorPrice) {
          distributorPrice = distributorPrice / obj.quantity
        }
        if (distributorPrice) {
          map[key] = distributorPrice
        }
        return map
      }, {}) || {}
    if (!_.isEqual(componentOverridesRef.current, component_overrides)) {
      const systemUuid = window.editor.selectedSystem?.uuid
      const system = systemUuid && window.editor.objectByUuid(systemUuid)

      if (system) {
        Object.entries(component_overrides).forEach(([key, value]) => {
          system.setComponentCostOverride({ id: key }, undefined, value, supplierFilterKeyRef.current)
        })
      }
      setComponentOverrides(component_overrides)
    }
  }, [])

  useStudioSignalsLazy(
    (object) => {
      if (
        (object.type !== 'OsSystem' &&
          object.type !== 'OsInverter' &&
          object.type !== 'OsBattery' &&
          object.type !== 'OsOther' &&
          object.type !== 'OsModule' &&
          object.type !== 'OsModuleGrid') ||
        !ukHardwareSuppliers.length
      ) {
        return
      }
      overrideComponentCost()
    },
    ['objectAdded', 'objectRemoved', 'objectChanged'],
    undefined,
    { debounce: 1 }
  )
}
export default useInjectDistributorCostOverride
