import { Divider, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { ComponentVersions_2_1 } from 'constants/uxVersions'
import { InverterRatio, ToolsInverter } from 'Designer/Toolbars'
import { setElectricalDialog } from 'ducks/electrical'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import { DraggableList } from 'elements/dnd/DraggableList'
import InverterDesignAssistantDialog from 'elements/hardwareSelectorV2/inverterWizard/InverterDesignAssistantDialog'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import { Button, Chip, ComponentVersionsInherit, IconButton } from 'opensolar-ui'
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { StudioInverterType, StudioSystemType } from 'types/global'
import { useFeatureFlag } from 'util/split'
import { ExternalLink } from '../../PanelSystem'
import ComponentWarningBox from '../../warning/ComponentWarningBox'
import { ComponentSelectorDropdown } from '../common/ComponentSelectorDropdown'
import { RemoveComponentButton } from '../common/RemoveComponentButton'
import { InverterStringIsolationMethod } from './InverterStringIsolationMethod'
import MpptDetailPopover from './MpptDetailPopover'

interface InverterBlockPropsType extends WithHardwareSelectorV2Props {
  handleAddComponent: Function
  system: StudioSystemType
  item: StudioInverterType
  allowEdit: boolean
  dragHandle: ReactNode | undefined
}

const useDraggableInverterBlockStyles = makeStyles((theme: any) => ({
  mpptHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoWrapper: {
    display: 'inline-block',
    '& span': {
      verticalAlign: 'middle',
      fontSize: 10,
    },
  },
  icon: { width: 20, height: 20, cursor: 'help', color: theme.blue, verticalAlign: 'middle' },
}))

const InverterBlockComponent: FC<InverterBlockPropsType> = ({
  allowEdit = true,
  dragHandle,
  enableHardwareDialogV2,
  handleAddComponent,
  handleOpenHardwareSelector,
  item: inverter,
  system,
}) => {
  const dispatch = useDispatch()
  const classes = useDraggableInverterBlockStyles()
  const translate = useTranslate()
  const slotsEnabled = useFeatureFlag('electrical_slots', 'on')
  const enableInverterWizard = useFeatureFlag('enable_inverter_wizard', 'on')
  const [isInverterDesignAssistantOpen, setIsInverterDesignAssistantOpen] = useState(false)

  const isSelected = useCallback((object) => {
    return object && window.editor && window.editor.selected && window.editor.selected.uuid === object.uuid
  }, [])

  const handleSelectObjectByUuid = useCallback((uuid) => {
    window.editor.selectByUuid(uuid)
  }, [])

  const handleDeleteObjectByUuid = useCallback((uuid) => {
    window.editor.deleteObjectByUuid(uuid)
  }, [])

  const openManualSelector = () => {
    if (enableHardwareDialogV2)
      handleOpenHardwareSelector({
        title: 'Select Inverters',
        componentTypes: ['inverter'],
        targetUuids: [inverter.uuid],
      })
    else
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper?.project?.org_id,
          window.WorkspaceHelper?.project?.id,
          [inverter.uuid],
          'inverter'
        )
      )
  }

  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flexGrow: 1 }}>
            <ComponentSelectorDropdown
              componentType="inverter"
              //@ts-ignore
              component={window.AccountHelper.getInverterById(inverter.inverter_id)}
              availableComponents={window.AccountHelper.getComponentInverterSpecsAvailable()}
              disabled={!allowEdit}
              select={() => {
                if (enableInverterWizard) {
                  setIsInverterDesignAssistantOpen(true)
                } else {
                  openManualSelector()
                }
              }}
            />
          </div>
          <RemoveComponentButton
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.editor.deleteObject(inverter)
            }}
          />
          {dragHandle}
        </div>
        {inverter?.inverter_id && (
          <InverterRatio object={inverter} system={system} isMicroInverter={Boolean(inverter.microinverter)} />
        )}

        {
          <div style={{ clear: 'both' }}>
            {inverter.mppts().map((mppt: any, mpptIndex: number) => (
              <div key={mppt.uuid}>
                <div className={classes.mpptHeader}>
                  <div>
                    <span style={{ verticalAlign: 'middle' }}>
                      {Boolean(inverter.microinverter) ? `Branch ${mpptIndex + 1}` : `MPPT ${mpptIndex + 1}`}
                    </span>
                    <IconButton
                      style={{ padding: 0, width: 24, height: 24 }}
                      disabled={!allowEdit}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        window.editor.deleteObject(mppt)
                      }}
                    >
                      {<DeleteIcon style={{ color: '#000000', width: 18, height: 18 }} />}
                    </IconButton>
                    <Button
                      style={{
                        minWidth: 32,
                        margin: 0,
                        marginLeft: 5,
                      }}
                      disabled={!allowEdit}
                      variant="contained"
                      color="default"
                      size="small"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleAddComponent('string', mppt.uuid)
                      }}
                      startIcon={<AddIcon />}
                    >
                      <span>{Boolean(inverter.microinverter) ? translate('Cable') : translate('String')}</span>
                    </Button>
                  </div>

                  {!Boolean(inverter.microinverter) && !Boolean(system.dcOptimizer()) && (
                    <div className={classes.infoWrapper}>
                      {' '}
                      <span>{translate('MPPT')}</span> <MpptDetailPopover mppt={mppt} index={mpptIndex} />
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px 0' }}>
                  {mppt.strings().map((electricalString: any) => (
                    <Chip
                      key={electricalString.uuid}
                      disabled={!allowEdit}
                      onDelete={() => {
                        handleDeleteObjectByUuid(electricalString.uuid)
                        if (Boolean(inverter.microinverter) && mppt.strings().length === 0) {
                          handleDeleteObjectByUuid(mppt.uuid)
                        }
                      }}
                      onClick={(e) => {
                        handleSelectObjectByUuid(electricalString.uuid)
                        e.stopPropagation()
                      }}
                      style={{
                        margin: 4,
                        border: isSelected(electricalString) ? 'solid 1px #ffff00' : '1px solid transparent',
                      }}
                      label={
                        Boolean(inverter.microinverter)
                          ? `${translate('Cable')} ${electricalString.moduleQuantity()}`
                          : `${translate('String')} ${electricalString.moduleQuantity()}`
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        }
        <ComponentWarningBox componentIds={[inverter.uuid]} />
        <ExternalLink componentData={inverter.getComponentData()} />
        {(!inverter.getComponentData()?.mppt_quantity ||
          inverter.mppts().length < inverter.getComponentData().mppt_quantity ||
          inverter.userData?.microinverter) && (
          <ToolsInverter
            object={inverter}
            allowEdit={allowEdit}
            plusOnClick={() => {
              handleAddComponent('mppt', inverter.uuid)
            }}
            system={system}
            isMicroInverter={Boolean(inverter.microinverter)}
            plusLabel={inverter.mppts().length > 0 ? '+ ' + translate('MPPT') : '+ ' + translate('Stringing')}
          />
        )}
      </div>
      <InverterDesignAssistantDialog
        targetUuids={[inverter.uuid]}
        isOpen={isInverterDesignAssistantOpen}
        onClose={() => setIsInverterDesignAssistantOpen(false)}
      />
      <InverterStringIsolationMethod system={system} inverter={inverter} disabled={!allowEdit} />
      {slotsEnabled && (
        <ComponentVersionsInherit versions={ComponentVersions_2_1}>
          <Button
            variant="contained"
            size="small"
            disabled={!allowEdit}
            onClick={() => {
              dispatch(setElectricalDialog(true, inverter))
            }}
          >
            <span>{translate('Add isolators and cabling')}</span>
          </Button>
        </ComponentVersionsInherit>
      )}
      <Divider style={{ height: 2, marginTop: 20 }} />
    </div>
  )
}

const InverterBlock = withHardwareSelectorV2(InverterBlockComponent)

type InverterListPropsType = {
  system: StudioSystemType
  handleAddComponent: Function
  allowEdit: boolean
}

const InverterList: FC<InverterListPropsType> = ({ system, handleAddComponent, allowEdit = true }) => {
  useEffect(() => {
    return () => {
      // reselect system when inverter tab get unmounted during stringing drawing
      // disable stringing drawing when user has navigated out of inverters
      if (window.editor?.selected?.type === 'OsString') {
        window.editor?.select(system)
      }
    }
  }, [])
  return (
    <DraggableList
      handleReorder={(oldOrder, newOrder) => {
        window.editor.execute(new window.ReorderChildrenCommand(system, oldOrder, newOrder))
      }}
      list={system.inverters()}
      render={(item, { dragHandle }) => (
        <InverterBlock
          item={item}
          system={system}
          allowEdit={allowEdit}
          handleAddComponent={handleAddComponent}
          dragHandle={dragHandle}
        />
      )}
      applyDragStyles={true}
    />
  )
}

export default InverterList
