import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useState } from 'react'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useDistributorAuthenticationStatus = ({
  orgId,
  distributor,
}: {
  orgId: number
  distributor: HardwareSupplierFilterKeyType
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isChecking, setIsChecking] = useState<boolean>(false)

  useEffect(() => {
    setIsChecking(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/distributors/${distributor}/authentication_status/`,
    })
      .then((response) => {
        if (response?.data?.result?.is_authenticated) {
          setIsAuthenticated(true)
        }
      })
      .catch((err: any) => {
        console.log(err, err)
      })
      .finally(() => {
        setIsChecking(false)
      })
  }, [orgId, distributor])

  return { isAuthenticated, isChecking }
}

export default useDistributorAuthenticationStatus
