import { RootState } from 'types/state'

export const tourSelectors = {
  tour: (state: RootState) => {
    return state.tour.tour
  },
  step: (state: RootState) => {
    return state.tour.step
  },
}
