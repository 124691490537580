import { ForecastStatus } from 'pages/inventory/forecast/type'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import type { ContactType } from 'types/contacts'
import type { ComponentTypes } from 'types/selectComponent'

export enum HardwareSupplierEnum {
  Segen = 1,
  SolarOutlet,
  CityPlumbing,
  Hdm,
  VtacUk,
  VtacPoland
}

export type HardwareSupplierFilterKeyType = 'segen' | 'outlet' | 'city_plumbing' | 'hdm' | 'vtac_uk' | 'vtac_pl'

export type ProjectOrderDetail = {
  requiredQuantity: number
  projectId: number
  systemId?: number
}

export type BasicComponentLineItemType = {
  code: string
  quantity: number
}

export type LineItemStatus = 'loaded' | 'loading' | 'error'

export type StockLevelStatus =
  | 'loading'
  | 'available'
  | 'low_stock'
  | 'partial_available'
  | 'out_of_stock'
  | 'not_available'
  | 'available_for_pre_order'

export type StockLevelType = {
  planned_date?: string
  in_stock: boolean
  quantity: number
  location?: string
}

type MinimumQuantityRequirementType = {
  greaterThanOrEqualToQuantity: string
}

export type MinimumAmountRequirementType = {
  greaterThanOrEqualToSubtotal: {
    amount: string
    currencyCode: string
  }
}

export type MinimumRequirementType = MinimumQuantityRequirementType | MinimumAmountRequirementType

export type PercentageDiscountValueType = { percentage: number }

export type FixedAmountDiscountValueType = {
  amount: { amount: string; currencyCode: string }
  appliesOnEachItem: boolean
}

type DiscountGenericType<T extends FixedAmountDiscountValueType | PercentageDiscountValueType> = {
  combinesWith: { orderDiscounts: boolean; productDiscounts: boolean; shippingDiscounts: boolean }
  customerGets: {
    appliesOnOneTimePurchase: boolean
    appliesOnSubscription: boolean
    value: T
  }

  discountClass: 'PRODUCT'
  endsAt?: Date
  startsAt: Date
  minimumRequirement: MinimumRequirementType
  id: string
  codes?: string
  shortSummary: string
  summary: string
  title: string
}

export type FixedAmountDiscountType = DiscountGenericType<FixedAmountDiscountValueType>

export type PercentageDiscountType = DiscountGenericType<PercentageDiscountValueType>

export type DiscountType = PercentageDiscountType | FixedAmountDiscountType

export type ProductDocumentType = {
  description: string
  Warranty: string
  url: string
}

export type DistributorDataType = {
  distributor: HardwareSupplierFilterKeyType
  variant_id: string
  price: number
  locations: string[]
  quantity_per_item: number
  is_available: boolean
  stock_levels: StockLevelType[] | undefined
  product_documents: ProductDocumentType[]
  volume_discounts: DiscountType[]
  refresh_date: string
}

export type LineItemLoadedDataType = {
  code: string
  component_type: ComponentTypes
  description: string
  short_description: string
  distributors: DistributorDataType[]
  data: string
  id: number
  image_url?: string
  logo_image_url?: string
  manufacturer_name: string
  title: string
  base_sku?: string
}

export type InventoryForecastData = {
  allocatedQuantity: number
  requiredQuantity: number
  validity: ForecastStatus
}

export type SystemDesignType = {
  id: number
  name: string
  batteries: BasicComponentLineItemType[]
  inverters: BasicComponentLineItemType[]
  modules: BasicComponentLineItemType[]
  others: BasicComponentLineItemType[]
}

export type OrderType = {
  type: string
  reference: string
  url: string
}

export type SystemDesignLineItemCategoryType = keyof Omit<SystemDesignType, 'id' | 'name'>

export type HardwareOrderProjectRecord = {
  id: number
  org_id: number
  system_sold_id?: number
  contacts_data: ContactType[]
  business_name: string
  installation_date?: string
  address: string
  systems?: SystemDesignType[]
  orders?: OrderType[]
  last_calculation_error?: ProjectErrorType
}
