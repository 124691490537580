import { Dialog, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import useFlow from 'elements/flow/useFlow'
import { IconButton } from 'opensolar-ui'
import { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import {
  INVERTER_MANUAL_SELECTOR_NODE_KEY,
  INVERTER_SELECTOR_MODE_NODE_KEY,
  INVERTER_SET_AUTO_SELECTOR_NODE_KEY,
  INVERTER_WIZARD_FLOW_CONFIG,
} from './constants'
import type { InverterDesignAssistantFlowNodeConfigType } from './types'

type StyleProps = {
  isSelectorView: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  dialogPaper: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: props.isSelectorView ? '0px' : '32px',
    padding: props.isSelectorView ? '0px' : '40px',
  }),
  dialogTitleContainer: (props: StyleProps) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: props.isSelectorView ? '0px 20px' : '0px',
  }),
  dialogTitle: (props: StyleProps) => ({
    color: '#212121',
    fontSize: props.isSelectorView ? '20px' : '24px',
    fontWeight: props.isSelectorView ? 600 : 500,
    lineHeight: 'normal',
  }),
  divider: {
    background: '#D9DBE9',
    height: '1px',
    minHeight: '1px',
    width: '100%',
  },
}))

interface InverterDesignAssistantDialogProps {
  isOpen: boolean
  onClose: () => void
  targetUuids?: string[]
}

const initialNodeConfig: InverterDesignAssistantFlowNodeConfigType = {
  title: 'Add Inverter',
  currentNodeKey: INVERTER_SELECTOR_MODE_NODE_KEY,
  options: {},
}

export const InverterDesignAssistantDialog: React.FC<InverterDesignAssistantDialogProps> = ({
  isOpen,
  onClose,
  targetUuids,
}) => {
  const initialStore = useMemo(
    () => ({
      targetUuids: targetUuids,
      selection: undefined,
      type: undefined,
      onFinish: onClose,
    }),
    [onClose, targetUuids]
  )

  const { currentNodeConfig, flow, flowController, flowStore } = useFlow({
    initialNodeConfig,
    flowConfigs: INVERTER_WIZARD_FLOW_CONFIG,
    initialStore,
  })

  const handleClose = () => {
    onClose()
    flowController.goTo(initialNodeConfig)
    flowController.setFlowStore(() => initialStore)
  }

  const isSelectorView =
    currentNodeConfig.currentNodeKey === INVERTER_MANUAL_SELECTOR_NODE_KEY ||
    currentNodeConfig.currentNodeKey === INVERTER_SET_AUTO_SELECTOR_NODE_KEY

  const classes = useStyles({ isSelectorView })

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth={isSelectorView ? 'xl' : 'lg'}
      open={isOpen}
      onBackdropClick={handleClose}
    >
      <DialogTitle classes={{ root: classes.dialogTitleContainer }} disableTypography>
        <span className={classes.dialogTitle}>{currentNodeConfig.title ?? 'Add Inverter'}</span>
        <IconButton id="SelectComponentDialogClose" onClick={handleClose}>
          <CloseIcon style={{ color: '#000000' }} />
        </IconButton>
      </DialogTitle>
      <div className={classes.divider} />
      {flow}
    </Dialog>
  )
}

export default InverterDesignAssistantDialog
