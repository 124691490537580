import { SPLIT_NAMES } from 'ducks/split'
import { RoofHookCalculationMethodValue } from 'types/mounting'
import { possibleInputVariables } from './constants'

export interface MountingSystem {
  calculate(): Promise<MountingCalcResult>
  getFastener?(): Fastener // This should be passed for rail systems where structural calculations can be used to determine the number of roof hooks
  getCompatibilityParameters?(): CompatibilityParameters
}

export type LayoutAdjusterClass = new (input: MountingCalcInput) => LayoutAdjuster
export interface LayoutAdjuster {
  adjustBlock(block: PanelBlockWithUnpositionedPanels): PanelBlock[]
}

export type BomGeneratorClass = new (input: MountingCalcInput, block: PanelBlock, blockIndex: number) => BomGenerator
export interface BomGenerator {
  generateBom(result: MountingCalcResult): Promise<MountingCalcResult>
}

export type PostProcessorClass = new (input: MountingCalcInput) => PostProcessor
export interface PostProcessor {
  process(result: MountingCalcResult): MountingCalcResult
}

export interface MountingCalcInput {
  panelBlocks: PanelBlockPrecursor[] // Allow multiples, but don't necessarily handle them together
  roof: RoofInfo
  options: MountingInputsIntegrationData
}

interface fastenerResult {
  [name: string]: {
    qty: number
    diameter: number
    length: number
  }
}

export interface MountingCalcResult {
  items: Item[]
  panelBlocks: PanelBlock[]
  fasteners: {
    [blockIndex: number]: fastenerResult
  }
  roofIndex: number
  // rail cutting details
  // comments/annotations
}
export interface PanelBlockPrecursor extends HasPosition {
  moduleGrid: ModuleGrid
  panel: ModuleType
}

export interface ModuleGrid {
  uuid: string
  cellsActive: string[] // eg. '0,0' where the first coordinate is left to right, starting at 0 and decreasing in value, and the second coordinate is top to bottom, starting at 0 and increasing in value
  // Assume the module grid has position 0,0 since the modules have positions relative to it.
  moduleLayout: () => Orientation
}

export interface ModuleType {
  height: number // m
  width: number // m
  thickness?: number // mm
  splitJunctionBox?: boolean // required for in-roof systems like GSE, but not currently passed through
  id: string
}

export interface PanelBlock {
  uuid: string
  panel: Panel // an example panel so we can quickly access dimensions, etc
  orientation: Orientation
  rows: PanelRow[] // Rows must all have the same length, even if some of them are blank at the beginning or end
  panelSpacing: [number, number] // x, y in m
}

export interface PanelBlockWithUnpositionedPanels extends HasPosition {
  uuid: string
  panel: Panel // an example panel so we can quickly access dimensions, etc
  orientation: Orientation
  rows: UnpositionedPanelRow[] // Rows must all have the same length, even if some of them are blank at the beginning or end
}

// PanelRow brings two unenforceable guarantees:
// Each panel has the same top value
// The panels' left values are strictly increasing
export type PanelRow = PanelWithPosition[]
export type UnpositionedPanelRow = Panel[]
export type PanelColumn = PanelRow // Columns are the same, but the left value is fixed and the top values strictly increase

export interface PanelWithPosition extends Panel, HasPosition {
  orientation: Orientation
}

export interface Panel extends HasDimensions {
  // Note that dimensions are adjusted for panel orientation so that height is vertical and width is horizontal
  id: string // unique id within design
  isActive: boolean // indicates if a panel is present in this panel slot
  thickness?: number // mm
  splitJunctionBox?: boolean
}

export type Orientation = 'portrait' | 'landscape'
export type Direction = 'horizontal' | 'vertical'

//added null types here as this was already returned undefined when roof type not selected
export interface RoofInfo {
  roofIndex: number // as relates to custom_data._mcs
  roofTypeName: RoofTypeName | null
}

export type RoofTypeName =
  | 'Composition / Asphalt Shingle'
  | 'Flat Concrete'
  | 'Flat Foam'
  | 'Kliplock'
  | 'Membrane EPDM'
  | 'Membrane PVC'
  | 'Membrane TPO'
  | 'Metal Decramastic'
  | 'Metal Shingle'
  | 'Metal Standing Seam'
  | 'Metal Stone Coated'
  | 'Metal Tin'
  | 'Tar and Gravel / Bitumen'
  | 'Thatched'
  | 'Tile Clay'
  | 'Tile Concrete'
  | 'Tile Slate'
  | 'Trapezoidal'
  | 'Wood/Shake Shingle'
  | 'Other'

export interface StructuralOptions {
  structuralRegs?: 'mcs'
  moduleGroups?: Record<number, ModuleGroupStructuralOptions>
}

export type RailType = 'continuous' | 'mini'

export type PossibleInputVariables = typeof possibleInputVariables[number]
type MountingInputsIntegrationDataBase = Partial<{ [key in PossibleInputVariables]: unknown }>
export interface MountingInputsIntegrationData extends MountingInputsIntegrationDataBase {
  bladeWidth?: number
  lateralConnection?: 'flashing'
  topConnection?: 'flashing' | 'flexalu'
  eavesConnection?: 'flexalu'
  flexaluColor?: 'black' | 'red'
  flexaluWidth?: 330 | 500
  clampsPerModule?: 4 | 6
  roofingUnderlay?: boolean
  mountingSpacing?: number
  structuralOptions?: StructuralOptions
  railDirection?: 'horizontal' | 'vertical'
  railDirectionPortraitPanels?: 'horizontal' | 'vertical'
  railDirectionLandscapePanels?: 'horizontal' | 'vertical'
  railLength?: number
  railColour?: string
  clampColour?: 'silver' | 'black'
  endCapColour?: string
  roofHook?: string
  geniusKit?: string
  mountingRail?: string
  railSplice?: string
  midClamp?: string
  endClamp?: string
  horizontalEndClamp?: string
  verticalEndClamp?: string
  fastener?: string
  endCap?: string
  railType?: RailType
  profile?: string
  panelThicknessRange?: string
  terrainCategory?: string
  windSpeed?: number
  system?: 'FLATFIX_FUSION' | 'CLICKFIT_EVO'
  tpoMats?: boolean
  rowDistance?: string
  roofHeight?: number
  blackArticles?: boolean
  roofRibDistance?: number
  includeRoofAdapter?: string
  seamType?: 'SINGLE_FOLDED' | 'DOUBLE_FOLDED' | 'ROUNDED' | 'SNAP'
  flangeDistance?: number
  batten_thickness?: number
  arc_box?: boolean
  roofHookCalculationMethod?: RoofHookCalculationMethodValue
  panelConfiguration?: 'SINGLE' | 'DUAL' // Used to distinguish between single rows or east/west configurations (which we don't support yet)
}

interface ModuleGroupStructuralOptions {
  minFastenerCount: number
}

// Items comprise the BOM, and are the primary output
// Their components are things we may want to display on the roof
// 0 components: Nothing to display on the roof, but some extra item we need to purchase
// 1 component: E.g. a clamp
// >1 components: E.g. a rail which is cut into different pieces
export interface Item {
  name: string
  components: Component[] // For displaying. Can be >1, eg. rails that are cut up, or bundles of things
}

export interface Component extends HasPosition {
  type: MountingComponentType
  name: string
  blockIndex?: number
}

export interface RailComponent extends Component {
  railType: RailType
  direction: Direction
  length: number // mm
}

export interface Fastener extends Item {
  includedInRoofHookProduct: Boolean // if true, it won't be added to the BOM
  qtyPerRoofHook: number
  length: number //mm
  diameter: number //mm
}

// For generalising structural calculations, which care about roof hooks
export enum MountingComponentType {
  RAIL,
  CLAMP,
  ROOF_HOOK,
  SPLICE,
  OTHER,
}

export interface HasDimensions {
  width: number // mm
  height: number // mm
}

export interface HasPosition {
  top: number // mm, top left corner of panel, down from top-left roof corner (origin?)
  left: number // mm, top left corner of panel, left from top-left roof corner (origin?)
}

type integration = 'Segen' | 'City Plumbing' | 'HDM'

export type CompatibilityParameters = {
  integrations?: integration[]
  slopeRange?: [number, number] //numbers are inclusive
  moduleThicknessRange?: [number, number]
  moduleWidthRanges?: [number, number][]
  moduleHeightRange?: [number, number]
  roofHeightRange?: [number, number]
  roofTypes?: RoofTypeName[]
  featureFlag?: SPLIT_NAMES
  roofTypeRequired?: boolean
  panelOrientation?: 'landscape' | 'portrait'
  moduleHasSpecs?: string[]
}

export type FastenerData = {
  length: number
  diameter: number
  qty?: number
}

//in future maybe we could change this type to a string literal that returns keyof the products for that system
export type ProductCode = string | number

export type QuantityPerProduct = Record<string, Array<ProductCode>>
