import { Link } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import { Button } from 'opensolar-ui'

import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import CalcError from './CalcError'
import EntityError from './EntityError'
import SharingError from './SharingError'
import { usePerformErrorAction } from './usePerformErrorAction'

const useStyles = makeOpenSolarStyles((theme) => ({
  button: {
    fontSize: 14,
    margin: 5,
  },
  itemList: {
    fontSize: 12,
  },
  expandLink: {
    textAlign: 'right',
    width: '100%',
    display: 'inline-block',
    color: theme.ValidityPalette.error.color + '!important',
    fontSize: 12,
  },
}))

export type AlertBoxListPropsTypes = {
  sharingSection?: string
  errors: ProjectErrorType[]
  maxShown?: number
}

export const AlertBoxList: React.FC<AlertBoxListPropsTypes> = ({ sharingSection, errors, maxShown }) => {
  const translate = useTranslate()
  const [showAll, setShowAll] = useState(false)
  const classes = useStyles()

  const errorsToShow = useMemo(() => {
    return !showAll && maxShown !== undefined ? errors.slice(0, maxShown) : errors
  }, [maxShown, errors, showAll])

  return (
    <>
      {errorsToShow?.map((error: ProjectErrorType) => {
        if (error.severity === 'basic') {
          return <BasicMessage key={error.key} error={error} sharingSection={sharingSection} />
        } else {
          return (
            <Alert key={error.key} severity={error.severity}>
              <BasicMessage key={error.key} error={error} sharingSection={sharingSection} />
            </Alert>
          )
        }
      })}
      {maxShown !== undefined && (errors.length || 0) > maxShown && (
        <Link color="error" onClick={() => setShowAll(!showAll)} className={classes.expandLink}>
          {translate(showAll ? 'Show less...' : 'Show more...')}
        </Link>
      )}
    </>
  )
}

export type AlertBoxPropsTypes = {
  error: ProjectErrorType
  sharingSection?: string
}

const BasicMessage: React.FC<AlertBoxPropsTypes> = ({ error, sharingSection }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()

  const userOrgId = useSelector(authSelectors.getOrgId)
  const isProjectOwner = userOrgId === form.getState().values.org_id
  const connectedOrgs = useSelector(orgSelectors.getOrg)?.connected_orgs || []
  const sharedWith = form.getState().values.shared_with
  const [connectedOrg, setConnectedOrg] = useState<string | undefined>()

  useEffect(() => {
    if (isProjectOwner) {
      setConnectedOrg(sharedWith?.map((x) => x.org_name).join(', '))
    } else {
      setConnectedOrg(connectedOrgs.find((x) => x.org_id === form.getState().values.org_id)?.org_name)
    }
  }, [isProjectOwner ? sharedWith : connectedOrgs])
  const renderErrorMessage = useCallback(
    (error: ProjectErrorType) => {
      switch (error.messageType) {
        case 'calcError':
          return <CalcError error={error} />
        case 'sharingError':
          return <SharingError error={error} sharingSection={sharingSection} connectedOrg={connectedOrg} />
        case 'entityError':
          return <EntityError error={error.message} />
        default:
          return <div>{translate(error.message, error.messageParams)}</div>
      }
    },
    [connectedOrg]
  )

  const performAction = usePerformErrorAction()

  return (
    <>
      {renderErrorMessage(error)}
      {error.options?.actions?.map((action) => {
        return (
          <Button
            classes={{ root: classes.button }}
            key={action.label}
            variant="contained"
            onClick={() => performAction(action)}
          >
            <span>{translate(action.label)}</span>
          </Button>
        )
      })}
    </>
  )
}
