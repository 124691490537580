import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined'
import { Bag05OutlineIcon, Button } from 'opensolar-ui'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { logAmplitudeEvent } from '../../amplitude/amplitude'
import { makeOpenSolarStyles } from '../../themes/makeOpenSolarStyles'
import { useFeatureFlag } from '../../util/split'
import { SelectedMenu } from './constant'
import SideBarNavigationSubMenu from './SideBarNavigationSubMenu'
import { MenuConfigType } from './type'

const SIDEBAR_MENU_CONFIG: MenuConfigType[] = [
  {
    type: SelectedMenu.BUY_HARDWARE,
    buttonLabel: 'Shop',
    amplitudeEventSource: 'hardware_sidebar_navigation_buy_hardware_button',
    redirectUrl: '/shop',
    routeRule: '^/shop/search(/.*)?$',
    icon: <LocalShippingOutlinedIcon />,
    subMenu: [
      { title: 'Panels', url: '/shop/search/modules', amplitudeEventSource: 'hardware_sidebar_submenu_panels_button' },
      {
        title: 'Inverters',
        url: '/shop/search/inverters',
        amplitudeEventSource: 'hardware_sidebar_submenu_inverters_button',
      },
      {
        title: 'Batteries',
        url: '/shop/search/batteries',
        amplitudeEventSource: 'hardware_sidebar_submenu_batteries_button',
      },
      {
        title: 'Mounting + BOS',
        url: '/shop/search/others',
        amplitudeEventSource: 'hardware_sidebar_submenu_others_button',
      },
    ],
    subMenuHeading: 'Shop',
  },
  {
    type: SelectedMenu.ORDER_BY_PROJECT,
    buttonLabel: 'Order By Project',
    amplitudeEventSource: 'hardware_sidebar_navigation_order_by_project_button',
    redirectUrl: '/shop/order_by_project',
    routeRule: '^/shop/order_by_project(/.*)?$',
    icon: <FormatListBulletedIcon />,
    subMenu: [],
    subMenuHeading: 'Order By Project',
  },
  {
    type: SelectedMenu.MANAGE_ORDERS,
    buttonLabel: 'Manage Orders',
    amplitudeEventSource: 'hardware_sidebar_navigation_manage_orders_button',
    redirectUrl: '/manage_orders/incoming_transfer',
    routeRule: '^/manage_orders(/.*)?$',
    icon: <Bag05OutlineIcon width="19.5" height="19.5" fill="none" />,
    subMenu: [{ title: 'Incoming POs', url: '/manage_orders/incoming_transfer' }],
    subMenuHeading: 'Manage Orders',
  },
  {
    type: SelectedMenu.INVENTORY_MANAGEMENT,
    buttonLabel: 'Inventory Management',
    amplitudeEventSource: 'hardware_sidebar_navigation_inventory_management_button',
    redirectUrl: '/inventory/dashboard',
    routeRule: '^/inventory(/.*)?$',
    icon: <AssignmentTurnedInOutlinedIcon />,
    subMenu: [
      { title: 'Dashboard', url: '/inventory/dashboard' },
      { title: 'Inventory', url: '/inventory/location' },
      { title: 'Outgoing Orders', url: '/inventory/outgoing_transfer' },
      { title: 'Activity', url: '/inventory/activity' },
      { title: 'Insights', url: '/inventory/insights' },
      { title: 'Settings ', url: '/inventory/configuration' },
    ],
    subMenuHeading: 'Manage Inventory',
  },
]

const getSelectedMenuByRoute = (pathName: string) => {
  return SIDEBAR_MENU_CONFIG.find((menu) => new RegExp(menu.routeRule).test(pathName))
}

const SideBarNavigationMenus = () => {
  const useStyles = makeOpenSolarStyles((theme) => ({
    root: {
      maxHeight: '100vh',
      scrollbarColor: 'transparent transparent',
      scrollbarWidth: 'thin',
      overflow: 'hidden',
      boxSizing: 'border-box',
      '&:hover': {
        overflow: 'overlay',
        maxHeight: '100vh',
        scrollbarColor: 'grey transparent',
      },
    },
    menuRoot: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      padding: '26px 12px',
      marginBottom: '12px',
      background: '#F6F6F6',
    },
    subMenuContainer: {
      padding: '0 12px',
    },
    button: {
      border: '1px solid #7F7D83',
      borderRadius: '6px',
      fontSize: '15px',
      width: '100%',
      justifyContent: 'flex-start',
      padding: '0 10px',
      color: '#0A090B',
      '&:hover': {
        border: 'none',
        background: '#4272DD',
        color: '#FFFFFF',
      },
    },
    buttonActive: {
      border: 'none',
      background: '#4272DD',
      color: '#FFFFFF',
    },
  }))
  const history = useHistory()

  const classes = useStyles()
  const location = useLocation()
  const pathname = location.pathname

  const enableInventoryManagement = useFeatureFlag('inventory_management', 'on')

  const menu = getSelectedMenuByRoute(pathname)
  const [selectedMenu, setSelected] = useState<MenuConfigType>(menu || SIDEBAR_MENU_CONFIG[0])

  const onChange = (menu: MenuConfigType) => {
    history.push(menu.redirectUrl)
    setSelected(menu)

    logAmplitudeEvent('hardware_sidebar_navigation_button_clicked', {
      source: menu.amplitudeEventSource,
    })
  }

  useEffect(() => {
    setSelected(selectedMenu)
  }, [selectedMenu])

  useEffect(() => {
    const menu = getSelectedMenuByRoute(location.pathname)
    if (menu) {
      setSelected(menu)
    }
  }, [location.pathname])

  return (
    <div className={classes.root}>
      <div className={classes.menuRoot}>
        {SIDEBAR_MENU_CONFIG.map((menu) => {
          if (menu.type === SelectedMenu.INVENTORY_MANAGEMENT && !enableInventoryManagement) {
            return null
          }
          return (
            <>
              <Button
                className={`${classes.button} ${selectedMenu?.type === menu.type ? classes.buttonActive : ''}`}
                fullWidth
                onClick={() => onChange(menu)}
                startIcon={menu.icon}
              >
                {menu.buttonLabel}
              </Button>
            </>
          )
        })}
      </div>
      <div className={classes.subMenuContainer}>
        <SideBarNavigationSubMenu
          title={selectedMenu.buttonLabel}
          subMenus={selectedMenu.subMenu}
          activeMenu={selectedMenu.type}
        />
      </div>
    </div>
  )
}

export default SideBarNavigationMenus
