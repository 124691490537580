import { orgSelectors } from 'ducks/orgs'
import { DISTRIBUTOR_FILTER_KEY_V2 } from 'elements/hardwareFilter/distributor/DistributorFilterConfigV2Factory'
import type { FilterValuesType } from 'elements/hardwareFilter/type'
import Pagination from 'elements/pagination/Pagination'
import { getSupplierConfig } from 'pages/ordering/configs'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { ComponentTypesV2 } from 'types/selectComponent'
import HardwareSelectorFilterSidebar from './elements/HardwareSelectorFilterSidebar'
import HardwareSelectorHeader from './elements/HardwareSelectorHeader'
import HardwareSelectorLayout from './elements/HardwareSelectorLayout'
import HardwareSelectorList from './elements/HardwareSelectorList'
import HardwareSelectorToolBars, { getPlaceholderByComponentType } from './elements/HardwareSelectorToolBars'
import useHardwareSelectorFetchEngine from './fetch/useHardwareSelectorFetchEngine'
import useHandleSelect from './useHandleSelect'

export type HardwareSelectorDialogConfig = {
  componentTypes: ComponentTypesV2[]
  defaultFilterValues?: FilterValuesType
  targetUuids?: string[]
  slotKey?: string
  title?: string
}

export const HardwareSelectorCore = ({
  layoutOptions: { footer, isHeaderHidden } = {
    footer: null,
    isHeaderHidden: false,
  },
  onClose,
  config,
}: {
  layoutOptions?: {
    footer?: React.ReactNode
    isHeaderHidden?: boolean
  }
  onClose(): void
  config: HardwareSelectorDialogConfig
}) => {
  const selectedDistributor = useSelector(orgSelectors.getSelectedHardwareSupplier)

  const defaultFilterValues = useMemo(() => {
    const autoApplyDistributorFilter = appStorage.getBool('auto_apply_distributor_filter')
    return {
      [DISTRIBUTOR_FILTER_KEY_V2]: autoApplyDistributorFilter ? getSupplierConfig(selectedDistributor)?.filterKey : '', // default to selected distributor
      ...config.defaultFilterValues,
    }
  }, [])

  const handleSelect = useHandleSelect({ onClose, config })
  const {
    componentsData,
    setFilters,
    total = 0,
    loading,
    page,
    perPage,
    setPage,
    setPerPage,
    persistentFilterValues,
    modifiableFilterValues,
    allFilterValues,
  } = useHardwareSelectorFetchEngine({
    componentTypes: config.componentTypes,
    limit: 20,
    defaultFilterValues,
  })

  const handleUpdate = useCallback(
    (newFilterValues: { [key: string]: unknown }) => {
      setFilters({ ...modifiableFilterValues, ...newFilterValues })
    },
    [modifiableFilterValues]
  )

  const handleSearchTextUpdate = useCallback(
    (searchText) => {
      handleUpdate({ search: searchText })
    },
    [handleUpdate]
  )

  const hiddenColumns = new Set<string>()
  const shouldShowRating = config.componentTypes.some((type) => ['module', 'inverters', 'battery'].includes(type))
  if (!shouldShowRating) {
    hiddenColumns.add('rating')
  }

  return (
    <HardwareSelectorLayout
      footer={footer}
      heading={
        !isHeaderHidden ? (
          <HardwareSelectorHeader title={config.title || 'Select Hardware'} handleClose={onClose} />
        ) : null
      }
      toolBars={
        <HardwareSelectorToolBars
          componentType={config.componentTypes[0]}
          placeholder={getPlaceholderByComponentType(config.componentTypes[0])}
          onChange={handleSearchTextUpdate}
        />
      }
      listContent={
        <HardwareSelectorList
          total={total}
          loading={loading}
          data={componentsData}
          onSelect={handleSelect}
          Pagination={
            <Pagination total={total} page={page} perPage={perPage} setPage={setPage} setPerPage={setPerPage} />
          }
          hiddenColumns={hiddenColumns}
        />
      }
      sidebar={
        <HardwareSelectorFilterSidebar
          persistentFilterValues={persistentFilterValues}
          modifiableFilterValues={modifiableFilterValues}
          allFilterValues={allFilterValues}
          setModifiableFilterValues={setFilters}
        />
      }
    />
  )
}

export default HardwareSelectorCore
