import { Dialog, DialogTitle, Tooltip } from '@material-ui/core'
import { Add, ClearOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'

import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import isEqual from 'lodash/isEqual'
import { IconButton } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { PaymentMethodEnum } from 'types/paymentOptions'
import ConfigureCustomInvoice from './ConfigureCustomInvoice'
import PreviewCustomInvoice from './PreviewCustomInvoice'
import ReviewAndSendCustomInvoice from './ReviewAndSendCustomInvoice'
import { DEFAULT_LINE_ITEM } from './constants'
import { InvoiceEmail, LineItem, SimpleInvoiceType } from './types'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeOpenSolarStyles((theme) => ({
  closeButtonWrapper: {
    position: 'absolute',
    top: '0px',
    right: '10px',
  },
}))

export type StandalonePaymentRequestSettings = {
  title: string
  description: string | undefined
  accepted_payment_methods: PaymentMethodEnum[]
  surcharging_enabled: boolean
}

interface PropTypes {
  paymentOptionId: number
  systemUuid: string
}

const CustomInvoiceButton: React.FC<PropTypes> = ({ paymentOptionId, systemUuid }) => {
  const classes = useStyles()
  const [lineItems, setLineItems] = useState<LineItem[]>([DEFAULT_LINE_ITEM])
  const [paymentRequestSettings, setPaymentRequestSettings] = useState<StandalonePaymentRequestSettings>()
  const [isOpen, setIsOpen] = useState(false)
  const [stage, setStage] = useState<'configure' | 'preview' | 'email'>('configure')
  const [invoice, setInvoice] = useState<SimpleInvoiceType | undefined>(undefined)
  const org = useSelector(orgSelectors.getOrg)
  const notify = useNotify()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const project = useFormState().values
  const translate = useTranslate()
  const dispatch = useDispatch()

  const handleOpenDialog = () => {
    setIsOpen(true)
    logAmplitudeEvent('cashflow_standalone_invoice_dialog_opened', {
      project_id: project?.id,
      org_id: project?.org_id,
    })
  }

  useEffect(() => {
    if (!isOpen) {
      resetData()
    }
  }, [isOpen])

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const resetData = () => {
    // RK: this is a hack to make sure everything is cleared out when the dialog is closed.
    // This CustomInvoiceButton component will otherwise maintain its state, and that can get weird if the dialog is re-opened
    // after previously sending a standalone invoice.
    setLineItems([DEFAULT_LINE_ITEM])
    setInvoice(undefined)
    setStage('configure')
  }

  const nextPage = () => {
    if (stage === 'configure') {
      setStage('preview')
    } else if (stage === 'preview') {
      setStage('email')
    }
  }

  const previousPage = () => {
    if (stage === 'preview') {
      setStage('configure')
    } else if (stage === 'email') {
      setStage('preview')
    }
  }

  const sendInvoice = async (emailConfig: InvoiceEmail) => {
    setIsLoading(true)
    restClientInstance('CUSTOM_PATCH', 'custom', {
      url: `orgs/${org?.id}/invoices/${invoice?.id}/finalize_standalone/`,
      data: { ...emailConfig, ...paymentRequestSettings },
    })
      .then(() => {
        setIsOpen(false)
        notify('Email sent', 'success')
        dispatch(triggerMilestoneRefresh())
        logAmplitudeEvent('cashflow_standalone_invoice_dialog_sent', {
          project_id: project?.id,
          org_id: project?.org_id,
        })
      })
      .catch((err) => {
        if (err?.body?.message) notify(err.body.message, 'warning')
        else notify('Something went wrong and we are unable to send this invoice', 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const makeInvoiceDraft = (invoiceLineItems: LineItem[], paymentRequestFields: StandalonePaymentRequestSettings) => {
    // Skip straight to the PDF preview if the underlying invoice data hasn't changed
    if (invoice?.id && lineItems && isEqual(lineItems, invoiceLineItems)) {
      nextPage()
      return
    }

    setIsLoading(true)
    setLineItems(invoiceLineItems)
    setPaymentRequestSettings(paymentRequestFields)

    // If we've already made an invoice draft, delete the existing one first
    if (invoice?.id) {
      restClientInstance('CUSTOM_DELETE', 'custom', {
        url: `orgs/${org?.id}/invoices/${invoice.id}/`,
      })
    }
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${org?.id}/invoices/create_standalone_invoice/?project_id=${project?.id}`,
      data: {
        line_items: invoiceLineItems,
      },
    })
      .then((res) => {
        setInvoice({
          id: res.data.invoice_id,
          download_url: res.data.download_url,
          invoice_number: res.data.invoice_number,
        })
        nextPage()
      })
      .catch(() => {})
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpenDialog} size="small">
        <Add />
        {translate('Custom Invoice')}
      </Button>
      <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="lg">
        <DialogTitle>
          <div>Custom Invoice</div>
          <div className={classes.closeButtonWrapper}>
            <IconButton
              id="GenerateDialogClose"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0)',
              }}
              onClick={() => setIsOpen(false)}
            >
              <Tooltip title={translate('Close Dialog')} enterDelay={300}>
                <ClearOutlined style={{ color: '#000000' }} />
              </Tooltip>
            </IconButton>
          </div>
        </DialogTitle>
        {stage === 'configure' && (
          <ConfigureCustomInvoice
            makeInvoiceDraft={makeInvoiceDraft}
            lineItems={lineItems}
            isLoading={isLoading}
            paymentRequestSettings={paymentRequestSettings}
          />
        )}
        {stage === 'preview' && invoice && (
          <PreviewCustomInvoice nextPage={nextPage} previousPage={previousPage} invoice={invoice} />
        )}
        {stage === 'email' && (
          <ReviewAndSendCustomInvoice
            sendInvoice={sendInvoice}
            previousPage={previousPage}
            invoice={invoice}
            isLoading={isLoading}
          />
        )}
      </Dialog>
    </>
  )
}

export default CustomInvoiceButton
