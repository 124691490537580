import { Grid, makeStyles } from '@material-ui/core'
import { SaveOutlined } from '@material-ui/icons'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import CashFlowSetupChecklist from 'pages/cashFlow/sharedComponents/CashFlowSetupChecklist'
import { useNotify, useTranslate } from 'ra-core'

import React, { useState } from 'react'

import { Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import EmailTemplate from './EmailTemplate'
import InvoiceSettings from './InvoiceSettings'
import { saveEmailConfiguration, saveInvoiceSettings, useGetEmailConfiguration, useGetInvoiceSettings } from './utils'

type PropTypes = {
  isLoading: boolean
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  containerWrapper: {
    overflow: 'hidden',
  },

  container: {
    background: theme.white,
    padding: '24px',
    border: '1px solid #e7e7e7',
    borderRadius: '5px',
    margin: '1rem 0',
  },

  header: {
    fontSize: 18,
    fontWeight: 400,
  },

  justifyCenter: {
    justifyContent: 'center',
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 30px',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 500,
    boxShadow: `0px -2px 2px ${theme.greyLight1}`,
  },
}))

const CustomerCommunicationsPage: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const formValues = useFormState().values
  const form = useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSave = () => {
    if (orgId) {
      let daysUntilDueInt
      try {
        daysUntilDueInt = parseInt(formValues.days_until_due)
      } catch (ex) {
        notify(translate('Days until due must be a number'), 'warning')
        return
      }

      if (!daysUntilDueInt || daysUntilDueInt < 0) {
        notify('Days until due must be a positive number', 'warning')
        return
      }

      if (!checkReminderDaysIsValid()) {
        notify(
          'Reminder emails cannot be scheduled to go out after the invoice due date. Move the invoice due date or reschedule the reminder emails to save.',
          'warning'
        )
        return
      }

      form.change('email_templates.invoice_reminder.send_after_days', sanitizeReminders('invoice_reminder'))
      form.change('email_templates.invoice_overdue.send_after_days', sanitizeReminders('invoice_overdue'))

      setIsLoading(true)
      saveEmailConfiguration(orgId, { email_templates: formValues.email_templates })
        .then(() => {
          saveInvoiceSettings(orgId, { days_until_due: daysUntilDueInt })
            .then(() => {
              notify(translate('Your updates have been saved'), 'success')
            })
            .catch((err) => {
              if (err?.body?.message) notify(err.body.message, 'warning')
              else if (err?.body?.non_field_errors?.length) notify(err.body.non_field_errors[0], 'warning')
              else notify(translate('Something went wrong'), 'error')
            })
            .finally(() => {
              setIsLoading(false)
            })
        })
        .catch((err) => {
          if (err?.body?.message) notify(err.body.message, 'warning')
          else if (err?.body?.non_field_errors?.length) notify(err.body.non_field_errors[0], 'warning')
          else notify(translate('Something went wrong'), 'error')
        })
    }
  }

  const checkReminderDaysIsValid = () => {
    const reminderDays = formValues['email_templates'].invoice_reminder.send_after_days
    return reminderDays.filter((days) => parseInt(days) >= parseInt(formValues.days_until_due)).length === 0
  }

  const sanitizeReminders = (type) => {
    const reminders = formValues['email_templates'][type].send_after_days.filter((day) => !!day)
    return reminders
  }

  return (
    <div className={classes.containerWrapper}>
      {props.isLoading ? (
        <LoadingDots />
      ) : (
        <>
          <Grid container spacing={2} className={classes.justifyCenter}>
            <Grid item xs={11} lg={8}>
              <InvoiceSettings />

              <h1 className={classes.header}>Email Template Settings</h1>
              <EmailTemplate
                header="Send Invoice"
                description="Email notifying a customer that their invoice is ready for payment."
                label="issue_invoice"
              />

              <EmailTemplate header="Reminder" badge={true} label="invoice_reminder" />

              <EmailTemplate
                header="Invoice Overdue"
                description="Final email sent on the payment due date."
                label="invoice_overdue"
              />

              <EmailTemplate
                header="Overdue Reminder"
                description="All overdue payment reminder emails configured sent after the invoice due date automatically or via the project page"
                badge={true}
                label="invoice_overdue"
              />

              <EmailTemplate
                header="Payment Authorized"
                description="Recipient preferences set in this section apply to all payment authorization emails sent to customers after they authorize a payment, regardless of payment method used. Below is a sample email sent to the customer after they authorize a credit card payment. These templates are not editable for compliance reasons."
                badge={true}
                label="payment_authorized"
              />
            </Grid>
            <Grid item xs={11} lg={3}>
              <CashFlowSetupChecklist activeStep={3} />
            </Grid>
          </Grid>
          <div className={classes.buttonsContainer}>
            <Button disabled={isLoading} onClick={onSave} variant="contained" color="primary" type="submit">
              <SaveOutlined /> {translate('Save')}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

const CustomerCommunicationsFormWrapper: React.FC<PropTypes> = (props) => {
  const invoiceSettings = useGetInvoiceSettings()
  const emailTemplate = useGetEmailConfiguration()

  return (
    <Form
      initialValues={{
        ...((invoiceSettings && invoiceSettings.invoiceSettings) || {}),
        ...((emailTemplate && emailTemplate.emailConfiguration) || {}),
      }}
      onSubmit={() => {}}
      render={(formProps) => (
        <CustomerCommunicationsPage
          {...formProps}
          {...props}
          isLoading={(emailTemplate && emailTemplate.emailConfigLoading) || false}
        />
      )}
    />
  )
}
export default CustomerCommunicationsFormWrapper
