import { InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React, { useContext } from 'react'
import { useTranslate } from 'react-admin'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { FilesAndTransContext, FilesAndTransContextProps } from '../../FilesAndTransactionsSection'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  quickSearchWrapper: {
    height: 36,
    maxHeight: 36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    padding: 0,
    height: 34,
    fontWeight: 400,
    color: theme.greyDark2,
    fontSize: '14px',
    overflowY: 'scroll',
    borderWidth: 0,
    boxSizing: 'border-box',
    '&:focus': {
      border: 'none',
      borderWidth: 0,
      height: 34,
      maxHeight: 34,
    },
  },
  outlined: {
    border: '1px solid',
    height: 34,
    borderRadius: 4,
    paddingLeft: 5,
    boxSizing: 'border-box',
    borderColor: theme.greyLight2,
    '&:focus': {
      borderWidth: 0,
      maxHeight: 34,
    },
  },
  noBorder: {
    display: 'none',
  },
  innerSearchIcon: {
    height: 20,
    paddingLeft: 4,
  },
}))

type PropTypes = {}
export const FileSearchField: React.FC<PropTypes> = () => {
  const context = useContext(FilesAndTransContext) as FilesAndTransContextProps
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <div className={classes.quickSearchWrapper}>
      <TextField
        id="File-Search-Input"
        variant="outlined"
        autoFocus={true}
        value={context.searchTerm}
        InputProps={{
          classes: { input: classes.input, root: classes.outlined, notchedOutline: classes.noBorder },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.innerSearchIcon} />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          if (context.addSearchTerm) {
            context.addSearchTerm(e.target.value)
          }
        }}
        onBlur={() => {
          if (context.searchTerm === '') {
            context.selectCta('')
          }
        }}
        placeholder={translate('Search')}
      />
    </div>
  )
}
