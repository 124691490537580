import { FormControlLabel, MenuItem, Paper, TextField } from '@material-ui/core'
import { Checkbox } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { OpenSolarTheme } from 'Themes'
import { useFeatureFlag } from 'util/split'
import Panel from '../Designer/Panel'
import CustomSelectField from '../elements/field/CustomSelectField'
import {
  clearTypingInFieldOnUnmount,
  markFieldActive,
  markFieldInactive,
  refreshPanelGeneric,
  stripKeyForActiveTextField,
} from '../Studio/Utils'
import {
  feetToMeters,
  formatCurrency,
  getMeasurementsFromState,
  metersToFeet,
  metersToFeetSquared,
  trimDecimalPlaces,
} from '../util/misc'
import ModuleGridsList from './components/ModuleGridsList'

const FacetAreaWithFeatureFlag = ({ childrenIfEnabled, childrenIfDisabled }) => {
  /*
  This is enabled if either a) feature flag is enabled or b) design does not include multiple imagery perspectives
  */
  const hasMultipleImageryViews = window.Designer.hasMultipleImageryViews()
  const enableFacetArea = useFeatureFlag('studio_facet_area', 'on')
  return hasMultipleImageryViews === false || enableFacetArea ? childrenIfEnabled : childrenIfDisabled
}

class PanelFacet extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      azimuthOverride: 0,
      slopeOverride: 0,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.facet) {
      injectState = this.stateFromObject(props.facet)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //onBlur doesn't fire if whole component unmounts first
    //cleanup just in case
    if (prevState.visible === true && this.state.visible === false) {
      clearTypingInFieldOnUnmount.call(this)
    }
  }

  componentWillUnmount() {
    clearTypingInFieldOnUnmount.call(this)
  }

  stateFromObject(facet) {
    if (!facet) {
      return { visible: false }
    }
    var _state = {
      visible: true,
      facet: facet,
      azimuthOverride: facet.azimuthOverride ? facet.azimuthOverride : '',
      slopeOverride: facet.slopeOverride ? facet.slopeOverride : '',
      height: facet.position ? this.format(facet.position.z) : '',
    }

    stripKeyForActiveTextField.call(this, _state, facet)

    return _state
  }

  updateCheck() {
    this.setState((oldState) => {
      return {
        checked: !oldState.checked,
      }
    })
  }

  handleSelectObjectByUuid = (uuid) => {
    window.editor.selectByUuid(uuid)
  }

  handleDeleteObjectByUuid = (uuid) => {
    window.editor.deleteObjectByUuid(uuid)
  }

  handleSetAzimuthOverride = (value, uuid) => {
    var facet = window.editor.objectByUuid(uuid)
    var newValue = value !== '' ? value : null
    window.editor.execute(
      new window.SetValueCommand(
        facet,
        'azimuthOverride',
        newValue,
        window.Utils.generateCommandUUIDOrUseGlobal(),
        true
      )
    )
  }

  handleSetSlopeOverride = (value, uuid) => {
    var facet = window.editor.objectByUuid(uuid)
    var newValue = value !== '' ? value : null
    window.editor.execute(
      new window.SetValueCommand(facet, 'slopeOverride', newValue, window.Utils.generateCommandUUIDOrUseGlobal(), true)
    )
  }

  select = (object) => {
    window.editor.select(object)
  }

  isSelected = (object) => {
    return object && window.editor && window.editor.selected && window.editor.selected.uuid === object.uuid
  }

  logIfEmptyString = (val) => {
    if (val === '') {
      console.log(val)
    }
    return true
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsFacet')
  }

  format = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(metersToFeet(value), 4)
    }
    return value
  }

  parse = (value) => {
    const { measurements } = this.props
    if (measurements === 'imperial') {
      return trimDecimalPlaces(feetToMeters(value), 6)
    }
    return value
  }

  render() {
    if (!this.state.visible) {
      return null
    }

    var facet = this.state.facet
    var _this = this
    const { translate } = this.props
    const facetIsManaged = this.state.facet?.isManaged
    let panelGroups = Array.isArray(facet.objectsFloating)
      ? facet.objectsFloating
          .filter((obj) => obj.type === 'OsModuleGrid')
          .filter((mg) => mg.getSystem() === window.editor.selectedSystem)
      : []

    return (
      <Panel
        selectedObject={facet}
        showTools={this.state.allowEdit}
        title={facet.isNonSpatial() ? 'Roof Facet (Non-spatial)' : 'Roof Facet'}
        summary=""
        feature={
          <div>
            <div style={{ clear: 'both', marginTop: 20 }}>
              <div style={{ float: 'left', width: 60 }}>{translate('Azimuth')}</div>
              <div>{formatCurrency(facet.azimuth, 2)}</div>
              <div style={{ clear: 'both' }} />
            </div>

            <div style={{ clear: 'both', marginTop: 20 }}>
              <div style={{ float: 'left', width: 60 }}>{translate('Slope')}</div>
              <div>{formatCurrency(facet.slope, 2)}</div>
              <div style={{ clear: 'both' }} />
            </div>

            <FacetAreaWithFeatureFlag
              childrenIfEnabled={
                <div style={{ clear: 'both', marginTop: 20 }}>
                  <div style={{ float: 'left', width: 60 }}>{translate('Area')}</div>
                  {facet.area && this.props.measurements === 'imperial' && (
                    <div>
                      {formatCurrency(metersToFeetSquared(facet.area), 2)} ft<sup>2</sup>
                    </div>
                  )}
                  {facet.area && this.props.measurements !== 'imperial' && (
                    <div>
                      {formatCurrency(facet.area, 2)} m<sup>2</sup>
                    </div>
                  )}
                  {!facet.area && <div>-</div>}
                  <div style={{ clear: 'both' }} />
                </div>
              }
              childrenIfDisabled={null}
            />
            <div style={{ clear: 'both', marginTop: 20 }}>
              <div style={{ float: 'left', width: 60 }}>{translate('Height')}</div>
              <TextField
                disabled={!this.state.allowEdit || facetIsManaged}
                type="number"
                style={{
                  float: 'left',
                  width: 60,
                  height: 32,
                  marginTop: -9,
                  marginRight: 10,
                }}
                label={null}
                name={'height'}
                value={this.state.height}
                inputProps={{ min: 0 }}
                InputProps={{
                  endAdornment: this.props.measurements === 'imperial' ? 'ft' : 'm',
                }}
                onFocus={() => {
                  markFieldActive.call(_this, 'height', facet)
                }}
                onBlur={() => {
                  markFieldInactive.call(this)
                }}
                onChange={(event) => {
                  const newValue = event.target.value
                  this.setState({ height: newValue })
                  if (newValue === '') {
                    return
                  }
                  let valueToSave = this.parse(parseFloat(newValue))
                  if (valueToSave < 0) {
                    valueToSave = 0
                  }
                  const newPosition = facet.position.clone()
                  Object.assign(newPosition, { z: valueToSave })
                  window.editor.execute(new window.SetPositionCommand(facet, newPosition))
                }}
              />
              <div style={{ clear: 'both' }} />
            </div>

            {window.ViewHelper.hasNoneView() && (
              <div style={{ clear: 'both', marginTop: 10 }}>
                <CustomSelectField
                  disabled={!this.state.allowEdit || facetIsManaged}
                  style={{ flex: '1 1', width: 230 }}
                  label={translate('Roof Type')}
                  floatingLabelFixed={true}
                  value={facet.roofTypeId()}
                  onChange={(event) => {
                    window.editor.execute(
                      new window.SetValueCommand(facet, '_roofTypeId', event.target.value, undefined, true)
                    )
                    facet.onChange(window.editor)
                  }}
                >
                  <MenuItem value={null}>{translate('Default')}</MenuItem>
                  {window.AccountHelper.loadedData.roofTypes.map((roofType) => (
                    <MenuItem key={roofType.id} value={roofType.id}>
                      {translate(roofType.name)}
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </div>
            )}
            {window.ViewHelper.hasNoneView() && (
              <div style={{ clear: 'both', marginTop: 10 }}>
                <CustomSelectField
                  disabled={!this.state.allowEdit || facetIsManaged}
                  style={{ flex: '1 1', width: 230 }}
                  label={translate('Wall Type')}
                  floatingLabelFixed={true}
                  value={facet.wallTypeId()}
                  onChange={(event) => {
                    window.editor.execute(
                      new window.SetValueCommand(facet, '_wallTypeId', event.target.value, undefined, true)
                    )
                    facet.onChange(window.editor)
                  }}
                >
                  <MenuItem value={null}>{translate('Default')}</MenuItem>
                  {window.AccountHelper.loadedData.wallTypes.map((wallType) => (
                    <MenuItem key={wallType.id} value={wallType.id}>
                      {translate(wallType.name)}
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </div>
            )}

            {window.editor.getTerrain() && (
              <div style={{ clear: 'both' }}>
                <FormControlLabel
                  style={{ marginTop: 20, marginLeft: 0 }}
                  control={
                    <Checkbox
                      disabled={!this.state.allowEdit || facetIsManaged}
                      id="elevationAutoCheckbox"
                      checked={facet.snapToTerrain()}
                      onChange={(event) => {
                        // @TODO: Implement undo/redo
                        facet.snapToTerrain(event.target.checked)
                        facet.onChange(window.editor)
                      }}
                      style={{ padding: 0, paddingRight: 9 }}
                    />
                  }
                  label={translate('Snap to terrain')}
                />
              </div>
            )}

            <Paper style={{ marginTop: 25, padding: 10, boxSizing: 'border-box' }}>
              <h4 style={OpenSolarTheme.typography.h4}>{translate('Panel Groups')}</h4>
              <div style={{ paddingTop: 5 }}>
                {panelGroups.length > 0 && <ModuleGridsList moduleGrids={panelGroups} />}
                {panelGroups.length === 0 && (
                  <span
                    style={{
                      fontWeight: OpenSolarTheme.typography.fontWeightLight,
                      color: OpenSolarTheme.greyMid1,
                    }}
                  >
                    <i>No panels in this facet.</i>
                  </span>
                )}
              </div>
            </Paper>
          </div>
        }
        content={null}
      />
    )
  }
}

PanelFacet.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(PanelFacet)
