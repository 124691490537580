import listQueryParamsFormatter from 'elements/input/listQueryParamsFormatter'
import createBatteryCompatibilityFilterNode, {
  BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2,
} from './batteryCompatibility/BatteryCompatibilityFilterNodeFactoryV2'
import createCableLengthFilterNode, { CABLE_LENGTH_FILTER_KEY_V2 } from './cableLength/CableLengthFilterNodeFactoryV2'
import createCableThicknessFilterNode, {
  CABLE_THICKNESS_FILTER_KEY_V2,
} from './cableThickness/CableThicknessFilterNodeFactoryV2'
import createCurrentRatingFilterNode, {
  CURRENT_RATING_FILTER_NODE_KEY_V2,
} from './currentRating/CurrentRatingFilterNodeFactoryV2'
import createCurrentTypeFilterNode, { CURRENT_FILTER_KEY_V2 } from './currentType/CurrentTypeFilterNodeFactoryV2'
import createDistributorFilterNode, { DISTRIBUTOR_FILTER_KEY_V2 } from './distributor/DistributorFilterConfigV2Factory'
import createGlobalDistributorFilterNode, {
  GLOBAL_DISTRIBUTOR_NODE_KEY_V2,
} from './distributor/GlobalDistributorFilterFactory'
import createInverterFilterNode, {
  INVERTER_TYPE_FILTER_NODE_KEY_V2,
} from './inverterType/InverterTypeFilterNodeFactoryV2'
import createDistributorBrandFilterNode, {
  DISTRIBUTOR_BRAND_FILTER_KEY_V2,
  SELECT_ALL_OPTION as MANUFACTURER_SELECT_ALL_OPTION,
} from './manufacturerV2/DistributorBrandFilterNodeFactoryV2'
import createFeatureConfigBrandFilterNode, {
  FEATURE_CONFIG_BRAND_FILTER_NODE_KEY_V2,
} from './manufacturerV2/FeatureConfigBrandFilterNodeFactoryV2'
import createMpptQuantityFilterNode, {
  MPPT_QUANTITY_FILTER_KEY_V2,
} from './mpptQuantity/MpptQuantityFilterNodeFactoryV2'
import createMyListFilterNode, { MY_LIST_FILTER_NODE_KEY_V2 } from './myList/MyListFilterNodeFactoryV2'
import {
  createHardwareSelectorOtherComponentTypeFilterNode,
  getCheckBoxSelectAllMutator as getCheckBoxSelectAllMutatorOtherComponentTypeFilter,
  OTHER_COMPONENT_TYPE_DESIGN_PAGE_NODE_KEY,
  SELECT_ALL_OPTION as OTHER_TYPES_SELECT_ALL_OPTION,
} from './otherComponentType/OtherComponentTypeFilterNodeFactoryV2'
import createPhaseTypeFilterNode, { PHASE_FILTER_KEY_V2 } from './phaseType/PhaseTypeFilterNodeFactoryV2'
import createCombinedRatingFilterNode, { COMBINED_RATING_FILTER_NODE_KEY } from './rating/CombinedRatingNodeFactory'
import createRatingFilterNode, { RATING_FILTER_NODE_KEY_V2 } from './rating/RatingFilterNodeFactoryV2'
import BasicMultiCheckBoxRendererV2 from './renderers/BasicMultiCheckBoxRendererV2'
import BasicRadioButtonRendererV2 from './renderers/BasicRadioButtonRendererV2'
import createAvailabilityFilterNode, {
  AVAILABILITY_FILTER_KEY_V2,
} from './stockAvailability/AvailabilityFilterNodeFactoryV2'
import type { ConfigurableFilterOptionsType } from './typeV2'
import getCheckBoxSelectAllMutator from './utils/getCheckBoxSelectAllMutator'
import createViewFromFilterNode, { VIEW_FROM_FILTER_NODE_KEY_V2 } from './viewFrom/ViewFromFilterNodeFactoryV2'
import createVoltageAndCurrentFilterNode, {
  VOLTAGE_AND_CURRENT_FILTER_KEY_V2,
} from './voltageAndCurrent/VoltageAndCurrentFilterNodeFactoryV2'
import createWarrantyFilterNode, { WARRANTY_FILTER_KEY_V2 } from './warranty/WarrantyFilterNodeFactoryV2'

export const FILTER_NODE_KEY_AND_FACTORY = {
  [DISTRIBUTOR_FILTER_KEY_V2]: createDistributorFilterNode,
  [WARRANTY_FILTER_KEY_V2]: createWarrantyFilterNode,
  [AVAILABILITY_FILTER_KEY_V2]: createAvailabilityFilterNode,
  [RATING_FILTER_NODE_KEY_V2]: createRatingFilterNode,
  [COMBINED_RATING_FILTER_NODE_KEY]: createCombinedRatingFilterNode,
  [INVERTER_TYPE_FILTER_NODE_KEY_V2]: createInverterFilterNode,
  [OTHER_COMPONENT_TYPE_DESIGN_PAGE_NODE_KEY]: createHardwareSelectorOtherComponentTypeFilterNode,
  [MPPT_QUANTITY_FILTER_KEY_V2]: createMpptQuantityFilterNode,
  [VOLTAGE_AND_CURRENT_FILTER_KEY_V2]: createVoltageAndCurrentFilterNode,
  [CURRENT_FILTER_KEY_V2]: createCurrentTypeFilterNode,
  [PHASE_FILTER_KEY_V2]: createPhaseTypeFilterNode,
  [CURRENT_RATING_FILTER_NODE_KEY_V2]: createCurrentRatingFilterNode,
  [CABLE_LENGTH_FILTER_KEY_V2]: createCableLengthFilterNode,
  [CABLE_THICKNESS_FILTER_KEY_V2]: createCableThicknessFilterNode,
  [GLOBAL_DISTRIBUTOR_NODE_KEY_V2]: createGlobalDistributorFilterNode,
  [DISTRIBUTOR_BRAND_FILTER_KEY_V2]: createDistributorBrandFilterNode,
  [FEATURE_CONFIG_BRAND_FILTER_NODE_KEY_V2]: createFeatureConfigBrandFilterNode,
  [MY_LIST_FILTER_NODE_KEY_V2]: createMyListFilterNode,
  [VIEW_FROM_FILTER_NODE_KEY_V2]: createViewFromFilterNode,
  [BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2]: createBatteryCompatibilityFilterNode,
}

export const HARDWARE_FILTERS_DESIGN_PAGE_CONFIG_V2: ConfigurableFilterOptionsType<any, any>[] = [
  {
    label: 'Compatible with system',
    key: BATTERY_COMPATIBILITY_FILTER_NODE_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Types',
    key: OTHER_COMPONENT_TYPE_DESIGN_PAGE_NODE_KEY,
    formatter: listQueryParamsFormatter,
    rendererAdditionalProps: {
      rendererOnChangeMutator: getCheckBoxSelectAllMutatorOtherComponentTypeFilter(OTHER_TYPES_SELECT_ALL_OPTION),
    },
    rendererComponent: BasicMultiCheckBoxRendererV2,
  },
  {
    label: 'View Items From',
    key: VIEW_FROM_FILTER_NODE_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'View by distributor',
    key: GLOBAL_DISTRIBUTOR_NODE_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2, // This hacky UI only work for radio buttons
  },
  {
    label: 'Stock Availability',
    key: AVAILABILITY_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },

  {
    label: 'Brand',
    key: DISTRIBUTOR_BRAND_FILTER_KEY_V2,
    formatter: listQueryParamsFormatter,
    rendererAdditionalProps: {
      rendererOnChangeMutator: getCheckBoxSelectAllMutator(MANUFACTURER_SELECT_ALL_OPTION),
    },
    rendererComponent: BasicMultiCheckBoxRendererV2,
  },
  {
    label: 'Brand',
    key: FEATURE_CONFIG_BRAND_FILTER_NODE_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Warranty',
    key: WARRANTY_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Rating',
    key: RATING_FILTER_NODE_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Combined Rating',
    key: COMBINED_RATING_FILTER_NODE_KEY,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Inverter Type',
    key: INVERTER_TYPE_FILTER_NODE_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'MPPT Quantity',
    key: MPPT_QUANTITY_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Voltage and Current',
    key: VOLTAGE_AND_CURRENT_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Current Type',
    key: CURRENT_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Phase',
    key: PHASE_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Current Rating',
    key: CURRENT_RATING_FILTER_NODE_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Cable Length',
    key: CABLE_LENGTH_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
  {
    label: 'Cable Thickness',
    key: CABLE_THICKNESS_FILTER_KEY_V2,
    rendererComponent: BasicRadioButtonRendererV2,
  },
]
