import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { ComponentVersionsInherit, styled } from 'opensolar-ui'
import { ProjectFilesList } from './ProjectFilesList'

export const MainWrapper = styled('div')({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
})

export const BackgroundOverlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'clip',
  backgroundColor: '#F6F9FC',
})

export const ProjectFiles = (props) => {
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <BackgroundOverlay />
      <MainWrapper>
        <ProjectFilesList {...props} />
      </MainWrapper>
    </ComponentVersionsInherit>
  )
}
