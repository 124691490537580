import { orgSelectors } from 'ducks/orgs'
import useFilter from 'elements/controller/filter/useFilter'
import useQuery from 'elements/controller/query/useQuery'
import type { FilterValuesType } from 'elements/hardwareFilter/typeV2'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ComponentTypesV2 } from 'types/selectComponent'
import useRecommendedAndRequiredComponentOrdering from '../useRecommendedAndRequiredComponentOrdering'
import type { HardwareSelectorControllerType } from './type'
import useHardwareComponentsListBasic from './useHardwareComponentsListBasic'

// JSON.stringify(COMPONENT_TYPE_OPTIONS.map((option) => option.id))
/*
  This hook returns a list of hardware component collections along with the associated data and handlers used for obtaining the results.
*/
const useHardwareSelectorFetchEngine = ({
  componentTypes,
  limit,
  defaultFilterValues,
  persistentFilterValuesOverride,
}: {
  componentTypes: ComponentTypesV2[]
  limit: number
  defaultFilterValues?: { [key: string]: any }
  persistentFilterValuesOverride?: { [key: string]: any }
}): HardwareSelectorControllerType => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const orderByCodes = useRecommendedAndRequiredComponentOrdering()
  const { query, queryPresenter } = useQuery({ perPage: limit, sort: '_default_rule', order: 'ASC' })
  const { filter, filterPresenter } = useFilter({
    defaultFilter: defaultFilterValues && {
      filterValues: {
        ...defaultFilterValues,
        country_iso2: countryIso2,
        order_by_codes: orderByCodes,
      },
      displayedFilters: {},
    },
    queryPresenter,
  })

  const modifiableFilterValues = filter.filterValues
  const PERSISTENT_FILTER_VALUES: FilterValuesType = useMemo(
    () => ({
      fieldset: 'list',
      types: JSON.stringify(componentTypes),
      ...(persistentFilterValuesOverride || {}),
      show_archived: modifiableFilterValues?.is_my_list ? 0 : 1,
    }),
    [persistentFilterValuesOverride, modifiableFilterValues]
  )

  const allFilterValues = useMemo(
    () => ({
      page: query.page,
      perPage: query.perPage,
      sort: query.sort,
      order: query.order,
      ...PERSISTENT_FILTER_VALUES,
      ...modifiableFilterValues,
    }),
    [query, modifiableFilterValues, PERSISTENT_FILTER_VALUES]
  )

  const filterValues = useMemo(
    () => ({
      ...PERSISTENT_FILTER_VALUES,
      ...modifiableFilterValues,
    }),
    [modifiableFilterValues, PERSISTENT_FILTER_VALUES]
  )

  const { data, ids, total, loading, loaded } = useHardwareComponentsListBasic({
    resource: 'componentsv2',
    page: query.page,
    perPage: query.perPage,
    sort: query.sort,
    order: query.order,
    filterValues: filterValues,
  })

  const componentsData = useMemo(() => {
    const productData = []
    ids?.forEach((id) => {
      // @ts-ignore
      // TODO: add strong type
      data?.[id] && productData.push(data[id])
    })
    return productData
  }, [data, ids])

  return {
    setPage: queryPresenter.setPage,
    setPerPage: queryPresenter.setPerPage,
    setFilters: filterPresenter.setFilters,
    page: query.page,
    perPage: query.perPage,
    total,
    componentsData,
    loading,
    persistentFilterValues: PERSISTENT_FILTER_VALUES,
    modifiableFilterValues,
    allFilterValues,
  }
}

export default useHardwareSelectorFetchEngine
