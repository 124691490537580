import { kw_to_watt, mm_to_m, m_to_mm, watt_to_kw } from 'util/misc'
import SoftWarning from '../AdornmentWithWarning'
import { convertToLegacyCustomFields } from '../common'
import type { ComponentConfig, ComponentTypeInString } from '../types'

export const moduleTechnologyValues = [
  { id: 'Mono-c-Si', name: 'Mono-Si' },
  { id: 'Multi-c-Si', name: 'Multi-Si' },
  { id: 'CdTe', name: 'CdTe' },
  { id: 'CIS', name: 'CIS' },
  { id: 'CIGS', name: 'CIGS' },
  { id: 'Amorphous', name: 'Amorphous' },
]

export const moduleComponentDataConfigs: ComponentConfig<ComponentTypeInString>[] = [
  {
    source: 'code',
    label: 'Code',
    component: 'String',
  },
  {
    source: 'manufacturer_name',
    label: 'Manufacturer',
    component: 'AutocompleteInput',
    referenceInputParams: {
      label: 'Manufacturer',
      reference: 'manufacturers',
      allowEmpty: true,
      ignoreWarnings: ['associatedReference'],
    },
    extra: {
      freeSolo: true,
      fullWidth: true,
      optionValue: 'name',
    },
  },
  {
    source: 'technology',
    label: 'Technology',
    component: 'Select',
    selectOptions: moduleTechnologyValues,
  },
  {
    source: 'kw_stc',
    label: 'Rating',
    component: 'Number',
    unit: 'Watts at STC', // be aware the unit is post conversion
    formatter: { parse: watt_to_kw, format: kw_to_watt },
  },
  {
    source: 'width',
    label: 'Width',
    component: 'Number',
    unit: 'mm',
    formatter: { parse: mm_to_m, format: m_to_mm },
  },
  {
    source: 'thickness',
    label: 'Thickness',
    component: 'Number',
    unit: 'mm',
    softWarning: <SoftWarning source="custom_data_field_thickness" max={100} />,
  },
  {
    source: 'height',
    label: 'Height',
    component: 'Number',
    unit: 'mm',
    formatter: { parse: mm_to_m, format: m_to_mm },
  },
  {
    source: 'max_power_voltage',
    label: 'Maximum Power Voltage (Vmp)',
    component: 'Number',
    unit: 'V',
    softWarning: <SoftWarning source="custom_data_field_max_power_voltage" max={100} />,
  },
  {
    source: 'imp',
    label: 'Maximum power Current (Imp)',
    component: 'Number',
    unit: 'A',
    softWarning: <SoftWarning source="custom_data_field_imp" max={100} />,
  },
  {
    source: 'isc',
    label: 'Short Circuit Current (Isc)',
    component: 'Number',
    unit: 'A',
    softWarning: <SoftWarning source="custom_data_field_isc" max={100} />,
  },
  {
    source: 'voc',
    label: 'Open Circuit Voltage (Voc)',
    component: 'Number',
    unit: 'V',
    softWarning: <SoftWarning source="custom_data_field_voc" max={100} />,
  },
  {
    source: 'noct',
    label: 'Nominal operating cell temperature',
    component: 'Number',
    unit: '°C',
    softWarning: <SoftWarning source="custom_data_field_noct" max={100} />,
  },
  {
    source: 'temp_coefficient_voc',
    label: 'Temp. Coefficient Voc',
    component: 'Number',
    unit: '%/°C',
    softWarning: <SoftWarning source="custom_data_field_temp_coefficient_voc" min={-1} />,
  },
  {
    source: 'temp_coefficient_isc',
    label: 'Temp. Coefficient Isc',
    component: 'Number',
    unit: '%/°C',
    softWarning: <SoftWarning source="custom_data_field_temp_coefficient_isc" max={1} />,
  },
  {
    source: 'temp_coefficient_vpmax',
    label: 'Temp. Coefficient Pmax',
    component: 'Number',
    unit: '%/°C',
    softWarning: <SoftWarning source="custom_data_field_temp_coefficient_vpmax" min={-1} />,
  },
  {
    source: 'cells_in_series',
    label: 'Cells In Series',
    component: 'Number',
    softWarning: <SoftWarning source="custom_data_field_cells_in_series" max={400} />,
  },
  {
    source: 'bifaciality',
    label: 'Bifaciality',
    component: 'Number',
    unit: '0-1',
  },
  {
    source: 'transmission',
    label: 'Transmission (For Bifacial Transmission Factor Calculations)',
    component: 'Number',
    unit: '0-1',
  },
  {
    source: 'product_warranty',
    label: 'Product Warranty',
    component: 'Number',
    unit: 'years',
    softWarning: <SoftWarning source="custom_data_field_product_warranty" max={30} />,
  },
  {
    source: 'performance_warranty',
    label: 'Performance Warranty',
    component: 'Number',
    unit: 'years',
    softWarning: <SoftWarning source="custom_data_field_performance_warranty" max={30} />,
  },
  {
    source: 'first_year_degradation',
    label: 'First Year Degradation',
    component: 'Number',
    unit: '%',
    softWarning: <SoftWarning source="custom_data_field_first_year_degradation" max={5} />,
  },
  {
    source: 'annual_degradation_override',
    label: 'Annual Degradation',
    component: 'Number',
    unit: '%/yr',
    softWarning: <SoftWarning source="custom_data_field_annual_degradation_override" max={1} />,
  },
  {
    source: 'weight',
    label: 'Weight',
    component: 'Number',
    unit: 'kg',
    softWarning: <SoftWarning source="custom_data_field_weight" max={50} />,
  },
  {
    source: 'skus',
    label: 'SKUs',
    component: 'String',
    unit: 'csv',
  },
  {
    source: 'sandia_specs',
    label: 'Sandia Specifications',
    component: 'String',
    unit: 'JSON',
  },
  {
    source: 'colour',
    label: 'Colour',
    component: 'String',
  },
]

const osSpecificModuleDataConfigs: ComponentConfig<ComponentTypeInString>[] = []

export const customModuleDataConfigs = [...moduleComponentDataConfigs, ...osSpecificModuleDataConfigs]

// TODO: to clean up the usage of customModuleDataFields and remove this method
export const customModuleDataFields = convertToLegacyCustomFields(customModuleDataConfigs)

export const moduleDataFields = customModuleDataFields.filter((item) => item.source !== 'manufacturer_name')
