import { Chip } from 'opensolar-ui'
import React from 'react'
import { ProjectTransactionTypeV3 } from 'types/projectTransactions'
import {
  StyledTinnyBox,
  TagsContainer,
  TinnyBoxBody,
  TinnyboxColumn,
  TinnyboxGreyText,
  TinnyBoxTopRow,
} from '../files/common/styles'
import { TransactionRowActions } from './TransactionRowActions'
import { TransactionRowData } from './types'
import { useFormatTransactionRow } from './useFormatTransactionRow'

type PropTypes = {
  transactionRecordData: ProjectTransactionTypeV3
  transactionSelectFunc: (i: ProjectTransactionTypeV3) => void
  setRecordResponseInfo: (i: any) => void
}

export const TransactionRow: React.FunctionComponent<PropTypes> = (props) => {
  const formattedTransaction: TransactionRowData = useFormatTransactionRow(props.transactionRecordData)

  const chipColor = props?.transactionRecordData?.is_complete ? 'success' : 'info'

  return (
    <div>
      <StyledTinnyBox
        title={
          <TinnyBoxTopRow>
            <div>
              <div>{formattedTransaction.title}</div>
            </div>
            <TagsContainer>
              <Chip color={chipColor} label={formattedTransaction.status} />
            </TagsContainer>
          </TinnyBoxTopRow>
        }
        backgroundColor={'transparent'}
        actions={
          <TransactionRowActions
            transactionRecordData={props.transactionRecordData}
            transactionSelectFunc={props.transactionSelectFunc}
            setRecordResponseInfo={props.setRecordResponseInfo}
          />
        }
      >
        <TinnyBoxBody>
          <TinnyboxColumn>
            {formattedTransaction.bodyItems.map((t, i) => (
              <TinnyboxGreyText>
                <strong>{t.label}: </strong>
                {t.value}
              </TinnyboxGreyText>
            ))}
          </TinnyboxColumn>
        </TinnyBoxBody>
      </StyledTinnyBox>
    </div>
  )
}
