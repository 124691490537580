import { Table, TableCell, TableRow } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { orderSelectors } from 'ducks/orderComponents'
import Button from 'elements/button/Button'
import Tooltip from 'elements/tooltip/Tooltip'
import type OrderLineItem from 'pages/ordering/OrderLineItem'
import { getOrderCost } from 'pages/ordering/utils'
import { FC, memo, useCallback, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import useHdmCreditLimit from 'services/hdm/useHdmCreditLimit'

import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import CreditLimit from '../CreditLimit'
import HdmConnectAccountAlert from './HdmConnectAccountAlert'
import { formatCurrency } from 'util/misc'

const ToolTipMessage = 'A new tab will open for you to complete your order with the hardware partner.'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    boxSizing: 'border-box',
    border: theme.border.default,
    borderRadius: '8px',
    padding: 15,
    marginBottom: 15,
  },
  loginLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const useStylesPriceBreakdown = makeOpenSolarStyles((theme) => ({
  textWrapper: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  table: {
    '& td': {
      padding: '2px',
      borderBottom: 'none',
      '&:last-child': {
        textAlign: 'right',
      },
    },
    marginBottom: '10px',
  },
  totalRow: {
    '& td': {
      fontWeight: 'bold',
      borderTop: `1px solid ${theme.greyMid1}`,
    },
    // Hide the separator line if there are no rows above the total (no discounts have been appliedReview )
    '&:first-child td': {
      borderTop: 'none',
    },
  },
}))

type Props = {
  orderableLineItems: OrderLineItem[]
}

export const PriceBreakdown: FC<Props> = ({ orderableLineItems }) => {
  const classes = useStylesPriceBreakdown()
  const translate = useTranslate()
  const currencySymbol = '£'

  const cost = getOrderCost(orderableLineItems, false)
  const tax = ((cost * 20) / 100).toFixed(2)

  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.textWrapper}>
        {translate('shopping_cart_action_subtotal', { smart_count: orderableLineItems.length })}
      </Typography>

      <Table className={classes.table}>
        <TableRow className={classes.totalRow}>
          <TableCell>{translate('Order Total')}:</TableCell>
          <TableCell>{`${currencySymbol} ${formatCurrency(cost)}`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{translate('VAT')} (20%):</TableCell>
          <TableCell>{`${currencySymbol} ${formatCurrency(parseFloat(tax))}`}</TableCell>
        </TableRow>
      </Table>
    </>
  )
}

const HdmCartActions = () => {
  const classes = useStyles()
  const history = useHistory()

  const { isLoading, creditLimit } = useHdmCreditLimit()
  const lineItems = useSelector(orderSelectors.getOrderableLineItems)

  const currencySymbol = '£'

  const totalCost = useMemo(() => {
    const itemsCost = getOrderCost(lineItems)
    const tax = itemsCost + itemsCost * 0.2
    return itemsCost + tax
  }, [lineItems])

  const hasEnoughCredit = useMemo(() => {
    return (creditLimit?.credit_balance || 0) > totalCost
  }, [creditLimit?.credit_balance, totalCost])

  const handleCtaClick = useCallback(
    (event) => {
      event.preventDefault()
      logAmplitudeEvent('hardware_review_order_cta_clicked', {
        distributor: 'hdm',
        source: 'shopping_cart',
      })
      history.push('/shop/cart/hdm/')
    },
    [hasEnoughCredit]
  )

  return (
    <>
      <div className={classes.wrapper}>
        <CreditLimit
          loading={isLoading}
          currencySymbol={currencySymbol}
          creditLimit={creditLimit?.credit_balance}
          supplierName="HDM"
        />
      </div>
      <div className={classes.wrapper}>
        <PriceBreakdown orderableLineItems={lineItems} />
        <Tooltip title={ToolTipMessage} interactive={true} arrow placement="top">
          <Button
            color="primary"
            loading={isLoading}
            disabled={isLoading || lineItems.length === 0}
            fullWidth={true}
            onClick={handleCtaClick}
          >
            Review and Place Order
          </Button>
        </Tooltip>
      </div>
      <HdmConnectAccountAlert />
    </>
  )
}

export default memo(HdmCartActions)
