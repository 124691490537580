import { DropdownInput } from 'types/mounting'
import { ComptibilityRules } from '../../inputFilters'

interface RowData {
  [key: string]: string
}

export const filterByType = (prods, type) => {
  return prods.filter((p) => p.variableName === type)
}

export const findProd = (prods, prodCode): RowData => {
  const prod = prods.find((p) => p.value === prodCode)
  if (!prod) {
    throw new Error(`Couldn't find product with code ${prodCode}`)
  } else {
    return prod
  }
}

export const getDefaultProd = (allProds, type, color, profile, panel_range, roofType?, orient?) => {
  const prods = filterByType(allProds, type)
  const defaultProduct = prods.find((p) => {
    const incCol = p.color.includes(color)
    const incProf = p.profile.includes(profile)
    const incRange = p.panel_range.includes(panel_range)
    const incRoof = roofType ? p.roof_type.includes(roofType) : true
    const incOrient = orient ? p.match_orientation.includes(orient) : true
    return incCol && incProf && incRange && incRoof && incOrient
  })
  if (defaultProduct) {
    return defaultProduct.value
  } else {
    throw new Error(
      `Couldn't find ${prods[0].dropdown_label} compatible with color${color} ${profile} ${panel_range} ${orient || ''}`
    )
  }
}

export const gmbhProducts = [
  {
    value: '814-0433',
    label: '10/48 End Cap Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '814-0431',
    label: '4/35 End Cap Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '814-0432',
    label: '6/40 End Cap Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '814-0427',
    label: '10/48 End Cap Grey',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '814-0425',
    label: '4/35 End Cap Grey',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'TRUE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '814-0426',
    label: '6/40 End Cap Grey',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Cap',
    variableName: 'endCap',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '702-0196',
    label: 'End Clamp for panels 30-40mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '702-0197',
    label: 'End Clamp for panels 33-45mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '702-0198',
    label: 'End Clamp for panels 38-50mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '702-0193',
    label: 'End Clamp for panels 30-40mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '702-0194',
    label: 'End Clamp for panels 33-45mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '702-0195',
    label: 'End Clamp for panels 38-50mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'End Clamp',
    variableName: 'endClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '806-0029',
    label: 'Hexagon wood screw 8x80mm 2x for heavy load roof hooks plus 2 washers',
    is_dropdown: 'FALSE',
    dropdown_label: 'Roof Fixing',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '806-0072',
    label: 'Chipboard screw 6x80mm 2xfor flat-tile roof hooks and adjustable roof hooks and standard and plain',
    is_dropdown: 'FALSE',
    dropdown_label: 'Roof Fixing',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '807-0026',
    label: 'Washer M8',
    is_dropdown: 'FALSE',
    dropdown_label: 'Other',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '806-0017',
    label: 'Chipboard screw 6x100mm 3x for slate roof hook',
    is_dropdown: 'FALSE',
    dropdown_label: 'Roof Fixing',
    variableName: 'fastener',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '700-0083',
    label: 'Mid Clamp for panels 30-40mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '700-0084',
    label: 'Mid Clamp for panels 33-45mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '700-0085',
    label: 'Mid Clamp for panels 38-50mm, Black',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'black',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '700-0080',
    label: 'Mid Clamp for panels 30-40mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '30-40',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '700-0081',
    label: 'Mid Clamp for panels 33-45mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '33-45',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '700-0082',
    label: 'Mid Clamp for panels 38-50mm, Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Mid Clamp',
    variableName: 'midClamp',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver',
    panel_range: '38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '610-0055',
    label: '1700mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1700',
  },
  {
    value: '610-0097',
    label: '1200mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1200',
  },
  {
    value: '610-0713',
    label: '600mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '600',
  },
  {
    value: '800-0992',
    label: '6000mm 4/35 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '6000',
  },
  {
    value: '610-0714',
    label: '600mm 6/40 Rail Black',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '600',
  },
  {
    value: '800-0025',
    label: '4150mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '4150',
  },
  {
    value: '800-1132',
    label: '6000mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '6000',
  },
  {
    value: '800-1341',
    label: '2400mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '2400',
  },
  {
    value: '800-1413',
    label: '4400mm 10/48 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '4400',
  },
  {
    value: '610-0056',
    label: '1700mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1700',
  },
  {
    value: '610-0096',
    label: '2250mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '2250',
  },
  {
    value: '610-0704',
    label: '1200mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1200',
  },
  {
    value: '610-0705',
    label: '600mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '600',
  },
  {
    value: '800-0020',
    label: '4150mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '4150',
  },
  {
    value: '800-0021',
    label: '3150mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '3150',
  },
  {
    value: '800-1055',
    label: '6000mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '6000',
  },
  {
    value: '800-1317',
    label: '5200mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '5200',
  },
  {
    value: '800-1344',
    label: '2400mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '2400',
  },
  {
    value: '800-1405',
    label: '4400mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '4400',
  },
  {
    value: '800-1384',
    label: '3300mm 4/35 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'TRUE',
    profile: '4/35',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '3300',
  },
  {
    value: '610-0070',
    label: '2400mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '2400',
  },
  {
    value: '800-0017',
    label: '4150mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '4150',
  },
  {
    value: '800-0019',
    label: '3150mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '3150',
  },
  {
    value: '800-1057',
    label: '6000mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '6000',
  },
  {
    value: '800-1319',
    label: '5200mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '5200',
  },
  {
    value: '800-1411',
    label: '4400mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '4400',
  },
  {
    value: '800-1412',
    label: '3300mm 6/40 Rail Silver',
    is_dropdown: 'TRUE',
    dropdown_label: 'Rail',
    variableName: 'mountingRail',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '3300',
  },
  {
    value: '602-0050',
    label: '10/48 Rail Connector Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Rail Connector',
    variableName: 'railSplice',
    is_default: 'FALSE',
    profile: '10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '602-0048',
    label: '4/35 Rail Connector Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Rail Connector',
    variableName: 'railSplice',
    is_default: 'TRUE',
    profile: '4/35',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '602-0049',
    label: '6/40 Rail Connector Silver',
    is_dropdown: 'FALSE',
    dropdown_label: 'Rail Connector',
    variableName: 'railSplice',
    is_default: 'FALSE',
    profile: '6/40',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '707-0202',
    label: 'Roof Hook standard comfort stainless steel 170-7-49',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'TRUE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'FALSE',
    roof_fixing__code: '',
    roof_fixing__included: 'TRUE',
    roof_fixing__qty: '1',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '707-0212',
    label: 'Roof Hook for plain tiles, preassembled stainless steel',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '707-0036',
    label: 'Roof Hook for slate tiles stainless steel',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal|vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0017',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '3',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '707-0204',
    label: 'Heavy Loads Roof Hook comfort stainless steel 225-9-46',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0029',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    washer__code: '807-0026',
    washer__included: 'FALSE',
    washer__qty: '2',
    length: '1',
  },
  {
    value: '707-0201',
    label: 'Adjustable Roof Hook comfort stainless steel',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'horizontal',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
  {
    value: '805-0047',
    label: 'Roof Hook with twisted bar stainless steel 155-7-49',
    is_dropdown: 'TRUE',
    dropdown_label: 'Roof Hook',
    variableName: 'roofHook',
    is_default: 'FALSE',
    profile: '4/35|6/40|10/48',
    color: 'silver|black',
    panel_range: '30-40|33-45|38-50',
    match_orientation: 'vertical',
    roof_type:
      'Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other',
    extra_required: 'TRUE',
    roof_fixing__code: '806-0072',
    roof_fixing__included: 'FALSE',
    roof_fixing__qty: '2',
    washer__code: '',
    washer__included: 'TRUE',
    washer__qty: '1',
    length: '1',
  },
]

export const gmbhDropdowns: DropdownInput[] = [
  {
    variableName: 'mountingRail',
    label: 'Rail',
    default: '800-1384',
    options: [
      {
        value: '610-0055',
        label: '1700mm 4/35 Rail Black',
      },
      {
        value: '610-0097',
        label: '1200mm 4/35 Rail Black',
      },
      {
        value: '610-0713',
        label: '600mm 4/35 Rail Black',
      },
      {
        value: '800-0992',
        label: '6000mm 4/35 Rail Black',
      },
      {
        value: '610-0714',
        label: '600mm 6/40 Rail Black',
      },
      {
        value: '800-0025',
        label: '4150mm 10/48 Rail Silver',
      },
      {
        value: '800-1132',
        label: '6000mm 10/48 Rail Silver',
      },
      {
        value: '800-1341',
        label: '2400mm 10/48 Rail Silver',
      },
      {
        value: '800-1413',
        label: '4400mm 10/48 Rail Silver',
      },
      {
        value: '610-0056',
        label: '1700mm 4/35 Rail Silver',
      },
      {
        value: '610-0096',
        label: '2250mm 4/35 Rail Silver',
      },
      {
        value: '610-0704',
        label: '1200mm 4/35 Rail Silver',
      },
      {
        value: '610-0705',
        label: '600mm 4/35 Rail Silver',
      },
      {
        value: '800-0020',
        label: '4150mm 4/35 Rail Silver',
      },
      {
        value: '800-0021',
        label: '3150mm 4/35 Rail Silver',
      },
      {
        value: '800-1055',
        label: '6000mm 4/35 Rail Silver',
      },
      {
        value: '800-1317',
        label: '5200mm 4/35 Rail Silver',
      },
      {
        value: '800-1344',
        label: '2400mm 4/35 Rail Silver',
      },
      {
        value: '800-1405',
        label: '4400mm 4/35 Rail Silver',
      },
      {
        value: '800-1384',
        label: '3300mm 4/35 Rail Silver',
      },
      {
        value: '610-0070',
        label: '2400mm 6/40 Rail Silver',
      },
      {
        value: '800-0017',
        label: '4150mm 6/40 Rail Silver',
      },
      {
        value: '800-0019',
        label: '3150mm 6/40 Rail Silver',
      },
      {
        value: '800-1057',
        label: '6000mm 6/40 Rail Silver',
      },
      {
        value: '800-1319',
        label: '5200mm 6/40 Rail Silver',
      },
      {
        value: '800-1411',
        label: '4400mm 6/40 Rail Silver',
      },
      {
        value: '800-1412',
        label: '3300mm 6/40 Rail Silver',
      },
    ],
  },
  {
    variableName: 'roofHook',
    label: 'Roof Hook',
    default: '707-0202',
    options: [
      {
        value: '707-0202',
        label: 'Roof Hook standard comfort stainless steel 170-7-49',
      },
      {
        value: '707-0212',
        label: 'Roof Hook for plain tiles, preassembled stainless steel',
      },
      {
        value: '707-0036',
        label: 'Roof Hook for slate tiles stainless steel',
      },
      {
        value: '707-0204',
        label: 'Heavy Loads Roof Hook comfort stainless steel 225-9-46',
      },
      {
        value: '707-0201',
        label: 'Adjustable Roof Hook comfort stainless steel',
      },
      {
        value: '805-0047',
        label: 'Roof Hook with twisted bar stainless steel 155-7-49',
      },
    ],
  },
]

export const compatibilityRules: ComptibilityRules = {
  mountingRail: {
    profileCompatibility: {
      '610-0055': ['4/35'],
      '610-0097': ['4/35'],
      '610-0713': ['4/35'],
      '800-0992': ['4/35'],
      '610-0714': ['6/40'],
      '800-0025': ['10/48'],
      '800-1132': ['10/48'],
      '800-1341': ['10/48'],
      '800-1413': ['10/48'],
      '610-0056': ['4/35'],
      '610-0096': ['4/35'],
      '610-0704': ['4/35'],
      '610-0705': ['4/35'],
      '800-0020': ['4/35'],
      '800-0021': ['4/35'],
      '800-1055': ['4/35'],
      '800-1317': ['4/35'],
      '800-1344': ['4/35'],
      '800-1405': ['4/35'],
      '800-1384': ['4/35'],
      '610-0070': ['6/40'],
      '800-0017': ['6/40'],
      '800-0019': ['6/40'],
      '800-1057': ['6/40'],
      '800-1319': ['6/40'],
      '800-1411': ['6/40'],
      '800-1412': ['6/40'],
    },
  },
}

//THE BELOW WAS USED TO PARSE PRODUCTS SPREADSHEET AND TEST THAT ALL PRODUCTS HAD A CORRECT DEFAULT FOR ALL POSSIBLE INPUTS. WILL DELETE ONCE IT IS CONFIRMED NO MORE CHANGES TO PRODUCTS LIST AND PRODUCT COMPATIBILITY ARE NEEDED ALTHOUGH

//NOTE:
//THIS LOGIC WOULD BE HANDY TO BE ABLE TO UPLOAD NEW MOUNTING SYSTEMS JUST USING A SPREADSHEET, COMPATIBILITY VALIDATION COULD BE DONE WITHIN THE ACTUAL SPREADSHEET AND ATTACHED TO THE PRODUCT IN DATABASE

// export const parseProds = (prods: String) => {
//   const rows: RowData[] = []
//   const lines = prods.split('\n')
//   const headers = lines[0].split('\t')
//   for (let i = 1; i < lines.length; i++) {
//     const values = lines[i].split('\t')
//     const row = {} as RowData
//     for (let j = 0; j < headers.length; j++) {
//       row[headers[j]] = values[j]
//     }
//     rows.push(row)
//   }
//   return rows
// }

// export const getDropdowns = (prods: any): DropdownInput[] => {
//   const transformedData = prods.reduce((acc: any[], prod: RowData) => {
//     if (prod.is_dropdown === 'FALSE') {
//       return acc
//     } else {
//       const dropdown = acc.find((p) => p.variableName === prod.variableName)
//       const { value, label } = prod
//       const defaultProd = prod.is_default === 'TRUE' ? value : null
//       if (dropdown) {
//         dropdown.options.push({ value, label })
//         if (dropdown?.default === null) {
//           dropdown.default = defaultProd
//         }
//       } else {
//         acc.push({
//           variableName: prod.variableName,
//           label: prod.dropdown_label,
//           default: defaultProd,
//           options: [{ value, label }],
//         })
//       }
//       return acc
//     }
//   }, [])
//   return transformedData
// }

// type OptionWithDefault = 'endCap' | 'endClamp' | 'fastener' | 'midClamp' | 'railSplice'
// type Color = 'silver' | 'black'
// export type Profile = '4/35' | '6/40' | '10/48'
// export type PanelRange = '30-40' | '33-45' | '38-50'
// export type OrientType = 'horizontal' | 'vertical'

// export const testAllDefaultsFound = (prods) => {
//   const prodTypesV: OptionWithDefault[] = ['endCap', 'endClamp', 'fastener', 'midClamp', 'railSplice']
//   const colors: Color[] = ['silver', 'black']
//   const profiles: Profile[] = ['4/35', '6/40', '10/48']
//   const p_range: PanelRange[] = ['30-40', '33-45', '38-50']
//   const roofTypes: RoofTypeName[] = [
//     'Composition / Asphalt Shingle',
//     'Flat Concrete',
//     'Flat Foam',
//     'Kliplock',
//     'Membrane EPDM',
//     'Membrane PVC',
//     'Membrane TPO',
//     'Metal Decramastic',
//     'Metal Shingle',
//     'Metal Standing Seam',
//     'Metal Stone Coated',
//     'Metal Tin',
//     'Tar and Gravel / Bitumen',
//     'Thatched',
//     'Tile Clay',
//     'Tile Concrete',
//     'Tile Slate',
//     'Wood/Shake Shingle',
//     'Other',
//   ]
//   const orientations: OrientType[] = ['horizontal', 'vertical']
//   for (const type of prodTypesV) {
//     for (const color of colors) {
//       for (const prof of profiles) {
//         for (const range of p_range) {
//           for (const roof of roofTypes) {
//             for (const orient of orientations) {
//               // const byType = filterByType(prods, type)
//               const defaultProd = getDefaultProd(prods, type, color, prof, range, roof, orient)
//               if (!defaultProd) {
//                 throw new Error(`-------no default for ${type} ${color} ${prof} ${range} ${roof} ${orient}`)
//               } else {
//                 console.log('------- DEFAULT FOUND ')
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

// export const gmbh_products = `value	label	is_dropdown	dropdown_label	variableName	is_default	profile	color	panel_range	match_orientation	roof_type	extra_required	roof_fixing__code	roof_fixing__included	roof_fixing__qty	washer__code	washer__included	washer__qty	length
// 814-0433	10/48 End Cap Black	FALSE	End Cap	endCap	FALSE	10/48	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 814-0431	4/35 End Cap Black	FALSE	End Cap	endCap	FALSE	4/35	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 814-0432	6/40 End Cap Black	FALSE	End Cap	endCap	FALSE	6/40	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 814-0427	10/48 End Cap Grey	FALSE	End Cap	endCap	FALSE	10/48	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 814-0425	4/35 End Cap Grey	FALSE	End Cap	endCap	TRUE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 814-0426	6/40 End Cap Grey	FALSE	End Cap	endCap	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 702-0196	End Clamp for panels 30-40mm, Black	FALSE	End Clamp	endClamp	FALSE	4/35|6/40|10/48	black	30-40	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 702-0197	End Clamp for panels 33-45mm, Black	FALSE	End Clamp	endClamp	FALSE	4/35|6/40|10/48	black	33-45	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 702-0198	End Clamp for panels 38-50mm, Black	FALSE	End Clamp	endClamp	FALSE	4/35|6/40|10/48	black	38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 702-0193	End Clamp for panels 30-40mm, Silver	FALSE	End Clamp	endClamp	FALSE	4/35|6/40|10/48	silver	30-40	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 702-0194	End Clamp for panels 33-45mm, Silver	FALSE	End Clamp	endClamp	FALSE	4/35|6/40|10/48	silver	33-45	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 702-0195	End Clamp for panels 38-50mm, Silver	FALSE	End Clamp	endClamp	FALSE	4/35|6/40|10/48	silver	38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 806-0029	Hexagon wood screw 8x80mm 2x for heavy load roof hooks plus 2 washers	FALSE	Roof Fixing	fastener	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 806-0072	Chipboard screw 6x80mm 2xfor flat-tile roof hooks and adjustable roof hooks and standard and plain	FALSE	Roof Fixing	fastener	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 807-0026	Washer M8	FALSE	Other	fastener	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 806-0017	Chipboard screw 6x100mm 3x for slate roof hook	FALSE	Roof Fixing	fastener	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 700-0083	Mid Clamp for panels 30-40mm, Black	FALSE	Mid Clamp	midClamp	FALSE	4/35|6/40|10/48	black	30-40	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 700-0084	Mid Clamp for panels 33-45mm, Black	FALSE	Mid Clamp	midClamp	FALSE	4/35|6/40|10/48	black	33-45	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 700-0085	Mid Clamp for panels 38-50mm, Black	FALSE	Mid Clamp	midClamp	FALSE	4/35|6/40|10/48	black	38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 700-0080	Mid Clamp for panels 30-40mm, Silver	FALSE	Mid Clamp	midClamp	FALSE	4/35|6/40|10/48	silver	30-40	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 700-0081	Mid Clamp for panels 33-45mm, Silver	FALSE	Mid Clamp	midClamp	FALSE	4/35|6/40|10/48	silver	33-45	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 700-0082	Mid Clamp for panels 38-50mm, Silver	FALSE	Mid Clamp	midClamp	FALSE	4/35|6/40|10/48	silver	38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 610-0055	1700mm 4/35 Rail Black	TRUE	Rail	mountingRail	FALSE	4/35	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1700
// 610-0097	1200mm 4/35 Rail Black	TRUE	Rail	mountingRail	FALSE	4/35	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1200
// 610-0713	600mm 4/35 Rail Black	TRUE	Rail	mountingRail	FALSE	4/35	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	600
// 800-0992	6000mm 4/35 Rail Black	TRUE	Rail	mountingRail	FALSE	4/35	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	6000
// 610-0714	600mm 6/40 Rail Black	TRUE	Rail	mountingRail	FALSE	6/40	black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	600
// 800-0025	4150mm 10/48 Rail Silver	TRUE	Rail	mountingRail	FALSE	10/48	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	4150
// 800-1132	6000mm 10/48 Rail Silver	TRUE	Rail	mountingRail	FALSE	10/48	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	6000
// 800-1341	2400mm 10/48 Rail Silver	TRUE	Rail	mountingRail	FALSE	10/48	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	2400
// 800-1413	4400mm 10/48 Rail Silver	TRUE	Rail	mountingRail	FALSE	10/48	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	4400
// 610-0056	1700mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1700
// 610-0096	2250mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	2250
// 610-0704	1200mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1200
// 610-0705	600mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	600
// 800-0020	4150mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	4150
// 800-0021	3150mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	3150
// 800-1055	6000mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	6000
// 800-1317	5200mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	5200
// 800-1344	2400mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	2400
// 800-1405	4400mm 4/35 Rail Silver	TRUE	Rail	mountingRail	FALSE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	4400
// 800-1384	3300mm 4/35 Rail Silver	TRUE	Rail	mountingRail	TRUE	4/35	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	3300
// 610-0070	2400mm 6/40 Rail Silver	TRUE	Rail	mountingRail	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	2400
// 800-0017	4150mm 6/40 Rail Silver	TRUE	Rail	mountingRail	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	4150
// 800-0019	3150mm 6/40 Rail Silver	TRUE	Rail	mountingRail	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	3150
// 800-1057	6000mm 6/40 Rail Silver	TRUE	Rail	mountingRail	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	6000
// 800-1319	5200mm 6/40 Rail Silver	TRUE	Rail	mountingRail	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	5200
// 800-1411	4400mm 6/40 Rail Silver	TRUE	Rail	mountingRail	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	4400
// 800-1412	3300mm 6/40 Rail Silver	TRUE	Rail	mountingRail	FALSE	6/40	silver	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	3300
// 602-0050	10/48 Rail Connector Silver	FALSE	Rail Connector	railSplice	FALSE	10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 602-0048	4/35 Rail Connector Silver	FALSE	Rail Connector	railSplice	TRUE	4/35	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 602-0049	6/40 Rail Connector Silver	FALSE	Rail Connector	railSplice	FALSE	6/40	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 707-0202	Roof Hook standard comfort stainless steel 170-7-49	TRUE	Roof Hook	roofHook	TRUE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	FALSE		TRUE	1		TRUE	1	1
// 707-0212	Roof Hook for plain tiles, preassembled stainless steel	TRUE	Roof Hook	roofHook	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	TRUE	806-0072	FALSE	2		TRUE	1	1
// 707-0036	Roof Hook for slate tiles stainless steel	TRUE	Roof Hook	roofHook	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal|vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	TRUE	806-0017	FALSE	3		TRUE	1	1
// 707-0204	Heavy Loads Roof Hook comfort stainless steel 225-9-46	TRUE	Roof Hook	roofHook	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	TRUE	806-0029	FALSE	2	807-0026	FALSE	2	1
// 707-0201	Adjustable Roof Hook comfort stainless steel	TRUE	Roof Hook	roofHook	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	horizontal	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	TRUE	806-0072	FALSE	2		TRUE	1	1
// 805-0047	Roof Hook with twisted bar stainless steel 155-7-49	TRUE	Roof Hook	roofHook	FALSE	4/35|6/40|10/48	silver|black	30-40|33-45|38-50	vertical	Composition / Asphalt Shingle|Flat Concrete|Flat Foam|Kliplock|Membrane EPDM|Membrane PVC|Membrane TPO|Metal Decramastic|Metal Shingle|Metal Standing Seam|Metal Stone Coated|Metal Tin|Tar and Gravel / Bitumen|Thatched|Tile Clay|Tile Concrete|Tile Slate|Wood/Shake Shingle|Other	TRUE	806-0072	FALSE	2		TRUE	1	1`

// export const gmbhProducts = parseProds(gmbh_products)

// export const gmbhDropdowns = getDropdowns(gmbhProducts)
