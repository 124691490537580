import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useState } from 'react'
import { useNotify, useRefresh, useUpdateMany } from 'react-admin'

type PropTypes = {
  isShowingArchived: boolean
  enableBulkSharing: boolean
  enableBulkArchive: boolean
  selectedIds: number[]
  resource: string
}

const BulkArchiveButton: React.FC<PropTypes> = (props) => {
  const [sentRequest, setSentRequest] = useState<boolean>(false)

  const notify = useNotify()
  const refresh = useRefresh()
  const [updateMany, { data, loading, error, total }] = useUpdateMany(
    props.resource,
    // @ts-ignore
    props.selectedIds,
    {
      is_archived: !props.isShowingArchived,
    },
    {
      views: 0,
    }
  )

  // I'm not able to get react-admins onSuccess param for useUpdateMany to fire, so this will take its place
  useEffect(() => {
    if (loading && !sentRequest) {
      setSentRequest(true)
    }
    if (sentRequest && !loading && !error?.body?.message) {
      notify(
        `${props.selectedIds.length} ${props.selectedIds.length > 1 ? 'records have' : 'record has'} been ${
          props.isShowingArchived ? 're-activated.' : 'archived.'
        }`,
        'success'
      )
      setSentRequest(false)
      refresh()
    }
  }, [loading, sentRequest, error])

  useEffect(() => {
    if (error?.body?.message) {
      notify(error.body.message, 'warning')
    }
  }, [error?.body?.message])

  const onClick = () => {
    updateMany()
  }

  return (
    <ProUXButton
      label={props.isShowingArchived ? 'Re-activate Selected Records' : 'Archive Selected Records'}
      onClick={onClick}
      type="secondary"
      loading={loading}
    />
  )
}
export default BulkArchiveButton
