import { PartnerIdType } from 'pages/auth/sso/types'
import { MFAConfigType } from './mfa'
import { OrgBrief } from './orgs'
import { RoleType } from './roles'

export type AuthStatus =
  | 'undetermined'
  | 'sso_logging_in'
  | 'reloading'
  | 'os_logging_in'
  | 'auto_logging_in'
  | 'os_registering'
  | 'authenticated'

export interface AuthState extends AuthType {
  loadedFromLocalStorage?: boolean
  autoLoginProject?: number
  status?: AuthStatus
  reloading?: boolean

  // MFA
  mfaCheckRequired: boolean
  mfaConfigRequired: boolean
  mfaCodesShowing: boolean
  mfaHasSms: boolean
  mfaHasTotp: boolean
  mfaHasRecovery: boolean
  mfaConfig?: MFAConfigType
}

// These props get omitted from local storage
export const AUTH_STATE_EPHEMERAL_PROPS = [
  'loadedFromLocalStorage',
  'autoLoginProject',
  'reloading',
  'mfaCheckRequired',
  'mfaConfigRequired',
  'mfaCodesShowing',
  'mfaHasSms',
  'mfaHasTotp',
  'mfaHasRecovery',
  'status',
]

export interface AuthUserType {
  id: number
  email: string
  phone?: string
  login_authority?: LoginAuthority
  external_account_id?: string
  email_marketing_opt_in: boolean
  is_mfa_enabled: boolean
  show_tour_banner?: boolean
  user_hid_banner?: boolean
  is_anonymous_share_user?: boolean
  paired_partners: PairedPartners
}

type PairedPartners = {
  [key in PartnerIdType]: {
    partner_user_uuid: string
    pair_metadata: {
      email: string
    }
  }
}

export interface AuthExhibitorType {
  id: number
  name: string
  logo_public_url: string
  component_types: number[]
}

export interface AuthType {
  OPENSOLAR_API_VERSION: string
  token: string | undefined
  user: AuthUserType
  org_id: number
  orgs: OrgBrief[]
  exhibitors: AuthExhibitorType[]
  is_staff: boolean
  is_superuser: boolean
  roles: RoleType[]
  terms_accepted_date: string // ISO8601: 2022-08-04T02:56:55.419768+00:00
  api_key_google: string
  staff_access_countries_csv?: string
}

export type LoginAuthority = 'nearmap'
