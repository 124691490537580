import { authSelectors } from 'ducks/auth'
import { List } from 'elements/react-admin/List'
import { Chip, StarIcon, styled } from 'opensolar-ui'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { FunctionField, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { PRIORITY_CHOICES } from '../choices'
import Pagination from '../projects3/elements/Pagination'
import { ContactsBlock } from '../projectsList/ContactsBlock'
import ProjectSelectDropdown from '../projectsList/ProjectSelectDropdown'
import StageSelectDropdown from '../projectsList/StageSelectDropdown'
import AddressDisplay from './elements/AddressDisplay'
import FavoriteButton from './elements/FavoriteButton'
import ProjectActionsButton from './elements/ProjectActionsButton'
import ProjectDatagrid from './elements/ProjectDatagrid'
import TeamDisplay from './elements/TeamDisplay'
import ProjectsHeader from './ProjectsHeader'
import SearchToolbar from './SearchToolbar'
import ViewToolbar from './ViewToolbar'

const StarIconStyled = styled(StarIcon)({
  paddingLeft: 8,
})

const ProjectList = styled(List)({
  padding: 0,
  width: '100%',
  '& .Ra-List-Main': {
    overflowX: 'auto',
  },
})

const StyledChip = styled(Chip)({
  marginRight: 3,
  borderRadius: 24,
  border: '1px solid #E6E6E6',
  color: COLOR_PALETTE.darkGrey,
  backgroundColor: 'transparent',
})

const HeaderWrapper = styled('div')({
  width: '100%',
})

const PrioSquare = styled('div')({
  width: 10,
  height: 10,
  marginRight: 5,
  borderRadius: 2,
})

const ChoiceSpan = styled('span')({
  //possibly move this as a BaseTheme style?
  fontSize: 14,
  fontWeight: 500,
})

const PRIORITY_COLORS = {
  1: 'info',
  2: 'warning',
  3: 'error',
}

const PRIORITY_CHOICE_COLORS = {
  1: '#005D7A',
  2: '#C47E0A',
  3: '#961616',
}

const ProjectsTable = (props) => {
  const translate = useTranslate()
  const classes = getProjectSectionStyles()
  const orgId = useSelector(authSelectors.getOrgId)

  return (
    <ProjectList
      id={orgId}
      perPage={10}
      hasCreate={false}
      resource={'projects'}
      basePath={'projects'}
      title={<ProjectHeader />}
      breadCrumbs={<></>}
      hasSearch={false}
      //@ts-ignore
      pagination={<Pagination />}
      {...props}
    >
      <ProjectDatagrid>
        {/* <FunctionField source="" label={<Checkbox />} render={(record) => <Checkbox />} /> */}
        <FunctionField
          source="stars"
          label={
            <div className={classes.row}>
              <StarIconStyled color={COLOR_PALETTE.orange} />
            </div>
          }
          render={(record) => <FavoriteButton record={record} />}
        />
        <FunctionField source="address" render={(record) => <AddressDisplay record={record} />} />
        <FunctionField source="id" label={'Project ID'} render={(record) => record.id} />
        <FunctionField
          source="contacts__family_name"
          label="Contacts"
          render={(record) => !record.is_lite && <ContactsBlock contactsData={record.contacts_data} />}
        />
        <FunctionField
          label={translate('Stage')}
          render={(record) => {
            if (!record.is_lite) {
              return <StageSelectDropdown project={record} />
            }
            return null
          }}
        />
        <FunctionField
          source="priority"
          render={(record) =>
            !record.is_lite && (
              <ProjectSelectDropdown
                choices={PRIORITY_CHOICES}
                source="priority"
                project={record}
                color={PRIORITY_COLORS[record.priority]}
                renderValue={(choice) => `● ${translate(choice.name)}`}
                renderChoiceValue={(choice) => (
                  <ChoiceSpan className={classes.row}>
                    <PrioSquare style={{ backgroundColor: PRIORITY_CHOICE_COLORS[choice.id] }} />
                    {translate(choice.name)}
                  </ChoiceSpan>
                )}
              />
            )
          }
        />
        <FunctionField
          source="assigned_role_data"
          label="Team"
          render={(record) => {
            return <TeamDisplay teamMember={record.assigned_role_data} />
          }}
        />
        <FunctionField
          source="is_residential"
          label="Tags"
          render={(record) => {
            const soldInstalledChip =
              record.project_installed !== null
                ? translate('Installed')
                : record.project_sold !== null
                ? translate('Sold')
                : undefined
            return (
              <div className={classes.row}>
                {record.is_lite && <StyledChip label={'OS Lite'} variant="outlined" />}
                <StyledChip
                  label={translate(record.is_residential ? 'Residential' : 'Commercial')}
                  variant="outlined"
                />
                {soldInstalledChip && <StyledChip label={soldInstalledChip} variant="outlined" />}
              </div>
            )
          }}
        />
        <FunctionField
          source=""
          textAlign={'right'}
          render={(record) => {
            return <ProjectActionsButton project={record} />
          }}
        />
      </ProjectDatagrid>
    </ProjectList>
  )
}

const ProjectHeader = (props) => {
  const classes = getProjectSectionStyles()
  return (
    <HeaderWrapper className={classes.column}>
      <ProjectsHeader {...props} />
      <ViewToolbar {...props} />
      <SearchToolbar {...props} />
    </HeaderWrapper>
  )
}

export default ProjectsTable
