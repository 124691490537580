import { CircularProgress, makeStyles, Paper, Tab, Tabs } from '@material-ui/core'
import {
  findBestMatchForDefaultImageryType,
  getImageryTypeDefault
} from 'app/src/projectSections/sections/design/util/getImageryTypeDefault'
import { PERFORMANCE_CALCULATORS } from 'constants/calculators'
import { useViewShow, viewModeActions, viewModeSelectors } from 'ducks/viewMode'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { PremiumImageryBlockReasonType, PremiumImageryType } from 'types/global'
import { MapDataTypes } from 'types/map'
import { RootState } from 'types/state'
import { DesignMode } from 'types/studio/map'
import { closablePanels } from 'util/closablePanels'
import { tryAddPremiumImageryIfRequiredAndNotify } from 'util/imagery'
import { getMapTypeKey } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import { extractImageryTypeDefaultIfMatchFound } from '../../util/extractImageryTypeDefaultIfMatchFound'
import { setImageryTypeDefault } from '../../util/setImageryTypeDefault'
import { ToolbarDrawingToolsName } from '../advanced/ToolbarAdvanced'
import ToolbarSelector from '../ToolbarSelector'
import IconDesignMode2D from './images/DesignMode2D.svg'
import IconDesignMode3D from './images/DesignMode3D.svg'
import IconDesignModeManual from './images/DesignModeManual.svg'
import ToolbarDesignModeActions from './ToolbarDesignModeActions'
import { DesignMode2D, DesignMode3D, DesignModeManual } from './ToolbarDesignModeContent'

const DESIGN_MODES_CONFIG = [
  { key: '3D', label: '3D', imagery: IconDesignMode3D, content: DesignMode3D },
  { key: '2D', label: '2D', imagery: IconDesignMode2D, content: DesignMode2D },
  { key: 'Manual', label: 'Manual', imagery: IconDesignModeManual, content: DesignModeManual },
]

const useImageryStyles = makeStyles((theme: any) => ({
  modeImage: {
    width: 75,
    height: 60,
    margin: 0,
  },
  wrapper: {
    position: 'relative',
    width: 75,
    height: 60,
  },
  tag: {
    position: 'absolute',
    fontSize: 10,
    bottom: 0,
    right: -20,
    padding: '0 5px',
    borderRadius: 5,
    color: 'rgb(255,255,255)',
    background: 'rgba(24, 144, 255, 0.9)',
  },
}))

type ImageryPropTypes = {
  imagery: any
  selectedDesignMode: string | null
  value: string
}

const MapImagery: React.FC<ImageryPropTypes> = ({ imagery, selectedDesignMode, value }) => {
  const currentAppliedDesignMode = useMemo(() => selectedDesignMode, [])
  const classes = useImageryStyles()
  const translate = useTranslate()
  return (
    <div className={classes.wrapper}>
      {/* <img src={imagery} classes={{ root: classes.modeImage }} /> */}
      <img src={imagery} />
      {currentAppliedDesignMode === value && <span className={classes.tag}>{translate('Applied')}</span>}
    </div>
  )
}

const useStyles = makeStyles((theme: any) => ({
  tab: {
    textTransform: 'none',
    borderBottom: '2px solid #e1e1e1',
    minWidth: 130,
    [theme.breakpoints.down('xs')]: {
      minWidth: 110,
    },
  },
  tabpanel: {
    overflow: 'auto',
    textAlign: 'left',
    padding: 8,
    boxShadow: '-4px 0px 2px -2px rgb(0 0 0 / 12%)',
    fontSize: 12,
  },
  modeImage: {
    width: 75,
    height: 60,
    margin: 0,
  },
  indicator: {
    backgroundColor: '#1890ff',
    width: 130,
    [theme.breakpoints.down('xs')]: {
      width: 110,
    },
  },
  modeSelectorWrapper: {
    display: 'flex',
  },
  loading: {
    width: 130,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      width: 110,
    },
  },
  wrapper: {
    position: 'absolute',
    top: 40,
    right: 0,
    textAlign: 'center',
    background: 'rgba(255, 255, 255, 0.9)',
    [theme.breakpoints.down('xs')]: {
      top: 'unset',
      position: 'absolute',
      bottom: 40,
      right: 0,
      textAlign: 'center',
      background: 'rgba(255, 255, 255, 0.9)',
    },
  },
  selectRoot: {
    display: 'block',
    '&$select': {
      paddingRight: 0,
    },
  },
  select: {
    display: 'block',
    width: 200,
    paddingRight: 0,
  },
}))

type PropTypes = {
  handleClick: Function
  // handleCloseOtherMenus: Function
  state?: any
  disabled: boolean
  toggleToolbar: Function
  isPremiumImageryAvailable: boolean
  premiumImageryUnavailableReson: PremiumImageryBlockReasonType
  premiumImageryActivations: PremiumImageryType[]
}

export const getCurrentSystemCalculatorOrDefault = (system?: any) => {
  var systems = window.editor && window.editor.getSystems ? window.editor.getSystems() : null
  if (!system && systems) {
    system = systems[0]
  }
  return PERFORMANCE_CALCULATORS.filter((c) => {
    if (system) {
      return c.id === system.calculator
    } else {
      return c.id === window.WorkspaceHelper.getDefaultPerformanceCalculator()
    }
  })[0]
}

export const ToolbarDesignModeName = 'ToolbarDesignMode'

const ToolbarDesignMode: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const tour = useSelector((state: RootState) => state.tour?.tour)
  const dispatch = useDispatch()
  const notify = useNotify()
  const designModeState = useSelector(viewModeSelectors.designModeState)
  // type openModeType = undefined | 'open-locked' | 'closed-interactive' | 'open-interactive' | 'open-locked'
  // const [openMode, setOpenMode] = useSafeSetState<openModeType>(tour ? 'open-locked' : undefined) // closed-interactive, open-interactive, open-locked,undefine
  const [imageryType, setImageryType] = useState<MapDataTypes | null>(null)
  const [hasForcedOpenForPremiumImg, setHasForcedOpenForPremiumImg] = useState<boolean>(false)

  const [isLoadingEagleViewReport, setIsLoadingEagleViewReport] = useState<boolean>(false)

  // Temporary 'upgrade' from Google Sunroof API preference > Google Solar API
  // https://github.com/open-solar/opensolar-todo/issues/9560
  // Can remove this after start of December 2023
  useEffect(() => {
    var imageryTypeDefault = getImageryTypeDefault()

    if (imageryTypeDefault && enableGoogleSolarApi) {
      if (imageryTypeDefault.map_type === 'Google3D' && imageryTypeDefault.variation_name !== 'Google Solar API') {
        imageryTypeDefault.variation_name = 'Google Solar API'
        setImageryTypeDefault(imageryTypeDefault)
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      // Clear designModeState when unmount
      dispatch(viewModeActions.setDesignModeState(undefined))
    }
  }, [])

  useEffect(() => {
    closablePanels.add(ToolbarDesignModeName, close)
    return () => closablePanels.remove(ToolbarDesignModeName)
  }, [])

  useEffect(() => {
    // open design mode panel if the use can enable premium imagery or if they normally can, but for this project they can't due to funds or location
    const blockedReasonsThatOpenDialog = ['insufficient_funds', 'unsupported_location', 'unsupported_location_3d']
    if (
      !hasForcedOpenForPremiumImg &&
      !hasSystems() &&
      (props.isPremiumImageryAvailable ||
        (props.premiumImageryUnavailableReson &&
          blockedReasonsThatOpenDialog.includes(props.premiumImageryUnavailableReson)))
    ) {
      open()
      setHasForcedOpenForPremiumImg(true)
    }
  }, [props.isPremiumImageryAvailable, props.premiumImageryUnavailableReson, hasForcedOpenForPremiumImg])

  const { availableImageryTypes, timezoneOffset, detectImageryStatus, detectImageryCacheKey } = useSelector(
    (state: any) => {
      return {
        availableImageryTypes: state.designer?.detectImagery?.availableMapTypes || [],

        // Beware timezoneOffset can equal 0 so be careful with a lazy truthy check
        timezoneOffset: state.designer?.detectImagery?.timezoneOffset || 0,
        detectImageryStatus: state.designer?.detectImagery?.status || null,
        detectImageryCacheKey: state.designer?.detectImagery?.cacheKey || null,
      }
    }
  )

  const hasSystems = () => {
    var systems = window.editor && window.editor.getSystems ? window.editor.getSystems() : null
    return systems && systems.length > 0
  }

  const startDesignModeAndEnablePremiumImageryIfNecessary = async (defaultImageryTypeData) => {
    notify('Auto-applying default imagery type preference', 'success')

    let sceneOrigin4326 = window.AccountHelper.sceneOrigin4326FromSceneOrProject()

    // This assumes premiumImageryActivations is empty but could there be scenarios where this is incorrect?
    const isActivated = await tryAddPremiumImageryIfRequiredAndNotify(
      defaultImageryTypeData,
      [],
      [window.WorkspaceHelper.project.lon, window.WorkspaceHelper.project.lat],
      notify
    )

    if (isActivated) {
      window.SceneHelper.startDesignMode(
        sceneOrigin4326,
        window.WorkspaceHelper.project.country_iso2,
        window.WorkspaceHelper.project.state,
        defaultImageryTypeData,
        timezoneOffset
      )
    }
  }

  useEffect(() => {
    // @TODO: Rebuild this hack
    var isReplay = window.location.hash.includes('/replay/')
    if (isReplay) {
      return
    }

    // // Check hasSystems() again because time may have passed since autoApply was first set before calling this async
    // Do not auto-apply during FUJI tour
    var autoApply = !Boolean(tour)

    if (autoApply && !hasSystems()) {
      // if we find a matching default imagery type then apply it here, otherwise force dialog open
      var defaultImageryTypeData = extractImageryTypeDefaultIfMatchFound(availableImageryTypes)
      // do not auto-apply premium imagery or disabled
      if (
        defaultImageryTypeData &&
        !defaultImageryTypeData?.variation_data?.require_wallet_product?.activation_requires_permission &&
        !defaultImageryTypeData?.disabled_message
      ) {
        startDesignModeAndEnablePremiumImageryIfNecessary(defaultImageryTypeData)
        close()
      } else {
        open('open-locked')
      }
    } else {
      if (!designModeState) {
        //initialize openMode
        close()
      }
    }

    // Always set selected design mode & imagery for all scenarios
    // as soon as we have available imagery
    if (availableImageryTypes && availableImageryTypes.length > 0) {
      var _selectedDesignMode = autoApplyDesignModeDefault(availableImageryTypes)
      if (_selectedDesignMode) autoSelectDefaultImageryTypeForDesignMode(_selectedDesignMode, availableImageryTypes)
    }
  }, [JSON.stringify(availableImageryTypes)])

  const [selectedDesignMode, setSelectedDesignMode] = useState<string | null>(null)
  const openModeRef = useRef(designModeState)
  const enableGoogleSolarApi = useFeatureFlag('google_solar_api', 'on')
  openModeRef.current = designModeState

  const open = useCallback((mode = 'open-interactive') => {
    // closablePanels.closeAllExcept(ToolbarDesignModeName)
    closablePanels.close(ToolbarDrawingToolsName)
    // props.handleCloseOtherMenus('ToolbarDesignMode')
    // setOpenMode(mode)
    dispatch(viewModeActions.setDesignModeState(mode))
  }, [])
  const close = useCallback(() => {
    // setOpenMode('closed-interactive')
    dispatch(viewModeActions.setDesignModeState('closed-interactive'))
  }, [])

  // const getState = useCallback(() => {
  //   return {
  //     openMode: openModeRef.current,
  //   }
  // }, [])

  const refreshPanel = () => {
    var eagleViewIsLoading = !!window.SceneHelper.isLoadingEagleViewReport
    if (eagleViewIsLoading !== isLoadingEagleViewReport) {
      setIsLoadingEagleViewReport(eagleViewIsLoading)
    }
  }

  const has3DImagery = (_availableImageryTypes: MapDataTypes[]) => {
    return _availableImageryTypes.some((m: MapDataTypes) => m.design_mode === '3D')
  }

  const autoApplyDesignModeDefault = (_availableImageryTypes: MapDataTypes[]) => {
    if (selectedDesignMode) {
      // do not auto-apply if already selected
      return selectedDesignMode
    }

    var designModeDefault: DesignMode | null = null

    // detect from the current map type in studio, if a match is found
    if (
      window.MapHelper &&
      window.MapHelper.activeMapInstance &&
      window.MapHelper.activeMapInstance.mapData &&
      window.MAP_TYPES
    ) {
      // Only use current imagery type as a guid if the design has actually
      // been progressed. If we are just arriving from the explore page
      // then ignore the current imagery type
      // @TODO: When imagery can be modified in the explore page we should
      // have a way to prefer any matches with that imagery type if superior
      // imagery is not found. e.g. If we changed to SixMaps in Explore
      // then we could prefer that over Google, but we should certainly still
      // use Google3D by default if it is available and ignore the SixMaps.
      var designHasBeenUpdatedByUser = window.editor.getSystems().length > 0

      if (designHasBeenUpdatedByUser) {
        // Previously we used this but since setView is async sometimes this will have old value
        // Use ViewHelper.views which is updated synchronously during setup
        var mapType = window.ViewHelper.views[0]?.mapData?.mapType || window.MapHelper.activeMapInstance.mapData.mapType
        // @TODO: Improve this method of classifying existing views
        var designModeFromMapType = window.MAP_TYPES[mapType].designMode

        if (designModeFromMapType) {
          designModeDefault = designModeFromMapType
        }
      }
    }

    // detect from imagery preference, if set
    if (!designModeDefault) {
      var imageryTypeDefault = getImageryTypeDefault()

      if (imageryTypeDefault && imageryTypeDefault.design_mode) {
        designModeDefault = imageryTypeDefault.design_mode
      } else {
        designModeDefault = '3D'
      }
    }

    // We now have a preference but we can only use this preference if matching imagery is available
    // otherwise fall back to next best design mode
    if (designModeDefault === '3D' && !has3DImagery(_availableImageryTypes)) {
      //choose either 2D or Manual
      designModeDefault = '2D'
    }

    setSelectedDesignMode(designModeDefault)
    return designModeDefault
  }

  const calculator = getCurrentSystemCalculatorOrDefault()
  const calculatorName = calculator ? calculator.name : null

  const autoSelectDefaultImageryTypeForDesignMode = (_designMode: string, _availableImageryTypes: MapDataTypes[]) => {
    //use _availableImageryTypes if supplied because we may not want to wait for state to update, otherwise use state

    var imageryTypesForDesignMode = _availableImageryTypes.filter((m) => m.design_mode === _designMode)

    if (imageryTypesForDesignMode.some((m) => getMapTypeKey(m) === getMapTypeKey(imageryType))) {
      // if currently selected mapData is still available in the list then keep it, otherwise select the first match
      return
    }

    var imageryTypeMatchingPreference = findBestMatchForDefaultImageryType(
      imageryTypesForDesignMode,
      getImageryTypeDefault()
    )
    if (imageryTypeMatchingPreference) {
      // no imageryType is selected yet, so if we have a preferenced saved then we should use that
      setImageryType(imageryTypeMatchingPreference)
      return
    }

    // no item matches our preference, just choose the first item
    setImageryType(imageryTypesForDesignMode[0])
    return
  }

  useStudioSignalsLazy(close, ['viewsChanged'])

  useEffect(() => {
    // const componentRef = {
    //   open,
    //   close,
    //   getState,
    //   refreshPanel,
    // }
    // referenceSave.call(componentRef, 'ToolbarDesignMode', [
    //   'viewsChanged',
    //   'sceneLoaded',
    //   'sceneGraphChanged',
    //   'objectChanged',
    //   'objectSelected',
    //   'systemSelected',
    //   'controlModeChanged',
    //   'displayModeChanged',
    //   'mapChanged',
    // ])
    // referenceSave.call(componentRef, 'ToolbarDesignMode')

    window.loadAvailableImagery()
    // return () => referenceClear.call(componentRef)
  }, [])

  useStudioSignalsLazy(refreshPanel, [
    'viewsChanged',
    'sceneLoaded',
    'sceneGraphChanged',
    'objectChanged',
    'objectSelected',
    'systemSelected',
    'controlModeChanged',
    'displayModeChanged',
    'mapChanged',
  ])

  let sceneOrigin4326 = window.AccountHelper.sceneOrigin4326FromSceneOrProject()
  const showPopup = designModeState === 'open-interactive' || designModeState === 'open-locked'
  const translate = useTranslate()

  const isLoading = isLoadingEagleViewReport
  const panelIsReady = !isLoading && !!(selectedDesignMode && availableImageryTypes && availableImageryTypes.length)

  const show = useViewShow('studio.select_design_mode')
  if (!show) {
    return null
  }


  return (
    <>
      <ToolbarSelector
        disabled={props.disabled}
        id={showPopup ? 'toolbar-design-mode-button' : 'design-mode-selection-wrapper'}
        label="Design Mode"
        icon={
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9426 15.0532C20.7887 14.6944 20.3725 14.5283 20.0146 14.6824L12.0001 18.1204L3.98555 14.6824C3.62625 14.5278 3.21138 14.6947 3.05753 15.0532C2.90344 15.4118 3.06956 15.8274 3.42836 15.9812L11.7213 19.539C11.8104 19.5772 11.9053 19.5962 12.0001 19.5962C12.0948 19.5962 12.1897 19.5771 12.2788 19.539L20.5718 15.9812C20.9303 15.8274 21.0964 15.4118 20.9426 15.0532Z" />
            <path d="M20.9433 11.5205C20.7899 11.1617 20.3748 10.9946 20.016 11.1475L12.0001 14.5634L3.98439 11.1475C3.62509 10.9944 3.21 11.1617 3.05707 11.5205C2.90393 11.8798 3.07096 12.2949 3.43001 12.4478L11.723 15.9817C11.8116 16.0194 11.9058 16.0382 12.0001 16.0382C12.0943 16.0382 12.1888 16.0194 12.2771 15.9817L20.5701 12.4477C20.9291 12.2948 21.0962 11.8797 20.9433 11.5205Z" />
            <path d="M20.5717 7.61474L12.2787 4.05724C12.1006 3.98092 11.8994 3.98092 11.7215 4.05724L3.42855 7.61474C3.16847 7.72619 3.00024 7.98205 3.00049 8.26499C3.00095 8.54793 3.17012 8.80334 3.4302 8.91429L11.7232 12.4482C11.8118 12.4859 11.906 12.5047 12.0002 12.5047C12.0945 12.5047 12.189 12.4859 12.2771 12.4482L20.57 8.91426C20.8304 8.80352 20.9996 8.54789 20.9998 8.26496C21 7.98226 20.8318 7.72615 20.5717 7.61474ZM12.0002 11.0299L5.50509 8.26214L12.0002 5.47576L18.4954 8.26214L12.0002 11.0299Z" />
          </svg>
        }
        dropdownOpen={designModeState === 'open-interactive' || designModeState === 'open-locked'}
        onClick={() => {
          if (designModeState === 'open-locked') {
            //
          } else if (designModeState === 'open-interactive') {
            close()

            autoApplyDesignModeDefault(availableImageryTypes)
          } else if (designModeState === 'closed-interactive' || !designModeState) {
            window.loadAvailableImagery()
            open('open-interactive')
          }
        }}
      />
      {(showPopup || isLoading) && (
        <Paper id={'design-mode-selection-wrapper'} classes={{ root: classes.wrapper }}>
          {panelIsReady ? (
            <>
              <Tabs
                value={selectedDesignMode}
                onChange={(event, value) => {
                  setSelectedDesignMode(value)
                  if (value) {
                    autoSelectDefaultImageryTypeForDesignMode(value, availableImageryTypes)
                  }
                }}
                classes={{ indicator: classes.indicator }}
              >
                {DESIGN_MODES_CONFIG.map((designMode) => {
                  return (
                    <Tab
                      classes={{ root: classes.tab }}
                      icon={
                        <MapImagery
                          imagery={designMode.imagery}
                          selectedDesignMode={selectedDesignMode}
                          value={designMode.key}
                        />
                      }
                      label={translate(designMode.label)}
                      value={designMode.key}
                      key={designMode.key}
                    />
                  )
                })}
              </Tabs>

              {DESIGN_MODES_CONFIG.map((designMode) => {
                return (
                  <div
                    role="tabpanel"
                    className={classes.tabpanel}
                    hidden={designMode.key !== selectedDesignMode}
                    key={designMode.key}
                    id={`toolbar-design-mode-tabpanel-${designMode.key}`}
                    aria-labelledby={`toolbar-design-mode-tab-${designMode.key}`}
                  >
                    {designMode.key === selectedDesignMode && (
                      <>
                        {React.createElement(designMode.content, {
                          warning:
                            designMode &&
                              designMode.key === '3D' &&
                              calculator &&
                              calculator.supports_automated_shading !== true
                              ? translate('not_support_shading_switch', { calcName: calculatorName })
                              : undefined,
                        })}
                        <ToolbarDesignModeActions
                          close={close}
                          availableImageryTypes={availableImageryTypes}
                          selectedDesignMode={selectedDesignMode}
                          imageryType={imageryType}
                          setImageryType={setImageryType}
                          timezoneOffset={timezoneOffset}
                          // forceSelection={openMode === 'open-locked'}
                          sceneOrigin4326={sceneOrigin4326}
                          // isPremiumImageryAvailable={props.isPremiumImageryAvailable}
                          premiumImageryUnavailableReson={props.premiumImageryUnavailableReson}
                          premiumImageryActivations={props.premiumImageryActivations}
                        />
                      </>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            <div id={'design-mode-selection-wrapper'} className={classes.loading}>
              <p>Loading</p>
              <CircularProgress size={30} />
            </div>
          )}
        </Paper>
      )}
    </>
  )
}

export default memo(withStudioSignals(ToolbarDesignMode))
