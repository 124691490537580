import { makeStyles } from '@material-ui/core'
import BulkDeleteWithConfirmButton from 'elements/button/BulkDeleteWithConfirmButton'
import React, { useState } from 'react'
import BulkArchiveButton from './BulkArchiveButton'
import BulkSharingButton from './BulkSharingButton'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'BulkActionsWrapper' }
)

type PropTypes = {
  enableBulkSharing: boolean
  enableBulkArchive: boolean
  enableBulkDelete: boolean
  selectedIds: number[]
  resource: string
  filterValues?: {
    show_only_archived?: 1 | 0
  }
  enableBulkExport?: boolean
}

const BulkActionButtons: React.FC<PropTypes> = (props) => {
  const [sharingExpanded, setSharingExpanded] = useState<boolean>(false)
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {props.enableBulkArchive && !sharingExpanded && (
        <BulkArchiveButton isShowingArchived={props.filterValues?.show_only_archived === 1} {...props} />
      )}
      {props.enableBulkSharing ? <BulkSharingButton {...props} setSharingExpanded={setSharingExpanded} /> : <></>}
      {props.enableBulkDelete && !sharingExpanded && (
        <BulkDeleteWithConfirmButton label="Delete Selected Records" {...props} />
      )}
    </div>
  )
}

export default BulkActionButtons
