import { Typography } from '@material-ui/core'
import { orderSelectors } from 'ducks/orderComponents'
import { useSelector } from 'react-redux'

const CheckoutTitle = () => {
  const totalLineItems = useSelector(orderSelectors.getOrderLineItems)

  return (
    <Typography variant="h4" gutterBottom align="center">
      {`Checkout (${totalLineItems.length} item(s))`}
    </Typography>
  )
}

export default CheckoutTitle
